import styled from 'styled-components';

const TextPostDescription = styled.div`
    color: #ffffff;
    font-size: 20px;
    padding: 25px;
    text-align: center;
    word-break: break-word;
    font-family: ${props => {
        switch (props.fontFamily) {
            case 'Typewritter':
                return 'TheinhardtPan';
            case 'Curly':
                return 'Fabfelt';
            case 'Bold':
                return 'Bower';
            default:
                return 'TheinhardtPan';
        }
    }};
`;

export default TextPostDescription;
