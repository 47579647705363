import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ImageSelectorContainer from './ImageSelectorContainer';
import PreviewImage from '../../components/PreviewImage';
import ProfileForm from './ProfileForm';
import dummy from './assets/dummy.png';
import { isIpadLandscape, isMobile } from '../../utils/constants';
import LocalisationsStore from '../../common/LocalisationsStore';

const CreateProfile = ({
    imageUri,
    setImageUri,
    postImage,
    questions,
    isLoading,
    popUpOpen,
    toggleShowSelectorModal,
    setNameQuestionAns,
    setTimeQuestionAns,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { addPhotoHere, changePhoto } = localisationsAfterLogin;

    const adjustedPath = 'M53.91,181.86c10.26,37.81,106.77,88.55,145.43,29.38,45.83-70.17,25.51-115.78,0-136.09C180.18,59.89,119.78,43.72,86.41,59.4S41.19,135,53.91,181.86Z';
    const adjustedTransform = 'translate(-48.66 -52.74)';

    const path = 'M32.7,191c12.86,47.37,133.78,111,182.21,36.81,57.42-87.91,32-145,0-170.5-24-19.12-99.68-39.38-141.49-19.74S16.76,132.25,32.7,191Z';
    const transform = 'translate(-19.35 -22.17)';

    return (
        <div className="create_profile_wrapper">
            <Grid container spacing={isIpadLandscape ? 4 : 0}>
                <Grid item xs={12} sm={12} md={4} lg={4} className="create_profile_image_wrapper">
                    {isMobile && <div className="mobile_profile_pic_des">Set your profile picture</div>}
                    <Box className={imageUri ? 'profilePicture profilePicture_adjusted' : 'profilePicture'}>
                        <div className="profile_picture_holder_container">
                            <svg height="0" width="0">
                                <clipPath id="myPath">
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d={imageUri ? path : adjustedPath}
                                        transform={imageUri ? transform : adjustedTransform}
                                        fill="none"
                                        stroke="#231f20"
                                        strokeMiterlimit="10"
                                        strokeWidth="0.5"
                                    />
                                </clipPath>
                            </svg>
                            {!imageUri ? (
                                <div className="preview_image_adjusted">
                                    <PreviewImage
                                        src={imageUri || dummy}
                                        alt=""
                                        width={imageUri ? 238 : 142}
                                        height={imageUri ? 250 : 163}
                                        margin="15px"
                                    />
                                </div>
                            ) : (
                                <PreviewImage
                                    src={imageUri || dummy}
                                    alt=""
                                    width={imageUri ? 238 : 142}
                                    height={imageUri ? 250 : 163}
                                />
                            )}
                            <ImageSelectorContainer
                                imageUri={imageUri}
                                setImageUri={setImageUri}
                                postImage={postImage}
                                questions={questions}
                                isLoading={isLoading}
                                popUpOpen={popUpOpen}
                                toggleShowSelectorModal={toggleShowSelectorModal}
                            />
                        </div>
                    </Box>
                    <Box className="pictureText">{imageUri ? changePhoto : addPhotoHere}</Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} className="mobile_profile_form_wrapper">
                    <ProfileForm
                        questions={questions}
                        setNameQuestionAns={setNameQuestionAns}
                        setTimeQuestionAns={setTimeQuestionAns}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

CreateProfile.propTypes = {
    postImage: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    setImageUri: PropTypes.func.isRequired,
    imageUri: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    toggleShowSelectorModal: PropTypes.func.isRequired,
    setNameQuestionAns: PropTypes.func.isRequired,
    setTimeQuestionAns: PropTypes.func.isRequired,
};

export default CreateProfile;
