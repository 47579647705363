import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HubComponent from './HubComponent';
import HubReducer from './redux/reducer';
import HubSagas from './redux/sagas';
import {
    getFeedsList,
    postLike,
    getAllComments,
    postComment,
    likeComment,
    deleteComment,
    uploadPost,
    getTags,
    postFollowUser,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    getFeedsListMore,
    reportPost,
    deletePost,
    getLikeDetails,
    updateShowBadge,
} from '../Feeds/redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('feedsReducer');
    const REFREDUCER = state.get('RefreshReducer');
    return {
        loading: REDUCER.isLoading,
        feedsData: REDUCER.feedsList,
        articleComments: REDUCER.articleComments,
        uploadingPost: REDUCER.uploadingPost,
        tags: REDUCER.tags,
        followedId: REDUCER.followedId,
        followedUsers: REDUCER.followedUsers,
        allFeedFetched: REDUCER.allFeedFetched,
        likeDetails: REDUCER.likeDetails,
        earnedBannerBadge: REDUCER.earnedBannerBadge,
        showBadge: REDUCER.showBadge,
        clientExpired: REFREDUCER.clientExpired,
    };
};

const dispatchToProps = {
    getFeedCards: getFeedsList,
    postLike,
    getAllComments,
    postComment,
    likeComment,
    deleteComment,
    uploadPost,
    getTags,
    postFollowUser,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    getFeedsListMore,
    reportPost,
    deletePost,
    getLikeDetails,
    updateShowBadge,
};

export { HubReducer, HubSagas };

export default withRouter(connect(stateToProps, dispatchToProps)(HubComponent));
