import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { pageAnalytics, storeTeamMembersList } from './containers/Feeds/redux/actions';

class AppContainer extends Component {
    // eslint-disable-next-line react/sort-comp
    UNSAFE_componentWillMount() {
        const {
            history: { listen },
            pageAnalytics,
            storeTeamMembersList,
        } = this.props;
        this.unlisten = listen(location => {
            const date = new Date();
            const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
            if (location.pathname.includes('/feed-details-article')) {
                storeTeamMembersList([]);
                sessionStorage.setItem('start_time', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
            }
            if (location.pathname.includes('/app') && sessionStorage.getItem('start_time')) {
                sessionStorage.setItem('end_time', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
                pageAnalytics({
                    page_tag: `feedList/${sessionStorage.interventionId}`,
                    device_id: localStorage.fcmToken,
                    start_time: sessionStorage.start_time,
                    end_time: sessionStorage.end_time,
                    user: userProfile,
                });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const { children } = this.props;
        return <div style={{ height: '100%' }}>{children}</div>;
    }
}

const dispatchToProps = { pageAnalytics, storeTeamMembersList };

AppContainer.propTypes = {
    pageAnalytics: PropTypes.func.isRequired,
    history: PropTypes.shape({
        listen: PropTypes.func.isRequired,
    }).isRequired,
    storeTeamMembersList: PropTypes.func.isRequired,
};

export default withRouter(connect(null, dispatchToProps)(AppContainer));
