import React, { Component } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

class Tabs extends Component {
    constructor(props) {
        super(props);
        const { selected } = this.props;
        this.state = {
            selected: selected || 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selected !== state.selected) {
            return {
                selected: props.selected,
            };
        }
        return null;
    }

    handleChange(index, tabname) {
        this.setState({ selected: index });
        // eslint-disable-next-line react/destructuring-assignment
        if (this.props.handleChange) {
            // eslint-disable-next-line react/destructuring-assignment
            this.props.handleChange(index, tabname);
        }
    }

    render() {
        const { children, disabled, classes } = this.props;
        return (
            <div>
                <ul className={`inline ${disabled ? 'disabled' : ''} ${classes || ''}`}>
                    {children.map((elem, index) => {
                        if (elem) {
                            // eslint-disable-next-line react/destructuring-assignment
                            const style = index === this.state.selected ? 'selected' : '';
                            if (elem.props.title) {
                                return (
                                    <li
                                        title={elem.props.showTitleOnHover ? elem.props.title : ''}
                                        className={style}
                                        key={index}
                                        onClick={this.handleChange.bind(this, index, elem.props.title)}
                                    >
                                        {elem.props.title}
                                    </li>
                                );
                            }
                            return <li />;
                        }
                        return null;
                    })}
                </ul>
                {/* eslint-disable-next-line react/destructuring-assignment */}
                <div className="tab">{children[this.state.selected]}</div>
            </div>
        );
    }
}

export default Tabs;

Tabs.defaultProps = {
    disabled: false,
};

Tabs.propTypes = {
    selected: PropTypes.number.isRequired,
    classes: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
};
