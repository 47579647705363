import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeComponent from './HomeComponent';
import HomeReducer from './redux/reducer';
import HomeSagas from './redux/sagas';
import { getHomePage, getRecommendedPrompts, getTodos, closeCard, getGoalsAction, getGoalsByCategoriesAction, resetGoalTab } from './redux/actions';
import { getUserMatrix } from '../UserMatrix/redux/actions';
import { uploadPost, getTags } from '../Feeds/redux/actions';
import { clientExpiredAction, resetClientExpiredAction } from '../../common/actions';

const stateToProps = state => {
    const REDUCER = state.get('HomeReducer');
    const FREDUCER = state.get('feedsReducer');
    const AuthenticationReducer = state.get('authentication').toJS();
    const REFREDUCER = state.get('RefreshReducer');
    return {
        dynamicCards: REDUCER.dynamicCards,
        isLoading: REDUCER.isLoading,
        recommendedPrompts: REDUCER.recommendedPrompts,
        todos: REDUCER.todos,
        goals: REDUCER.goals,
        uploadingPost: FREDUCER.uploadingPost,
        tags: FREDUCER.tags,
        firstTimeDataLoaded: REDUCER.firstTimeDataLoaded,
        hideSections: AuthenticationReducer.hide_sections,
        hideAddGoalBtn: AuthenticationReducer.hide_add_goal_button,
        refreshTodo: REFREDUCER.refreshTodo,
        refreshGoals: REFREDUCER.refreshGoals,
        clientExpired: REFREDUCER.clientExpired,
    };
};

const dispatchToProps = {
    getHomePage,
    getRecommendedPrompts,
    getTodos,
    closeCard,
    uploadPost,
    getTags,
    clientExpiredAction,
    resetClientExpiredAction,
    getGoalsAction,
    getGoalsByCategoriesAction,
    getUserMatrix,
    resetGoalTab,
};

export { HomeReducer, HomeSagas };

export default withRouter(connect(stateToProps, dispatchToProps)(HomeComponent));
