import React, { useRef, useEffect, useState, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import * as lottieConstants from './lottieConstants';

const propTypes = {
    id: PropTypes.string,
    options: PropTypes.object,
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    eventListeners: PropTypes.arrayOf(PropTypes.object),
    playSegments: PropTypes.object,
};

function Lottie(props) {
    const { id, options, eventListeners, className, playSegments } = props;
    const [lottieInstance, setLottieInstance] = useState(null);
    const [lottieConfig, setLottieConfig] = useState(null);
    const lottieContainer = useRef(null);
    const [isAnimationMissing, setAnimationMissing] = useState(false);
    // eslint-disable-next-line no-use-before-define
    const [lottieOptions, dispatch] = useReducer(setLottieOptions, {});

    function setLottieOptions(state, action) {
        // useReducer is required to compare incoming props with previous props
        // useEffect is rendered with every state change and compares changes in props by both reference and value
        // We need to update lottieOptions only when the options have changed by value so that we can can create a lottie instance only when there is a change in options
        const isOptionsChanged = !lottieConstants.checkObjectsEqualByValue(action.newOptions, state);
        if (isOptionsChanged) {
            if (!action.newOptions.path && !action.newOptions.animationData) {
                setAnimationMissing(true);
            } else {
                setAnimationMissing(false);
            }
            if ((state.path || state.animationData) && lottieInstance) {
                // Before returning options for new instance destroy the previous instance
                lottieInstance.destroy();
            }
            return action.newOptions;
        }
        return state;
    }

    useEffect(() => {
        dispatch({ newOptions: options });
    }, [options]);

    useEffect(() => {
        if (lottieOptions && (lottieOptions.animationData || lottieOptions.path)) {
            const newConfig = { ...lottieOptions, container: lottieContainer.current };
            if (newConfig.path) {
                // If path defined then ignore animationData provided
                delete newConfig.animationData;
            }
            setLottieConfig(newConfig);
        }
    }, [lottieOptions]);

    // Initialise animation
    useEffect(() => {
        if (lottieConfig && lottieConfig.container) {
            const newInstance = lottie.loadAnimation(lottieConfig);
            setLottieInstance(newInstance);
        }
    }, [lottieConfig]);

    // Remove listeners if any added when initialised
    // eslint-disable-next-line consistent-return
    const removeEventListeners = useCallback(() => {
        if (lottieInstance) {
            eventListeners.forEach(eventListener => {
                lottieInstance.removeEventListener(eventListener.eventName, eventListener.callback);
            });
            // Destroy lottieInstance once all listeners have been removed
            return () => lottieInstance.destroy();
        }
    }, [lottieInstance, eventListeners]);

    // Add listeners if any passed to props
    const addEventListeners = useCallback(() => {
        if (lottieInstance && eventListeners) {
            eventListeners.forEach(eventListener => {
                lottieInstance.addEventListener(eventListener.eventName, eventListener.callback);
            });
        }
    }, [eventListeners, lottieInstance]);

    useEffect(() => {
        addEventListeners();
        // Equivalent to component will unmount.
        return () => removeEventListeners();
    });

    // Play specific segments
    useEffect(() => {
        if (lottieInstance && playSegments && playSegments.segments && playSegments.forceFlag) {
            lottieInstance.playSegments(playSegments.segments, playSegments.forceFlag);
        }
    }, [playSegments, lottieInstance]);

    // Container for animation
    return (
        <div className={className}>
            <div id={id} ref={lottieContainer} className="inner_animation_container">
                {isAnimationMissing && <p>{lottieConstants.animationMissing}</p>}
            </div>
        </div>
    );
}

const defaultOptions = {
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: lottieConstants.examplePath, // the animation data
};

Lottie.propTypes = propTypes;
Lottie.defaultProps = {
    id: 'lottie-web-react',
    options: defaultOptions,
    eventListeners: [],
    playSegments: null,
};

export default Lottie;
