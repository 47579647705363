import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import PreviewProfileImage from './PreviewProfileImage';
import FollowUnfollow from './FollowUnfollow';
import LocalisationsStore from '../../common/LocalisationsStore';

function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
    };
}

export default function LikeDetailModal({
    open,
    handleClose,
    likeDetails,
    followedId,
    postFollowUser,
    postUnfollowUser,
    setFollowUnfollowInFeeds,
}) {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const profileId = userProfile && userProfile.id;
    const { localisationsAfterLogin } = LocalisationsStore;
    const { likes } = localisationsAfterLogin;
    const [modalStyle] = useState(getModalStyle);
    const body = (
        <div style={modalStyle} className="likeModalBody outline-none paper">
            <div className="close_icon_selector">
                <div className="likeHeading">{likes.charAt(0).toUpperCase() + likes.slice(1)}</div>
                <div
                    className="cursor-pointer outline-none"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={e => {
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    <Cross color="black" />
                </div>
            </div>
            {likeDetails.map(x => (
                <div className="likesUsers" key={x.liked_by}>
                    <div className="d-flex">
                        <div>
                            <svg height="0" width="0">
                                <clipPath id="myLikePath">
                                    <path
                                        fill="none"
                                        fillRule="evenodd"
                                        stroke="#979797"
                                        d="M15.542 2.489C6.796 6.793-1.244 20.263 2.092 33.137c2.69 10.38 25.388 26.49 37.996 12.243 18.18-20.543 5.045-31.448-1.641-37.024-5.023-4.189-14.158-10.171-22.905-5.867z"
                                    />
                                </clipPath>
                            </svg>
                            <div className="profile_image_adjusted">
                                <PreviewProfileImage
                                    src={x.profile_picture}
                                    alt="image"
                                    width={48}
                                    height={48}
                                    id="myLikePath"
                                />
                            </div>
                        </div>
                        <div className="nameText">{x.full_name}</div>
                    </div>

                    <div className="followUnfollowBtn">
                        {profileId !== x.liked_by ? (
                            <FollowUnfollow
                                isFollowing={x.is_following}
                                followedId={followedId}
                                postFollowUser={postFollowUser}
                                userId={x.liked_by}
                                postUnfollowUser={postUnfollowUser}
                                setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <Modal
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="likeModal"
        >
            {body}
        </Modal>
    );
}

LikeDetailModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedId: PropTypes.number.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
};
