import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Text from '../../../../components/Text';
import './index.scss';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import burst from './burst.svg';
import rArrow from '../../../../assets/rArrow.svg';
import lArrow from '../../../../assets/lArrow.svg';

export default function WeeklyStreaks({ streak }) {
    const handleLeftScroll = e => {
        e.stopPropagation();
        document.getElementById('DayCirclerow').scrollBy({ behaviour: 'smooth', left: -200 });
    };
    const handleRightScroll = e => {
        e.stopPropagation();
        document.getElementById('DayCirclerow').scrollBy({ behaviour: 'smooth', left: 200 });
    };

    return (
        <div className="streaks_wrapper">
            <div className="daysContaine">
                {streak.weekly_streak_data.length > 5 && (
                    <div className="scroller" onClick={handleLeftScroll}>
                        <img src={lArrow} alt="Left Arrow" />
                    </div>
                )}
                <div className="daysRow" id="DayCirclerow">
                    {streak.weekly_streak_data.map((obj, i) => (
                        <DayCircle day={obj} key={i} />
                    ))}

                    {!streak.weekly_streak_data.length && <div className="no-streak">No streaks available</div>}
                </div>
                {streak.weekly_streak_data.length > 5 && (
                    <div className="scroller" onClick={handleRightScroll}>
                        <img src={rArrow} alt="Right Arrow" />
                    </div>
                )}
            </div>
        </div>
    );
}

const DayCircle = ({ day }) => {
    const count = day.streak_count;
    const date = moment(day.date).format('MM/DD');
    return (
        <div className="circleContaine">
            <Text
                fontsize="1rem"
                fontcolor={day.type === 'current' ? fontColors.electricBlue : fontColors.nneutralGrey}
                fontfamily={fontFamilies.TheinhardtPanRegular}
                margin="0 0 2px 0"
                justifyContent="center"
                display="flex"
            >
                {date}
            </Text>
            <div className="count">
                {day.type === 'completed' && [
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        justifyContent="center"
                        display="flex"
                    >
                        {count}
                    </Text>,
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.75251 1.10597C0.743331 3.81338 -1.39232 14.1526 0.899728 22.2501C2.74763 28.7786 20.1281 37.5401 27.0883 27.3233C35.3418 15.2079 31.6817 7.33204 27.0883 3.82477C23.6379 1.1903 12.7617 -1.60143 6.75251 1.10597Z"
                            fill="#52AC79"
                        />
                    </svg>,
                ]}
                {day.type === 'star' && [
                    <img src={burst} alt="" height="25" width="25" style={{ position: 'absolute' }} />,
                    <div style={{ position: 'absolute', zIndex: 100, right: '54%', top: '10px' }}>
                        <Text
                            fontsize="0.8rem"
                            fontcolor={fontColors.errorRed}
                            fontfamily={fontFamilies.TheinhardtPanMedium}
                            justifyContent="center"
                            display="flex"
                        >
                            {count}
                        </Text>
                    </div>,
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.75251 1.10597C0.743331 3.81338 -1.39232 14.1526 0.899728 22.2501C2.74763 28.7786 20.1281 37.5401 27.0883 27.3233C35.3418 15.2079 31.6817 7.33204 27.0883 3.82477C23.6379 1.1903 12.7617 -1.60143 6.75251 1.10597Z"
                            fill="#eac64c"
                        />
                    </svg>,
                ]}
                {day.type === 'current' && [
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.electricBlue}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                    >
                        {count}
                    </Text>,
                    <svg width="32" height="32" viewBox="-1 -1 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.75251 1.10597C0.743331 3.81338 -1.39232 14.1526 0.899728 22.2501C2.74763 28.7786 20.1281 37.5401 27.0883 27.3233C35.3418 15.2079 31.6817 7.33204 27.0883 3.82477C23.6379 1.1903 12.7617 -1.60143 6.75251 1.10597Z"
                            stroke={fontColors.electricBlue}
                            strokeWidth="2"
                        />
                    </svg>,
                ]}

                {day.type === '' && [
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                    >
                        -
                    </Text>,
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.75251 1.10597C0.743331 3.81338 -1.39232 14.1526 0.899728 22.2501C2.74763 28.7786 20.1281 37.5401 27.0883 27.3233C35.3418 15.2079 31.6817 7.33204 27.0883 3.82477C23.6379 1.1903 12.7617 -1.60143 6.75251 1.10597Z"
                            fill={fontColors.nneutralGrey}
                        />
                    </svg>,
                ]}
            </div>
        </div>
    );
};

WeeklyStreaks.propTypes = {
    streak: PropTypes.object.isRequired,
};
DayCircle.propTypes = {
    day: PropTypes.number.isRequired,
};
