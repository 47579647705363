import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Feeds from './Feeds';
import notificationfeedsReducer from './redux/reducer';
import NotificationFeedsSaga from './redux/sagas';
import {
    uploadPost,
    getTags,
    getFeedsList,
    getFeedsListMore,
    toggleCreatePostModal,
    postLike,
    deletePost,
    getSavedPosts,
    postSavedPosts,
    getLikeDetails,
    postFollowUser,
    getAllComments,
    postComment,
    likeComment,
    deleteComment,
    reportPost,
    resetAllFeedFetched,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    fetchUsersList,
    sharePost,
    fetchMoreUsersList,
    removeBookMark,
    storeTeamMembersList,
} from './redux/actions';
import { getUserProgressData } from '../MyProgress/redux/actions';
import { logoutUser, getUserData } from '../UnAuthenticatedContent/actions';
import { getVcrbData, getNotes, addNotes } from '../Profile/actions';
import { getWeeklyQuestions } from '../WeeklyQuestions/redux/actions';
import { emptyNotificationList, resetPageNumber } from '../NotificationCenter/redux/actions';

const stateToProps = state => {
    const FeedsReducer = state.get('notificationfeedsReducer');
    return {
        isLoading: FeedsReducer.isLoading,
        isDeleting: FeedsReducer.isDeleting,
        feedsList: FeedsReducer.feedsList,
        pageIndex: FeedsReducer.pageIndex,
        allFeedFetched: FeedsReducer.allFeedFetched,
        isFeedLoading: FeedsReducer.isFeedLoading,
        tags: FeedsReducer.tags,
        uploadingPost: FeedsReducer.uploadingPost,
        imageUploaderModal: FeedsReducer.imageUploaderModal,
        isLiked: FeedsReducer.isLiked,
        likedId: FeedsReducer.likedId,
        isDeleted: FeedsReducer.isDeleted,
        savedPosts: FeedsReducer.savedPosts,
        savedPostId: FeedsReducer.savedPostId,
        followedId: FeedsReducer.followedId,
        likeDetails: FeedsReducer.likeDetails,
        isLikeLoading: FeedsReducer.isLikeLoading,
        articleComments: FeedsReducer.articleComments,
        followedUsers: FeedsReducer.followedUsers,
        vcrbDetails: state.get('profileReducer').vcrbDetails,
        notes: state.get('profileReducer').notes,
        userListForSharePost: FeedsReducer.userListForSharePost,
        allUsersFetched: FeedsReducer.allUsersFetched,
        isInitialFeedsLoading: FeedsReducer.isInitialFeedsLoading,
        teamMembersList: FeedsReducer.teamMembersList,
    };
};

export { NotificationFeedsSaga, notificationfeedsReducer };

const dispatchToProps = {
    uploadPost,
    getTags,
    getFeedsList,
    getFeedsListMore,
    postLike,
    toggleCreatePostModal,
    deletePost,
    getSavedPosts,
    postSavedPosts,
    getLikeDetails,
    postFollowUser,
    getAllComments,
    postComment,
    likeComment,
    deleteComment,
    reportPost,
    resetAllFeedFetched,
    getWeeklyQuestions,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    getVcrbData,
    getNotes,
    addNotes,
    fetchUsersList,
    sharePost,
    fetchMoreUsersList,
    removeBookMark,
    logoutUser,
    getUserProgressData,
    getUserData,
    emptyNotificationList,
    resetPageNumber,
    storeTeamMembersList,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Feeds));
