import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    htmlContent: null,
};

const weeklyReplayMailerContentReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_MAIL_ANSWER}_DONE`]: (state, { data }) => ({
        ...state,
        htmlContent: data,
        isLoading: false,
    }),
    [`${types.GET_MAIL_ANSWER}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
});

export default weeklyReplayMailerContentReducer;
