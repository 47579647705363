import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { FormControl, RadioGroup, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { isMobile } from '../../../utils/constants';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import Loader from '../../../components/Loader';
import UncheckedIcon from '../assets/uncheckedIcon.svg';
import CheckedIcon from '../assets/checkedIcon.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';

const UncheckedIconComponent = () => <img src={UncheckedIcon} alt="" />;
const CheckedIconComponent = () => <img src={CheckedIcon} alt="" />;

const ThemeOrangeRadio = withStyles({
    root: {
        color: '#d63f1c',
        '&$checked': {
            color: '#d63f1c',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

const ThemeOrangeCheckbox = withStyles({
    root: {
        color: '#d63f1c',
        '&$checked': {
            color: '#d63f1c',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

export default function FeedFiltersComponent({
    getFilters,
    filters,
    filterString,
    setFilterString,
    getFeedsList,
    selectedHashTag,
    selectedKey,
    setShowFeedFilters,
    storeFeedFilters,
    resetAllFeedFetched,
    setPage,
    loading,
}) {
    const [selectedRadioValue, setSelectedRadioValue] = useState('');
    const [checkboxState, setCheckboxState] = useState([]);

    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || { clearFilters: '', apply: '' };
    const { clearFilters, apply } = localisationsAfterLogin;

    const reverseDirectionLanguage = localStorage.getItem('languageIsRightToLeft') === 'true';

    useEffect(() => {
        if (!filters) {
            getFilters();
        }
    }, [getFilters, filters]);

    useEffect(() => {
        if (filters) {
            const { byContent, byChapters } = filters;
            const byContentOptions = byContent.options;

            const stringArray = filterString.split('&');
            const contentType = stringArray[0].split('content_type=')[1];
            const chapters = stringArray[1].split('chapter=')[1].split(',');
            const selectedContentType = byContentOptions.filter(option => option.id === Number(contentType))[0].id;
            setSelectedRadioValue(selectedContentType);

            if (checkboxState.length === 0 && byChapters.options.length > 0) {
                const byChaptersOptions = byChapters.options.map(option => {
                    chapters.forEach(chapter => {
                        if (Number(chapter) === option.id) {
                            // eslint-disable-next-line no-param-reassign
                            option.checked = true;
                            // eslint-disable-next-line no-param-reassign
                        }
                    });
                    return option;
                });
                setCheckboxState(byChaptersOptions);
            }
        }
    }, [filters, filterString, checkboxState]);

    const handleRadioChange = event => {
        setSelectedRadioValue(Number(event.target.value));
        const stringArray = filterString.split('&');
        stringArray[0] = `content_type=${Number(event.target.value)}`;
        const updatedFilterString = `${stringArray[0]}&${stringArray[1]}`;
        setFilterString(updatedFilterString);
    };

    const handleCheckboxChange = (event, index) => {
        const checkboxes = [...checkboxState];
        checkboxes[index].checked = event.target.checked;
        setCheckboxState(checkboxes);

        const selectedChapters = checkboxes.filter(chapter => chapter.checked).map(chapter => chapter.id);
        const stringArray = filterString.split('&');
        stringArray[1] = `chapter=${selectedChapters.join(',')}`;
        const updatedFilterString = `${stringArray[0]}&${stringArray[1]}`;
        setFilterString(updatedFilterString);
    };

    const resetFilters = () => {
        setFilterString('content_type=&chapter=');

        const byContentOptions = filters.byContent.options;
        setSelectedRadioValue(byContentOptions[0].id);
        const checkboxes = [...checkboxState];
        checkboxes.forEach(cech => {
            // eslint-disable-next-line no-param-reassign
            cech.checked = false;
        });
        setCheckboxState(checkboxes);
        storeFeedFilters(null);
        getFilters();
        resetAllFeedFetched();
        setPage(1);
        getFeedsList(1, selectedKey, selectedHashTag);
        if (isMobile) setShowFeedFilters(false);
    };

    const handleClose = e => {
        e.stopPropagation();
        setShowFeedFilters(false);
    };

    const handleApplyClick = () => {
        resetAllFeedFetched();
        setPage(1);
        getFeedsList(1, selectedKey, selectedHashTag);
        if (isMobile) setShowFeedFilters(false);
    };

    return (
        <>
            {loading && (
                <div className="feed_filter_container">
                    <Loader position="relative" background="transparent" />
                </div>
            )}
            {!loading && filters && (
                <div
                    className={
                        reverseDirectionLanguage ? 'feed_filter_container reverse_direction' : 'feed_filter_container'
                    }
                    data-test="filters-wrapper-attr"
                >
                    <div onClick={handleClose} role="button" tabIndex={0} className="back">
                        <Cross color="black" />
                    </div>
                    <div className="delete_filters_container">
                        <div>Filter your feed</div>
                    </div>
                    <div className="content_radio_btns_wrapper">
                        <div className="chapter_filter_heading">{filters.byContent.displayName}</div>
                        <div className="radio_btn">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="gender"
                                    name="content"
                                    value={selectedRadioValue}
                                    onChange={handleRadioChange}
                                    data-test="radio-test-attr-id"
                                    className="radio-test-attr-class"
                                >
                                    {filters.byContent.options.map(option => (
                                        <FormControlLabel
                                            key={`option-formcontrol-radio-${option.id}`}
                                            value={option.id}
                                            control={<ThemeOrangeRadio />}
                                            label={option.name}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="filter_by_chapter">
                        <div className="chapter_filter_heading">{filters.byChapters.displayName}</div>
                        <div className="chapters">
                            <FormGroup row data-test="chapter-checkbox-attr-test">
                                {checkboxState.map((option, index) => (
                                    <FormControlLabel
                                        key={`option-formcontrol-radio-${option.name}`}
                                        control={(
                                            <ThemeOrangeCheckbox
                                                checked={option.checked}
                                                onChange={event => handleCheckboxChange(event, index)}
                                                name={option.name}
                                                icon={<UncheckedIconComponent />}
                                                checkedIcon={<CheckedIconComponent />}
                                            />
                                        )}
                                        label={option.name}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                    </div>
                    <div className="apply_clear_btns">
                        <button type="submit" onClick={resetFilters}>
                            {clearFilters}
                        </button>
                        <button type="submit" onClick={handleApplyClick}>
                            {apply}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

const byChaptersShape = PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
});

FeedFiltersComponent.defaultProps = {
    filters: {},
};

FeedFiltersComponent.propTypes = {
    getFilters: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        byChapters: byChaptersShape.isRequired,
        byContent: byChaptersShape.isRequired,
    }),
    filterString: PropTypes.string.isRequired,
    setFilterString: PropTypes.func.isRequired,
    getFeedsList: PropTypes.func.isRequired,
    selectedHashTag: PropTypes.string.isRequired,
    selectedKey: PropTypes.number.isRequired,
    setShowFeedFilters: PropTypes.func.isRequired,
    storeFeedFilters: PropTypes.func.isRequired,
    resetAllFeedFetched: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
