import { connect } from 'react-redux';
import PreAdditionalQuestionC from './PreAdditionalQuestionComponent';
import weeklyQuestionsReducer from '../WeeklyQuestions/redux/reducer';
import preAdditionalQuestionsSaga from './redux/sagas';
import { getWeeklyQuestions } from '../WeeklyQuestions/redux/actions';
import {
    setSeenPercentage,
    closeWeeklyQuestionModal,
    storeAnsweredQuestionResponse,
    postAnswer,
    userPreAdditionalQuestionComment,
} from './redux/actions';
import { getNextApiInSequence } from '../../common/actions';

const stateToProps = state => {
    const WeeklyQuestionsReducer = state.get('weeklyQuestionsReducer');
    return {
        open: WeeklyQuestionsReducer.openPredAdditionalQuestion,
        weeklyQuestionsData: WeeklyQuestionsReducer.preAdditionalQuestionsData,
        originalData: WeeklyQuestionsReducer.originalData,
        questionResponseFromDb: WeeklyQuestionsReducer.preAdditionalquestionResponseFromDb,
        dontShowWeeklyQuestionsFromPreAdditionalQuestion:
            WeeklyQuestionsReducer.dontShowWeeklyQuestionsFromPreAdditionalQuestion,
    };
};

export { preAdditionalQuestionsSaga, weeklyQuestionsReducer };

const dispatchToProps = {
    postAnswer,
    setSeenPercentage,
    storeAnsweredQuestionResponse,
    closeWeeklyQuestionModal,
    getWeeklyQuestions,
    userPreAdditionalQuestionComment,
    getNextApiInSequence,
};

export default connect(stateToProps, dispatchToProps)(PreAdditionalQuestionC);
