import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import InspireTopIcon from '../../assets/InspireTopIcon.svg';
import particleBg from '../../assets/particleBg.svg';
import InputField from '../../components/InputField';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import { requestSignUp, resetScreen } from '../UnAuthenticatedContent/actions';
import { selectLoading, selectSignUpError } from '../UnAuthenticatedContent/selector';
import Loader from '../../components/Loader';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';
import { isValidPattern } from '../../utils/functions';
import { EMAIL_PATTERN } from '../../utils/constants';
import WarningModal from '../../components/WarningModal';
import crossIcon from '../../assets/cross.svg';

function SignInComponent({ history, requestSignUp, isLoading, error,resetScreen}) {
    const { localisationsBeforeLogin } = LocalisationsStore;
    const {
        Inspire,
        inspireDescriptionLogin,
        WhatsYourEmail,
        LoginEmailText,
        continueAsGuest,
        BackToSignIn,
        Continue,
        takeMeToSignin,
        existingUser,
        PleaseEnterEmail,
        EmailError,
        errorOccurred,
        tryAgainLater,
        emailAlreadyRegister,
    } = localisationsBeforeLogin;
    const [email, setEmail] = useState('');
    const [feError, setFeError] = useState(
        { emailValidated: true, emailError: '' },
    );
    const [beError, setBeError] = useState(false);

    const handleChange = event => {
        setEmail(event.target.value);
    };

    const handleRegisterClick = () => {
        history.push('/');
    };

    const handleContinueAsAGuestClick = () => {
        history.push('/guest');
    };

    const handleOnClick = () => {
        setBeError(false);
        if (email === '') {
            setFeError({ emailValidated: false, emailError: PleaseEnterEmail });
        } else if (!isValidPattern(email, EMAIL_PATTERN)) {
            setFeError({ emailValidated: false, emailError: EmailError });
        } else {
            setFeError({ emailValidated: true, emailError: ''});
            const callback = () => {
                history.push({
                    pathname: '/checkemail',
                    state: { msg: 'Please check your email for instructions to continue' },
                });
            };
            requestSignUp({ username: email }, () => callback());
        }
    };
    
    const handleCross = () => {
        resetScreen();
        setBeError(true);
    }
    
    const goToSignIn = () => {
        resetScreen();
        history.push('/');
    }
    

    const renderEmailError = () => {
        let errMsg = '';
        if (!feError.emailValidated) {
            errMsg = feError.emailError;
        }
        return (
            <Text
                fontsize="0.8rem"
                fontcolor={fontColors.errorRed}
                fontfamily={fontFamilies.TheinhardtPanRegular}
                margin="0 0 1.25rem 0"
            >
                {errMsg}
            </Text>
        );
    };

    return (
        <div className="unauth_content_container_wrapper_sign_in">
            {error.message && !beError && (
                <WarningModal>
                    <div className="login-popup-error_popup">
                    <div className="login-popup-cross" onClick={handleCross}><img src={crossIcon} alt='cross icon'/></div>
                    <div className="login-popup-title">{error.status === 400 ? existingUser : errorOccurred }</div>
                    <div className="login-popup-description">{error.status === 400 ? emailAlreadyRegister : error.message}</div>
                    <div className="login-popup-cta" onClick={error.status === 400 ? goToSignIn : handleCross}>{error.status === 400 ? takeMeToSignin: tryAgainLater}</div>
                    </div>
                </WarningModal>
            )}
            {isLoading && <Loader position="absolute" showBackground />}
            <img src={particleBg} alt="particles" className="particleBg" particleBg />
            <div className="unauth_content_container">
                <img src={InspireTopIcon} alt="inspire_logo" className="biggerInspireTopIcon" />
                <div className="central_content">
                    <div className="header_info">
                        <Text
                            fontsize="2.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.625rem 0"
                        >
                            {Inspire}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                        >
                            {inspireDescriptionLogin}
                        </Text>
                    </div>
                    <div className="main_section">
                        <div className="input_element">
                            <InputField
                                label={WhatsYourEmail}
                                type="text"
                                value={email}
                                onChange={handleChange}
                                fontSize="1rem"
                                fontFamily={fontFamilies.TheinhardtPanMedium}
                                fontColor={fontColors.siteGrey}
                                placeholder={LoginEmailText}
                                borderColor={email.length > 0 && !feError.emailValidated ? fontColors.errorRed : fontColors.borderGrey}
                            />
                        </div>
                        {email.length > 0 && ( <div className="render_error">{renderEmailError()}</div> )}
                         {email.length > 0 && ( 
                            <div className="action_container">
                                <Actionbutton
                                    fontSize="1.125rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.siteWhite}
                                    bgColor={fontColors.electricBlue}
                                    disabled={false}
                                    onClick={handleOnClick}
                                >
                                    {Continue}
                                </Actionbutton>
                            </div>
                         )}
                    </div>
                </div>
                <div className="footer_container">
                    <Text
                        fontsize="0.8rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        onClick={handleContinueAsAGuestClick}
                        cursor="pointer"
                        decoration="underline"
                    >
                        {continueAsGuest}
                    </Text>
                    <Text
                        fontsize="0.8rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        cursor="pointer"
                        onClick={handleRegisterClick}
                        decoration="underline"
                    >
                        {BackToSignIn}
                    </Text>
                </div>
            </div>
        </div>
    );
}

SignInComponent.propTypes = {
    history: PropTypes.object.isRequired,
    requestSignUp: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
        status: PropTypes.number,
    }).isRequired,
    resetScreen: PropTypes.func.isRequired,
};

const dispatchToProps = { requestSignUp, resetScreen };

const stateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    error: selectSignUpError(),
});

export default withRouter(connect(stateToProps, dispatchToProps)(SignInComponent));
