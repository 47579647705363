import React from 'react';
import PropTypes from 'prop-types';

const Tab = props => {
    const { children, disabled } = props;
    return <div className="tab-content">{disabled ? <div className="disabled-tab">You have not completed any practice yet.</div> : children}</div>;
};

export default Tab;

Tab.defaultProps = {
    disabled: false,
};

Tab.propTypes = {
    disabled: PropTypes.bool,
};
