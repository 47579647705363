import types from '../../Feeds/redux/types';

export const getFilters = () => ({
    type: `${types.GET_FEED_FILTERS}_PENDING`,
});

export const storeFeedFilters = data => ({
    type: `${types.GET_FEED_FILTERS}_DONE`,
    data,
});

export const setFilterString = filterString => ({
    type: types.SET_FILTER_STRING,
    filterString,
});
