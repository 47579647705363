import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { MenuItem, Select } from '@material-ui/core';
import cretePostCross from '../../../assets/cretePostCross.svg';
import ImageSelectorContainer from '../../Profile/ImageSelectorContainer';
import PreviewImage from '../../../components/PreviewImage';
import dummy from '../../Profile/assets/dummy.png';
// import LocalisationsStore from '../../../common/LocalisationsStore';
import { fontColors, fontFamilies } from '../../../common/fontMap';
import Text from '../../../components/Text';
import InputField from '../../../components/InputField';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function PreferencesModalComponent({
    showPreferences,
    setShowPreferencesModal,
    postImage,
    getQuestionsAction,
    questions,
    isLoading,
    popUpOpen,
    toggleShowSelectorModal,
    postAnswers,
    resetPassword,
    setShowCheckEmailModal,
    getUserProgressData,
    setIsLoading,
}) {
    const [nameQuestionResponse, setNameQuestionAns] = useState(
        localStorage.userProfile ? JSON.parse(localStorage.userProfile).preferred_name : '',
    );
    const [timeQuestionResponse, setTimeQuestionAns] = useState('');
    const [imageUri, setImageUri] = useState('');
    let email;
    let updatesTime;
    const profilePicture = localStorage.getItem('userDetails');

    // const { localisationsAfterLogin } = LocalisationsStore;
    // const { emailHeader } = localisationsAfterLogin;

    if (localStorage.userProfile) {
        // eslint-disable-next-line prefer-destructuring
        email = JSON.parse(localStorage.userProfile).email;
        updatesTime = JSON.parse(localStorage.userProfile).notification_time;
    }

    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        yourProfileTitle,
        emailAddress,
        password,
        change_password,
        save,
        profile_and_preferences,
    } = localisationsAfterLogin;

    // eslint-disable-next-line no-unused-vars
    let nameQuestion;
    let timeQuestion;

    if (questions && questions.length > 0) {
        [nameQuestion] = questions.filter(question => question.question_detail.type === 'text');
        [timeQuestion] = questions.filter(question => question.question_detail.type === 'objective');
    }

    useEffect(() => {
        if (showPreferences) {
            getQuestionsAction();
        }
    }, [showPreferences, getQuestionsAction]);

    useEffect(() => {
        if (showPreferences) {
            setNameQuestionAns(localStorage.getItem('preferred_name') || '');
        }
    }, [showPreferences]);

    useEffect(() => {}, []);

    const closePreferences = () => {
        setShowPreferencesModal(false);
    };

    const setNameResponse = e => {
        setNameQuestionAns(e.target.value);
    };

    const setObjectiveResponse = e => {
        setTimeQuestionAns(e.target.value);
    };

    const _renderImageUrl = () => {
        if (imageUri) return imageUri;
        if (profilePicture !== 'null' && profilePicture) return profilePicture;
        return dummy;
    };

    const changeProfile = () => {
        if (setIsLoading) {
            setIsLoading(true);
        }
        const trimmedPreferredName = nameQuestionResponse?.trim();
        if (trimmedPreferredName.length > 0) {
            // postAnswers({
            //     ...nameQuestion,
            //     response: nameQuestionResponse,
            // });
            postImage({ preferred_name: trimmedPreferredName });
            localStorage.setItem('full_name', trimmedPreferredName);
        }
        if (timeQuestionResponse.length > 0) {
            postAnswers({
                ...timeQuestion,
                response: timeQuestionResponse,
            });
        }
        setShowPreferencesModal(false);
        setTimeout(() => {
            if (setIsLoading) {
                setIsLoading(false);
            } else {
                getUserProgressData();
            }
        }, 1000);
    };

    const path = 'M32.7,191c12.86,47.37,133.78,111,182.21,36.81,57.42-87.91,32-145,0-170.5-24-19.12-99.68-39.38-141.49-19.74S16.76,132.25,32.7,191Z';
    const transform = 'translate(-19.35 -22.17)';
    const adjustedPath = 'M53.91,181.86c10.26,37.81,106.77,88.55,145.43,29.38,45.83-70.17,25.51-115.78,0-136.09C180.18,59.89,119.78,43.72,86.41,59.4S41.19,135,53.91,181.86Z';
    const adjustedTransform = 'translate(-48.66 -52.74)';

    const _isS3DummyImage = () => profilePicture === 'https://s3.amazonaws.com/ohi-npn-upload-image/mpc/standard/user_profile/default.png';

    const reverseDirectionLanguage = localStorage.getItem('languageIsRightToLeft') === 'true';

    const handlePasswordresetClick = () => {
        resetPassword({ username: email });
        setShowPreferencesModal(false);
        setShowCheckEmailModal(true);
    };

    return (
        <Modal open={showPreferences} className="preferences_outer">
            <div className={reverseDirectionLanguage ? 'preferences_inner reverse_direction' : 'preferences_inner'}>
                <div className={reverseDirectionLanguage ? 'modal_heading reverse' : 'modal_heading'}>
                    <div className="cretePostCross pointer">
                        <img src={cretePostCross} alt="cross" onClick={closePreferences} />
                    </div>
                    <Text
                        fontsize="1.25rem"
                        fontfamily={fontFamilies.playfairBold}
                        fontcolor={fontColors.textBlack}
                        margin="0 0 0 0.8rem"
                    >
                        {profile_and_preferences}
                    </Text>
                </div>
                <div className="main_content profileWrapper">
                    <div
                        className={
                            reverseDirectionLanguage
                                ? 'profile_picture_holder_container reverse'
                                : 'profile_picture_holder_container'
                        }
                        id="previewCimgg"
                    >
                        <svg height="0" width="0">
                            <clipPath id="myPath">
                                <path
                                    xmlns="http://www.w3.org/2000/svg"
                                    d={
                                        imageUri || (profilePicture !== 'null' && profilePicture && !_isS3DummyImage())
                                            ? adjustedPath
                                            : path
                                    }
                                    transform={
                                        imageUri || (profilePicture !== 'null' && profilePicture && !_isS3DummyImage())
                                            ? adjustedTransform
                                            : transform
                                    }
                                    fill="none"
                                    stroke="#231f20"
                                    strokeMiterlimit="10"
                                    strokeWidth="0.5"
                                />
                            </clipPath>
                        </svg>
                        {!imageUri && profilePicture === 'null' && !profilePicture ? (
                            <div className="preview_image_adjusted">
                                <PreviewImage
                                    src={_renderImageUrl()}
                                    alt=""
                                    width={imageUri || (profilePicture !== 'null' && profilePicture) ? 238 : 142}
                                    height={imageUri || (profilePicture !== 'null' && profilePicture) ? 'auto' : 163}
                                    margin="20px"
                                />
                                <div className="message_label">Add or change photo</div>
                            </div>
                        ) : (
                            <PreviewImage
                                src={_renderImageUrl()}
                                alt=""
                                width={imageUri || (profilePicture !== 'null' && profilePicture) ? 238 : 142}
                                height={imageUri || (profilePicture !== 'null' && profilePicture) ? 'auto' : 163}
                                margin="20px"
                            />
                        )}
                        <ImageSelectorContainer
                            imageUri={imageUri}
                            setImageUri={setImageUri}
                            postImage={postImage}
                            questions={questions}
                            isLoading={isLoading}
                            popUpOpen={popUpOpen}
                            toggleShowSelectorModal={toggleShowSelectorModal}
                        />
                    </div>
                    <div className="questions_container">
                        <Fragment>
                            {questions.map((question, i) => {
                                if (question.question_detail.type === 'text') {
                                    return (
                                        <div className="question" key={question.id}>
                                            <div className="question_text">{yourProfileTitle}</div>
                                            <InputField
                                                label=""
                                                type={i === 1 ? 'password' : 'text'}
                                                value={nameQuestionResponse}
                                                onChange={setNameResponse}
                                                fontSize="1rem"
                                                fontFamily={fontFamilies.TheinhardtPanMedium}
                                                fontColor={fontColors.siteGrey}
                                                placeholder="Name"
                                                borderColor={fontColors.borderGrey}
                                                id="profile_name"
                                                maxLength={15}
                                            />
                                        </div>
                                    );
                                }
                                if (question.question_detail.type === 'objective') {
                                    return (
                                        <div className="question" key={question.id}>
                                            <div className="question_text">{question.question_detail.question}</div>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select_timee"
                                                value={timeQuestionResponse || updatesTime}
                                                onChange={setObjectiveResponse}
                                            >
                                                {question.question_detail.options.map(option => (
                                                    <MenuItem value={option.value} className="options">
                                                        {option.text}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            <div className="question">
                                <div className="question_text">{emailAddress}</div>
                                <div className="email_display">{email}</div>
                            </div>
                            <div className="question">
                                <div className="question_text">{password}</div>
                                <InputField
                                    label=""
                                    type="password"
                                    value="test@123"
                                    onChange={() => {}}
                                    fontSize="1rem"
                                    fontFamily={fontFamilies.TheinhardtPanMedium}
                                    fontColor={fontColors.siteGrey}
                                    placeholder="Password"
                                    borderColor={fontColors.borderGrey}
                                    id="password"
                                />
                            </div>
                            <div className="action_container">
                                <Text
                                    fontsize="0.8rem"
                                    fontcolor={fontColors.textBlack}
                                    fontfamily={fontFamilies.TheinhardtPanMedium}
                                    decoration="underline"
                                    cursor="pointer"
                                    onClick={handlePasswordresetClick}
                                >
                                    {change_password}
                                </Text>
                            </div>
                        </Fragment>
                    </div>
                </div>
                <div
                    className={reverseDirectionLanguage ? 'done_btn reverse' : 'done_btn'}
                    onClick={!nameQuestionResponse?.trim() ? null : changeProfile}
                    style={!nameQuestionResponse?.trim() ? {
                        opacity: 0.5,
                    } : {}}
                >
                    <span>{save}</span>
                </div>
            </div>
        </Modal>
    );
}

PreferencesModalComponent.defaultProps = {
    setIsLoading: null,
};

PreferencesModalComponent.propTypes = {
    showPreferences: PropTypes.bool.isRequired,
    setShowPreferencesModal: PropTypes.func.isRequired,
    postImage: PropTypes.func.isRequired,
    getUserProgressData: PropTypes.func.isRequired,
    getQuestionsAction: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    toggleShowSelectorModal: PropTypes.func.isRequired,
    postAnswers: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    setShowCheckEmailModal: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func,
};
