import React, { useEffect, useState } from 'react';
import './index.scss';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getArticle } from '../Feeds/redux/apis';
import FeedCard from '../HubContainer/FeedCard';
import Loader from '../../components/Loader';
import LocalisationsStore from '../../common/LocalisationsStore';

const SingleNotificationView = ({
    history,
    match,
    postLike,
    likeComment,
    getAllComments,
    postComment,
    deleteComment,
    articleComments,
    followedUsers,
    postFollowUser,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    followedId,
    reportPost,
    deletePost,
    likeDetails,
    getLikeDetails,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { post } = localisationsAfterLogin;
    const [feed, setFeed] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const getArticleDetailsApi = () => {
        setLoading(true);
        getArticle({ id: match.params.id })
            .then(response => {
                setLoading(false);
                setFeed({ ...response.data.data, showComments: true });
                // eslint-disable-next-line no-unused-vars
            })
            .catch(() => {
                history.push('/app/hub');
            });
    };

    useEffect(() => {
        if (!feed) {
            getArticleDetailsApi();
        }
    });

    const deleteTheComment = (id, articleId, replycount) => {
        deleteComment(id, articleId, replycount);
        setTimeout(() => {
            getArticleDetailsApi();
        }, 2000);
    };

    const deleteThePost = ({ articleId }) => {
        setFeed(null);
        deletePost({ articleId });
        setTimeout(() => {
            history.push('/app/hub');
        }, 2000);
    };

    const postTheComment = (id, commentValue, selectedUsers, parrentid) => {
        setLoading(true);
        postComment(id, commentValue, selectedUsers, parrentid);
        setTimeout(() => {
            getArticleDetailsApi();
        }, 2000);
    };

    const followUser = ({ user: profileId, following_to: userId }) => {
        setFeed({ ...feed, is_following: true });
        postFollowUser({ user: profileId, following_to: userId });
    };

    const unfollowUser = (userId, userType) => {
        setFeed({ ...feed, is_following: false });
        postUnfollowUser(userId, userType);
    };
    return (
        <div className="single-notification">
            {isLoading && <Loader position="fixed" showBackground />}
            <div className="single-notification__back">
                <div
                    className="arrow-icon"
                    onClick={() => {
                        history.push('/app/hub');
                    }}
                >
                    <ArrowBack />
                </div>
                <div>{post}</div>
            </div>
            <div className="single-notification__content">
                {feed && (
                    <FeedCard
                        index={0}
                        feed={feed}
                        profilePicture={(value => value)(feed.profile_picture)}
                        postLike={postLike}
                        likeComment={likeComment}
                        getAllComments={getAllComments}
                        postComment={postTheComment}
                        deleteComment={deleteTheComment}
                        articleComments={articleComments}
                        followedUsers={followedUsers}
                        postFollowUser={followUser}
                        postUnfollowUser={unfollowUser}
                        getUsersFollowed={getUsersFollowed}
                        setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                        followedId={followedId}
                        reportPost={reportPost}
                        deletePost={deleteThePost}
                        likeDetails={likeDetails}
                        getLikeDetails={getLikeDetails}
                    />
                )}
            </div>
        </div>
    );
};

export default SingleNotificationView;

SingleNotificationView.propTypes = {
    postLike: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    getAllComments: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    articleComments: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    followedUsers: PropTypes.array.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    followedId: PropTypes.number.isRequired,
    reportPost: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
};
