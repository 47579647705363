import React, { useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import WarningModal from '../../../components/WarningModal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import leftIcon from '../../../assets/leftIcon.svg';
import { getGoalApi } from '../../HomeContainer/redux/apis';
import ActiveCard from '../ActiveCard';
import Loader from '../../../components/Loader';
import { pageAnalytics } from '../../../utils/functions';


const GoalDetails = ({
    setView,
    goalId,
    fromNotifications = false,
    refreshGoals,
    deleteGoalAction,
    setShowGoalDetails,
}) => {
    const [goal, setGoal] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const fetchGoalDetail = () => {
        getGoalApi(goalId)
            .then(response => {
                setGoal({ ...response.data.data });
                setIsLoading(false);
            })
            .catch(() => {
                setView(0);
                setIsLoading(false);
            });
    };


    const deleteCallback = () => {
        setView(0);
        setShowGoalDetails && setShowGoalDetails(false);
        refreshGoals();
    };

    useEffect(() => {
        pageAnalytics.initialise(`goalDetail/${goalId}`);
        return () => {
            pageAnalytics.complete(`goalDetail/${goalId}`);
        };
    }, [goalId]);

    useEffect(() => {
        fetchGoalDetail();
        // eslint-disable-next-line
    }, []);

    return (
        <WarningModal classNames="goalDetailsModal">
            <div className="goal-detail-view">
                <div className="header">
                    <div className="title">

                        <div
                            className="back-icon"
                            onClick={() => {
                                setView(0);
                                setShowGoalDetails && setShowGoalDetails(false);
                                //if (fromNotifications) {
                                    refreshGoals();
                                //}
                            }}
                        >
                            <img src={leftIcon} alt="Back"/>
                        </div>
                        <div>Goal Details</div>
                    </div>
                    <div
                        className="cross"
                        onClick={() => {
                            setView(0);
                            setShowGoalDetails && setShowGoalDetails(false);
                           // if (fromNotifications) {
                                refreshGoals();
                            //}
                        }}
                    >
                        <Cross color="black"/>
                    </div>
                </div>
                {goal.id && (
                    <div className="goal-detail-content">
                        <ActiveCard
                            key={goal.id}
                            goal={goal}
                            callBack={fetchGoalDetail}
                            editCallBack={fetchGoalDetail}
                            fromScreen="goalDetails"
                            deleteCallBack={deleteCallback}
                            deleteGoalAction={deleteGoalAction}
                        />
                    </div>
                )
                }
            </div>
            {isLoading && <Loader showBackground position="absolute" />}
        </WarningModal>
    );
};


GoalDetails.propTypes = {
    setView: PropTypes.func.isRequired,
    goalId: PropTypes.object.isRequired,
    refreshGoals: PropTypes.func.isRequired,
    deleteGoalAction: PropTypes.func.isRequired,
};

export default GoalDetails;
