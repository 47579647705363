import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getLastUpdatedAt } from '../../../utils/functions';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function ProfileName({
    name,
    text,
    commentLikedBy,
    createdAt,
    replies,
    showReplies,
    index,
    commenColor,
    replyToThisComment,
    parentCommentId,
    articleId,
    articleindex,
    thisCommentId,
    isParent,
    getlikedBy,
    setReplyComment,
    replyComment,
    setReplyOpen,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { reply, LIKES, LIKE } = localisationsAfterLogin;

    const urlify = text => {
        // eslint-disable-next-line no-useless-escape
        const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const stringList = text.split(' ');
        return stringList
            .map(element => element.replace(urlRegex, url => `<a href="http://${url}">${url}</a>`))
            .join(' ');
    };

    const handleUrlClick = e => {
        e.preventDefault();
        if (e.target.href) {
            window.open(`${e.target.href}`, '_blank');
        }
    };

    const getLikes = e => {
        if (commentLikedBy > 0) {
            getlikedBy(e);
        }
    };

    return (
        <div className="commenter_text_wrapper">
            <div>
                <div className={`commenter_text ${commenColor}`}>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{name.toUpperCase()}</div>
                    <div className="commnent_text_wrapper">
                        {text.split(' ').map(item => {
                            const isAUser = item.match(/[\u200b]/g);
                            if (isAUser) {
                                return <span className="is_a_user">{` ${item} `}</span>;
                            }
                            // eslint-disable-next-line react/no-danger
                            return (
                                <span
                                    className="comment_inner_HTML"
                                    onClick={handleUrlClick}
                                    dangerouslySetInnerHTML={{ __html: urlify(item) }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="like_and_reply">
                <span onClick={getLikes} data-commentid={thisCommentId} className="like_no">
                    {`${commentLikedBy} ${commentLikedBy === 1 ? LIKE : LIKES}`}
                </span>
                {isParent && (
                    <Fragment>
                        <span className="separator" />
                        <span
                            role="none"
                            className="reply_btn"
                            data-articleindex={articleindex}
                            data-parentcommentid={isParent ? thisCommentId : parentCommentId}
                            data-articleid={articleId}
                            onClick={event => {
                                setReplyComment(!replyComment);
                                replyToThisComment(event);
                            }}
                        >
                            {reply.charAt(0).toUpperCase() + reply.slice(1)}
                        </span>
                    </Fragment>
                )}
                <span className="separator" />
                {replies > 0 && [
                    <span
                        role="none"
                        className="like_no"
                        data-commentindex={index}
                        onClick={event => {
                            setReplyOpen(true);
                            showReplies(event);
                        }}
                    >
                        {replies === 1 ? `${replies} ${reply}` : `${replies} Replies`}
                    </span>,
                    <span className="separator" />,
                ]}
                <span className="created_at">{getLastUpdatedAt(createdAt)}</span>
            </div>
        </div>
    );
}

ProfileName.defaultProps = {
    // parentCommentId: null,
};

ProfileName.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    commentLikedBy: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    isParent: PropTypes.any.isRequired,
    replies: PropTypes.number.isRequired,
    showReplies: PropTypes.func.isRequired,
    setReplyComment: PropTypes.func.isRequired,
    setReplyOpen: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    commenColor: PropTypes.string.isRequired,
    replyToThisComment: PropTypes.func.isRequired,
    parentCommentId: PropTypes.number.isRequired,
    articleId: PropTypes.number.isRequired,
    articleindex: PropTypes.number.isRequired,
    thisCommentId: PropTypes.number.isRequired,
    replyComment: PropTypes.bool.isRequired,
    getlikedBy: PropTypes.func.isRequired,
};
