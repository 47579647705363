import { connect } from 'react-redux';
import FeedFiltersComponent from './FeedFiltersComponent';
import FeedFiltersSaga from './redux/sagas';
import { getFilters, setFilterString, storeFeedFilters } from './redux/actions';
import { getFeedsList, resetAllFeedFetched } from '../Feeds/redux/actions';

const stateToProps = state => {
    const FeedsReducer = state.get('feedsReducer');
    return {
        filters: FeedsReducer.filters,
        filterString: FeedsReducer.filterString,
        loading: FeedsReducer.filtersAreLoading,
    };
};

export { FeedFiltersSaga };

const dispatchToProps = {
    getFilters,
    setFilterString,
    getFeedsList,
    storeFeedFilters,
    resetAllFeedFetched,
};

export default connect(stateToProps, dispatchToProps)(FeedFiltersComponent);
