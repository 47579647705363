import React, { useState } from 'react'
import './index.scss'
import redirectionIcon from '../../assets/redirection.svg'
import { withRouter } from 'react-router-dom'
import PromptArticle from '../PromptArticle/index.container'
import CompletedBadge from '../../components/CompletedBadge'
import LocalisationsStore from '../../common/LocalisationsStore'
const WorkOnProgress = ({ data, history, callback }) => {
    const { localisationsAfterLogin } = LocalisationsStore
    const { you_are_working_on, continue_practicing } = localisationsAfterLogin
    const [showPrompt, setShowPrompt] = useState({ show: false })

    const handleClosePromptArticle = ({ show }) => {
        setShowPrompt({ show })
        callback()
    }

    const redirect = () => {
        setShowPrompt({ show: true })
    }
    return data.is_completed ? (
        <div className="working-on">
            <div className="header">{you_are_working_on}</div>
            <div className="work-on-progressCompleted">
                <CompletedBadge />
            </div>
        </div>
    ) : (
        <div className="working-on">
            <div className="header">{you_are_working_on}</div>
            <div className="work-on-progress">
                <div className="image">
                    <img src={data.image_url} alt="Working Badge" />
                </div>
                <div className="detail">
                    <div className="title">{data.practice_name.toUpperCase()}</div>
                    <div className="subtitle">{data.folder_name}</div>
                    <div className="redirection" onClick={() => redirect()}>
                        <div className="label">{continue_practicing}</div>
                        <img src={redirectionIcon} alt="redirect" />
                    </div>
                </div>
            </div>
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={data.article_id}
                    tag={`recommendedPrompts/${data.article_id}`}
                    history={history}
                />
            )}
        </div>
    )
}

export default withRouter(WorkOnProgress)
