import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    resetScreen,
    getUserData,
    getChecksum,
    oauth,
    getAvailableLanguages,
    postSelectedLanguage,
    getToken,
    getTokenFailed,
} from './actions';
import UnAuthContainer from './UnAuthContent';
import authentication from './reducer';
import unAuthSagas from './sagas';

import {
    selectLoading,
    selectError,
    selectChangeSignUp,
    selectSuccessScreen,
    selectUserData,
    selectInitialLocalisations,
    selectLanguages,
    selectDomainValid,
    selectClientExpire,
} from './selector';

const stateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    error: selectError(),
    changeSignUp: selectChangeSignUp(),
    successScreen: selectSuccessScreen(),
    userData: selectUserData(),
    initialLocalisations: selectInitialLocalisations(),
    availableLanguages: selectLanguages(),
    domainValid: selectDomainValid(),
    clientExpired: selectClientExpire(),
});

const dispatchToProps = {
    resetScreen,
    getUserData,
    getChecksum,
    oauth,
    getAvailableLanguages,
    postSelectedLanguage,
    getToken,
    getTokenFailed,
};

export { unAuthSagas, authentication };

export default connect(stateToProps, dispatchToProps)(UnAuthContainer);
