import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../components/Text';
import ProgressBar from '../../components/ProgressBar';
import journeyProgressIcon from '../../assets/journey-progress.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import { fontColors, fontFamilies } from '../../common/fontMap';

function JourneyProgressCard({ cardDetails }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { journey_progress, journey_completed } = localisationsAfterLogin;

    return (
        <div className="journey-progress-card">
            <div className="card-header-container">
                <Text
                    fontsize="16px"
                    fontcolor={fontColors.electricBlue200}
                    fontfamily={fontFamilies.MckinseySansRegular}
                    fontweight={400}
                    lineHeight="16px"
                    margin="0 16px 24px 0"
                >
                    {journey_progress}
                </Text>
                <div className="img-container">
                    <img src={journeyProgressIcon} alt="journey-progress" />
                </div>
            </div>
            <div className="percentage-text-container">
                <Text
                    fontsize="45px"
                    fontcolor={fontColors.siteWhite}
                    fontfamily={fontFamilies.MckinseySansRegular}
                    fontweight={400}
                    lineHeight="34px"
                >
                    {cardDetails.percentage}
                </Text>
                <div className="percentage-text">
                    <Text
                        fontsize="20px"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.MckinseySansLight}
                        fontweight={300}
                        lineHeight="16px"
                        margin="0 0 4px 0"
                    >
                        %
                    </Text>
                    <Text
                        fontsize="14px"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.MckinseySansLight}
                        fontweight={300}
                    >
                        {journey_completed}
                    </Text>
                </div>
            </div>
            <ProgressBar percentage={cardDetails.percentage} />
        </div>
    );
}

export default JourneyProgressCard;

JourneyProgressCard.defaultProps = {
    cardDetails: {},
};

JourneyProgressCard.propTypes = {
    cardDetails: PropTypes.shape({
        completed: PropTypes.number,
        total: PropTypes.number,
        percentage: PropTypes.number,
    }),
};
