import { postWebApi, fetchWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';
import AuthStore from '../../common/AuthStore';

export const uploadImageApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { postImage } = endpoints;
    const url = apiBasePath + postImage;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const getQuestionsApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { createProfile } = endpoints;
    const url = apiBasePath + createProfile;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const postNameAnswersApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { createProfile } = endpoints;
    const url = apiBasePath + createProfile;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const getVcrbApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { vcrb } = endpoints;
    const url = apiBasePath + vcrb;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getNotesApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { notes } = endpoints;
    const url = `${apiBasePath + notes}?type=${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const postNotesApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { notes } = endpoints;
    const url = `${apiBasePath + notes}?type=${body.type}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};
