import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BottomScrollListener from 'react-bottom-scroll-listener';
import moment from 'moment';
import LocalisationsStore from '../../../common/LocalisationsStore';
import '../index.scss';
import { isMobile } from '../../../utils/constants';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import Nodata from '../assets/noData.svg';
import { getLastUpdatedAt } from '../../../utils/functions';
import Oops from '../../../assets/oops.svg';
import { LoadIcon } from '../../Feeds/assets/svg';
import PreviewProfileImage from '../../Feeds/PreviewProfileImage';
import Loader from '../../../components/Loader';

export default function NotificationCenterComponent({
    getNotificationsList,
    notificationsList,
    setShowNotificationCenter,
    history,
    loading,
    getThisFeedData,
    readThisNotification,
    markAllRead,
    incrementPageNumber,
    allNotificationsFetched,
    toggleReachedEndOfNotificationsList,
    setSelectedKey,
    notificationsLoading,
    getNotificationsListMore,
    resetPageNumber,
    pageAnalytics,
}) {
    const [flag, setFlag] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [notificationCenterStartTime, setNotificationCenterStartTime] = useState(
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    );
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        notifications,
        noNotifications,
        allCaughtUpNotifications,
        noMoreRecords,
        oops,
        msgDeleted,
        markAllAsRead,
    } = localisationsAfterLogin;

    useEffect(
        () => () => {
            const date = new Date();
            const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
            const analyticsEndTime = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
            pageAnalytics({
                page_tag: 'userNotifications',
                device_id: localStorage.fcmToken,
                start_time: notificationCenterStartTime,
                end_time: analyticsEndTime,
                user: userProfile,
            });
        },
        [pageAnalytics, notificationCenterStartTime],
    );

    useEffect(() => {
        getNotificationsList();
    }, [getNotificationsList]);

    useEffect(() => {
        if (allNotificationsFetched) {
            setFlag(true);
            setTimeout(() => {
                setFlag(false);
            }, [3000]);
        }
    }, [allNotificationsFetched]);

    useEffect(
        () => () => {
            toggleReachedEndOfNotificationsList(false);
            resetPageNumber();
        },
        [toggleReachedEndOfNotificationsList, resetPageNumber],
    );

    const handleClose = () => {
        setShowNotificationCenter(false);
    };

    const routeToPage = (id, screenname) => {
        switch (screenname) {
            case 'comment_details': {
                history.push(`/feed-details-social/${id}`);
                break;
            }
            case 'article_detail': {
                history.push(`/feed-details-article/${id}`);
                break;
            }
            case 'social_details': {
                history.push(`/feed-details-social/${id}`);
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleNotificationClick = e => {
        e.stopPropagation();
        const { currentTarget: { dataset: { id, notificationid, screenname } } } = e;
        readThisNotification(Number(notificationid));
        switch (screenname) {
            case 'comment_details': {
                getThisFeedData(id, Number(notificationid), () => routeToPage(id, screenname));
                break;
            }
            case 'article_detail': {
                getThisFeedData(id, Number(notificationid), () => routeToPage(id, screenname));
                break;
            }
            case 'article': {
                history.push('/feeds');
                setSelectedKey(1);
                localStorage.setItem('selectedMenu', 1);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                break;
            }
            case 'social_details': {
                localStorage.setItem('selectedMenu', 4);
                getThisFeedData(id, Number(notificationid), () => routeToPage(id, screenname));
                break;
            }
            default: {
                history.push('/feeds');
                break;
            }
        }
    };

    const handleMarkAllClick = e => {
        e.stopPropagation();
        markAllRead();
    };

    const scrollCallback = () => {
        if (!allNotificationsFetched && notificationsList.length > 0) {
            incrementPageNumber();
            getNotificationsListMore();
        }
    };

    return (
        <div className="notificationsList_container">
            {notificationsLoading && <Loader position="absolute" background="transparent" showBackground />}
            {!notificationsLoading && notificationsList.length === 0 && (
                <div className="no_notifications_data">
                    {isMobile && (
                        <div onClick={handleClose} role="button" tabIndex={0} className="back">
                            <Cross color="black" />
                        </div>
                    )}
                    <img src={Nodata} alt="" />
                    <span>{noNotifications}</span>
                    <span>{allCaughtUpNotifications}</span>
                </div>
            )}
            {notificationsList.length > 0 && (
                <div className="notificationsList_wrapper">
                    <div className="notificationsList_header">
                        {notifications}
                        {!isMobile && (
                            <div className="mark_all_read" onClick={handleMarkAllClick}>
                                {markAllAsRead}
                            </div>
                        )}
                        {isMobile && (
                            <div onClick={handleClose} role="button" tabIndex={0} className="back">
                                <Cross color="black" />
                            </div>
                        )}
                    </div>
                    <BottomScrollListener onBottom={scrollCallback} offset={100} debounce={700}>
                        {scrollRef => (
                            <>
                                <div ref={scrollRef} className="notification_rows">
                                    {notificationsList.map((row, index) => (
                                        <div
                                            key={`${row.content_id}-notification-${index}`}
                                            className={row.is_read ? 'faded notification_row' : 'notification_row'}
                                            data-id={row.content_id}
                                            data-notificationid={row.notification_id}
                                            data-screenname={row.screen_name}
                                            onClick={handleNotificationClick}
                                        >
                                            <div className="notification_card_img">
                                                {row.isDeleted ? (
                                                    <img src={Oops} alt="oops" />
                                                ) : (
                                                    <>
                                                        <svg height="0" width="0">
                                                            <clipPath id="myLikePath">
                                                                <path
                                                                    fill="none"
                                                                    fillRule="evenodd"
                                                                    stroke="#979797"
                                                                    d="M15.542 2.489C6.796 6.793-1.244 20.263 2.092 33.137c2.69 10.38 25.388 26.49 37.996 12.243 18.18-20.543 5.045-31.448-1.641-37.024-5.023-4.189-14.158-10.171-22.905-5.867z"
                                                                />
                                                            </clipPath>
                                                        </svg>
                                                        <div className="profile_image_adjusted">
                                                            <PreviewProfileImage
                                                                src={row.image}
                                                                alt="image"
                                                                width={48}
                                                                height={48}
                                                                id="myLikePath"
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="notification_row_data">
                                                {row.isDeleted ? (
                                                    <div className="notification_name">{`${oops} ${msgDeleted}`}</div>
                                                ) : (
                                                    <>
                                                        <div className="notification_name">{row.notification_text}</div>
                                                        <div className="notification_created_at">
                                                            {getLastUpdatedAt(row.created_at)}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {flag && <div className="loaderWrapper">{noMoreRecords}</div>}
                                {loading && (
                                    <div className="loaderWrapper">
                                        <LoadIcon />
                                    </div>
                                )}
                            </>
                        )}
                    </BottomScrollListener>
                </div>
            )}
        </div>
    );
}

const notification = PropTypes.shape({
    content_id: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    is_read: PropTypes.bool.isRequired,
    notification_id: PropTypes.number.isRequired,
    notification_text: PropTypes.string.isRequired,
    screen_name: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool,
});

NotificationCenterComponent.propTypes = {
    getNotificationsList: PropTypes.func.isRequired,
    setShowNotificationCenter: PropTypes.func.isRequired,
    notificationsList: PropTypes.arrayOf(notification).isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getThisFeedData: PropTypes.func.isRequired,
    readThisNotification: PropTypes.func.isRequired,
    markAllRead: PropTypes.func.isRequired,
    incrementPageNumber: PropTypes.func.isRequired,
    allNotificationsFetched: PropTypes.bool.isRequired,
    toggleReachedEndOfNotificationsList: PropTypes.func.isRequired,
    setSelectedKey: PropTypes.func.isRequired,
    notificationsLoading: PropTypes.bool.isRequired,
    getNotificationsListMore: PropTypes.func.isRequired,
    resetPageNumber: PropTypes.func.isRequired,
    pageAnalytics: PropTypes.func.isRequired,
};
