import styled from 'styled-components';

const PreviewImage = styled.img`
    background-size: auto;
    background-repeat: no-repeat;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    clip-path: ${props => (props.clipid ? `url(#${props.clipid})` : 'url(#myPath)')};
    opacity: ${props => props.opacity || 1};
    margin-left: ${props => props.margin || 0};
`;

export default PreviewImage;
