import { combineReducers } from 'redux-immutable';

import { authentication } from '../containers/UnAuthenticatedContent';
import loader from './loader';
import notification from './notification';
import { profileReducer } from '../containers/Profile';
import { feedsReducer } from '../containers/Feeds';
import { tagUserReducer } from '../components/TagUser';
import { weeklyQuestionsReducer } from '../containers/WeeklyQuestions';
import { notificationfeedsReducer } from '../containers/NotificationFeeds';
import { myProgressReducer } from '../containers/MyProgress';
import apiSequence from './apiSequence';
import coachingJourney from './coachingJourney';
import { userActivityReducer } from '../containers/UserActivity';
import { userMatrixReducer } from '../containers/UserMatrix';
import { unsubscribeReducer } from '../containers/Unsubscribe';
import { notificationsListReducer } from '../containers/NotificationCenter';
import { feedbackQuestionReducer } from '../containers/SubmitIdeaContainer';
import { mailerReducer } from '../containers/MailerResponse';
import { weeklyReplayMailerContentReducer } from '../components/WeeklyReplayMailer';
import { faqReducer } from '../components/FAQs';
import { emailLanguagesReducer } from '../containers/LanguageSelection';
import { appReducer } from '../containers/AppWrapper';
import { PracticesReducer } from '../containers/PracticesContainer';
import { HomeReducer } from '../containers/HomeContainer';
import { HubReducer } from '../containers/HubContainer';
import { PracticeDetailReducer } from '../containers/PracticesDetailsContainer';
import RefreshReducer from './refreshReducer';

const rootReducer = combineReducers({
    loader,
    authentication,
    notification,
    profileReducer,
    feedsReducer,
    tagUserReducer,
    weeklyQuestionsReducer,
    notificationfeedsReducer,
    myProgressReducer,
    apiSequence,
    coachingJourney,
    userActivityReducer,
    userMatrixReducer,
    unsubscribeReducer,
    notificationsListReducer,
    feedbackQuestionReducer,
    mailerReducer,
    weeklyReplayMailerContentReducer,
    faqReducer,
    emailLanguagesReducer,
    appReducer,
    PracticesReducer,
    HomeReducer,
    HubReducer,
    PracticeDetailReducer,
    RefreshReducer,
});

export default rootReducer;
