import { fetchWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

// eslint-disable-next-line import/prefer-default-export
export const getFeedCardsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPromptDetails } = endpoints;
    const url = `${apiBasePath}${getPromptDetails}?hashtag=${params[1]}&type=social&page=${params[2]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
