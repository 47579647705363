import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    faqContent: [],
};

const faqReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_FAQ}_DONE`]: (state, { data }) => ({
        ...state,
        faqContent: data,
        isLoading: false,
    }),
    [`${types.GET_FAQ}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
});

export default faqReducer;
