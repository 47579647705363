import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserActivityComponent from './UserActivityComponent';
import userActivitySaga from './redux/sagas';
import userActivityReducer from './redux/reducer';
import { getUserActivity } from './redux/actions';
import { getThisFeedsListSuccess, pageAnalytics } from '../Feeds/redux/actions';

const stateToProps = state => {
    const UserActivityReducer = state.get('userActivityReducer');
    return {
        activities: UserActivityReducer.activities,
        loading: UserActivityReducer.loading,
    };
};

export { userActivitySaga, userActivityReducer };

const dispatchToProps = {
    getUserActivity,
    getThisFeedsListSuccess,
    pageAnalytics,
};

export default withRouter(connect(stateToProps, dispatchToProps)(UserActivityComponent));
