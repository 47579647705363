import createReducer from '../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    uploadDone: false,
    questions: [],
    userDetails: {},
    isLoading: false,
    popUpOpen: false,
    vcrbDetails: {},
    notes: [],
};

const profileReducer = createReducer(INITIAL_STATE, {
    [types.UPLOAD_IMAGE_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),
    [types.UPLOAD_IMAGE_DONE]: (state, { payload }) => ({
        ...state,
        uploadDone: true,
        userDetails: payload,
        isLoading: false,
        popUpOpen: false,
    }),
    [types.STORE_QUESTIONS]: (state, { payload }) => ({
        ...state,
        questions: payload,
    }),
    [types.TOGGLE_POP_UP]: (state, { val }) => ({
        ...state,
        popUpOpen: val,
    }),
    [types.GET_VCRB_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),
    [types.GET_VCRB_SUCCESS]: (state, { payload }) => ({
        ...state,
        vcrbDetails: payload.data,
        isLoading: false,
    }),
    [types.GET_VCRB_FAILED]: state => ({
        ...state,
        isLoading: false,
    }),
    [types.GET_NOTES_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),
    [types.GET_NOTES_SUCCESS]: (state, { payload }) => ({
        ...state,
        notes: payload.data,
        isLoading: false,
    }),
    [types.GET_NOTES_FAILED]: state => ({
        ...state,
        isLoading: false,
    }),
    [types.ADD_NOTES_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),
    [types.ADD_NOTES_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        notes: [payload, ...state.notes],
    }),
    [types.ADD_NOTES_FAILED]: state => ({
        ...state,
        isLoading: false,
    }),
});

export default profileReducer;
