import React from 'react';
import CompletedPrcImg from '../../assets/completed_pracs.svg';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import LocalisationsStore from '../../common/LocalisationsStore';

function EmptyPracticeContainer() {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { practices, completed_prac_title, completed_prac_desc } = localisationsAfterLogin;
    return (
        <div>
            <Text
                fontsize="1.3rem"
                fontcolor={fontColors.textBlack}
                fontfamily={fontFamilies.playfairBold}
                margin="2rem 0 1rem 0"
                cursor="pointer"
            >
                {practices}
            </Text>
            <div style={{ borderRadius: '5px', backgroundColor: 'white', paddingLeft: '40px', paddingRight: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img style={{ marginTop: '60px', height: '130px', maxWidth: '140px', width: '100%' }} src={CompletedPrcImg} alt="completed practice" />
                <p style={{ marginTop: '40px', fontFamily: fontFamilies.playfairBold, fontSize: '26px', maxWidth: '384px', width: '100%', textAlign: 'center' }}>{completed_prac_title}</p>
                <p style={{ marginBottom: '60px', fontFamily: fontFamilies.TheinhardtPanLight, fontSize: '16px', maxWidth: '484px', width: '100%', textAlign: 'center' }}>{completed_prac_desc}</p>
            </div>
        </div>
    );
}

export default EmptyPracticeContainer;
