import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import PromptDetails, { PracticeStateType } from '../../containers/PracticesDetailsContainer/PromptDetails';
import { nullable } from '../../utils/functions';
import './index.scss';

export default function NewPracticeCard({
    index,
    id,
    name,
    thumbnail,
    description,
    onClick,
    orientation_promt_id,
    getPracticeDetails,
    resetPracticesPage,
}) {
    const [showPrompt, setShowPrompt] = useState(false);

    const handlePracticeClick = (e, id) => {
        if (orientation_promt_id !== null) {
            setShowPrompt(true);
        } else {
            onClick(e, id);
        }
    };

    const handleCrossClick = e => {
        e.stopPropagation();
        setShowPrompt(false);
    };

    return (
        <div
            className="new_practice_card_container"
            key={`new-progress-card-${index}`}
            data-id={id}
            onClick={e => handlePracticeClick(e, id)}
        >
            <div className="left_panel">
                <img src={thumbnail} style={{ objectFit: 'cover' }} alt="thm" />
                <div className="view_practice_wrapper">
                    <Text
                        fontsize=".875rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        margin="0 0.5rem 0 0"
                        textTransform="uppercase"
                        letterSpacing="1px"
                        cursor="pointer"
                    >
                        {name}
                    </Text>
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.siteGrey}
                        fontfamily={fontFamilies.TheinhardtPanRegular}
                        margin="0 0.5rem 0 0"
                        cursor="pointer"
                    >
                        {description}
                    </Text>
                </div>
            </div>
            {showPrompt && (
                <PromptDetails
                    article={{
                        id: orientation_promt_id,
                        state: PracticeStateType.read,
                        from: 'practicesListPage',
                        type: 'orientation',
                    }}
                    handleCrossClick={handleCrossClick}
                    practiceId={id}
                    getPracticeDetails={getPracticeDetails}
                    resetPracticesPage={resetPracticesPage}
                />
            )}
        </div>
    );
}

NewPracticeCard.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    orientation_promt_id: nullable(PropTypes.number).isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    resetPracticesPage: PropTypes.func.isRequired,
};
