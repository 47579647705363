import styled from 'styled-components';

const Text = styled.div`
    ${({
        fontsize,
        fontweight,
        fontcolor,
        fontfamily,
        letterSpacing,
        lineHeight,
        margin,
        decoration,
        cursor,
        textAlign,
        width,
        textTransform,
        display,
        justifyContent,
        wordBreak,
        textOverflow,
        overflow,
        whiteSpace,
    }) => `
        color: ${fontcolor};
        font-size: ${fontsize};
        font-weight: ${fontweight};
        font-family: ${fontfamily};
        letter-spacing: ${letterSpacing || 'normal'};
        line-height: ${lineHeight || 'normal'};
        margin: ${margin};
        text-decoration: ${decoration};
        text-align: ${textAlign || 'left'};
        word-break: ${wordBreak || 'normal'};
        cursor: ${cursor || 'auto'};
        width: ${width || 'auto'}
        text-transform: ${textTransform || 'auto'}
        align-items:center;
        display:${display || 'flex'};
        justify-content:${justifyContent || ''};
        text-overflow:${textOverflow || ''};
        overflow:${overflow || ''};
        white-space:${whiteSpace || ''}};
        min-width:0;
    `}
`;

// text-overflow:${textOverflow || 'clip'};
// overflow:${overflow || 'visible'};
// white-space: ${whiteSpace || ''};

export default Text;
