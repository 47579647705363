import * as TYPES from './types';

export const getPracticeDetails = id => ({
    type: `${TYPES.GET_PRACTICE_DETAILS}_PENDING`,
    id,
});
export const storeInPracticeDetails = data => ({
    type: `${TYPES.GET_PRACTICE_DETAILS}_DONE`,
    data,
});

export const getArticleDetails = id => ({
    type: `${TYPES.GET_PROMPT_DETAILS}_PENDING`,
    id,
});

export const storeInPromptDetails = data => ({
    type: `${TYPES.GET_PROMPT_DETAILS}_DONE`,
    data,
});

export const getSubPromptDetails = id => ({
    type: `${TYPES.GET_SUBPROMPT_DETAILS}_PENDING`,
    id,
});

export const storeInSubPromptDetails = data => ({
    type: `${TYPES.GET_SUBPROMPT_DETAILS}_DONE`,
    data,
});

export const storeProTagViewed = () => ({
    type: TYPES.PROTAG_VIEWED,
});

export const resetPromptData = () => ({
    type: TYPES.RESET_PROMPT_DATA,
});
