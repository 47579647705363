import { put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { apiFailed } from '../../../common/actions';
import { getFeedCardsApi } from './apis';
import { storeFeedCardList } from './actions';

function* getFeedCardListSaga({ hashtag = '', page = 1 }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getFeedCardsApi,
            payload: [AuthStore.accessToken, hashtag, page],
        });
        yield put(storeFeedCardList(data));
    } catch (e) {
        yield put(apiFailed());
    }
}

const HubSagas = [takeLatest(`${types.GET_FEED_CARDS_LIST}_PENDING`, getFeedCardListSaga)];

export default HubSagas;
