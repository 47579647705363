import { fetchWebApi } from '../webapis/dashboard';
import { webApiAuthGet } from '../webapis/core';
import endpoints from '../Endpoints';

export const fetchLogoutApi = () => {
    const url = endpoints.logoutPath;
    return fetchWebApi(url).request;
};

export const getOpenLinkTranslationsApi = (baseUrl, email) => {
    const { getLocalisationsFromUser } = endpoints;
    const url = `${baseUrl}/${getLocalisationsFromUser}?email=${email}`;
    return webApiAuthGet(url).request;
};
