import Endpoints from '../../../Endpoints';
import { webApiAuthGet } from '../../../webapis/core';
import parseJwt from '../../../utils/parseJwt';
import { fetchWebApi } from '../../../webapis/dashboard';

export const getFaq = (baseurl, email) => {
    const url = `${baseurl}?email=${email}`;
    return webApiAuthGet(url).request;
};

export const getFaqFromToken = token => {
    const { faq } = Endpoints;
    const parsedData = parseJwt(token);
    const baseUrl = parsedData['https://mckinspire.com_baseURL'];
    const url = `${baseUrl}${faq}/`;
    return fetchWebApi(token, url).request;
};
