import * as TYPES from './types';

export const getHomePage = () => ({
    type: `${TYPES.GET_HOME_PAGE}_PENDING`,
});

export const storeHomePage = data => ({
    type: `${TYPES.GET_HOME_PAGE}_DONE`,
    data,
});

export const getRecommendedPrompts = () => ({
    type: `${TYPES.GET_RECOMMENDED_PROMPTS}_PENDING`,
});

export const storeRecommendedPrompts = data => ({
    type: `${TYPES.GET_RECOMMENDED_PROMPTS}_DONE`,
    data,
});

export const getTodos = (pageSize, pageNumber) => ({
    type: `${TYPES.GET_TODOS}_PENDING`,
    pageSize,
    pageNumber,
});

export const storeTodos = data => ({
    type: `${TYPES.GET_TODOS}_DONE`,
    data,
});

export const getGoalsAction = (pageSize, pageNumber, status) => ({
    type: `${TYPES.GET_GOALS}_PENDING`,
    pageSize,
    pageNumber,
    status,
});
export const storeGoals = (data, count, hide_goal_section) => ({
    type: `${TYPES.GET_GOALS}_DONE`,
    data,
    count,
    hide_goal_section,
});

export const getGoalsByCategoriesAction = () => ({
    type: `${TYPES.GET_GOALS_BY_CATEGORIES}_PENDING`,
});
export const storeGoalsByCategories = (data, hide_goal_section) => ({
    type: `${TYPES.GET_GOALS_BY_CATEGORIES}_DONE`,
    data,
    hide_goal_section,
});
export const getGoalsByCategoriesFailed = () => ({
    type: `${TYPES.GET_GOALS_BY_CATEGORIES}_FAILED`,
});

export const closeCard = id => ({
    type: `${TYPES.CLOSE_CARD}_PENDING`,
    id,
});

export const refreshTodoAction = () => ({
    type: `${TYPES.REFRESH_TODO}`,
});

export const refreshGoalAction = () => ({
    type: `${TYPES.REFRESH_GOALS}`,
});
export const deleteGoalAction = payload => ({
    type: `${TYPES.DELETE_GOAL}_PENDING`,
    payload,
});

export const deleteGoalSuccess = payload => ({
    type: `${TYPES.DELETE_GOAL}_SUCCESS`,
    payload,
});

export const resetGoalTab = () => ({
    type: `${TYPES.RESET_GOAL_TAB}`,
});
