import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import { getSubPromptDetails, getPracticeDetails } from './redux/actions';
import DynamicCardMapper from '../../components/DynamicCard/DynamicCardMapper';
import { updateCardStatus } from '../HomeContainer/redux/apis';
import SubPromptsContainer from './SubPromptsContainer';
import { closeCard } from '../HomeContainer/redux/actions';
import ArticleThisPractice from './ArticleThisPractice';
import './index.scss';
import backArrow from '../../assets/backArrow.svg';
import { uploadPost } from '../Feeds/redux/actions';
import AddNewPost from '../Feeds/AddNewPost';
import { pageAnalytics, nullable } from '../../utils/functions';

function SubPromptDetailContainer({
    history,
    subPromptDetails,
    uploadingPost,
    tags,
    uploadPost,
    getSubPromptDetails,
    getPracticeDetails,
    isSubPromptsLoading,
    match,
    clientExpired,
}) {
    const [isDataFetched, setisDataFetched] = useState(false);
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [hashtag, setHashTag] = useState('');

    useEffect(() => {
        if (match.params.id) {
            pageAnalytics.initialise(`practiceFolderDetail/${match.params.id}`);
            return () => {
                pageAnalytics.complete(`practiceFolderDetail/${match.params.id}`);
            };
        }
        return (() => {});
    }, [match.params.id]);

    useEffect(() => {
        if (subPromptDetails?.id === +match.params.id) {
            setisDataFetched(true);
        }
    }, [subPromptDetails, match.params.id]); // article.id

    useEffect(() => {
        getSubPromptDetails(match.params.id);
    }, [getSubPromptDetails, match.params.id]);

    /*  useEffect(() => {
        if (practiceDetailsData) {
            // setPracticeImageData(practiceDetailsData.practice_image);
            if (!practiceDetailsData.protag_viewed && practiceDetailsData.protag_id) {
                extractJsonFromUrl(practiceDetailsData.protag_anim).then(response => {
                    setShowProTagAnimation(true);
                    setProtagAnimData(response.data);
                    return extractJsonFromUrl(practiceDetailsData.practice_image);
                });
            }
        }
    }, [practiceDetailsData, params.id]); */

    const closeCallback = cardId => {
        closeCard(cardId);
    };

    const handleBackCLick = () => {
        history.goBack();
    };

    const handleClose = () => {
        setShowCreatePost(false);
    };
    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };

    const SubpromptHeader = styled.div`
        display: flex;
        padding: 20px;
        background-color: ${fontColors.backgroundGrey};

        .back {
            margin-top: 10px;
        }
    `;
    // const { practiceId } = location.state;
    // Replace anchor tags to open in new tabs
    return (
        <>
            {clientExpired && moveToClientExpirePage()}
            <SubpromptHeader>
                <div className="back" onClick={handleBackCLick}>
                    <img src={backArrow} alt="" />
                </div>
                <Text
                    fontsize="28px"
                    fontcolor={fontColors.textBlack}
                    fontfamily={fontFamilies.playfairBold}
                    margin="0 60px"
                >
                    {match.params.name}
                </Text>
            </SubpromptHeader>
            <div className="practice_details_container">
                {isSubPromptsLoading && <Loader position="fixed" showBackground />}
                {isDataFetched && (
                    <>
                        <div className="practice-content">
                            <div className="middle_panel_details sumprompts_middle">
                                <div className="upper_texts_wrapper">
                                    <Text
                                        fontsize="14px"
                                        fontcolor={fontColors.siteGrey}
                                        fontfamily={fontFamilies.TheinhardtPanRegular}
                                        margin="0 0 0.5rem 0"
                                        letterSpacing="1"
                                        textTransform="uppercase"
                                    >
                                        {subPromptDetails.sub_title}
                                    </Text>
                                    <Text
                                        fontsize="36px"
                                        fontcolor={fontColors.black}
                                        fontfamily={fontFamilies.playfairBold}
                                        textAlign="center"
                                    >
                                        {subPromptDetails.title}
                                    </Text>

                                    <div
                                        className="feed_inner_html"
                                        dangerouslySetInnerHTML={{
                                            __html: subPromptDetails.description_web,
                                        }}
                                    />
                                </div>
                                <div className="divider" />
                                <div className="article_list_wrapper">
                                    {subPromptDetails?.sub_prompts
                                        && subPromptDetails.sub_prompts.map(article => {
                                            if (article.type === 'folder') {
                                                return (
                                                    <SubPromptsContainer
                                                        article={article}
                                                        history={history}
                                                        key={article.id}
                                                        practiceId={match.params.practiceid}
                                                        uploadPost={uploadPost}
                                                        uploadingPost={uploadingPost}
                                                        tags={tags}
                                                    />
                                                );
                                            }
                                            if (article.show_type === 'article') {
                                                return (
                                                    <ArticleThisPractice
                                                        article={article}
                                                        totalCount={subPromptDetails.sub_prompts.length}
                                                        practiceId={match.params.practiceid}
                                                        uploadingPost={uploadingPost}
                                                        tags={tags}
                                                        uploadPost={uploadPost}
                                                        key={article.id}
                                                        getSubPromptDetails={getSubPromptDetails}
                                                        getPracticeDetails={getPracticeDetails}
                                                        setShowCreatePost={setShowCreatePost}
                                                        /* setShowProTagAnimation={setShowProTagAnimation}
                                                setProtagAnimData={setProtagAnimData} */
                                                        history={history}
                                                        fromPractice={false}
                                                    />
                                                );
                                            }

                                            return new DynamicCardMapper(
                                                article.card_type,
                                                () => closeCallback(article.id),
                                                {
                                                    key: article.title,
                                                    bgColor: article.bg_color,
                                                    title: article.title,
                                                    body: article.body,
                                                    thumbnail: article.thumbnail,
                                                    star_streak: article.star_streak,
                                                    competedPrompt: article.completed_promt,
                                                    ctaExternal: article.cta_external,
                                                    ctaInternal: article.cta_internal,
                                                    ctaType: article.cta_type,
                                                    id: article.id,
                                                    cta_text: article.cta_text,
                                                    updateCardStatus,
                                                    showRemoveIcon: false,
                                                },
                                            ).getCard();
                                        })}
                                </div>
                            </div>
                            <div className="animation_wrapper" />
                        </div>
                    </>
                )}
                {showCreatePost && (
                    <AddNewPost
                        handleClose={handleClose}
                        imageUploaderModal={showCreatePost}
                        uploadPost={uploadPost}
                        tags={tags}
                        uploadingPost={uploadingPost}
                        selectedKey={1}
                        selectedHashTag={hashtag}
                        setSelectedHashTag={setHashTag}
                        setShowCreatePost={setShowCreatePost}
                        history={history}
                    />
                )}
            </div>
        </>
    );
}

const dispatchToProps = {
    getSubPromptDetails,
    uploadPost,
    getPracticeDetails,
};

const stateToProps = state => {
    const REDUCER = state.get('PracticeDetailReducer');
    const FREDUCER = state.get('feedsReducer');
    const RefREDUCER = state.get('RefreshReducer');
    return {
        subPromptDetails: REDUCER.subPromptDetails,
        // practiceDetailsData: REDUCER.practiceDetailsData,
        uploadingPost: FREDUCER.uploadingPost,
        uploadPost: FREDUCER.uploadPost,
        tags: FREDUCER.tags,
        isSubPromptsLoading: REDUCER.isSubPromptsLoading,
        clientExpired: RefREDUCER.clientExpired,
    };
};

SubPromptDetailContainer.defaultProps = {
    subPromptDetails: null,
};

SubPromptDetailContainer.propTypes = {
    history: PropTypes.object.isRequired,
    subPromptDetails: PropTypes.object,
    uploadingPost: PropTypes.bool.isRequired,
    tags: nullable(PropTypes.object).isRequired,
    uploadPost: PropTypes.func.isRequired,
    getSubPromptDetails: PropTypes.func.isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    isSubPromptsLoading: PropTypes.bool.isRequired,
    clientExpired: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            practiceid: PropTypes.string,
            name: PropTypes.string,
        }),
    }).isRequired,
};

export default connect(stateToProps, dispatchToProps)(SubPromptDetailContainer);
