import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Details from './Details';
import feedsReducer from '../Feeds/redux/reducer';
import FeedsSaga from '../Feeds/redux/sagas';
import {
    getThisFeedData,
    uploadPost,
    getTags,
    toggleCreatePostModal,
    postLike,
    getLikeDetails,
    postFollowUser,
    postQuizAnswer,
    resetPostQuizData,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    postIsActionableNotes,
    storeTeamMembersList,
    getThisFeedsListSuccess,
} from '../Feeds/redux/actions';
import { logoutUser, getUserData } from '../UnAuthenticatedContent/actions';
import { getUserProgressData } from '../MyProgress/redux/actions';
import { emptyNotificationList, resetPageNumber } from '../NotificationCenter/redux/actions';
import { storeFeedFilters } from '../FeedFilters/redux/actions';

const stateToProps = state => {
    const FeedsReducer = state.get('feedsReducer');
    return {
        isLoading: FeedsReducer.isLoading,
        tags: FeedsReducer.tags,
        uploadingPost: FeedsReducer.uploadingPost,
        imageUploaderModal: FeedsReducer.imageUploaderModal,
        likedId: FeedsReducer.likedId,
        thisFeedData: FeedsReducer.thisFeedData,
        description: FeedsReducer.thisFeedData ? FeedsReducer.thisFeedData.description : '',
        readTime: FeedsReducer.thisFeedData ? FeedsReducer.thisFeedData.read_time : '',
        postDate: FeedsReducer.thisFeedData ? FeedsReducer.thisFeedData.publish_date : '',
        title: FeedsReducer.thisFeedData ? FeedsReducer.thisFeedData.title : '',
        subTitle: FeedsReducer.thisFeedData ? FeedsReducer.thisFeedData.sub_title : '',
        likeDetails: FeedsReducer.likeDetails,
        followedId: FeedsReducer.followedId,
        quizData: FeedsReducer.thisFeedData ? FeedsReducer.thisFeedData.quiz : null,
        postQuizSuccess: FeedsReducer.postQuizSuccess,
        showShareSuccess: FeedsReducer.showShareSuccess,
        followedUsers: FeedsReducer.followedUsers,
        earnedBannerBadge: FeedsReducer.earnedBannerBadge,
        filterString: FeedsReducer.filterString,
        stickyQuizResponse: FeedsReducer.stickyQuizResponse,
    };
};

export { FeedsSaga, feedsReducer };

const dispatchToProps = {
    uploadPost,
    getTags,
    postLike,
    toggleCreatePostModal,
    getThisFeedData,
    getLikeDetails,
    postFollowUser,
    postQuizAnswer,
    resetPostQuizData,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    logoutUser,
    postIsActionableNotes,
    getUserProgressData,
    storeTeamMembersList,
    getUserData,
    getThisFeedsListSuccess,
    emptyNotificationList,
    resetPageNumber,
    storeFeedFilters,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Details));
