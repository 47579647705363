import { createSelector } from 'reselect';

const authentication = state => state.get('authentication');
const profileReducer = state => state.get('profileReducer');
const refreshReducer = state => state.get('RefreshReducer');

const selectLoading = () => createSelector(authentication, state => state.toJS().isLoading);
const selectLoadingImage = () => createSelector(profileReducer, state => state.isLoading);
const popUpOpen = () => createSelector(authentication, state => state.toJS().popUpOpen);
const selectError = () => createSelector(authentication, state => state.toJS().error);
const selectSignUpError = () => createSelector(authentication, state => state.toJS().signupError);
const selectChangeSignUp = () => createSelector(authentication, state => state.toJS().changeSignUp);
const selectAccessToken = () => createSelector(authentication, state => state.toJS().accessToken);
const selectSuccessScreen = () => createSelector(authentication, state => state.toJS().successScreen);
const selectUserData = () => createSelector(authentication, state => state.toJS().userData);
const selectInitialLocalisations = () => createSelector(authentication, state => state.toJS().initialLocalisations);
const selectLanguages = () => createSelector(authentication, state => state.toJS().languages);
const selectCount = () => createSelector(authentication, state => state.toJS().count);
const selectPopUpOpen = () => createSelector(profileReducer, state => state.popUpOpen);

const selectDomainValid = () => createSelector(authentication, state => state.get('domainValid'));

const selectQuestions = () => createSelector(profileReducer, state => state.questions);

const selectClientExpire = () => createSelector(refreshReducer, state => state.clientExpired);

export {
    selectLoading,
    selectError,
    selectChangeSignUp,
    selectAccessToken,
    selectSuccessScreen,
    selectUserData,
    selectInitialLocalisations,
    selectLanguages,
    selectDomainValid,
    selectQuestions,
    popUpOpen,
    selectLoadingImage,
    selectPopUpOpen,
    selectCount,
    selectSignUpError,
    selectClientExpire,
};
