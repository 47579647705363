import * as firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const messaging = () => {
    try {
        firebase.initializeApp(config);
        const messaging = firebase.messaging();
        messaging.usePublicVapidKey(
            // Project Settings => Cloud Messaging => Web Push certificates
            process.env.REACT_APP_USE_PUBLIC_VAPID_KEY,
        );
        return messaging;
    } catch (e) {
        return {
            onMessage: () => {},
            requestPermission: () => new Promise(resolve => resolve()),
            getToken: () => new Promise(resolve => resolve()),
        };
    }
};

const messageStore = messaging();

// eslint-disable-next-line import/prefer-default-export
export { messageStore as messaging };
