import styled from 'styled-components';
import { Select as Slt, MenuItem as MnItem } from '@material-ui/core';
import { fontColors, fontFamilies } from '../../common/fontMap';

const rem = pixels => `${pixels}vh`;
export default rem;

const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    opacity: ${props => (props.disabled ? '0.3' : '')};
`;
const Label = styled.span`
    color: ${fontColors.textBlack};
    font-size: 0.75rem;
    font-family: ${fontFamilies.TheinhardtPanMedium};
    margin-bottom: ${props => (props.label ? '0.5rem' : '0')};

    sup {
        color: ${fontColors.$textOrange};
    }
`;

const Select = styled(Slt)`
    width: 100%;
    border-radius: 0.3125rem;
    background-color: ${fontColors.siteWhite};

    & .MuiSelect-select:focus {
        background-color: ${fontColors.siteWhite};
    }
    .MuiSelect-root {
        font-size: ${rem(2)};
        padding: 1.9vh 1.3vw;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: ${fontColors.borderGrey} !important;
        border-width: 1px !important;
    }

    .MuiSelect-iconOutlined {
        color: ${fontColors.$themeBlue};
    }
`;

const MenuItem = styled(MnItem)`
    font-size: ${rem(2)};
    border-bottom: 1px solid rgba(229, 229, 229, 0.2);
    color: ${fontColors.$darkIndigo};

    & > span:first-child {
        padding: 0;
        margin-right: 0.5vw;

        svg {
            height: 2.5vh;
            width: 2.5vh;
        }
    }
`;

const ChipWrapper = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 99;
`;

const Chip = styled.div`
    display: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: ${fontColors.$grey1500};
    margin-right: 0.3vw;
    padding: 0 0.5vw;
    z-index: 99;

    & > span {
        color: ${fontColors.$black100};
        font-size: ${rem(1.5)};
        margin-bottom: 0.2vh;
    }
    & > svg {
        height: 2vh;
    }
`;

const PlaceholderText = styled.span`
    color: ${fontColors.placeholderGrey};
    font-size: 0.8rem;
    font-family: ${fontFamilies.TheinhardtPanLight};
`;

export { DropdownWrapper, Label, Select, MenuItem, ChipWrapper, Chip, PlaceholderText };
