import { connect } from 'react-redux';
import AppWrapperComponent from './AppWrapperComponent';
import appReducer from './redux/reducer';
import { uploadPost } from '../Feeds/redux/actions';
import { setNewNotification } from '../UnAuthenticatedContent/actions';
import { getTodos, refreshTodoAction, refreshGoalAction, deleteGoalAction, resetGoalTab } from '../HomeContainer/redux/actions';
import { getUserMatrix } from '../UserMatrix/redux/actions';
import { clientExpiredAction, resetClientExpiredAction } from '../../common/actions';

const stateToProps = state => {
    const REDUCER = state.get('appReducer');
    const newNotification = state.get('authentication').toJS().setNewNotification;
    const PracticeReducer = state.get('PracticeDetailReducer');
    const FeedsREDUCER = state.get('feedsReducer');
    return {
        ...REDUCER,
        newNotification,
        uploadingPost: PracticeReducer.uploadingPost,
        tags: FeedsREDUCER.tags,
        updateGoals: REDUCER.updateGoals,
    };
};

const dispatchToProps = {
    uploadPost,
    setNewNotification,
    getTodos,
    refreshGoalAction,
    refreshTodoAction,
    clientExpiredAction,
    resetClientExpiredAction,
    deleteGoalAction,
    getUserMatrix,
    resetGoalTab,
};

export { appReducer };

export default connect(stateToProps, dispatchToProps)(AppWrapperComponent);
