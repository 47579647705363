import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GradientPicker from '../../../components/GradientPicker';
import LocalisationsStore from '../../../common/LocalisationsStore';

const TextFeedContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
`;

const CanvasCapturingArea = styled.div`
    width: 100%;
    height: 200px;
    overflow-y:auto;
    background-color: transparent;
`;

export default function StoryWriter({
    setSubT,
    isMobile,
    selectedFont,
    setSelectedFont,
    currentGradient,
    setCurrentGradient,
    setGradientIndex,
    subTitle,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { tapToType, TYPEWRITER, HANDWRITTEN, BOLD } = localisationsAfterLogin;

    const setSubTitle = e => {
        e.stopPropagation();
        setSubT(e.target.value);
    };

    const changeFont = (e, val) => {
        e.stopPropagation();
        setSelectedFont(val);
    };

    const setGradAndItsIndex = (val, index) => {
        setCurrentGradient(val);
        setGradientIndex(index);
    };

    const getFontClass = () => {
        switch (selectedFont) {
            case 'Typewritter':
                return 'thein';
            case 'Curly':
                return 'fabFelt';
            case 'Bold':
                return 'bower';
            default:
                return 'thein';
        }
    };

    return (
        <div className="text_feed_container">
            <TextFeedContainer isMobile={isMobile} style={{ backgroundColor: currentGradient.backColor }}>
                <div className="svgWrapper">
                    <svg viewBox="0 0 170 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M96.6432 0C76.2651 29.3908 56.527 65.6794 37.3598 110.23C-55.8702 326.926 39.651 383.059 177.226 463.904C196.577 475.275 216.759 487.135 237.366 500H695V0H96.6432Z"
                            fill={currentGradient.imageColor}
                        />
                    </svg>
                </div>
                <div className="text_controls">
                    <div className="fonts_control">
                        <div>
                            <button
                                onClick={e => changeFont(e, 'Typewritter')}
                                className={selectedFont === 'Typewritter' ? 'thein selected_font' : 'thein'}
                                type="submit"
                            >
                                {TYPEWRITER}
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={e => changeFont(e, 'Curly')}
                                className={selectedFont === 'Curly' ? 'thein selected_font' : 'thein'}
                                type="submit"
                            >
                                {HANDWRITTEN}
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={e => changeFont(e, 'Bold')}
                                className={selectedFont === 'Bold' ? 'thein selected_font' : 'thein'}
                                type="submit"
                            >
                                {BOLD}
                            </button>
                        </div>
                    </div>
                    <CanvasCapturingArea id="textarea_containerid">
                        <div className="quote">
                            <svg
                                width="21"
                                height="15"
                                viewBox="0 0 21 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.124 14.652C4.25733 14.652 3.512 14.496 2.888 14.184C2.264 13.872 1.77867 13.4213 1.432 12.832C1.05067 12.3467 0.756 11.7053 0.548 10.908C0.374667 10.1107 0.288 9.31333 0.288 8.516C0.288 6.78266 0.721334 5.188 1.588 3.732C2.48933 2.24133 3.876 1.06266 5.748 0.195997L6.216 1.132C5.24533 1.548 4.37867 2.18933 3.616 3.056C2.85333 3.92267 2.40267 4.80667 2.264 5.708C2.09067 6.47066 2.09067 7.19866 2.264 7.892C3.02667 7.06 4.032 6.644 5.28 6.644C6.49333 6.644 7.48133 7.008 8.244 7.736C9.00667 8.42933 9.388 9.4 9.388 10.648C9.388 11.8613 8.972 12.832 8.14 13.56C7.34267 14.288 6.33733 14.652 5.124 14.652ZM15.836 14.652C14.9693 14.652 14.224 14.496 13.6 14.184C12.976 13.872 12.4907 13.4213 12.144 12.832C11.7627 12.3467 11.468 11.7053 11.26 10.908C11.0867 10.1107 11 9.31333 11 8.516C11 6.78266 11.4333 5.188 12.3 3.732C13.2013 2.24133 14.588 1.06266 16.46 0.195997L16.928 1.132C15.9573 1.548 15.0907 2.18933 14.328 3.056C13.5653 3.92267 13.1147 4.80667 12.976 5.708C12.8027 6.47066 12.8027 7.19866 12.976 7.892C13.7387 7.06 14.744 6.644 15.992 6.644C17.2053 6.644 18.1933 7.008 18.956 7.736C19.7187 8.42933 20.1 9.4 20.1 10.648C20.1 11.8613 19.684 12.832 18.852 13.56C18.0547 14.288 17.0493 14.652 15.836 14.652Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <textarea
                            id="textwrap"
                            placeholder={tapToType}
                            className={`textarea_inner ${getFontClass()}`}
                            onChange={e => setSubTitle(e)}
                            onClick={e => e.stopPropagation()}
                            contentEditable
                            maxLength="200"
                            value={subTitle}
                        />
                    </CanvasCapturingArea>
                    <div className="text_limit">
                        <span>
                            {' '}
                            {subTitle.length}
                            {' '}
                            / 200
                        </span>
                    </div>
                </div>
            </TextFeedContainer>
            <div className="background_control">
                <GradientPicker
                    setCurrentGradient={setGradAndItsIndex}
                    isMobile={isMobile}
                    setGradientIndex={setGradientIndex}
                />
            </div>
        </div>
    );
}

StoryWriter.defaultProps = {
    isMobile: false,
};

StoryWriter.propTypes = {
    setSubT: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    selectedFont: PropTypes.string.isRequired,
    setSelectedFont: PropTypes.func.isRequired,
    currentGradient: PropTypes.shape({
        backColor: PropTypes.string,
        imageColor: PropTypes.string,
    }).isRequired,
    setCurrentGradient: PropTypes.func.isRequired,
    setGradientIndex: PropTypes.func.isRequired,
    subTitle: PropTypes.string.isRequired,
};
