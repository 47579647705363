import React, { Fragment } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import Actions from './Actions';
import GreenTick from '../../../assets/greenTick.svg';
import { MONTH_MAP } from '../../../utils/constants';
import LocalisationsStore from '../../../common/LocalisationsStore';

const ActiveCard = ({ goal, callBack, editCallBack }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { remindMeOn, taskTitle, taskDetails } = localisationsAfterLogin;
    const month = goal.due_date ? new Date(goal.due_date.replace('Z', '')).getMonth() : '';
    const date = goal.due_date ? new Date(goal.due_date.replace('Z', '')).getDate() : '';

    return (
        <Fragment>
            <div className="todo-detail-card">
                <div className="card-actions">
                    <Actions goal={goal} callBack={callBack} editCallBack={editCallBack} />
                </div>
                <div className="card-item">
                    <div style={{ whiteSpace: 'pre-line' }} className="title">
                        {taskTitle}
                    </div>

                    <div style={{ whiteSpace: 'pre-line' }} className="description">
                        {goal.goal_text}
                    </div>
                </div>
                <div className="card-item">
                    <div style={{ whiteSpace: 'pre-line' }} className="title">
                        {taskDetails}
                    </div>

                    <div style={{ whiteSpace: 'pre-line' }} className="description">
                        {goal.goal_description}
                    </div>
                </div>
                {(month && date) ? (
                    <div className="date">
                        <img src={GreenTick} alt="Reminder Date" />
                        {` ${remindMeOn} ${MONTH_MAP[month]} ${date}`}
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
};

ActiveCard.propTypes = {
    goal: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
};

export default ActiveCard;
