import styled from 'styled-components';

const TextPostGradientBox = styled.div`
    background-image: ${props => props.gradient};
    width: 100%;
    height: 361px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default TextPostGradientBox;
