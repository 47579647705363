import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Question from '../Question';
import LocalisationsStore from '../../../common/LocalisationsStore';

const Filler = styled.div`
    height: 100%;
    width: ${props => props.width}%;
    background-color: #25ca06 !important;
`;

export default function QuestionStepper({
    weeklyQuestionsData,
    setSeenPercentage,
    postAnswer,
    indexToShowOfPage,
    setIndex,
    closeWeeklyQuestionModal,
    setWeeklyResponse01,
    setHeading,
    setSubHeading,
    getNextApiInSequence,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { back, proceed } = localisationsAfterLogin;

    const [answerToThisQuestion, setAnswer] = useState(null);
    const [responsesInRange, setResponsesInRange] = useState(false);
    const [openendResponses, setopenendResponses] = useState([]);
    const [singleSelect, setSingleSelect] = useState(null);
    const [selectedOps, setSelectedOps] = useState([]);

    useEffect(() => {
        const question = weeklyQuestionsData[indexToShowOfPage];
        setSubHeading(question.header_text);
        if (question.questionType === 'Additional_Question') {
            setHeading(false);
        } else {
            setHeading(true);
        }
    }, [indexToShowOfPage, setHeading, setSubHeading, weeklyQuestionsData]);

    const checkProceedEnabled = () => {
        if (weeklyQuestionsData[indexToShowOfPage].type === 'Multiselect_ordered') {
            if (responsesInRange) {
                return true;
            }
            return false;
        }
        if (
            weeklyQuestionsData[indexToShowOfPage].type === 'frequency'
            && !weeklyQuestionsData[indexToShowOfPage].isBeahviourText
        ) {
            if (answerToThisQuestion) {
                return true;
            }
            return false;
        }
        if (
            weeklyQuestionsData[indexToShowOfPage].type === 'frequency'
            && weeklyQuestionsData[indexToShowOfPage].isBeahviourText
            && weeklyQuestionsData[indexToShowOfPage].questionType.includes('_Post')
        ) {
            if (answerToThisQuestion) {
                return true;
            }
            return false;
        }
        if (weeklyQuestionsData[indexToShowOfPage].type === 'objective') {
            if (answerToThisQuestion) {
                return true;
            }
            return false;
        }
        return true;
    };

    function showNextQuestion() {
        if (checkProceedEnabled()) {
            const currentQuestion = weeklyQuestionsData[indexToShowOfPage];

            if (indexToShowOfPage + 1 <= weeklyQuestionsData.length - 1) {
                setIndex(indexToShowOfPage => indexToShowOfPage + 1);
            }
            if (indexToShowOfPage <= weeklyQuestionsData.length - 1) {
                setSeenPercentage(indexToShowOfPage);
            }
            if (indexToShowOfPage === weeklyQuestionsData.length - 1) {
                closeWeeklyQuestionModal();
                getNextApiInSequence();
            }

            if (
                !weeklyQuestionsData[indexToShowOfPage].isBeahviourText
                || weeklyQuestionsData[indexToShowOfPage].type === 'Multiselect_ordered'
                || (weeklyQuestionsData[indexToShowOfPage].type === 'frequency'
                    && weeklyQuestionsData[indexToShowOfPage].questionType.includes('_Post'))
            ) {
                postAnswer([
                    {
                        ...currentQuestion,
                        id: currentQuestion.question,
                        response: answerToThisQuestion,
                        openend_response: openendResponses,
                    },
                ]);
                setAnswer(null);
                setSingleSelect(null);
            }
            setWeeklyResponse01(false);
            setSelectedOps([]);
        }
    }

    function showPrevQuestion() {
        setResponsesInRange(false);
        setAnswer(null);
        setIndex(indexToShowOfPage => indexToShowOfPage - 1);
        setSeenPercentage(indexToShowOfPage - 1);
    }

    return (
        <div>
            <div className="stepper_container">
                {weeklyQuestionsData.map(data => (
                    <div className="stepper">
                        <Filler width={data.seenPercentage} />
                    </div>
                ))}
            </div>
            <Question
                question={weeklyQuestionsData[indexToShowOfPage]}
                setAnswer={setAnswer}
                indexToShowOfPage={indexToShowOfPage}
                setResponsesInRange={setResponsesInRange}
                openendResponses={openendResponses}
                setopenendResponses={setopenendResponses}
                singleSelect={singleSelect}
                setSingleSelect={setSingleSelect}
                selectedOps={selectedOps}
                setSelectedOps={setSelectedOps}
            />
            <div className="call_to_action">
                {weeklyQuestionsData.length > 1 && indexToShowOfPage > 0 && (
                    <div
                        type="button"
                        onClick={showPrevQuestion}
                        className="outline-none"
                        role="none"
                        style={{ marginRight: '20px' }}
                    >
                        {back}
                    </div>
                )}
                <div
                    type="button"
                    onClick={showNextQuestion}
                    className={checkProceedEnabled() ? 'outline-none' : 'disable_proceed outline-none'}
                    role="none"
                >
                    {proceed}
                </div>
            </div>
        </div>
    );
}

QuestionStepper.defaultProps = {
    weeklyQuestionsData: [],
};

QuestionStepper.propTypes = {
    weeklyQuestionsData: PropTypes.array,
    postAnswer: PropTypes.func.isRequired,
    setSeenPercentage: PropTypes.func.isRequired,
    indexToShowOfPage: PropTypes.number.isRequired,
    setIndex: PropTypes.func.isRequired,
    closeWeeklyQuestionModal: PropTypes.func.isRequired,
    setWeeklyResponse01: PropTypes.func.isRequired,
    setHeading: PropTypes.func.isRequired,
    setSubHeading: PropTypes.func.isRequired,
    getNextApiInSequence: PropTypes.func.isRequired,
};
