import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BottomScrollListener from 'react-bottom-scroll-listener';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import ActiveCard from '../Goals/ActiveCard';
import Loader from '../../components/Loader';
import EditGoal from '../Goals/EditGoal';
import backArrow from '../../assets/backArrow.svg';
import emptyGoals from '../../assets/emptyGoals.svg';
import AddIcon from '../../assets/addIcon.svg';
import rArrow from '../../assets/white_arrow.svg';
import NewCompletedIcon from '../../assets/new_completed.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import { pageAnalytics } from '../../utils/functions';
import './index.scss';

const TabViewByCompletion = ({
    isLoading,
    hideAddGoalBtn,
    activeTab,
    page,
    goals,
    history,
    setPage,
    setView,
    setActiveTab,
    actionCallBack,
    deleteGoalAction,
    editCallBack,
    deleteCallBack,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { goals: _goals, active, completed, dontHaveGoalsAtThisMoment, newGoals } = localisationsAfterLogin;
    const [editModal, setEditModal] = useState({ active: false, goal: null });

    const handleBackCLick = () => {
        history.push('/app/home');
    };

    const editGoal = goalObj => {
        setEditModal({ active: true, goal: goalObj });
    };

    const scrollCallback = () => {
        if (goals.list.length < goals.count) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (activeTab === 0) {
            pageAnalytics.initialise('goalPending');

            return () => {
                pageAnalytics.complete('goalPending');
            };
        }
        pageAnalytics.initialise('goalCompleted');

        return () => {
            pageAnalytics.complete('goalCompleted');
        };
    }, [activeTab]);

    const Image = <img src={emptyGoals} alt="No Active Goals" />;

    // eslint-disable-next-line no-nested-ternary
    const ActiveGoals = (isLoading ? (
        <Loader position="relative" background="transparent" />
    ) : goals.list.length > 0 ? (
        <div className="goals-list-container">
            <div className="active-goals">
                {goals.list.map(goal => (
                    <ActiveCard
                        key={goal.id}
                        goal={goal}
                        callBack={actionCallBack}
                        editCallBack={editCallBack}
                        deleteCallBack={deleteCallBack}
                        deleteGoalAction={deleteGoalAction}
                        fromScreen="AllGoals"
                    />
                ))}
            </div>
            <div>
                {goals.completedGoals.map(goal => (
                    <div className="completed-goals-new">
                        <img src={NewCompletedIcon} alt="completed" />
                        <div className="c-label">{goal.title}</div>
                        <img onClick={() => { editGoal(goal); }} style={{ padding: '6px' }} src={rArrow} alt="arrow" />
                    </div>
                ))}
            </div>
            {editModal.active
                && (
                    <EditGoal
                        editCallBack={editCallBack}
                        goalObj={editModal.goal}
                        closeModal={setEditModal}
                    />
                )}
        </div>
    ) : (
        <div className="empty-goals">
            {Image}
            <div className="empty-goals__message">{dontHaveGoalsAtThisMoment}</div>
        </div>
    ));

    return (
        <div className="all-goals">
            <div className="all-goals-container">
                <div className="all-goals-header">
                    <div className="back" onClick={handleBackCLick}>
                        <img src={backArrow} alt="" />
                    </div>
                    <div className="title">{_goals}</div>
                </div>
                <div className="all-goals-content">
                    {hideAddGoalBtn != null && hideAddGoalBtn === false && (
                        <div
                            className="add-goal"
                            onClick={() => {
                                setView(1);
                            }}
                        >
                            <div className="icon">
                                <img src={AddIcon} alt="ADD NEW GOAL" />
                            </div>
                            <div className="label">{newGoals}</div>
                        </div>
                    )}
                    <Tabs selected={0} handleChange={setActiveTab}>
                        <Tab title={active}>
                            {ActiveGoals}
                        </Tab>
                        <Tab title={completed}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {isLoading ? (
                                <Loader position="relative" background="transparent" />
                            ) : goals.list.length > 0 ? (
                                <div className="goals-list-container">
                                    <div className="completed-goals">
                                        {goals.list.map(goal => (
                                            <ActiveCard
                                                key={goal.id}
                                                goal={goal}
                                                editCallBack={editCallBack}
                                                deleteCallBack={deleteCallBack}
                                                callBack={() => {}}
                                                fromScreen="AllGoals"
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="empty-goals">
                                    {Image}
                                    <div className="empty-goals__message">{dontHaveGoalsAtThisMoment}</div>
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <BottomScrollListener onBottom={scrollCallback} offset={0} debounce={1000} />
        </div>
    );
};

TabViewByCompletion.propTypes = {
    hideAddGoalBtn: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    goals: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.shape({})),
        count: PropTypes.number,
        completedGoals: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    page: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    deleteGoalAction: PropTypes.func.isRequired,
    actionCallBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
    deleteCallBack: PropTypes.func.isRequired,
};

export default withRouter(TabViewByCompletion);
