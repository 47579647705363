import React, { Fragment, useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import WarningModal from '../../../components/WarningModal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import { editGoalApi } from '../../HomeContainer/redux/apis';
import CustomDateTimePicker from '../../../components/DateTimePicker';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { fontColors, fontFamilies } from '../../../common/fontMap';
import Actionbutton from '../../../components/ActionButton';
import Loader from '../../../components/Loader';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../utils/functions';

const EditGoal = ({ goalDetail, closeModal, editCallBack }) => {
    // const questionMap = {
    //     text_area: 'goal_text',
    //     date: 'due_date',
    // };
    const [goal, setGoal] = useState({
        goal_text: goalDetail.goal_text,
        goal_description: goalDetail.goal_description,
        due_date: goalDetail.due_date?.replace('Z', ''),
    });
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    useEffect(() => {
        pageAnalytics.initialise(`todoEdit/${goalDetail.id}`);
        return () => {
            pageAnalytics.complete(`todoEdit/${goalDetail.id}`);
        };
        // eslint-disable-next-line
    }, []);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { editTask, taskTitle, taskDetails, remindMeOn, saveChanges } = localisationsAfterLogin;

    const [isLoading, setIsLoading] = useState(false);
    const [error, errorCallBack] = useState(false);

    const { id } = goalDetail;

    // eslint-disable-next-line no-unused-vars
    const [goalQuestions, setGoalQuestions] = useState([
        {
            question_type: 'text_area',
            type: 'goal_text',
            header: taskTitle,
            setInput: (goal, goal_text) => {
                setGoal({
                    ...goal,
                    goal_text,
                });
            },
        },
        {
            question_type: 'text_area',
            type: 'goal_description',
            header: taskDetails,
            setInput: (goal, goal_description) => {
                setGoal({
                    ...goal,
                    goal_description,
                });
            },
        },
        {
            question_type: 'date',
            header: remindMeOn,
        },
    ]);

    const setDueDate = due_date => {
        setGoal({
            ...goal,
            due_date,
        });
    };

    const editGoal = () => {
        setIsLoading(true);
        const goalText = (goal.goal_text || '').trim();
        const goalDescription = (goal.goal_description || '').trim();
        editGoalApi(id, { ...goal, goal_text: goalText, goal_description: goalDescription, prompt: 'custom' }).then(() => {
            setIsLoading(false);
            closeModal(false);
            editCallBack();
        });
    };

    const showView = () => {
        const views = goalQuestions.map(question => {
            switch (question.question_type) {
                case 'date':
                    return (
                        <Fragment>
                            <div className="editable_item">
                                <div className="title">{question.header}</div>
                                <div className="textarea">
                                    {isClientPG ? (
                                        <CustomDatePicker
                                            selectedDate={goal.due_date}
                                            handleDateChange={setDueDate}
                                            errorCallBack={errorCallBack}
                                        />
                                    ) : (
                                        <CustomDateTimePicker
                                            selectedDate={goal.due_date}
                                            handleDateChange={setDueDate}
                                            errorCallBack={errorCallBack}
                                        />
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    );
                case 'text_area':
                    return (
                        <Fragment>
                            <div className="editable_item" key={question.type}>
                                <div className="title">{question.header}</div>
                                <div className="editable_details">
                                    <div className="question">{question.header}</div>
                                    <textarea
                                        placeholder={question.place_holder_text}
                                        className="goal-textarea"
                                        onChange={event => question.setInput(goal, event.target.value)}
                                        value={goal[question.type]}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    );
                default:
                    return null;
            }
        });

        return views;
    };
    return (
        <WarningModal classNames="edit-goal">
            <div className="edit-task-view">
                <div className="header">
                    <div className="title">
                        <div>{editTask}</div>
                    </div>
                    <div
                        className="cross"
                        onClick={() => {
                            closeModal(false);
                        }}
                    >
                        <Cross color="black" />
                    </div>
                </div>
                <div className="edit-goal-content">
                    {showView()}
                    <Actionbutton
                        fontSize="14px"
                        fontFamily={fontFamilies.TheinhardtPanRegular}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        disabled={goal.goal_text.trim().length < 1 || error || goal.goal_description.trim().length < 1}
                        width="40%"
                        height="auto"
                        padding="10px"
                        onClick={() => {
                            editGoal(id);
                        }}
                    >
                        {saveChanges}
                    </Actionbutton>
                </div>
            </div>
            {isLoading && <Loader showBackground position="absolute" />}
        </WarningModal>
    );
};
EditGoal.propTypes = {
    goalDetail: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
};
export default EditGoal;
