export const getPreAdditionalQuestions = () => ({
    type: 'GET_PRE_ADDITIONAL_QUESTIONS_PENDING',
});

export const storePreAdditionalQuestions = data => ({
    type: 'GET_PRE_ADDITIONAL_QUESTIONS_DONE',
    data,
});

export const dontShowWeeklyQuestions = () => ({
    type: 'DONT_SHOW_WEEKLY_QUESTION_FROM_HERE',
});

export const closeWeeklyQuestionModal = () => ({
    type: 'CLOSE_PRE_ADDITIONAL_QUESTIONS_MODAL',
});

export const setSeenPercentage = index => ({
    type: 'SET_SEEN_PERCENTAGE_OF_PRE_ADDITIONAL_QUESTION',
    index,
});

export const storeAnsweredQuestionResponse = payload => ({
    type: 'POST_PRE_ADDITIONAL_QUESTION_ANSWER_DONE',
    payload,
});

export const postAnswer = payload => ({
    type: 'POST_PRE_ADDITIONAL_QUESTION_ANSWER_PENDING',
    payload,
});

export const storePreAdditionalAnsweredQuestionResponse = payload => ({
    type: 'POST_PRE_ADDITIONAL_QUESTION_ANSWER_DONE',
    payload,
});

export const userPreAdditionalQuestionComment = payload => ({
    type: 'POST_PRE_ADDITIONAL_QUESTION_COMMENT',
    payload,
});
