/* eslint-disable react/no-danger */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../Text';
import ArrowCircle from '../../assets/arrow-circle.svg';
import ArrowCircleBlack from '../../assets/arrow-circle-black.svg';
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg';
import './index.scss';
import CTATypeMapper from './CTATypeMapper';
import WarningModal from '../WarningModal';
import Frame from '../../assets/Frame.svg';
import PromptDetails, { PracticeStateType } from '../../containers/PracticesDetailsContainer/PromptDetails';

const CardContainer = styled.div`
    width: 100%;
    min-height: 12.5rem
    border-radius: ${({ isThumbnailTop }) => (isThumbnailTop ? '0.55rem' : '0.4rem')};
    background-color: ${fontColors.siteWhite};
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    position:relative;
`;

function CustomizeAnnouncementCard({
    key,
    title,
    body,
    closeCallback,
    history,
    ctaExternal,
    ctaInternal,
    ctaType,
    id,
    cta_text,
    cardType,
    thumbnail,
    updateNewCardStatus,
    thumbnailTop,
    textColor,
    setShowCreatePost,
    articleId,
    practiceId,
    showRemoveIcon,
    bgColor,
    is_hide_close_button,
}) {
    const card = new CTATypeMapper(history, ctaType, ctaExternal, id, ctaInternal, practiceId);
    const [openModal, setOpenModal] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);

    const handleProfileCompleteOnClick = () => {
        if (cardType === 3 && ((ctaType === 1 && ctaInternal) || (ctaType === 2 && ctaExternal))) {
            updateNewCardStatus(card.id);
            if (ctaExternal.includes('html')) {
                setOpenModal(true);
            } else {
                card.openUrl({
                    setShowCreatePost,
                    setShowPrompt,
                });
            }
        }
    };

    const handleCrossClick = () => {
        setShowPrompt(false);
    };

    const getNormalTemplate = () => (
        <Fragment>
            <div className="left_panel" style={{ padding: '1.25rem' }}>
                <div className="element normal-header">
                    <Text
                        fontsize="0.75rem"
                        fontcolor={fontColors.placeholderGrey}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        letterSpacing="0.75px"
                        textTransform="uppercase"
                        fontweight="500"
                        lineHeight="1.125rem"
                    >
                        {title}
                    </Text>
                    {showRemoveIcon && !is_hide_close_button && (
                        <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                            <Cross color={fontColors.textBlack} />
                        </div>
                    )}
                </div>
                <div className="element">
                    <Text
                        fontsize="1.125rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontweight="400"
                        margin="0 0 0.5rem 0"
                        width="85%"
                    >
                        {body}
                    </Text>
                </div>
                <div className="element" onClick={handleProfileCompleteOnClick}>
                    {cardType === 3 && ((ctaType === 1 && ctaInternal) || (ctaType === 2 && ctaExternal)) && (
                        <Text
                            fontsize="1rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.MckinseySansMedium}
                            fontweight="500"
                            margin="0 0.625rem 0 0"
                        >
                            {cta_text}
                        </Text>
                    )}
                    {cardType === 3 && ((ctaType === 1 && ctaInternal) || (ctaType === 2 && ctaExternal)) && (
                        <img src={ArrowCircleBlack} alt="arrow" className="pointer" />
                    )}
                </div>
            </div>
            <div className="fimage">
                <img src={thumbnail} alt="" />
            </div>
        </Fragment>
    );

    const getColoredTemplate = () => (
        <Fragment>
            <div className="headerImage">
                <img src={thumbnailTop} alt="Thumbnail Top" />
            </div>
            <div
                className="left_panel"
                style={{
                    justifyContent: 'center',
                    flex: 1,
                }}
            >
                <div className="element no_border">
                    <Text
                        fontsize="0.875rem"
                        fontcolor={textColor}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight={500}
                        lineHeight="1.25rem"
                        letterSpacing="1px"
                        textTransform="uppercase"
                        margin="0 0 0.625rem 0"
                    >
                        {title}
                    </Text>
                    {showRemoveIcon && !is_hide_close_button && (
                        <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none colored-cross">
                            <Cross color="white" showCircle={false} />
                        </div>
                    )}
                </div>
                <div className="element">
                    <Text
                        fontsize="1.75rem"
                        lineHeight="2rem"
                        fontcolor={textColor}
                        fontfamily={fontFamilies.playfairBold}
                        fontweight={700}
                        margin="0 0 0.625rem 0"
                        letterSpacing="-0.2px"
                    >
                        {body}
                    </Text>
                </div>
                <div className="element" onClick={handleProfileCompleteOnClick}>
                    {cardType === 3 && ((ctaType === 1 && ctaInternal) || (ctaType === 2 && ctaExternal)) && (
                        <Text
                            fontsize="1rem"
                            fontcolor={textColor}
                            fontfamily={fontFamilies.MckinseySansMedium}
                            fontweight={500}
                            margin="0 0.5rem 0 0"
                            lineHeight="1.063rem"
                            letterSpacing="0.5px"
                        >
                            {cta_text}
                        </Text>
                    )}
                    {cardType === 3 && ((ctaType === 1 && ctaInternal) || (ctaType === 2 && ctaExternal)) && (
                        <img src={ArrowCircle} alt="arrow" className="pointer" />
                    )}
                </div>
            </div>
            <div className="colored-fimage">
                <img src={thumbnail} alt="Thumbnail" />
            </div>
        </Fragment>
    );

    return (
        <CardContainer
            key={key}
            className="customAnnouncementCard"
            style={{ backgroundColor: thumbnailTop ? bgColor : fontColors.siteWhite }}
            isThumbnailTop={thumbnailTop}
        >
            {openModal && (
                <WarningModal>
                    <div className="cardtype1modal">
                        <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                            <Cross color="black" />
                        </div>
                        <div className="img">
                            <img src={Frame} alt="" />
                        </div>
                        <div className="html" dangerouslySetInnerHTML={{ __html: ctaExternal }} />
                    </div>
                </WarningModal>
            )}
            {showPrompt && (
                <PromptDetails
                    article={{
                        id: articleId,
                        state: PracticeStateType.lockedOrNew,
                        from: 'homePage',
                    }}
                    handleCrossClick={handleCrossClick}
                    practiceId={practiceId}
                />
            )}
            {thumbnailTop ? getColoredTemplate() : getNormalTemplate()}
        </CardContainer>
    );
}

CustomizeAnnouncementCard.defaultProps = {
    practiceId: -1,
    ctaInternal: -1,
    articleId: -1,
    key: -1,
};

CustomizeAnnouncementCard.propTypes = {
    key: PropTypes.number,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    closeCallback: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    ctaExternal: PropTypes.string.isRequired,
    ctaInternal: PropTypes.number,
    ctaType: PropTypes.number.isRequired,
    cardType: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    cta_text: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    thumbnailTop: PropTypes.string.isRequired,
    updateNewCardStatus: PropTypes.func.isRequired,
    // getHomePage: PropTypes.func.isRequired,
    setShowCreatePost: PropTypes.func.isRequired,
    practiceId: PropTypes.number,
    articleId: PropTypes.number,
    showRemoveIcon: PropTypes.bool.isRequired,
    bgColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    is_hide_close_button: PropTypes.bool.isRequired,
};

export default withRouter(CustomizeAnnouncementCard);
