import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.png';
import './index.scss';
import { getArticleInfo } from '../UnAuthenticatedContent/apis';
import Loader from '../../components/Loader';
import EmailCard from '../../components/EmailCard';
import noThanks from '../../assets/noThanks.svg';
import EmailFooter from '../../components/EmailFooter';

const NoThanks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [articleDetails, setArticleDetails] = useState({ localization: {} });

    useEffect(() => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const baseUrl = url.searchParams.get('base_url');
        const tagId = url.searchParams.get('tag_id');
        const cta = url.searchParams.get('cta');
        setIsLoading(true);
        getArticleInfo({ baseUrl, tagId, cta })
            .then(response => {
                setIsLoading(false);
                const { data, localization } = response.data.data;
                setArticleDetails({
                    practice_name: data.practice_name,
                    article_title: data.article_title,
                    localization,
                });
            })
            .catch(() => {
                setIsLoading(false);
                setArticleDetails({ localization: {} });
                // history.push('/404-not-found');
            });
    }, []);

    const {
        youJourneyYourPace,
        makeYourJourney,
        goToInspireWeb,
        mayBeNextTime,
        noThanksMessage,
        inspireWebLink,
        appStoreLink,
        playStoreLink,
    } = articleDetails.localization;
    const { practice_name, article_title } = articleDetails;
    return (
        <div className="no-thanks">
            {isLoading ? (
                <Loader position="absolute" showBackground />
            ) : (
                <div className="header">
                    <div className="logo">
                        <img src={logo} alt="Inspire" />
                    </div>
                    <div className="content">
                        <div className="label">{mayBeNextTime}</div>
                        <div className="page-details">
                            <div className="image">
                                <img src={noThanks} alt="No Thanks" />
                            </div>

                            <div className="title">{noThanksMessage}</div>

                            <div className="practice-detail">
                                <EmailCard title={practice_name} subtitle={article_title} />
                            </div>
                            {articleDetails.localization.is_hide_footer && articleDetails.localization.is_hide_footer === "true" ? (
                                <div />
                            ) : (
                                <div className="footer">
                                    <EmailFooter
                                        heading={youJourneyYourPace}
                                        subHeading={makeYourJourney}
                                        link={goToInspireWeb}
                                        inspireWebLink={inspireWebLink}
                                        appStoreLink={appStoreLink}
                                        playStoreLink={playStoreLink}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NoThanks;

NoThanks.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
