import React from 'react'
import './index.scss'
import completedBadgeDottedImage from '../../assets/completedBadgeDottedImage.svg';
import completedBadgeBalloonCelebrationMobile from '../../assets/completedBadgeBalloonCelebrationMobile.svg';
import completedBadgeBalloonCelebration from '../../assets/completedBadgeBalloonCelebration.svg'
import LocalisationsStore from '../../common/LocalisationsStore'
import { useMobileCheck } from '../../utils/functions';

const CompletedBadge = () => {
    const { localisationsAfterLogin } = LocalisationsStore
    const { you_have_completed_for_today, congratulations } = localisationsAfterLogin;
    const isMobile = useMobileCheck();

    return (
        <div className="completedBadge">
            <div className="lImage">
                <img src={completedBadgeDottedImage} alt="Dotted Badge" />
            </div>
            <div className="content">
                <div className="congratulations">{congratulations}</div>
                <div className="completed">{you_have_completed_for_today}</div>
            </div>
            <div className="rImage">
                <img src={isMobile ? completedBadgeBalloonCelebrationMobile : completedBadgeBalloonCelebration} alt="Celebration Badge" />
            </div>
        </div>
    )
}

export default CompletedBadge
