import React, { useRef, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../common/LocalisationsStore';

const hoursMap = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const minutesMap = ['00', '30'];

const formatMap = ['AM', 'PM'];

const TimePicker = ({ onChange, time, isTimeOpen, setIsTimeOpen }) => {
    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || { hrs: 'hrs', Min: 'Min' };
    const { hrs, Min } = localisationsAfterLogin;
    const timeRef = useRef();
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isTimeOpen && timeRef.current && !timeRef.current.contains(e.target)) {
                setIsTimeOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isTimeOpen, setIsTimeOpen]);

    return (
        <div className="time-picker" ref={timeRef}>
            <div className="hour-selector">
                {hoursMap.map(el => (
                    <div
                        className={`value ${el === `${time.hr}` ? 'selected' : ''}`}
                        key={el}
                        onClick={event => {
                            onChange(event, el, 'hr');
                        }}
                    >
                        {el}
                    </div>
                ))}
            </div>
            <div className="label">{hrs}</div>
            <div className="minute-selector">
                {minutesMap.map(el => (
                    <div
                        className={`value ${el === `${time.min}` ? 'selected' : ''}`}
                        key={el}
                        onClick={event => {
                            onChange(event, el, 'min');
                        }}
                    >
                        {el}
                    </div>
                ))}
            </div>
            <div className="label">{Min}</div>
            <div className="time-format">
                {formatMap.map(el => (
                    <div
                        className={`value ${el === `${time.format}` ? 'selected' : ''}`}
                        key={el}
                        onClick={event => {
                            onChange(event, el, 'format');
                        }}
                    >
                        {el}
                    </div>
                ))}
            </div>
        </div>
    );
};

TimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    time: PropTypes.object.isRequired,
    isTimeOpen: PropTypes.bool.isRequired,
    setIsTimeOpen: PropTypes.func.isRequired,
};


export default TimePicker;
