import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Input, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import searchIcon from './assets/searchIcon.svg';
import PreviewProfileImage from './PreviewProfileImage';
import LocalisationsStore from '../../common/LocalisationsStore';

const SharePostModal = ({
    openSharePostModal,
    handleSharePostClose,
    fetchUsersList,
    userListForSharePost,
    shareePost,
    articleId,
    fetchMoreUsersList,
    allUsersFetched,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { sharePost, searchPeople, selectedUsers } = localisationsAfterLogin;

    const [searchInput, setSearchInput] = useState('');
    const [selectedUsersList, setSelectedUsersList] = useState([]);
    const [selectedUserCount, setSelectedUserCount] = useState(0);
    const [page, setPage] = useState(1);

    const fetchUsersListCall = useCallback(fetchUsersList, []);

    useEffect(() => {
        fetchUsersListCall(1);
    }, [fetchUsersListCall]);

    const handleOnChange = e => {
        const { value } = e.target;
        setSearchInput(value);
        fetchUsersList(1, value);
        setPage(1);
    };

    const handleShareClick = () => {
        const payload = {
            article_id: articleId,
            to: selectedUsersList,
        };
        shareePost(payload);
        handleSharePostClose();
    };

    const handleCheckBoxChange = e => {
        const { checked, value } = e.target;
        const selectedUsers = selectedUsersList;
        if (checked) {
            selectedUsers.push(parseInt(value, 10));
        } else {
            selectedUsers.splice(selectedUsers.indexOf(parseInt(value, 10)), 1);
        }
        setSelectedUsersList(selectedUsers);
        setSelectedUserCount(selectedUsers.length);
    };

    const scrollCallback = () => {
        if (!allUsersFetched && userListForSharePost.length > 0) {
            setPage(page + 1);
            fetchMoreUsersList(page + 1, searchInput);
        }
    };

    return (
        <Modal open={openSharePostModal} className="share_post_modal">
            <div className="share_post_container">
                <div className="heading_container">
                    <span className="heading_share_post">{sharePost}</span>
                    <div
                        className="close_button"
                        onClick={handleSharePostClose}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                    >
                        <Cross color="black" />
                    </div>
                </div>
                <Input
                    className="search_input_field"
                    placeholder={searchPeople}
                    startAdornment={<img className="search_icon" src={searchIcon} alt="" />}
                    disableUnderline
                    onChange={handleOnChange}
                    value={searchInput}
                />
                <BottomScrollListener onBottom={scrollCallback}>
                    {scrollRef => (
                        <div ref={scrollRef} className="users_container">
                            {userListForSharePost.map(x => (
                                <div className="d-flex user_details_container">
                                    <div className="user_details">
                                        <div className="profile_image_adjusted">
                                            <PreviewProfileImage
                                                src={x.profile_picture}
                                                alt="image"
                                                width={48}
                                                height={48}
                                                id="myLikePath"
                                            />
                                        </div>
                                        <div className="nameText">{x.full_name}</div>
                                    </div>
                                    <Checkbox
                                        inputProps={{ 'aria-label': 'Checkbox A' }}
                                        className="user_checkbox"
                                        disableRipple
                                        value={x.id}
                                        onChange={handleCheckBoxChange}
                                        checked={selectedUsersList.indexOf(x.id) !== -1}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </BottomScrollListener>
                <div className="share_post_bottom">
                    <div className="selected_users">
                        <span>{selectedUsers}</span>
                        <span>{selectedUserCount}</span>
                    </div>
                    <div className="share_btn" onClick={handleShareClick} role="none">
                        {sharePost}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

SharePostModal.propTypes = {
    openSharePostModal: PropTypes.bool.isRequired,
    handleSharePostClose: PropTypes.func.isRequired,
    fetchUsersList: PropTypes.func.isRequired,
    userListForSharePost: PropTypes.array.isRequired,
    shareePost: PropTypes.func.isRequired,
    articleId: PropTypes.number.isRequired,
    fetchMoreUsersList: PropTypes.func.isRequired,
    allUsersFetched: PropTypes.bool.isRequired,
};

export default SharePostModal;
