import types from './types';

export const getLanguages = (tag, baseUrl, email) => ({
    type: `${types.GET_LANGUAGES}_PENDING`,
    tag,
    baseUrl,
    email,
});

export const storeLanguagesData = data => ({
    type: `${types.GET_LANGUAGES}_SUCCESS`,
    data,
});

export const setLanguage = (payload, baseurl, email) => ({
    type: `${types.POSt_LANG_CHANGE}_PENDING`,
    payload,
    baseurl,
    email,
});

export const languageChanged = value => ({
    type: `${types.LANGUAGE_CHANGED}`,
    value,
});
