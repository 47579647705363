import React, { useEffect, useState, forwardRef } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import LikeSection from './LikeSection';
import SharePostModal from './SharePostModal';
import { isMobile } from '../../utils/constants';

const IllustrationFeed = forwardRef(
    (
        {
            feedItem,
            renderMedia,
            isLiked,
            likedId,
            postLike,
            postSavedPosts,
            savedPostId,
            likeDetails,
            getLikeDetails,
            isLoading,
            followedId,
            postFollowUser,
            showInterventionPage,
            setShowInterventionPage,
            clickedArticle,
            fetchUsersList,
            userListForSharePost,
            sharePost,
            postUnfollowUser,
            getUsersFollowed,
            followedUsers,
            setFollowUnfollowInFeeds,
            fetchMoreUsersList,
            allUsersFetched,
            removeBookMark,
        },
        ref,
    ) => {
        const [feedBookmarked, setFeedBookmarked] = useState(feedItem.is_bookmarked);
        const [openSharePostModal, toggleSharePostModal] = useState(false);

        const savePost = id => {
            if (feedBookmarked) {
                removeBookMark(id);
                setFeedBookmarked(false);
            } else {
                postSavedPosts({ articleId: id });
            }
        };
        useEffect(() => {
            if (savedPostId === feedItem.id) {
                setFeedBookmarked(true);
            }
        }, [savedPostId, feedItem.id]);

        const handleClickOnSharePost = () => {
            toggleSharePostModal(true);
        };

        const handleSharePostClose = () => {
            toggleSharePostModal(false);
        };

        return (
            feedItem && (
                <div className="feedCardWrapper" ref={ref}>
                    <div className="animationHeading">{feedItem.title}</div>
                    <div
                        id="animate_to_leftid"
                        onClick={() => setShowInterventionPage(true, feedItem.id)}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                        className={
                            showInterventionPage && clickedArticle === feedItem.id
                                ? `${
                                    !isMobile ? 'animate_to_left' : 'animate_to_top_mobile'
                                } outline-none feeds_page_animations`
                                : 'outline-none feeds_page_animations'
                        }
                    >
                        {renderMedia({
                            fileType: feedItem.file_type,
                            fileSrc: feedItem.file,
                            videoUrl: feedItem.file,
                            id: feedItem.id,
                            description: feedItem.description,
                            config: feedItem.config,
                            type: feedItem.type,
                        })}
                    </div>
                    <div className="feedActions">
                        <div className="d-flex">
                            <LikeSection
                                postLike={postLike}
                                feedItem={feedItem}
                                likedId={likedId}
                                isLiked={isLiked}
                                feedType="article"
                                likeDetails={likeDetails}
                                getLikeDetails={getLikeDetails}
                                isLoading={isLoading}
                                isFollowing={feedItem.is_following}
                                followedId={followedId}
                                postFollowUser={postFollowUser}
                                postUnfollowUser={postUnfollowUser}
                                getUsersFollowed={getUsersFollowed}
                                followedUsers={followedUsers}
                                setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                            />
                        </div>
                        <div className="d-flex">
                            <div
                                className={`outline-none ${feedBookmarked ? 'savedPostBtn' : 'savePostBtn'}`}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={-1}
                                onClick={() => savePost(feedItem.id)}
                            />

                            <div className="shareBtn" onClick={handleClickOnSharePost} role="none" />
                        </div>
                    </div>
                    {openSharePostModal && (
                        <SharePostModal
                            openSharePostModal={openSharePostModal}
                            handleSharePostClose={handleSharePostClose}
                            fetchUsersList={fetchUsersList}
                            userListForSharePost={userListForSharePost}
                            shareePost={sharePost}
                            articleId={feedItem.id}
                            fetchMoreUsersList={fetchMoreUsersList}
                            allUsersFetched={allUsersFetched}
                        />
                    )}
                </div>
            )
        );
    },
);

IllustrationFeed.defaultProps = {
    clickedArticle: null,
    followedId: 0,
};

IllustrationFeed.propTypes = {
    feedItem: PropTypes.shape({
        title: PropTypes.string.isRequired,
        file_type: PropTypes.string,
        file: PropTypes.object.isRequired,
        embedded_url: PropTypes.string,
        id: PropTypes.number.isRequired,
        is_following: PropTypes.bool.isRequired,
        is_bookmarked: PropTypes.bool.isRequired,
        is_new: PropTypes.bool.isRequired,
        description: PropTypes.string,
        config: PropTypes.shape({
            colors: PropTypes.arrayOf(PropTypes.string),
            fontFamily: PropTypes.string,
            index: PropTypes.number,
        }),
        type: PropTypes.string,
    }).isRequired,
    postLike: PropTypes.func.isRequired,
    renderMedia: PropTypes.func.isRequired,
    isLiked: PropTypes.bool.isRequired,
    likedId: PropTypes.number.isRequired,
    setShowInterventionPage: PropTypes.func.isRequired,
    showInterventionPage: PropTypes.bool.isRequired,
    postSavedPosts: PropTypes.func.isRequired,
    savedPostId: PropTypes.number.isRequired,
    likeDetails: PropTypes.array.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    followedId: PropTypes.number.is,
    postFollowUser: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    clickedArticle: PropTypes.number,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    fetchUsersList: PropTypes.func.isRequired,
    userListForSharePost: PropTypes.array.isRequired,
    sharePost: PropTypes.func.isRequired,
    fetchMoreUsersList: PropTypes.func.isRequired,
    allUsersFetched: PropTypes.bool.isRequired,
    removeBookMark: PropTypes.func.isRequired,
};

export default IllustrationFeed;
