import types from './types';

export const getNotificationsList = () => ({
    type: `${types.GET_NOTIFICATIONS_LIST}_PENDING`,
});

export const storeNotificationsList = data => ({
    type: `${types.GET_NOTIFICATIONS_LIST}_DONE`,
    data,
});

export const storeMoreNotificationsList = data => ({
    type: `${types.GET_NOTIFICATIONS_LIST_MORE}_DONE`,
    data,
});

export const markThisNotificationAsDeleted = id => ({
    type: types.MARK_THIS_NOTIFICATION_AS_DELETED,
    id,
});

export const markThisNotificationAsRead = id => ({
    type: types.MARK_THIS_NOTIFICATION_AS_READ,
    id,
});

export const readThisNotification = id => ({
    type: `${types.READ_THIS_NOTIFICATION}_PENDING`,
    id,
});

export const markAllRead = () => ({
    type: `${types.MARK_ALL_AS_READ}_PENDING`,
});

export const incrementPageNumber = () => ({
    type: `${types.INCREMENT_PAGE_NUMBER}`,
});

export const resetPageNumber = () => ({
    type: types.RESET_NOTIFICATIONS_PAGE_NUMBER,
});

export const toggleReachedEndOfNotificationsList = value => ({
    type: types.REACHED_END_OF_NOTIFICATIONS_LIST,
    value,
});

export const emptyNotificationList = data => ({
    type: types.EMPTY_NOTIFICATION_LIST,
    data,
});

export const getNotificationsListMore = () => ({
    type: `${types.GET_NOTIFICATIONS_LIST_MORE}_PENDING`,
});
