const types = {
    ADD_FILE_TO_LIST: 'NOTIFICATION_ADD_FILE_TO_LIST',
    UPLOAD_POST: 'NOTIFICATION_UPLOAD_POST',
    GET_TAGS: 'NOTIFICATION_GET_TAGS',
    GET_FEEDS_PENDING: 'NOTIFICATION_GET_FEEDS_PENDING',
    GET_FEEDS_SUCCESS: 'NOTIFICATION_GET_FEEDS_SUCCESS',
    GET_FEEDS_FAILED: 'NOTIFICATION_GET_FEEDS_FAILED',
    GET_FEEDS_MORE_PENDING: 'NOTIFICATION_GET_FEEDS_MORE_PENDING',
    GET_FEEDS_MORE_SUCCESS: 'NOTIFICATION_GET_FEEDS_MORE_SUCCESS',
    GET_FEEDS_MORE_FAILED: 'NOTIFICATION_GET_FEEDS_MORE_FAILED',
    POST_LIKE_PENDING: 'NOTIFICATION_POST_LIKE_PENDING',
    POST_LIKE_SUCCESS: 'NOTIFICATION_POST_LIKE_SUCCESS',
    POST_LIKE_FAILURE: 'NOTIFICATION_POST_LIKE_FAILURE',
    TOGGLE_CREATE_POST_MODAL: 'NOTIFICATION_TOGGLE_CREATE_POST_MODAL',
    GET_THIS_FEED: 'NOTIFICATION_GET_THIS_FEED',
    DELETE_POST_PENDING: 'NOTIFICATION_DELETE_POST_PENDING',
    DELETE_POST_SUCCESS: 'NOTIFICATION_DELETE_POST_SUCCESS',
    DELETE_POST_FAILURE: 'NOTIFICATION_DELETE_POST_FAILURE',
    GET_SAVED_POSTS_PENDING: 'NOTIFICATION_GET_SAVED_POSTS_PENDING',
    GET_SAVED_POSTS_SUCCESS: 'NOTIFICATION_GET_SAVED_POSTS_SUCCESS',
    GET_SAVED_POSTS_FAILURE: 'NOTIFICATION_GET_SAVED_POSTS_FAILURE',
    POST_SAVED_POSTS_PENDING: 'NOTIFICATION_POST_SAVED_POSTS_PENDING',
    POST_SAVED_POSTS_SUCCESS: 'NOTIFICATION_POST_SAVED_POSTS_SUCCESS',
    POST_SAVED_POSTS_FAILURE: 'NOTIFICATION_POST_SAVED_POSTS_FAILURE',
    GET_LIKE_DETAILS_PENDING: 'NOTIFICATION_GET_LIKE_DETAILS_PENDING',
    GET_LIKE_DETAILS_SUCCESS: 'NOTIFICATION_GET_LIKE_DETAILS_SUCCESS',
    GET_LIKE_DETAILS_FAILURE: 'NOTIFICATION_GET_LIKE_DETAILS_FAILURE',
    POST_FOLLOW_USER_PENDING: 'NOTIFICATION_POST_FOLLOW_USER_PENDING',
    POST_FOLLOW_USER_SUCCESS: 'NOTIFICATION_POST_FOLLOW_USER_SUCCESS',
    POST_FOLLOW_USER_FAILURE: 'NOTIFICATION_POST_FOLLOW_USER_FAILURE',
    GET_USERS_OF_TYPE: 'NOTIFICATION_GET_USERS_OF_TYPE',
    GET_ALL_COMMENTS: 'NOTIFICATION_GET_ALL_COMMENTS',
    LIKE_COMMENT: 'NOTIFICATION_LIKE_COMMENT',
    DELETE_COMMENT: 'NOTIFICATION_DELETE_COMMENT',
    UPDATE_FEEDS: 'NOTIFICATION_UPDATE_FEEDS',
    POST_QUIZ_ANSWER: 'NOTIFICATION_POST_QUIZ_ANSWER',
    RESET_QUIZ_DATA: 'NOTIFICATION_RESET_QUIZ_DATA',
    REPORT_POST: 'NOTIFICATION_REPORT_POST',
    RESET_ALL_FEED_FETCHED: 'NOTIFICATION_RESET_ALL_FEED_FETCHED',
    GET_WEEKLY_QUESTIONS: 'NOTIFICATION_GET_WEEKLY_QUESTIONS',
    POST_UNFOLLOW_USER: 'NOTIFICATION_POST_UNFOLLOW_USER',
    GET_USERS_FOLLOWED: 'NOTIFICATION_GET_USERS_FOLLOWED',
    SET_FOLLOW_UNFOLLOW_FEEDS: 'NOTIFICATION_SET_FOLLOW_UNFOLLOW_FEEDS',
    FETCH_USERS_LIST: 'NOTIFICATION_FETCH_USERS_LIST',
    SHARE_POST: 'NOTIFICATION_SHARE_POST',
    FETCH_MORE_USERS_LIST: 'NOTIFICATION_FETCH_MORE_USERS_LIST',
    REMOVE_BOOKMARK: 'NOTIFICATION_REMOVE_BOOKMARK',
};

export default types;
