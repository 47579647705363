import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import WarningModal from '../../components/WarningModal';
import './index.scss';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import Text from '../../components/Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import TodoAction from '../PracticesDetailsContainer/TodoAction';
import { changeArticleStatusApi, startPracticeApi } from '../PracticesDetailsContainer/redux/apis';
import CompleteAction from '../PracticesDetailsContainer/CompleteAction';
import ArticleAction from '../PracticesDetailsContainer/ArticleAction';
import OrientationAction from '../PracticesDetailsContainer/OrientationAction';
import HybridGoals from '../HybridGoals';
import Quiz from '../HybridGoals/components/Quiz';
import PromptQuestion from '../HybridGoals/components/PromptQuestion';
import ShareView from '../HybridGoals/components/ShareView';
import Carousel from '../HybridGoals/components/Carousel';
import { CODE_CLIENT_EXPIRED } from '../../utils/constants';
import { clientExpiredAction, resetClientExpiredAction } from '../../common/actions';
import Stepper from '../../components/Stepper';
import Loader from '../../components/Loader';
import { pageAnalytics, nullable } from '../../utils/functions';

const PromptArticle = ({
    handleCrossClick,
    getArticleDetails,
    promptDetailsData,
    setShowCreatePost,
    id,
    refreshGoalAction,
    refreshTodoAction,
    clientExpired,
    resetPromptData,
    getHomePage,
    shouldSendAnalytics,
    promptLoading,
}) => {
    const [is_notification_active, setNotificationActive] = useState(false);
    const [hybridData, setHybridData] = useState({ question_response: {}, quiz_response: {} });
    const [showToolTip, setShowToolTip] = useState(false);
    const [disableDiv, setDisableDiv] = useState(false);
    const history = useHistory();
    const myRef = React.createRef();
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    const refToTop = React.createRef();
    const promptId = promptDetailsData?.id;
    const [currentPromptId, setCurrentPromptId] = useState(-1);

    useEffect(() => {
        resetPromptData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refToTop && refToTop.current) {
            refToTop.current.scrollIntoView();
        }
        // eslint-disable-next-line
    }, [currentPromptId]);

    useEffect(() => {
        if (shouldSendAnalytics && currentPromptId && currentPromptId !== -1) {
            pageAnalytics.initialise(`promptDetail/${currentPromptId}`);
            return () => {
                pageAnalytics.complete(`promptDetail/${currentPromptId}`);
            };
        }
        return (() => {});
    }, [currentPromptId, shouldSendAnalytics]);


    useEffect(() => {
        if (promptId) {
            setCurrentPromptId(promptId);
        }
    }, [promptId]);


    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };
    useEffect(() => {
        getArticleDetails(id);
    }, [getArticleDetails, id]);


    useEffect(() => {
        setDisableDiv(false);
    }, [promptDetailsData]);

    const changePracticeStatus = (practiceId, status) => {
        changeArticleStatusApi(promptDetailsData.id, status)
            .then(() => {
                getArticleDetails(promptDetailsData.id);
                if (status === 'remove_todo') {
                    refreshTodoAction();
                    handleCrossClick({ show: false, dataProps: { in_todo: false, id: promptDetailsData.id } });
                }
            })
            .catch(e => {
                if (e.response.status === CODE_CLIENT_EXPIRED) {
                    clientExpiredAction();
                    moveToClientExpirePage();
                } else {
                    resetClientExpiredAction();
                }
            });
    };

    const addToDo = (id, status) => changeArticleStatusApi(id, status)
        .then(() => {
            // resetPromptData();
            getArticleDetails(id);
        })
        .catch(e => {
            if (e.response.status === CODE_CLIENT_EXPIRED) {
                clientExpiredAction();
                moveToClientExpirePage();
            } else {
                resetClientExpiredAction();
            }
        });

    const startAPractice = (practiceId, status) => {
        startPracticeApi(practiceId, status).then(() => {
            getArticleDetails(id);
            history.push(`/app/practice/${practiceId}`);
        });
    };

    const getRequiredData = () => {
        const { prompt_questions } = promptDetailsData;
        const quiz = promptDetailsData.quiz || {};
        const questions = (prompt_questions || [])
            .filter(elem => elem.required && elem.is_editable)
            .map(item => item.id);
        return {
            questions,
            quiz: [quiz.id],
        };
    };

    const downloadCallback = () => {
        setShowToolTip(true);
    };
    const updateParent = hybridData => {
        setHybridData(hybridData);
    };

    const refreshPromptsPage = (newPromptId, fromStepper) => {
        if (myRef && myRef.current) {
            myRef.current.scrollTo(0, 0);
        }
        if (fromStepper) {
            resetPromptData();
        }
        setHybridData({
            question_response: {},
            quiz_response: {},
        });
        getArticleDetails(newPromptId);
    };

    const disableParent = status => {
        setDisableDiv(status);
    };

    const resetHybridData = () => {
        // also refreshTodlist

        setHybridData({ question_response: {}, quiz_response: {} });
    };

    const getPrevNextWidgetLayout = () => {
        const { state } = promptDetailsData;
        return (
            <HybridGoals
                dataConfig={promptDetailsData}
                hybridData={hybridData}
                required={getRequiredData()}
                state={state}
                callBack={resetHybridData}
                practiceId={promptDetailsData.practiceId}
                changePracticeStatus={addToDo}
                onClose={() => {
                    handleCrossClick({
                        show: false,
                    });
                    refreshGoalAction();
                    refreshTodoAction();
                    getHomePage();
                }}
                refreshCallback={refreshPromptsPage}
                disableParent={disableParent}
                promptLoading={promptLoading}
            />
        );
    };

    const getWidget = () => {
        if (promptDetailsData.completed_on) {
            return (
                <CompleteAction
                    type={promptDetailsData.type}
                    article={promptDetailsData}
                    practiceId={promptDetailsData.practice_id}
                    changePracticeStatus={changePracticeStatus}
                    setShowCreatePost={setShowCreatePost}
                    setShowPrompt={value => {
                        handleCrossClick({ show: value, dataProps: { in_todo: false, id: promptDetailsData.id } });
                    }}
                />
            );
        }
        if (promptDetailsData.in_todo) {
            return (
                <TodoAction
                    promptDetailsData={promptDetailsData}
                    practiceId={promptDetailsData.practice_id}
                    changePracticeStatus={changePracticeStatus}
                    articleId={promptDetailsData.id}
                    setNotificationActive={setNotificationActive}
                    onClose={() => handleCrossClick({ show: false })}
                />
            );
        }
        if (promptDetailsData.type === 'orientation') {
            return (
                <OrientationAction
                    article={promptDetailsData}
                    practiceId={promptDetailsData.practice_id}
                    changePracticeStatus={startAPractice}
                />
            );
        }

        return (
            <ArticleAction
                practiceId={promptDetailsData.practice_id}
                changePracticeStatus={changePracticeStatus}
                handleCrossClick={() => {
                    handleCrossClick({
                        show: false,
                        dataProps: { in_todo: promptDetailsData.in_todo, id: promptDetailsData.id },
                    });
                }}
                type={promptDetailsData.type}
                article={promptDetailsData}
                refreshTodoAction={refreshTodoAction}
                refreshGoalAction={refreshGoalAction}
            />
        );
    };

    const getWidgetType = () => {
        if (promptDetailsData.cta_layout === 'standard') {
            return getWidget();
        }
        return getPrevNextWidgetLayout();
    };

    const getParsedHTML = yourHTML => yourHTML.replaceAll('<a ', '<a target = "_blank" ').replaceAll('<img ', '<img style="width:auto;max-width:350px" ')
        .replaceAll('<video', '<video style="width:auto;max-height:350px;max-width:350px;display: block;margin: 0 auto;" ');

    const getSequencedView = () => {
        const _sequence = promptDetailsData.prompt_component_sequence;
        const prompt_quiz = promptDetailsData.quiz;
        const { prompt_questions } = promptDetailsData;
        const { state } = promptDetailsData;
        const showSharePopUp = promptDetailsData.show_share_popup;
        const sharePopUpData = promptDetailsData.share_popup_data;
        const carousel = promptDetailsData.corousel;

        return _sequence.map(elem => {
            switch (elem) {
                case 'quiz_question':
                    return (
                        prompt_quiz
                        && prompt_quiz.quizoption_set
                        && prompt_quiz.quizoption_set.length > 0 && (
                            <Fragment key={elem}>
                                <Quiz
                                    quiz={prompt_quiz}
                                    callBack={setHybridData}
                                    callBackData={hybridData}
                                    freeze={state === 4}
                                    updateParent={updateParent}
                                />
                                <div className="divider" />
                            </Fragment>
                        )
                    );
                case 'carousel':
                    return (
                        carousel && (
                            <Fragment key={elem}>
                                <Carousel carousel={carousel} />
                                <div className="divider" style={{ margin: '2.5rem 0' }} />
                            </Fragment>
                        )
                    );
                case 'html':
                    return (
                        <Fragment key={elem}>
                            <div
                                className="feed_inner_html"
                                dangerouslySetInnerHTML={{ __html: getParsedHTML(promptDetailsData.description_web) }}
                            />
                            {promptDetailsData.description_web && <div className="divider" />}
                        </Fragment>
                    );
                case 'prompt_question':
                    return (
                        prompt_questions
                        && prompt_questions.length > 0 && (
                            <Fragment key={elem}>
                                <PromptQuestion
                                    callBackData={hybridData}
                                    callBack={setHybridData}
                                    promptQuestions={prompt_questions}
                                    freeze={state === 4}
                                    updateParent={updateParent}
                                />
                                <div className="divider" />
                            </Fragment>
                        )
                    );
                case 'share_popup':
                    return (
                        showSharePopUp && (
                            <Fragment key={elem}>
                                <ShareView
                                    data={sharePopUpData}
                                    downloadCallback={downloadCallback}
                                    promptId={promptDetailsData.id}
                                />
                                {sharePopUpData && <div className="divider" />}
                            </Fragment>
                        )
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <Fragment>
            <WarningModal classNames="prompt" isClientPG={isClientPG}>
                {clientExpired && moveToClientExpirePage()}
                {promptDetailsData ? (
                    <div className="prompt_outer_wrapper" ref={refToTop}>
                        <div
                            className="prompt_detail_wrapper"
                            style={{
                                position: 'relative',
                                overflowY: promptDetailsData.show_article_completed_stepper?.show_stepper
                                    ? 'hidden'
                                    : 'initial',
                            }}
                        >
                            {showToolTip && (
                            <div className="info_popup">
                                <div style={{ width: '320px', paddingLeft: '10px', paddingTop: '5px' }}>
                                    Image downloaded successfully
                                </div>
                                <div
                                    onClick={() => {
                                        setShowToolTip(false);
                                    }}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Cross color="black" />
                                </div>
                            </div>
                            )}
                            {promptDetailsData.show_article_completed_stepper?.show_stepper ? (
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '10px', display: 'flex', flex: 1, alignItems: 'center' }}>
                                        {promptDetailsData.show_article_completed_stepper.article_detail.map(el => <Stepper key={el.article_id} state={el.state} current={el.is_current} articleId={el.article_id} onClick={refreshPromptsPage} />)}
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}
                            <div
                                ref={myRef}
                                style={{
                                    pointerEvents: `${disableDiv ? 'none' : 'auto'}`,
                                    overflowY: promptDetailsData.show_article_completed_stepper?.show_stepper
                                        ? 'auto'
                                        : 'hidden',
                                    overflowX: 'hidden',
                                }}
                            >
                                <div className="title_and_image_wrapper">
                                    {promptDetailsData.hide_practice_image !== undefined
                                    && !promptDetailsData.hide_practice_image && (
                                        <div className="image">
                                            <img src={promptDetailsData.file} alt="" />
                                        </div>
                                    )}
                                    <div className="title_and_subtitle">
                                        <Text
                                            fontsize=".875rem"
                                            fontcolor={fontColors.siteGrey}
                                            fontfamily={fontFamilies.TheinhardtPanRegular}
                                            margin="0.5rem 0 0 0"
                                            letterSpacing="1px"
                                            textTransform="uppercase"
                                        >
                                            {promptDetailsData.sub_title}
                                        </Text>
                                        <Text
                                            fontsize="1.75rem"
                                            fontcolor={fontColors.black}
                                            fontfamily={fontFamilies.playfairBold}
                                        >
                                            {promptDetailsData.title}
                                        </Text>
                                    </div>
                                </div>
                                <div className="divider" />
                                <div className="spacer" />
                                <div>{getSequencedView()}</div>
                                <div className="actions_wrapper_conntainer">{getWidgetType()}</div>
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                refreshTodoAction();
                                refreshGoalAction();
                                getHomePage();
                                handleCrossClick({
                                    show: false,
                                    dataProps: {
                                        is_notification_active,
                                        in_todo: promptDetailsData.in_todo,
                                        id: promptDetailsData.id,
                                    },
                                });
                            }
                            }
                            role="button"
                            tabIndex={0}
                            style={{
                                cursor: 'pointer',
                                marginTop: '10px',
                                marginRight: '10px',
                                position: 'sticky',
                                right: '10px',
                                top: '10px',
                                height: 'fit-content',
                            }}
                            className="outline-none"
                        >
                            <Cross color="black" />
                        </div>
                    </div>
                ) : (
                    <Loader position="absolute" showBackground />
                )}
            </WarningModal>
        </Fragment>
    );
};

export default PromptArticle;

PromptArticle.defaultProps = {
    shouldSendAnalytics: true,
};

PromptArticle.propTypes = {
    handleCrossClick: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    getArticleDetails: PropTypes.func.isRequired,
    promptDetailsData: nullable(PropTypes.object).isRequired,
    setShowCreatePost: PropTypes.func.isRequired,
    refreshGoalAction: PropTypes.func.isRequired,
    refreshTodoAction: PropTypes.func.isRequired,
    clientExpired: PropTypes.bool.isRequired,
    resetPromptData: PropTypes.func.isRequired,
    getHomePage: PropTypes.func.isRequired,
    shouldSendAnalytics: PropTypes.bool,
    promptLoading: PropTypes.bool.isRequired,
};
