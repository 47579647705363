import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import PreviewProfileImage from '../PreviewProfileImage';
import LocalisationsStore from '../../../common/LocalisationsStore';

function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${57}%)`,
    };
}

export default function TeamMembers({ teamMembers, teamMembersList, handleClose }) {
    const [modalStyle] = useState(getModalStyle);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { yourTeam } = localisationsAfterLogin;

    return (
        <Modal open={teamMembers} className="likeModal">
            <div style={modalStyle} className="likeModalBody paper outline-none">
                <div className="close_icon_selector">
                    <div className="likeHeading">{yourTeam}</div>
                    <div
                        className="cursor-pointer outline-none"
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                            e.stopPropagation();
                            handleClose();
                        }}
                    >
                        <Cross color="black" />
                    </div>
                </div>
                {teamMembersList.map(x => (
                    <div className="likesUsers" key={x.liked_by}>
                        <div className="d-flex">
                            <div>
                                <svg height="0" width="0">
                                    <clipPath id="myLikePath">
                                        <path
                                            fill="none"
                                            fillRule="evenodd"
                                            stroke="#979797"
                                            d="M15.542 2.489C6.796 6.793-1.244 20.263 2.092 33.137c2.69 10.38 25.388 26.49 37.996 12.243 18.18-20.543 5.045-31.448-1.641-37.024-5.023-4.189-14.158-10.171-22.905-5.867z"
                                        />
                                    </clipPath>
                                </svg>
                                <div className="profile_image_adjusted">
                                    <PreviewProfileImage
                                        src={x.profile_picture}
                                        alt="image"
                                        width={48}
                                        height={48}
                                        id="myLikePath"
                                    />
                                </div>
                            </div>
                            <div className="nameText">{x.full_name}</div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
}

TeamMembers.propTypes = {
    teamMembers: PropTypes.bool.isRequired,
    teamMembersList: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
            follower_following: PropTypes.bool.isRequired,
            full_name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            profile_picture: PropTypes.string.isRequired,
        }),
    ).isRequired,
    handleClose: PropTypes.func.isRequired,
};
