import React, { Fragment, useState } from 'react';
import './index.scss';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import dotsIcon from '../../../../assets/dots.svg';
import rArrow from '../../../../assets/rArrow.svg';
import { completeGoalApi } from '../../../HomeContainer/redux/apis';
import { Cross } from '../../../UnAuthenticatedContent/assets/svg';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import Actionbutton from '../../../../components/ActionButton';
import EditGoal from '../../EditGoal';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../../utils/functions';
import { GOAL_DETAIL_VIEWS } from '../../../../utils/constants';

const Actions = ({
    goal,
    callBack,
    editCallBack,
    deleteCallBack,
    deleteGoalAction,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const { id } = goal;
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        markAsComplete,
        edit,
        deletePostAction,
        deleteGoal: _deleteGoal,
        goalDeleteConf,
        yesDelete,
        dontDelete,
    } = localisationsAfterLogin;

    const isClientPG = localStorage.getItem('client_config') === 'pg';

    const handleClick = event => {
        if (isClientPG) {
            setEditModal(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const completeGoal = () => {
        completeGoalApi(id)
            .then(() => {
                handleClose();
                callBack(id);
            });
    };

    const deleteGoal = () => {
        setDeleteModal(true);
        handleClose();
        pageAnalytics.initialise(`goalDelete/${id}`);
    };

    const editGoal = () => {
        setEditModal(true);
        handleClose();
    };

    return (
        <Fragment>
            <div>
                <div className="dot-wrapper pointer" onClick={handleClick} id="simple-menu">
                    {isClientPG ? (
                        <img
                            className="pencil-icon"
                            src={rArrow}
                            alt="arrow"
                            role="none"
                        />
                    ) : <img className="report_dots" src={dotsIcon} alt="" role="none" />}
                </div>
                <div>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="goalActions"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem
                            key={1}
                            onClick={() => {
                                completeGoal();
                            }}
                        >
                            <span>{markAsComplete}</span>
                        </MenuItem>

                        <MenuItem key={2} onClick={editGoal}>
                            <span>{edit}</span>
                        </MenuItem>

                        <MenuItem
                            key={3}
                            onClick={() => {
                                deleteGoal();
                            }}
                        >
                            <span>{deletePostAction}</span>
                        </MenuItem>
                    </Menu>
                </div>


            </div>
            {deleteModal && (
                <div className="delete_goal__modal">
                    <div
                        className="close_button"
                        onClick={() => {
                            setDeleteModal(false);
                            pageAnalytics.complete(`goalDelete/${id}`);
                        }}
                        onKeyDown={() => {
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <Cross color="black" />
                    </div>
                    <div className="title">{_deleteGoal}</div>
                    <div className="subtitle">{goalDeleteConf}</div>
                    <Actionbutton
                        fontSize="1.125rem"
                        fontFamily={fontFamilies.TheinhardtPanRegular}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        width="40%"
                        onClick={() => {
                            setDeleteModal(false);
                            handleClose();
                            deleteGoalAction(id);
                            pageAnalytics.complete(`goalDelete/${id}`);
                            setTimeout(() => {
                                if (deleteCallBack) deleteCallBack('pending');
                            }, 1000);
                            /* deleteGoalApi(id).then(() => {
                                setDeleteModal(false);
                                handleClose();
                                deleteCallBack()
                            }); */
                        }}
                    >
                        {yesDelete}
                    </Actionbutton>

                    <div
                        className="no-delete"
                        onClick={() => {
                            setDeleteModal(false);
                            pageAnalytics.complete(`goalDelete/${id}`);
                        }}
                    >
                        {dontDelete}

                    </div>
                </div>
            )}
            {editModal
                && (
                <EditGoal
                    editCallBack={editCallBack}
                    goalObj={goal}
                    closeModal={() => setEditModal(false)}
                    goalView={goal?.is_completed ? GOAL_DETAIL_VIEWS.EDIT : GOAL_DETAIL_VIEWS.VIEW}
                />
                )}
        </Fragment>
    );
};

Actions.propTypes = {
    goal: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
    deleteCallBack: PropTypes.func.isRequired,
    deleteGoalAction: PropTypes.func.isRequired,
};


export default Actions;
