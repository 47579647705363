import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../../../components/Tabs';
import Tab from '../../../../components/Tabs/Tab';
import ActionDetailCard from './ActionDetailCard';
import { PracticeStateType } from '../../../PracticesDetailsContainer/PromptDetails';
import { fontColors } from '../../../../common/fontMap';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import './index.scss';

const ActionDetailsTab = ({ actionList, onClickCard }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { remaining, completed, no_remaining_action, no_completed_action } = localisationsAfterLogin;

    if (!actionList || Object.keys(actionList).length === 0) return null;

    return (
        <div className="action-details-tab-container">
            <Tabs selected={activeTab} handleChange={setActiveTab} classes="action-details-tab">
                <Tab title={remaining}>
                    {actionList?.remaining?.length > 0 ? actionList?.remaining?.map(practice => (
                        <ActionDetailCard
                            key={practice.id}
                            actionText={practice.title}
                            isDisabled={practice.state === PracticeStateType.lockedOrNew}
                            onClickCard={practice.state !== PracticeStateType.lockedOrNew ? onClickCard : null}
                            id={practice.id}
                        />
                    )) : (
                        <ActionDetailCard
                            isEmpty
                            emptyText={no_remaining_action}
                        />
                    )}
                </Tab>
                <Tab title={completed}>
                    {actionList?.completed?.length > 0 ? actionList?.completed?.map(practice => (
                        <ActionDetailCard
                            key={practice.id}
                            actionText={practice.title}
                            textColor={fontColors.siteWhite}
                            backgroundColor={fontColors.indigoDye}
                            isDisabled={practice.state === PracticeStateType.lockedOrNew}
                            onClickCard={practice.state !== PracticeStateType.lockedOrNew ? onClickCard : null}
                            isCompleted
                            id={practice.id}
                        />
                    )) : (
                        <ActionDetailCard
                            isEmpty
                            emptyText={no_completed_action}
                        />
                    )}
                </Tab>
            </Tabs>
        </div>
    );
};

ActionDetailsTab.defaultProps = {
    actionList: null,
};

ActionDetailsTab.propTypes = {
    actionList: PropTypes.shape({
        remaining: PropTypes.arrayOf(PropTypes.shape({})),
        completed: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    onClickCard: PropTypes.func.isRequired,
};

export default ActionDetailsTab;
