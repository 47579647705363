import { takeLatest, put } from 'redux-saga/effects';
import types from './types';
import { apiFailed } from '../../../common/actions';
import { storeUserActivity } from './actions';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getUserActivityApi } from './apis';

function* getUserActivitySaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUserActivityApi,
            payload: [AuthStore.accessToken],
        });
        yield put(storeUserActivity(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const userActivitySaga = [takeLatest(`${types.GET_USER_ACTIVITY_DATA}_PENDING`, getUserActivitySaga)];

export default userActivitySaga;
