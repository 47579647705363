import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../../common/LocalisationsStore';
import InspireLoader from '../../../components/InspireLoader/InspireLoader';
import languageChangeSymbol from '../../../assets/languageChangeSymbol.svg';

import '../index.scss';

export default function LanguageSelectionComponent({
    getLanguages,
    languagesData,
    setLanguage,
    loading,
    changeLanguageLoading,
}) {
    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || {
        language: '',
        langQuestion: '',
        langQuestionDescription: '',
        done: '',
        emailChangeMessage: '',
    };
    const { language, langQuestion, langQuestionDescription, done, emailChangeMessage } = localisationsAfterLogin;

    const [baseUrl, setBaseUrl] = useState('');
    const [tagId, setTagId] = useState('');
    const [selectedLang, setSelectedLang] = useState('');
    const [originalLang, setOriginalLang] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [email, setEmail] = useState('');

    const getLanguagesCall = useCallback(getLanguages, []);

    useEffect(() => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const tag = url.searchParams.get('tag_id');
        const baseUrl = url.searchParams.get('baseurl');
        const email = url.searchParams.get('email');
        setTagId(tag);
        setBaseUrl(baseUrl);
        setEmail(email);
        getLanguagesCall(tag, baseUrl, email);
    }, [getLanguagesCall]);

    useEffect(() => {
        if (languagesData) {
            setSelectedLang(languagesData.selected_lang);
            setOriginalLang(languagesData.selected_lang);
        }
    }, [languagesData]);

    useEffect(() => {
        if (languagesData && originalLang.length > 0 && languagesData.selected_lang !== originalLang) {
            setShowMessage(true);
        }
    }, [changeLanguageLoading, originalLang, languagesData]);

    const handleLanguageClick = event => {
        const { currentTarget: { dataset: { lang } } } = event;
        setSelectedLang(lang);
    };

    const handleOnClick = async () => {
        const payload = {
            lang: selectedLang,
            tag_id: tagId,
        };
        await setLanguage(payload, baseUrl, email);
        setTimeout(() => {
            setShowMessage(true);
        }, 1000);
    };

    return (
        <>
            {loading && (
                <div style={{ height: '100vh', width: '100vw' }}>
                    <InspireLoader isLoading />
                </div>
            )}

            {!loading && languagesData && (
                <div className="container" data-test="email-language-test-attr">
                    <div className="feedsHeader">
                        <div className="logo" role="none" />
                    </div>
                    <div className="bg_blob" />
                    <div className="inner_container">
                        <div className="question_text_container">
                            {showMessage ? (
                                <>
                                    <img src={languageChangeSymbol} alt="" />
                                    <div className="feedback_submitted_message">{emailChangeMessage}</div>
                                </>
                            ) : (
                                <>
                                    <span>{language}</span>
                                    <span>{langQuestion}</span>
                                    <span>{langQuestionDescription}</span>
                                    <div className="languages_list_container">
                                        {languagesData.languages.map((element, index) => (
                                            <div
                                                className={
                                                    element.value === selectedLang
                                                        ? 'selected_language'
                                                        : 'not_elected_language'
                                                }
                                                key={`email-language-${index}`}
                                                onClick={handleLanguageClick}
                                                data-lang={element.value}
                                            >
                                                {element.name}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="btn_container">
                                        <button type="submit" onClick={handleOnClick}>
                                            {done}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

LanguageSelectionComponent.defaultProps = {
    languagesData: null,
    changeLanguageLoading: false,
};

LanguageSelectionComponent.propTypes = {
    getLanguages: PropTypes.func.isRequired,
    languagesData: PropTypes.shape({
        languages: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                name: PropTypes.string,
                is_default: PropTypes.bool,
                is_right_to_left: PropTypes.bool,
            }),
        ),
        selected_lang: PropTypes.string,
        tag_id: PropTypes.string,
    }),
    setLanguage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    changeLanguageLoading: PropTypes.bool,
};
