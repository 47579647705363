import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import groupIcon from './assets/groupIcon.svg';
import nextBlackIcon from './assets/next_black.svg';
import nextGreyIcon from './assets/next_grey.svg';
import greenTick from './assets/green_tick.svg';
import redCross from './assets/red_cross.svg';
import rightAnswer from './assets/right_answer.png';
import wrongAnswer from './assets/wrong_answer.png';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import quizIconReversed from '../../assets/quizIconReversed.svg';

export default function QuizModal({
    openQuizModal,
    quizData,
    handleQuizModalClose,
    postQuizAnswer,
    postQuizSuccess,
    showShareSuccess,
    uploadScreenshot,
    resetPostQuizData,
    openResultModal,
    setOpenResultModal,
    checkedOptions,
    setCheckedOptions,
}) {
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
    const [userResponse, setUserResponse] = useState('');
    const [toShowShareSuccess, setToShowShareSuccess] = useState(false);

    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        testYourKnowledge,
        shareSuccess,
        youRChampion,
        wellDone,
        wrongAnswerText,
        subtitleQuizModal,
    } = localisationsAfterLogin;

    const resetPostQuizDataCall = useCallback(resetPostQuizData, []);

    const reverseDirectionLanguage = localStorage.getItem('languageIsRightToLeft') === 'true';

    useEffect(() => {
        setCorrectAnswer(String.fromCharCode(64 + parseInt(quizData.correct_answer, 10)));
        return () => {
            setOpenResultModal(false);
            setIsCorrectAnswer(false);
            resetPostQuizDataCall();
        };
    }, [resetPostQuizDataCall, quizData.correct_answer, setOpenResultModal]);

    useEffect(() => {
        if (postQuizSuccess) {
            setOpenResultModal(true);
            if (showShareSuccess && isCorrectAnswer) {
                setToShowShareSuccess(true);
            } else {
                setToShowShareSuccess(false);
            }
        }
    }, [postQuizSuccess, showShareSuccess, isCorrectAnswer, setOpenResultModal]);

    const handleChange = value => {
        const checkedArray = quizData.quizoption_set.map(optionItem => optionItem.value === quizData.correct_answer);
        setCheckedOptions(checkedArray);
        setIsCorrectAnswer(value === quizData.correct_answer);
        setUserResponse(value);
    };

    const handleClick = () => {
        const thisUser = Number(JSON.parse(localStorage.userProfile).id);
        const payload = {
            quiz_id: quizData.id,
            is_correct: isCorrectAnswer,
            user: thisUser,
            response: userResponse,
        };
        postQuizAnswer(payload);
    };

    const handleClickShareSuccess = () => {
        uploadScreenshot();
    };

    const resetQuizAndhandleQuizModalClose = () => {
        resetPostQuizDataCall();
        setOpenResultModal(false);
        setCheckedOptions([]);
        handleQuizModalClose();
    };

    return (
        <Modal open={openQuizModal} className="quiz_question_modal">
            {!openResultModal ? (
                <div
                    className={
                        reverseDirectionLanguage
                            ? 'quiz_question_container reverse_direction'
                            : 'quiz_question_container'
                    }
                >
                    <div className="quiz_left_container">
                        <img
                            className={reverseDirectionLanguage ? 'reverse_language_group_image' : 'group_image'}
                            src={reverseDirectionLanguage ? quizIconReversed : groupIcon}
                            alt=""
                        />
                        <div className="quiz_modal_text_container">
                            <span className="left_heading">{testYourKnowledge}</span>
                            <span className="left_subtitle">{subtitleQuizModal}</span>
                        </div>
                    </div>
                    <div className="quiz_right_container">
                        <div className="quiz_modal_question_container">
                            <span className="question_number">01.</span>
                            <span className="question_text">{quizData && quizData.question}</span>
                            {quizData
                                && Object.keys(quizData).length
                                && quizData.quizoption_set.map((optionItem, index) => (
                                    <div className="option_wrapper" key={optionItem.id}>
                                        {checkedOptions.length < 1 && (
                                            <div
                                                className="checkbox_unchecked unchecked_grey"
                                                onClick={() => handleChange(optionItem.value)}
                                                role="none"
                                            />
                                        )}
                                        {checkedOptions.length > 0 && (
                                            <img
                                                src={checkedOptions[index] ? greenTick : redCross}
                                                className="radio_image"
                                                alt=""
                                            />
                                        )}
                                        <span className="option_text">{optionItem.text}</span>
                                    </div>
                                ))}
                            {checkedOptions.length > 0 ? (
                                <img
                                    className="next-button"
                                    src={nextBlackIcon}
                                    alt=""
                                    onClick={() => handleClick()}
                                    role="none"
                                />
                            ) : (
                                <img className="next-button pointer-none" src={nextGreyIcon} alt="" />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="result_container">
                    {isCorrectAnswer ? (
                        <div className="result" id="result">
                            <span className="question_text">{quizData.question}</span>
                            <img className="result_image" src={rightAnswer} alt="" />
                            <div className="result_text_container">
                                <div className="master_ans_text">{`${youRChampion}`}</div>
                                <div className="subtitle_ans_text">{wellDone}</div>
                            </div>
                        </div>
                    ) : (
                        <div className="result">
                            <span className="question_text">{quizData.question}</span>
                            <img className="result_image" src={wrongAnswer} alt="" />
                            <div className="result_text_container">
                                <div className="master_ans_text">{wrongAnswerText + correctAnswer}</div>
                            </div>
                        </div>
                    )}
                    {toShowShareSuccess && (
                        <div className="share_success_btn" onClick={() => handleClickShareSuccess()} role="none">
                            {shareSuccess}
                        </div>
                    )}
                    <div
                        className="close_button"
                        onClick={resetQuizAndhandleQuizModalClose}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                    >
                        <Cross color="black" />
                    </div>
                </div>
            )}
        </Modal>
    );
}

QuizModal.defaultProps = {
    quizData: {},
};

QuizModal.propTypes = {
    openQuizModal: PropTypes.bool.isRequired,
    quizData: PropTypes.object,
    handleQuizModalClose: PropTypes.func.isRequired,
    postQuizAnswer: PropTypes.func.isRequired,
    postQuizSuccess: PropTypes.bool.isRequired,
    showShareSuccess: PropTypes.bool.isRequired,
    uploadScreenshot: PropTypes.func.isRequired,
    resetPostQuizData: PropTypes.func.isRequired,
    openResultModal: PropTypes.bool.isRequired,
    setOpenResultModal: PropTypes.func.isRequired,
    checkedOptions: PropTypes.array.isRequired,
    setCheckedOptions: PropTypes.func.isRequired,
};
