export const LOGOUT = 'LOGOUT';
export const GET_OPEN_LINK_TRANSLATIONS = 'GET_OPEN_LINK_TRANSLATIONS';
export const NEXT = 'NEXT';
export const ADD_TEXT = 'ADD';
export const GO_TO_FEED = 'GO TO FEED';
export const ERROR_400_MSG = 'Oops! Looks like the page is gone.';
export const ERROR_404_BUTTON = 'Back to Home';
export const ERROR_500_HEADER = 'Oops! There was Internal Server Error';
export const ERROR_500_MSG = "Don{'}t worry, our development team have automaticaly been notified of this issue and they are working on it. Please try again in a few minutes";
export const NAME_PATTERN = /^[a-zA-Z0-9 _,.&-]+$/;
export const EMAIL_PATTERN = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/;
export const EMAIL_INVALID = 'Email pattern is invalid';
export const EMAIL_UNMATCHED = "Email addresses don't match";
export const THROW_ERROR = 'THROW_ERROR';
export const THROW_SUCCESS = 'THROW_SUCCESS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const isMobile = window.outerWidth <= 768;
export const isMobileWithTab = window.outerWidth <= 740;
export const isIpadProPortrait = window.outerHeight >= 1366 && window.outerWidth <= 1024;
export const isIpadLandscape = window.outerWidth <= 1024;
export const breakpointTabLandscape = 1024;
export const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.mckinsey.inspireIndividuals';
export const IOS_LINK = 'https://apps.apple.com/in/app/mckinsey-inspire/id1490428374';
export const TERMS_CONDITIONS_LINK = 'https://ohi-npn-upload-image.s3.amazonaws.com/mpc/common/termsAndConditions.html';

export const API_FAILED = 'API_FAILED';
export const STORE_API_SEQUENCE = 'STORE_API_SEQUENCE';
export const STORE_API_SEQUENCE_INDEX = 'STORE_API_SEQUENCE_INDEX';
export const CALL_NEXT_API_IN_SEQUNCE = 'CALL_NEXT_API_IN_SEQUNCE';
export const CLIENT_EXPIRED = 'CLIENT_EXPIRED';
export const RESET_CLIENT_EXPIRED = 'RESET_CLIENT_EXPIRED';
export const CODE_CLIENT_EXPIRED = 423;
export const CODE_ACCOUNT_LOCK = 429;
export const CODE_UNAUTHORIZE_USER = 401;
export const CODE_BAD_REQUEST = 400;

export const SEQUENCE_APIS_AND_SAGA_MAPPING = {
    'content/user-weekly-question?type=before': 'getWeeklyQuestionsBeforeSaga',
    'content/calendar-introduction': 'getChapterIntroSaga',
    'content/user-weekly-question/': 'getWeeklyQuestionsSaga',
};
export const NOT_SUPPORTED_BROWSER = 'Hmm, looks like your browser isn’t supported.';
export const WHAT_WORKS_MESSAGE = 'Here’s what works';

export const MONTH_MAP = {
    0: 'JAN',
    1: 'FEB',
    2: 'MAR',
    3: 'APR',
    4: 'MAY',
    5: 'JUN',
    6: 'JUL',
    7: 'AUG',
    8: 'SEP',
    9: 'OCT',
    10: 'NOV',
    11: 'DEC',
};

export const COACH_MARKS_TYPES = {
    PROFILE_VIDEO_ACCELETOR: 'profile_video_acceletor',
    PRACTICE: 'practice_coach_mark',
};

export const GOAL_DETAIL_VIEWS = {
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};
