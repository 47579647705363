import { connect } from 'react-redux';
import AccountExpiredComponent from './AccountExpiredComponent';

const stateToProps = state => {
    const AuthReducer = state.get('authentication');
    return {
        isAccountExpired: AuthReducer.get('isAccountExpired'),
    };
};

export default connect(stateToProps)(AccountExpiredComponent);
