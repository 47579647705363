/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TagUser from '../../../components/TagUser';
import InspireLoader from '../../../components/InspireLoader/InspireLoader';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function PostTextZone({
    setFile,
    setImageSrc,
    uploadPost,
    tags,
    draggedFile,
    subTitle,
    uploadingPost,
    fileType,
    setDraggedFile,
    selectedKey,
    selectedHashTag,
    selectedFont,
    currentGradient,
    gradientIndex,
    setSelectedHashTag,
    // eslint-disable-next-line react/prop-types
    selectedUsers,
    setSelecteduser,
    description,
    setDescription,
    setPracticeTagsForPost,
}) {
    const [practiceTags, setPracticeTags] = useState(tags.practice);
    const [categoryTags, setCategoryTags] = useState(tags.category);
    const [loading, setLoading] = useState(false);
    // const [selectedUsers, setSelecteduser] = useState([]);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { tagPractices, writeACaption } = localisationsAfterLogin;

    useEffect(
        () => () => {
            const newState = tags.practice.map(thisTag => {
                // eslint-disable-next-line no-param-reassign
                thisTag.selected = false;
                return thisTag;
            });
            const newState2 = tags.category.map(thisTag => {
                // eslint-disable-next-line no-param-reassign
                thisTag.selected = false;
                return thisTag;
            });
            setPracticeTags(newState);
            setCategoryTags(newState2);
        },
        [tags.category, tags.practice],
    );

    const handleChange = e => {
        const input = e;
        setDescription(input);
    };

    // const handleUploadPost = () => {
    //     const capturedCancas = document.getElementById('textarea_containerid');
    //     const selectedPractices = practiceTags.filter(tag => tag.selected);
    //     const selectedCategories = categoryTags.filter(tag => tag.selected);
    //     const selectedPracticeTags = selectedPractices.map(tag => ` #${tag.name}`);
    //     const selectedCategoryTags = selectedCategories.map(tag => ` #${tag.name}`);
    //     if (capturedCancas && !draggedFile) {
    //         setLoading(true);
    //         setSelectedHashTag('');
    //         uploadPost(
    //             {
    //                 file: '',
    //                 fileType,
    //                 desc: subTitle,
    //                 content: description + selectedPracticeTags.join(' ') + selectedCategoryTags.join(' '),
    //                 categoryTags,
    //                 practiceTags,
    //                 users_list: selectedUsers,
    //                 social_config: {
    //                     fontFamily: selectedFont,
    //                     colors: currentGradient.colorOrder,
    //                     index: gradientIndex,
    //                 },
    //                 type: 'text',
    //             },
    //             selectedKey,
    //             selectedHashTag,
    //         );
    //         setLoading(false);
    //     } else {
    //         setSelectedHashTag('');
    //         uploadPost(
    //             {
    //                 file: draggedFile,
    //                 fileType,
    //                 desc: subTitle,
    //                 content: description + selectedPracticeTags.join(' ') + selectedCategoryTags.join(' '),
    //                 categoryTags,
    //                 practiceTags,
    //                 users_list: selectedUsers,
    //                 social_config: null,
    //                 type: '',
    //             },
    //             selectedKey,
    //             selectedHashTag,
    //         );
    //     }
    //     setFile(null);
    //     setImageSrc('');
    //     setDraggedFile(null);
    // };

    const selectedCategoryTags = (state, tag) => {
        const newState = state.map(thisTag => {
            if (thisTag.id === tag.id) {
                // eslint-disable-next-line no-param-reassign
                thisTag.selected = !thisTag.selected;
            }
            return thisTag;
        });
        setCategoryTags(newState);
    };

    const selectedPracticeTags = (state, tag) => {
        const newState = state.map(thisTag => {
            if (thisTag.id === tag.id) {
                // eslint-disable-next-line no-param-reassign
                thisTag.selected = !thisTag.selected;
            }
            return thisTag;
        });
        setPracticeTagsForPost(newState);
        setPracticeTags(newState);
    };

    return (
        <div className="tags_container">
            {(loading || uploadingPost) && <InspireLoader isLoading={loading || uploadingPost} />}
            <div className="inner_tags_container">
                <div className="description">
                    <TagUser onChange={handleChange} placeholder={writeACaption} userAddMentions={setSelecteduser} />
                </div>
                <div className="tags">
                    <div className="tag-practice">{tagPractices}</div>
                    <div>
                        {practiceTags.map(tag => (
                            <span
                                key={`${tag.name}-key-1`}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={0}
                                onClick={() => selectedPracticeTags(practiceTags, tag)}
                                className={tag.selected && 'tag_selected'}
                            >
                                {`${tag.name}`}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

PostTextZone.defaultProps = {
    tags: {
        practice: [],
        category: [],
    },
    draggedFile: '',
    setSelectedHashTag: () => {},
};

PostTextZone.propTypes = {
    uploadPost: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array.isRequired,
        category: PropTypes.array.isRequired,
    }),
    draggedFile: PropTypes.string,
    subTitle: PropTypes.string.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    fileType: PropTypes.string.isRequired,
    setFile: PropTypes.func.isRequired,
    setImageSrc: PropTypes.func.isRequired,
    setDraggedFile: PropTypes.func.isRequired,
    selectedKey: PropTypes.number.isRequired,
    selectedHashTag: PropTypes.string.isRequired,
    selectedFont: PropTypes.string.isRequired,
    currentGradient: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        colorOrder: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    gradientIndex: PropTypes.number.isRequired,
    setSelectedHashTag: PropTypes.func,
};
