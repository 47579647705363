import { takeLatest, put } from 'redux-saga/effects';
import AuthStore from '../../../common/AuthStore';

import {
    weeklyQuestionsApi,
    weeklyQuestionsAnswerPostApi,
    postPreAdditionalQuestionCommentApi,
} from '../../WeeklyQuestions/redux/apis';
import { storePreAdditionalQuestions, storePreAdditionalAnsweredQuestionResponse } from './actions';
import { apiFailed } from '../../../common/actions';
import { tokenValidatorGenerator } from '../../../utils/functions';

export function* getWeeklyQuestionsBeforeSaga() {
    try {
        const preAdditionalQuestion = yield tokenValidatorGenerator({
            saga: weeklyQuestionsApi,
            payload: [AuthStore.accessToken, 'before'],
        });
        yield put(storePreAdditionalQuestions(preAdditionalQuestion.data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* postWeeklyQuestionAnswerSaga({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: weeklyQuestionsAnswerPostApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
        if (
            data.data
            && (data.data[0].questionType.includes('_Post') || data.data[0].questionType === 'Additional_Question')
        ) {
            yield put(storePreAdditionalAnsweredQuestionResponse(data.data));
        }
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* postPreAdditionalQuestionCommentSaga({ payload }) {
    try {
        yield tokenValidatorGenerator({
            saga: postPreAdditionalQuestionCommentApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const preAdditionalQuestionsSaga = [
    takeLatest('GET_PRE_ADDITIONAL_QUESTIONS_PENDING', getWeeklyQuestionsBeforeSaga),
    takeLatest('POST_PRE_ADDITIONAL_QUESTION_ANSWER_PENDING', postWeeklyQuestionAnswerSaga),
    takeLatest('POST_PRE_ADDITIONAL_QUESTION_COMMENT', postPreAdditionalQuestionCommentSaga),
];

export default preAdditionalQuestionsSaga;
