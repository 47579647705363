import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import emailFooter from '../../assets/emailFooter.svg';
import apple from '../../assets/apple.svg';
import playStore from '../../assets/playStore.svg';
import inspireRedirect from '../../assets/inspireRedirect.svg';

const EmailFooter = ({ heading, subHeading, link, appStoreLink, playStoreLink, inspireWebLink }) => (
    <div className="email-footer">
        <img src={emailFooter} alt="Email Footer" />
        <div className="footer-content">
            <div className="labels">
                <div className="heading">{heading}</div>
                <div className="subHeading">{subHeading}</div>
                <div className="link">
                    <div className="label">{link}</div>
                    <div className="inspire">
                        <a target="_blank" rel="noopener noreferrer" href={inspireWebLink}>
                            <img src={inspireRedirect} alt="inspire" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="actions">
                <div className="apple">
                    <a target="_blank" rel="noopener noreferrer" href={appStoreLink}>
                        <img src={apple} alt="Apple" />
                    </a>
                </div>
                <div className="playStore">
                    <a target="_blank" rel="noopener noreferrer" href={playStoreLink}>
                        <img src={playStore} alt="PlayStore" />
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default EmailFooter;

EmailFooter.propTypes = {
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    appStoreLink: PropTypes.string.isRequired,
    playStoreLink: PropTypes.string.isRequired,
    inspireWebLink: PropTypes.string.isRequired,
};
