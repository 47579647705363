import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LocalisationsStore from '../../../common/LocalisationsStore';
import '../index.scss';
import { isMobile } from '../../../utils/constants';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import Loader from '../../../components/Loader';
import Nodata from '../assets/noData.svg';

export default function UserActivityComponent({
    getUserActivity,
    activities,
    setShowUserActivity,
    history,
    loading,
    getThisFeedsListSuccess,
    pageAnalytics,
}) {
    // eslint-disable-next-line no-unused-vars
    const [thisAnalyticsStartTime, setAnalyticsStartTime] = useState(
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    );

    const { localisationsAfterLogin } = LocalisationsStore;
    const { myActivity, noActivityData, noActivityDataMessage } = localisationsAfterLogin;

    useEffect(
        () => () => {
            const date = new Date();
            const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
            const analyticsEndTime = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
            pageAnalytics({
                page_tag: 'myActivityHome',
                device_id: localStorage.fcmToken,
                start_time: thisAnalyticsStartTime,
                end_time: analyticsEndTime,
                user: userProfile,
            });
        },
        [pageAnalytics, thisAnalyticsStartTime],
    );

    useEffect(() => {
        getUserActivity();
    }, [getUserActivity]);

    const handleClose = () => {
        setShowUserActivity(false);
    };

    const handleActivityClick = e => {
        const { currentTarget: { dataset: { id } } } = e;
        const pathName = history.location.pathname;
        if (pathName.includes('/feed-details-article')) {
            const pathId = pathName.split('/feed-details-article/')[1];
            if (pathId !== id) getThisFeedsListSuccess(null);
        } else {
            getThisFeedsListSuccess(null);
        }
        history.push(`/feed-details-article/${id}`);
    };

    return (
        <div className="user_activity_container">
            {loading && <Loader position="relative" background="transparent" showBackground />}
            {!loading && activities.length === 0 && (
                <div className="no_activity_data">
                    {isMobile && (
                        <div onClick={handleClose} role="button" tabIndex={0} className="back">
                            <Cross color="black" />
                        </div>
                    )}
                    <img src={Nodata} alt="" />
                    <span>{noActivityData}</span>
                    <span>{noActivityDataMessage}</span>
                </div>
            )}
            {!loading && activities.length > 0 && (
                <div className="my_activities_wrapper">
                    <div className="my_activities_header">
                        {myActivity}
                        {isMobile && (
                            <div onClick={handleClose} role="button" tabIndex={0} className="back">
                                <Cross color="black" />
                            </div>
                        )}
                    </div>
                    <div className="activity_rows">
                        {activities.map((duration, index) => (
                            <div className="activity_row_in_this_duration" key={`activity-${index}00`}>
                                <div className="activity_creation_since">{duration.header}</div>
                                {duration.items.map((activity, innerIndex) => (
                                    <div
                                        className="activity_row"
                                        data-id={activity.id}
                                        onClick={handleActivityClick}
                                        key={`activity-${index}-${innerIndex}00`}
                                    >
                                        <div className="activity_row_data">
                                            <div className="activity_chapter_name">{activity.title}</div>
                                            <div>{activity.activity_text}</div>
                                            <div>
                                                {activity.note.length > 34
                                                    ? `${activity.note.substring(0, 34)}...`
                                                    : activity.note}
                                            </div>
                                        </div>
                                        <div className="activity_card_img">
                                            <img src={activity.thumbnail_image} alt="act" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

const activity = PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    created_at: PropTypes.string,
    activity_text: PropTypes.string,
    note: PropTypes.string,
    thumbnail_image: PropTypes.string,
});

const ActivityDuration = PropTypes.shape({
    header: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(activity)),
});

UserActivityComponent.defaultProps = {
    activities: [],
};

UserActivityComponent.propTypes = {
    getUserActivity: PropTypes.func.isRequired,
    setShowUserActivity: PropTypes.func.isRequired,
    activities: PropTypes.arrayOf(ActivityDuration),
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getThisFeedsListSuccess: PropTypes.func.isRequired,
    pageAnalytics: PropTypes.func.isRequired,
};
