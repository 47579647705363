/* eslint-disable no-nested-ternary */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import Text from '../../../components/Text';
import ChartProgress from './ChartProgress';
import BadgesEarned from './BadgesEarned';
import Stats from './Stats';
import LocalisationsStore from '../../../common/LocalisationsStore';
import FollowUnfollowList from '../../Feeds/FollowUnfollowList';
import Streaks from './Streaks/index';

export default function MyProgressComponent({ userProgressData, open, closeUserProgress, pageAnalytics }) {
    const [chaptersDetails, setChapterDetailsOpen] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [userType, setUserType] = useState(0);

    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        myProgress,
        chaptersParticipatedin,
        startOfChapterScore,
        endOfChapterScore,
        change,
        inProgress,
        displayNa,
        naFullForm,
    } = localisationsAfterLogin;

    const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
    const [myProgressHomeStartTime, setMyProgressHomeStartTime] = useState(
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    );
    const [chapterParticipatedStartTime, setChapterParticipatedStartTime] = useState('');
    const [followersStartTime, setFollowersStartTime] = useState('');
    const [followingStartTime, setFollowingStartTime] = useState('');

    const handleDrawerClose = () => {
        const date = new Date();
        setChapterDetailsOpen(false);
        pageAnalytics({
            page_tag: 'chaptersParticipated',
            device_id: localStorage.fcmToken,
            start_time: chapterParticipatedStartTime,
            end_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            user: userProfile,
        });
        setChapterParticipatedStartTime('');
        setMyProgressHomeStartTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
    };

    const closeUsersList = () => {
        const date = new Date();
        setShowUsers(false);
        pageAnalytics({
            page_tag: userType === 0 ? 'followers' : 'following',
            device_id: localStorage.fcmToken,
            start_time: userType === 0 ? followersStartTime : followingStartTime,
            end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            user: userProfile,
        });
        setFollowersStartTime('');
        setFollowingStartTime('');
        setMyProgressHomeStartTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
    };

    const handleClose = () => {
        pageAnalytics({
            page_tag: 'myProgressHome',
            device_id: localStorage.fcmToken,
            start_time: myProgressHomeStartTime,
            end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            user: userProfile,
        });
        closeUserProgress();
    };

    return (
        <Fragment>
            <Modal open={open} className="myprogress_outer">
                {userProgressData && (
                    <div className="wrapper_myprogress">
                        {showUsers && (
                            <FollowUnfollowList open={showUsers} handleClose={closeUsersList} userType={userType} />
                        )}
                        {!chaptersDetails && (
                            <div
                                onClick={handleClose}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={0}
                                className="cross outline-none"
                            >
                                <Cross color="black" />
                            </div>
                        )}
                        {!chaptersDetails && (
                            <Fragment>
                                <div className="modal_heading">
                                    <Text
                                        fontcolor="#272727"
                                        fontsize="18"
                                        fontfamily="TheinhardtPan_medium"
                                        fontweight="600"
                                    >
                                        {myProgress}
                                    </Text>
                                </div>
                                <ChartProgress chartProgress={userProgressData.chartProgress} />
                                <BadgesEarned badges={userProgressData.badges} />
                                {!userProgressData.streak.is_hide && <Streaks streak={userProgressData.streak} />}
                                <Stats
                                    contentState={userProgressData.contentState}
                                    setChapterDetailsOpen={setChapterDetailsOpen}
                                    setShowUsers={setShowUsers}
                                    setUserType={setUserType}
                                    pageAnalytics={pageAnalytics}
                                    myProgressHomeStartTime={myProgressHomeStartTime}
                                    setMyProgressHomeStartTime={setMyProgressHomeStartTime}
                                    setChapterParticipatedStartTime={setChapterParticipatedStartTime}
                                    setFollowersStartTime={setFollowersStartTime}
                                    setFollowingStartTime={setFollowingStartTime}
                                />
                            </Fragment>
                        )}
                        {chaptersDetails && (
                            <div className="chapter_details_wrapper">
                                <div className="heading_wrapper">
                                    <div className="back_icon" onClick={handleDrawerClose} />
                                    <Text fontcolor="#272727" fontsize="18" fontfamily="TheinhardtPan" fontweight="600">
                                        {chaptersParticipatedin}
                                    </Text>
                                </div>
                                <div className="chapter_wrapper">
                                    {userProgressData.contentState.chaptersParticipated.map(chapter => (
                                        <div className="chapter" key={chapter.title}>
                                            <div className="chapter_index">
                                                <Text
                                                    fontcolor="#525252"
                                                    fontsize="12"
                                                    fontfamily="TheinhardtPan"
                                                    fontweight="500"
                                                >
                                                    {chapter.title}
                                                </Text>
                                            </div>
                                            <div>
                                                <Text
                                                    fontcolor="#272727"
                                                    fontsize="18"
                                                    fontfamily="TheinhardtPan"
                                                    fontweight="600"
                                                >
                                                    {chapter.name}
                                                </Text>
                                            </div>
                                            {chapter.inProgress ? (
                                                <div className="pre_chapter">
                                                    <Text
                                                        fontcolor="#525252"
                                                        fontsize="16"
                                                        fontfamily="TheinhardtPan"
                                                        fontweight="500"
                                                    >
                                                        {inProgress}
                                                    </Text>
                                                </div>
                                            ) : (
                                                [
                                                    <div className="pre_chapter">
                                                        <Text
                                                            fontcolor="#525252"
                                                            fontsize="16"
                                                            fontfamily="TheinhardtPan"
                                                            fontweight="500"
                                                        >
                                                            {startOfChapterScore}
                                                        </Text>
                                                        <div className="score">
                                                            {chapter.pre.name.length > 0 && (
                                                                <Text
                                                                    fontcolor="#a0a0a0"
                                                                    fontsize="12"
                                                                    fontfamily="TheinhardtPan"
                                                                    fontweight="300"
                                                                >
                                                                    (
                                                                    {chapter.pre.name}
                                                                    )
                                                                </Text>
                                                            )}
                                                            <Text
                                                                fontcolor="#000000"
                                                                fontsize="18"
                                                                fontfamily="TheinhardtPan"
                                                                fontweight="600"
                                                            >
                                                                {chapter.inProgress ? inProgress : chapter.pre.value}
                                                            </Text>
                                                        </div>
                                                    </div>,
                                                    <div className="pre_chapter">
                                                        <Text
                                                            fontcolor="#525252"
                                                            fontsize="16"
                                                            fontfamily="TheinhardtPan"
                                                            fontweight="500"
                                                        >
                                                            {endOfChapterScore}
                                                        </Text>
                                                        <div className="score">
                                                            {chapter.post.name.length > 0 && (
                                                                <Text
                                                                    fontcolor="#a0a0a0"
                                                                    fontsize="12"
                                                                    fontfamily="TheinhardtPan"
                                                                    fontweight="300"
                                                                >
                                                                    (
                                                                    {chapter.post.display_na
                                                                        ? naFullForm
                                                                        : chapter.post.name}
                                                                    )
                                                                </Text>
                                                            )}
                                                            <Text
                                                                fontcolor="#000000"
                                                                fontsize="18"
                                                                fontfamily="TheinhardtPan"
                                                                fontweight="600"
                                                            >
                                                                {chapter.post.display_na
                                                                    ? displayNa
                                                                    : chapter.inProgress
                                                                        ? inProgress
                                                                        : chapter.post.value}
                                                            </Text>
                                                        </div>
                                                    </div>,
                                                    <div className="pre_chapter">
                                                        {!chapter.post.display_na && (
                                                            <>
                                                                <Text
                                                                    fontcolor="#525252"
                                                                    fontsize="16"
                                                                    fontfamily="TheinhardtPan"
                                                                    fontweight="500"
                                                                >
                                                                    {change}
                                                                </Text>
                                                                <div className="score">
                                                                    {chapter.change.name.length > 0 && (
                                                                        <Text
                                                                            fontcolor="#a0a0a0"
                                                                            fontsize="12"
                                                                            fontfamily="TheinhardtPan"
                                                                            fontweight="300"
                                                                        >
                                                                            (
                                                                            {chapter.change.name}
                                                                            )
                                                                        </Text>
                                                                    )}
                                                                    <Text
                                                                        fontcolor="#000000"
                                                                        fontsize="18"
                                                                        fontfamily="TheinhardtPan"
                                                                        fontweight="600"
                                                                        style={{ marginRight: 0 }}
                                                                    >
                                                                        {chapter.inProgress
                                                                            ? inProgress
                                                                            : chapter.change.value}
                                                                    </Text>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>,
                                                ]
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </Fragment>
    );
}

MyProgressComponent.defaultProps = {
    userProgressData: null,
};

MyProgressComponent.propTypes = {
    userProgressData: PropTypes.shape({
        badges: PropTypes.shape({ social: PropTypes.number, actionable: PropTypes.number }),
        chartProgress: PropTypes.shape({
            influencer: PropTypes.shape({
                total: PropTypes.number,
                earned: PropTypes.number,
            }),
            performance: PropTypes.shape({
                total: 100,
                earned: PropTypes.number,
            }),
        }),
        contentState: PropTypes.object,
        streak: PropTypes.object,
    }),
    open: PropTypes.bool.isRequired,
    closeUserProgress: PropTypes.func.isRequired,
    pageAnalytics: PropTypes.func.isRequired,
};
