import React from 'react';
import PropTypes from 'prop-types';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import Text from '../../../../components/Text';
import './contentType.scss';
import bgImage from '../../../../assets/downloadbg.png';

class TextPostBGColorMapper {
    constructor(imageColor, backColor) {
        this.imageColor = imageColor;
        this.backColor = backColor;
        this.colorOrder = [imageColor, backColor];
    }
}

//adding dummy comment for checking CICD
export const TextPostBGColor = {
    postBGColors: [
        new TextPostBGColorMapper(fontColors.forestGreen, fontColors.darkGreen),
        new TextPostBGColorMapper(fontColors.deepPurple, fontColors.crimson),
        new TextPostBGColorMapper(fontColors.lightpurple, fontColors.violet),
        new TextPostBGColorMapper(fontColors.darkBlue, fontColors.deepDarkBlue),
        new TextPostBGColorMapper(fontColors.pastelBLue, fontColors.darkBlue),
        // new TextPostBGColorMapper(fontColors.forestGreen, fontColors.violet),
    ],
};

const feedFontFamilyMapper = {
    Bold: fontFamilies.TheinhardtPanMedium,
    theinhardtPan: fontFamilies.TheinhardtPanRegular,
    playfairDisplay: fontFamilies.playfairRegular,
    playfairDisplayBold: fontFamilies.playfairBold,
    theinhardtBold: fontFamilies.TheinhardtPanMedium,
    theinhardtMedium: fontFamilies.TheinhardtPanMedium,
    theinhardtRegular: fontFamilies.TheinhardtPanRegular,
    Curly: fontFamilies.Curly,
};

export default function TextDownloadContent({ feedData }) {

    return (
        <div className="TextDownloadContent" style={{ backgroundImage:`url(${bgImage})`, maxHeight: 'auto' ,minHeight:'250px',backgroundSize:'cover'}}>
            <div className="quote">
                <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.124 14.652C4.25733 14.652 3.512 14.496 2.888 14.184C2.264 13.872 1.77867 13.4213 1.432 12.832C1.05067 12.3467 0.756 11.7053 0.548 10.908C0.374667 10.1107 0.288 9.31333 0.288 8.516C0.288 6.78266 0.721334 5.188 1.588 3.732C2.48933 2.24133 3.876 1.06266 5.748 0.195997L6.216 1.132C5.24533 1.548 4.37867 2.18933 3.616 3.056C2.85333 3.92267 2.40267 4.80667 2.264 5.708C2.09067 6.47066 2.09067 7.19866 2.264 7.892C3.02667 7.06 4.032 6.644 5.28 6.644C6.49333 6.644 7.48133 7.008 8.244 7.736C9.00667 8.42933 9.388 9.4 9.388 10.648C9.388 11.8613 8.972 12.832 8.14 13.56C7.34267 14.288 6.33733 14.652 5.124 14.652ZM15.836 14.652C14.9693 14.652 14.224 14.496 13.6 14.184C12.976 13.872 12.4907 13.4213 12.144 12.832C11.7627 12.3467 11.468 11.7053 11.26 10.908C11.0867 10.1107 11 9.31333 11 8.516C11 6.78266 11.4333 5.188 12.3 3.732C13.2013 2.24133 14.588 1.06266 16.46 0.195997L16.928 1.132C15.9573 1.548 15.0907 2.18933 14.328 3.056C13.5653 3.92267 13.1147 4.80667 12.976 5.708C12.8027 6.47066 12.8027 7.19866 12.976 7.892C13.7387 7.06 14.744 6.644 15.992 6.644C17.2053 6.644 18.1933 7.008 18.956 7.736C19.7187 8.42933 20.1 9.4 20.1 10.648C20.1 11.8613 19.684 12.832 18.852 13.56C18.0547 14.288 17.0493 14.652 15.836 14.652Z"
                        fill="white"
                    />
                </svg>
            </div>
            <Text
                fontsize="1.5rem"
                whiteSpace="pre-line"
                fontfamily={
                    feedData.config !== null ? feedFontFamilyMapper[feedData.config.fontFamily] : fontFamilies.Curly
                }
                fontcolor={fontColors.siteWhite}
            >
                {feedData.description}
            </Text>
        </div>
    );
}

TextDownloadContent.propTypes = {
    feedData: PropTypes.object.isRequired,
};
