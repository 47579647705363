import { connect } from 'react-redux';
import PromptArticle from './index';
import { getArticleDetails, resetPromptData } from '../PracticesDetailsContainer/redux/actions';
import { getHomePage, getTodos, refreshGoalAction, refreshTodoAction } from '../HomeContainer/redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('PracticeDetailReducer');
     const RefREDUCER = state.get('RefreshReducer');
    return {
        promptDetailsData: REDUCER.promptDetails,
        clientExpired: RefREDUCER.clientExpired,
        promptLoading: REDUCER.isLoading,
    };
};

const dispatchToProps = {
    getArticleDetails,
    getTodos,
    refreshGoalAction,
    refreshTodoAction,
    resetPromptData,
    getHomePage,
};

export default connect(stateToProps, dispatchToProps)(PromptArticle);
