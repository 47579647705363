import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../Text';
import './index.scss';
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg';
import StreakBlob from '../../assets/streakblob.svg';
import CheckCircleDeafult from '../../assets/CheckCircleDeafult.svg';
import unfilledStreak from '../../assets/unfilledStreak.svg';

const CardContainer = styled.div`
    width: 100%;
    height: 12.5rem;
    min-height: 12.5rem
    border-radius: 0.4rem;
    background-color: ${fontColors.siteWhite};
    display: flex !important;
    justify-content: space-between;
    align-items: center;
`;

export default function CompleteStreakCard({ key, title, body, closeCallback, competedPrompt }) {
    return (
        <CardContainer key={key} className="CompleteStreakCard">
            <div className="left_panel">
                <div className="element">
                    <Text
                        fontsize="0.75rem"
                        fontcolor={fontColors.placeholderGrey}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0 1rem 0"
                        letterSpacing="0.75px"
                        textTransform="uppercase"
                    >
                        {title}
                    </Text>
                    <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                        <Cross color="black" />
                    </div>
                </div>
                <div className="element">
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontweight="400"
                        margin="0 0 0.5rem 0"
                    >
                        {body}
                    </Text>
                </div>
                <div className="element">
                    <ProgressBar competedPrompt={competedPrompt} keyNumber={1} isDivider />
                    <ProgressBar competedPrompt={competedPrompt} keyNumber={2} isDivider />
                    <ProgressBar competedPrompt={competedPrompt} keyNumber={3} isDivider={false} />
                </div>
            </div>
        </CardContainer>
    );
}

function ProgressBar({ competedPrompt, keyNumber, isDivider }) {
    return (
        <div className="streak_blob_wrapper">
            <div className="images_wrapper">
                <img src={competedPrompt >= keyNumber ? StreakBlob : unfilledStreak} alt="" className="blob" />
                {competedPrompt >= keyNumber && (
                    <img src={CheckCircleDeafult} alt="CheckCircleDeafult" className="checkmark" />
                )}
            </div>
            {isDivider && (
                <div
                    style={{
                        height: '1px',
                        backgroundColor: competedPrompt > keyNumber ? fontColors.jadeGreen : fontColors.nneutralGrey,
                        width: '6rem',
                    }}
                />
            )}
        </div>
    );
}

ProgressBar.propTypes = {
    competedPrompt: PropTypes.number.isRequired,
    keyNumber: PropTypes.number.isRequired,
    isDivider: PropTypes.bool.isRequired,
};

CompleteStreakCard.propTypes = {
    key: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    closeCallback: PropTypes.func.isRequired,
    competedPrompt: PropTypes.number.isRequired,
};
