import { put, takeLatest, select } from 'redux-saga/effects';
import types from './types';
import {
    tokenValidatorGenerator,
    dataURItoBlob,
    validURL,
    decompressedFile,
    getStateAccordingToExcutedApi,
} from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getUserData, getStatus } from '../../UnAuthenticatedContent/actions';
import {
    getTagsApi,
    makeAPostApi,
    getFeedsApi,
    postLikeApi,
    deletePostApi,
    getThisFeedsApi,
    postSavedPostsApi,
    getSavedPostsApi,
    getLikeDetailsApi,
    postFollowApi,
    getUsersListOfType,
    getAllCommentsApi,
    postCommentApi,
    modifyCommentApi,
    postQuizAnswerApi,
    reportPostApi,
    postUnfollowUserApi,
    getUsersFollowedApi,
    fetchUsersApi,
    sharePostApi,
    removeBookMarkApi,
    bannerOpenedApi,
    postActionableNotesApi,
    pageAnalyticsApi,
    getTeamMembersApi,
    getSequenceOfApisApi,
    getNewPostsApi,
    getCoachingJourneyApi,
    userScrollAnalyticsApi,
} from './apis';

import {
    storeTags,
    getFeedsListSuccess,
    getFeedsListFailed,
    getFeedsListMoreSuccess,
    getFeedsListMoreFailed,
    postLikeFailed,
    postLikeSuccess,
    uploadPostDone,
    toggleCreatePostModal,
    getThisFeedsListSuccess,
    deletePostSuccess,
    deletePostFailed,
    getSavedPostsSuccess,
    getSavedPostsFailed,
    postSavedPostsSuccess,
    postSavedPostsFailed,
    getLikeDetailsSuccess,
    getLikeDetailsFailed,
    postFollowUserSuccess,
    postFollowUserFailed,
    storeUsersListOfType,
    storeAllComments,
    postCommentDone,
    upDateComments,
    updateFeeds,
    successPostQuizAnswer,
    successReportPost,
    failedReportPost,
    successGetUsersFollowed,
    failedGetUsersFollowed,
    getUsersOfType,
    getUsersFollowed,
    successFetchUsersList,
    failedFetchUsersList,
    successSharePost,
    failedSharePost,
    successFetchMoreUsersList,
    failedFetchMoreUsersList,
    successRemoveBookMark,
    failedRemoveBookMark,
    getSavedPosts,
    storeBanners,
    storeEarnedBadge,
    storeTeamMembersList,
    storeNewPostsCount,
    storeNumberOfNewPostsCount,
    storeNumberOfNewPostsCountCoachingJourney,
    storeNumberOfNewPostsCountSocialGroup,
    storeCoachingJorneyData,
    storeStickyQuizResponse,
    updateShowBadge,
    setGlobalLoading,
} from './actions';
import {
    apiFailed,
    storeApiSequence,
    storeApiIndex,
    clientExpiredAction,
    resetClientExpiredAction,
} from '../../../common/actions';
import { markThisNotificationAsDeleted } from '../../NotificationCenter/redux/actions';

import { getCheckSumSaga } from '../../UnAuthenticatedContent/sagas';
import {
    CALL_NEXT_API_IN_SEQUNCE,
    CODE_CLIENT_EXPIRED,
    SEQUENCE_APIS_AND_SAGA_MAPPING
} from '../../../utils/constants';
import { getWeeklyQuestionsBeforeSaga } from '../../PreAdditionalQuestion/redux/sagas';
import { getChapterIntroSaga, getWeeklyQuestionsSaga } from '../../WeeklyQuestions/redux/sagas';

function* getAllTags({ history }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getTagsApi,
            payload: [AuthStore.accessToken],
            history,
        });
        if (data && data.data) {
            yield put(storeTags(data.data));
        }
        yield put(resetClientExpiredAction());
        yield put(getStatus(localStorage.getItem('fcmToken')));
    } catch ({ response }) {
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(getFeedsListFailed(response));
        }
    }
}

function* getFeeds({ payload, selectedKey, hashTag }) {
    try {
        const state = yield select();
        const fString = state.get('feedsReducer').filterString;
        const { data } = yield tokenValidatorGenerator({
            saga: getFeedsApi,
            payload: [AuthStore.accessToken, payload, selectedKey, hashTag, fString],
        });

        const files = [];
        const promises = [];
        let newArr = data && data.data;
        newArr.map(k => {
            if (validURL(k.file)) {
                if (k.file.split('.').pop() === 'json') {
                    return promises.push(
                        decompressedFile(k.file).then(response => files.push({ file: JSON.parse(response), id: k.id })),
                    );
                }
            }
            return false;
        });

        yield Promise.all(promises).then(() => {
            if (files) {
                newArr = [
                    ...newArr.map(x => {
                        if (validURL(x.file)) {
                            if (x.file.split('.').pop() === 'json') {
                                const item = files.find(({ id }) => id === x.id);
                                return Object.assign({}, x, { file: item.file });
                            }
                            return Object.assign({}, x, { file: x.file });
                        }
                        return x;
                    }),
                ];
            }
        });
        yield put(resetClientExpiredAction());
        yield put(getFeedsListSuccess(selectedKey === 2 && files.length === 0 ? [] : newArr));
        switch (selectedKey) {
            case 1: {
                yield put(storeNumberOfNewPostsCount(data.new_posts));
                break;
            }
            case 2: {
                yield put(storeNumberOfNewPostsCountCoachingJourney(data.new_posts));
                break;
            }
            case 4: {
                yield put(storeNumberOfNewPostsCountSocialGroup(data.new_posts));
                break;
            }
            default: {
                yield put(storeNumberOfNewPostsCount(data.new_posts));
                break;
            }
        }
        yield put(storeBanners(data.roadblocks));
        const fcmToken = localStorage.getItem('fcmToken');
        const status = localStorage.getItem('status');
        if (status === 'false') {
            yield put(getStatus(fcmToken));
        }
    } catch ({ response }) {
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(getFeedsListFailed(response));
        }
    }
}

function* getMoreFeeds({ payload, selectedKey, hashTag }) {
    try {
        const state = yield select();
        const fString = state.get('feedsReducer').filterString;
        const { data } = yield tokenValidatorGenerator({
            saga: getFeedsApi,
            payload: [AuthStore.accessToken, payload, selectedKey, hashTag, fString],
        });
        const files = [];
        const promises = [];
        let newArr = data && data.data;
        newArr.map(k => {
            if (validURL(k.file)) {
                if (k.file.split('.').pop() === 'json') {
                    return promises.push(
                        decompressedFile(k.file).then(response => files.push({ file: JSON.parse(response), id: k.id })),
                    );
                }
            }
            return false;
        });

        yield Promise.all(promises).then(() => {
            if (files) {
                newArr = [
                    ...newArr.map(x => {
                        if (validURL(x.file)) {
                            if (x.file.split('.').pop() === 'json') {
                                const item = files.find(({ id }) => id === x.id);
                                return Object.assign({}, x, { file: item.file });
                            }
                            return Object.assign({}, x, { file: x.file });
                        }
                        return x;
                    }),
                ];
            }
        });
        yield put(resetClientExpiredAction());
        yield put(getFeedsListMoreSuccess(newArr));
    } catch ({ response }) {
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(getFeedsListMoreFailed(response.data));
        }
    }
}

function* postLikeFeed({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: postLikeApi,
            payload: [AuthStore.accessToken],
            body: { article_id: articleId },
        });
        const dataRequest = { data, articleId };
        // yield getFeeds({ payload: 1, selectedKey, hashTag: selectedHashTag });
        yield put(resetClientExpiredAction());
        yield put(postLikeSuccess(dataRequest));
    } catch ({ response }) {
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(postLikeFailed(response));
        }
    }
}

function* deletePost({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: deletePostApi,
            payload: [AuthStore.accessToken],
            body: { article_id: articleId },
        });
        const dataRequest = { data, articleId };
        yield put(resetClientExpiredAction());
        yield put(deletePostSuccess(dataRequest));
    } catch ({ response }) {
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(deletePostFailed(response));
        }
    }
}

function* makeAPost({
    file,
    // eslint-disable-next-line camelcase
    desc,
    categoryTags,
    practiceTags,
    content,
    fileType,
    // eslint-disable-next-line camelcase
    users_list,
    selectedKey,
    social_config,
    postType,
    history,
    callFeedsApi,
}) {
    try{
        const formData = new FormData();
        const payload = {
            desc,
            practice_tags: practiceTags
                .filter(tag => tag.selected)
                .map(tag => tag.id)
                .join(','),
            category_tags: categoryTags
                .filter(tag => tag.selected)
                .map(tag => tag.id)
                .join(','),
            file,
            content,
            fileType,
            users_list,
            social_config,
            type: postType,
        };
        if (payload.file) {
            if (payload.fileType.includes('video')) {
                formData.append('file', payload.file, 'rec_1.mp4');
            } else {
                formData.append('file', dataURItoBlob(payload.file), 'Post.png');
            }
        }
        formData.append('practice_tags', payload.practice_tags);
        formData.append('category_tags', payload.category_tags);
        formData.append('content', payload.content);
        formData.append('desc', payload.desc);
        formData.append('users_list', payload.users_list);
        if (payload.social_config) {
            formData.append('social_config', JSON.stringify(payload.social_config));
        }
        formData.append('type', payload.type);

        yield put(setGlobalLoading(true));
        const { data } = yield tokenValidatorGenerator({
            saga: makeAPostApi,
            payload: [AuthStore.accessToken],
            body: formData,
        });
        localStorage.removeItem('canvasImage');
        const delay = time => new Promise(resolve => setTimeout(resolve, time));
        yield put(storeEarnedBadge(data.data));
        if (data.data.hasUserEarnedBadge) {
            yield put(updateShowBadge({ showBadge: true }));
        }
        yield put(resetClientExpiredAction());
        yield put(uploadPostDone());
        yield put(toggleCreatePostModal(false));
        yield put(setGlobalLoading(false));
        history.push('/app/hub');
        yield delay(500);
        if (callFeedsApi) {
            yield getFeeds({ payload: 1, selectedKey, hashTag: '' });
        }
    } catch ({ response }) {
    if (response.status === CODE_CLIENT_EXPIRED) {
        yield put(clientExpiredAction());
    } else {
        yield put(resetClientExpiredAction());
    }
}
}

function* getThisFeedData({ id, notificationid, routeToPage }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getThisFeedsApi,
            payload: [AuthStore.accessToken, id],
        });

        const files = [];
        const promises = [];
        let newArr = data && data.data;

        if (newArr.type === 'article') {
            if (validURL(newArr.file)) {
                if (newArr.file.split('.').pop() === 'json') {
                    promises.push(
                        decompressedFile(newArr.file).then(response => files.push({ file: JSON.parse(response), id: newArr.id })),
                    );
                }
            }

            yield Promise.all(promises).then(() => {
                if (files) {
                    if (validURL(newArr.file)) {
                        if (newArr.file.split('.').pop() === 'json') {
                            const item = files.find(({ id }) => id === newArr.id);
                            newArr = { ...newArr, file: item.file };
                        }
                        newArr = { ...newArr, file: newArr.file };
                    }
                }
            });
        }
        yield put(getThisFeedsListSuccess(newArr));
        if (routeToPage) {
            routeToPage(id);
        }
    } catch ({ response }) {
        yield put(getFeedsListFailed(response.data));

        const state = yield select();
        const { notifications } = state.get('notificationsListReducer');

        if (notifications.length > 0) {
            yield put(markThisNotificationAsDeleted(notificationid));
        }
    }
}

function* fetchSavedPosts() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getSavedPostsApi,
            payload: [AuthStore.accessToken],
        });
        yield put(getSavedPostsSuccess(data));
    } catch ({ response }) {
        yield put(getSavedPostsFailed(response));
    }
}

function* saveAPost({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: postSavedPostsApi,
            payload: [AuthStore.accessToken],
            body: { article_id: articleId },
        });
        const dataRequest = {
            data,
            articleId,
        };
        yield put(postSavedPostsSuccess(dataRequest));
        yield put(getSavedPosts());
    } catch ({ response }) {
        yield put(postSavedPostsFailed(response));
    }
}
function* fetchLikeDetails({ payload }) {
    try {
        const { articleId, commentId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: getLikeDetailsApi,
            payload: [AuthStore.accessToken, articleId, commentId],
        });
        yield put(getLikeDetailsSuccess(data));
    } catch ({ response }) {
        yield put(getLikeDetailsFailed(response));
    }
}
function* postFollow({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: postFollowApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });

        yield put(getUsersFollowed());
        yield put(getUserData());
        yield put(postFollowUserSuccess(data));
    } catch ({ response }) {
        yield put(postFollowUserFailed(response));
    }
}

function* getUsersOfTypeSaga({ userType }) {
    const { data } = yield tokenValidatorGenerator({
        saga: getUsersListOfType,
        payload: [AuthStore.accessToken, userType],
    });
    yield put(storeUsersListOfType(data));
}

function* getAllCommentsForArticleSaga({ articleId }) {
    const { data } = yield tokenValidatorGenerator({
        saga: getAllCommentsApi,
        payload: [AuthStore.accessToken, articleId],
    });
    yield put(storeAllComments(data.data, articleId));
}

function* postCommentSaga({ articleId, text, selectedUsers, parrentid }) {
    yield tokenValidatorGenerator({
        saga: postCommentApi,
        payload: [AuthStore.accessToken],
        body: {
            article: articleId,
            text,
            user: localStorage.id,
            users_list: selectedUsers.join(),
            parent_comment: parrentid || null,
        },
    });
    yield getAllCommentsForArticleSaga({ articleId });
    yield put(postCommentDone());
    yield put(updateFeeds({ articleId: Number(articleId), operation: '+' }));
}

function* likeCommentSaga({ id, articleId }) {
    yield tokenValidatorGenerator({
        saga: modifyCommentApi,
        payload: [AuthStore.accessToken, id, 'like'],
    });
    yield getAllCommentsForArticleSaga({ articleId });
    yield put(upDateComments());
}

function* deleteCommentSaga({ id, articleId, replycount }) {
    yield tokenValidatorGenerator({
        saga: modifyCommentApi,
        payload: [AuthStore.accessToken, id, 'delete'],
    });
    yield getAllCommentsForArticleSaga({ articleId });
    yield put(upDateComments());
    yield put(updateFeeds({ articleId: Number(articleId), operation: '-', replycount }));
}

function* postQuizAnswerFn({ payload, isStickyQuiz }) {
    const response = yield tokenValidatorGenerator({
        saga: postQuizAnswerApi,
        payload: [AuthStore.accessToken],
        body: payload,
    });
    if (!isStickyQuiz) {
        yield put(successPostQuizAnswer(response));
    } else {
        yield put(storeStickyQuizResponse(response.data.data));
    }
}

function* reportPostFn({ feedId }) {
    try {
        yield tokenValidatorGenerator({
            saga: reportPostApi,
            payload: [AuthStore.accessToken, feedId],
            body: {
                action: 'report',
            },
        });
        yield put(resetClientExpiredAction());
        yield put(successReportPost());
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(failedReportPost());
        }
    }
}

function* postUnfollowUser({ payload, userType }) {
    try {
        yield tokenValidatorGenerator({
            saga: postUnfollowUserApi,
            payload: [AuthStore.accessToken, payload],
        });

        if (userType !== null) {
            yield put(getUsersOfType(userType));
        }
        yield put(getUsersFollowed());
        yield put(getUserData());
    } catch (e) {
        yield put(failedReportPost(e));
    }
}

function* getUsersFollowedFn() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUsersFollowedApi,
            payload: [AuthStore.accessToken],
        });
        yield put(successGetUsersFollowed(data));
    } catch ({ response }) {
        yield put(failedRemoveBookMark(response));
    }
}

function* removeBookMarkFn({ articleId }) {
    try {
        yield tokenValidatorGenerator({
            saga: removeBookMarkApi,
            payload: [AuthStore.accessToken, articleId],
        });
        yield put(getSavedPosts());
        yield put(successRemoveBookMark());
    } catch (e) {
        yield put(failedGetUsersFollowed(e));
    }
}

function* fetchUsersListFn({ page, query }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: fetchUsersApi,
            payload: [AuthStore.accessToken, query, page],
        });

        yield put(successFetchUsersList(data));
    } catch (e) {
        yield put(failedFetchUsersList());
    }
}

function* sharePostFn({ payload }) {
    try {
        yield tokenValidatorGenerator({
            saga: sharePostApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });

        yield put(successSharePost());
    } catch (e) {
        yield put(failedSharePost());
    }
}

function* fetchMoreUsersListFn({ page, query }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: fetchUsersApi,
            payload: [AuthStore.accessToken, query, page],
        });

        yield put(successFetchMoreUsersList(data));
    } catch (e) {
        yield put(failedFetchMoreUsersList());
    }
}

function* bannerOpenedSaga({ id }) {
    const payload = {
        roadblock_id: id,
    };
    try {
        yield tokenValidatorGenerator({
            saga: bannerOpenedApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
        yield getFeeds({ payload: 1, selectedKey: 1, hashTag: '' });
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* postActionableNotesSaga({ id, notes }) {
    const payload = {
        text: notes,
        article_id: id,
    };
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: postActionableNotesApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
        yield put(storeEarnedBadge(data.data));
        yield getThisFeedData({ id });
    } catch (e) {
        yield put(apiFailed(e));
    }
}

export function* pageAnalyticsSaga({ page_tag, device_id, start_time, end_time, user }) {
    try {
        const payload = [
            {
                page_tag,
                device_id,
                start_time,
                end_time,
                user,
            },
        ];
        yield tokenValidatorGenerator({
            saga: pageAnalyticsApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
        sessionStorage.clear();
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* getTeamMembersSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getTeamMembersApi,
            payload: [AuthStore.accessToken, JSON.parse(localStorage.userProfile).social_group[0].id],
        });
        yield put(storeTeamMembersList(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* apiSequenceResolver() {
    try {
        const state = yield select();
        const { apis } = state.get('apiSequence');
        const { apiIndex } = state.get('apiSequence');
        const api = apis[apiIndex];
        const { api_URL } = api;

        switch (SEQUENCE_APIS_AND_SAGA_MAPPING[api_URL]) {
            case 'getWeeklyQuestionsBeforeSaga': {
                yield getWeeklyQuestionsBeforeSaga();
                break;
            }
            case 'getChapterIntroSaga': {
                yield getChapterIntroSaga();
                break;
            }
            case 'getWeeklyQuestionsSaga': {
                yield getWeeklyQuestionsSaga();
                break;
            }
            default:
                yield getWeeklyQuestionsSaga();
        }
        yield put(storeApiIndex(apiIndex + 1));
        const newState = yield select();
        const stateToUpdateFromSaga = getStateAccordingToExcutedApi(api_URL);
        const stateValue = newState.get('weeklyQuestionsReducer')[stateToUpdateFromSaga];
        if (Object.keys(stateValue).length === 0 && apiIndex < apis.length) {
            yield put(storeApiIndex(apiIndex + 1));
            yield apiSequenceResolver();
        }
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* getSequenceOfApisSaga() {
    try {
        yield getCheckSumSaga({ isLoggedIn: true });
        const { data } = yield tokenValidatorGenerator({
            saga: getSequenceOfApisApi,
            payload: [AuthStore.accessToken],
        });
        const apisInSequence = data.data.filter(d => d.api_URL !== 'content/midloop-popup/');
        yield put(storeApiSequence(apisInSequence));
        yield apiSequenceResolver();
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* getNewPostsSaga({ time, selectedKey }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getNewPostsApi,
            payload: [AuthStore.accessToken, time, selectedKey],
        });
        yield put(storeNewPostsCount(data.data.new_count));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* getCoachingJourneySaga({ queryString }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getCoachingJourneyApi,
            payload: [AuthStore.accessToken, queryString],
        });
        yield put(storeCoachingJorneyData(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}
function* userScrollAnalyticsSaga({ article, start_time, end_time, device_id }) {
    const payload = [{ article, start_time, end_time, device_id }];
    try {
        yield tokenValidatorGenerator({
            saga: userScrollAnalyticsApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const FeedsSaga = [
    takeLatest(`${types.UPLOAD_POST}_PENDING`, makeAPost),
    takeLatest(`${types.GET_TAGS}_PENDING`, getAllTags),
    takeLatest(types.GET_FEEDS_PENDING, getFeeds),
    takeLatest(types.GET_FEEDS_MORE_PENDING, getMoreFeeds),
    takeLatest(types.POST_LIKE_PENDING, postLikeFeed),
    takeLatest(`${types.GET_THIS_FEED}_PENDING`, getThisFeedData),
    takeLatest(types.DELETE_POST_PENDING, deletePost),
    takeLatest(types.GET_SAVED_POSTS_PENDING, fetchSavedPosts),
    takeLatest(types.POST_SAVED_POSTS_PENDING, saveAPost),
    takeLatest(types.GET_LIKE_DETAILS_PENDING, fetchLikeDetails),
    takeLatest(types.POST_FOLLOW_USER_PENDING, postFollow),
    takeLatest(`${types.GET_USERS_OF_TYPE}_PENDING`, getUsersOfTypeSaga),
    takeLatest(`${types.GET_ALL_COMMENTS}_PENDING`, getAllCommentsForArticleSaga),
    takeLatest(`${types.POST_COMMENT}_PENDING`, postCommentSaga),
    takeLatest(`${types.LIKE_COMMENT}_PENDING`, likeCommentSaga),
    takeLatest(`${types.DELETE_COMMENT}_PENDING`, deleteCommentSaga),
    takeLatest(`${types.POST_QUIZ_ANSWER}_PENDING`, postQuizAnswerFn),
    takeLatest(`${types.REPORT_POST}_PENDING`, reportPostFn),
    takeLatest(`${types.POST_UNFOLLOW_USER}_PENDING`, postUnfollowUser),
    takeLatest(`${types.GET_USERS_FOLLOWED}_PENDING`, getUsersFollowedFn),
    takeLatest(`${types.REMOVE_BOOKMARK}_PENDING`, removeBookMarkFn),
    takeLatest(`${types.FETCH_USERS_LIST}_PENDING`, fetchUsersListFn),
    takeLatest(`${types.SHARE_POST}_PENDING`, sharePostFn),
    takeLatest(`${types.FETCH_MORE_USERS_LIST}_PENDING`, fetchMoreUsersListFn),
    takeLatest(types.BANNER_OPENED, bannerOpenedSaga),
    takeLatest(types.POST_ACTIONABLE_NOTES, postActionableNotesSaga),
    takeLatest(types.POST_PAGE_ANALYTICS, pageAnalyticsSaga),
    takeLatest(`${types.GET_TEAM_MEMBERS}_PENDING`, getTeamMembersSaga),
    takeLatest(`${types.GET_SEQUENCE_OF_APIS}_PENDING`, getSequenceOfApisSaga),
    takeLatest(CALL_NEXT_API_IN_SEQUNCE, apiSequenceResolver),
    takeLatest(`${types.GET_NEW_POSTS_BLIP}_PENDING`, getNewPostsSaga),
    takeLatest(`${types.GET_COACHING_JOURNEY}_PENDING`, getCoachingJourneySaga),
    takeLatest(types.USER_SCROLL_ANALYTICS, userScrollAnalyticsSaga),
];

export default FeedsSaga;
