import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import BottomScrollListener from 'react-bottom-scroll-listener';
import Loader from '../../components/Loader';
import FeedCard from './FeedCard';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import './index.scss';
import AddNewPost from '../Feeds/AddNewPost';
import PlusCircle from '../../assets/plus-button.svg';
import AddPost from '../../assets/addPost.svg';
import PGAddPost from '../../assets/pg_AddPost.png';
import LocalisationsStore from '../../common/LocalisationsStore';
import BadgeModal from '../../components/BadgeModal';
import { pageAnalytics } from '../../utils/functions';

export default function HomeComponent({
    getFeedCards,
    feedsData,
    loading,
    postLike,
    likeComment,
    getAllComments,
    postComment,
    deleteComment,
    articleComments,
    uploadPost,
    getTags,
    tags,
    history,
    uploadingPost,
    followedUsers,
    postFollowUser,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    followedId,
    allFeedFetched,
    getFeedsListMore,
    reportPost,
    deletePost,
    likeDetails,
    getLikeDetails,
    earnedBannerBadge,
    showBadge,
    updateShowBadge,
    clientExpired,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { stayTunned, CREATE_NEW_POST } = localisationsAfterLogin;
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1);
    const [hashtag, setHashTag] = useState('');
    const [showCreatePost, setShowCreatePost] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        pageAnalytics.initialise('hub');
        return () => {
            pageAnalytics.complete('hub');
        };
    }, []);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem('canvasImage')) {
            setShowCreatePost(true);
        }

        if (localStorage.getItem('createPost')) {
            setShowCreatePost(true);
        }
    }, []);

    useEffect(() => {
        getTags(history);
        getFeedCards(page, 1, hashtag);
        // eslint-disable-next-line
    }, [getFeedCards])

    const closeBadgeModal = navigate => {
        updateShowBadge({ showBadge: false });
        if (navigate) {
            history.push('/app/profile');
        }
    };

    const handleCreatePostClick = e => {
        e.stopPropagation();
        setShowCreatePost(true);
    };

    const handleClose = () => {
        setShowCreatePost(false);
    };

    const scrollCallback = () => {
        if (!allFeedFetched) {
            getFeedsListMore(page + 1, 1, hashtag);
        }
        setPage(page + 1);
    };

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };

    const Image = localStorage.getItem('client_config') === 'pg' ? (
        <img src={PGAddPost} alt="Add Post" width="223px" />
    ) : (
        <img src={AddPost} alt="Add Post" />
    );
    return (
        <div className="hub_container">
            {clientExpired && moveToClientExpirePage()}
            {loading && <Loader position="fixed" showBackground />}
            <div className="space-adjuster" />
            <div className="scroll-container-horizontal" ref={ref} />
            <div className="hub-content">
                {showBadge && <BadgeModal earnedBannerBadge={earnedBannerBadge} closeBadgeModal={closeBadgeModal} />}
                {tags && (
                    <AddNewPost
                        handleClose={handleClose}
                        imageUploaderModal={showCreatePost}
                        uploadPost={uploadPost}
                        tags={tags}
                        uploadingPost={uploadingPost}
                        selectedKey={1}
                        selectedHashTag={hashtag}
                        setSelectedHashTag={setHashTag}
                        setShowCreatePost={value => {
                            setShowCreatePost(value);
                            ref.current.scrollIntoView();
                        }}
                        imageSrc={localStorage.getItem('canvasImage') || ''}
                        history={history}
                    />
                )}
                <div className="create_post" onClick={handleCreatePostClick}>
                    <div className="nnewPostPlus">
                        {/* <Text
                        fontsize="1.5rem"
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        fontcolor={fontColors.siteWhite}
                        cursor="Pointer"
                    >
                        +
                    </Text> */}
                        <img src={PlusCircle} alt="plusicon" />
                    </div>
                    <Text
                        fontsize="0.875rem"
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        fontcolor={fontColors.textBlack}
                        margin="0 0.5rem 0 1rem"
                        letterSpacing="1px"
                        textTransform="uppercase"
                        cursor="Pointer"
                    >
                        {CREATE_NEW_POST}
                    </Text>
                </div>
                {feedsData && !feedsData.length && !loading && (
                    <div className="empty_feed_list">
                        <Text
                            fontsize="0.875rem"
                            fontfamily={fontFamilies.TheinhardtPanLight}
                            fontcolor={fontColors.textBlack}
                            letterSpacing="1px"
                        >
                            {stayTunned}
                        </Text>
                        {Image}
                    </div>
                )}
                <div className="feed_card_list">
                    <BottomScrollListener onBottom={scrollCallback} offset={1000} debounce={1000} />
                    {feedsData
                        && feedsData.map((feed, index) => (
                            <FeedCard
                                key={feed.id}
                                index={index}
                                feed={feed}
                                profilePicture={(value => value)(feed.profile_picture)}
                                hashtag={hashtag}
                                postLike={postLike}
                                likeComment={likeComment}
                                getAllComments={getAllComments}
                                postComment={postComment}
                                deleteComment={deleteComment}
                                articleComments={articleComments}
                                followedUsers={followedUsers}
                                postFollowUser={postFollowUser}
                                postUnfollowUser={postUnfollowUser}
                                getUsersFollowed={getUsersFollowed}
                                setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                                followedId={followedId}
                                reportPost={reportPost}
                                deletePost={deletePost}
                                likeDetails={likeDetails}
                                getLikeDetails={getLikeDetails}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}

HomeComponent.defaultProps = {
    tags: {},
};

HomeComponent.propTypes = {
    getFeedCards: PropTypes.func.isRequired,
    feedsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
    postLike: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    getAllComments: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    articleComments: PropTypes.array.isRequired,
    uploadPost: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    history: PropTypes.object.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    followedUsers: PropTypes.array.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    followedId: PropTypes.number.isRequired,
    allFeedFetched: PropTypes.bool.isRequired,
    getFeedsListMore: PropTypes.func.isRequired,
    reportPost: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    earnedBannerBadge: PropTypes.object.isRequired,
    showBadge: PropTypes.bool.isRequired,
    updateShowBadge: PropTypes.func.isRequired,
    clientExpired: PropTypes.bool.isRequired,
};
