const types = {
    GET_NOTIFICATIONS_LIST: 'GET_NOTIFICATIONS_LIST',
    GET_NOTIFICATIONS_LIST_MORE: 'GET_NOTIFICATIONS_LIST_MORE',
    MARK_THIS_NOTIFICATION_AS_DELETED: 'MARK_THIS_NOTIFICATION_AS_DELETED',
    MARK_THIS_NOTIFICATION_AS_READ: 'MARK_THIS_NOTIFICATION_AS_READ',
    READ_THIS_NOTIFICATION: 'READ_THIS_NOTIFICATION',
    MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
    INCREMENT_PAGE_NUMBER: 'INCREMENT_PAGE_NUMBER',
    REACHED_END_OF_NOTIFICATIONS_LIST: 'REACHED_END_OF_NOTIFICATIONS_LIST',
    EMPTY_NOTIFICATION_LIST: 'EMPTY_NOTIFICATION_LIST',
    RESET_NOTIFICATIONS_PAGE_NUMBER: 'RESET_NOTIFICATIONS_PAGE_NUMBER',
};

export default types;
