import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import reminderSet from '../../../assets/reminderSet.svg';
import EmailCard from '../../../components/EmailCard';
import EmailFooter from '../../../components/EmailFooter';
import blueClock from '../../../assets/blueClock.svg';
//

const SetReminderDone = ({ articleDetails }) => {
    const { localization, practice_name, article_title, notification_time } = articleDetails;
    const {
        allSet,
        willRemindYou,
        manageJourney,
        createManageTodos,
        goToInspireWeb,
        inspireWebLink,
        appStoreLink,
        playStoreLink,
        alreadyComplete,
    } = localization;
    return (
        <div className="reminder-content reminder-set">
            <div className="image">
                <img src={reminderSet} alt="Reminder Set" />
            </div>
            <div className="title">{allSet}</div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {articleDetails.is_article_completed ? (
                <div className="subTitle">{alreadyComplete || 'You have already completed this prompt.'}</div>
            ) : articleDetails.is_reminder_set ? (
                <div className="subTitle">{willRemindYou}</div>
            ) : (
                ''
            )}
            {notification_time && articleDetails.is_reminder_set && !articleDetails.is_article_completed && (
                <div className="remindedOn">
                    <img width="12px" src={blueClock} alt="Reminder Icon" />
                    <span className="date">
                        {format(new Date(notification_time.replace('Z', '')), 'MMM dd, yyyy, hh:mm a')}
                    </span>
                </div>
            )}

            <div className="practice-detail">
                <EmailCard
                    title={practice_name}
                    subtitle={article_title}
                    isReminder={articleDetails.is_reminder_set}
                    isComplete={articleDetails.is_article_completed}
                />
            </div>
            {articleDetails.localization.is_hide_footer && articleDetails.localization.is_hide_footer === "true"  ? (
                <div />
            ) : (
                <div className="footer">
                    <EmailFooter
                    heading={manageJourney}
                    subHeading={createManageTodos}
                    link={goToInspireWeb}
                    inspireWebLink={inspireWebLink}
                    appStoreLink={appStoreLink}
                    playStoreLink={playStoreLink}
                />
                </div>
            )}
        </div>
    );
};

export default SetReminderDone;

SetReminderDone.propTypes = {
    articleDetails: PropTypes.object.isRequired,
};
