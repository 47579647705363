import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import './index.scss';
import accountExpired from '../../../../assets/accountExpired.svg';
import LocalisationsStore from '../../../../common/LocalisationsStore';

export default function AccountExpiredComponent({ isAccountExpired }) {
    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || {
        sorryToSeeYougo: '',
        journeyEnds: '',
    };
    const { sorryToSeeYougo, journeyEnds } = localisationsAfterLogin;
    return (
        <>
            {isAccountExpired && (
                <Modal open={isAccountExpired} className="account_expired_outer">
                    <div className="account_expired_inner" data-test="account-expired-pop-up">
                        <img src={accountExpired} alt="Account expired" />
                        <span className="message_title">{sorryToSeeYougo}</span>
                        <span className="message_desc">{journeyEnds}</span>
                    </div>
                </Modal>
            )}
        </>
    );
}

AccountExpiredComponent.propTypes = {
    isAccountExpired: PropTypes.bool.isRequired,
};
