import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningModal from '../../components/WarningModal';
import blackBell from '../../assets/blackBell.svg';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import { readNotification, readAllNotifications } from '../PracticesContainer/redux/apis';
import { getLastUpdatedAt, pageAnalytics } from '../../utils/functions';
import NoNotifications from '../../assets/no-notification.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import Loader from '../../components/Loader';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Actionbutton from '../../components/ActionButton';
import Text from '../../components/Text';
import { CODE_CLIENT_EXPIRED } from '../../utils/constants';

const NotificationsModal = ({
    closeModal,
    notifications,
    isNotificationsLoading,
    setNotificationsList,
    setShowPrompt,
    history,
    setNewNotification,
    newNotification,
    scrollBack,
    isInfiniteLoading,
    allFetched,
    setShowGoalDetails,
    clientExpired,
    clientExpiredAction,
    resetClientExpiredAction,
    unreadNotificationCount,
    setUnreadNotificationCount,
}) => {
    useEffect(() => {
        pageAnalytics.initialise('notifications');
        return () => {
            pageAnalytics.complete('notifications');
        };
    }, []);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { notifications: notificationsTitle, allCaughtUpNotifications, mark_all_as_read } = localisationsAfterLogin;

    // eslint-disable-next-line no-nested-ternary
    const notificationsJSX = notifications.map(el => (
        <div
            className="notification-item"
            key={el.notification_id}
            onClick={() => {
                const _notifications = notifications.map(elem => {
                    if (elem.notification_id === el.notification_id) {
                        return {
                            ...elem,
                            is_read: true,
                        };
                    }
                    setNewNotification(newNotification - 1);
                    return { ...elem };
                });
                setNotificationsList(_notifications);
                setUnreadNotificationCount(prevVal => prevVal - 1);
                readNotification(el.notification_id);
                if (el.screen_name === 'comment_details') {
                    history.push(`/app/notification/${el.content_id}`);
                    closeModal(false);
                }
                if (el.screen_name === 'article_todo_detail' || el.screen_name === 'article_detail') {
                    closeModal(false);
                    setShowPrompt({
                        show: true,
                        id: el.content_id,
                    });
                }
                if (el.screen_name === 'recommended_article_detail') {
                    closeModal(false);
                    setShowPrompt({
                        show: true,
                        id: el.content_id,
                    });
                }
                if (el.screen_name === 'practice_detail') {
                    closeModal(false);
                    history.push(`/app/practice/${el.content_id}`);
                }
                if (el.screen_name === 'home' || el.screen_name === 'article_todo_detail_custom') {
                    closeModal(false);
                    history.push('/app/home');
                }
                if (el.screen_name === 'article_todo_list') {
                    closeModal(false);
                    history.push('/all-todos');
                }
                if (el.screen_name === 'profile_progress') {
                    closeModal(false);
                    history.push('/app/profile');
                }
                if (el.screen_name === 'create_post') {
                    closeModal(false);
                    localStorage.setItem('createPost', 'true');
                    history.push('/app/hub');
                }
                if (el.screen_name === 'goal_detail') {
                    closeModal(false);
                    setShowGoalDetails({
                        show: true,
                        id: el.content_id,
                    });
                }
            }}
        >
            {el.title && <div className="notification-item__title">{el.title}</div>}
            {el.notification_text && (
                <div className={`notification-item__description ${el.is_read ? '' : 'new'}`}>
                    {el.notification_text}
                </div>
            )}
            {el.created_at && <div className="notification-item__date">{getLastUpdatedAt(el.created_at)}</div>}
        </div>
    ));

    // eslint-disable-next-line no-nested-ternary
    const loader = isInfiniteLoading ? (
        <CircularProgress />
    ) : allFetched ? (
        <span style={{ fontSize: '16px' }}>No more notifications to show</span>
    ) : (
        <Actionbutton
            fontSize="1rem"
            fontFamily={fontFamilies.TheinhardtPanRegular}
            fontColor={fontColors.siteWhite}
            bgColor={fontColors.electricBlue}
            disabled={false}
            onClick={event => {
                scrollBack(event);
            }}
            width="7rem"
            height="34px"
        >
            Show more
        </Actionbutton>
    );

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };

    const handleMarkAllAsRead = () => {
        readAllNotifications()
            .then(() => {
                const _notifications = notifications.map(notification => ({
                    ...notification,
                    is_read: true,
                }));
                setNotificationsList(_notifications);
                setUnreadNotificationCount(0);
                resetClientExpiredAction();
            })
            .catch(e => {
                if (e.response.status === CODE_CLIENT_EXPIRED) {
                    clientExpiredAction();
                } else {
                    resetClientExpiredAction();
                }
            });
    };

    return (
        <Fragment>
            <WarningModal>
                <div className="notifications">
                    {clientExpired && moveToClientExpirePage()}
                    <div className="notifications__header">
                        <div className="bell">
                            {' '}
                            <img src={blackBell} alt="Notification Bell" />
                        </div>
                        <div className="title">{notificationsTitle}</div>
                        <div
                            onClick={() => closeModal(false)}
                            role="button"
                            tabIndex={0}
                            className="notifications__close"
                        >
                            <Cross color="black" />
                        </div>
                    </div>
                    <div className="notifications__content">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {isNotificationsLoading ? (
                            <div className="no-notifications">
                                <Loader position="absolute" showBackground />
                            </div>
                        ) : notifications.length > 0 ? (
                            <>
                                {unreadNotificationCount > 0 && (
                                    <Text
                                        fontsize="14px"
                                        fontcolor={fontColors.electricBlue}
                                        fontfamily={fontFamilies.TheinhardtPanRegular}
                                        lineHeight="20px"
                                        fontweight={500}
                                        cursor="pointer"
                                        onClick={handleMarkAllAsRead}
                                    >
                                        {mark_all_as_read}
                                    </Text>
                                )}
                                {notificationsJSX}
                                <div>{loader}</div>
                            </>
                        ) : (
                            <div className="no-notifications">
                                <img src={NoNotifications} alt="no-notificaiton" />
                                <p>{allCaughtUpNotifications}</p>
                            </div>
                        )}
                    </div>
                    {/* <BottomScrollListener onBottom={scrollBack} offset={1000} debounce={1000} /> */}
                </div>
            </WarningModal>
        </Fragment>
    );
};

export default withRouter(NotificationsModal);

NotificationsModal.defaultProps = {
    notifications: [],
    isNotificationsLoading: false,
    isInfiniteLoading: false,
};

NotificationsModal.propTypes = {
    notifications: PropTypes.array,
    isNotificationsLoading: PropTypes.bool,
    isInfiniteLoading: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    setShowPrompt: PropTypes.func.isRequired,
    setNotificationsList: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
    setNewNotification: PropTypes.func.isRequired,
    newNotification: PropTypes.number.isRequired,
    scrollBack: PropTypes.func.isRequired,
    allFetched: PropTypes.bool.isRequired,
    setShowGoalDetails: PropTypes.func.isRequired,
    clientExpired: PropTypes.bool.isRequired,
    clientExpiredAction: PropTypes.func.isRequired,
    resetClientExpiredAction: PropTypes.func.isRequired,
    unreadNotificationCount: PropTypes.number.isRequired,
    setUnreadNotificationCount: PropTypes.func.isRequired,
};
