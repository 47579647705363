import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import QuestionHeading from './QuestionHeading';
import Questionnaire from '../../components/Questions';
import './index.scss';

const ProfileForm = ({ questions, setNameQuestionAns, setTimeQuestionAns }) => {
    const myQuestions = questions.filter(ques => ques.question_detail.type !== 'profile_picture');
    return (
        <Box className="profileFormContainer">
            {myQuestions.map(question => (
                <Fragment>
                    <QuestionHeading index={1} description={question.question_detail.question} />
                    <Questionnaire
                        type={question.question_detail.type}
                        question={question}
                        setNameQuestionAns={setNameQuestionAns}
                        setTimeQuestionAns={setTimeQuestionAns}
                    />
                </Fragment>
            ))}
        </Box>
    );
};

ProfileForm.propTypes = {
    questions: PropTypes.array.isRequired,
    setNameQuestionAns: PropTypes.func.isRequired,
    setTimeQuestionAns: PropTypes.func.isRequired,
};

export default ProfileForm;
