import React, { useState, Fragment, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import WarningModal from '../../../components/WarningModal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import leftIcon from '../../../assets/leftIcon.svg';
import Actionbutton from '../../../components/ActionButton';
import Loader from '../../../components/Loader';
import { fontColors, fontFamilies } from '../../../common/fontMap';
import { goalQuestionApi, setGoalApi } from '../../HomeContainer/redux/apis';
import CustomDateTimePicker from '../../../components/DateTimePicker';
import CustomDatePicker from '../../../components/CustomDatePicker';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../utils/functions';

const AddGoal = ({ setView, refreshHomeGoals }) => {
    const [goalView, setGoalView] = useState(1);
    const [goalQuestions, setGoalQuestions] = useState([]);
    const today = new Date();
    const [goal, setGoal] = useState({
        goal_text: '',
        due_date: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0)).format('yyyy-MM-DDTHH:mm:ss.SSS'),
    });
    const [isLoading, setIsLoading] = useState(false);

    const { localisationsAfterLogin } = LocalisationsStore;
    const [error, errorCallBack] = useState(false);
    const { createGoal, nextFirst, setGoal: _setGoal } = localisationsAfterLogin;
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    useEffect(() => {
        pageAnalytics.initialise('goalCreate');
        return () => {
            pageAnalytics.complete('goalCreate');
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);
        goalQuestionApi().then(response => {
            let sortedQuestions = response.data.data;
            sortedQuestions = sortedQuestions.sort((a, b) => a.sequence - b.sequence);
            setGoalQuestions(sortedQuestions);
            setIsLoading(false);
        });
    }, []);

    const setGoalText = goal_text => {
        setGoal({
            ...goal, goal_text,
        });
    };

    const setDueDate = due_date => {
        setGoal({
            ...goal, due_date,
        });
    };

    const setGoals = () => {
        const goalText = (goal.goal_text || '').trim();
        setGoalApi({ ...goal, goal_text: goalText }).then(() => {
            setView(0);
            refreshHomeGoals();
        });
    };
    const showView = () => {
        const match = goalQuestions.length > 0 ? goalQuestions[goalView - 1] : { question_type: '' };
        switch (match.question_type) {
            case 'text_area':
                return (
                    <Fragment>
                        <div className="info">
                            <div className="title">
                                {match.question}
                            </div>
                            <div className="subtitle">
                                {match.description}
                            </div>
                        </div>
                        <div className="goal-details">
                            <textarea placeholder={match.place_holder_text} className="text-area" onChange={event => { setGoalText(event.target.value); }} value={goal.goal_text} />
                        </div>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={(goal.goal_text?.trim()?.length < 1)}
                            width={isClientPG ? '25%' : '20%'}
                            onClick={() => {
                                setGoalView(2);
                            }}
                        >
                            {nextFirst}
                        </Actionbutton>
                    </Fragment>
                );
            case 'date':
                return (
                    <Fragment>
                        <div className="info">
                            <div className="title">
                                {match.question}
                            </div>
                            <div className="subtitle">
                                {match.description}
                            </div>
                            <div className="goal-date">
                                {isClientPG ? (
                                    <CustomDatePicker
                                        errorShow={error}
                                        errorCallBack={errorCallBack}
                                        handleDateChange={setDueDate}
                                        selectedDate={goal.due_date}
                                        isHeaderVisible={false}
                                        isSaveButtonVisible={false}
                                        isStatic
                                    />
                                ) : (
                                    <CustomDateTimePicker
                                        errorShow={error}
                                        errorCallBack={errorCallBack}
                                        handleDateChange={setDueDate}
                                        selectedDate={goal.due_date}
                                    />
                                )}
                            </div>
                        </div>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={error}
                            width={isClientPG ? '25%' : '20%'}
                            onClick={() => {
                                setGoals();
                            }}
                        >
                            {_setGoal}
                        </Actionbutton>
                    </Fragment>
                );
            default:
                return null;
        }
    };
    return (
        <WarningModal classNames="add-goal add-goal-modal">
            <div className={`add-goal-view${isClientPG ? ' add-goal-view-pg' : ''}`}>
                <div className="header">
                    <div className="title">
                        {goalView > 1 && (
                        <div className="back-icon" onClick={() => { setGoalView(goalView - 1); }}>
                            <img src={leftIcon} alt="Back" />
                        </div>
                        )}
                        <div>{createGoal}</div>
                    </div>
                    <div
                        className="cross"
                        onClick={() => {
                            setView(0);
                        }}
                    >
                        <Cross color="black" />
                    </div>
                </div>
                <div className="add-goal-content">
                    {showView()}
                </div>
            </div>
            {isLoading && <Loader showBackground position="absolute" />}
        </WarningModal>
    );
};


AddGoal.propTypes = {
    setView: PropTypes.func.isRequired,
    refreshHomeGoals: PropTypes.func.isRequired,
};

export default AddGoal;
