import React from 'react';
import DynamicCard from '.';
import SurveyPromptCart from './SurveyPromptCart';
import CompleteProfileCard from './CompleteProfileCard';
import CompleteStreakCard from './CompleteStreakCard';
import StreakStatusCard from './StreakStatusCard';
import CoachIntroCard from './CoachIntroCard';
import CustomizeAnnouncementCard from './CustomizeAnnouncementCard';

const DynamicCardsEnum = Object.freeze({
    CARD_WELCOME: 1,
    CARD_PULSE_SURVEY: 2,
    CARD_ANNOUNCEMENT: 3,
    CARD_IDEAL_RATE: 4,
    CARD_COMPLETE_PROFILE: 5,
    CARD_DID_YOU_KNOW: 6,
    CARD_COMPLETE_YOUR_STREAK: 7,
    CARD_STREAK_STATUS: 8,
    CARD_ROADBLOCK: 9,
    CARD_COACH_MARKS: 10,
    AP_ONBOARD: 15,
});

export default class DynamicCardsMapper {
    constructor(type, closeCallback, props) {
        this.type = type;
        this.closeCallback = closeCallback;
        this.props = props;
    }

    getCard() {
        switch (this.type) {
            case DynamicCardsEnum.CARD_WELCOME:
                return <DynamicCard {...this.props} />;
            case DynamicCardsEnum.CARD_PULSE_SURVEY:
                return <SurveyPromptCart {...this.props} closeCallback={this.closeCallback} />;

            case DynamicCardsEnum.CARD_ANNOUNCEMENT:
                return <CustomizeAnnouncementCard {...this.props} closeCallback={this.closeCallback} />;

            case DynamicCardsEnum.CARD_IDEAL_RATE:
                return <DynamicCard {...this.props} closeCallback={this.closeCallback} />;

            case DynamicCardsEnum.CARD_COMPLETE_PROFILE:
                return <CompleteProfileCard {...this.props} closeCallback={this.closeCallback} />;

            case DynamicCardsEnum.CARD_DID_YOU_KNOW:
                return <DynamicCard {...this.props} closeCallback={this.closeCallback} />;

            case DynamicCardsEnum.CARD_COMPLETE_YOUR_STREAK:
                return <CompleteStreakCard {...this.props} closeCallback={this.closeCallback} />;

            case DynamicCardsEnum.CARD_STREAK_STATUS:
                return <StreakStatusCard {...this.props} closeCallback={this.closeCallback} />;
            case DynamicCardsEnum.CARD_COACH_MARKS:
                return <CoachIntroCard {...this.props} closeCallback={this.closeCallback} />;
            case DynamicCardsEnum.CARD_ROADBLOCK:
                return <CompleteProfileCard {...this.props} closeCallback={this.closeCallback} />;
            case DynamicCardsEnum.AP_ONBOARD: {
                return <DynamicCard {...this.props} cardType="AP" />;
            }
            default:
                return <DynamicCard />;
        }
    }
}
