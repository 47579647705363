/* eslint-disable no-param-reassign */
import moment from 'moment'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
    getTokenFailed,
    getTokenSuccess,
    requestSignUpFailed,
    requestSignUpSuccess,
    resetPasswordFailed,
    resetPasswordSuccess,
    getStatus,
    getStatusSuccess,
    getStreakSuccess,
    getStatusFailed,
    getUserDataSuccess,
    getUserDataFailed,
    failedLogoutUser,
    initialLocalisationsFetched,
    validDomain,
    getUserData,
    storeIsAccountExpired,
    storeLanguages,
    resetUserDetailsSuccess,
    setNewNotification,
    emailSubscribe,
    setCount,
    setHideSections,
    setHideAddGoalsBtn,
    smrfLink,
} from './actions'
import { apiFailed, clientExpiredAction, resetClientExpiredAction } from '../../common/actions'
import {
    LOGIN,
    SIGN_UP_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    GET_STATUS,
    GET_USER_DATA,
    LOGOUT_USER,
    GET_CHECKSUM,
    GET_LANGUAGES,
    POST_LANGUAGE,
} from './constants'
import {
    getTokenApi,
    signUpApi,
    resetPasswordApi,
    getUserStatusApi,
    getUserDataApi,
    logoutUserApi,
    getCheckSum,
    getCheckSum2,
    getLocalisationKeys,
    getLocalisationKeys2,
    verifyDomainNameApi,
    postInfoToAuth0Api,
    getLanguageApi,
    postLanguageApi,
} from './apis'
import { tokenValidatorGenerator, pageAnalytics } from '../../utils/functions'
import parseJwt from '../../utils/parseJwt'
import AuthStore from '../../common/AuthStore'
import { messaging } from '../../init-fcm'
import LocalisationsStore from '../../common/LocalisationsStore'
import AUTH0 from '../../auth_config'
import { Badges } from '../Feeds/constants'
import { pageAnalyticsApi } from '../Feeds/redux/apis'
import { CODE_CLIENT_EXPIRED, CODE_UNAUTHORIZE_USER } from '../../utils/constants'
import endpoints from '../../Endpoints';

export function* getCheckSumSaga({ isLoggedIn }) {
    try {
        if (!isLoggedIn) {
            const {
                data: {
                    data: { checksum },
                },
            } = yield call(getCheckSum)
            if (LocalisationsStore.checksumBefore !== checksum) {
                LocalisationsStore.checksumBefore = checksum
                const {
                    data: { data },
                } = yield call(getLocalisationKeys)
                LocalisationsStore.localisationsBeforeLogin = JSON.stringify(data)
                yield put(initialLocalisationsFetched(true))
                yield put(setCount(1))
            }
            yield put(initialLocalisationsFetched(true))
        } else {
            const {
                data: {
                    data: { checksum },
                },
            } = yield tokenValidatorGenerator({
                saga: getCheckSum2,
                payload: [AuthStore.accessToken],
            })
            if (LocalisationsStore.checksumAfter !== checksum) {
                LocalisationsStore.checksumAfter = checksum
                const {
                    data: { data },
                } = yield tokenValidatorGenerator({
                    saga: getLocalisationKeys2,
                    payload: [AuthStore.accessToken],
                })
                LocalisationsStore.localisationsAfterLogin = JSON.stringify(data)
                const { localisationsAfterLogin } = LocalisationsStore
                const {
                    actionTacker,
                    yayyHeadingForAction1,
                    yayyContentForAction1,
                    supperActionTacker,
                    yayyHeadingForAction2,
                    yayyContentForAction2,
                    meghaActionTacker,
                    yayyHeadingForAction3,
                    yayyContentForAction3,
                    influencer,
                    yayyHeadingForSocial1,
                    yayyContentForSocial1,
                    champion,
                    yayyHeadingForSocial2,
                    yayyContentForSocial2,
                    diamond,
                    yayyHeadingForSocial3,
                    yayyContentForSocial3,
                    multiple,
                    multiple2,
                    streak,
                    streakContent,
                } = localisationsAfterLogin
                Badges.forEach((badge, i) => {
                    if (i === 0) {
                        badge.badgeName = actionTacker
                        badge.title = yayyHeadingForAction1
                        badge.content = yayyContentForAction1
                    }
                    if (i === 1) {
                        badge.badgeName = supperActionTacker
                        badge.title = yayyHeadingForAction2
                        badge.content = yayyContentForAction2
                    }
                    if (i === 2) {
                        badge.badgeName = meghaActionTacker
                        badge.title = yayyHeadingForAction3
                        badge.content = yayyContentForAction3
                    }
                    if (i === 3) {
                        badge.badgeName = influencer
                        badge.title = yayyHeadingForSocial1
                        badge.content = yayyContentForSocial1
                    }
                    if (i === 4) {
                        badge.badgeName = champion
                        badge.title = yayyHeadingForSocial2
                        badge.content = yayyContentForSocial2
                    }
                    if (i === 5) {
                        badge.badgeName = diamond
                        badge.title = yayyHeadingForSocial3
                        badge.content = yayyContentForSocial3
                    }
                    if (i === 6) {
                        badge.badgeName = streak
                        badge.title = actionTacker
                        badge.content = streakContent
                    }
                    if (i === 7) {
                        badge.badgeName = multiple
                        badge.title = actionTacker
                        badge.content = actionTacker
                    }
                    if (i === 8) {
                        badge.badgeName = multiple2
                        badge.title = actionTacker
                        badge.content = actionTacker
                    }
                })
            }
        }
    } catch (rese) {
        yield put(requestSignUpFailed(rese.data))
    }
}

function* getFcmToken() {
    yield messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(token => {
            if (token) {
                localStorage.setItem('fcmToken', token)
            } else {
                localStorage.setItem('fcmToken', 'fcm_token_not_found_web')
            }
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            localStorage.setItem('fcmToken', 'fcm_token_not_found_web')
            console.log('Unable to get permission to notify.', err)
        })
}

export function* getUserSaga(fromLogin = false) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUserStatusApi,
            payload: [AuthStore.accessToken],
            body: {
                device_id: 'fcmToken',
                app_version: '1',
                device_type: 'web',
                user_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            },
            fromLogin,
        })
        const hideSections = data?.data?.hide_sections;
        const hideProgressSnapshot = !(hideSections && !hideSections.includes('user_matrix'));
        localStorage.setItem('hide_progress_snapshot', hideProgressSnapshot);
        localStorage.setItem('status', data.data.status)
        localStorage.setItem('isVersion2', data.data.inspire_version === 2)
        localStorage.setItem('full_name', data.data.full_name)
        localStorage.setItem('preferred_name', data.data.full_name);
        localStorage.setItem('id', data.data.id)
        localStorage.setItem('profile_picture', data.data.profile_picture)
        localStorage.setItem('support_email', data.data.support_email)
        localStorage.setItem('is_hide_onboarding_lang', data.data.is_hide_onboarding_lang)
        localStorage.setItem('client_config', data.data.client_config)
        localStorage.setItem('return_to_home', data.data.return_to_home)
        localStorage.setItem('todo_show_add_to_calender', data.data.todo_show_add_to_calender);
        localStorage.setItem('prompt_multiple_select_question_layout', data.data.prompt_multiple_select_question_layout)
        localStorage.setItem('hide_profile_your_progress',data.data.hide_profile_your_progress)
        localStorage.setItem('is_hide_social_post', data.data.is_hide_social_post);
        localStorage.setItem('client_logo', data.data.client_logo);
        localStorage.setItem('client_logo_height', data.data.client_logo_height);
        localStorage.setItem('client_logo_width', data.data.client_logo_width);
        localStorage.setItem('profile_smrf_index', data.data.profile_smrf_index);
        localStorage.setItem('user_profile_order', data.data.user_profile_order);
        // eslint-disable-next-line no-use-before-define
        yield fetchUserData()
        yield put(resetClientExpiredAction())
        yield put(getStatusSuccess(data.data.status))
        yield put(setNewNotification(data.data.notification_count))
        yield put(setHideSections(data.data.hide_sections))
        yield put(resetUserDetailsSuccess(data.data.allow_reset_profile))
        yield put(emailSubscribe(data.data.is_hide_email_subscribe))
        yield put(smrfLink(data.data.smrf_analytics_url))
        yield put(getStreakSuccess(data.data.streak))
        yield put(storeIsAccountExpired(data.data.is_account_expire))
        yield put(setHideAddGoalsBtn(data.data.hide_add_goal_button))
    } catch ({ response }) {
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction())
        } else {
            yield put(resetClientExpiredAction())
            yield put(getStatusFailed(response))
        }
        if (fromLogin && response.status === CODE_UNAUTHORIZE_USER) {
            const obj = {
                message: response.data.message || response.data.detail,
                status: response.status,
            };
            yield put(getTokenFailed(obj));
        }
    }
}

export function* loginSaga({ payload, history }) {
    const pageObject = {
        page_tag: 'status_internal',
        device_id: navigator.userAgent,
        start_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }
    try {
        const { data } = yield call(getTokenApi, payload)
        const parsedData = parseJwt(data.id_token)
        AuthStore.accessToken = data.id_token
        if (data.refresh_token) {
            AuthStore.refreshToken = data.refresh_token
        }
        AuthStore.baseUrl = 'https://mckinspire.com_baseURL'
        AuthStore.expTime = 'exp'
        yield getFcmToken()
        yield getCheckSumSaga({ isLoggedIn: true })
        yield getUserSaga(true);
        yield put(getTokenSuccess(parsedData));
        yield put(resetClientExpiredAction())
        pageAnalytics.initialise('userSession');
        if (localStorage.getItem('status') === 'false') {
            history.push('/onboarding')
        } else {
            yield call(pageAnalyticsApi, [pageObject])
            history.push('/app')
        }
    } catch ({ response }) {
        const { analyticsPage } = endpoints;
        if (response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction())
        } else if (!(response.status === CODE_UNAUTHORIZE_USER && response?.request?.responseURL?.includes(analyticsPage))) {
            yield put(resetClientExpiredAction())
            const obj = {
                message: response.data.message || response.data.detail,
                status: response.status,
                display_message: response?.data?.display_message,
                is_hide_forgot_link: response?.data?.is_hide_forgot_link,
            }
            yield put(getTokenFailed(obj))
        }
    }
}

export function* signUpSaga({ payload, callback }) {
    try {
        const { data } = yield call(signUpApi, payload)
        yield put(requestSignUpSuccess(data))
        yield callback()
    } catch ({ response }) {
        const obj = {
            message: response.data.message,
            status: response.status,
        }
        yield put(requestSignUpFailed(obj))
    }
}

export function* forgotPassSaga({ payload, callback }) {
    try {
        const { data } = yield call(resetPasswordApi, payload)
        yield put(resetPasswordSuccess(data))
        yield callback()
    } catch ({ response }) {
        yield put(resetPasswordFailed(response.data))
    }
}

function* fetchUserData() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUserDataApi,
            payload: [AuthStore.accessToken],
        })
        localStorage.setItem('userDetails', data.data.profile_picture)
        yield put(getUserDataSuccess(data))
    } catch ({ response }) {
        yield put(getUserDataFailed(response))
    }
}

function* logoutUserFn({ history }) {
    try {
        yield tokenValidatorGenerator({
            saga: logoutUserApi,
            payload: [AuthStore.accessToken],
        });
        AuthStore.clear();
        if (history) {
            history.push('/');
        }
    } catch (e) {
        yield put(failedLogoutUser());
    }
}

function* verifyDomainNameSaga({ domain }) {
    try {
        yield call(verifyDomainNameApi, domain)
        yield put(validDomain(true))
    } catch (e) {
        yield put(apiFailed(e))
        yield put(validDomain(false))
    }
}

function* postInfoToAuth0Saga({ code, state, history }) {
    const payload = {
        grant_type: 'authorization_code',
        client_id: AUTH0.clientId,
        code,
        state,
        redirect_uri: AUTH0.redirect_uri,
    }
    const pageObject = {
        page_tag: 'status_internal',
        device_id: navigator.userAgent,
        start_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }
    try {
        const { data } = yield call(postInfoToAuth0Api, payload)
        const parsedData = parseJwt(data.id_token)
        AuthStore.accessToken = data.id_token
        if (data.refresh_token) {
            AuthStore.refreshToken = data.refresh_token
        }
        AuthStore.baseUrl = 'https://mckinspire.com_baseURL'
        AuthStore.expTime = 'exp'
        yield put(getTokenSuccess(parsedData))
        yield call(pageAnalyticsApi, [pageObject])
        yield getFcmToken()
        yield getCheckSumSaga({ isLoggedIn: false })
        yield getCheckSumSaga({ isLoggedIn: true })
        yield put(getStatus(localStorage.getItem('fcmToken')))
        yield put(getUserData())
        if (localStorage.getItem('status') === 'false') {
            history.push('/onboarding')
        } else {
            history.push('/app')
        }
    } catch ({ response }) {
        yield put(getTokenFailed(response.data))
    }
}

function* getLanguagesSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getLanguageApi,
            payload: [AuthStore.accessToken],
        })
        yield put(storeLanguages(data.data))
    } catch ({ response }) {
        yield put(getStatusFailed(response))
    }
}

function* postSelectedLanguageSaga({ language }) {
    try {
        yield tokenValidatorGenerator({
            saga: postLanguageApi,
            payload: [AuthStore.accessToken],
            body: {
                lang: language,
            },
        })
    } catch ({ response }) {
        yield put(getStatusFailed(response))
    }
}

const unAuthSagas = [
    takeLatest(`${FORGOT_PASSWORD_REQUEST}_PENDING`, forgotPassSaga),
    takeLatest(`${LOGIN}_PENDING`, loginSaga),
    takeLatest(`${SIGN_UP_REQUEST}_PENDING`, signUpSaga),
    takeLatest(`${GET_STATUS}_PENDING`, getUserSaga),
    takeLatest(`${GET_USER_DATA}_PENDING`, fetchUserData),
    takeLatest(`${LOGOUT_USER}_PENDING`, logoutUserFn),
    takeLatest(`${GET_CHECKSUM}_PENDING`, getCheckSumSaga),
    takeLatest('VERIFY_DOMAIN_NAME_PENDING', verifyDomainNameSaga),
    takeLatest('SEND_INFO_TO_AUTH0', postInfoToAuth0Saga),
    takeLatest(`${GET_LANGUAGES}_PENDING`, getLanguagesSaga),
    takeLatest(POST_LANGUAGE, postSelectedLanguageSaga),
]
export default unAuthSagas
