import { takeLatest, put, select } from 'redux-saga/effects';
import AuthStore from '../../../common/AuthStore';

import {
    weeklyQuestionsApi,
    weeklyQuestionsAnswerPostApi,
    chapterIntroApi,
    chapterIntroSeenApi,
    postPreAdditionalQuestionCommentApi,
} from './apis';
import { storeWeeklyQuestions, storeAnsweredQuestionResponse, storeChapterIntro } from './actions';
import { tokenValidatorGenerator } from '../../../utils/functions';
import { apiFailed } from '../../../common/actions';

export function* getWeeklyQuestionsSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: weeklyQuestionsApi,
            payload: [AuthStore.accessToken],
        });
        yield put(storeWeeklyQuestions(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* postWeeklyQuestionAnswer({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: weeklyQuestionsAnswerPostApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
        if (
            data.data
            && (data.data[0].questionType.includes('_Post') || data.data[0].questionType === 'Additional_Question')
        ) {
            yield put(storeAnsweredQuestionResponse(data.data));
        }
    } catch (e) {
        yield put(apiFailed(e));
    }
}

export function* getChapterIntroSaga() {
    try {
        const chapterIntro = yield tokenValidatorGenerator({
            saga: chapterIntroApi,
            payload: [AuthStore.accessToken],
        });
        yield put(storeChapterIntro(chapterIntro.data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* cahpterIntroSeenSaga() {
    try {
        const state = yield select();
        const chapterId = state.get('weeklyQuestionsReducer').chapterIntro.id;
        const chapterIntro = yield tokenValidatorGenerator({
            saga: chapterIntroSeenApi,
            payload: [AuthStore.accessToken],
            body: {
                id: chapterId,
            },
        });
        yield put(storeChapterIntro(chapterIntro.data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* postAdditionalQuestionCommentSaga({ payload }) {
    try {
        yield tokenValidatorGenerator({
            saga: postPreAdditionalQuestionCommentApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const weeklyQuestionsSaga = [
    takeLatest('GET_WEEKLY_QUESTIONS_PENDING', getWeeklyQuestionsSaga),
    takeLatest('POST_WEEKLY_QUESTION_ANSWER_PENDING', postWeeklyQuestionAnswer),
    takeLatest('GET_CHAPTER_INTRO_PENDING', getChapterIntroSaga),
    takeLatest('CHAPTER_INTRO_SEEN', cahpterIntroSeenSaga),
    takeLatest('POST_ADDITIONAL_QUESTION_COMMENT', postAdditionalQuestionCommentSaga),
];

export default weeklyQuestionsSaga;
