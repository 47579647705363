/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Actionbutton from '../../components/ActionButton';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';

const Element = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: center;
`;

export default function ArticleAction({
    practiceId,
    changePracticeStatus,
    handleCrossClick,
    type,
    article,
    subpromptId,
    refreshTodoAction,
    refreshGoalAction,
}) {
    return (
        <div className="action_wrapper">
            {type !== 'orientation' && (
                <Element>
                    <Actionbutton
                        fontSize="1.125rem"
                        fontFamily={fontFamilies.TheinhardtPanLight}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        disabled={false}
                        onClick={() => {
                            changePracticeStatus(practiceId, 'complete');
                            if (refreshTodoAction != null) refreshTodoAction();
                            if (refreshTodoAction != null) refreshGoalAction();
                        }}
                        width="20rem"
                    >
                        {article.article_buttons.find(elem => elem.key === 'complete').text}
                    </Actionbutton>
                </Element>
            )}
            <Element>
                <Actionbutton
                    fontSize="1.125rem"
                    fontFamily={fontFamilies.TheinhardtPanLight}
                    fontColor={fontColors.electricBlue}
                    bgColor={fontColors.siteWhite}
                    disabled={false}
                    onClick={() => {
                        changePracticeStatus(practiceId, 'todo');
                    }}
                    width="20rem"
                    border={`1px solid ${fontColors.electricBlue}`}
                >
                    {article.article_buttons.find(elem => elem.key === 'add_todo').text}
                </Actionbutton>
            </Element>
            <Element>
                <Text
                    fontsize="1rem"
                    fontcolor={fontColors.textBlack}
                    fontfamily={fontFamilies.TheinhardtPanMedium}
                    decoration="underline"
                    cursor="pointer"
                    onClick={event => {
                        handleCrossClick(event, practiceId, subpromptId);
                    }}
                >
                    {article.article_buttons.find(elem => elem.key === 'no_thanks').text}
                </Text>
            </Element>
        </div>
    );
}

ArticleAction.defaultProps = {
    refreshTodoAction: () => {},
    refreshGoalAction: () => {},
    subpromptId: null,
};

ArticleAction.propTypes = {
    practiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    changePracticeStatus: PropTypes.func.isRequired,
    handleCrossClick: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    article: PropTypes.object.isRequired,
    subpromptId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    refreshTodoAction: PropTypes.func,
    refreshGoalAction: PropTypes.func,
};
