import React from 'react';
import './clientexpire.scss';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Actionbutton from '../../components/ActionButton';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../common/LocalisationsStore';
import ClientExpireSvg from '../../assets/clientExpire.svg';

function ClientExpireComponent({ history }) {
    const { localisationsAfterLogin } = LocalisationsStore;

    const { weAreSorry, AppExpiredDesc, continueAsGuest } = localisationsAfterLogin;

    const handleOnClick = () => {
        history.push('/guest');
    }

    return (
    <div className="client-expire">
        <div className="content_wrapper">
            <img className="err_img" src={ClientExpireSvg} alt="expire logo" />
            <p className="err_title">{weAreSorry}</p>
            <p className="err_description">{AppExpiredDesc}</p>
            <div className="err_btn">
                <Actionbutton
                    fontSize="1.125rem"
                    fontFamily={fontFamilies.TheinhardtPanRegular}
                    fontColor={fontColors.siteWhite}
                    bgColor={fontColors.electricBlue}
                    disabled={false}
                    onClick={handleOnClick}
                >{continueAsGuest}
                </Actionbutton>
            </div>
        </div>
    </div>
)
}

ClientExpireComponent.propTypes = {
    history: PropTypes.object.isRequired,
}

export default ClientExpireComponent
 

