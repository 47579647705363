/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InspireLoader from '../InspireLoader/InspireLoader';
import './index.scss';

export default function WeeklyReplayMailerComponent({ htmlContent, getHtmlContent, isLoading }) {
    useEffect(() => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const baseurl = window.atob(url.searchParams.get('url'));
        getHtmlContent({ baseurl });
    }, [getHtmlContent]);

    return (
        <>
            {isLoading && (
                <div style={{ height: '100vh', width: '100vw' }}>
                    <InspireLoader isLoading />
                </div>
            )}
            {!isLoading && htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
        </>
    );
}

WeeklyReplayMailerComponent.propTypes = {
    getHtmlContent: PropTypes.func.isRequired,
    htmlContent: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
