import React from 'react';
import PropTypes from 'prop-types';
import { getLastUpdatedAt } from '../../../utils/functions';

export default function LastUpdatedAt({ postDate }) {
    return (
        <div data-test="post-date-attr-test" className="feed_card_last_updated_at">
            {getLastUpdatedAt(postDate)}
        </div>
    );
}

LastUpdatedAt.propTypes = {
    postDate: PropTypes.string.isRequired,
};
