import types from './types';

export const getUserMatrix = () => ({
    type: `${types.GET_USER_MATRIX_DATA}_PENDING`,
});

export const storeUserMatrix = data => ({
    type: `${types.GET_USER_MATRIX_DATA}_DONE`,
    data,
});

export const resetUserMatrix = () => ({
    type: types.RESET_USER_MATRIX_DATA,
});
