import React, { useState } from 'react'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import './index.scss'

const StylePicker = styled.div`
    border: ${props => props.border || '1px solid rgb(133, 133, 133)'};
    padding: 6px;
    display: inline-block;
    margin: 4px 0;
    border-radius: 4px;
`

function CustomDateTimePicker({ selectedDate, handleDateChange, errorCallBack, errorShow = false, border, onCloseCallback }) {
    const today = new Date()
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0)
    const [error, setError] = useState(errorShow)
    return (
        <StylePicker border={border}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                    format="MM/dd/yyyy HH:mm a"
                    variant="inline"
                    allowKeyboardControl={false}
                    ampm
                    error={false}
                    value={new Date(selectedDate)}
                    minDate={new Date(minDate)}
                    minutesStep={30}
                    disablePast
                    minDateMessage=""
                    onClose={onCloseCallback}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    onChange={value => {
                        if (new Date(value).getTime() - new Date().getTime() < 0) {
                            setError(true)
                            errorCallBack(true)
                        } else {
                            setError(false)
                            errorCallBack(false)
                        }
                        handleDateChange(moment(value).format('yyyy-MM-DDTHH:mm:ss.SSS'))
                    }}
                />
                {error && (
                    <div className="date-error" style={{ color: '#ff0000' }}>
                        Please select future date and time.
                    </div>
                )}
            </MuiPickersUtilsProvider>
        </StylePicker>
    )
}

export default CustomDateTimePicker

CustomDateTimePicker.defaultProps = {
    onCloseCallback: () => {},
};

CustomDateTimePicker.propTypes = {
    selectedDate: PropTypes.object.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    errorCallBack: PropTypes.func.isRequired,
    errorShow: PropTypes.bool.isRequired,
    onCloseCallback: PropTypes.func,
}
