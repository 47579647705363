import React, { useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import WarningModal from '../../../components/WarningModal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import { getGoalApi } from '../../HomeContainer/redux/apis';
import Loader from '../../../components/Loader';
import ActiveCard from '../ActiveCard';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../utils/functions';

const GoalDetails = ({ setView, activeGoalDetail }) => {
    const [goal, setGoal] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { taskDetails } = localisationsAfterLogin;

    useEffect(() => {
        pageAnalytics.initialise(`todo/${activeGoalDetail.id}`);
        return () => {
            pageAnalytics.complete(`todo/${activeGoalDetail.id}`);
        };
        // eslint-disable-next-line
    }, [])
    const fetchGoalDetail = () => {
        setIsLoading(true);
        getGoalApi(activeGoalDetail.id, 'custom')
            .then(response => {
                setGoal({ ...response.data.data });
                setIsLoading(false);
            })
            .catch(() => {
                setView(0);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        fetchGoalDetail();
        // eslint-disable-next-line
    }, [])
    return (
        <WarningModal classNames="goalDetails goal-todo-modal">
            <div className="goal-detail-view">
                <div className="header">
                    <div className="title">
                        <div
                            className="back-icon"
                            onClick={() => {
                                setView(0);
                            }}
                        />
                        <div>{taskDetails}</div>
                    </div>
                    <div
                        className="cross"
                        onClick={() => {
                            setView(0);
                        }}
                    >
                        <Cross color="black" />
                    </div>
                </div>
                {goal.id && (
                    <div className="goal-detail-content">
                        <ActiveCard key={goal.id} goal={goal} callBack={fetchGoalDetail} editCallBack={fetchGoalDetail} fromScreen="Todo" />
                    </div>
                )}
            </div>
            {isLoading && <Loader showBackground position="absolute" />}
        </WarningModal>
    );
};
GoalDetails.propTypes = {
    setView: PropTypes.func.isRequired,
    activeGoalDetail: PropTypes.object.isRequired,
};

export default GoalDetails;
