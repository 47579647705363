import { showCommentsKeyFeedsList } from '../../../utils/functions';
import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    feedsData: null,
    articleComments: [],
};

const HubReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_FEED_CARDS_LIST}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_FEED_CARDS_LIST}_DONE`]: (state, { data }) => ({
        ...state,
        loading: false,
        feedsData: data,
    }),
    [`${types.GET_ALL_COMMENTS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_ALL_COMMENTS}_DONE`]: (state, { data, articleId }) => ({
        ...state,
        isLoading: false,
        articleComments: data,
        feedsList: showCommentsKeyFeedsList({
            feedId: articleId,
            feedsData: state.feedsList,
        }),
    }),
});

export default HubReducer;
