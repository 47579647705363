/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import '../../WeeklyQuestions/index.scss';
import QuestionStepper from '../QuestionStepper';
import BigReveal from '../../WeeklyQuestions/assets/group-4.svg';
import Thanks from '../../WeeklyQuestions/assets/group-17.svg';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function PreAdditionalQuestionComponent({
    open,
    weeklyQuestionsData,
    originalData,
    postAnswer,
    setSeenPercentage,
    questionResponseFromDb,
    closeWeeklyQuestionModal,
    storeAnsweredQuestionResponse,
    getWeeklyQuestions,
    dontShowWeeklyQuestionsFromPreAdditionalQuestion,
    userPreAdditionalQuestionComment,
    getNextApiInSequence,
}) {
    const [indexToShowOfPage, setIndex] = useState(0);
    const [weeklyQuestionsData01, setWeeklyResponse01] = useState(false);
    const [questionResponseFromDbState, setquestionResponseFromDbState] = useState([]);
    const [heading, setHeading] = useState(true);
    const [subHeading, setSubHeading] = useState('');
    const [openEndResponse, setOpenEndResponse] = useState('');

    const { localisationsAfterLogin } = LocalisationsStore;
    const { question, typeHere } = localisationsAfterLogin;

    useEffect(() => {
        if (weeklyQuestionsData && weeklyQuestionsData.length > 0 && indexToShowOfPage === 0) {
            const data = weeklyQuestionsData.filter(element => element.questionType.includes('_Post')).length > 0;
            setWeeklyResponse01(data);
        }
    }, [weeklyQuestionsData, indexToShowOfPage]);

    useEffect(() => {
        if (questionResponseFromDb && questionResponseFromDb.length > 0) {
            setquestionResponseFromDbState(questionResponseFromDb);
        }
    }, [questionResponseFromDb]);

    const closeModal = () => {
        setquestionResponseFromDbState([]);
        storeAnsweredQuestionResponse(null);
    };

    const getResponseAsset = () => {
        if (
            questionResponseFromDbState.length > 0
            && questionResponseFromDbState[0].questionType === 'Additional_Question'
        ) {
            return questionResponseFromDbState[0].image;
        }
        return Thanks;
    };

    const handleOpenEndResponseChange = e => {
        setOpenEndResponse(e.target.value);
    };

    const handleOpenEndResponseClick = () => {
        const payload = {
            id: questionResponseFromDbState[0].qid,
            response: openEndResponse,
        };
        userPreAdditionalQuestionComment(payload);
        setquestionResponseFromDbState([]);
        storeAnsweredQuestionResponse(null);
    };

    return (
        <Fragment>
            {!dontShowWeeklyQuestionsFromPreAdditionalQuestion && [
                <Modal
                    open={questionResponseFromDbState.length > 0}
                    className="weekly_questions_outer weekly_questions_outer_2"
                >
                    <div className="wrapper_weekly_ques">
                        <div className="inner_wrapper_weekly_ques centered">
                            <div onClick={closeModal} onKeyDown={() => {}} role="button" tabIndex={0} className="cross">
                                <Cross color="black" />
                            </div>
                            {openEndResponse && (
                                <div
                                    onClick={handleOpenEndResponseClick}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                    className="done"
                                >
                                    <span>Done</span>
                                </div>
                            )}
                            <div className="thank_you_note">
                                <div className="image_container">
                                    <img src={getResponseAsset()} alt="thanks" />
                                </div>
                                {questionResponseFromDbState.length > 0 && (
                                    <div className="thanks_note">
                                        <span>{questionResponseFromDbState[0].header_text}</span>
                                        <span>{questionResponseFromDbState[0].answered_text}</span>
                                        {questionResponseFromDbState[0].isOpenText && (
                                            <div className="open_end_textbox">
                                                <textarea
                                                    onChange={handleOpenEndResponseChange}
                                                    value={openEndResponse}
                                                    placeholder={typeHere}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>,
                <Modal open={open} className="weekly_questions_outer">
                    <div className="wrapper_weekly_ques">
                        <div className="inner_wrapper_weekly_ques">
                            <div className="big_reveal_blob">
                                <div className="image_wrapper">
                                    {!weeklyQuestionsData01 ? (
                                        <div className="image_text">
                                            <>
                                                {heading && <span>{question}</span>}
                                                <span>{subHeading}</span>
                                            </>
                                        </div>
                                    ) : (
                                        <div className="image_text">
                                            <>
                                                <span>{subHeading}</span>
                                            </>
                                        </div>
                                    )}
                                    <img src={BigReveal} alt="bigreveal" />
                                </div>
                            </div>
                            <div className="weekly_questions_inner">
                                <QuestionStepper
                                    weeklyQuestionsData={weeklyQuestionsData}
                                    originalData={originalData}
                                    postAnswer={postAnswer}
                                    setSeenPercentage={setSeenPercentage}
                                    indexToShowOfPage={indexToShowOfPage}
                                    setIndex={setIndex}
                                    closeWeeklyQuestionModal={closeWeeklyQuestionModal}
                                    setWeeklyResponse01={setWeeklyResponse01}
                                    setHeading={setHeading}
                                    setSubHeading={setSubHeading}
                                    getWeeklyQuestions={getWeeklyQuestions}
                                    getNextApiInSequence={getNextApiInSequence}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>,
            ]}
        </Fragment>
    );
}
PreAdditionalQuestionComponent.defaultProps = {
    weeklyQuestionsData: [],
    originalData: [],
    questionResponseFromDb: '',
};

PreAdditionalQuestionComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    weeklyQuestionsData: PropTypes.array,
    originalData: PropTypes.array,
    postAnswer: PropTypes.func.isRequired,
    setSeenPercentage: PropTypes.func.isRequired,
    questionResponseFromDb: PropTypes.string,
    closeWeeklyQuestionModal: PropTypes.func.isRequired,
    storeAnsweredQuestionResponse: PropTypes.func.isRequired,
    getWeeklyQuestions: PropTypes.func.isRequired,
    dontShowWeeklyQuestionsFromPreAdditionalQuestion: PropTypes.bool.isRequired,
    userPreAdditionalQuestionComment: PropTypes.func.isRequired,
    getNextApiInSequence: PropTypes.func.isRequired,
};
