import React from 'react'
import Slider from 'react-slick'
import './index.scss'

const CarouselSlider = ({ children }) => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    }
    return (
        <div className="carousel-slider">
            <Slider {...settings}>{children}</Slider>
        </div>
    )
}

export default CarouselSlider
