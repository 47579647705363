import { deleteWebApi, fetchWebApi, postWebApi, putDashboardWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

export const getHomePageFeedApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { getHomePage } = endpoints;
    const url = `${apiBasePath}${getHomePage}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getTaskApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getTodos } = endpoints;
    const url = `${apiBasePath}${getTodos}?page_size=${params.page_size}&page=${params.page}&prompt=custom`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getRecommendedPromptsApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { getRecommendPrompts } = endpoints;
    const url = `${apiBasePath}${getRecommendPrompts}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const closeCardApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { cardDismiss } = endpoints;
    const url = `${apiBasePath}${cardDismiss}${params[1]}/dismiss`;
    return putDashboardWebApi(AuthStore.accessToken, url).request;
};

export const updateCardStatus = id => {
    const apiBasePath = AuthStore.baseUrl;
    const { cardDismiss } = endpoints;
    const url = `${apiBasePath}${cardDismiss}${id}/`;
    return putDashboardWebApi(AuthStore.accessToken, url).request;
};

export const getCoachMarks = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { COACH_MARKS_RP } = endpoints;
    const url = `${apiBasePath}${COACH_MARKS_RP}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const setGoalApi = body => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    const url = apiBasePath + goal;
    return postWebApi(AuthStore.accessToken, url, body).request;
};


export const getTodosApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getTodos } = endpoints;
    const url = `${apiBasePath}${getTodos}?page_size=${params[1]}&page=${params[2]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getGoals = ({ page, page_size, status }) => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    let url = `${apiBasePath}${goal}?page=${page}&page_size=${page_size}`;
    url = status ? `${url}&status=${status}` : url;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getGoalsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    let url = `${apiBasePath}${goal}?page=${params[2]}&page_size=${params[1]}`;
    url = params[3] ? `${url}&status=${params[3]}` : url;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getGoalsByCategoryApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { goalByCategory } = endpoints;
    const url = `${apiBasePath}${goalByCategory}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const deleteGoalApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    let url = `${apiBasePath}${goal}${params[1]}/`;
    url = params[2] ? `${url}?prompt=custom` : url;
    return deleteWebApi(AuthStore.accessToken, url).request;
};

export const completeGoalApi = (id, custom) => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    let url = `${apiBasePath}${goal}${id}/complete/`;
    url = custom ? `${url}?prompt=custom` : url;
    return putDashboardWebApi(AuthStore.accessToken, url).request;
};

export const goalQuestionApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { goalQuestion } = endpoints;
    const url = `${apiBasePath}${goalQuestion}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const editGoalApi = (id, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    const url = `${apiBasePath}${goal}${id}/`;
    return putDashboardWebApi(AuthStore.accessToken, url, body).request;
};

export const getGoalApi = (id, custom) => {
    const apiBasePath = AuthStore.baseUrl;
    const { goal } = endpoints;
    let url = `${apiBasePath}${goal}${id}/`;
    url = custom ? `${url}?prompt=custom` : url;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
