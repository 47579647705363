import { fetchWebApi, postWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import parseJwt from '../../../utils/parseJwt';
import { webApiAuthGet, webApiAuthPost } from '../../../webapis/core';

export const getFeedbackQuestionApi = token => {
    const { feebackQuestion } = endpoints;
    const parsedData = parseJwt(token);
    const baseUrl = parsedData['https://mckinspire.com_baseURL'];
    const url = `${baseUrl}${feebackQuestion}/`;
    return fetchWebApi(token, url).request;
};

export const postFeedbackQuestionAnswerApi = (payload, token) => {
    const { feebackQuestion } = endpoints;
    const parsedData = parseJwt(token);
    const baseUrl = parsedData['https://mckinspire.com_baseURL'];
    const url = `${baseUrl}${feebackQuestion}/`;
    return postWebApi(token, url, payload).request;
};

export const getFeedbackQuestionFromEmailApi = (baseurl, email) => webApiAuthGet(`${baseurl}?email=${email}`).request;

export const postFeedbackQuestionAnswerFromEmailApi = (baseurl, email, payload) => {
    const url = `${baseurl}?email=${email}`;
    return webApiAuthPost(url, payload).request;
};
