/* eslint-disable import/prefer-default-export */
import { fetchWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

export const getUserProgressApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { userProgress } = endpoints;
    const url = `${apiBasePath + userProgress}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
