import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { OutlookCalendar, GoogleCalendar } from 'datebook';
import WarningModal from '../../components/WarningModal';
import Text from '../../components/Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import LocalisationsStore from '../../common/LocalisationsStore';
import './ReminderSuccessModal.scss';
import backArrow from '../../assets/backArrow.svg';
import cross from '../../assets/cross.svg';

const ReminderSuccessModal = ({
    date,
    closeModal,
    handleClickBack,
    isClientPG,
    promptId,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { your_reminder_date, add_to_calender, outlook, google } = localisationsAfterLogin;
    // eslint-disable-next-line
    const [calAdded,setCalAdded] = useState({ qId: -1, set: false });
    let addedToCalendar = JSON.parse(localStorage.getItem('addedToCalendar'));

    useEffect(() => {
        const handleVisibilityEvent = () => {
            // eslint-disable-next-line
            addedToCalendar = JSON.parse(localStorage.getItem('addedToCalendar'));
             setCalAdded({ qId: addedToCalendar?.qId, set: addedToCalendar?.set }); // just to re-render component
        };
        document.addEventListener('visibilitychange', handleVisibilityEvent);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityEvent);
        };
    }, []);
    const handleGoogleClick = () => {
        const startDate = moment(date).isBefore() ? new Date() : new Date(moment(date).format('yyyy-MM-DDTHH:mm:ss'));
        const options = {
            start: startDate,
            end: startDate,
        };
        const googleCalendar = new GoogleCalendar(options);
        const calendarURL = googleCalendar.render();
        localStorage.setItem('addedToCalendar', JSON.stringify({ qId: promptId, set: true }));
        window.open(calendarURL, '_blank');
    };

    const handleOutlookClick = () => {
        const startDate = moment(date).isBefore() ? new Date() : new Date(moment(date).format('yyyy-MM-DDTHH:mm:ss'));
        const options = {
            start: startDate,
            end: startDate,
        };
        const outlookCalendar = new OutlookCalendar(options);
        outlookCalendar.setHost('office');
        let calendarURL = outlookCalendar.render();
        calendarURL = (calendarURL || '').replace('0/deeplink', 'deeplink');
        localStorage.setItem('addedToCalendar', JSON.stringify({ qId: promptId, set: true }));
        window.open(calendarURL, '_blank');
    };

    return (
        <WarningModal classNames="reminder-success-warning-modal">
            <div className="reminder-success-modal">
                <div className="header-actions">
                    <div className="back" onClick={handleClickBack}>
                        <img src={backArrow} alt="" />
                    </div>
                    <div className="cross" onClick={closeModal}>
                        <img src={cross} alt="" />
                    </div>
                </div>
                <div className="reminder-modal-content">
                    <Text
                        fontsize="28px"
                        lineHeight="20px"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.playfairBold}
                        fontweight={700}
                    >
                        {your_reminder_date}
                    </Text>
                    <Text
                        fontsize="22px"
                        lineHeight="24px"
                        fontcolor={fontColors.black}
                        fontfamily={fontFamilies.TheinhardtPanLight}
                        margin="30px 0 0 0"
                    >
                        {isClientPG ? moment(date).format('DD MMMM YYYY') : moment(date).format('DD MMM YYYY, hh:mm a')}
                    </Text>
                    <Text
                        fontsize="18px"
                        lineHeight="20px"
                        fontcolor={(addedToCalendar?.qId === promptId && addedToCalendar?.set) ? fontColors.electricBlue : fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanLight}
                        fontweight={400}
                        margin="60px 0 0 0"
                    >
                        {add_to_calender}
                    </Text>
                    <div className="add-calendar-container">
                        <Text
                            fontsize="22px"
                            lineHeight="24px"
                            fontcolor={fontColors.black}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                            margin="0 20px 0 0"
                            decoration="underline"
                            onClick={handleGoogleClick}
                            cursor="pointer"
                            fontweight={500}
                        >
                            {google}
                        </Text>
                        <Text
                            fontsize="22px"
                            lineHeight="24px"
                            fontcolor={fontColors.black}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                            decoration="underline"
                            onClick={handleOutlookClick}
                            cursor="pointer"
                            fontweight={500}
                        >
                            {outlook}
                        </Text>
                    </div>
                </div>
            </div>
        </WarningModal>
    );
};

export default ReminderSuccessModal;

ReminderSuccessModal.propTypes = {
    date: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleClickBack: PropTypes.func.isRequired,
    isClientPG: PropTypes.bool.isRequired,
    promptId: PropTypes.number.isRequired,
};
