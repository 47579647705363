const types = {
    ADD_FILE_TO_LIST: 'ADD_FILE_TO_LIST',
    UPLOAD_POST: 'UPLOAD_POST',
    GET_TAGS: 'GET_TAGS',
    GET_FEEDS_PENDING: 'GET_FEEDS_PENDING',
    GET_FEEDS_SUCCESS: 'GET_FEEDS_SUCCESS',
    GET_FEEDS_FAILED: 'GET_FEEDS_FAILED',
    GET_FEEDS_MORE_PENDING: 'GET_FEEDS_MORE_PENDING',
    GET_FEEDS_MORE_SUCCESS: 'GET_FEEDS_MORE_SUCCESS',
    GET_FEEDS_MORE_FAILED: 'GET_FEEDS_MORE_FAILED',
    POST_LIKE_PENDING: 'POST_LIKE_PENDING',
    POST_LIKE_SUCCESS: 'POST_LIKE_SUCCESS',
    POST_LIKE_FAILURE: 'POST_LIKE_FAILURE',
    TOGGLE_CREATE_POST_MODAL: 'TOGGLE_CREATE_POST_MODAL',
    GET_THIS_FEED: 'GET_THIS_FEED',
    DELETE_POST_PENDING: 'DELETE_POST_PENDING',
    DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
    DELETE_POST_FAILURE: 'DELETE_POST_FAILURE',
    GET_SAVED_POSTS_PENDING: 'GET_SAVED_POSTS_PENDING',
    GET_SAVED_POSTS_SUCCESS: 'GET_SAVED_POSTS_SUCCESS',
    GET_SAVED_POSTS_FAILURE: 'GET_SAVED_POSTS_FAILURE',
    POST_SAVED_POSTS_PENDING: 'POST_SAVED_POSTS_PENDING',
    POST_SAVED_POSTS_SUCCESS: 'POST_SAVED_POSTS_SUCCESS',
    POST_SAVED_POSTS_FAILURE: 'POST_SAVED_POSTS_FAILURE',
    GET_LIKE_DETAILS_PENDING: 'GET_LIKE_DETAILS_PENDING',
    GET_LIKE_DETAILS_SUCCESS: 'GET_LIKE_DETAILS_SUCCESS',
    GET_LIKE_DETAILS_FAILURE: 'GET_LIKE_DETAILS_FAILURE',
    POST_FOLLOW_USER_PENDING: 'POST_FOLLOW_USER_PENDING',
    POST_FOLLOW_USER_SUCCESS: 'POST_FOLLOW_USER_SUCCESS',
    POST_FOLLOW_USER_FAILURE: 'POST_FOLLOW_USER_FAILURE',
    GET_USERS_OF_TYPE: 'GET_USERS_OF_TYPE',
    GET_ALL_COMMENTS: 'GET_ALL_COMMENTS',
    LIKE_COMMENT: 'LIKE_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',
    UPDATE_FEEDS: 'UPDATE_FEEDS',
    POST_QUIZ_ANSWER: 'POST_QUIZ_ANSWER',
    RESET_QUIZ_DATA: 'RESET_QUIZ_DATA',
    REPORT_POST: 'REPORT_POST',
    RESET_ALL_FEED_FETCHED: 'RESET_ALL_FEED_FETCHED',
    GET_WEEKLY_QUESTIONS: 'GET_WEEKLY_QUESTIONS',
    POST_UNFOLLOW_USER: 'POST_UNFOLLOW_USER',
    GET_USERS_FOLLOWED: 'GET_USERS_FOLLOWED',
    SET_FOLLOW_UNFOLLOW_FEEDS: 'SET_FOLLOW_UNFOLLOW_FEEDS',
    FETCH_USERS_LIST: 'FETCH_USERS_LIST',
    SHARE_POST: 'SHARE_POST',
    FETCH_MORE_USERS_LIST: 'FETCH_MORE_USERS_LIST',
    REMOVE_BOOKMARK: 'REMOVE_BOOKMARK',
    POST_COMMENT: 'POST_COMMENT',
    SET_THIS_FEED: 'SET_THIS_FEED',
    STORE_BANNERS: 'STORE_BANNERS',
    BANNER_OPENED: 'BANNER_OPENED',
    EARNED_BANNER_BADGE_STORE: 'EARNED_BANNER_BADGE_STORE',
    POST_ACTIONABLE_NOTES: 'POST_ACTIONABLE_NOTES',
    POST_PAGE_ANALYTICS: 'POST_PAGE_ANALYTICS',
    GET_TEAM_MEMBERS: 'GET_TEAM_MEMBERS',
    GET_FEED_FILTERS: 'GET_FEED_FILTERS',
    SET_FILTER_STRING: 'SET_FILTER_STRING',
    GET_SEQUENCE_OF_APIS: 'GET_SEQUENCE_OF_APIS',
    GET_NEW_POSTS_BLIP: 'GET_NEW_POSTS_BLIP',
    STORE_NO_OF_NEW_POStS_COUNT: 'STORE_NO_OF_NEW_POStS_COUNT',
    GET_COACHING_JOURNEY: 'GET_COACHING_JOURNEY',
    REMOVE_FEED_LIST_FROM_LOCAL_STATE: 'REMOVE_FEED_LIST_FROM_LOCAL_STATE',
    SET_ARTICLE_INDEX_TIME: 'SET_ARTICLE_INDEX_TIME',
    USER_SCROLL_ANALYTICS: 'USER_SCROLL_ANALYTICS',
    STREAK_CLOSED: 'STREAK_CLOSED',
    UPDATE_SHOW_BADGE: 'UPDATE_SHOW_BADGE',
    SET_GLOBAL_LOADING: 'SET_GLOBAL_LOADING',
};

export default types;
