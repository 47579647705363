import React from 'react';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function PieSegment(props) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { almostNever, seldom, sometimes, often, almostAlways } = localisationsAfterLogin;

    const setCurrentNeedlePosition = () => {
        const { setAngle, degreeVal, value, setAnswer, setColors, setRatingTextValue } = props;
        setAngle(degreeVal);
        setAnswer(value);
        if (value === 1) {
            setColors({
                position1: '#8e2209',
                position2: '#cccccc',
                position3: '#cccccc',
                position4: '#cccccc',
                position5: '#cccccc',
            });
            setRatingTextValue(almostNever);
        } else if (value === 2) {
            setColors({
                position1: '#8e2209',
                position2: '#cb4829',
                position3: '#cccccc',
                position4: '#cccccc',
                position5: '#cccccc',
            });
            setRatingTextValue(seldom);
        } else if (value === 3) {
            setColors({
                position1: '#8e2209',
                position2: '#cb4829',
                position3: '#d63f1c',
                position4: '#cccccc',
                position5: '#cccccc',
            });
            setRatingTextValue(sometimes);
        } else if (value === 4) {
            setColors({
                position1: '#8e2209',
                position2: '#cb4829',
                position3: '#d63f1c',
                position4: '#e26a1f',
                position5: '#cccccc',
            });
            setRatingTextValue(often);
        } else if (value === 5) {
            setColors({
                position1: '#8e2209',
                position2: '#cb4829',
                position3: '#d63f1c',
                position4: '#e26a1f',
                position5: '#ff9500',
            });
            setRatingTextValue(almostAlways);
        }
    };

    const generatePathDef = (centre, rIn, rOut, start, delta) => {
        const endRad = start + delta;
        const startOut = {
            x: centre.x + rOut * Math.cos(start),
            y: centre.y + rOut * Math.sin(start),
        };

        const endOut = {
            x: centre.x + rOut * Math.cos(endRad),
            y: centre.y + rOut * Math.sin(endRad),
        };

        const startIn = {
            x: centre.x + rIn * Math.cos(endRad),
            y: centre.y + rIn * Math.sin(endRad),
        };

        const endIn = {
            x: centre.x + rIn * Math.cos(start),
            y: centre.y + rIn * Math.sin(start),
        };

        const largeArc = delta > Math.PI ? 1 : 0;

        return (
            `M${startOut.x},${startOut.y}`
            + ` A${rOut},${rOut} 0 `
            + `${largeArc},1 ${endOut.x},${endOut.y}`
            + ` L${startIn.x},${startIn.y}`
            + ` A${rIn},${rIn} 0 `
            + `${largeArc},0 ${endIn.x},${endIn.y}`
            + ` L${startOut.x},${startOut.y} Z`
        );
    };

    const { fillColor, strokeColor, start, delta, rIn, rOut, centre } = props;

    const pathDef = generatePathDef(centre, rIn, rOut, start, delta);

    return (
        <g onClick={setCurrentNeedlePosition} role="none">
            <path stroke={strokeColor} fill={fillColor} d={pathDef} />
        </g>
    );
}

PieSegment.propTypes = {
    fillColor: PropTypes.string.isRequired,
    strokeColor: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    delta: PropTypes.number.isRequired,
    rIn: PropTypes.number.isRequired,
    rOut: PropTypes.number.isRequired,
    centre: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
    degreeVal: PropTypes.number.isRequired,
    setAngle: PropTypes.func.isRequired,
    setAnswer: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    setColors: PropTypes.func.isRequired,
    setRatingTextValue: PropTypes.func.isRequired,
};
