import React from 'react';
import PropTypes from 'prop-types';
import SvgPicture from '../SvgPicture';
import './index.scss';

export default function CtaArrow({ color, showBold }) {
    if (showBold) {
        return (
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.5" cy="10.0117" r="9" stroke="#3E3E3E" strokeWidth="2" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3534 9.68692L11.3534 6.01172L10.6464 6.66131L13.7929 9.55232H5.5V10.4711H13.7929L10.6464 13.3621L11.3534 14.0117L15.3534 10.3365C15.5489 10.1569 15.5489 9.86655 15.3534 9.68692"
                    fill="#3E3E3E"
                />
                <path
                    d="M15.3534 9.68692L11.3534 6.01172L10.6464 6.66131L13.7929 9.55232H5.5V10.4711H13.7929L10.6464 13.3621L11.3534 14.0117L15.3534 10.3365C15.5489 10.1569 15.5489 9.86655 15.3534 9.68692"
                    stroke="#3E3E3E"
                />
            </svg>
        );
    }
    return (
        <div className="right_cta_container">
            <SvgPicture height="1.125rem" width="1.125rem" style={{ border: `2px solid ${color}` }}>
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.8534 4.6752L6.85343 1L6.14644 1.64959L9.2929 4.5406H1V5.4594H9.2929L6.14644 8.35041L6.85343 9L10.8534 5.3248C11.0489 5.14517 11.0489 4.85483 10.8534 4.6752Z"
                        fill="#3E3E3E"
                    />
                    <path
                        d="M10.8534 4.6752L6.85343 1L6.14644 1.64959L9.2929 4.5406H1V5.4594H9.2929L6.14644 8.35041L6.85343 9L10.8534 5.3248C11.0489 5.14517 11.0489 4.85483 10.8534 4.6752"
                        stroke={color}
                    />
                </svg>
            </SvgPicture>
        </div>
    );
}

CtaArrow.defaultProps = {
    showBold: false,
};

CtaArrow.propTypes = {
    color: PropTypes.string.isRequired,
    showBold: PropTypes.bool,
};
