import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import removeIcon from './assets/removeIcon.svg';
import LocalisationsStore from '../../common/LocalisationsStore';

const SavedPostsContainer = ({ savedPosts, history, removeBookMark }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { saved, content, remove } = localisationsAfterLogin;

    const handleClickOnCard = e => {
        const { currentTarget: { dataset: { articleid } } } = e;
        history.push(`/feed-details-article/${articleid}`);
    };

    const handleClickOnRemove = e => {
        const { currentTarget: { dataset: { articleid } } } = e;
        removeBookMark(articleid);
        e.stopPropagation();
    };

    const getParsedDate = postDate => {
        const date = new Date(postDate);
        return moment(date).format('MMM DD, YYYY');
    };

    const isMobile = window.innerWidth <= 740;

    return (
        <div className="saved_posts_column">
            <div className="save_posts_heading">
                <div className="topText">{saved}</div>
                <div className="bottomText">{content}</div>
            </div>
            {savedPosts.map(savedItem => (
                <div className="saved_post_card" key={savedItem.article}>
                    <div
                        className="post_image"
                        data-articleid={savedItem.article}
                        onClick={handleClickOnCard}
                        role="none"
                    />
                    <div className="saved_post_details">
                        <span
                            className="post_title"
                            data-articleid={savedItem.article}
                            onClick={handleClickOnCard}
                            role="none"
                        >
                            {savedItem.title}
                        </span>
                        <span className="post_date">{getParsedDate(savedItem.createdAt)}</span>
                        {isMobile && (
                            <div
                                className="remove_button"
                                data-articleid={savedItem.article}
                                onClick={handleClickOnRemove}
                                role="none"
                            >
                                <img src={removeIcon} alt="" />
                                <span>{remove}</span>
                            </div>
                        )}
                    </div>
                    {!isMobile && (
                        <div
                            className="remove_button"
                            data-articleid={savedItem.article}
                            onClick={handleClickOnRemove}
                            role="none"
                        >
                            <img src={removeIcon} alt="" />
                            <span>{remove}</span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

SavedPostsContainer.propTypes = {
    savedPosts: PropTypes.array.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    removeBookMark: PropTypes.func.isRequired,
};

export default SavedPostsContainer;
