import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import TodoCard from '../../components/TodoCard';
import './index.scss';
import backArrow from '../../assets/backArrow.svg';
import { getTaskApi } from '../HomeContainer/redux/apis';
import PromptArticle from '../PromptArticle/index.container';
import AddNewPost from '../Feeds/AddNewPost';
import LocalisationsStore from '../../common/LocalisationsStore';
import EmptyToDoList from '../../assets/emptyToDoList.svg';
import AddGoal from '../Todos/AddGoal';
import GoalDetails from '../Todos/GoalDetails';
import { pageAnalytics } from '../../utils/functions';

const AllToDos = ({ history, uploadPost, tags, uploadingPost, getTags, globalLoader }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { toDoTitle, notodos } = localisationsAfterLogin;
    const [isLoading, setLoading] = useState(true);
    const [articleId, setTodoArticleId] = useState('');
    const [view, setView] = useState(0);
    const [page, setPage] = useState(1);
    const [todos, setToDos] = useState({ count: 0, items: [] });
    const [showPrompt, setShowPrompt] = useState({
        show: false,
    });
    const [activeGoalDetail, setActiveGoalDetail] = useState({});
    const status = localStorage.getItem('status');
    const [hashtag, setHashTag] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [showCreatePost, setShowCreatePost] = useState(false);

    useEffect(() => {
        pageAnalytics.initialise('todo');
        return () => {
            pageAnalytics.complete('todo');
        };
    }, []);

    useEffect(() => {
        getTags(history);
    }, [getTags, history]);

    useEffect(() => {
        if (status === 'false') {
            history.push('/onboarding');
        }
    }, [status, history]);

    const fetchInitialTasks = () => {
        getTaskApi({ page_size: 10, page })
            .then(response => {
                const { data, count } = response.data;
                setLoading(false);

                if (page === 1) {
                    setToDos({ count, items: [...data] });
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                } else {
                    setToDos(({ count, items }) => ({ count, items: [...items, ...data] }));
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchInitialTasks();
        // eslint-disable-next-line
    }, [page])

    useEffect(() => {
        if (view === 0) {
            if (page === 1) {
                fetchInitialTasks();
            }
            setPage(1);
        }
        // eslint-disable-next-line
    }, [view])

    const handleClosePromptArticle = ({ show }) => {
        if (page === 1) {
            fetchInitialTasks();
        }
        setPage(1);
        setShowPrompt({ show });
        pageAnalytics.complete(`todo/${activeGoalDetail.id}/promptDetail`);
        setActiveGoalDetail({});
    };

    const handleOnTodoClick = card => {
        if (card.type === 'custom') {
            setActiveGoalDetail({ id: card.id });
            setView(2);
        } else if (card.type === 'prompt') {
            setActiveGoalDetail({ id: card.id });
            setTodoArticleId(card.id);
            setShowPrompt({ show: true });
            pageAnalytics.initialise(`todo/${card.id}/promptDetail`);
        }
    };

    const scrollCallback = () => {
        if (todos.items.length < todos.count) {
            setPage(page => page + 1);
        }
    };

    const handleBackCLick = () => {
        history.push('/app/home');
    };
    const showToDoList = () => {
        let showToDoList = false;
        if (todos?.items?.length > 0) {
            todos.items.forEach(card => {
                if (card.hide === undefined) {
                    showToDoList = true;
                }
            });
        }
        return showToDoList;
    };

    const handleClose = () => {
        setShowCreatePost(false);
    };
    const toDoContent = showToDoList() ? (
        <div className="to_do_container_wrapper">
            {todos.items.map((card, index) => (
                <TodoCard key={index} index={index} card={card} onClick={() => handleOnTodoClick(card)} isHidden={card.hide} />
            ))}
        </div>
    ) : (
        <div className="empty_to_do_list">
            <img src={EmptyToDoList} alt="no-to do" />
            <p>{notodos}</p>
        </div>
    );

    const showModal = () => {
        switch (view) {
            case 1:
                return <AddGoal setView={setView} />;
            case 2:
                return <GoalDetails setView={setView} activeGoalDetail={activeGoalDetail} />;
            default:
                return null;
        }
    };

    return (
        <div className="Home_container_from_all_todos">
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={articleId}
                    setShowCreatePost={setShowCreatePost}
                />
            )}

            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || { practice: [], category: [] }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}

            {showModal()}

            {(isLoading || globalLoader) && <Loader position="absolute" showBackground />}
            <div className="to_do_container">
                <div className="todo_list_header">
                    <div className="header-title">
                        <div className="back" onClick={handleBackCLick}>
                            <img src={backArrow} alt="" />
                        </div>
                        <Text
                            fontsize="1.3rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0 1rem"
                        >
                            {toDoTitle}
                        </Text>
                    </div>
                    <div className="todo-count">
                        {todos.count - todos.items.length + todos.items.filter(el => !el.hide).length}
                    </div>
                </div>
                {!(isLoading || globalLoader) && toDoContent}
            </div>
            <BottomScrollListener onBottom={scrollCallback} offset={1000} debounce={1000} />
        </div>
    );
};

AllToDos.propTypes = {
    history: PropTypes.object.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }).isRequired,
    uploadPost: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    getTags: PropTypes.func.isRequired,
    globalLoader: PropTypes.bool.isRequired,
};

export default AllToDos;
