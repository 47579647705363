/* eslint-disable block-scoped-var */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import InputField from '../../components/InputField';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import InspireTopIcon from '../../assets/InspireTopIcon.svg';
import SingleSelectDropDownWithLabel from '../../components/SingleSelectDropDownWithLabel';
import ClippedImage from '../../components/ClippedImage';
import camera from '../../assets/camera.svg';
import {
    selectLanguages,
    selectLoadingImage,
    selectPopUpOpen,
    selectQuestions,
} from '../UnAuthenticatedContent/selector';
import { getAvailableLanguages } from '../UnAuthenticatedContent/actions';
import { getQuestionsAction, postAnswers, postImage, toggleShowSelectorModal } from '../Profile/actions';
import { postTandC, updateCurrentLanguages } from '../UnAuthenticatedContent/apis';
import particleBg from '../../assets/particleBg.svg';
import './index.scss';
import ImageSelectorContainer from '../Profile/ImageSelectorContainer';
import PreviewImage from '../../components/PreviewImage';
import LocalisationsStore from '../../common/LocalisationsStore';

const CustomColorCheckbox = withStyles({
    root: {
        color: fontColors.electricBlue,
        '&$checked': {
            color: fontColors.electricBlue,
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

function OnboardingComponent({
    isLoading,
    getAvailableLanguages,
    availableLanguages,
    getQuestionsAction,
    questions,
    postImage,
    postAnswers,
    history,
    checked,
    setChecked,
    preferredName,
    setPreferredName,
    toggleShowSelectorModal,
    popUpOpen,
    selectedImage,
    setSelectedImage,
    imageUri,
    setImageUri,
}) {
    const [state, setState] = useState({
        language: '',
    });
    const nameQuestion = questions.find(question => question.question_detail.type === 'text');
    const profilePictureQuestion = questions.find(question => question.question_detail.type === 'profile_picture');
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        WelcomeToInspire,
        FewDetails,
        PreferredName,
        EnterName,
        AddAPhoto,
        Continue,
        ByAccepting,
        termsCondition,
    } = localisationsAfterLogin;

    useEffect(() => {
        getQuestionsAction();
        getAvailableLanguages();
    }, [getQuestionsAction, getAvailableLanguages]);


    useEffect(() => {
        if (availableLanguages.length > 0) {
            const defaultLanguage = availableLanguages.find(language => language.is_default);
            setState(defaultLanguage);
        }
    }, [availableLanguages]);

    const handleCheckBoxChange = event => {
        event.stopPropagation();
        setChecked(event.target.checked);
        postTandC();
    };

    const handleUsernameChange = event => {
        setPreferredName(event.target.value);
    };

    const handleChange = event => {
        const { target: { value } } = event;
        const language = availableLanguages.find(language => language.value === value);
        localStorage.setItem('languageIsRightToLeft', language.is_right_to_left);
        const lang = {
            language,
        };
        setState(lang);
    };

    const handleTandCClick = event => {
        event.stopPropagation();
        history.push('/onboarding/T&C');
    };

    const completeProfile = () => {
        if (selectedImage) {
            postImage({
                data: [
                    {
                        id: profilePictureQuestion.id,
                        response: new File([selectedImage], 'pict.jpeg'),
                        type: 'profile_picture',
                    },
                ],
            });
        }
        const trimmedPreferredName = preferredName?.trim();
        localStorage.setItem('full_name', trimmedPreferredName);
        postImage({ preferred_name: trimmedPreferredName });
        if (localStorage.getItem('is_hide_onboarding_lang') === 'false') {
            updateCurrentLanguages({
                lang: state.language ? state.language.value : '',
                preferred_name: trimmedPreferredName,
            });
        }
        postAnswers(
            {
                ...nameQuestion,
                response: trimmedPreferredName,
            },
            history,
        );
    };

    const handleImageSelect = e => {
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        const file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const _renderImageUrl = () => {
        if (imageUri) return imageUri;
        return false;
    };

    return (
        <div className="unauth_content_container_wrapper_onboarding">
            <img src={particleBg} alt="particles" className="particleBg" particleBg />
            {isLoading && <Loader position="absolute" showBackground />}
            <div className="unauth_content_container">
                <img src={InspireTopIcon} alt="inspire_logo" />
                <div className="central_content">
                    <div className="header_info">
                        <Text
                            fontsize="2.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.625rem 0"
                        >
                            {WelcomeToInspire}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                        >
                            {FewDetails}
                        </Text>
                    </div>
                    <div className="main_section">
                        {questions.length > 0 && nameQuestion && (
                            <div className="input_element">
                                <InputField
                                    label={PreferredName}
                                    type="text"
                                    value={preferredName}
                                    onChange={handleUsernameChange}
                                    fontSize="1rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.textBlack}
                                    placeholder={EnterName}
                                    maxLength={15}
                                />
                            </div>
                        )}
                        {localStorage.getItem('is_hide_onboarding_lang') === 'false' && (
                            <div className="input_element">
                                <SingleSelectDropDownWithLabel
                                    label="DO YOU HAVE A PREFERRED LANGUAGE?"
                                    onChange={handleChange}
                                    value={state.language}
                                    name="employee_count"
                                    placeholder="Language"
                                    menuItems={availableLanguages}
                                />
                            </div>
                        )}
                        {questions.length > 0 && profilePictureQuestion && (
                            <div className="input_element">
                                <Text
                                    fontsize="0.75rem"
                                    fontcolor={fontColors.textBlack}
                                    fontfamily={fontFamilies.TheinhardtPanMedium}
                                    margin="0 0 0.5rem 0"
                                >
                                    {AddAPhoto}
                                </Text>
                                <div className="dp_image_conntainer">
                                    <div className="dp_image">
                                        {!isLoading && imageUri ? (
                                            <>
                                                <svg
                                                    width="80"
                                                    height="80"
                                                    viewBox="0 0 80 80"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <clipPath id="dpBlob">
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M16.8813 2.76493C1.85833 9.53345 -3.48079 35.3815 2.24932 55.6253C6.86908 71.9464 50.3202 93.8503 67.7207 68.3083C88.3546 38.0197 79.2042 18.3301 67.7207 9.56193C59.0949 2.97575 31.9042 -4.00359 16.8813 2.76493Z"
                                                            fill="none"
                                                        />
                                                    </clipPath>
                                                </svg>
                                                <ClippedImage
                                                    src={imageUri}
                                                    alt=""
                                                    width="auto"
                                                    height="5rem"
                                                    clipid="dpBlob"
                                                    bgColor={fontColors.purple}
                                                />
                                                <label>
                                                    <input
                                                        className="input_image"
                                                        type="file"
                                                        onChange={handleImageSelect}
                                                        name="profile_image_choose_again"
                                                        accept=".jpeg,.jpg,.png,"
                                                    />

                                                    <img className="choose_again" src={camera} alt="chooseagain" />
                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <label>
                                                    {imageUri && (
                                                        <PreviewImage
                                                            src={_renderImageUrl()}
                                                            alt=""
                                                            width={imageUri ? 238 : 142}
                                                            height={imageUri ? 'auto' : 163}
                                                            margin="20px"
                                                        />
                                                    )}
                                                    <div
                                                        className="onboard-image-selector"
                                                        onClick={() => {
                                                            toggleShowSelectorModal(true);
                                                        }}
                                                    >
                                                        <ImageSelectorContainer
                                                            imageUri={imageUri}
                                                            setImageUri={setImageUri}
                                                            postImage={postImage}
                                                            isLoading={isLoading}
                                                            popUpOpen={popUpOpen}
                                                            toggleShowSelectorModal={toggleShowSelectorModal}
                                                        />
                                                    </div>
                                                    <svg
                                                        style={{ position: 'initial' }}
                                                        width="80"
                                                        height="80"
                                                        viewBox="0 0 80 80"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M16.8813 2.76493C1.85833 9.53345 -3.48079 35.3815 2.24932 55.6253C6.86908 71.9464 50.3202 93.8503 67.7207 68.3083C88.3546 38.0197 79.2042 18.3301 67.7207 9.56193C59.0949 2.97575 31.9042 -4.00359 16.8813 2.76493Z"
                                                            fill={fontColors.purple}
                                                        />
                                                        <image
                                                            id="image0"
                                                            width="30"
                                                            height="30"
                                                            x="50%"
                                                            y="50%"
                                                            transform="translate(-15,-15)"
                                                            xlinkHref={camera}
                                                        />
                                                    </svg>
                                                </label>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="input_element">
                            <FormControlLabel
                                control={(
                                    <CustomColorCheckbox
                                        checked={checked}
                                        onChange={handleCheckBoxChange}
                                        name="benchmarked"
                                    />
                                )}
                                label={(
                                    <div>
                                        <Text
                                            fontsize="1rem"
                                            fontcolor={fontColors.textBlack}
                                            fontfamily={fontFamilies.TheinhardtPanRegular}
                                        >
                                            {ByAccepting}
                                        </Text>
                                        <Text
                                            fontsize="1rem"
                                            fontcolor={fontColors.electricBlue}
                                            fontfamily={fontFamilies.TheinhardtPanRegular}
                                            cursor="pointer"
                                            onClick={handleTandCClick}
                                        >
                                            {termsCondition}
                                        </Text>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="action_container">
                            <Actionbutton
                                fontSize="1.125rem"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.siteWhite}
                                bgColor={fontColors.electricBlue}
                                disabled={!(checked && preferredName?.trim())}
                                onClick={completeProfile}
                            >
                                {Continue}
                            </Actionbutton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

OnboardingComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getAvailableLanguages: PropTypes.func.isRequired,
    availableLanguages: PropTypes.arrayOf(
        PropTypes.shape({
            is_default: PropTypes.bool,
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ).isRequired,
    getQuestionsAction: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    postImage: PropTypes.func.isRequired,
    toggleShowSelectorModal: PropTypes.func.isRequired,
    postAnswers: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    checked: PropTypes.bool.isRequired,
    setChecked: PropTypes.func.isRequired,
    preferredName: PropTypes.string.isRequired,
    setPreferredName: PropTypes.func.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    selectedImage: PropTypes.string.isRequired,
    setSelectedImage: PropTypes.func.isRequired,
    imageUri: PropTypes.string.isRequired,
    setImageUri: PropTypes.func.isRequired,
};

const stateToProps = createStructuredSelector({
    popUpOpen: selectPopUpOpen(),
    isLoading: selectLoadingImage(),
    availableLanguages: selectLanguages(),
    questions: selectQuestions(),
});

const dispatchToProps = {
    getAvailableLanguages,
    getQuestionsAction,
    postImage,
    postAnswers,
    toggleShowSelectorModal,
};

export default withRouter(connect(stateToProps, dispatchToProps)(OnboardingComponent));
