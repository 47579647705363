import LocalisationsStore from '../../common/LocalisationsStore';

export const LOGIN = 'LOGIN';
export const SIGN_UP = 'SIGN UP';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const FORGOT_PASSWORD = 'FORGOT PASSWORD';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const RESET_SCREEN = 'RESET_SCREEN';
export const GET_STATUS = 'GET_STATUS';
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';
export const EMAIL_SUBSCRIBE = 'EMAIL_SUBSCRIBE';
export const SMRF_LINK = 'SMRF_LINK';
export const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';
export const SET_HIDE_SECTIONS = 'SET_HIDE_SECTIONS';
export const SET_HIDE_ADD_GOAL_BTN = 'SET_HIDE_ADD_GOAL_BTN';
export const GET_STREAK_STATUS = 'GET_STREAK_STATUS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_CHECKSUM = 'GET_CHECKSUM';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const POST_LANGUAGE = 'POST_LANGUAGE';

const localisationsBeforeLogin = LocalisationsStore.localisationsBeforeLogin || {
    accountLock: 'The account is locked. Please contact inspire@mckinsey.com',
    accountLockContactEmail: 'inspire@mckinsey.com',
};
const { accountLock, accountLockContactEmail } = localisationsBeforeLogin;

export const ERRORS = {
    INVALID_CREDENTIALS: 'You’ve entered the wrong email ID or password, try again.',
    'Please inquire with your organization to make sure you can sign up':
        'Please inquire with your organization to make sure you can sign up',
    SIGNUP_ERROR: 'Sign up error, reach out to your administrator for more details?',
    USER_BLOCKED: `<span>${accountLock}</span><span><a target="_blank" href="mailto:${accountLockContactEmail}"/> ${accountLockContactEmail}</span>`,
};

export const PRIVACY_LINK = 'https://ohi-npn-upload-image.s3.amazonaws.com/mpc/common/privacyPolicy.html';
export const TERMS_LINK = 'https://ohi-npn-upload-image.s3.amazonaws.com/mpc/common/termsAndConditions.html';

export const SITE_TXT = {
    EMAIL_LABEL: 'Email Address',
    RE_ENTER_EMAIL_LABEL: 'Re-enter Email Address',
    REGISTERED_EMAIL_LABEL: 'Registered Email Address',
    PASSWORD_LABEL: 'Password',
    FORGOT_PASSWORD_LABEL: 'Forgot Password?',
    NEXT: 'NEXT',
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD_TEXT: 'Confirm your registered email address and we will send you a link to reset your password.',
    TERMS_LABEL: 'By configuring this experience, I accept the Terms and Conditions',
    HELP_TOOLTIP: 'Need help? Contact our 24/7 quick support staff!',
    GET_APP: 'Get the app now!',
    HELP_TEXT: 'Need a quick solution? Send us an email or chat with us! Our support staff is here for you 24/7.',
    EMIAL_BUTTON: 'Send an email',
    CHAT_BUTTON: 'Chat with us',
    BACK_LOGIN: 'Back to Login',
    SIGN_UP_DONE_TEXT: 'Click on the confirmation link sent to your email ID to finish signing up.',
    FORGOT_DONE_TEXT: 'A link has been sent to your email id to set a new password.',
    OPEN_IN_APPSTORE: 'Open in App Store',
};
