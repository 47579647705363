import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MakeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import icons from './assets/svg';
import { NEXT, GO_TO_FEED } from '../../utils/constants';
import CreateProfile from './CreateProfile';
import SetGoals from './SetGoals';
import Thankyou from './Thankyou';
import LocalisationsStore from '../../common/LocalisationsStore';

const TabPanel = withRouter(props => {
    const { children, value, index, clickOverlay, goalScreen, getThanksPage, thanksPage, ...other } = props;
    const nextClick = () => {
        if (index === 0) {
            clickOverlay(false);
        } else if (index === 1) {
            if (thanksPage) {
                props.history.push('/feeds');
            } else {
                getThanksPage(true);
            }
        }
    };

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>
                {value === index && (
                    <Box p={3} className="innerBox">
                        {children}
                    </Box>
                )}
                {goalScreen === '' && (
                    <Box className="nextButton">
                        <Button
                            className="nextBtn"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={nextClick}
                        >
                            {thanksPage ? GO_TO_FEED : NEXT}
                        </Button>
                    </Box>
                )}
            </Box>
        </Typography>
    );
});

TabPanel.defaultProps = {
    children: null,
    goalScreen: '',
    thanksPage: false,
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    clickOverlay: PropTypes.func.isRequired,
    goalScreen: PropTypes.string,
    getThanksPage: PropTypes.func.isRequired,
    thanksPage: PropTypes.bool,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = MakeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        borderRadius: 10,
    },
}));

export default function ProfileTabs({
    postImage,
    questions,
    isLoading,
    popUpOpen,
    toggleShowSelectorModal,
    clickOverlay,
    setNameQuestionAns,
    setTimeQuestionAns,
    tabIndex,
    vcrbDetails,
    getVcrbData,
    addNotes,
    getNotes,
    notes,
}) {
    const classes = useStyles();
    const [image, setImage] = useState('');
    const [goalScreen, setGoalScreen] = useState('');
    const getGoalScreen = type => {
        setGoalScreen(type);
    };
    // const [value, setValue] = useState(tabIndex);
    const [thanksPage, setThanksPage] = useState(false);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { CREATE_PROFILE, SET_GOALS } = localisationsAfterLogin;
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const getThanksPage = val => {
        setThanksPage(val);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" className={thanksPage ? `${'tabHeaders'}` : ''}>
                <Tabs value={tabIndex} variant="fullWidth">
                    <Tab
                        label={CREATE_PROFILE}
                        icon={icons.group(tabIndex === 0 && true)}
                        {...a11yProps(0)}
                        disabled={tabIndex === 1}
                    />
                    <Tab
                        label={SET_GOALS}
                        icon={icons.goals(tabIndex === 1 && true)}
                        {...a11yProps(1)}
                        disabled={tabIndex === 0}
                    />
                    {/* <Tab label={PERSONALIZE} icon={icons.personalize(value === 2 && true)} {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0} clickOverlay={clickOverlay} goalScreen={goalScreen}>
                <CreateProfile
                    imageUri={image}
                    setImageUri={setImage}
                    postImage={postImage}
                    questions={questions}
                    isLoading={isLoading}
                    popUpOpen={popUpOpen}
                    toggleShowSelectorModal={toggleShowSelectorModal}
                    setNameQuestionAns={setNameQuestionAns}
                    setTimeQuestionAns={setTimeQuestionAns}
                />
            </TabPanel>
            <TabPanel
                value={tabIndex}
                index={1}
                clickOverlay={clickOverlay}
                className="setGoalsPanel"
                goalScreen={goalScreen}
                getThanksPage={getThanksPage}
                thanksPage={thanksPage}
            >
                <Box>
                    {thanksPage ? (
                        <Thankyou />
                    ) : (
                        <SetGoals
                            vcrbDetails={vcrbDetails}
                            getVcrbData={getVcrbData}
                            isLoading={isLoading}
                            addNotes={addNotes}
                            getNotes={getNotes}
                            notes={notes}
                            getGoalScreen={getGoalScreen}
                        />
                    )}
                </Box>
            </TabPanel>
            {/* <TabPanel value={value} index={2} clickOverlay={clickOverlay}>
                Item Three
            </TabPanel> */}
        </div>
    );
}

ProfileTabs.defaultProps = {
    userDisplayPicture: '',
};

ProfileTabs.propTypes = {
    postImage: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    userDisplayPicture: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    toggleShowSelectorModal: PropTypes.func.isRequired,
    clickOverlay: PropTypes.func.isRequired,
    setNameQuestionAns: PropTypes.func.isRequired,
    setTimeQuestionAns: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
    getVcrbData: PropTypes.func.isRequired,
    vcrbDetails: PropTypes.arrayOf({
        detail: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        inner_subheading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    getNotes: PropTypes.func.isRequired,
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    goalScreen: PropTypes.string.isRequired,
    getThanksPage: PropTypes.func.isRequired,
    thanksPage: PropTypes.bool.isRequired,
};
