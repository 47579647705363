import React from 'react';
import PropTypes from 'prop-types';
import PreviewHeaderImage from './PreviewHeaderImage';

export default function MaskMenu({ profilePic, handleClick, selectedKey }) {
    return (
        <div className=" outline-none" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg width="0" height="0" viewBox="0 0 42 43">
                <clipPath id="myHeaderPath">
                    <path
                        id="prefix__a"
                        d="M8.23 1.405c-7.324 3.44-9.927 16.58-7.133 26.87 2.252 8.296 23.434 19.43 31.917 6.447 10.059-15.396 5.598-25.405 0-29.862-4.205-3.347-17.46-6.895-24.784-3.455z"
                    />
                </clipPath>
            </svg>
            <div className="header_image_svg_wrapper" role="none" onClick={handleClick}>
                <svg width="43" height="45" viewBox="0 0 43 45" className={selectedKey === 2 ? 'svgAdjusted' : ''}>
                    <path
                        transform="translate(2 2)"
                        id="prefix__a"
                        fill="none"
                        d="M8.23 1.405c-7.324 3.44-9.927 16.58-7.133 26.87 2.252 8.296 23.434 19.43 31.917 6.447 10.059-15.396 5.598-25.405 0-29.862-4.205-3.347-17.46-6.895-24.784-3.455z"
                        stroke="#D4401D"
                        strokeWidth="3"
                    />
                </svg>
                <div className="header_image_adjusted">
                    <PreviewHeaderImage src={profilePic} alt="image" width="39px" height="41px" id="myHeaderPath" />
                </div>
            </div>
        </div>
    );
}

MaskMenu.defaultProps = {
    handleClick: () => {},
};

MaskMenu.propTypes = {
    profilePic: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    selectedKey: PropTypes.number.isRequired,
};
