import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    isInsubscribed: false,
    alreadyUnsubscribed: false,
};

const unsubscribeReducer = createReducer(INITIAL_STATE, {
    [`${types.UNSUBSCRIBE_USER}_DONE`]: state => ({
        ...state,
        isInsubscribed: true,
    }),
    [types.ALREADY_UNSUBSCRIBED]: state => ({
        ...state,
        alreadyUnsubscribed: true,
    }),
});

export default unsubscribeReducer;
