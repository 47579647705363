import AuthStore from '../../../common/AuthStore';
import endpoints from '../../../Endpoints';
import { fetchWebApi, putDashboardWebApi } from '../../../webapis/dashboard';

export const getNotificationsListApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { notificationsList } = endpoints;
    const pageNumber = params[1];
    const url = `${apiBasePath}${notificationsList}?page=${pageNumber}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const readThisNotificationApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { notificationsList } = endpoints;
    const url = `${apiBasePath}${notificationsList}`;
    return putDashboardWebApi(AuthStore.accessToken, url, body).request;
};

export const markAllAsReadApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { markAllRead } = endpoints;
    const url = `${apiBasePath}${markAllRead}`;
    return putDashboardWebApi(AuthStore.accessToken, url, body).request;
};
