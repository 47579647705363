import { fetchWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

const getFeedsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { feeds } = endpoints;
    const url = `${apiBasePath + feeds}${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export default getFeedsApi;
