import { connect } from 'react-redux';
import WeeklyQuestions from './WeeklyQuestionsComponent';
import weeklyQuestionsReducer from './redux/reducer';
import weeklyQuestionsSaga from './redux/sagas';
import {
    postAnswer,
    setSeenPercentage,
    storeAnsweredQuestionResponse,
    closeWeeklyQuestionModal,
    chapterIntroSeen,
    userAdditionalQuestionComment,
} from './redux/actions';
import { getNextApiInSequence } from '../../common/actions';

const stateToProps = state => {
    const WeeklyQuestionsReducer = state.get('weeklyQuestionsReducer');
    return {
        open: WeeklyQuestionsReducer.open,
        weeklyQuestionsData: WeeklyQuestionsReducer.weeklyQuestionsData,
        originalData: WeeklyQuestionsReducer.originalData,
        questionResponseFromDb: WeeklyQuestionsReducer.questionResponseFromDb,
        chapterIntro: WeeklyQuestionsReducer.chapterIntro,
    };
};

export { weeklyQuestionsSaga, weeklyQuestionsReducer };

const dispatchToProps = {
    postAnswer,
    setSeenPercentage,
    storeAnsweredQuestionResponse,
    closeWeeklyQuestionModal,
    chapterIntroSeen,
    userAdditionalQuestionComment,
    getNextApiInSequence,
};

export default connect(stateToProps, dispatchToProps)(WeeklyQuestions);
