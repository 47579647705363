import React, { Fragment, useState } from 'react';
import './index.scss';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import dotsIcon from '../../../../assets/dots.svg';
import { completeGoalApi, deleteGoalApi } from '../../../HomeContainer/redux/apis';
import { Cross } from '../../../UnAuthenticatedContent/assets/svg';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import Actionbutton from '../../../../components/ActionButton';
import EditGoal from '../../EditGoal';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../../utils/functions';
import AuthStore from '../../../../common/AuthStore';

const Actions = ({ goal, callBack, editCallBack }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const { id } = goal;
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        markAsComplete,
        edit,
        deletePostAction,
        deleteThisTask,
        deleteDescription,
        yesDelete,
        dontDelete,
    } = localisationsAfterLogin;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const completeGoal = () => {
        completeGoalApi(id, 'custom').then(() => {
            handleClose();
            callBack(id);
        });
    };

    const deleteGoal = () => {
        setDeleteModal(true);
        pageAnalytics.initialise(`todoDelete/${goal.id}`);
        handleClose();
    };

    const editGoal = () => {
        setEditModal(true);
        handleClose();
    };

    return (
        <Fragment>
            <div>
                <div className="dot-wrapper pointer" onClick={handleClick} id="simple-menu">
                    <img className="report_dots" src={dotsIcon} alt="" role="none" />
                </div>
                <div>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="goalActions"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem
                            key={1}
                            onClick={() => {
                                completeGoal();
                            }}
                        >
                            <span>{markAsComplete}</span>
                        </MenuItem>

                        <MenuItem key={2} onClick={editGoal}>
                            <span>{edit}</span>
                        </MenuItem>

                        <MenuItem
                            key={3}
                            onClick={() => {
                                deleteGoal();
                            }}
                        >
                            <span>{deletePostAction}</span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            {deleteModal && (
                <div className="delete_goal__modal">
                    <div
                        className="close_button"
                        onClick={() => {
                            setDeleteModal(false);
                            pageAnalytics.complete(`todoDelete/${goal.id}`);
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                    >
                        <Cross color="black" />
                    </div>
                    <div className="title">{deleteThisTask}</div>
                    <div className="subtitle">{deleteDescription}</div>
                    <Actionbutton
                        fontSize="1.125rem"
                        fontFamily={fontFamilies.TheinhardtPanRegular}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        width="40%"
                        onClick={() => {
                            deleteGoalApi([AuthStore.accessToken,id, 'custom']).then(() => {
                                setDeleteModal(false);
                                pageAnalytics.complete(`todoDelete/${goal.id}`);
                                handleClose();
                                callBack(id);
                            });
                        }}
                    >
                        {yesDelete}
                    </Actionbutton>

                    <div
                        className="no-delete"
                        onClick={() => {
                            setDeleteModal(false);
                            pageAnalytics.complete(`todoDelete/${goal.id}`);
                        }}
                    >
                        {dontDelete}
                    </div>
                </div>
            )}
            {editModal && <EditGoal editCallBack={editCallBack} goalDetail={goal} closeModal={setEditModal} />}
        </Fragment>
    );
};

Actions.propTypes = {
    goal: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
};
export default Actions;
