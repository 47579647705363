import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TabProfileComponent from './TabProfileComponent';
import myProgressReducer from '../MyProgress/redux/reducer';
import myProgressSaga from '../MyProgress/redux/sagas';
import { getStatus, logoutUser } from '../UnAuthenticatedContent/actions';

const stateToProps = state => {
    const MyProgressReducer = state.get('myProgressReducer');
    const AuthenticationReducer = state.get('authentication').toJS();
    const RefReducer = state.get('RefreshReducer');
    return {
        open: MyProgressReducer.open,
        resetUserDetails: AuthenticationReducer.resetUserDetails,
        isEmailSubscribe: AuthenticationReducer.isEmailSubscribe,
        smrfLink: AuthenticationReducer.smrfLink,
        isFetched: AuthenticationReducer.isFetched,
        clientExpired: RefReducer.clientExpired,
    };
};

const dispatchToProps = { logoutUser, getStatus };

export { myProgressSaga, myProgressReducer };

export default withRouter(connect(stateToProps, dispatchToProps)(TabProfileComponent));
