import { connect } from 'react-redux';
import PracticesDetailsContainer from './PracticesDetailsContainer';
import PracticeDetailReducer from './redux/reducer';
import PracticeDetailSaga from './redux/sagas';
import { getPracticeDetails, storeProTagViewed, storeInPracticeDetails } from './redux/actions';
import { uploadPost, getTags } from '../Feeds/redux/actions';
import { closeCard } from '../HomeContainer/redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('PracticeDetailReducer');
    const FeedsREDUCER = state.get('feedsReducer');
    const RefREDUCER = state.get('RefreshReducer');
    return {
        isLoading: REDUCER.isLoading,
        practiceDetailsData: REDUCER.practiceDetailsData,
        uploadingPost: REDUCER.uploadingPost,
        tags: FeedsREDUCER.tags,
        clientExpired: RefREDUCER.clientExpired,
    };
};

const dispatchToProps = {
    getPracticeDetails,
    uploadPost,
    closeCard,
    getTags,
    storeProTagViewed,
    storeInPracticeDetails,
};

export { PracticeDetailReducer, PracticeDetailSaga };

export default connect(stateToProps, dispatchToProps)(PracticesDetailsContainer);
