import { connect } from 'react-redux';
import TagUser from './TagUser';
import tagUserReducer from './redux/reducer';
import tagUserSaga from './redux/sagas';
import { getUsersList, storeQueriedUsers } from './redux/actions';

const stateToProps = state => {
    const TagUserReducer = state.get('tagUserReducer');
    return {
        users: TagUserReducer.users,
    };
};

export { tagUserSaga, tagUserReducer };

const dispatchToProps = {
    getUsersList,
    storeQueriedUsers,
};

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(TagUser);
