const Endpoints = {
    apiBasePath: `https://${process.env.REACT_APP_API_BASEPATH}/common`,
    getToken: '/user/login/',
    signUp: '/user/signup/',
    forgotPassword: '/user/forget/',
    userStatus: 'user/status/',
    refreshToken: '/user/token/',
    createProfile: 'content/question/?cat=profile',
    postImage: 'user/data/',
    vcrb: 'vcrb/list',
    notes: 'vcrb/note/',
    tags: 'socialfeed/tags/list/',
    makePost: 'socialfeed/feed/',
    feeds: 'feed/article/',
    like: 'feed/like/',
    posts: 'socialfeed/feed/',
    userData: 'user/data/',
    articleLikes: 'feed/article-likes/',
    commentLikes: 'feed/comment-likes/',
    followUser: 'socialfeed/user-following/',
    usersOfType: 'user/followers-followings/?status=',
    bookmarkPosts: 'feed/bookmark/',
    getAllComments: 'feed/article-comments/',
    postComment: 'feed/comment/',
    postQuiz: 'feed/quiz-response/',
    reportPost: 'socialfeed/feed/',
    weeklyQuestion: 'content/user-weekly-question/',
    unfollowUser: 'socialfeed/unfollow-user/',
    fetchUserList: 'user/list',
    sharePost: 'feed/Share/',
    logout: '/user/logout/',
    checksum: 'translations/checksum?lang=',
    localizationKeys: 'translations/localization?lang=',
    chapterIntroduction: 'content/calendar-introduction/',
    verifyDomain: '/user/verify-domain?domain=',
    authorize: '/authorize?domain=',
    auth0post: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
    bannerClicked: 'roadblock/banner-click/',
    postActionableNotes: 'feed/article-notes/',
    analyticsPage: 'analytics/page/',
    userProgress: 'user/progress/',
    teamMembers: 'user/social-user-list?social_group=',
    feedFilters: 'feed/filters/',
    sequenceApi: 'user/api-sequence/',
    additionalQuestionComment: 'content/user-additional-comment/',
    newPosts: 'feed/check-new-post?date=',
    coachingJourney: 'feed/coaching-journey/?q=',
    userScrollAnalytics: 'analytics/article-focus/',
    userActivity: 'user/activity/',
    notificationsList: 'user/notification/',
    markAllRead: 'user/notification-read-all/',
    dissmissStreak: 'user/streak',
    languages: 'translations/languages/',
    postLanguage: 'user/language/',
    feebackQuestion: 'content/feedback-question',
    emailFeedback: '/content/email-feedback/',
    faq: 'translations/faq',
    faqEmail: '/translations/faq-by-email/',
    getEmailLanguages: 'translations/langs-by-tag/',
    postEmailLanguages: 'user/lang-by-tag/',
    getLocalisationsFromUser: 'translations/localization-by-email/',
    UNIVERSAL_HOME_DATA_ONEFIVE: 'feed/all-feeds/?version=INSPIRE1_5',
    USER_INSTRUCTION: 'user/instruction/',
    USER_TAND_C: 'user/term-condition/',

    // Inspire 1.5 routes
    getPractices: 'feed/practices/',
    getHomePage: 'feed/home/',
    fetchAllNotifications: 'user/notification/',
    getRecommendPrompts: 'feed/recommended_promt/',
    getTodos: 'feed/todo/',
    cardDismiss: 'feed/cards/',
    getPromptDetails: 'feed/article/',
    PROFILE_EMAIL_SUBS: 'user/email-unsubscribe/',
    RESET_PROFILE: 'user/user-reset/',
    FEED_PROTAG: 'feed/protags/',
    COACH_MARKS_RP: 'translations/coach_mark/recommended_coach_mark/',
    COACH_MARKS_PRACTICE: 'translations/coach_mark/practice_coach_mark',
    COACH_MARKS_ACCELETOR: 'translations/coach_mark/profile_video_acceletor',
    goal: 'content/goal/',
    goalByCategory: 'content/goal/by_category',
    goalQuestion: 'content/goal-question/',
    emailCtaTrack: '/content/user-email-cta-track/',
    setReminder: '/feed/email_todo_reminder/',
    completeGoalDetails: '/complete',
    workOnProgress: 'content/working-on-progress/',
    homeMatrix: 'user/home-matrix',
}

export default Endpoints
