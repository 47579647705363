import createReducer from '../utils/redux-utils/createReducer';
import types from '../containers/Feeds/redux/types';

const INITIAL_STATE = {
    coachingJourneyChapter: [],
    loading: false,
};

const coachingJourney = createReducer(INITIAL_STATE, {
    [`${types.GET_COACHING_JOURNEY}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_COACHING_JOURNEY}_DONE`]: (state, { data }) => ({
        ...state,
        loading: false,
        coachingJourneyChapter: data,
    }),
});

export default coachingJourney;
