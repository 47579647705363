/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react';
import { call } from 'redux-saga/effects';
import axios from 'axios';
import pako from 'pako';
import moment from 'moment';
import { OutlookCalendar, GoogleCalendar } from 'datebook';
import { NAME_PATTERN, CODE_UNAUTHORIZE_USER, CODE_BAD_REQUEST } from './constants';
import { getTokenFromRefreshToken } from '../webapis/core';
import AuthStore from '../common/AuthStore';
import LocalisationsStore from '../common/LocalisationsStore';
import { pageAnalyticsApi } from '../containers/Feeds/redux/apis';
import { logoutUserApi } from '../containers/UnAuthenticatedContent/apis';

export const isValidPattern = (value, pattern) => {
    const re = pattern || NAME_PATTERN;
    return re.test(value);
};

export const random = number => Math.floor(Math.random() * number) + 1;

export const isTokenExpired = () => new Date() > new Date(Number(AuthStore.expTime));

const handleInActiveUser = async fromLogin => {
    const { localisationsBeforeLogin } = LocalisationsStore;
    if (AuthStore && AuthStore.accessToken) {
        await logoutUserApi();
        AuthStore.clear();
        if (fromLogin) {
            LocalisationsStore.localisationsBeforeLogin = JSON.stringify(localisationsBeforeLogin);
        }
    }
    if (!fromLogin) {
        window.location.reload();
    }
};

const logoutUser = async () => {
    await logoutUserApi();
    AuthStore.clear();
    window.location.reload();
};

export function* tokenValidatorGenerator({ saga, payload, body, history, fromLogin }) {
    if (AuthStore.refreshToken) {
        if (isTokenExpired()) {
            try {
                const { data } = yield call(getTokenFromRefreshToken, {
                    refresh_token: AuthStore.refreshToken,
                });
                AuthStore.accessToken = data.id_token;
                AuthStore.baseUrl = 'https://mckinspire.com_baseURL';
                AuthStore.expTime = 'exp';
            } catch (e) {
                if (e?.response?.status === CODE_BAD_REQUEST) {
                    logoutUser();
                }
            }
        }
        try {
            return yield call(saga, payload, body);
        } catch (e) {
            if (e?.response?.status === CODE_UNAUTHORIZE_USER) {
                handleInActiveUser(fromLogin);
            }
            throw e;
        }
    }
    if (isTokenExpired()) {
        localStorage.removeItem('userProfile');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('status');
        AuthStore.deleteccessToken();
        history.push('/');
    } else {
        try {
            return yield call(saga, payload, body);
        } catch (e) {
            if (e?.response?.status === CODE_UNAUTHORIZE_USER) {
                handleInActiveUser(e);
            }
            throw e;
        }
    }
    return {};
}

export function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'Android';
}

const createImage = url => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
});

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(file);
        }, 'image/jpeg');
    });
}

export async function getCroppedImgBase64(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );

    // As Base64 string
    return canvas.toDataURL('image/jpeg');
}

export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
}

export function decompressedFile(file) {
    const headers = {
        'Cache-Control': 'no-store',
    };
    return axios.get(file, { headers }).then(response => {
        if (response) {
            const encodedData = response.data;
            const compressed = new Uint8Array(
                atob(encodedData)
                    .split('')
                    .map(c => c.charCodeAt(0)),
            );
            const inflate = pako.inflate(compressed);
            const binaryconverter = new TextDecoder('utf-8');
            const dataOutput = binaryconverter.decode(inflate);
            return dataOutput;
        }
        return false;
    });
}

export function validURL(str) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
            + '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator
    return !!pattern.test(str);
}

export const getLastUpdatedAt = postTime => {
    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || {
        now: 'Just now',
        aMinuteAgo: 'min ago',
        anHourAgo: '1 hr ago',
        secondsAgo: 'sec ago',
        minutesAgo: 'mins ago',
        hoursAgo: 'hrs ago',
        daysAgo: 'days ago',
        weeksAgo: 'weeks ago',
        monthsAgo: 'months ago',
        yearsAgo: 'years ago',
        lastYear: '1 yr ago',
        lastMOnth: 'month ago',
        lastWeek: 'week ago',
        yesterday: '1 day ago',
    };
    const {
        now,
        aMinuteAgo,
        anHourAgo,
        secondsAgo,
        minutesAgo,
        hoursAgo,
        daysAgo,
        weeksAgo,
        monthsAgo,
        yearsAgo,
        lastYear,
        lastMOnth,
        lastWeek,
        yesterday,
    } = localisationsAfterLogin;

    if (postTime) {
        const date1 = moment(new Date(postTime));
        const date2 = moment(new Date());
        const difference = {
            inSeconds: date2.diff(date1, 'seconds'),
            inMinutes: date2.diff(date1, 'minutes'),
            inHours: date2.diff(date1, 'hours'),
            inDays: date2.diff(date1, 'days'),
            inWeeks: date2.diff(date1, 'weeks'),
            inMonths: date2.diff(date1, 'months'),
            years: date2.diff(date1, 'years'),
        };

        if (Math.floor(difference.inDays / 365) >= 2) {
            return `${Math.floor(difference.inDays / 365)} ${yearsAgo}`;
        }
        if (Math.floor(difference.inDays / 365) >= 1) {
            return lastYear;
        }
        if (Math.floor(difference.inDays / 30) >= 2) {
            return `${Math.floor(difference.inDays / 30)} ${monthsAgo}`;
        }
        if (Math.floor(difference.inDays / 30) >= 1) {
            return `1 ${lastMOnth}`;
        }
        if (Math.floor(difference.inDays / 7) >= 2) {
            return `${Math.floor(difference.inDays / 7)} ${weeksAgo}`;
        }
        if (Math.floor(difference.inDays / 7) >= 1) {
            return `1 ${lastWeek}`;
        }
        if (difference.inDays >= 2) {
            return `${difference.inDays} ${daysAgo}`;
        }
        if (difference.inDays >= 1) {
            return yesterday;
        }
        if (difference.inHours >= 2) {
            return `${difference.inHours} ${hoursAgo}`;
        }
        if (difference.inHours >= 1) {
            return anHourAgo;
        }
        if (difference.inMinutes >= 2) {
            return `${difference.inMinutes} ${minutesAgo}`;
        }
        if (difference.inMinutes >= 1) {
            return `${difference.inMinutes} ${aMinuteAgo}`;
        }
        if (difference.inSeconds >= 3) {
            return `${difference.inSeconds} ${secondsAgo.replace('1 ', '')}`;
        }
        return now;
    }
    return '';
};

export const showCommentsKeyFeedsList = ({ feedId, feedList }) => feedList.map(item => {
    if (item.id === feedId) {
        item.showComments = true;
    }
    return item;
});

export const getStateAccordingToExcutedApi = api => {
    switch (api) {
        case 'content/user-weekly-question?type=before': {
            return 'preAdditionalQuestionsData';
        }
        case 'content/calendar-introduction': {
            return 'chapterIntro';
        }
        case 'content/user-weekly-question/': {
            return 'weeklyQuestionsData';
        }
        default: {
            return 'weeklyQuestionsData';
        }
    }
};

export const mobileCheck = () => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a,
            )
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4),
            )
        ) check = true;
    }(navigator.userAgent || navigator.vendor || window.opera));
    return check;
};
export const pageAnalytics = {
    initialise: page_tag => {
        const date = new Date();
        const pageObject = {
            page_tag,
            device_id: navigator.userAgent,
        };
        if (window.sessionStorage && AuthStore.baseUrl) {
            const sessionObj = window.sessionStorage.getItem('pageAnalytics');
            if (sessionObj) {
                const currentPage = JSON.parse(sessionObj);
                currentPage[page_tag] = { ...pageObject, start_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS') };
                window.sessionStorage.setItem('pageAnalytics', JSON.stringify(currentPage));
            } else {
                window.sessionStorage.setItem(
                    'pageAnalytics',
                    JSON.stringify({
                        [page_tag]: { ...pageObject, start_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS') },
                    }),
                );
            }
        }
    },
    complete: page_tag => {
        const date = new Date();

        setTimeout(() => {
            if (window.sessionStorage && AuthStore.baseUrl) {
                const sessionObj = window.sessionStorage.getItem('pageAnalytics');
                if (sessionObj) {
                    const currentPage = JSON.parse(sessionObj);
                    currentPage[page_tag] = {
                        ...currentPage[page_tag],
                        end_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    };
                    window.sessionStorage.setItem('pageAnalytics', JSON.stringify(currentPage));
                }
            }
            pageAnalytics.send(page_tag);
        }, [0]);
    },
    send: page_tag => {
        if (window.sessionStorage && AuthStore.baseUrl) {
            const sessionObj = window.sessionStorage.getItem('pageAnalytics');
            if (sessionObj) {
                const currentPage = JSON.parse(sessionObj);
                pageAnalyticsApi([currentPage[page_tag]]).then(() => {
                    const _currentPage = JSON.parse(window.sessionStorage.getItem('pageAnalytics') || null) || {};
                    delete _currentPage[page_tag];
                    window.sessionStorage.setItem('pageAnalytics', JSON.stringify(_currentPage));
                });
            }
        }
    },
};

export const handleGoogleClick = (dueDate, id) => {
    const startDate = moment(dueDate).isBefore() ? new Date() : new Date(moment(dueDate).format('yyyy-MM-DDTHH:mm:ss'));
    const options = {
        start: startDate,
        end: startDate,
    };
    const googleCalendar = new GoogleCalendar(options);
    const calendarURL = googleCalendar.render();
    localStorage.setItem('addedToCalendar', JSON.stringify({ qId: id, set: true }));
    window.open(calendarURL, '_blank');
};

export const handleOutlookClick = (dueDate, id) => {
    const startDate = moment(dueDate).isBefore() ? new Date() : new Date(moment(dueDate).format('yyyy-MM-DDTHH:mm:ss'));
    const options = {
        start: startDate,
        end: startDate,
    };
    const outlookCalendar = new OutlookCalendar(options);
    outlookCalendar.setHost('office');
    let calendarURL = outlookCalendar.render();
    calendarURL = (calendarURL || '').replace('0/deeplink', 'deeplink');
    localStorage.setItem('addedToCalendar', JSON.stringify({ qId: id, set: true }));
    window.open(calendarURL, '_blank');
};

export const nullable = subRequirement => {
    const check = (required, props, key, ...rest) => {
        if (props[key] === null) {
            return null;
        }
        const sub = required ? subRequirement.isRequired : subRequirement;
        return sub(props, key, ...rest);
    };
    const fn = check.bind(null, false);
    fn.isRequired = check.bind(null, true);
    return fn;
};

export function useMobileCheck(resolution = 740) {
    const [isMobile, setIsMobile] = useState((window.innerWidth <= resolution));

    useEffect(() => {
        const handleCheckMobile = () => {
            if (window.innerWidth <= resolution) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleCheckMobile);

        return (() => window.addEventListener('resize', handleCheckMobile));
        // eslint-disable-next-line
    }, []);
    return isMobile;
}

export function useAddToCalendar() {
    // eslint-disable-next-line
    const [calAdded, setCalAdded] = useState({ qId: -1, set: false });

    useEffect(() => {
        const handleVisibilityEvent = () => {
            const addedToCalendar = JSON.parse(localStorage.getItem('addedToCalendar'));
            setCalAdded({ qId: addedToCalendar?.qId, set: addedToCalendar?.set }); // just to re-render component
        };
        document.addEventListener('visibilitychange', handleVisibilityEvent);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityEvent);
        };
    }, []);
}

export const toSentenceCase = str => {
    const _str = str || '';
    if (_str) {
        const firstLetter = _str[0].toUpperCase();
        const remaining = _str.slice(1).toLowerCase();
        return `${firstLetter}${remaining}`;
    }
    return '';
};
