import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Text from '../../components/Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';
import { getFontFamily, getIconColor, getTitleColor } from './helper';
import SvgPicture from '../../components/SvgPicture';
import { PracticeStateType } from './PromptDetails';
import AddNewPost from '../Feeds/AddNewPost';
import { uploadPost } from '../Feeds/redux/actions';
import PromptArticle from '../PromptArticle/index.container';
import { nullable, useMobileCheck } from '../../utils/functions';
import { breakpointTabLandscape } from '../../utils/constants';

function SubPromptsContainer(props) {
    const {
        article,
        practiceId,
        uploadPost,
        uploadingPost,
        tags,
        refreshPage,
        getPracticeDetails,
    } = props;
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState({
        show: false,
        prompt_id: null,
    });
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [hashtag, setHashTag] = useState('');
    const isMobileWithTab = useMobileCheck(breakpointTabLandscape);

    const handleClosePromptArticle = () => {
        setShowPrompt({
            show: false,
            prompt_id: null,
        });
        if (refreshPage != null) refreshPage(practiceId);
    };
    const handleClose = () => {
        setShowCreatePost(false);
    };


    const handlePromptClick = (event, article) => {
        if (article.state === PracticeStateType.lockedOrNew) {
            return;
        }
        if (article.hide_article_list && article.hide_article_list.hide_listing) {
            setShowPrompt({
                show: true,
                prompt_id: article.hide_article_list.article_id,
            });
        } else {
            event.stopPropagation();
            if (article.component_name === '') {
                article.component_name = ' ';
            }
            history.push({
                pathname: `/app/practice/${article.component_name}/${practiceId}/${article.id}`,
            });
            if (getPracticeDetails) {
                getPracticeDetails(practiceId);
            }
        }
    };

    const ArticleWrapper = styled.div`
    display: flex
    border-radius: 5px;
    justify-content: space-between;
`;

    const RightView = styled.div`
        display: flex;
        padding: 0 1.5rem 0 0;
        background-color: ${fontColors.siteWhite};
        margin: 0 0 1rem 0;
        border-radius: 0px 5px 5px 0px;
        min-height: 92px;
        align-items: ${article.component_name ? 'auto' : 'center'};
        opacity: ${article.state === PracticeStateType.lockedOrNew ? 0.5 : 1};
        width: 100%;
        justify-content: flex-end;
    `;
    const _getOpacity = () => {
        if (article.state === PracticeStateType.lockedOrNew) {
            return 0.5;
        }
        return 1.0;
    };

    return (
        <div>
            {showPrompt.show
                && (
                <PromptArticle
                    handleCrossClick={e => handleClosePromptArticle(e)}
                    id={showPrompt.prompt_id}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
                )}
            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || {
                        practice: [],
                        category: [],
                    }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
            <ArticleWrapper key={`p-article-${article.id}`} onClick={e => handlePromptClick(e, article)}>
                <div className="practice_article sub_prompt_article fullWidth" style={{ opacity: _getOpacity() }}>
                    {article.component_name && (
                        <Text
                            fontsize="14px"
                            fontcolor={fontColors.borderGrey}
                            fontfamily={fontFamilies.TheinhardtPanMedium}
                            letterSpacing="1px"
                            textTransform="uppercase"
                            cursor="pointer"
                        >
                            {article.component_name}
                        </Text>
                    )}

                    <Text
                        fontsize="18px"
                        fontcolor={getTitleColor(article)}
                        fontfamily={getFontFamily(article)}
                        cursor="pointer"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        width={isMobileWithTab ? '100%' : '350px'}
                        display="block"
                        margin="10px 0 0 0"
                    >
                        {article.title}
                    </Text>
                </div>
                <RightView>
                    {PracticeStateType.completed !== article.state && (
                        <p className="sequence">
                            <span className="bold">{article.completed_sub_promts}</span>
                            /
                            {article.total_sub_prompts}
                        </p>
                    )}
                    {PracticeStateType.completed === article.state && (
                        <SvgPicture
                            height="1.5rem"
                            width="1.5rem"
                            style={{
                                backgroundColor: getIconColor(article),
                                borderRadius: '50%',
                                marginTop: '1rem',
                            }}
                        >
                            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.14567 8.66667C3.98743 8.66667 3.8292 8.6047 3.70866 8.48141L0 4.68773L0.874008 3.79368L4.14567 7.14034L11.126 0L12 0.894044L4.58267 8.48141C4.46214 8.6047 4.3039 8.66667 4.14567 8.66667Z"
                                    fill="white"
                                />
                            </svg>
                        </SvgPicture>
                    )}
                </RightView>
            </ArticleWrapper>

        </div>
    );
}

const Article = {
    created_date: PropTypes.string,
    id: PropTypes.number,
    practice_sequence: PropTypes.number,
    show_type: PropTypes.string,
    state: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
};

SubPromptsContainer.defaultProps = {
    refreshPage: null,
    getPracticeDetails: null,
    uploadingPost: false,
};

SubPromptsContainer.propTypes = {
    article: PropTypes.shape(Article).isRequired,
    practiceId: PropTypes.string.isRequired,
    uploadingPost: PropTypes.bool,
    uploadPost: PropTypes.func.isRequired,
    tags: nullable(PropTypes.object).isRequired,
    refreshPage: PropTypes.func,
    getPracticeDetails: PropTypes.func,
};

const stateToProps = state => {
    const PracticeReducer = state.get('PracticeDetailReducer');
    const FeedsREDUCER = state.get('feedsReducer');
    return {
        uploadingPost: PracticeReducer.uploadingPost,
        tags: FeedsREDUCER.tags,
    };
};

const dispatchToProps = {
    uploadPost,
};
export default connect(stateToProps, dispatchToProps)(SubPromptsContainer);
