import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { OutlookCalendar, GoogleCalendar } from 'datebook';
import styled from 'styled-components';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import CustomDateTimePicker from '../../../../components/DateTimePicker';
import './index.scss';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import TextWithTags from '../../../../components/TextWithTags';

const TextBox = ({
    elem,
    callBack,
    callBackData,
    freeze,
}) => {
    const {
        id,
        question_text,
        show_due_date_field,
        due_date_title,
        placeholder,
        required,
        header,
        footer,
        config,
        response,
        due_date,
        is_editable,
        add_to_calender: showAddToCalendarText,
    } = elem;
    const { localisationsAfterLogin } = LocalisationsStore;
    const { quiz_mandatory, add_to_calender, outlook, google } = localisationsAfterLogin;
    const [value, setValue] = useState('');
    const [showAddToCalendar, setShowAddToCalender] = useState(false);
    const [error, errorCallback] = useState(false);
    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0);
    const [date, setDate] = useState(minDate);
    const ref = useRef(null);
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    // eslint-disable-next-line
    const [calAdded, setCalAdded] = useState({ qId: -1, set: false });
    let addedToCalendar = JSON.parse(localStorage.getItem('addedToCalendar'));

    const computeHeight = () => {
        ref.current.style.height = '0px';

        const { scrollHeight } = ref.current;
        const computed = window.getComputedStyle(ref.current);

        const newHeight = parseInt(computed.getPropertyValue('border-top-width'), 10)
                            + scrollHeight
                            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        ref.current.style.overflowY = newHeight < 160 ? 'hidden' : 'unset';
        ref.current.style.height = newHeight < 160 ? `${newHeight}px` : '160px';
    };

    useEffect(() => {
        if (elem.response) {
            setValue(elem.response);
        } else {
            setValue('');
        }
    }, [elem]);

    useEffect(() => {
        const handleVisibilityEvent = () => {
            // eslint-disable-next-line
            addedToCalendar = JSON.parse(localStorage.getItem('addedToCalendar'));
            setCalAdded({ qId: addedToCalendar?.qId, set: addedToCalendar?.set }); // just to re-render component
        };
        document.addEventListener('visibilitychange', handleVisibilityEvent);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityEvent);
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            computeHeight();
        }
    }, [value, ref]);


    useEffect(() => {
        setValue(response);
    }, [response]);

    useEffect(() => {
        setDate(due_date && new Date(due_date.replace('Z', '')));
    }, [due_date]);

    useEffect(() => {
        if (freeze && showAddToCalendarText) {
            setShowAddToCalender(true);
        }
    }, [freeze, showAddToCalendarText]);

    const setDueDate = due_date => {
        setDate(due_date);
        setShowAddToCalender(true);
        const existingData = callBackData.question_response[id] || {};
        callBack({
            ...callBackData,
            question_response: {
                ...callBackData.question_response,
                [id]: {
                    ...existingData,
                    due_date: due_date.replace('T', ' '),
                },
            },
        });
    };

    const handleGoogleClick = () => {
        const startDate = moment(date || minDate).isBefore() ? new Date() : new Date(moment(date || minDate).format('yyyy-MM-DDTHH:mm:ss'));
        const options = {
            start: startDate,
            end: startDate,
        };
        const googleCalendar = new GoogleCalendar(options);
        const calendarURL = googleCalendar.render();
        localStorage.setItem('addedToCalendar', JSON.stringify({ qId: id, set: true }));
        window.open(calendarURL, '_blank');
    };

    const handleOutlookClick = () => {
        const startDate = moment(date || minDate).isBefore() ? new Date() : new Date(moment(date || minDate).format('yyyy-MM-DDTHH:mm:ss'));
        const options = {
            start: startDate,
            end: startDate,
        };
        const outlookCalendar = new OutlookCalendar(options);
        outlookCalendar.setHost('office');
        let calendarURL = outlookCalendar.render();
        calendarURL = (calendarURL || '').replace('0/deeplink', 'deeplink');
        localStorage.setItem('addedToCalendar', JSON.stringify({ qId: id, set: true }));
        window.open(calendarURL, '_blank');
    };

    const QuesTitle = styled.div`
        font-size: ${`${config.font_size}px` || '16px'};
        color: ${config.color};
        text-transform: ${config.all_caps ? 'uppercase' : ''};
        font-family: ${config.font_family === 'PlayfairDisplay' ? 'playfairRegular' : config.font_family};
        font-weight: ${config.font_weight};
        white-space: pre-wrap;
    `;
    const dateFormat = isClientPG ? 'DD MMM YYYY' : 'DD MMM YYYY, hh:mm a';
    const shouldShowTextDate = isClientPG && showAddToCalendar;
    return (
        <div className="text-box">
            {header && (
            <div className="header-text">
                <TextWithTags text={header.text} config={header.config} />
            </div>
            )}
            <QuesTitle>{question_text}</QuesTitle>
            <div className="textarea">
                <textarea
                    ref={ref}
                    className={`${freeze || !is_editable ? 'disabled' : ''}`}
                    disabled={freeze || !is_editable}
                    onFocus={computeHeight}
                    onBlur={computeHeight}
                    onChange={event => {
                        setValue(event.target.value);
                        const existingData = callBackData.question_response[id] || {};
                        const due_date = existingData.due_date || moment(minDate)
                            .format('yyyy-MM-DD HH:mm:ss.SSS');
                        const formattedObject = {
                            ...existingData,
                            response: event.target.value,
                            question_id: id,
                        };
                        if (show_due_date_field) {
                            formattedObject.due_date = due_date;
                        }
                        callBack({
                            ...callBackData,
                            question_response: {
                                ...callBackData.question_response,
                                [id]: formattedObject,
                            },
                        });
                    }}
                    value={value}
                    placeholder={!freeze ? placeholder : null}
                />
                {required && <div className="mandatory">{quiz_mandatory}</div>}
            </div>
            {show_due_date_field && (
                <div className={`date-reminder-container${isClientPG ? ' date-reminder-container-pg' : ''}`}>
                    <div className="date-picker">
                        <div className="dueDate-title">{due_date_title}</div>
                        <div className={`${freeze ? 'freeze' : ''}`}>
                            {(!freeze || !showAddToCalendarText) ? (
                                <>
                                    {isClientPG ? (
                                        <CustomDatePicker
                                            border={freeze ? '' : '2px solid #1F40E6'}
                                            errorShow={error}
                                            errorCallBack={errorCallback}
                                            handleDateChange={setDueDate}
                                            selectedDate={date || minDate}
                                            onCloseCallback={() => {}}
                                            showTextState={showAddToCalendar && showAddToCalendarText}
                                        />
                                    ) : (
                                        <CustomDateTimePicker
                                            border={freeze ? '' : '2px solid #1F40E6'}
                                            errorShow={error}
                                            errorCallBack={errorCallback}
                                            handleDateChange={setDueDate}
                                            selectedDate={date || minDate}
                                            onCloseCallback={() => {}}
                                        />
                                    )}
                                </>
                            ) : <div className="due-date-text">{moment(date || minDate).format(dateFormat)}</div>}
                        </div>
                    </div>
                    {(showAddToCalendar && showAddToCalendarText) && (
                    <div className="add-calendar-container">
                        {(freeze || shouldShowTextDate) && <div className={`${(addedToCalendar?.qId === id && addedToCalendar?.set) ? 'dueDate-title blue-text' : 'dueDate-title grey-text'}`}>{add_to_calender}</div>}
                        {(!freeze && !shouldShowTextDate) && <div className={`${(addedToCalendar?.qId === id && addedToCalendar?.set) ? 'dueDate-title blue-text extra-margin' : 'dueDate-title grey-text extra-margin'}`}>{add_to_calender}</div>}
                        <div
                            className="calendar-options"
                            style={!freeze ? {
                                margin: '10px 0 0 0',
                                height: '24px',
                            } : {
                                height: '24px',
                            }}
                        >
                            <div className="calendar-option-text" onClick={handleGoogleClick}>{google}</div>
                            <div className="calendar-option-text" onClick={handleOutlookClick}>{outlook}</div>
                        </div>
                    </div>
                    )}
                </div>
            )}
            {footer && (
                <div className="header-text">
                    <TextWithTags text={footer.text} config={footer.config} />
                </div>
            )}
        </div>
    );
};

export default TextBox;

TextBox.propTypes = {
    elem: PropTypes.shape({
        id: PropTypes.number,
        question_text: PropTypes.string,
        show_due_date_field: PropTypes.bool,
        add_to_calender: PropTypes.bool,
        due_date_title: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        header: PropTypes.shape({
            text: PropTypes.string,
            config: PropTypes.shape({}),
        }),
        footer: PropTypes.shape({
            text: PropTypes.string,
            config: PropTypes.shape({}),
        }),
        config: PropTypes.shape({
            font_size: PropTypes.number,
            color: PropTypes.string,
            all_caps: PropTypes.bool,
            font_family: PropTypes.string,
            font_weight: PropTypes.string,
        }),
        response: PropTypes.string,
        due_date: PropTypes.string,
        is_editable: PropTypes.bool,
    }).isRequired,
    callBackData: PropTypes.shape({
        question_response: PropTypes.shape({}),
    }).isRequired,
    callBack: PropTypes.func.isRequired,
    freeze: PropTypes.bool.isRequired,
};
