import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import MagicSliderDots from 'react-magic-slider-dots';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import RecommendedPromptCard from '../../components/RecommendedPromptCard';
import DynamicCardMapper from '../../components/DynamicCard/DynamicCardMapper';
import { updateCardStatus } from './redux/apis';
import CompletedRpCard from '../../components/CompletedRpCard';
import AddNewPost from '../Feeds/AddNewPost';
import coachMark from '../../assets/coachMark.svg';
import CoachMarks from './CoachMarks';
import LocalisationsStore from '../../common/LocalisationsStore';
import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import PromptArticle from '../PromptArticle/index.container';
import Goals from '../Goals';
import GoalsTabView from '../Goals/GoalsTabView';
import Todos from '../Todos/index.container';
import { pageAnalytics } from '../../utils/functions';
import { CODE_CLIENT_EXPIRED } from '../../utils/constants';
import { getWorkInProgressApi } from '../UnAuthenticatedContent/apis';
import WorkOnProgress from '../WorkOnProgress';
import AuthStore from '../../common/AuthStore';
import Endpoints from '../../Endpoints';
import { webApiGet } from '../../webapis/core';
import UserMatrix from '../UserMatrix';
import ProgressSnapshot from '../TabProfileContainer/ProgressSnapshot';
import WarningModal from '../../components/WarningModal';

export default function HomeComponent({
    getHomePage,
    isLoading,
    dynamicCards,
    getRecommendedPrompts,
    getTodos,
    recommendedPrompts,
    closeCard,
    history,
    uploadPost,
    getTags,
    tags,
    uploadingPost,
    firstTimeDataLoaded,
    hideSections,
    hideAddGoalBtn,
    refreshGoals,
    clientExpired,
    clientExpiredAction,
    resetClientExpiredAction,
    getGoalsAction,
    getGoalsByCategoriesAction,
    refreshTodo,
    getUserMatrix,
    resetGoalTab,
}) {
    const [showPrompt, setShowPrompt] = useState({
        show: false,
    });
    // eslint-disable-next-line no-unused-vars
    const [articleId, setTodoArticleId] = useState('');
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [hashtag, setHashTag] = useState('');
    const [showCoachMarks, setShowCoachMarks] = useState(false);
    const [workOnProgress, setWorkOnProgress] = useState(null);
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 740));
    const [showProgressModal, setShowProgressModal] = useState(false);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { recommendedPromptsTitle } = localisationsAfterLogin;
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    useEffect(() => {
        const handleCheckMobile = () => {
            if (window.innerWidth <= 740) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleCheckMobile);

        return (() => window.addEventListener('resize', handleCheckMobile));
        // eslint-disable-next-line
    }, []);

    const fetchGoals = (pageSize, pageNumber) => {
        if (isClientPG) {
            getGoalsByCategoriesAction();
        } else {
            getGoalsAction(pageSize, pageNumber);
        }
    };

    const getWorkingOn = (initialRender = false) => {
        getWorkInProgressApi().then(response => {
            setWorkOnProgress(response.data.data);
        });
        getTodos(6, 1);
        fetchGoals(3, 1);
        if (!initialRender) {
            getUserMatrix();
            resetGoalTab();
        }
    };

    useEffect(() => {
        const handleVisibilityEvent = () => {
            if (document.visibilityState === 'visible' && dynamicCards.length > 0) {
                const url = AuthStore.baseUrl + Endpoints.getHomePage;
                webApiGet(AuthStore.accessToken, url).request.then(res => {
                    const newDynamicCards = res.data.data.dynamic_card;
                    const newIds = newDynamicCards.map(card => card.id);
                    const oldIds = dynamicCards.map(card => card.id);
                    if (JSON.stringify(newIds) !== JSON.stringify(oldIds)) {
                        getHomePage();
                    }
                });
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityEvent);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityEvent);
        };
    }, [dynamicCards, getHomePage]);

    useEffect(() => {
        getTags(history);
        getHomePage();
        getTodos(6, 1);
        fetchGoals(3, 1);
        // eslint-disable-next-line
    }, [getHomePage, getTags, history, getTodos]);

    useEffect(() => {
        getWorkingOn(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (hideSections && !hideSections.includes('recommended')) {
            getRecommendedPrompts();
        }
        // eslint-disable-next-line
    }, [getRecommendedPrompts, JSON.stringify(hideSections)])

    useEffect(() => {
        fetchGoals(3, 1);
        // eslint-disable-next-line
    }, [refreshGoals]);

    useEffect(() => {
        getTodos(6, 1);
    }, [refreshTodo, getTodos]);

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        appendDots: dots => <MagicSliderDots dots={dots} numDotsToShow={dynamicCards.length} dotWidth={30} />,
        slidesToShow: 1,
    };

    const closeCallback = cardId => {
        closeCard(cardId);
    };
    const updateNewCardStatus = async id => {
        try {
            await updateCardStatus(id);
            resetClientExpiredAction();
        } catch (e) {
            if (e.response.status === CODE_CLIENT_EXPIRED) {
                clientExpiredAction();
            } else {
                resetClientExpiredAction();
            }
        }
    };

    useEffect(() => {
        pageAnalytics.initialise('home');
        return () => {
            pageAnalytics.complete('home');
        };
    }, []);
    const handleOnSwipe = () => {
        // if (key && dynamicCards.length > 0) {
        //     updateCardStatus(dynamicCards[key].id);
        // }
    };

    const handleClose = () => {
        setShowCreatePost(false);
    };

    const handleCoachMarkClick = () => {
        setShowCoachMarks(true);
    };
    const handleClosePromptArticle = ({ show }) => {
        getTodos(5, 1);
        getUserMatrix();
        setShowPrompt({ show });
        resetGoalTab();
    };

    const handleCloseProgressModal = () => {
        getWorkingOn(true);
        setShowProgressModal(false);
    };

    const handleSeeMoreClick = () => {
        if (!isMobile) {
            history.push('/app/profile', {
                from: 'progress_snapshot_home',
            });
        } else {
            setShowProgressModal(true);
        }
    };

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };
    const GoalsComponent = isClientPG ? GoalsTabView : Goals;
    return (
        <div className="Home_container">
            {clientExpired && moveToClientExpirePage()}
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={articleId}
                    setShowCreatePost={setShowCreatePost}
                />
            )}
            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || { practice: [], category: [] }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
            {showProgressModal && (
                <WarningModal classNames="mobile-progress-modal" isFullScreen>
                    <div className="mobile-progress-snapshot-wrapper">
                        <ProgressSnapshot hasBackButton onBackClick={handleCloseProgressModal} shouldRetainData />
                    </div>
                </WarningModal>
            )}
            <div className="space-adjuster" />
            {showCoachMarks && <CoachMarks setShowCoachMarks={setShowCoachMarks} />}
            {isLoading && <Loader position="fixed" showBackground />}
            <div className="Home_innner_container">
                {dynamicCards.length > 0 && (
                    <div className="dynamic_cards_container">
                        <Slider {...settings} beforeChange={() => handleOnSwipe()}>
                            {dynamicCards.map(card => new DynamicCardMapper(card.card_type, () => closeCallback(card.id), {
                                key: card.title,
                                bgColor: card.bg_color,
                                title: card.title,
                                cardType: card.card_type,
                                body: card.body,
                                thumbnail: card.thumbnail,
                                textColor: card.text_color,
                                star_streak: card.star_streak,
                                competedPrompt: card.completed_promt,
                                ctaExternal: card.cta_external,
                                ctaInternal: card.cta_internal,
                                ctaType: card.cta_type,
                                id: card.id,
                                cta_text: card.cta_text,
                                thumbnailTop: card.thumbnail_top,
                                practiceId: card.practice_id,
                                articleId: card.article_id,
                                updateNewCardStatus,
                                getHomePage,
                                setShowCreatePost,
                                showRemoveIcon: true,
                                is_hide_close_button: card.is_hide_close_button,
                            }).getCard())}
                        </Slider>
                    </div>
                )}

                {recommendedPrompts.length > 0 && hideSections && !hideSections.includes('recommended') && (
                    <div className="recommended_prompts_container">
                        <Text
                            fontsize="20px"
                            fontcolor={fontColors.black}
                            fontfamily={fontFamilies.playfairBold}
                            fontweight="bold"
                            lineHeight="inherit"
                            letterSpacing="inherit"
                            margin="0 0 0.5rem 0"
                        >
                            {recommendedPromptsTitle}
                            <img
                                src={coachMark}
                                alt=""
                                style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                                onClick={handleCoachMarkClick}
                            />
                        </Text>
                        <div className="cards">
                            {recommendedPrompts.map((card, index) => (
                                <RecommendedPromptCard
                                    key={card.id}
                                    index={index}
                                    id={card.id}
                                    name={card.practice_name}
                                    thumbnail={card.file}
                                    description={card.title}
                                    practice_id={card.practice_id}
                                    getRecommendedPrompts={getRecommendedPrompts}
                                    getTodos={getTodos}
                                    uploadPost={uploadPost}
                                    tags={tags}
                                    uploadingPost={uploadingPost}
                                    showCreatePost={showCreatePost}
                                    setShowCreatePost={setShowCreatePost}
                                    history={history}
                                    is_featured={card.is_featured}
                                    getUserMatrix={getUserMatrix}
                                    resetGoalTab={resetGoalTab}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {recommendedPrompts.length === 0
                    && firstTimeDataLoaded
                    && hideSections
                    && !hideSections.includes('recommended') && (
                        <div className="recommended_prompts_container 2">
                            <Text
                                fontsize="20px"
                                fontcolor={fontColors.black}
                                fontfamily={fontFamilies.playfairBold}
                                fontweight="bold"
                                margin="0 0 0.5rem 0"
                                lineHeight="inherit"
                                letterSpacing="inherit"
                            >
                                {recommendedPromptsTitle}
                                <img
                                    src={coachMark}
                                    alt=""
                                    style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                                    onClick={handleCoachMarkClick}
                                />
                            </Text>
                            <CompletedRpCard />
                        </div>
                )}
                {hideSections && !hideSections.includes('working_on') && workOnProgress && workOnProgress.show_banner && (
                    <WorkOnProgress data={workOnProgress} callback={getWorkingOn} />
                )}
                {hideSections && !hideSections.includes('user_matrix') && <UserMatrix handleSeeMoreClick={handleSeeMoreClick} />}
            </div>
            <div className={`todos_goals_container${!isClientPG ? ' items-reverse' : ''}`}>
                {hideSections && !hideSections.includes('goal') ? (
                    <GoalsComponent hideAddGoalBtn={hideAddGoalBtn} />
                ) : null}
                <Todos />
            </div>
        </div>
    );
}

HomeComponent.defaultProps = {
    tags: {},
    hideAddGoalBtn: false,
    hideSections: null,
};

HomeComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getHomePage: PropTypes.func.isRequired,
    getRecommendedPrompts: PropTypes.func.isRequired,
    getTodos: PropTypes.func.isRequired,
    dynamicCards: PropTypes.arrayOf(
        PropTypes.shape({
            article_id: PropTypes.number,
            bg_color: PropTypes.string,
            text_color: PropTypes.string,
            body: PropTypes.string,
            card_type: PropTypes.number,
            cta_external: PropTypes.string,
            cta_internal: PropTypes.number,
            cta_type: PropTypes.number,
            id: PropTypes.number,
            metrics: PropTypes.string,
            practice_id: PropTypes.number,
            thumbnail: PropTypes.string,
            title: PropTypes.string,
            completed_promt: PropTypes.number,
        }),
    ).isRequired,
    recommendedPrompts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            orientation_promt_id: PropTypes.number,
            name: PropTypes.string,
            thumbnail: PropTypes.string,
            description: PropTypes.string,
            bg_color: PropTypes.string,
        }),
    ).isRequired,
    todos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            practice_name: PropTypes.string,
            article_title: PropTypes.string,
        }),
    ).isRequired,
    closeCard: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    getTags: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    uploadPost: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    firstTimeDataLoaded: PropTypes.bool.isRequired,
    hideSections: PropTypes.array,
    hideAddGoalBtn: PropTypes.bool,
    refreshGoals: PropTypes.number.isRequired,
    clientExpired: PropTypes.bool.isRequired,
    clientExpiredAction: PropTypes.func.isRequired,
    resetClientExpiredAction: PropTypes.func.isRequired,
    getGoalsAction: PropTypes.func.isRequired,
    getGoalsByCategoriesAction: PropTypes.func.isRequired,
    refreshTodo: PropTypes.number.isRequired,
    getUserMatrix: PropTypes.func.isRequired,
    resetGoalTab: PropTypes.func.isRequired,
};
