import React from 'react';
import PropTypes from 'prop-types';
import cretePostCross from '../../assets/cretePostCross.svg';
import './index.scss';
import Loader from '../../assets/blobAnimation.gif';
import Actionbutton from '../ActionButton';
import WarningModal from '../WarningModal';
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg';
import { fontColors, fontFamilies } from '../../common/fontMap';

const ModalCard = ({
    title,
    actionButton,
    children,
    className = '',
    closeModal,
    actionCallBack,
    dataProps,
    actionHappening,
    isMobile,
    description,
}) => (
    <WarningModal classNames="remindar-warning-modal">
        <section className={`modal-card ${className}`}>
            {isMobile ? (
                <div className="top-bar">
                    <div className="left">
                        <div className="close" onClick={() => { closeModal(); }}>
                            <img src={cretePostCross} alt="cross" />
                        </div>
                        <div className="title">{title}</div>
                    </div>
                    <div className="action-button" onClick={e => { actionCallBack(dataProps, e); }}>{actionButton}</div>
                </div>
            ) : (
                <>
                    <div
                        onClick={() => { closeModal(); }}
                        role="button"
                        tabIndex={0}
                        style={{ cursor: 'pointer' }}
                        className="desktop-cross"
                    >
                        <Cross color={fontColors.textBlack} />
                    </div>
                    <div className="desktop-top-bar">
                        <div className="title">{title}</div>
                        <div className="subtitle">{description}</div>
                    </div>
                </>
            )}
            <div className="modal-content">
                {children}
                {(actionHappening && isMobile) && <img src={Loader} alt="Loading" className="loader" />}
                {!isMobile && (
                <div className="desktop-action-button">
                    <Actionbutton
                        fontSize="1.125rem"
                        fontFamily={fontFamilies.TheinhardtPanRegular}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        disabled={false}
                        btnLoading={actionHappening}
                        width="270px"
                        border={`1px solid ${fontColors.electricBlue}`}
                        onClick={e => { actionCallBack(dataProps, e); }}
                        margin="30px 0 0"
                    >
                        {actionButton}
                    </Actionbutton>
                </div>
                )}
            </div>
        </section>
    </WarningModal>
);

export default ModalCard;

ModalCard.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    actionButton: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    dataProps: PropTypes.object.isRequired,
    actionCallBack: PropTypes.func.isRequired,
    actionHappening: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
};
