import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const RadioBtn = ({ question, setTimeQuestionAns }) => {
    const [selected, setSelected] = useState(false);

    const setValue = value => {
        setSelected(value);
        setTimeQuestionAns(value);
    };
    const buttonStyle = text => {
        if (text.includes('(')) {
            const resp = text.split('(');
            const secWord = resp[1].replace(/[()]/g, '');
            return [<div>{resp[0]}</div>, <div className="timeSlot">{secWord}</div>];
        }
        return <div className="onlyTime">{text}</div>;
    };
    return (
        <Grid container className="btn-group">
            {question.question_detail.options.map(option => (
                <div
                    className={`button ${selected === option.value && 'selected'}`}
                    onClick={() => setValue(option.value)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                >
                    {buttonStyle(option.text)}
                </div>
            ))}
        </Grid>
    );
};

RadioBtn.propTypes = {
    setTimeQuestionAns: PropTypes.func.isRequired,
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        sequence: PropTypes.number.isRequired,
        question: PropTypes.number.isRequired,
        question_detail: PropTypes.object.isRequired,
        response: PropTypes.object.isRequired,
        roadblock: PropTypes.object.isRequired,
    }).isRequired,
};

export default RadioBtn;
