import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Feeds from './Feeds';
import feedsReducer from './redux/reducer';
import FeedsSaga from './redux/sagas';
import {
    uploadPost,
    getTags,
    getFeedsList,
    getFeedsListMore,
    toggleCreatePostModal,
    postLike,
    deletePost,
    getSavedPosts,
    postSavedPosts,
    getLikeDetails,
    postFollowUser,
    getAllComments,
    postComment,
    likeComment,
    deleteComment,
    reportPost,
    resetAllFeedFetched,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    fetchUsersList,
    sharePost,
    fetchMoreUsersList,
    removeBookMark,
    setThisFeed,
    bannerOpened,
    getTeamMembersList,
    storeTeamMembersList,
    getSequenceOfApis,
    getNewPosts,
    storeNewPostsCount,
    getCoachinJourneyData,
    removeIsNewTagFromLocalState,
    articleIndexStartTimeTracker,
    articleIndexEndTimeTracker,
    userScrollAnalytics,
    storeNumberOfNewPostsCount,
    storeNumberOfNewPostsCountSocialGroup,
    pageAnalytics,
} from './redux/actions';
import { logoutUser, getUserData } from '../UnAuthenticatedContent/actions';
import { getVcrbData, getNotes, addNotes } from '../Profile/actions';
import { getPreAdditionalQuestions } from '../PreAdditionalQuestion/redux/actions';
import { getUserProgressData } from '../MyProgress/redux/actions';
import { setFilterString, storeFeedFilters } from '../FeedFilters/redux/actions';
import { storeApiIndex } from '../../common/actions';
import { emptyNotificationList, resetPageNumber } from '../NotificationCenter/redux/actions';

const stateToProps = state => {
    const FeedsReducer = state.get('feedsReducer');
    const WeeklyQuestionsReducer = state.get('weeklyQuestionsReducer');
    const CoachingJourneyReducer = state.get('coachingJourney');
    const refReducer = state.get('RefreshReducer');
    return {
        isLoading: FeedsReducer.isLoading,
        isDeleting: FeedsReducer.isDeleting,
        feedsList: FeedsReducer.feedsList,
        pageIndex: FeedsReducer.pageIndex,
        allFeedFetched: FeedsReducer.allFeedFetched,
        isFeedLoading: FeedsReducer.isFeedLoading,
        tags: FeedsReducer.tags,
        uploadingPost: FeedsReducer.uploadingPost,
        imageUploaderModal: FeedsReducer.imageUploaderModal,
        isLiked: FeedsReducer.isLiked,
        likedId: FeedsReducer.likedId,
        isDeleted: FeedsReducer.isDeleted,
        savedPosts: FeedsReducer.savedPosts,
        savedPostId: FeedsReducer.savedPostId,
        followedId: FeedsReducer.followedId,
        likeDetails: FeedsReducer.likeDetails,
        isLikeLoading: FeedsReducer.isLikeLoading,
        articleComments: FeedsReducer.articleComments,
        followedUsers: FeedsReducer.followedUsers,
        vcrbDetails: state.get('profileReducer').vcrbDetails,
        notes: state.get('profileReducer').notes,
        userListForSharePost: FeedsReducer.userListForSharePost,
        allUsersFetched: FeedsReducer.allUsersFetched,
        isInitialFeedsLoading: FeedsReducer.isInitialFeedsLoading,
        chapterIntro: WeeklyQuestionsReducer.chapterIntro,
        earnedBannerBadge: FeedsReducer.earnedBannerBadge,
        teamMembersList: FeedsReducer.teamMembersList,
        filterString: FeedsReducer.filterString,
        newerPostsCount: FeedsReducer.newerPostsCount,
        badgeOfNewerPostsCount: FeedsReducer.badgeOfNewerPostsCount,
        badgeOfNewerPostsCountCoachingJourney: FeedsReducer.badgeOfNewerPostsCountCoachingJourney,
        badgeOfNewerPostsCountSocialGroup: FeedsReducer.badgeOfNewerPostsCountSocialGroup,
        coachingJourney: CoachingJourneyReducer.coachingJourneyChapter,
        clientExpired: refReducer.clientExpired,
    };
};

export { FeedsSaga, feedsReducer };

const dispatchToProps = {
    uploadPost,
    getTags,
    getFeedsList,
    getFeedsListMore,
    postLike,
    toggleCreatePostModal,
    deletePost,
    getSavedPosts,
    postSavedPosts,
    getLikeDetails,
    postFollowUser,
    getAllComments,
    postComment,
    likeComment,
    deleteComment,
    reportPost,
    resetAllFeedFetched,
    getPreAdditionalQuestions,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    getVcrbData,
    getNotes,
    addNotes,
    fetchUsersList,
    sharePost,
    fetchMoreUsersList,
    removeBookMark,
    setThisFeed,
    logoutUser,
    bannerOpened,
    getUserProgressData,
    getTeamMembersList,
    storeTeamMembersList,
    getUserData,
    setFilterString,
    storeFeedFilters,
    getSequenceOfApis,
    getNewPosts,
    storeNewPostsCount,
    storeApiIndex,
    getCoachinJourneyData,
    removeIsNewTagFromLocalState,
    articleIndexStartTimeTracker,
    articleIndexEndTimeTracker,
    userScrollAnalytics,
    emptyNotificationList,
    resetPageNumber,
    storeNumberOfNewPostsCount,
    storeNumberOfNewPostsCountSocialGroup,
    pageAnalytics,
};

export default withRouter(connect(stateToProps, dispatchToProps)(Feeds));
