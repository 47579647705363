import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Actionbutton from '../../components/ActionButton';
import { fontColors, fontFamilies } from '../../common/fontMap';
import LocalisationsStore from '../../common/LocalisationsStore';
import './TodoAction.scss';
import AntSwitch from '../../components/AntSwitch';
import { setNotificationReminder } from '../PracticesContainer/redux/apis';
import greenTick from '../../assets/greenTick.svg';
import time from '../../assets/time.svg';
import calendar from '../../assets/calendar.svg';
import toDo from '../../assets/to-do.svg';
import ModalCard from '../../components/ModalCard';
import TimePicker from '../../components/TimePicker';
import TimePickerDesktop from '../../components/TimePickerDesktop';
import StaticDatePickerDesktop from '../../components/StaticDatePickerDesktop';

const Element = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: flex-start;
`;

const TodoAction = ({ changePracticeStatus, practiceId, promptDetailsData, setNotificationActive, onClose }) => {
    const [reminder, setReminder] = useState(false);
    const [isTimeOpen, setIsTimeOpen] = useState(false);
    const [reminderToggleOn, setReminderToggleOn] = useState(false);
    const [_saveReminder, setSaveReminder] = useState(false);
    const current = new Date();
    const today = new Date(current.setDate(current.getDate() + 1)).toISOString().split('T')[0];
    const [reminderDate, setReminderDate] = useState(today);
    // eslint-disable-next-line no-unused-vars
    const [reminderTime, setReminderTime] = useState({
        hr: '12',
        min: '00',
        format: 'AM',
    });
    const { localisationsAfterLogin } = LocalisationsStore;
    const { doYouWantToBeReminded, remindMeOn, saveReminder, addedToTODoList, save, set_a_reminder, set_a_reminder_desc } = localisationsAfterLogin;
    const _setReminder = localisationsAfterLogin.setReminder;
    const [isReminderSet, setIsReminderSet] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [selectedDate, handleDateChange] = useState(
        moment()
            .add(1, 'days')
            .startOf('day')
            .format('yyyy-MM-DDTHH:mm:ss.SSS'),
    );
    const [remindedDate, setRemindedDate] = useState(
        moment()
            .add(1, 'days')
            .format('yyyy-MM-DDTHH:mm:ss.SSS'),
    );
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 740));

    useEffect(() => {
        const handleCheckMobile = () => {
            if (window.innerWidth <= 740) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleCheckMobile);

        return (() => window.addEventListener('resize', handleCheckMobile));
    }, []);

    useEffect(() => {
        if (promptDetailsData.is_notification_active) {
            setNotificationActive(true);
            const _date = new Date(promptDetailsData.notification_time.replace('Z', ''));
            // eslint-disable-next-line no-nested-ternary
            let _hr = '12';
            const hours = _date.getHours();
            if (hours > 12) {
                if (hours - 12 > 9) {
                    _hr = _date.getHours() - 12;
                } else {
                    _hr = `0${_date.getHours() - 12}`;
                }
            } else if (hours <= 9) {
                if (hours === 0) {
                    _hr = '12';
                } else {
                    _hr = `0${_date.getHours()}`;
                }
            } else {
                _hr = hours;
            }
            setReminderTime({
                hr: _hr,
                min: !_date.getMinutes() ? '00' : _date.getMinutes(),
                format: `${_date.getHours() >= 12 ? 'PM' : 'AM'}`,
            });

            setIsReminderSet(true);
            const current = new Date(_date);
            const _today = moment(current).format('yyyy-MM-DD');
            setReminderDate(_today);
            setReminder(promptDetailsData.is_notification_active);
            handleDateChange(promptDetailsData.notification_time.replace('Z', ''));
            setRemindedDate(promptDetailsData.notification_time.replace('Z', ''));
        } else {
            setNotificationActive(false);
            setReminderTime({
                hr: '12',
                min: '00',
                format: 'AM',
            });
            setIsReminderSet(false);
            setReminder(false);
        }
        // eslint-disable-next-line
    }, [promptDetailsData])

    const onSetReminder = (e, article_id, selectedDate) => {
        setSaveReminder(true);
        setNotificationReminder({
            article_id,
            is_notification_active: true,
            notification_time: selectedDate,
        }).then(() => {
            setNotificationActive(true);
            setSaveReminder(false);
            setReminderToggleOn(false);
            setIsReminderSet(true);
            setRemindedDate(selectedDate);
            onClose(e);
        });
    };

    const onSwitchClick = article_id => {
        if (!reminder) {
            setReminder(true);
            setReminderToggleOn(true);
        } else {
            setNotificationReminder({
                article_id,
                is_notification_active: false,
                notification_time: null,
            }).then(() => {
                setReminderTime({
                    hr: '12',
                    min: '00',
                    format: 'AM',
                });
                setReminderDate(today);
                setNotificationActive(false);
                setIsReminderSet(false);
                setReminder(false);
            });
        }
    };

    const getRemindedDate = () => {
        const _date = new Date(remindedDate);
        return `${_date.getDate()} ${_date.toLocaleString('default', {
            month: 'short',
        })}, ${_date.getFullYear()} ${reminderTime.hr}:${reminderTime.min} ${reminderTime.format}`;
    };

    const onChangeReminderDate = event => {
        setReminderDate(event.target.value);
    };

    const onChangeReminderTime = (event, value, type, fromDesktop = false) => {
        event.stopPropagation();
        if (type === 'hr' && fromDesktop) {
            setReminderTime({ ...reminderTime, [type]: value.split(':')[0], min: value.split(':')[1] });
        } else {
            setReminderTime({ ...reminderTime, [type]: value });
        }
    };

    const renderChildren = () => (
        <div className="reminder-dateTime">
            <div className="date">
                <span className="display-date">{moment(reminderDate).format('MM/DD/YYYY')}</span>
                <img src={calendar} alt="Set Date" className="calendar-icon" />
                <input
                    required
                    onKeyDown={e => e.preventDefault()}
                    onChange={event => {
                        onChangeReminderDate(event);
                    }}
                    className="date-field"
                    id="reminderDate"
                    name="reminderDate"
                    type="date"
                    defaultValue={reminderDate}
                    min={today}
                />
            </div>
            <div className="time">
                <img
                    src={time}
                    alt="Set Time"
                    className="time-icon"
                    onClick={() => {
                        setIsTimeOpen(!isTimeOpen);
                    }}
                />
                {isTimeOpen && (
                    <TimePicker
                        onChange={onChangeReminderTime}
                        time={reminderTime}
                        isTimeOpen={isTimeOpen}
                        setIsTimeOpen={setIsTimeOpen}
                    />
                )}
                {`${reminderTime.hr} : ${reminderTime.min} ${reminderTime.format} `}
            </div>
        </div>
    );

    const actionCallBack = (params, e) => {
        let _hr = reminderTime.hr;
        if (reminderTime.format === 'PM') {
            _hr = 12 + parseInt(_hr, 10);
            if (_hr === 24) {
                _hr = '12';
            }
        } else if (parseInt(_hr, 10) === 12) {
            _hr = '00';
        }
        onSetReminder(e, promptDetailsData.id, `${params.reminderDate}T${_hr}:${reminderTime.min}:00`);
    };

    const closeReminderModal = () => {
        setReminderToggleOn(false);
        if (!isReminderSet) {
            setReminder(false);
        }
    };

    return (
        <div className="action_wrapper">
            {!isReminderSet && (
                <div className="added-to-do">
                    <div className="to-do-icon">
                        <img src={toDo} alt="Added to To Do" />
                    </div>
                    <div>{addedToTODoList}</div>
                </div>
            )}
            <div className="reminder">
                <div className="reminder__title">
                    {isReminderSet ? (
                        <span style={{ display: 'flex' }}>
                            <img style={{ marginRight: '6px' }} src={greenTick} alt="Green Tick" />
                            <span>
                                {remindMeOn}
                                &nbsp;
                            </span>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#1f40e6',
                                    borderBottom: '1px solid #1f40e6',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setReminderToggleOn(true);
                                }}
                            >
                                {getRemindedDate()}
                            </span>
                        </span>
                    ) : (
                        doYouWantToBeReminded
                    )}
                </div>
                <div className="reminder__toggle">
                    <AntSwitch
                        checked={reminder}
                        name="checkedC"
                        onChange={() => {
                            onSwitchClick(promptDetailsData.id);
                        }}
                    />
                </div>
                {reminderToggleOn && (
                    <ModalCard
                        className={isMobile ? 'reminder-modal' : 'reminder-modal-desktop'}
                        closeModal={closeReminderModal}
                        actionCallBack={actionCallBack}
                        actionHappening={_saveReminder}
                        title={isMobile ? _setReminder : (set_a_reminder || _setReminder)}
                        description={set_a_reminder_desc}
                        actionButton={isMobile ? saveReminder : save}
                        dataProps={{
                            reminderDate,
                        }}
                        isMobile={isMobile}
                    >
                        {isMobile ? renderChildren() : (
                            <div className="reminder-dateTime">
                                <StaticDatePickerDesktop
                                    selectedDate={reminderDate}
                                    onChangeReminderDate={onChangeReminderDate}
                                />
                                <TimePickerDesktop
                                    onChange={onChangeReminderTime}
                                    time={reminderTime}
                                    isTimeOpen={isTimeOpen}
                                    setIsTimeOpen={setIsTimeOpen}
                                />
                            </div>
                        )}
                    </ModalCard>
                )}
            </div>

            <Element>
                <Actionbutton
                    fontSize="1.125rem"
                    fontFamily={fontFamilies.TheinhardtPanRegular}
                    fontColor={fontColors.siteWhite}
                    bgColor={fontColors.electricBlue}
                    disabled={false}
                    onClick={() => changePracticeStatus(practiceId, 'complete')}
                    width="20rem"
                >
                    {promptDetailsData.article_buttons.find(elem => elem.key === 'complete').text}
                </Actionbutton>
            </Element>
            <Element>
                <Actionbutton
                    fontSize="1.125rem"
                    fontFamily={fontFamilies.TheinhardtPanLight}
                    fontColor={fontColors.electricBlue}
                    bgColor={fontColors.siteWhite}
                    disabled={false}
                    onClick={event => {
                        if (promptDetailsData.hit_remove_todo_api) {
                            changePracticeStatus(practiceId, 'remove_todo', event);
                        } else {
                            onClose(event);
                        }
                    }}
                    width="20rem"
                    border={`1px solid ${fontColors.electricBlue}`}
                >
                    {promptDetailsData.article_buttons.find(elem => elem.key === 'remove_todo').text}
                </Actionbutton>
            </Element>
        </div>
    );
};

TodoAction.propTypes = {
    practiceId: PropTypes.number.isRequired,
    setNotificationActive: PropTypes.func.isRequired,
    changePracticeStatus: PropTypes.func.isRequired,
    promptDetailsData: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default TodoAction;
