import styled from 'styled-components';

const PreviewProfileImage = styled.img`
    background-size: auto;
    background-repeat: no-repeat;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    clip-path: url(${props => props.id});
    opacity: ${props => props.opacity || 1};
    margin-left: ${props => props.margin || 0};
`;

export default PreviewProfileImage;
