import React from 'react';
import Box from '@material-ui/core/Box';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';

const Footer = () => {
    const { localisationsBeforeLogin } = LocalisationsStore;
    const { privacyPolicylink, taclink, aboutUs, privacyCaps, termsCaps } = localisationsBeforeLogin;

    return (
        <Box display="flex" className="footer-links" justifyContent="center" width="100%" marginTop="20px">
            <span className="splash_links">{aboutUs}</span>
            <span className="splash_links">&bull;</span>
            <span className="splash_links">
                <a href={privacyPolicylink} target="_blank" rel="noopener noreferrer">
                    {privacyCaps}
                </a>
            </span>
            <span className="splash_links">&bull;</span>
            <span className="splash_links">
                <a href={taclink} target="_blank" rel="noopener noreferrer">
                    {termsCaps}
                </a>
            </span>
        </Box>
    );
};

export default Footer;
