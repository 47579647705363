import React from 'react';
import Grid from '@material-ui/core/Grid';
import ThanksImage from './assets/Thankyou.png';
import LocalisationsStore from '../../common/LocalisationsStore';

const Thankyou = () => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { EXPLORE_FEEDS_NOW, THANKYOU_TEXT } = localisationsAfterLogin;
    return (
        <div className="thanksWrapper">
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <img src={ThanksImage} alt="thanksImage" width={214} height={138} />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} className="thankyou">
                    <div className="upperText">{THANKYOU_TEXT}</div>
                    <div className="bottomText">{EXPLORE_FEEDS_NOW}</div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Thankyou;
