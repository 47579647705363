import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import WarningModal from '../WarningModal';
import { Cross2 } from '../../containers/UnAuthenticatedContent/assets/svg';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import { Badge } from '../../containers/Feeds/assets/svg';
import Actionbutton from '../ActionButton';
import CtaArrow from '../CtaArrow';
import LocalisationsStore from '../../common/LocalisationsStore';

const BadgeModal = ({ closeBadgeModal, earnedBannerBadge }) => {
    const badgeDescription = earnedBannerBadge.badge.content.split('##');

    const { localisationsAfterLogin } = LocalisationsStore;
    const { done, seeAllAchievements } = localisationsAfterLogin;

    return (
        <WarningModal>
            <div className="badge_wrapper">
                <div
                    onClick={() => {
                        closeBadgeModal();
                    }}
                    role="button"
                    tabIndex={0}
                    className="cross outline-none pointer"
                >
                    <Cross2 color={fontColors.textBlack} />
                </div>
                <Text
                    fontfamily={fontFamilies.TheinhardtPanRegular}
                    fontcolor={fontColors.textBlack}
                    fontsize="14px"
                    margin="3.2rem 0 0.5rem 0"
                    fontWeight="500"
                >
                    {earnedBannerBadge.badge.header}
                </Text>
                <Badge
                    badgeNumber={earnedBannerBadge.badge.social_post_count}
                    badgeName={earnedBannerBadge.badge.badge_name.toUpperCase()}
                />
                {badgeDescription.map((text, index) => (
                    <Text
                        fontsize="18px"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanLight}
                        margin={index === badgeDescription.length - 1 ? '0 0 2.8rem 0' : ''}
                    >
                        {text}
                    </Text>
                ))}
                <Actionbutton
                    fontSize="18px"
                    fontFamily={fontFamilies.TheinhardtPanLight}
                    fontColor={fontColors.siteWhite}
                    bgColor={fontColors.electricBlue}
                    disabled={false}
                    onClick={() => {
                        closeBadgeModal();
                    }}
                    width="14rem"
                    padding="10px 0"
                >
                    {done}
                </Actionbutton>
                <div
                    className="seeAchievements"
                    onClick={() => {
                        closeBadgeModal(true);
                    }}
                >
                    <Text
                        fontsize="16px"
                        fontWeight="500"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanRegular}
                        cursor="pointer"
                        letterSpacing="0.5px"
                        lineHeight="22px"
                    >
                        {seeAllAchievements}
                    </Text>
                    <CtaArrow color={fontColors.textBlack} showBold />
                </div>
            </div>
        </WarningModal>
    );
};

BadgeModal.propTypes = {
    closeBadgeModal: PropTypes.func.isRequired,
    earnedBannerBadge: PropTypes.object.isRequired,
};

export default BadgeModal;
