/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    file: null,
    loading: false,
    tags: null,
    isLoading: false,
    feedsList: [],
    allFeedFetched: false,
    isFeedLoading: false,
    isLiked: false,
    uploadingPost: false,
    imageUploaderModal: false,
    thisFeedData: {},
    likedId: -1,
    isDeleted: false,
    savedPosts: [],
    savedPostId: -1,
    likeDetails: [],
    followedId: -1,
    isLikeLoading: false,
    userListOfType: [],
    articleComments: [],
    isDeleting: false,
    postQuizSuccess: false,
    showShareSuccess: false,
    followedUsers: [],
    userListForSharePost: [],
    allUsersFetched: false,
    isInitialFeedsLoading: false,
};

const feedsReducer = createReducer(INITIAL_STATE, {
    [types.GET_FEEDS_PENDING]: state => ({
        ...state,
        isInitialFeedsLoading: true,
    }),

    [types.GET_FEEDS_SUCCESS]: (state, { payload }) => ({
        ...state,
        feedsList: payload,
        isInitialFeedsLoading: false,
    }),
    [types.GET_FEEDS_FAILED]: state => ({
        ...state,
        isInitialFeedsLoading: false,
    }),
    [types.GET_FEEDS_MORE_PENDING]: state => ({
        ...state,
        isFeedLoading: true,
        allFeedFetched: false,
    }),
    [types.GET_FEEDS_MORE_SUCCESS]: (state, { payload }) => ({
        ...state,
        feedsList: [...state.feedsList, ...payload],
        isFeedLoading: false,
        allFeedFetched: false,
    }),
    [types.GET_FEEDS_MORE_FAILED]: state => ({
        ...state,
        isFeedLoading: false,
        allFeedFetched: true,
    }),
    [types.ADD_FILE_TO_LIST]: (state, { file }) => ({
        ...state,
        file,
    }),
    [`${types.GET_TAGS}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_TAGS}_DONE`]: (state, { tags }) => ({
        ...state,
        loading: false,
        tags,
    }),

    [types.POST_LIKE_PENDING]: state => ({
        ...state,
        isLoading: true,
        isLiked: false,
    }),

    [types.POST_LIKE_SUCCESS]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLiked: true,
        likedId: payload.payload.articleId,
    }),
    [types.POST_LIKE_FAILURE]: state => ({
        ...state,
        isLoading: false,
        isLiked: false,
    }),
    [`${types.UPLOAD_POST}_PENDING`]: state => ({
        ...state,
        uploadingPost: true,
    }),
    [`${types.UPLOAD_POST}_DONE`]: state => ({
        ...state,
        uploadingPost: false,
    }),
    [types.TOGGLE_CREATE_POST_MODAL]: (state, { value }) => ({
        ...state,
        imageUploaderModal: value,
    }),
    [`${types.GET_THIS_FEED}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_THIS_FEED}_DONE`]: (state, { data }) => ({
        ...state,
        thisFeedData: data,
        isLoading: false,
    }),
    [types.DELETE_POST_PENDING]: state => ({
        ...state,
        isDeleting: true,
        isDeleted: false,
    }),

    [types.DELETE_POST_SUCCESS]: (state, payload) => ({
        ...state,
        isDeleting: false,
        isDeleted: true,
        feedsList: state.feedsList.filter(item => item.id !== payload.payload.articleId),
    }),
    [types.DELETE_POST_FAILURE]: state => ({
        ...state,
        isDeleting: false,
        isDeleted: false,
    }),
    [types.GET_SAVED_POSTS_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),

    [types.GET_SAVED_POSTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        savedPosts: payload.data,
        isLoading: false,
    }),
    [types.GET_SAVED_POSTS_FAILED]: state => ({
        ...state,
        isLoading: false,
    }),

    [types.POST_SAVED_POSTS_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),

    [types.POST_SAVED_POSTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        savedPostId: payload.articleId,
        savedPosts: [payload.data.data[0], ...state.savedPosts],
    }),
    [types.POST_SAVED_POSTS_FAILURE]: state => ({
        ...state,
        isLoading: false,
    }),

    [types.GET_LIKE_DETAILS_PENDING]: state => ({
        ...state,
        isLoading: true,
        isLikeLoading: true,
    }),

    [types.GET_LIKE_DETAILS_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        likeDetails: payload.data,
        isLikeLoading: false,
    }),
    [types.GET_LIKE_DETAILS_FAILURE]: state => ({
        ...state,
        isLoading: false,
        isLikeLoading: false,
    }),

    [types.POST_FOLLOW_USER_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),

    [types.POST_FOLLOW_USER_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        followedId: payload.id,
    }),
    [types.POST_FOLLOW_USER_FAILURE]: state => ({
        ...state,
        isLoading: false,
    }),
    [`${types.GET_USERS_OF_TYPE}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_USERS_OF_TYPE}_DONE`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        userListOfType: data.data,
    }),
    [`${types.GET_ALL_COMMENTS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        // feedsList: state.feedList.map(item => {
        //     item.showComments = true;
        //     return item;
        // }),
    }),
    [`${types.GET_ALL_COMMENTS}_DONE`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        articleComments: data,
        feedsList: state.feedsList.map(item => {
            item.showComments = true;
            return item;
        }),
    }),
    [`${types.POST_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.POST_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.LIKE_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.DELETE_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.LIKE_COMMENT}_DONE`]: state => ({
        ...state,
        isLoading: false,
    }),
    [types.UPDATE_FEEDS]: (state, { articleId, operation, replycount }) => ({
        ...state,
        feedsList: state.feedsList.map(item => {
            if (item.id === articleId) {
                switch (operation) {
                    case '+':
                        item.comment_count += 1;
                        break;
                    case '-': {
                        if (replycount > 0) item.comment_count = item.comment_count - 1 - replycount;
                        else item.comment_count -= 1;
                        break;
                    }
                    default:
                        break;
                }
                item.showComments = true;
            }
            return item;
        }),
    }),
    [`${types.POST_QUIZ_ANSWER}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        postQuizSuccess: false,
        showShareSuccess: false,
    }),
    [`${types.POST_QUIZ_ANSWER}_SUCCESS`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        postQuizSuccess: true,
        showShareSuccess: data.status === 201,
    }),
    [types.RESET_QUIZ_DATA]: state => ({
        ...state,
        isLoading: false,
        postQuizSuccess: false,
        showShareSuccess: false,
    }),
    [`${types.REPORT_POST}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        reportPostSuccess: false,
    }),
    [`${types.REPORT_POST}_SUCCESS`]: state => ({
        ...state,
        isLoading: false,
        reportPostSuccess: true,
    }),
    [`${types.REPORT_POST}_FAILED`]: state => ({
        ...state,
        isLoading: false,
        reportPostSuccess: false,
    }),
    [types.RESET_ALL_FEED_FETCHED]: state => ({
        ...state,
        allFeedFetched: false,
    }),
    [`${types.GET_USERS_FOLLOWED}_SUCCESS`]: (state, { data }) => ({
        ...state,
        followedUsers: data.data,
    }),
    [types.SET_FOLLOW_UNFOLLOW_FEEDS]: (state, { userId, isFollow }) => {
        const newFeeds = state.feedsList.map(feedItem => {
            if (feedItem.user === userId) {
                feedItem.is_following = isFollow;
            }
            return feedItem;
        });
        return {
            ...state,
            feedsList: newFeeds,
        };
    },
    [`${types.FETCH_USERS_LIST}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        userListForSharePost: [],
        allUsersFetched: false,
    }),
    [`${types.FETCH_USERS_LIST}_SUCCESS`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        userListForSharePost: data.data,
    }),
    [`${types.FETCH_USERS_LIST}_FAILED`]: state => ({
        ...state,
        isLoading: false,
    }),
    [`${types.FETCH_MORE_USERS_LIST}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.FETCH_MORE_USERS_LIST}_SUCCESS`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        userListForSharePost: [...state.userListForSharePost, ...data.data],
        allUsersFetched: data.data.length === 0,
    }),
    [`${types.FETCH_MORE_USERS_LIST}_FAILED`]: state => ({
        ...state,
        isLoading: false,
        allUsersFetched: true,
    }),
});

export default feedsReducer;
