import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, ThemeProvider, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import rArrow from '../../assets/rArrow.svg';
import lArrow from '../../assets/lArrow.svg';
import './index.scss';

const materialTheme = createTheme({
    palette: {
        primary: {
            main: '#2251FF',
        },
    },
});

function StaticDatePickerDesktop({ selectedDate, onChangeReminderDate }) {
    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0);
    const [showYearPicker, setShowYearPicker] = useState(false);
    const [showMonthPicker, setShowMonthPicker] = useState(false);
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    useEffect(() => {
        const element = document.getElementsByClassName('MuiPickersCalendarHeader-transitionContainer')?.[0];
        if (element) {
            const handleClick = () => {
                setShowYearPicker(true);
            };
            element.addEventListener('click', handleClick);

            return (() => element.removeEventListener('click', handleClick));
        }
        return (() => {});
    }, []);

    const handleDateChange = value => {
        onChangeReminderDate({ target: { value: moment(value).format('yyyy-MM-DD') } });
    };

    return (
        <div className={`desktop-date-picker${isClientPG ? ' pg-desktop-picker' : ''}`}>
            <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <>
                        <DatePicker
                            views={['year', 'month', 'date']}
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            variant="static"
                            minDate={minDate}
                            disableToolbar
                            rightArrowIcon={<img src={rArrow} alt="Right Arrow" />}
                            leftArrowIcon={<img src={lArrow} alt="Left Arrow" />}
                        />
                        {showYearPicker && (
                        <div className="year-picker">
                            <DatePicker
                                views={['year']}
                                minDate={minDate}
                                variant="static"
                                value={selectedDate}
                                onChange={handleDateChange}
                                onAccept={() => {
                                    setShowYearPicker(false);
                                    setShowMonthPicker(true);
                                }}
                                orientation="portrait"
                            />
                        </div>
                        )}
                        {showMonthPicker && (
                        <div className="month-picker">
                            <DatePicker
                                views={['month']}
                                minDate={minDate}
                                variant="static"
                                value={selectedDate}
                                onChange={handleDateChange}
                                onAccept={() => setShowMonthPicker(false)}
                                ToolbarComponent={({ date }) => (
                                    <div className="month-toolbar">
                                        <Button className="month-toolbar-heading">
                                            {moment(date).format('MMMM')}
                                        </Button>
                                    </div>
                                )}
                                orientation="portrait"
                            />
                        </div>
                        )}
                    </>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </div>
    );
}

export default StaticDatePickerDesktop;

StaticDatePickerDesktop.propTypes = {
    selectedDate: PropTypes.string.isRequired,
    onChangeReminderDate: PropTypes.func.isRequired,
};
