import types from './types';

export const postMailAnswer = ({ baseurl, id, response, email }) => ({
    type: `${types.POST_MAIL_ANSWER}_PENDING`,
    baseurl,
    id,
    response,
    email,
});

export const storeAnswerResponse = data => ({
    type: `${types.POST_MAIL_ANSWER}_DONE`,
    data,
});
