import React, { useEffect } from 'react';
import moment from 'moment';
import TextBox from '../TextBox';
import MultiSelectQues from '../MultiSelectQues';
import './index.scss';
import DropDownQues from '../DropDownQues';


const PromptQuestion = ({ promptQuestions, callBack, callBackData, freeze, updateParent }) => {
    const getResponse = data => {
        const _obj = {};
        const today = new Date();
        const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0);
        (data || []).forEach(elem => {
            if (elem.type === 'text_area' && elem.is_editable) {
                const { response, id, show_due_date_field, due_date } = elem;

                const formattedObject = {
                    response,
                    question_id: id,
                };
                if (show_due_date_field) {
                    const due_date_obj = due_date || moment(minDate).format('yyyy-MM-DD HH:mm:ss.SSS');
                    formattedObject.due_date = due_date_obj;
                }
                _obj[id] = formattedObject;
            } else if (elem.type === 'text_area' && !elem.is_editable) {
                const { response, id, show_due_date_field, due_date } = elem;

                const formattedObject = {
                    response,
                    question_id: id,
                };
                if (show_due_date_field) {
                    const due_date_obj = due_date || moment(minDate).format('yyyy-MM-DD HH:mm:ss.SSS');
                    formattedObject.due_date = due_date_obj;
                }
                _obj[id] = formattedObject;
            }
        });
        return _obj;
    };

    useEffect(() => {
        if (!freeze) {
            const _obj = {
                ...callBackData,
                question_response: {
                    ...callBackData.question_response,
                    ...getResponse(promptQuestions),
                },
            };
            callBack(_obj);
            updateParent(_obj);
        }
        // eslint-disable-next-line
    }, [promptQuestions])
    return promptQuestions.map(elem => {
        switch (elem.type) {
            case 'text_area':
                return <TextBox key={elem?.id} elem={elem} callBack={callBack} callBackData={callBackData} freeze={freeze} />;
            case 'multiple_choice_question':
                return <MultiSelectQues key={elem?.id} elem={elem} callBack={callBack} callBackData={callBackData} freeze={freeze} />;
            case 'dropdown':
                return <DropDownQues key={elem?.id} elem={elem} callBack={callBack} callBackData={callBackData} freeze={freeze} />;
            default:
                return null;
        }
    });
};

export default PromptQuestion;
