import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    data: null,
    isLoading: false,
};

const mailerReducer = createReducer(INITIAL_STATE, {
    [`${types.POST_MAIL_ANSWER}_DONE`]: (state, { data }) => ({
        ...state,
        data,
        isLoading: false,
    }),
    [`${types.POST_MAIL_ANSWER}_PENDING`]: state => ({
        ...state,
        data: true,
        isLoading: true,
    }),
});

export default mailerReducer;
