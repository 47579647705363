import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import styled from 'styled-components';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import TextWithTags from '../../../../components/TextWithTags';

const MultiSelectQues = ({
    elem,
    callBack,
    callBackData,
    freeze,
}) => {
    const {
        id,
        question_text,
        options,
        max_selection,
        required,
        header,
        footer,
        config,
    } = elem;
    const [mcqOptions, setMcqOptions] = useState([]);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { quiz_mandatory } = localisationsAfterLogin;
    const [selectedOptions, setSelectedOptions] = useState([]);
    const promptLayout = localStorage.getItem('prompt_multiple_select_question_layout');

    const QuesTitle = styled.div`
        font-size: ${`${config.font_size}px` || '16px'};
        color: ${config.color};
        text-transform: ${config.all_caps ? 'uppercase' : ''};
        font-family: ${config.font_family};
        font-weight: ${config.font_weight};
        margin-bottom:15px;
        white-space: pre-wrap;
    `;

    useEffect(() => {
        const responseIdList = elem.response ? elem.response.split(',') : [];
        setSelectedOptions(responseIdList.map(str => Number(str)));
    }, [elem]);

    useEffect(() => {
        setMcqOptions(JSON.parse(JSON.stringify(options)));
    }, [options]);

    const clickOption = (item, index) => {
        if (max_selection === 1) {
            setSelectedOptions([item.id]);
            const newOptions = mcqOptions.map(elem => {
                if (elem.id === item.id) {
                    return {
                        ...elem,
                        isSelected: true,
                    };
                }
                return {
                    ...elem,
                    isSelected: false,
                };
            });
            setMcqOptions(newOptions);
            callBack({
                ...callBackData,
                question_response: {
                    ...callBackData.question_response,
                    [id]: {
                        response: `${item.id}`,
                        question_id: id,
                    },
                },
            });
        } else {
            const newOptions = [...mcqOptions];
            if (selectedOptions.includes(item.id)) {
                newOptions[index].isSelected = false;
                // remove that id
                const newList = selectedOptions.filter(elem => elem !== item.id);
                setSelectedOptions(newList);
            } else {
                if (max_selection === selectedOptions.length) return;
                newOptions[index].isSelected = true;
                setSelectedOptions([...selectedOptions, item.id]);
            }
            const response = newOptions
                .filter(elem => elem.isSelected)
                .map(item => item.id);
            callBack({
                ...callBackData,
                question_response: {
                    ...callBackData.question_response,
                    [id]: {
                        response: response.join(','),
                        question_id: id,
                    },
                },
            });
            setMcqOptions(newOptions);
        }
    };

    return (
        <div className="mcq">
            {header && (
                <div className="header-text">
                    <TextWithTags text={header.text} config={header.config} />
                </div>
            )}
            <QuesTitle>{question_text}</QuesTitle>
            <div className={`option-wrapper ${promptLayout === 'grid' ? 'layout-grid' : 'layout-list'}${freeze ? ' freeze' : ''}`}>
                {mcqOptions.map((item, index) => (
                    <button
                        className={`option ${selectedOptions.includes(item.id) ? 'active' : ''}`}
                        onClick={() => clickOption(item, index)}
                        key={item?.id}
                        type="button"
                    >
                        {item.title}
                    </button>
                ))}
            </div>
            {required && <div className="mandatory">{quiz_mandatory}</div>}
            {footer && (
            <div className="header-text">
                <TextWithTags text={footer.text} config={footer.config} />
            </div>
            )}
        </div>
    );
};

export default MultiSelectQues;

MultiSelectQues.propTypes = {
    elem: PropTypes.shape({
        id: PropTypes.number,
        question_text: PropTypes.string,
        options: PropTypes.array,
        response: PropTypes.string,
        max_selection: PropTypes.number,
        required: PropTypes.bool,
        isSelected: PropTypes.bool,
        header: PropTypes.shape({
            text: PropTypes.string,
            config: PropTypes.shape({}),
        }),
        footer: PropTypes.shape({
            text: PropTypes.string,
            config: PropTypes.shape({}),
        }),
        config: PropTypes.shape({
            font_size: PropTypes.number,
            color: PropTypes.string,
            all_caps: PropTypes.bool,
            font_family: PropTypes.string,
            font_weight: PropTypes.string,
        }),
    }).isRequired,
    callBackData: PropTypes.shape({
        question_response: PropTypes.shape({}),
    }).isRequired,
    callBack: PropTypes.func.isRequired,
    freeze: PropTypes.bool.isRequired,
};
