const fontFamilies = {
    TheinhardtPanRegular: 'Theinhardt-Medium',
    TheinhardtPanMedium: 'Theinhardt-Bold',
    TheinhardtPanLight: 'Theinhardt-Regular',
    playfairRegular: 'playfair_regular',
    playfairDisplay: 'PlayfairDisplay',
    playfairBold: 'playfair_bold',
    Curly: 'Curly',
    Typewritter: 'Typewritter',
    MckinseySansRegular: 'Mckinsey-Sans-Regular',
    MckinseySansMedium: 'Mckinsey-Sans-Medium',
    MckinseySansLight: 'Mckinsey-Sans-Light',
};

const fontColors = {
    siteGrey: '#767676',
    siteWhite: '#ffffff',
    textBlack: '#3E3E3E',
    placeholderGrey: '#767676',
    electricBlue: '#1F40E6',
    darkIndigo: '#101357',
    borderGrey: '#999999',
    purple: '#8ea6fe',
    textGrey1: '#767676',
    errorRed: '#BD150B',
    streakBlue: '#3E74CA',
    jadeGreen: '#52AC79',
    nneutralGrey: '#d8d8d8',
    forestGreen: '#2A823F',
    lightGrey: '#cccccc',
    backgroundGrey: '#F5F5F8',
    darkGreen: '#15472C',
    deepPurple: '#650F67',
    crimson: '#BF334E',
    lightpurple: '#722E92',
    violet: '#4B007B',
    darkBlue: '#0029AD',
    deepDarkBlue: '#142973',
    pastelBLue: '#2B61B7',
    emptyCardGreen: '#53AB79',
    black: '#000',
    transparent: 'transparent',
    grey: '#A3A3A3',
    black200: '#051C2C',
    electricBlue200: '#99C4FF',
    electricBlue900: '#061F79',
    textColorDisabled: '#B3B3B3',
    backgroundColorDisabled: '#FAFAFB',
    indigoDye: '#0E2B99',
    blueLight: '#2251FF',
};

export { fontFamilies, fontColors };
