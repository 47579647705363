import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import blueClock from '../../assets/blueClock.svg';
import tick from '../../assets/tick.svg';

const EmailCard = ({ title, subtitle, isReminder = false, isComplete = false, complete }) => (
    <div className="email-card">
        <div className="name">
            <div className="value">{(title || '').toUpperCase()}</div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isComplete ? (
                <div className="complete">
                    <div className="tick">
                        <img src={tick} alt="Tick" />
                    </div>
                    <div className="side-label">{complete || 'Complete'}</div>
                </div>

            ) : isReminder ? (
                <div className="image">
                    <img src={blueClock} alt="Reminder Icon" />
                </div>
            ) : null}

        </div>
        <div className="label">{subtitle}</div>
    </div>
);

export default EmailCard;
EmailCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    isReminder: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    complete: PropTypes.bool.isRequired,
};
