import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Text from '../Text';
import rArrow from '../../assets/rArrow.svg';
import lArrow from '../../assets/lArrow.svg';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';

const hoursMap = ['12:00', '12:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'];

const formatMap = ['AM', 'PM'];

const TimePickerDesktop = ({ onChange, time }) => {
    const [currentFormat, setCurrentFormat] = useState(time?.format ? formatMap.indexOf(time?.format) : 0);

    const handleClick = event => {
        const newIndex = (currentFormat + 1) % 2;
        setCurrentFormat(newIndex);
        onChange(event, formatMap[newIndex], 'format');
    };

    return (
        <div className="desktop-time-picker">
            <div className="time-header-container">
                <IconButton className="left-arrow" onClick={handleClick}>
                    <img src={lArrow} alt="Left Arrow" />
                </IconButton>
                <Text
                    fontcolor={fontColors.black}
                    fontfamily={fontFamilies.TheinhardtPanRegular}
                    fontsize="18px"
                    lineHeight="27px"
                    fontWeight="500"
                >
                    {formatMap[currentFormat]}
                </Text>
                <IconButton className="right-arrow" onClick={handleClick}>
                    <img src={rArrow} alt="Right Arrow" />
                </IconButton>
            </div>
            <div className="hours-container">
                {hoursMap.map(hour => (
                    <div
                        className={`time-value ${hour === `${time.hr}:${time.min}` ? 'time-selected' : ''}`}
                        key={hour}
                        onClick={event => {
                            onChange(event, hour, 'hr', true);
                        }}
                    >
                        {hour}
                    </div>
                ))}
            </div>
        </div>
    );
};

TimePickerDesktop.propTypes = {
    onChange: PropTypes.func.isRequired,
    time: PropTypes.object.isRequired,
};

export default TimePickerDesktop;
