import React, { useEffect, useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import PracticeCard from '../../components/PracticeCard';
import './index.scss';
import PracticeProgressCard from '../../components/PracticeProgressCard';
import UpNextCard from '../../components/UpNextCard';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import NewPracticeCard from '../../components/NewPracticeCard';
import CompletedPracticeCard from '../../components/CompletedPracticeCard';
import Loader from '../../components/Loader';
import Actionbutton from '../../components/ActionButton';
import coachMark from '../../assets/coachMark.svg';
import CoachMarks from './CoachMarks';
import LocalisationsStore from '../../common/LocalisationsStore';
import PromptArticle from '../PromptArticle/index.container';
import AddNewPost from '../Feeds/AddNewPost';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import EmptyPracticeContainer from './EmptyPracticeContainer';
import { pageAnalytics, nullable, useMobileCheck } from '../../utils/functions';

export default function PracticesCompnent({
    getInProgressPractices,
    getCompletedPractices,
    getNewPractices,
    practicesInProgress,
    practicesCompleted,
    practicesNew,
    isLoading,
    storeInProgressEmptyPractices,
    storeCompletedEmptyPractices,
    storeEmptyNewPractices,
    history,
    getPracticeDetails,
    uploadPost,
    uploadingPost,
    tags,
    fetchbrowseMore,
    setBrowseMoreCompleted,
    clientExpired,
    getInProgressNewPractices,
    inPrgLoading,
}) {
    // eslint-disable-next-line no-unused-vars
    const [inProgressPage, setinProgressPage] = useState(1);
    const [newPage, setnewPage] = useState(1);
    const [completedPage, setCompletedPage] = useState(1);
    const [showPrompt, setShowPrompt] = useState({
        show: false,
    });
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [hashtag, setHashTag] = useState('');

    const [UpNextId, setUpnextid] = useState('');
    const [showCoachMarks, setShowCoachMarks] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const isMobileWithTab = useMobileCheck();

    const { localisationsAfterLogin } = LocalisationsStore;
    const { practiceInProgress, browseAllPractices, startAnewPractice, completed } = localisationsAfterLogin;

    useEffect(() => {
        pageAnalytics.initialise('practiceHome');
        return () => {
            pageAnalytics.complete('practiceHome');
        };
    }, []);

    useEffect(() => {
        getInProgressNewPractices(inProgressPage);
        getCompletedPractices(completedPage);
        // eslint-disable-next-line
    }, [getCompletedPractices, getInProgressNewPractices])

    useEffect(
        () => () => {
            storeInProgressEmptyPractices();
            storeCompletedEmptyPractices();
            storeEmptyNewPractices();
        },
        // eslint-disable-next-line
        [],
    );

    const handleNewBrowseClick = useCallback(() => {
        const thisPage = newPage + 1;
        setnewPage(thisPage);
        getNewPractices(thisPage);
    }, [newPage, getNewPractices]);

    const handleInProgressBrowseClick = useCallback(() => {
        const thisPage = inProgressPage + 1;
        setinProgressPage(thisPage);
        fetchbrowseMore(thisPage);
    }, [inProgressPage, fetchbrowseMore]);

    const handleCompletedBrowseClick = useCallback(() => {
        const thisPage = completedPage + 1;
        setCompletedPage(thisPage);
        setBrowseMoreCompleted(true);
        getCompletedPractices(thisPage);
    }, [completedPage, getCompletedPractices, setBrowseMoreCompleted]);

    const handleViewClick = (event, id) => {
        event.stopPropagation();
        history.push(`/app/practice/${id}`);
    };
    const resetPracticesPage = () => {
        setnewPage(1);
        setCompletedPage(1);
        setinProgressPage(1);
        storeInProgressEmptyPractices();
        storeEmptyNewPractices();
        getCompletedPractices(1);
        getInProgressNewPractices(1);
    };

    const handleNextArticleClick = (e, next_article_id) => {
        e.stopPropagation();
        setUpnextid(next_article_id);
        setShowPrompt({ show: true });
    };

    const handleCoachMarkClick = () => {
        setShowCoachMarks(true);
    };

    const handleClose = () => {
        getInProgressPractices(inProgressPage);
        setShowCreatePost(false);
    };

    const handleClosePromptArticle = ({ show }) => {
        setBrowseMoreCompleted(false);
        setShowPrompt({ show });
        resetPracticesPage();
    };

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };

    const hasActivePractices = (practicesInProgress && practicesInProgress.data.length > 0) || (practicesNew && practicesNew.data.length > 0);
    const hasCompletedPractices = practicesCompleted && practicesCompleted.data.length > 0;
    const initialLoading = inProgressPage === 1 && newPage === 1 && completedPage === 1 && (isLoading || inPrgLoading);

    const getMobileActivePracticesView = () => (
        <div className="in_progress_and_new">
            {practicesInProgress && practicesInProgress.data.length > 0 && (
                <>
                    <Text
                        fontsize="1.25rem"
                        fontcolor={fontColors.black}
                        fontfamily={fontFamilies.playfairBold}
                        margin="2rem 0rem 1rem 0"
                    >
                        {practiceInProgress}
                        <img
                            src={coachMark}
                            alt=""
                            onClick={handleCoachMarkClick}
                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                        />
                    </Text>
                    {practicesInProgress.data.map((card, index) => (
                        <div className="practice-wrappr" key={card.id}>
                            <PracticeCard
                                index={index}
                                id={card.id}
                                name={card.name}
                                thumbnail={card.thumbnail}
                                progress={card.progress}
                                is_featured={card.is_featured}
                                next_article_id={card.next_article_id}
                                next_article_title={card.next_article_title}
                                bg_color={card.bg_color}
                                onClick={e => handleViewClick(e, card.id)}
                            />
                            <PracticeProgressCard
                                progress={card.progress}
                                index={index}
                                bg_color={card.bg_color}
                                badge_name={card.badge_name}
                            />
                            {card.next_article_id && (
                            <UpNextCard
                                key={card.next_article_id}
                                handleNextArticleClick={handleNextArticleClick}
                                index={index}
                                next_article_id={card.next_article_id}
                                next_article_title={card.next_article_title}
                            />
                            )}
                        </div>
                    ))}
                </>
            )}
            {practicesNew && practicesNew.data.length > 0 && (
                <>
                    <Text
                        fontsize="1.25rem"
                        fontcolor={fontColors.black}
                        fontfamily={fontFamilies.playfairBold}
                        margin="2rem 0 1rem 0"
                    >
                        {startAnewPractice}
                    </Text>

                    <div className="new-practices-wrapper">
                        {practicesNew
                        && practicesNew.data.map((card, index) => (
                            <NewPracticeCard
                                index={index}
                                id={card.id}
                                key={card.id}
                                name={card.name}
                                thumbnail={card.thumbnail}
                                orientation_promt_id={card.orientation_promt_id}
                                description={card.description}
                                bg_color={card.bg_color}
                                onClick={handleViewClick}
                                getPracticeDetails={getPracticeDetails}
                                getInProgressPractices={getInProgressPractices}
                                getNewPractices={getNewPractices}
                                storeInProgressEmptyPractices={
                                    storeInProgressEmptyPractices
                                }
                                storeEmptyNewPractices={storeEmptyNewPractices}
                                resetPracticesPage={resetPracticesPage}
                            />
                        ))}
                    </div>
                    {practicesNew && practicesNew.hasMoreData && (
                    <div className="browse_btn_wrapper">
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            onClick={handleNewBrowseClick}
                            width="15rem"
                        >
                            {browseAllPractices}
                        </Actionbutton>
                    </div>
                    )}
                </>
            )}
        </div>
    );

    const getMobileCompletedPracticesView = () => (practicesCompleted.data.length > 0 && (
    <div className="in_completed">
        <Text
            fontsize="1.25rem"
            fontcolor={fontColors.black}
            fontfamily={fontFamilies.playfairBold}
            margin="2rem 0 1rem 0"
            cursor="pointer"
        />
        {practicesCompleted.data.map((card, index) => (
            <CompletedPracticeCard
                index={index}
                id={card.id}
                key={card.id}
                name={card.name}
                thumbnail={card.thumbnail}
                progress={card.progress}
                is_featured={card.is_featured}
                next_article_id={card.next_article_id}
                next_article_title={card.next_article_title}
                completedOn={card.completed_on}
                bg_color={card.bg_color}
                onClick={handleViewClick}
            />
        ))}
        {practicesCompleted && practicesCompleted.hasMoreData && (
        <div className="browse_btn_wrapper">
            <Actionbutton
                fontSize="1.125rem"
                fontFamily={fontFamilies.TheinhardtPanRegular}
                fontColor={fontColors.siteWhite}
                bgColor={fontColors.electricBlue}
                disabled={false}
                onClick={handleCompletedBrowseClick}
                width="15rem"
            >
                {browseAllPractices}
            </Actionbutton>
        </div>
        )}
    </div>
    ));

    const getTabView = () => (
        <div className="mobile-practices-tabular-view">
            <Tabs selected={activeTab} handleChange={setActiveTab}>
                <Tab title="Active">
                    {getMobileActivePracticesView()}
                </Tab>
                <Tab title="Completed">
                    {getMobileCompletedPracticesView()}
                </Tab>
            </Tabs>
        </div>
    );

    return (
        <div className="practices_container">
            {clientExpired && moveToClientExpirePage()}
            {showCoachMarks && <CoachMarks setShowCoachMarks={setShowCoachMarks} />}
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={UpNextId}
                    setShowCreatePost={setShowCreatePost}
                    resetPracticesPage={resetPracticesPage}
                />
            )}
            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || { practice: [], category: [] }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
            {(isLoading || inPrgLoading) && <Loader position="fixed" showBackground />}
            <div className="practices_innner_container">
                {isMobileWithTab ? (
                    <>
                        {(hasActivePractices && hasCompletedPractices) ? getTabView() : (
                            <>
                                {hasActivePractices && !initialLoading ? getMobileActivePracticesView() : null}
                                {hasCompletedPractices && !initialLoading ? getMobileCompletedPracticesView() : null}
                            </>
                        )}
                    </>
                ) : (
                    <Fragment>
                        <div className="space-adjuster" />
                        <div
                            className="in_progress_and_new"
                            style={{
                                width: practicesCompleted && practicesCompleted.data.length > 0 ? '35%' : '50%',
                                marginLeft: practicesCompleted && practicesCompleted.data.length > 0 ? '10%' : '10%',
                            }}
                        >
                            {practicesInProgress && practicesInProgress.data.length > 0 && (
                                <>
                                    <Text
                                        fontsize="1.25rem"
                                        fontcolor={fontColors.black}
                                        fontfamily={fontFamilies.playfairBold}
                                        margin="2rem 0rem 1rem 0"
                                    >
                                        {practiceInProgress}
                                        <img
                                            src={coachMark}
                                            alt=""
                                            onClick={handleCoachMarkClick}
                                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                                        />
                                    </Text>
                                    {practicesInProgress.data.map((card, index) => (
                                        <div className="practice-wrappr" key={card.id}>
                                            <PracticeCard
                                                index={index}
                                                id={card.id}
                                                name={card.name}
                                                thumbnail={card.thumbnail}
                                                progress={card.progress}
                                                is_featured={card.is_featured}
                                                next_article_id={card.next_article_id}
                                                next_article_title={card.next_article_title}
                                                bg_color={card.bg_color}
                                                onClick={e => handleViewClick(e, card.id)}
                                            />
                                            <PracticeProgressCard
                                                progress={card.progress}
                                                index={index}
                                                bg_color={card.bg_color}
                                                badge_name={card.badge_name}
                                            />
                                            {card.next_article_id && (
                                                <UpNextCard
                                                    key={card.next_article_id}
                                                    handleNextArticleClick={handleNextArticleClick}
                                                    index={index}
                                                    next_article_id={card.next_article_id}
                                                    next_article_title={card.next_article_title}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    {practicesInProgress && practicesInProgress.hasMoreData && (
                                        <div className="browse_btn_wrapper">
                                            <Actionbutton
                                                fontSize="1.125rem"
                                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                                fontColor={fontColors.siteWhite}
                                                bgColor={fontColors.electricBlue}
                                                disabled={false}
                                                onClick={handleInProgressBrowseClick}
                                                width="15rem"
                                            >
                                                {browseAllPractices}
                                            </Actionbutton>
                                        </div>
                                    )}
                                </>
                            )}
                            {practicesNew && practicesNew.data.length > 0 && (
                                <>
                                    <Text
                                        fontsize="1.25rem"
                                        fontcolor={fontColors.black}
                                        fontfamily={fontFamilies.playfairBold}
                                        margin="2rem 0 1rem 0"
                                    >
                                        {startAnewPractice}
                                    </Text>

                                    <div className="new-practices-wrapper">
                                        {practicesNew
                                            && practicesNew.data.map((card, index) => (
                                                <NewPracticeCard
                                                    index={index}
                                                    id={card.id}
                                                    key={card.id}
                                                    name={card.name}
                                                    thumbnail={card.thumbnail}
                                                    orientation_promt_id={card.orientation_promt_id}
                                                    description={card.description}
                                                    bg_color={card.bg_color}
                                                    onClick={handleViewClick}
                                                    getPracticeDetails={getPracticeDetails}
                                                    getInProgressPractices={getInProgressPractices}
                                                    getNewPractices={getNewPractices}
                                                    storeInProgressEmptyPractices={storeInProgressEmptyPractices}
                                                    storeEmptyNewPractices={storeEmptyNewPractices}
                                                    resetPracticesPage={resetPracticesPage}
                                                />
                                            ))}
                                    </div>
                                    {practicesNew && practicesNew.hasMoreData && (
                                        <div className="browse_btn_wrapper">
                                            <Actionbutton
                                                fontSize="1.125rem"
                                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                                fontColor={fontColors.siteWhite}
                                                bgColor={fontColors.electricBlue}
                                                disabled={false}
                                                onClick={handleNewBrowseClick}
                                                width="15rem"
                                            >
                                                {browseAllPractices}
                                            </Actionbutton>
                                        </div>
                                    )}
                                </>
                            )}
                            {practicesInProgress && practicesInProgress.data.length === 0 && practicesNew && practicesNew.data.length === 0 && (
                                <EmptyPracticeContainer />
                            )}
                        </div>
                        {practicesCompleted && practicesCompleted.data.length > 0 && (
                            <div
                                className="in_completed"
                                style={{ width: practicesCompleted.data.length > 0 ? '25%' : 'auto' }}
                            >
                                <Text
                                    fontsize="1.25rem"
                                    fontcolor={fontColors.black}
                                    fontfamily={fontFamilies.playfairBold}
                                    margin="2rem 0 1rem 0"
                                    cursor="pointer"
                                >
                                    {completed}
                                </Text>
                                {practicesCompleted.data.map((card, index) => (
                                    <CompletedPracticeCard
                                        index={index}
                                        id={card.id}
                                        key={card.id}
                                        name={card.name}
                                        thumbnail={card.thumbnail}
                                        progress={card.progress}
                                        is_featured={card.is_featured}
                                        next_article_id={card.next_article_id}
                                        next_article_title={card.next_article_title}
                                        completedOn={card.completed_on}
                                        bg_color={card.bg_color}
                                        onClick={handleViewClick}
                                    />
                                ))}
                                {practicesCompleted && practicesCompleted.hasMoreData && (
                                    <div className="browse_btn_wrapper">
                                        <Actionbutton
                                            fontSize="1.125rem"
                                            fontFamily={fontFamilies.TheinhardtPanRegular}
                                            fontColor={fontColors.siteWhite}
                                            bgColor={fontColors.electricBlue}
                                            disabled={false}
                                            onClick={handleCompletedBrowseClick}
                                            width="15rem"
                                        >
                                            {browseAllPractices}
                                        </Actionbutton>
                                    </div>
                                )}
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
}

PracticesCompnent.defaultProps = {
    uploadingPost: false,
};

PracticesCompnent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    inPrgLoading: PropTypes.bool.isRequired,
    getInProgressPractices: PropTypes.func.isRequired,
    getCompletedPractices: PropTypes.func.isRequired,
    getNewPractices: PropTypes.func.isRequired,
    practicesInProgress: nullable(PropTypes.shape({
        hasMoreData: PropTypes.bool,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                thumbnail: PropTypes.string,
                progress: PropTypes.string,
                is_featured: PropTypes.bool,
                next_article_id: PropTypes.number,
                next_article_title: PropTypes.string,
                bg_color: PropTypes.string,
            }),
        ),
    })).isRequired,

    practicesNew: nullable(PropTypes.shape({
        completed: PropTypes.bool,
        inprogress: PropTypes.bool,
        hasMoreData: PropTypes.bool,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                orientation_promt_id: PropTypes.number,
                name: PropTypes.string,
                thumbnail: PropTypes.string,
                description: PropTypes.string,
                bg_color: PropTypes.string,
            }),
        ),
    })).isRequired,

    practicesCompleted: nullable(PropTypes.shape({
        hasMoreData: PropTypes.bool,
        data: PropTypes.array.isRequired,
    })).isRequired,

    storeInProgressEmptyPractices: PropTypes.func.isRequired,
    storeCompletedEmptyPractices: PropTypes.func.isRequired,
    fetchbrowseMore: PropTypes.func.isRequired,
    setBrowseMoreCompleted: PropTypes.func.isRequired,
    storeEmptyNewPractices: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool,
    uploadPost: PropTypes.func.isRequired,
    tags: nullable(PropTypes.object).isRequired,
    clientExpired: PropTypes.bool.isRequired,
    getInProgressNewPractices: PropTypes.func.isRequired,

};
