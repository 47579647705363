import types from './types';

export const getFeedCards = ({ hashtag, page }) => ({
    type: `${types.GET_FEED_CARDS_LIST}_PENDING`,
    hashtag,
    page,
});

export const storeFeedCardList = data => ({
    type: `${types.GET_FEED_CARDS_LIST}_DONE`,
    data,
});
