import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PracticesCompnent from './PracticesCompnent';
import PracticesReducer from './redux/reducer';
import PracticesSaga from './redux/sagas';
import {
    getInProgressPractices,
    getCompletedPractices,
    getNewPractices,
    storeInProgressEmptyPractices,
    storeCompletedEmptyPractices,
    storeEmptyNewPractices,
    browseMoreFetched,
    fetchbrowseMore,
    setBrowseMoreCompleted, getInProgressNewPractices,
} from './redux/actions';

import { getPracticeDetails } from '../PracticesDetailsContainer/redux/actions';
import { uploadPost } from '../Feeds/redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('PracticesReducer');
    const PracticeReducer = state.get('PracticeDetailReducer');
    const FeedsREDUCER = state.get('feedsReducer');
    const REFREDUCER = state.get('RefreshReducer');
    return {
        isLoading: REDUCER.isLoading,
        practicesInProgress: REDUCER.practicesInProgress,
        practicesCompleted: REDUCER.practicesCompleted,
        practicesNew: REDUCER.practicesNew,
        uploadingPost: PracticeReducer.uploadingPost,
        tags: FeedsREDUCER.tags,
        clientExpired: REFREDUCER.clientExpired,
        inPrgLoading: REDUCER.inPrgLoading,
    };
};

const dispatchToProps = {
    getInProgressPractices,
    getCompletedPractices,
    getNewPractices,
    storeInProgressEmptyPractices,
    storeCompletedEmptyPractices,
    storeEmptyNewPractices,
    getPracticeDetails,
    uploadPost,
    browseMoreFetched,
    fetchbrowseMore,
    setBrowseMoreCompleted,
    getInProgressNewPractices,
};

export { PracticesReducer, PracticesSaga };

export default withRouter(connect(stateToProps, dispatchToProps)(PracticesCompnent));
