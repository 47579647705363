import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../components/Text';
import NewCompletedIcon from '../../../../assets/new_completed.svg';
import { fontFamilies, fontColors } from '../../../../common/fontMap';
import './index.scss';

const ActionDetailCard = ({
    actionText,
    backgroundColor,
    emptyText,
    id,
    isCompleted,
    isDisabled,
    isEmpty,
    onClickCard,
    textColor,
}) => (
    <div
        className="action-detail-card"
        style={{
            backgroundColor: isDisabled ? fontColors.backgroundColorDisabled : (backgroundColor || fontColors.siteWhite),
            cursor: isDisabled ? 'default' : 'pointer',
        }}
        onClick={() => (onClickCard ? onClickCard(id) : null)}
    >
        {!isEmpty ? (
            <>
                {isCompleted ? <img src={NewCompletedIcon} alt="completed" className="completed-icon" /> : null}
                <Text
                    fontfamily={fontFamilies.MckinseySansRegular}
                    fontcolor={isDisabled ? fontColors.textColorDisabled : (textColor || fontColors.black)}
                    fontsize="16px"
                    lineHeight="20px"
                    fontweight={400}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    display="block"
                >
                    {actionText}
                </Text>
            </>
        ) : (
            <Text
                fontfamily={fontFamilies.MckinseySansRegular}
                fontcolor={fontColors.textBlack}
                fontsize="18px"
                lineHeight="24px"
                letterSpacing="0.1px"
                fontweight={400}
            >
                {emptyText}
            </Text>
        )}
    </div>
);

ActionDetailCard.defaultProps = {
    actionText: null,
    backgroundColor: null,
    emptyText: null,
    id: null,
    isCompleted: false,
    isDisabled: false,
    isEmpty: false,
    onClickCard: () => {},
    textColor: null,
};

ActionDetailCard.propTypes = {
    actionText: PropTypes.string,
    backgroundColor: PropTypes.string,
    emptyText: PropTypes.string,
    id: PropTypes.number,
    isCompleted: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isEmpty: PropTypes.bool,
    onClickCard: PropTypes.func,
    textColor: PropTypes.string,
};

export default ActionDetailCard;
