import styled from 'styled-components';

const PreviewHeaderImage = styled.img`
    background-size: auto;
    background-repeat: no-repeat;
    width: ${props => props.width};
    height: ${props => props.height};
    clip-path: url(${props => props.id});
`;

export default PreviewHeaderImage;
