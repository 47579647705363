import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import NotesInput from './NotesInput';
import LocalisationsStore from '../../../common/LocalisationsStore';

const NotesDetails = ({ addNotes, notes, goalType }) => {
    const [notesSize, setNotesSize] = useState(3);
    const [showAll, setShowAll] = useState(true);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { viewAll } = localisationsAfterLogin;
    const newArray = notes;
    const showMoreNotes = size => {
        setNotesSize(size);
        setShowAll(false);
    };

    const setNumberOfNotes = num => {
        setNotesSize(num);
    };
    const numberOFnotes = notes.length;
    return (
        <div className="notesWrapper">
            <div className="notesDetails">
                <NotesInput addNotes={addNotes} goalType={goalType} notes={notes} setNumberOfNotes={setNumberOfNotes} />
                <div className="notesList">
                    <ul>{newArray && newArray.slice(0, notesSize).map(v => <li key={v.id}>{v.text}</li>)}</ul>
                </div>
            </div>
            {numberOFnotes !== 0 && numberOFnotes > notesSize && (
                <div className="moreBtn" onKeyDown={() => {}} role="button" tabIndex={0}>
                    {showAll && (
                        <div
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                            onClick={() => showMoreNotes(numberOFnotes)}
                            className="moreInnerBtn"
                        >
                            {`+ ${numberOFnotes - notesSize} ${viewAll}`}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

NotesDetails.propTypes = {
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    goalType: PropTypes.string.isRequired,
};

export default NotesDetails;
