/* eslint-disable block-scoped-var */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import './index.scss';
import header from '../../assets/header.svg';
import headerm from '../../assets/headerm.svg';
import LogoInsWhite from '../../assets/LogoInsWhite.png';
import { fetchAllFeeds } from '../UnAuthenticatedContent/apis';
import { mobileCheck } from '../../utils/functions';
import LocalisationsStore from '../../common/LocalisationsStore';

function GuestComponent(props) {
    const { history } = props;

    const [feeds, setFeeds] = useState(null);
    const [loading, setIsLoading] = useState(false);

    const { localisationsBeforeLogin } = LocalisationsStore;
    const { WelcometoInspire, articles, returnToLogin } = localisationsBeforeLogin;

    useEffect(() => {
        setIsLoading(true);
        fetchAllFeeds()
            .then(response => {
                setFeeds(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    const handleReturnClick = () => {
        history.push('/');
    };

    const handleCardClick = event => {
        const { currentTarget: { dataset: { link } } } = event;
        window.open(link, '_blank');
    };

    const isMobile = mobileCheck();

    return (
        <div className="unauth_content_container_wrapper_guest">
            {loading && <Loader position="absolute" showBackground />}
            <div className="unauth_content_container">
                <div className="header_container">
                    <img src={isMobile ? headerm : header} alt="inspire_logo" />
                    <span className="logo_header">
                        <img src={LogoInsWhite} alt="inspire_log" />
                        <Text
                            fontsize="1.5rem"
                            fontcolor={fontColors.siteWhite}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0 2rem"
                        >
                            {WelcometoInspire}
                        </Text>
                    </span>
                </div>
                <div className="dummy_box" />
                <div className="central_content">
                    <div className="header_info">
                        <Text
                            fontsize="1.5rem"
                            fontcolor={fontColors.siteGrey}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.625rem 0"
                        >
                            {articles}
                        </Text>
                        <div className="unauth_feeds_wrapper">
                            {feeds
                                && feeds.vertical_list.map(feed => (
                                    <div
                                        className="card"
                                        key={feed.name}
                                        onClick={handleCardClick}
                                        data-link={feed.url}
                                        role="none"
                                    >
                                        <img src={feed.image} alt="feed.name" />
                                        <div className="card_right_content">
                                            <Text
                                                fontsize="0.75rem"
                                                fontcolor={fontColors.textGrey1}
                                                fontfamily={fontFamilies.TheinhardtPanMedium}
                                                letterSpacing="1px"
                                            >
                                                {(feed.site || '').toUpperCase()}
                                            </Text>
                                            <Text
                                                fontsize="1.125rem"
                                                fontcolor={fontColors.textBlack}
                                                fontfamily={fontFamilies.TheinhardtPanRegular}
                                                margin="0.625rem 0"
                                            >
                                                {feed.name}
                                            </Text>
                                            <Text
                                                fontsize="0.625rem"
                                                fontcolor={fontColors.textGrey1}
                                                fontfamily={fontFamilies.TheinhardtPanLight}
                                            >
                                                {feed.sub_heading.length > 100
                                                    ? `${feed.sub_heading.substring(0, 100)}...`
                                                    : feed.sub_heading}
                                            </Text>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="footer_container">
                    <Actionbutton
                        fontSize="1.125rem"
                        fontFamily={fontFamilies.TheinhardtPanRegular}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        disabled={false}
                        onClick={handleReturnClick}
                        width="365px"
                    >
                        {returnToLogin}
                    </Actionbutton>
                </div>
            </div>
        </div>
    );
}

GuestComponent.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
    }).isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(GuestComponent);
