import _ from 'lodash';

export const animationMissing = '';

export const PLAY = 'play';
export const PAUSE = 'pause';
export const STOP = 'stop';
export const directionForward = 1;
export const directionReverse = -1;
export const examplePath = '';

export const checkObjectsEqualByValue = (object1, object2) => {
    // Find all keys of object1 since comparing object with object1 is enough to validate equality
    // For every key find if object 2 has the same key and then compare with the value of object1 for that key.
    // For nested objects recursively check equality
    // eslint-disable-next-line camelcase
    const is_equal = true;
    // eslint-disable-next-line camelcase
    const object_keys = _.keys(object1);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < object_keys.length; i++) {
        const key = object_keys[i];
        if (_.has(object2, key)) {
            if (_.isObject(object2[key])) {
                return checkObjectsEqualByValue(object1[key], object2[key]);
            }
            if (object1[key] !== object2[key]) {
                return false;
            }
        } else {
            return false;
        }
    }
    // eslint-disable-next-line camelcase
    return is_equal;
};
