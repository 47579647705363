/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { webApiGet, webApiPut, webApiPost, webApiDelete, webApiPostFormData } from './core';

export function fetchWebApi(accessToken, url) {
    let socialGroup;
    const selectedMenu = localStorage.getItem('selectedMenu');
    if (localStorage.userProfile && selectedMenu === '4') {
        socialGroup = JSON.parse(localStorage.userProfile).social_group;
    }
    if (socialGroup && socialGroup.length > 0) {
        if (url.includes('?')) {
            return webApiGet(accessToken, `${url}&social_group=${socialGroup[0].id}`);
        }
        return webApiGet(accessToken, `${url}?social_group=${socialGroup[0].id}`);
    }
    return webApiGet(accessToken, url);
}

export function putDashboardWebApi(accessToken, url, bodyObject) {
    let socialGroup;
    const selectedMenu = localStorage.getItem('selectedMenu');
    if (localStorage.userProfile && selectedMenu === '4') {
        socialGroup = JSON.parse(localStorage.userProfile).social_group;
    }
    if (socialGroup && socialGroup.length > 0 && bodyObject) {
        bodyObject.social_group = socialGroup[0].id;
        return webApiPut(accessToken, `${url}`, bodyObject);
    }
    return webApiPut(accessToken, url, bodyObject);
}

export function postWebApi(accessToken, url, bodyObject, removeCacheControl) {
    let socialGroup;
    const selectedMenu = localStorage.getItem('selectedMenu');
    if (localStorage.userProfile && selectedMenu === '4') {
        socialGroup = JSON.parse(localStorage.userProfile).social_group;
    }
    if (bodyObject instanceof FormData) {
        if (socialGroup && socialGroup.length > 0) {
            bodyObject.append('social_group', socialGroup[0].id);
            return webApiPostFormData(accessToken, `${url}`, bodyObject);
        }
        return webApiPostFormData(accessToken, url, bodyObject);
    }
    if (socialGroup && socialGroup.length > 0) {
        bodyObject.social_group = socialGroup[0].id;
        return webApiPost(accessToken, `${url}`, bodyObject);
    }
    return webApiPost(accessToken, url, bodyObject, removeCacheControl);
}

export function deleteWebApi(accessToken, url, bodyObject) {
    let socialGroup;
    const selectedMenu = localStorage.getItem('selectedMenu');
    if (localStorage.userProfile && selectedMenu === '4') {
        socialGroup = JSON.parse(localStorage.userProfile).social_group;
    }
    if (socialGroup && socialGroup.length > 0) {
        if (url.includes('?')) {
            return webApiDelete(accessToken, `${url}&social_group=${socialGroup[0].id}`, bodyObject);
        }
        return webApiDelete(accessToken, `${url}?social_group=${socialGroup[0].id}`, bodyObject);
    }
    return webApiDelete(accessToken, url, bodyObject);
}
