import { fetchWebApi, postWebApi, deleteWebApi, putDashboardWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

export const getFeedsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { feeds } = endpoints;
    const url = `${apiBasePath + feeds}?hashtag=${params[3].substring(1)}&type=social&page=${params[1]}&${
        params[params.length - 1]
    }`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const postLikeApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { like } = endpoints;
    const url = apiBasePath + like;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const getTagsApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { tags } = endpoints;
    const url = apiBasePath + tags;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const makeAPostApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { makePost } = endpoints;
    const url = apiBasePath + makePost;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const getThisFeedsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { feeds } = endpoints;
    const url = `${apiBasePath + feeds}${params[1]}/`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getArticle = ({ id }) => {
    const apiBasePath = AuthStore.baseUrl;
    const { feeds } = endpoints;
    const url = `${apiBasePath + feeds}${id}/`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const deletePostApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { posts } = endpoints;
    const url = `${apiBasePath + posts}${body.article_id}/`;
    return deleteWebApi(AuthStore.accessToken, url, body).request;
};

export const postSavedPostsApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { bookmarkPosts } = endpoints;
    const url = apiBasePath + bookmarkPosts;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const getSavedPostsApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { bookmarkPosts } = endpoints;
    const url = apiBasePath + bookmarkPosts;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getLikeDetailsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    let url;
    const { articleLikes, commentLikes } = endpoints;
    if (params[1]) {
        url = `${apiBasePath}${articleLikes}${params[1]}/`;
        return fetchWebApi(AuthStore.accessToken, url).request;
    }
    url = `${apiBasePath}${commentLikes}${params[2]}/`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
export const postFollowApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { followUser } = endpoints;
    const url = apiBasePath + followUser;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const getUsersListOfType = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { usersOfType } = endpoints;
    const url = `${apiBasePath + usersOfType}${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getAllCommentsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getAllComments } = endpoints;
    const url = `${apiBasePath + getAllComments}${params[1]}/`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const postCommentApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { postComment } = endpoints;
    const url = `${apiBasePath + postComment}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const modifyCommentApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { postComment } = endpoints;
    const url = `${apiBasePath + postComment}${params[1]}`;
    const call = params[2] === 'like'
        ? putDashboardWebApi(AuthStore.accessToken, url).request
        : deleteWebApi(AuthStore.accessToken, url).request;
    return call;
};

export const postQuizAnswerApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { postQuiz } = endpoints;
    const url = `${apiBasePath + postQuiz}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const reportPostApi = (params, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { reportPost } = endpoints;
    const url = `${apiBasePath + reportPost}${params[1]}`;
    return putDashboardWebApi(AuthStore.accessToken, url, body).request;
};

export const fetchUsersApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { fetchUserList } = endpoints;
    const url = `${apiBasePath + fetchUserList}?q=${params[1] ? params[1] : ''}&page=${params[2]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const sharePostApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { sharePost } = endpoints;
    const url = `${apiBasePath + sharePost}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const postUnfollowUserApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { unfollowUser } = endpoints;
    const url = `${apiBasePath + unfollowUser}?user=${params[1]}`;
    return deleteWebApi(AuthStore.accessToken, url).request;
};

export const getUsersFollowedApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { followUser } = endpoints;
    const url = `${apiBasePath + followUser}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const removeBookMarkApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { bookmarkPosts } = endpoints;
    const url = `${apiBasePath + bookmarkPosts}${params[1]}/`;
    return deleteWebApi(AuthStore.accessToken, url).request;
};

export const bannerOpenedApi = (params, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { bannerClicked } = endpoints;
    const url = `${apiBasePath}${bannerClicked}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const postActionableNotesApi = (params, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { postActionableNotes } = endpoints;
    const url = `${apiBasePath}${postActionableNotes}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const pageAnalyticsApi = body => {
    const apiBasePath = AuthStore.baseUrl;
    const { analyticsPage } = endpoints;
    const url = `${apiBasePath}${analyticsPage}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};
export const getTeamMembersApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { teamMembers } = endpoints;
    const url = `${apiBasePath}${teamMembers}${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
export const getSequenceOfApisApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { sequenceApi } = endpoints;
    const url = `${apiBasePath}${sequenceApi}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
export const getNewPostsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { newPosts } = endpoints;
    let url;
    switch (params[2]) {
        case 1: {
            url = `${apiBasePath}${newPosts}${params[1]}`;
            break;
        }
        case 2: {
            url = `${apiBasePath}${newPosts}${params[1]}&type=article/`;
            break;
        }
        default: {
            url = `${apiBasePath}${newPosts}${params[1]}`;
            break;
        }
    }
    return fetchWebApi(AuthStore.accessToken, url).request;
};
export const getCoachingJourneyApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { coachingJourney } = endpoints;
    const url = `${apiBasePath}${coachingJourney}${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
export const userScrollAnalyticsApi = (params, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { userScrollAnalytics } = endpoints;
    const url = `${apiBasePath}${userScrollAnalytics}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const dissmissStreakPopupApi = body => {
    const apiBasePath = AuthStore.baseUrl;
    const { dissmissStreak } = endpoints;
    const url = `${apiBasePath + dissmissStreak}`;
    return putDashboardWebApi(AuthStore.accessToken, url, body).request;
};
