import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authentication from '../UnAuthenticatedContent/reducer';
import unAuthSagas from '../UnAuthenticatedContent/sagas';
import { selectCount } from '../UnAuthenticatedContent/selector';
import { getChecksum, setCount } from '../UnAuthenticatedContent/actions';
import Loader from '../../components/Loader';
import LocalisationsStore from '../../common/LocalisationsStore';

function BeforeLoginHOC(props) {
    const { count, setCount, getChecksum, component } = props;
    const getChecksumCall = useCallback(getChecksum, []);
    const setCountCall = useCallback(setCount, []);
    const { localisationsBeforeLogin } = LocalisationsStore;

    useEffect(() => {
        getChecksumCall(false);
    }, [getChecksumCall]);

    useEffect(() => {
        if (count) {
            setCountCall(0);
        }
    }, [count, setCountCall]);

    if (!localisationsBeforeLogin) {
        return <Loader position="absolute" showBackground />;
    }
    return component;
}

BeforeLoginHOC.propTypes = {
    getChecksum: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    setCount: PropTypes.func.isRequired,
};

const stateToProps = createStructuredSelector({
    count: selectCount(),
});

const dispatchToProps = {
    getChecksum,
    setCount,
};

export { unAuthSagas, authentication };

export default withRouter(connect(stateToProps, dispatchToProps)(BeforeLoginHOC));
