import { connect } from 'react-redux';
import CoachingJourneyComponent from './CoachingJourneyComponent';
import { getCoachinJourneyData, storeCoachingJorneyData, pageAnalytics } from '../redux/actions';

const stateToProps = state => {
    const CoachingJourneyReducer = state.get('coachingJourney');
    return {
        coachingJourney: CoachingJourneyReducer.coachingJourneyChapter,
        loading: CoachingJourneyReducer.loading,
    };
};

const dispatchToProps = {
    getCoachinJourneyData,
    storeCoachingJorneyData,
    pageAnalytics,
};

export default connect(stateToProps, dispatchToProps)(CoachingJourneyComponent);
