import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import moment from 'moment';

import Page404 from './containers/Page404';

import PrivateRoute from './PrivateRoute';
import AuthStore from './common/AuthStore';
import Feeds from './containers/Feeds';
import FeedDetails from './containers/FeedDetails';
import NotificationFeeds from './containers/NotificationFeeds';
import InspireLoader from './components/InspireLoader/InspireLoader';
import CallbackComponent from './components/CallbackComponent';
import AppContainer from './AppContainer';
import SubmitIdeaContainer from './containers/SubmitIdeaContainer';
import SignInComponent from './containers/SignInComponent';
import CheckEmailComponent from './containers/CheckEmailComponent';
import GuestComponent from './containers/GuestComponent';
import FirstLoginExperience from './containers/FirstLoginExperience';
import ForgotPassWordComponent from './containers/ForgotPassWordComponent';
import AllTodosComponent from './containers/AllTodos/index.container';
import OnboardingWrapperComponent from './containers/OnboardingComponent/OnboardingWrapperComponent';
import BeforeLoginHOC from './containers/BeforeLoginHOC';
import AllGoals from './containers/AllGoals';
import SetReminder from './containers/SetReminder';
import NoThanks from './containers/NoThanks';
import MarkAsComplete from './containers/MarkAsComplete';
import { getConfig, getTokenFromRefreshToken } from './webapis/core';
import ClientExpireComponent from './containers/ExpireComponent/ClientExpireComponent';

import { pageAnalytics } from './utils/functions';
import endpoints from './Endpoints';
import { logoutUserApi } from './containers/UnAuthenticatedContent/apis';
import { CODE_BAD_REQUEST } from './utils/constants';

const UnAuthPage = lazy(() => {
    const Login = import('./containers/UnAuthenticatedContent');
    return Promise.all([Login, new Promise(resolve => setTimeout(resolve, 300))]).then(
        ([moduleExports]) => moduleExports,
    );
});

const ProfilePage = lazy(() => {
    const Profile = import('./containers/Profile');
    return Promise.all([Profile, new Promise(resolve => setTimeout(resolve, 300))]).then(
        ([moduleExports]) => moduleExports,
    );
});

const Vcrb = lazy(async () => {
    const Vcrb = import('./containers/Profile');
    const [moduleExports] = await Promise.all([Vcrb, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const Unsubscribe = lazy(async () => {
    const Unsubscribe = import('./containers/Unsubscribe');
    const [moduleExports] = await Promise.all([Unsubscribe, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const FaqComponent = lazy(async () => {
    const Faqs = import('./components/FAQs');
    const [moduleExports] = await Promise.all([Faqs, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const WeeklyReplayMailerComponent = lazy(async () => {
    const weeklyReplayMailer = import('./components/WeeklyReplayMailer');
    const [moduleExports] = await Promise.all([weeklyReplayMailer, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const MailerResponse = lazy(async () => {
    const Mailer = import('./containers/MailerResponse');
    const [moduleExports] = await Promise.all([Mailer, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const DetectUserAgent = lazy(async () => {
    const Detector = import('./components/DetectUserAgent');
    const [moduleExports] = await Promise.all([Detector, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const LanguageSelection = lazy(async () => {
    const Detector = import('./containers/LanguageSelection');
    const [moduleExports] = await Promise.all([Detector, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const AppWrapper = lazy(async () => {
    const Detector = import('./containers/AppWrapper');
    const [moduleExports] = await Promise.all([Detector, new Promise(resolve => setTimeout(resolve, 300))]);
    return moduleExports;
});

const tokenExpireTimeMillis = new Date(Number(AuthStore.expTime)) - new Date();

const logoutUser = async () => {
    await logoutUserApi();
    AuthStore.clear();
    window.location.reload();
};

const refreshToken = time => {
    setTimeout(() => {
        if (AuthStore.refreshToken) {
            getTokenFromRefreshToken({ refresh_token: AuthStore.refreshToken }).then(data => {
                AuthStore.accessToken = data.data.id_token;
                AuthStore.baseUrl = 'https://mckinspire.com_baseURL';
                AuthStore.expTime = 'exp';
                const newTokenExpireMillis = new Date(Number(AuthStore.expTime)) - new Date();
                refreshToken(newTokenExpireMillis);
            }).catch(e => {
                if (e?.response?.status === CODE_BAD_REQUEST) {
                    logoutUser();
                }
            });
        }
    }, time);
};

refreshToken(tokenExpireTimeMillis);

const Routes = () => {
    useEffect(() => {
        if (AuthStore.accessToken) {
            pageAnalytics.initialise('userSession');

            const handleVisibilityChange = async () => {
                if (document.visibilityState === 'visible') {
                    pageAnalytics.initialise('userSession');
                } else {
                    const sessionObj = window.sessionStorage.getItem('pageAnalytics');
                    const currentPage = JSON.parse(sessionObj);
                    const userSessionObj = currentPage?.userSession;
                    const pageObject = {
                        ...userSessionObj,
                        end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    };

                    const apiBasePath = AuthStore.baseUrl;
                    const { analyticsPage } = endpoints;
                    const url = `${apiBasePath}${analyticsPage}`;
                    const config = getConfig(AuthStore.accessToken);

                    fetch(url, {
                        method: 'POST',
                        keepalive: true,
                        headers: {
                            ...config.config.headers,
                        },
                        body: JSON.stringify([pageObject]),
                    }).catch(() => {});
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }

        return (() => {});
        // eslint-disable-next-line
    }, [AuthStore.accessToken]);

    return (
        <Route
            render={() => (
                <AppContainer>
                    <Switch>
                        <Route
                            path="/"
                            exact
                            render={props => {
                                const isAuthenticated = AuthStore.accessToken;
                                const page = isAuthenticated ? (
                                    <Suspense fallback={<InspireLoader isLoading />}>
                                        <AppWrapper {...props} />
                                    </Suspense>
                                ) : (
                                    <Suspense fallback={<InspireLoader isLoading />}>
                                        <BeforeLoginHOC component={<UnAuthPage />} {...props} />
                                    </Suspense>
                                );
                                return page;
                            }}
                        />
                        <PrivateRoute path="/profile" component={ProfilePage} />
                        <PrivateRoute path="/feeds" component={Feeds} />
                        <PrivateRoute path="/feed-details-article/:id" component={FeedDetails} />
                        <PrivateRoute path="/feed-details-social/:id" component={NotificationFeeds} />
                        <PrivateRoute path="/vcrb" component={Vcrb} />
                        <Route
                            path="/unsubscribe"
                            render={props => (
                                <Suspense fallback={<InspireLoader isLoading />}>
                                    <Unsubscribe {...props} />
                                </Suspense>
                            )}
                        />

                        <Route path="/callback" component={CallbackComponent} />
                        <Route path="/submitidea" component={SubmitIdeaContainer} />
                        <Route
                            path="/weeklyreplaymailer"
                            render={props => (
                                <Suspense fallback={<InspireLoader isLoading />}>
                                    <WeeklyReplayMailerComponent {...props} />
                                </Suspense>
                            )}
                        />
                        <Route
                            path="/mailer"
                            render={props => (
                                <Suspense fallback={<InspireLoader isLoading />}>
                                    <MailerResponse {...props} />
                                </Suspense>
                            )}
                        />
                        <Route
                            path="/faqs"
                            render={props => (
                                <Suspense fallback={<InspireLoader isLoading />}>
                                    <FaqComponent {...props} />
                                </Suspense>
                            )}
                        />
                        <Route
                            path="/detectuseragent"
                            render={props => (
                                <Suspense fallback={<InspireLoader isLoading />}>
                                    <DetectUserAgent {...props} />
                                </Suspense>
                            )}
                        />
                        <Route
                            path="/languageselectemail"
                            render={props => (
                                <Suspense fallback={<InspireLoader isLoading />}>
                                    <LanguageSelection {...props} />
                                </Suspense>
                            )}
                        />
                        <Route
                            path="/signin"
                            render={props => <BeforeLoginHOC component={<SignInComponent />} {...props} />}
                        />
                        <Route
                            path="/setReminder"
                            render={props => <BeforeLoginHOC component={<SetReminder />} {...props} />}
                        />
                        <Route path="/noThanks" render={props => <BeforeLoginHOC component={<NoThanks />} {...props} />} />
                        <Route
                            path="/markAsComplete"
                            render={props => <BeforeLoginHOC component={<MarkAsComplete />} {...props} />}
                        />
                        <Route
                            path="/forgotPassword"
                            render={props => <BeforeLoginHOC component={<ForgotPassWordComponent />} {...props} />}
                        />
                        <Route path="/checkemail" component={CheckEmailComponent} />
                        <Route
                            path="/guest"
                            render={props => <BeforeLoginHOC component={<GuestComponent />} {...props} />}
                        />
                        <Route path="/firstloginexperience" component={FirstLoginExperience} />
                        <PrivateRoute path="/onboarding" component={OnboardingWrapperComponent} />
                        <PrivateRoute path="/all-todos" component={AllTodosComponent} />
                        <PrivateRoute path="/all-goals" component={AllGoals} />
                        {/* <PrivateRoute path="/your-progress" component={YourProgressPage} /> */}
                        <PrivateRoute path="/app" component={AppWrapper} />
                        <PrivateRoute path="/client-expire" component={ClientExpireComponent} />
                        <Route component={Page404} path="/404-not-found" />
                    </Switch>
                </AppContainer>
            )}
        />
    );
};

export default withRouter(Routes);
