import { connect } from 'react-redux';
import MailerResponse from './MailerResponse';
import mailerSaga from './redux/sagas';
import mailerReducer from './redux/reducer';
import { postMailAnswer } from './redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('mailerReducer');
    return {
        data: REDUCER.data,
        isLoading: REDUCER.isLoading,
    };
};

export { mailerSaga, mailerReducer };

const dispatchToProps = {
    postMailAnswer,
};

export default connect(stateToProps, dispatchToProps)(MailerResponse);
