import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthStore from './common/AuthStore';
import InspireLoader from './components/InspireLoader/InspireLoader';

export default function PrivateRoute({ component: Component, path, ...rest }) {
    const isAuthenticated = AuthStore.accessToken;
    const route = isAuthenticated ? (
        <Route
            path={path}
            render={props => (
                <Suspense fallback={<InspireLoader isLoading />}>
                    <Component {...props} {...rest} />
                </Suspense>
            )}
        />
    ) : (
        <Route render={() => <Redirect to="/" />} />
    );
    return route;
}
