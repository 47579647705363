import React from 'react';
import PropTypes from 'prop-types';
import Actionbutton from '../../components/ActionButton';
import { fontColors, fontFamilies } from '../../common/fontMap';

export default function OrientationAction({ practiceId, changePracticeStatus, article }) {
    const _getCTAText = article => {
        if (article.article_buttons.find(o => o.key === 'start_practice')) {
            return article.article_buttons.find(o => o.key === 'start_practice').text;
        }
        return article.article_buttons[0].text;
    };
    return (
        <div className="action_wrapper">
            <Actionbutton
                fontSize="1.125rem"
                fontFamily={fontFamilies.TheinhardtPanLight}
                fontColor={fontColors.siteWhite}
                bgColor={fontColors.electricBlue}
                disabled={false}
                onClick={() => changePracticeStatus(practiceId, 'start')}
                width="20rem"
            >
                {_getCTAText(article)}
            </Actionbutton>
        </div>
    );
}

OrientationAction.propTypes = {
    practiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    changePracticeStatus: PropTypes.func.isRequired,
    article: PropTypes.object.isRequired,
};
