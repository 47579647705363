/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import '../index.scss';
import QuestionStepper from '../QuestionStepper';
import BigReveal from '../assets/group-4.svg';
import Thanks from '../assets/group-17.svg';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function WeeklyQuestionsComponent({
    open,
    weeklyQuestionsData,
    originalData,
    postAnswer,
    setSeenPercentage,
    questionResponseFromDb,
    closeWeeklyQuestionModal,
    chapterIntro,
    storeAnsweredQuestionResponse,
    setSelectedKey,
    chapterIntroSeen,
    userAdditionalQuestionComment,
    getNextApiInSequence,
}) {
    const [indexToShowOfPage, setIndex] = useState(0);
    const [showWeeklyQuestions, setShowWeeklyQuestion] = useState(false);
    const [weeklyQuestionsData01, setWeeklyResponse01] = useState(false);
    const [questionResponseFromDbState, setquestionResponseFromDbState] = useState([]);
    const [heading, setHeading] = useState(true);
    const [subHeading, setSubHeading] = useState('');
    const [openEndResponse, setOpenEndResponse] = useState('');
    const [responsesInRange, setResponsesInRange] = useState(false);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { question, typeHere, letsBeginSurvey } = localisationsAfterLogin;

    useEffect(() => {
        if (chapterIntro && Object.keys(chapterIntro).length > 0) {
            setShowWeeklyQuestion(false);
        } else {
            setShowWeeklyQuestion(true);
        }
    }, [chapterIntro]);

    useEffect(() => {
        if (weeklyQuestionsData && weeklyQuestionsData.length > 0 && indexToShowOfPage === 0) {
            const data = weeklyQuestionsData.filter(element => element.questionType.includes('_Post')).length > 0;
            setWeeklyResponse01(data);
        }
        const element = document.querySelectorAll('.question_container')[0];
        if (element) {
            element.scrollTop = 0;
        }
    }, [weeklyQuestionsData, indexToShowOfPage]);

    useEffect(() => {
        if (questionResponseFromDb && questionResponseFromDb.length > 0) {
            setquestionResponseFromDbState(questionResponseFromDb);
        }
    }, [questionResponseFromDb]);

    const closeModal = () => {
        setquestionResponseFromDbState([]);
        storeAnsweredQuestionResponse(null);
    };

    const getResponseAsset = () => {
        if (
            questionResponseFromDbState.length > 0
            && questionResponseFromDbState[0].questionType === 'Additional_Question'
        ) {
            return questionResponseFromDbState[0].image;
        }
        return Thanks;
    };

    const postChapterIntroAnswer = () => {
        setShowWeeklyQuestion(true);
        chapterIntroSeen();
        getNextApiInSequence();
    };

    const handleOpenEndResponseChange = e => {
        setOpenEndResponse(e.target.value);
    };

    const handleOpenEndResponseClick = () => {
        const payload = {
            id: questionResponseFromDbState[0].qid,
            response: openEndResponse,
        };
        userAdditionalQuestionComment(payload);
        setquestionResponseFromDbState([]);
        storeAnsweredQuestionResponse(null);
    };

    return (
        <Fragment>
            <Modal
                open={questionResponseFromDbState.length > 0}
                className="weekly_questions_outer weekly_questions_outer_2"
            >
                <div className="wrapper_weekly_ques">
                    <div className="inner_wrapper_weekly_ques centered">
                        <div onClick={closeModal} onKeyDown={() => {}} role="button" tabIndex={0} className="cross">
                            <Cross color="black" />
                        </div>
                        {openEndResponse && (
                            <div
                                onClick={handleOpenEndResponseClick}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={0}
                                className="done"
                            >
                                <span>Done</span>
                            </div>
                        )}
                        <div className="thank_you_note">
                            <div className="image_container">
                                <img src={getResponseAsset()} alt="thanks" />
                            </div>
                            {questionResponseFromDbState.length > 0 && (
                                <div className="thanks_note">
                                    <span>{questionResponseFromDbState[0].header_text}</span>
                                    <span>{questionResponseFromDbState[0].answered_text}</span>
                                    {questionResponseFromDbState[0].isOpenText && (
                                        <div className="open_end_textbox">
                                            <textarea
                                                onChange={handleOpenEndResponseChange}
                                                value={openEndResponse}
                                                placeholder={typeHere}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={open} className="weekly_questions_outer">
                <div className="wrapper_weekly_ques">
                    <div className="inner_wrapper_weekly_ques">
                        <div className="big_reveal_blob">
                            <div className="image_wrapper">
                                {!weeklyQuestionsData01 ? (
                                    <div className="image_text">
                                        {heading && <span>{question}</span>}
                                        <span>{subHeading}</span>
                                    </div>
                                ) : (
                                    <div className="image_text">
                                        <span>{subHeading}</span>
                                    </div>
                                )}
                                <img src={BigReveal} alt="bigreveal" />
                            </div>
                        </div>
                        <div className="weekly_questions_inner">
                            <QuestionStepper
                                weeklyQuestionsData={weeklyQuestionsData}
                                originalData={originalData}
                                postAnswer={postAnswer}
                                setSeenPercentage={setSeenPercentage}
                                indexToShowOfPage={indexToShowOfPage}
                                setIndex={setIndex}
                                closeWeeklyQuestionModal={closeWeeklyQuestionModal}
                                setWeeklyResponse01={setWeeklyResponse01}
                                setHeading={setHeading}
                                setSubHeading={setSubHeading}
                                setSelectedKey={setSelectedKey}
                                getNextApiInSequence={getNextApiInSequence}
                                responsesInRange={responsesInRange}
                                setResponsesInRange={setResponsesInRange}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={!showWeeklyQuestions} className="weekly_questions_outer">
                <div className="wrapper_weekly_ques">
                    {chapterIntro && (
                        <div className="inner_wrapper_weekly_ques">
                            <div className="big_reveal_blob">
                                <div className="image_wrapper">
                                    <div className="image_text">
                                        <span style={{ fontSize: '1.3rem' }}>{chapterIntro.header}</span>
                                    </div>
                                    <img src={BigReveal} alt="bigreveal" />
                                </div>
                            </div>
                            <div className="weekly_questions_inner">
                                <div className="chapter_intro">
                                    <div
                                        className="dangerouslySetInnerHTML_chapter_intro"
                                        dangerouslySetInnerHTML={{ __html: chapterIntro.detail }}
                                    />
                                    <div className="call_to_action_chapter_intro outline-none">
                                        <button type="submit" onClick={postChapterIntroAnswer}>
                                            {letsBeginSurvey}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </Fragment>
    );
}
WeeklyQuestionsComponent.defaultProps = {
    weeklyQuestionsData: [],
    originalData: [],
    chapterIntro: null,
    questionResponseFromDb: '',
};

WeeklyQuestionsComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    weeklyQuestionsData: PropTypes.array,
    originalData: PropTypes.array,
    postAnswer: PropTypes.func.isRequired,
    setSeenPercentage: PropTypes.func.isRequired,
    questionResponseFromDb: PropTypes.string,
    closeWeeklyQuestionModal: PropTypes.func.isRequired,
    chapterIntro: PropTypes.shape({
        detail: PropTypes.string,
        header: PropTypes.string,
        id: PropTypes.number,
    }),
    storeAnsweredQuestionResponse: PropTypes.func.isRequired,
    setSelectedKey: PropTypes.func.isRequired,
    chapterIntroSeen: PropTypes.func.isRequired,
    userAdditionalQuestionComment: PropTypes.func.isRequired,
    getNextApiInSequence: PropTypes.func.isRequired,
};
