import React from 'react'
import './index.scss'

const Stepper = ({ state, current, articleId, onClick }) => {
    const getClass = () => {
        if (current) {
            return 'current'
        }
        switch (state) {
            case 1:
                return 'grey'
            case 2:
                return 'filled'
            default:
                return 'current'
        }
    }
    const handleStepperClick = () => {
        if (state === 2 && !current) {
            onClick(articleId, true);
        }
    };
    return <div className={`stepper ${getClass()}`} onClick={handleStepperClick} />
}

export default Stepper
