import React from 'react';
import PropTypes from 'prop-types';

import TextAnswer from './TextAnswer';
import RadioBtn from './RadioBtn';
import './index.scss';

const Questionnaire = ({ type, question, setNameQuestionAns, setTimeQuestionAns }) => {
    const getQuestionType = () => {
        switch (type) {
            case 'text':
                return <TextAnswer setNameQuestionAns={setNameQuestionAns} />;
            case 'objective':
                return <RadioBtn question={question} setTimeQuestionAns={setTimeQuestionAns} />;
            default:
                return null;
        }
    };
    return <div className="questionnaire">{getQuestionType()}</div>;
};
Questionnaire.propTypes = {
    type: PropTypes.string.isRequired,
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        sequence: PropTypes.number.isRequired,
        question: PropTypes.number.isRequired,
        question_detail: PropTypes.object.isRequired,
        response: PropTypes.object.isRequired,
        roadblock: PropTypes.object.isRequired,
    }).isRequired,
    setNameQuestionAns: PropTypes.func.isRequired,
    setTimeQuestionAns: PropTypes.func.isRequired,
};

export default Questionnaire;
