import { fontColors, fontFamilies } from '../../common/fontMap';
import { PracticeStateType } from './PromptDetails';

export const getTitleColor = article => {
    let value = '';
    switch (article.state) {
        case PracticeStateType.lockedOrNew:
            value = fontColors.textBlack;
            break;

        case PracticeStateType.unlockedOrNext:
            value = fontColors.electricBlue;
            break;

        case PracticeStateType.read:
            value = fontColors.textBlack;
            break;

        case PracticeStateType.completed:
            value = fontColors.textBlack;
            break;
        default:
            break;
    }
    return value;
};

export const getFontWeight = article => {
    if (article.state === PracticeStateType.unlockedOrNext) {
        return 800;
    }
    return 400;
};

export const getFontFamily = article => {
    if ([PracticeStateType.unlockedOrNext, PracticeStateType.lockedOrNew].includes(article.state)) {
        return fontFamilies.TheinhardtPanRegular;
    }
    return fontFamilies.TheinhardtPanLight;
};

export const getIconColor = article => {
    let value = '';
    switch (article.state) {
        case PracticeStateType.lockedOrNew:
            value = fontColors.nuetralGrey;
            break;

        case PracticeStateType.unlockedOrNext:
            value = fontColors.nuetralGrey;
            break;

        case PracticeStateType.read:
            value = fontColors.nuetralGrey;
            break;

        case PracticeStateType.completed:
            value = fontColors.forestGreen;
            break;
        default:
            break;
    }
    return value;
};
