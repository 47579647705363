import { all } from 'redux-saga/effects';
import { unAuthSagas } from '../containers/UnAuthenticatedContent';
import commonSagas from '../common/sagas';
import { CreateProfileSaga } from '../containers/Profile';
import { FeedsSaga } from '../containers/Feeds';
import { tagUserSaga } from '../components/TagUser';
import { weeklyQuestionsSaga } from '../containers/WeeklyQuestions';
import { NotificationFeedsSaga } from '../containers/NotificationFeeds';
import { myProgressSaga } from '../containers/MyProgress';
import { preAdditionalQuestionsSaga } from '../containers/PreAdditionalQuestion';
import { FeedFiltersSaga } from '../containers/FeedFilters';
import { userActivitySaga } from '../containers/UserActivity';
import { userMatrixSaga } from '../containers/UserMatrix';
import { unsubscribeUserSaga } from '../containers/Unsubscribe';
import { notificationsListSaga } from '../containers/NotificationCenter';
import { FeedbackQuestionSaga } from '../containers/SubmitIdeaContainer';
import { mailerSaga } from '../containers/MailerResponse';
import { weeklyReplayMailerSaga } from '../components/WeeklyReplayMailer';
import { faqSaga } from '../components/FAQs';
import { emailLanguageSelectionSaga } from '../containers/LanguageSelection';
import { PracticesSaga } from '../containers/PracticesContainer';
import { HomeSagas } from '../containers/HomeContainer';
import { PracticeDetailSaga } from '../containers/PracticesDetailsContainer';
import { HubSagas } from '../containers/HubContainer';

export default function* rootSaga() {
    yield all([
        ...unAuthSagas,
        ...commonSagas,
        ...CreateProfileSaga,
        ...FeedsSaga,
        ...tagUserSaga,
        ...weeklyQuestionsSaga,
        ...NotificationFeedsSaga,
        ...myProgressSaga,
        ...preAdditionalQuestionsSaga,
        ...FeedFiltersSaga,
        ...userActivitySaga,
        ...userMatrixSaga,
        ...unsubscribeUserSaga,
        ...notificationsListSaga,
        ...FeedbackQuestionSaga,
        ...mailerSaga,
        ...weeklyReplayMailerSaga,
        ...faqSaga,
        ...emailLanguageSelectionSaga,
        ...PracticesSaga,
        ...HomeSagas,
        ...PracticeDetailSaga,
        ...HubSagas,
    ]);
}
