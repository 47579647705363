import { call, put, takeLatest } from 'redux-saga/effects';
import { LOGOUT, GET_OPEN_LINK_TRANSLATIONS } from '../utils/constants';
import { fetchLogoutApi, getOpenLinkTranslationsApi } from './apis';
import { logoutBackendFailure } from './actions';
import LocalisationsStore from './LocalisationsStore';

function* logoutSaga() {
    try {
        yield call(fetchLogoutApi);
    } catch (e) {
        yield put(logoutBackendFailure(e));
    }
}

export function* getOpenLinkTranslationsSaga({ baseUrl, email }) {
    const { data: { data } } = yield call(getOpenLinkTranslationsApi, baseUrl, email);
    yield (LocalisationsStore.localisationsAfterLogin = JSON.stringify(data));
}

const sagas = [takeLatest(LOGOUT, logoutSaga), takeLatest(GET_OPEN_LINK_TRANSLATIONS, getOpenLinkTranslationsSaga)];
export default sagas;
