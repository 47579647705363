const types = {
    UPLOAD_IMAGE_PENDING: 'UPLOAD_IMAGE_PENDING',
    UPLOAD_IMAGE_DONE: 'UPLOAD_IMAGE_DONE',
    GET_QUESTIONS: 'GET_QUESTIONS',
    STORE_QUESTIONS: 'STORE_QUESTIONS',
    TOGGLE_POP_UP: 'TOGGLE_POP_UP',
    POST_ANSWERS_PENDING: 'POST_ANSWERS_PENDING',
    POST_ANSWERS_DONE: 'POST_ANSWERS_DONE',
    GET_VCRB_PENDING: 'GET_VCRB_PENDING',
    GET_VCRB_SUCCESS: 'GET_VCRB_SUCCESS',
    GET_VCRB_FAILED: 'GET_VCRB_FAILED',
    GET_NOTES_PENDING: 'GET_NOTES_PENDING',
    GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
    GET_NOTES_FAILED: 'GET_NOTES_FAILED',
    ADD_NOTES_PENDING: 'ADD_NOTES_PENDING',
    ADD_NOTES_SUCCESS: 'ADD_NOTES_SUCCESS',
    ADD_NOTES_FAILED: 'ADD_NOTES_FAILED',
};

export default types;
