import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.png';
import './index.scss';
import calendar from '../../assets/calendar.svg';
import time from '../../assets/time.svg';
import TimePicker from '../../components/TimePicker';
import SetReminderDone from './SetReminderDone';
import { getArticleInfo, setReminderApi } from '../UnAuthenticatedContent/apis';
import Loader from '../../components/Loader';
import EmailCard from '../../components/EmailCard';

const SetReminder = () => {
    const current = new Date();
    const today = new Date(current.setDate(current.getDate() + 1)).toISOString().split('T')[0];
    const [reminderDate, setReminderDate] = useState(today);
    const [isLoading, setIsLoading] = useState(true);
    const [articleDetails, setArticleDetails] = useState({ localization: {} });
    const onChangeReminderDate = event => {
        setReminderDate(event.target.value);
    };

    const [isReminderSet, setReminder] = useState(false);

    const [isTimeOpen, setIsTimeOpen] = useState(false);
    const [reminderTime, setReminderTime] = useState({
        hr: '12',
        min: '00',
        format: 'AM',
    });
    const onChangeReminderTime = (event, value, type) => {
        event.stopPropagation();
        setReminderTime({ ...reminderTime, [type]: value });
    };

    useEffect(() => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const baseUrl = url.searchParams.get('base_url');
        const tagId = url.searchParams.get('tag_id');
        const cta = url.searchParams.get('cta');
        setIsLoading(true);
        getArticleInfo({ baseUrl, tagId, cta }).then(response => {
            setIsLoading(false);
            const { data, localization } = response.data.data;
            setArticleDetails({
                practice_name: data.practice_name,
                article_title: data.article_title,
                notification_time: data.notification_time,
                is_reminder_set: data.is_reminder_set,
                is_article_completed: data.is_article_completed,
                localization,
            });
            const _isReminderSet = data.is_reminder_set || data.is_article_completed;
            setReminder(_isReminderSet);
        }).catch(() => {
            setIsLoading(false);
            setArticleDetails({ localization: {} });
            // history.push('/404-not-found');
        });
    }, []);

    const onSetReminder = date => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const baseUrl = url.searchParams.get('base_url');
        const tagId = url.searchParams.get('tag_id');
        const cta = url.searchParams.get('cta');
        setIsLoading(true);
        setReminderApi({ baseUrl, tagId, cta, date }).then(response => {
            setIsLoading(false);
            const _data = response.data.data.data;
            setArticleDetails(
                {
                    ...articleDetails,
                    notification_time: _data.notification_time,
                    is_reminder_set: true,
                    is_article_completed: false,
                },
            );
            setReminder(true);
        }).catch(() => {
            setReminder(true);
            setIsLoading(false);
            setArticleDetails({ localization: {} });
            // history.push('/404-not-found');
        });
    };


    const actionCallBack = params => {
        let _hr = reminderTime.hr;
        if (reminderTime.format === 'PM') {
            _hr = 12 + parseInt(_hr, 10);
            if (_hr === 24) {
                _hr = '12';
            }
        } else if (parseInt(_hr, 10) === 12) {
            _hr = '00';
        }
        onSetReminder(`${params.reminderDate}T${_hr}:${reminderTime.min}:00`);
    };
    const renderChildren = () => (
        <div className="reminder-dateTime">
            <div className="date">
                <span className="display-date">{moment(reminderDate).format('MM/DD/YYYY')}</span>
                <img src={calendar} alt="Set Date" className="calendar-icon" />
                <input
                    required
                    onKeyDown={e => e.preventDefault()}
                    onChange={event => {
                        onChangeReminderDate(event);
                    }}
                    className="date-field"
                    id="reminderDate"
                    name="reminderDate"
                    type="date"
                    defaultValue={reminderDate}
                    min={today}
                />
            </div>
            <div className="time">
                <img
                    src={time}
                    alt="Set Time"
                    className="time-icon"
                    onClick={() => {
                        setIsTimeOpen(!isTimeOpen);
                    }}
                />
                {isTimeOpen && (
                    <TimePicker
                        onChange={onChangeReminderTime}
                        time={reminderTime}
                        isTimeOpen={isTimeOpen}
                        setIsTimeOpen={setIsTimeOpen}
                    />
                )}
                {`${reminderTime.hr} : ${reminderTime.min} ${reminderTime.format} `}
            </div>
        </div>
    );
    const { setReminder: _setReminder, selectDateTime, continue: _continue } = articleDetails.localization;
    return (
        <div className="set-reminder">
            {isLoading ? <Loader position="absolute" showBackground /> : (
                <div className="header">
                    <div className="logo">
                        <img src={logo} alt="Inspire" />
                    </div>
                    <div className="content">
                        <div className="label">{_setReminder}</div>
                        {isReminderSet ? <SetReminderDone articleDetails={articleDetails} /> : (
                            <div className="reminder-content">
                                <div className="subtitle">
                                    {selectDateTime}
                                </div>
                                <div className="card-details">
                                    <EmailCard title={articleDetails.practice_name} subtitle={articleDetails.article_title} />
                                </div>
                                <div className="date-time reminder-modal">
                                    {renderChildren()}
                                </div>

                                <div className="action" onClick={() => { actionCallBack({ reminderDate, x: 1 }); }}>
                                    {_continue}
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            )}
        </div>
    );
};

export default SetReminder;

SetReminder.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
