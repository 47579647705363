import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UnsubscribeComponent from './UnsubscribeComponent';
import unsubscribeUserSaga from './redux/sagas';
import unsubscribeReducer from './redux/reducer';
import { unsubscribeUser } from './redux/actions';

const stateToProps = state => {
    const UnsubscribeReducer = state.get('unsubscribeReducer');
    return {
        isInsubscribed: UnsubscribeReducer.isInsubscribed,
        alreadyUnsubscribed: UnsubscribeReducer.alreadyUnsubscribed,
    };
};

export { unsubscribeUserSaga, unsubscribeReducer };

const dispatchToProps = {
    unsubscribeUser,
};

export default withRouter(connect(stateToProps, dispatchToProps)(UnsubscribeComponent));
