import parseJwt from '../utils/parseJwt';

class AuthStore {
    constructor() {
        if (!AuthStore.instance) {
            this._data = localStorage;
            AuthStore.instance = this;
        }

        return AuthStore.instance;
    }

    set accessToken(value) {
        this._data.setItem('accessToken', value);
    }

    get accessToken() {
        return this._data.getItem('accessToken');
    }

    deleteccessToken() {
        this._data.removeItem('accessToken');
        this._data.removeItem('addedToCalendar');
    }

    set refreshToken(value) {
        this._data.setItem('refresh_token', value);
    }

    get refreshToken() {
        return this._data.getItem('refresh_token');
    }

    set baseUrl(value) {
        const parsedData = parseJwt(this._data.accessToken);
        this._data.setItem('baseURL', parsedData[value]);
    }

    get baseUrl() {
        return this._data.getItem('baseURL');
    }

    set expTime(value) {
        const parsedData = parseJwt(this._data.accessToken);
        this._data.setItem('exp_time', parsedData[value] * 1000);
    }

    get expTime() {
        return this._data.getItem('exp_time');
    }

    clear() {
        this._data.clear();
        return true;
    }
}

const instance = new AuthStore();
Object.freeze(instance);

export default instance;
