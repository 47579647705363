/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import { isMobile } from '../../utils/constants';

const MentionSuggestionsComponentDropDown = props => {
    const { mention, ...parentProps } = props;
    return (
        <div {...{ ...parentProps, className: `${parentProps.className} suggestion` }}>
            <div className="profile_picture">
                <img src={mention.profile_picture} alt="Profile" />
            </div>
            <div className="user-details">
                <div
                    className="mentioned_user-name"
                    data-userid={mention.id}
                >
                    {mention.preferred_name}
                </div>
                <div
                    className="mentioned_user-email"
                    data-userid={mention.id}
                >
                    {mention.email}
                </div>
            </div>

        </div>
    );
};

export default class TagUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: props.value ? EditorState.createWithText(props.value) : EditorState.createEmpty(),
            taggedUsers: [],
        };

        this.emojiPlugin = createEmojiPlugin();

        this.mentionPlugin = createMentionPlugin({
            mentions: [],
            mentionComponent: mentionProps => (
                <span className="mentioned_user" data-userid={mentionProps.mention.id}>
                    {mentionProps.mention.preferred_name}
                </span>
            ),
        });

        this.linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
    }


    createEmptyState = () => {
        this.setState({
            editorState: EditorState.createEmpty(),
        });
    }

    onChange = editorState => {
        const { onChange, itemid } = this.props;
        const stateToSend = editorState.getCurrentContent().getPlainText();

        onChange(stateToSend, itemid);
        this.setState({
            editorState,
        });
    }

    onSearchChange = ({ value }) => {
        const { getUsersList } = this.props;
        if (value.length > 0) getUsersList(value);
    }

    focus = () => {
        this.editor.focus();
    }

    onAddMention = user => {
        const { userAddMentions, storeQueriedUsers } = this.props;
        const { taggedUsers } = this.state;
        const usersCopy = [...taggedUsers];
        usersCopy.push(user.id.toString());
        this.setState({ taggedUsers: usersCopy }, () => {
            userAddMentions(usersCopy);
        });
        storeQueriedUsers([]);
    }

    render() {
        const { MentionSuggestions } = this.mentionPlugin;
        const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;
        const plugins = [this.mentionPlugin, this.emojiPlugin, this.linkifyPlugin];
        const { editorState } = this.state;
        const { users, placeholder, className } = this.props;

        return (
            <Fragment>
                {className.length > 0 && (
                    <svg width="0" height="0" viewBox="0 0 34 35">
                        <clipPath id="comment_bubble">
                            <path
                                transform="translate(1 1)"
                                id="prefix__a"
                                d="M9.481.976C3.65 3.796-1.709 12.622.514 21.058c1.794 6.8 16.926 17.357 25.331 8.021 12.12-13.46 3.363-20.606-1.094-24.26C21.402 2.076 15.312-1.844 9.48.977z"
                            />
                        </clipPath>
                    </svg>
                )}
                <div className={`editor ${className}`} onClick={this.focus} role="none">
                    <Editor
                        id="user_tag_editor"
                        editorState={editorState}
                        onChange={this.onChange}
                        plugins={plugins}
                        ref={element => {
                            this.editor = element;
                        }}
                        placeholder={placeholder}
                    />
                    <div className="suggestions_container">
                        <MentionSuggestions
                            onSearchChange={this.onSearchChange}
                            suggestions={users}
                            mentionPrefix="@"
                            onAddMention={this.onAddMention}
                            entryComponent={MentionSuggestionsComponentDropDown}
                            popoverContainer={({ children }) => <div>{children}</div>}
                        />
                    </div>
                    {!isMobile && (
                        <Fragment>
                            <EmojiSuggestions />
                            <div className="emojis_selector">
                                <EmojiSelect />
                            </div>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

TagUser.defaultProps = {
    users: [],
    itemid: null,
    className: '',
    value: '',
};

TagUser.propTypes = {
    getUsersList: PropTypes.func.isRequired,
    storeQueriedUsers: PropTypes.func.isRequired,
    userAddMentions: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    users: PropTypes.array,
    itemid: PropTypes.number,
    className: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    value: PropTypes.string,
};
