import createReducer from '../../../utils/redux-utils/createReducer';
import types from '../../Feeds/redux/types';

const INITIAL_STATE = {
    globalLoader: false,
};

const appReducer = createReducer(INITIAL_STATE, {
    [types.SET_GLOBAL_LOADING]: (state, { payload }) => ({
        ...state,
        globalLoader: payload,
    }),
});

export default appReducer;
