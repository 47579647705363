/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import App from './App';
import store from './store';

// import { messaging } from './init-fcm';
import isPrivateMode from './checkPrivateMode';

import theme from './theme';
import './styles/main.scss';

// messaging.onMessage(payload => {
//     console.log('Message received. ', payload);
//     if (payload && payload.data) {
//         const { data: { screen, id } } = payload;
//         switch (screen) {
//             case 'comment_details': {
//                 console.log('comment details');
//                 window.open(`${window.location.origin}/feed-details-social/${id}`, '_blank');
//                 break;
//             }
//             case 'article_details': {
//                 window.open(`${window.location.origin}/feed-details-article/${id}`, '_blank');
//                 break;
//             }
//             default:
//         }
//     }
// });

isPrivateMode().then(isPrivate => {
    if (isPrivate) {
        ReactDOM.render(<h1>Not supported in private mode</h1>, document.getElementById('root'));
    } else {
        ReactDOM.render(
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline>
                        <App />
                    </CssBaseline>
                </ThemeProvider>
            </Provider>,
            document.getElementById('root'),
        );

        if ('serviceWorker' in navigator) {
            const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
            navigator.serviceWorker
                .register(swUrl)
                .then(registration => {
                    console.log('Registration successful, scope is:', registration.scope);
                })
                .catch(err => {
                    console.log('Service worker registration failed, error:', err);
                });
        }
    }
});
