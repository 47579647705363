import styled from 'styled-components';

const ClippedImage = styled.img`
    background-size: auto;
    background-repeat: no-repeat;
    background-color: ${props => props.bgColor || 'transparent'};
    width: ${props => props.width};
    height: ${props => props.height};
    clip-path: ${props => (props.clipid ? `url(#${props.clipid})` : 'url(#myPath)')};
    opacity: ${props => props.opacity || 1};
    margin: ${props => props.margin || '0'};
`;

export default ClippedImage;
