/* eslint-disable eol-last */
import createReducer from '../utils/redux-utils/createReducer';
import * as types from '../containers/HomeContainer/redux/types';
import * as commonType from '../utils/constants';

const INITIAL_STATE = {
    refreshTodo: 0,
    refreshGoals: 0,
    clientExpired: false,
};

const RefreshReducer = createReducer(INITIAL_STATE, {
    [`${types.REFRESH_GOALS}`]: state => ({
        ...state,
        refreshGoals: state.refreshGoals + 1,
        clientExpired: false,
    }),

    [`${types.REFRESH_TODO}`]: state => ({
        ...state,
        refreshTodo: state.refreshTodo + 1,
        clientExpired: false,
    }),

    [`${commonType.CLIENT_EXPIRED}`]: state => ({
        ...state,
        clientExpired: true,
    }),

    [`${commonType.RESET_CLIENT_EXPIRED}`]: state => ({
        ...state,
        clientExpired: false,
    }),
});

export default RefreshReducer;
