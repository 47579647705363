import { put, takeLatest } from 'redux-saga/effects';
import * as types from './types';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import {
    apiFailed,
    clientExpiredAction,
    resetClientExpiredAction,
} from '../../../common/actions';
import { getPracticesInProgressApi, getPracticesCompletedApi, getPracticesNewApi } from './apis';
import {
    storeInProgressPractices,
    storeCompletedPractices,
    storeNewPractices,
    closeLoader,
    browseMoreFetched,
    getNewPractices,
} from './actions';
import { CODE_CLIENT_EXPIRED } from '../../../utils/constants';

function* getPracticesInProgress({ page }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPracticesInProgressApi,
            payload: [AuthStore.accessToken, page],
        });
        // if (data.message !== 'Invalid page.') {
        yield put(resetClientExpiredAction());
        yield put(storeInProgressPractices(data));
        //  }
        yield put(closeLoader());
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getInPrgAndNewPractices({ page }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPracticesInProgressApi,
            payload: [AuthStore.accessToken, page],
        });
        yield put(resetClientExpiredAction());
        yield put(storeInProgressPractices(data));
        yield put(getNewPractices(page));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getBrowseAllPractices({ page }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPracticesInProgressApi,
            payload: [AuthStore.accessToken, page],
        });
        yield put(browseMoreFetched(data));
        yield put(closeLoader());
    } catch (e) {
        yield put(apiFailed());
    }
}

function* getPracticesCompleted({ page }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPracticesCompletedApi,
            payload: [AuthStore.accessToken, page],
        });
        // if (data.message !== 'Invalid page.') {
        yield put(storeCompletedPractices(data));
        // }
        yield put(closeLoader());
    } catch (e) {
        yield put(apiFailed());
    }
}

function* getPracticesNew({ page }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPracticesNewApi,
            payload: [AuthStore.accessToken, page],
        });
        // if (data.message !== 'Invalid page.') {
        yield put(storeNewPractices(data, page));
        // }
        yield put(closeLoader());
    } catch (e) {
        yield put(apiFailed());
    }
}
const PracticesSaga = [
    takeLatest(`${types.GET_PRACTICES_IN_PROGRESS}_PENDING`, getPracticesInProgress),
    takeLatest(`${types.GET_PRACTICES_COMPLETED}_PENDING`, getPracticesCompleted),
    takeLatest(`${types.GET_PRACTICES_NEW}_PENDING`, getPracticesNew),
    takeLatest(`${types.BROWSE_ALL}_PENDING`, getBrowseAllPractices),
    takeLatest(`${types.GET_PRACTICES_INPROGRESS_NEW}_PENDING`, getInPrgAndNewPractices),
];

export default PracticesSaga;
