/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import InspireLoader from '../../components/InspireLoader/InspireLoader';

export default function MailerResponse({ postMailAnswer, isLoading, data }) {
    useEffect(() => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const baseurl = window.atob(url.searchParams.get('baseurl'));
        const id = url.searchParams.get('quiz_id');
        const response = url.searchParams.get('response');
        const email = url.searchParams.get('email');

        postMailAnswer({ baseurl, id, response, email });
    }, [postMailAnswer]);

    return (
        <>
            {isLoading && (
                <div style={{ height: '100vh', width: '100vw' }}>
                    <InspireLoader isLoading />
                </div>
            )}
            {!isLoading && data && (
                <div className="mailer">
                    <img alt="" src={data.response_img} />
                    <div className="success">{data.response_title}</div>
                    <div className="msg" dangerouslySetInnerHTML={{ __html: data.response_content }} />
                </div>
            )}
        </>
    );
}

MailerResponse.propTypes = {
    postMailAnswer: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
        response_title: PropTypes.string,
        response_content: PropTypes.string,
        response_img: PropTypes.string,
    }).isRequired,
};
