import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import star from '../../assets/start.svg';
import CtaArrow from '../CtaArrow';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function PracticesCard({ index, name, thumbnail, is_featured, bg_color, onClick }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { feature, viewPractice } = localisationsAfterLogin;

    return (
        <div
            className="practice_card_container"
            style={{ backgroundColor: bg_color }}
            key={`in-progress-card-${index}`}
            onClick={onClick}
        >
            <div className="left_panel">
                <div className="top_texts">
                    <Text
                        fontsize="1.25rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.playfairBold}
                        cursor="pointer"
                    >
                        {name}
                    </Text>
                    {is_featured && (
                        <div className="featured pointer">
                            <img src={star} alt="featured" />
                            <Text
                                fontsize="11px"
                                fontcolor={fontColors.textBlack}
                                fontfamily={fontFamilies.TheinhardtPanRegular}
                                margin="0 0 0 0.5rem"
                                cursor="pointer"
                                letterSpacing="0.75px"
                                lineHeight="unset"
                            >
                                {feature}
                            </Text>
                        </div>
                    )}
                </div>
                <div className="view_practice_wrapper">
                    <Text
                        fontsize="0.875rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0.5rem 0 0"
                        letterSpacing="1px"
                        textTransform="uppercase"
                        cursor="pointer"
                    >
                        {viewPractice}
                    </Text>
                    <CtaArrow color={fontColors.siteWhite} />
                </div>
            </div>
            <div className="right_image">
                <img src={thumbnail} alt="thm" />
            </div>
        </div>
    );
}

PracticesCard.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    is_featured: PropTypes.bool.isRequired,
    bg_color: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
