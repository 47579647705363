import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Info from '@material-ui/icons/Info';
import moment from 'moment';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import './index.scss';
import Loader from '../../components/Loader';
import LogoutImage from '../../assets/logout.svg';
import ClippedImage from '../../components/ClippedImage';
import CtaArrow from '../../components/CtaArrow';
import rArrow from '../../assets/rArrow.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import AuthStore from '../../common/AuthStore';
import { postWebApi, putDashboardWebApi } from '../../webapis/dashboard';
import Endpoints from '../../Endpoints';
import logut from '../../assets/logut.svg';
import intersection from '../../assets/intersection.svg';
import PreferencesModal from '../PreferencesModal';
import { LightTooltip } from '../MyProgress/MyProgressComponent/ChartProgress';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import { pageAnalytics } from '../../utils/functions';
import WarningModal from '../../components/WarningModal';
import Actionbutton from '../../components/ActionButton';
import AntSwitch from '../../components/AntSwitch';
import ProgressSnapshot from './ProgressSnapshot';
import { pageAnalyticsApi } from '../Feeds/redux/apis';
import CoachMarks from '../PracticesContainer/CoachMarks';
import { COACH_MARKS_TYPES } from '../../utils/constants';

const emailSubscribeUpdateApi = body => {
    const apiBasePath = AuthStore.baseUrl;
    const { PROFILE_EMAIL_SUBS } = Endpoints;
    const url = `${apiBasePath + PROFILE_EMAIL_SUBS}`;
    return putDashboardWebApi(AuthStore.accessToken, url, body).request;
};

const resetProfile = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { RESET_PROFILE } = Endpoints;
    const url = `${apiBasePath + RESET_PROFILE}`;
    return postWebApi(AuthStore.accessToken, url).request;
};

export default function TabProfileComponent({
    logoutUser,
    history,
    resetUserDetails,
    getStatus,
    isEmailSubscribe,
    smrfLink,
    clientExpired,
    isFetched,
}) {
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [emailToggle, setToggle] = useState(JSON.parse(localStorage.userProfile).email_unsubscribed);
    const [showPreferences, setShowPreferencesModal] = useState(false);
    const [showCheckEmailModal, setShowCheckEmailModal] = useState(false);
    const [openLogoutConfirm, toggleLogoutConfirm] = useState(false);
    const [openResetConfirm, toggleResetConfirm] = useState(false);
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    const hideProfileProgress = localStorage.getItem('hide_progress_snapshot') === 'true';
    const userProfileOrder = (localStorage.getItem('user_profile_order') || '').split(',');
    const [showCoachMarks, setShowCoachMarks] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 740));
    const fromProgressSnapshotHome = isMobile && location?.state?.from === 'progress_snapshot_home';
    const [showProgressModal, setShowProgressModal] = useState(fromProgressSnapshotHome);
    const hideProfileProgressDesktop = hideProfileProgress || (userProfileOrder && !userProfileOrder.includes('your_progress'));

    useEffect(() => {
        const _state = location?.state;
        if (_state && _state?.from === 'progress_snapshot_home') {
            delete _state.from;
            history.replace({ ...history.location, state: _state });
        }
    }, [location, history]);

    useEffect(() => {
        const handleCheckMobile = () => {
            if (window.innerWidth <= 740) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleCheckMobile);

        return (() => window.addEventListener('resize', handleCheckMobile));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isMobile) {
            setShowProgressModal(false);
        }
    }, [isMobile]);

    useEffect(() => {
        pageAnalytics.initialise('profile');
        return () => {
            pageAnalytics.complete('profile');
        };
    }, []);

    useEffect(() => {
        getStatus(localStorage.getItem('fcmToken'));
    }, [getStatus]);

    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        privacyPolicylink,
        taclink,
        resetProfileMessage,
        resetProfile: resetProfileText,
        logout,
        youAreAboutToLogoutWeb,
        emailHint,
        CheckYourEmail,
        changePassDescription,
        ok,
        edit,
        FAQs,
        contactUs,
        termsCondition,
        privacyPolicy,
        cancel,
        emails,
        LogoutcalltoAction,
        smrf_dashboard,
        myProgress,
        how_to_use_accelerator,
    } = localisationsAfterLogin;

    const stopUserSession = async () => {
        const sessionObj = window.sessionStorage.getItem('pageAnalytics');
        const currentPage = JSON.parse(sessionObj);
        const userSessionObj = currentPage?.userSession;
        const pageObject = {
            ...userSessionObj,
            end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        };
        await pageAnalyticsApi([pageObject]);
        delete currentPage.userSession;
        window.sessionStorage.setItem('pageAnalytics', JSON.stringify(currentPage));
    };

    const handleProfileOptionClick = key => {
        if (key === 'logout') {
            stopUserSession();
            logoutUser(history);
        } else if (key === 'contactUs') {
            window.open(`mailto:${localStorage.support_email}`, '_self');
        } else if (key === 'tnc') {
            window.open(taclink);
        } else if (key === 'smrfLink') {
            window.open(smrfLink);
        } else if (key === 'pp') {
            window.open(privacyPolicylink);
        } else if (key === 'faq') {
            window.open(`https://${process.env.REACT_APP_API_BASEPATH}/faqs`, '_blank');
        } else if (key === 'resetProfile') {
            resetProfile();
            AuthStore.deleteccessToken();
            history.push('/');
        } else if (key === 'editProfile') {
            setShowPreferencesModal(true);
        } else if (key === 'yourProgress') {
            document.getElementsByClassName('your_progress_wrapper')[0].style.display = 'flex';
            document.querySelectorAll('.element.yourProgress')[0].style.display = 'none';
            document.getElementsByClassName('your_profile_wrapper')[0].style.display = 'none';
        }
    };

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };

    const handleChange = value => {
        emailSubscribeUpdateApi({ unsubscribe: !value.target.checked });
        const profile = JSON.parse(localStorage.userProfile);
        profile.email_unsubscribed = !value.target.checked;
        localStorage.setItem('userProfile', JSON.stringify(profile));
        setToggle(!value.target.checked);
    };

    const showYourProgress = () => {
        setShowProgressModal(true);
    };

    const handleCloseProgressModal = () => {
        setShowProgressModal(false);
    };

    useEffect(() => {
        if (isFetched) {
            setIsLoading(false);
        }
    }, [isFetched]);

    const optionsData = {
        your_progress: {
            label: myProgress,
            onClick: showYourProgress,
            show: (!hideProfileProgress && isMobile),
            key: 'your_progress',
        },
        email: {
            label: emails,
            onClick: handleChange,
            show: !isEmailSubscribe,
            key: 'email',
        },
        how_to_use_accelerator: {
            label: how_to_use_accelerator,
            onClick: () => setShowCoachMarks(true),
            show: isClientPG,
            key: 'how_to_use_accelerator',
        },
        faq: {
            label: FAQs,
            onClick: () => handleProfileOptionClick('faq'),
            show: true,
            key: 'faq',
        },
        contact_us: {
            label: contactUs,
            onClick: () => handleProfileOptionClick('contactUs'),
            show: true,
            key: 'contact_us',
        },
        tc: {
            label: termsCondition,
            onClick: () => handleProfileOptionClick('tnc'),
            show: true,
            key: 'tc',
        },
        pp: {
            label: privacyPolicy,
            onClick: () => handleProfileOptionClick('pp'),
            show: true,
            key: 'pp',
        },
        reset_your_profile: {
            label: resetProfileText,
            onClick: () => toggleResetConfirm(true),
            show: resetUserDetails,
            key: 'reset_your_profile',
        },
        smrf: {
            label: smrf_dashboard,
            onClick: () => handleProfileOptionClick('smrfLink'),
            show: smrfLink,
            key: 'smrf',
        },
    };

    return (
        <>
            {clientExpired && moveToClientExpirePage() }
            {isLoading && <Loader position="fixed" showBackground />}

            {showProgressModal && (
                <>
                    <div className="mobile-mask-progress-snapshot" />
                    <WarningModal classNames="mobile-progress-modal" isFullScreen>
                        <div className="mobile-progress-snapshot-wrapper">
                            <ProgressSnapshot hasBackButton onBackClick={handleCloseProgressModal} />
                        </div>
                    </WarningModal>
                </>
            )}
            {openLogoutConfirm && (
                <WarningModal classNames="logout-modal" isFullScreen={isMobile} hasTopSpacing={isMobile}>
                    <div className="logoutmodal">
                        <div
                            onClick={() => toggleLogoutConfirm(false)}
                            role="button"
                            tabIndex={0}
                            className="logout-close"
                        >
                            <Cross color="black" />
                        </div>

                        <div className="logout-content">
                            <div className="logout-img">
                                <img src={LogoutImage} alt="logout" />
                            </div>
                            <div className="logout-title">{logout}</div>
                            <div className="logout-text">
                                <span>{youAreAboutToLogoutWeb}</span>
                            </div>
                            <div className="logout-buttons">
                                <Actionbutton
                                    fontSize="1.125rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.siteWhite}
                                    bgColor={fontColors.electricBlue}
                                    disabled={false}
                                    onClick={() => handleProfileOptionClick('logout')}
                                    width="13rem"
                                    height="48px"
                                >
                                    {logout}
                                </Actionbutton>
                            </div>
                        </div>
                    </div>
                </WarningModal>
            )}

            {openResetConfirm && (
                <WarningModal classNames="logout-modal">
                    <h2>
                        {resetProfileText}
                        ?
                    </h2>
                    <div className="content">{resetProfileMessage}</div>
                    <div className="buttonContent">
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.grey}
                            disabled={false}
                            onClick={() => toggleResetConfirm(false)}
                            width="8rem"
                            margin="0 1.2rem"
                        >
                            {cancel}
                        </Actionbutton>

                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            onClick={() => handleProfileOptionClick('resetProfile')}
                            width="8rem"
                        >
                            {ok}
                        </Actionbutton>
                    </div>
                </WarningModal>
            )}
            <div
                className={`tab_profile_wrapper${hideProfileProgressDesktop ? ' hide-progress' : ''}`}
            >
                {showCheckEmailModal && (
                <WarningModal>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '2rem 1.5rem',
                        }}
                    >
                        <Text
                            fontsize="1.5rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                        >
                            {CheckYourEmail}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                            margin="1.25rem 0"
                        >
                            {changePassDescription}
                        </Text>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            onClick={() => setShowCheckEmailModal(false)}
                            width="5rem"
                        >
                            {ok}
                        </Actionbutton>
                    </div>
                </WarningModal>
                )}
                <PreferencesModal
                    showPreferences={showPreferences}
                    setShowPreferencesModal={setShowPreferencesModal}
                    setShowCheckEmailModal={setShowCheckEmailModal}
                    setIsLoading={setIsLoading}
                />
                {(!hideProfileProgressDesktop && !isMobile) && (
                    <>
                        <div className="space-adjuster" />
                        <div className="your-progress-snapshot">
                            <ProgressSnapshot showLoader={!isLoading} />
                        </div>
                    </>
                )}
                <div
                    className={`your_profile_wrapper${hideProfileProgressDesktop ? ' hide-progress' : ''}`}
                >
                    <div className="main_section">
                        <div className="name_and_picture_part">
                            <img src={intersection} alt="" />
                            <div className="profile_picture">
                                <svg
                                    width="0"
                                    height="0"
                                    viewBox="0 0 80 80"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <clipPath id="dpBlob">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M16.8813 2.76493C1.85833 9.53345 -3.48079 35.3815 2.24932 55.6253C6.86908 71.9464 50.3202 93.8503 67.7207 68.3083C88.3546 38.0197 79.2042 18.3301 67.7207 9.56193C59.0949 2.97575 31.9042 -4.00359 16.8813 2.76493Z"
                                            fill="none"
                                        />
                                    </clipPath>
                                </svg>
                                <div style={{
                                    backgroundSize: 'auto',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#F5F5F8',
                                    padding: '10px 0 0 0',
                                    clipPath: 'url(#dpBlob)',
                                    margin: '0 2rem',
                                }}
                                >
                                    <ClippedImage
                                        src={localStorage.profile_picture}
                                        alt=""
                                        width="5.0rem"
                                        height="4.35rem"
                                    />
                                </div>
                            </div>
                            <div className="profile_info_wrapper">
                                <Text
                                    fontsize="20px"
                                    lineHeight="24px"
                                    letterSpacing="-0.2px"
                                    fontweight={700}
                                    fontcolor={fontColors.siteWhite}
                                    fontfamily={fontFamilies.playfairBold}
                                    margin="0 0 10px 0"
                                    whiteSpace="pre-wrap"
                                >
                                    {localStorage.preferred_name || localStorage.full_name}
                                </Text>
                                <Text
                                    fontsize="14px"
                                    lineHeight="20px"
                                    letterSpacing="0.5px"
                                    fontcolor={fontColors.siteWhite}
                                    fontfamily={fontFamilies.MckinseySansRegular}
                                    fontweight={400}
                                    margin="0 0 30px 0"
                                >
                                    {JSON.parse(localStorage.userProfile).email}
                                </Text>
                                <div className="element" onClick={() => handleProfileOptionClick('editProfile')}>
                                    <Text
                                        fontsize="16px"
                                        lineHeight="22px"
                                        letterSpacing="0.5px"
                                        fontcolor={fontColors.siteWhite}
                                        fontfamily={fontFamilies.MckinseySansMedium}
                                        fontweight={500}
                                        margin="0 8px 0 0"
                                        cursor="pointer"
                                    >
                                        {edit}
                                    </Text>
                                    <CtaArrow color={fontColors.siteWhite} />
                                </div>
                            </div>
                        </div>
                        <div className="options_part">
                            {
                                userProfileOrder?.map(key => {
                                    const option = optionsData[key];
                                    if (option?.show && option?.key !== 'email') {
                                        return (
                                            <div className="element" onClick={option.onClick} key={option.key}>
                                                <Text
                                                    fontsize="18px"
                                                    lineHeight="24px"
                                                    letterSpacing="0.1px"
                                                    fontweight={400}
                                                    fontcolor={fontColors.black}
                                                    fontfamily={fontFamilies.MckinseySansRegular}
                                                    margin="0 0.5rem 0 0"
                                                    cursor="pointer"
                                                >
                                                    {option.label}
                                                </Text>
                                                <span className="cta">
                                                    <img src={rArrow} alt="" />
                                                </span>
                                            </div>
                                        );
                                    } if (option?.show) {
                                        return (
                                            <div className="element" key={option.key}>
                                                <span>
                                                    <Text
                                                        fontsize="18px"
                                                        lineHeight="24px"
                                                        letterSpacing="0.1px"
                                                        fontweight={400}
                                                        fontcolor={fontColors.black}
                                                        fontfamily={fontFamilies.MckinseySansRegular}
                                                        margin="0 0.5rem 0 0"
                                                        cursor="pointer"
                                                    >
                                                        {emails}
                                                    </Text>
                                                    <LightTooltip title={emailHint} enterTouchDelay={5}>
                                                        <Info
                                                            htmlColor={fontColors.borderGrey}
                                                            style={{
                                                                width: '21px',
                                                                height: '21px',
                                                            }}
                                                        />
                                                    </LightTooltip>
                                                </span>
                                                <AntSwitch checked={!emailToggle} onChange={handleChange} name="checkedC" />
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            }

                            <div className="logut" onClick={() => toggleLogoutConfirm(true)}>
                                <img src={logut} alt="" />
                                <Text
                                    fontsize="16px"
                                    lineHeight="22px"
                                    letterSpacing="0.5px"
                                    fontweight={500}
                                    fontcolor={fontColors.electricBlue}
                                    fontfamily={fontFamilies.MckinseySansMedium}
                                    cursor="pointer"
                                >
                                    {LogoutcalltoAction}
                                </Text>
                            </div>
                            {showCoachMarks && <CoachMarks setShowCoachMarks={setShowCoachMarks} type={COACH_MARKS_TYPES.PROFILE_VIDEO_ACCELETOR} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

TabProfileComponent.defaultProps = {
    isEmailSubscribe: false,
    smrfLink: null,
};

TabProfileComponent.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    resetUserDetails: PropTypes.bool.isRequired,
    isEmailSubscribe: PropTypes.bool,
    smrfLink: PropTypes.string,
    getStatus: PropTypes.func.isRequired,
    clientExpired: PropTypes.bool.isRequired,
    isFetched: PropTypes.bool.isRequired,
};
