/* eslint-disable no-param-reassign */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Cross } from '../../../UnAuthenticatedContent/assets/svg';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import Loader from '../../../../components/Loader';

export default function CoachingJourneyComponent({
    coachingJourney,
    getCoachinJourneyData,
    storeCoachingJorneyData,
    loading,
    pageAnalytics,
    coachingJourneyStartTime,
}) {
    const [allChapters, setAllChapters] = useState([]);
    const [expanded, setExpanded] = useState('panel1');
    const [chapterQuery, setChapterQuery] = useState('');

    const [timeLineSearchStartTime, setTimeLineSearchStartTime] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [timeLineSearchEndTime, setTimeLineSearchEndTime] = useState('');

    const { localisationsAfterLogin } = LocalisationsStore;
    const { searchIntervention, noInterventionsFound } = localisationsAfterLogin;

    const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';

    const reverseDirectionLanguage = localStorage.getItem('languageIsRightToLeft') === 'true';

    useEffect(() => {
        const allChaptersCopy = coachingJourney.map((chapter, index) => {
            chapter.panelName = `panel${index + 1}`;
            return chapter;
        });
        setAllChapters(allChaptersCopy);
    }, [coachingJourney]);

    useEffect(
        () => () => {
            setAllChapters([]);
            storeCoachingJorneyData([]);
        },
        [setAllChapters, storeCoachingJorneyData],
    );

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchQueryChange = e => {
        setChapterQuery(e.target.value);
        getCoachinJourneyData({ queryString: e.target.value });
    };

    const handleCrossClick = () => {
        setChapterQuery('');
        getCoachinJourneyData({ queryString: '' });
    };

    const handleOnFocus = () => {
        const date = new Date();
        setTimeLineSearchStartTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
        pageAnalytics({
            page_tag: 'timeLineHome',
            device_id: localStorage.fcmToken,
            start_time: coachingJourneyStartTime || moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            end_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            user: userProfile,
        });
    };

    const handleOnFocusOut = () => {
        const date = new Date();
        coachingJourneyStartTime = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
        setTimeLineSearchEndTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
        pageAnalytics({
            page_tag: 'timeLineSearch',
            device_id: localStorage.fcmToken,
            start_time: timeLineSearchStartTime,
            end_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            user: userProfile,
        });
    };

    return (
        <div
            className={
                reverseDirectionLanguage ? 'coaching_journey_chapters reverse_direction' : 'coaching_journey_chapters'
            }
        >
            <div className="search_bar">
                <input
                    className="outline-none"
                    type="text"
                    value={chapterQuery}
                    onChange={handleSearchQueryChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnFocusOut}
                    placeholder={`${searchIntervention}...`}
                />
                {chapterQuery.length > 0 && (
                    <div onClick={handleCrossClick}>
                        <Cross color="black" />
                    </div>
                )}
            </div>
            {loading && <Loader position="relative" background="transparent" />}
            {!loading && allChapters.length === 0 && (
                <div className="no_chapter_interventions">
                    <span>{noInterventionsFound}</span>
                    <div className="empty_inspire_icon" />
                </div>
            )}
            {!loading && chapterQuery.length === 0 && (
                <Fragment>
                    {allChapters.map(chapter => (
                        <Accordion
                            expanded={expanded === chapter.panelName}
                            onChange={handleChange(chapter.panelName)}
                            square={false}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <div>{chapter.name}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="chapter_summary">
                                    {chapter.article_list.map((article, index) => (
                                        <Link to={`feed-details-article/${article.id}`}>
                                            <div className="article_in_chapter">
                                                <span>{article.publish_date}</span>
                                                <div className="chapter_seen_unseen_circle_wrapper">
                                                    <div className="chapter_seen_unseen_circle">
                                                        {!article.is_new ? (
                                                            <div className="tick_circle" />
                                                        ) : (
                                                            <div className="circle" />
                                                        )}
                                                    </div>
                                                    {chapter.article_list.length > 1
                                                        && index < chapter.article_list.length - 1 && (
                                                            <div className="vertical_connector_line" />
                                                    )}
                                                </div>
                                                <span>
                                                    {article.title.length > 35
                                                        ? `${article.title.substring(0, 35)}...`
                                                        : article.title}
                                                </span>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Fragment>
            )}
            {!loading && chapterQuery.length > 0 && (
                <div className="search_chapter_article_container">
                    {allChapters.map(chapter => (
                        <div className="search_chapter_article">
                            <span>{chapter.name}</span>
                            {chapter.article_list.map(article => (
                                <Link to={`feed-details-article/${article.id}`}>
                                    <div className="saved_post_card">
                                        <img
                                            className="post_image"
                                            src={article.thumbnail_image}
                                            alt="thumbnail_image"
                                        />
                                        <div className="saved_post_details">
                                            <span className="post_title">{article.title}</span>
                                            <span className="post_date">{article.publish_date}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const chapter = PropTypes.shape({
    article_list: PropTypes.arrayOf({
        id: PropTypes.number,
        is_new: PropTypes.bool,
        publish_date: PropTypes.string,
        thumbnail_image: PropTypes.string,
        title: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
});

CoachingJourneyComponent.propTypes = {
    coachingJourney: PropTypes.arrayOf(chapter).isRequired,
    getCoachinJourneyData: PropTypes.func.isRequired,
    storeCoachingJorneyData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageAnalytics: PropTypes.func.isRequired,
    coachingJourneyStartTime: PropTypes.string.isRequired,
};
