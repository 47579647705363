import React from 'react';
import './index.scss';
import { Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import loaderInspire from './loaderInspire.gif';

const InspireLoader = ({ isLoading }) => (
    <Modal open={isLoading} className="loader_modal">
        <img id="loaderImage" src={loaderInspire} alt="" />
    </Modal>
);

InspireLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};
export default InspireLoader;
