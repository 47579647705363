import types from './types';

export const getUserActivity = () => ({
    type: `${types.GET_USER_ACTIVITY_DATA}_PENDING`,
});

export const storeUserActivity = data => ({
    type: `${types.GET_USER_ACTIVITY_DATA}_DONE`,
    data,
});
