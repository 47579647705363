import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import LikeDetailModal from './LikeDetailModal';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function LikeSection({
    feedItem,
    isLiked,
    feedType,
    postLike,
    getLikeDetails,
    likeDetails,
    isLoading,
    followedId,
    postFollowUser,
    postUnfollowUser,
    getUsersFollowed,
    followedUsers,
    setFollowUnfollowInFeeds,
    selectedHashTag,
    selectedKey,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { like, likes } = localisationsAfterLogin;
    const [getLikes, setGetLikes] = useState(false);
    const [feedLiked, setFeedLiked] = useState(feedItem.is_liked);
    const [feedLikedCount, setFeedLikedCount] = useState(feedItem.like_count);
    const [likeDetailsWithFollowed, setLikeDetailsWithFollowed] = useState([]);

    const likePost = articleId => {
        postLike({ articleId, selectedHashTag, selectedKey });
        if (!feedLiked) {
            setFeedLiked(true);
            setFeedLikedCount(feedLikedCount => feedLikedCount + 1);
        } else {
            setFeedLiked(false);
            setFeedLikedCount(feedLikedCount => feedLikedCount - 1);
        }
    };

    const getDetails = articleId => {
        if (feedLikedCount > 0) {
            setGetLikes(true);
            getLikeDetails({ articleId });
            getUsersFollowed();
        }
    };
    const handleClose = () => {
        setGetLikes(false);
    };

    useEffect(() => {
        setFeedLikedCount(feedItem.like_count);
        if (isLiked) {
            setFeedLiked(true);
        } else {
            setFeedLiked(false);
        }
    }, [isLiked, feedItem.like_count]);

    useEffect(() => {
        const likeDetailsWithFollowed = likeDetails.map(user => ({
            ...user,
            is_following: followedUsers.some(followedUser => followedUser.following_to === user.liked_by),
        }));
        setLikeDetailsWithFollowed(likeDetailsWithFollowed);
    }, [followedUsers, likeDetails]);

    return (
        <Fragment>
            <div
                className={`outline-none ${feedLiked ? ' liked' : 'likeBtn '}`}
                onClick={() => likePost(feedItem.id)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
            />
            <div
                className={`likeText outline-none cursor-pointer ${feedType === 'post' ? 'textDivison' : ''}`}
                onClick={() => getDetails(feedItem.id)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={-1}
            >
                {`${feedLikedCount} ${' '} ${feedLikedCount <= 1 ? like : likes}`}
            </div>

            {feedItem.type === 'social' && <div className="separator" />}

            {getLikes && (
                <LikeDetailModal
                    open={getLikes}
                    handleClose={handleClose}
                    likeDetails={likeDetailsWithFollowed}
                    isLoading={isLoading}
                    followedId={followedId}
                    postFollowUser={postFollowUser}
                    postUnfollowUser={postUnfollowUser}
                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                />
            )}
        </Fragment>
    );
}

LikeSection.defaultProps = {
    selectedKey: Number(localStorage.getItem('selectedMenu')),
    selectedHashTag: '',
    followedId: 0,
};

LikeSection.propTypes = {
    feedItem: PropTypes.shape({
        is_liked: PropTypes.bool.isRequired,
        like_count: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        is_following: PropTypes.bool.isRequired,
        user: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    isLiked: PropTypes.bool.isRequired,
    feedType: PropTypes.string.isRequired,
    postLike: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    followedId: PropTypes.number,
    postFollowUser: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    selectedHashTag: PropTypes.string,
    selectedKey: PropTypes.number,
};
