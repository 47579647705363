import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ToDos from './index';
import { getRecommendedPrompts, getTodos, resetGoalTab } from '../HomeContainer/redux/actions';
import { getUserMatrix } from '../UserMatrix/redux/actions';
import { getTags, uploadPost } from '../Feeds/redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('HomeReducer');
    const FREDUCER = state.get('feedsReducer');
    const APPREDUCER = state.get('appReducer');
    // const AuthenticationReducer = state.get('authentication').toJS();
    return {
        isLoading: REDUCER.isLoading,
        todos: REDUCER.todos,
        uploadingPost: FREDUCER.uploadingPost,
        tags: FREDUCER.tags,
        globalLoader: APPREDUCER.globalLoader,
        // hideSections: AuthenticationReducer.hide_sections,
    };
};

const dispatchToProps = { getTodos, uploadPost, getTags, getRecommendedPrompts, getUserMatrix, resetGoalTab };

export default withRouter(connect(stateToProps, dispatchToProps)(ToDos));
