import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ProfilePic from './ProfilePic';
import ProfileName from './ProfileName';
import LocalisationsStore from '../../../common/LocalisationsStore';
import TagUser from '../../../components/TagUser';

export default function Comment({
    comment,
    index,
    showReplies,
    handleClose,
    handleClick,
    likeThisComment,
    _thisUser,
    anchorEl,
    commenColor,
    replyToThisComment,
    articleindex,
    isParent,
    getlikedBy,
    postComment,
    setReplyOpen,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { deletePostAction, reply, replyToComment } = localisationsAfterLogin;
    const [replyComment, setReplyComment] = useState(false);
    const [replyContent, setReplyContent] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [selectedUsers, setSelecteduser] = useState([]);

    const _checkCommentIsLiked = comment => {
        if (!isParent) {
            return comment.comment_liked_by.filter(user => user.id === _thisUser).length > 0;
        }
        return comment.is_liked;
    };

    return (
        <Fragment>
            <div className="comment" key={`${comment.id}-comment-id`}>
                <ProfilePic profilePic={comment.commented_by.profile_picture} />
                <ProfileName
                    name={comment.commented_by.full_name}
                    text={comment.text}
                    commentLikedBy={comment.comment_liked_by.length}
                    createdAt={comment.created_at}
                    replies={comment.replies_count}
                    showReplies={showReplies}
                    index={index}
                    commenColor={commenColor}
                    setReplyOpen={setReplyOpen}
                    setReplyComment={setReplyComment}
                    replyComment={replyComment}
                    replyToThisComment={replyToThisComment}
                    thisCommentId={comment.id}
                    articleId={comment.article}
                    articleindex={articleindex}
                    isParent={isParent}
                    parentCommentId={comment.parent_comment}
                    getlikedBy={getlikedBy}
                />

                <div
                    role="none"
                    className={`${_checkCommentIsLiked(comment) ? 'cannot_like' : 'can_like'}${
                        _thisUser === comment.commented_by.id ? '' : ' like_width_adjust'
                    }`}
                    onClick={likeThisComment}
                    data-commid={comment.id}
                    data-articleid={comment.article}
                />
                {_thisUser === comment.commented_by.id && [
                    <div role="none" onClick={handleClick} data-commid={comment.id} className="comment_action">
                        <div
                            role="none"
                            onClick={handleClose}
                            data-commid={comment.id}
                            data-articleid={comment.article}
                            data-replycount={comment.replies_count}
                            className={`action_btn ${anchorEl === comment.id ? 'show_comments_action' : ''}`}
                        >
                            {deletePostAction}
                        </div>
                    </div>,
                ]}
            </div>
            {replyComment && (
            <div className="reply comment">
                <ProfilePic profilePic={comment.commented_by.profile_picture} />
                <SetReply
                    replyContent={replyContent}
                    setReplyContent={setReplyContent}
                    setReplyComment={setReplyComment}
                    postComment={postComment}
                    articleId={comment.article}
                    parentCommentId={comment.id}
                    setReplyOpen={setReplyOpen}
                    setSelecteduser={setSelecteduser}
                    selectedUsers={selectedUsers}
                    reply={reply}
                    replyToComment={replyToComment}
                />
            </div>
            )}
        </Fragment>
    );
}

const SetReply = ({ replyContent, setReplyContent, postComment, articleId, parentCommentId, setReplyComment, setReplyOpen, setSelecteduser, reply, replyToComment, selectedUsers }) => (
    <div className="reply-content">
        <TagUser onChange={setReplyContent} userAddMentions={setSelecteduser} placeholder={replyToComment} value={replyContent} />
        {replyContent.trim() && (
            <div
                className="reply-button"
                onClick={() => {
                    setReplyOpen(true);
                    setReplyContent('');
                    setReplyComment(false);
                    postComment(articleId, replyContent, selectedUsers, parentCommentId);
                }}
            >
                {reply.toUpperCase()}
            </div>
        )}
    </div>
);
Comment.defaultProps = {
    showReplies: () => {},
    anchorEl: 0,
    commenColor: '',
};

Comment.propTypes = {
    comment: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showReplies: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    likeThisComment: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    setReplyOpen: PropTypes.func.isRequired,
    _thisUser: PropTypes.number.isRequired,
    anchorEl: PropTypes.number,
    commenColor: PropTypes.string,
    replyToThisComment: PropTypes.func.isRequired,
    articleindex: PropTypes.number.isRequired,
    isParent: PropTypes.bool.isRequired,
    getlikedBy: PropTypes.func.isRequired,
};
SetReply.propTypes = {
    replyContent: PropTypes.string.isRequired,
    setReplyContent: PropTypes.func.isRequired,
    setSelecteduser: PropTypes.func.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    setReplyComment: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    setReplyOpen: PropTypes.func.isRequired,
    articleId: PropTypes.number.isRequired,
    reply: PropTypes.string.isRequired,
    replyToComment: PropTypes.string.isRequired,
    parentCommentId: PropTypes.number.isRequired,
};
