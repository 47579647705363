import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Actionbutton from '../../components/ActionButton';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import tick from '../../assets/tick.svg';
import LocalisationsStore from '../../common/LocalisationsStore';

const TickAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    img {
        height: 1rem;
        width: 1rem;
        background-color: green;
        border-radius: 50%;
        margin-right: 0.5rem;
        padding: 3px;
    }
    & > div:nth-child(2) {
        width: max-content;
    }
`;

export default function CompleteAction({
    article,
    changePracticeStatus,
    practiceId,
    type,
    setShowCreatePost,
    setShowPrompt,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { completed, markAsInComplete } = localisationsAfterLogin;
    const hideHubTab = localStorage.getItem('is_hide_social_post') === 'true';

    const handleSHareClick = e => {
        e.stopPropagation();
        setShowCreatePost(true);
        setShowPrompt(false);
    };

    return (
        <div className="action_wrapper">
            <TickAndTextWrapper>
                <img src={tick} alt="completed practice" />
                <Text fontsize="1rem" fontcolor={fontColors.textBlack} fontfamily={fontFamilies.TheinhardtPanRegular}>
                    {completed}
                    {' '}
                    {moment(article.completed_on).format('MM/DD/yyyy')}
                </Text>
                {type !== 'orientation' && !article.is_survey_article && article.cta_layout === 'standard' && (
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanRegular}
                        decoration="underline"
                        cursor="pointer"
                        onClick={() => changePracticeStatus(practiceId, 'incomplete')}
                        margin="0 0 0 1rem"
                    >
                        {markAsInComplete}
                    </Text>
                )}
            </TickAndTextWrapper>
            {/*  {!article.is_survey_article && ( */}
            {article.cta_layout === 'standard' && !hideHubTab && (
                <Actionbutton
                    fontSize="1.125rem"
                    fontFamily={fontFamilies.TheinhardtPanRegular}
                    fontColor={fontColors.siteWhite}
                    bgColor={fontColors.electricBlue}
                    disabled={false}
                    width="18rem"
                    onClick={handleSHareClick}
                >
                    {article.article_buttons.find(elem => elem.key === 'share_post').text}
                </Actionbutton>
            )}
            {/*  )} */}
        </div>
    );
}

CompleteAction.defaultProps = {
    type: '',
};

CompleteAction.propTypes = {
    article: PropTypes.object.isRequired,
    changePracticeStatus: PropTypes.func.isRequired,
    practiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.string,
    setShowCreatePost: PropTypes.func.isRequired,
    setShowPrompt: PropTypes.func.isRequired,
};
