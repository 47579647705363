/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import './index.scss';
import BottomScrollListener from 'react-bottom-scroll-listener';
import PropTypes from 'prop-types';
import moment from 'moment';
import Lottie from '../../components/Lottie';
import IllustrationFeed from './IllustrationFeed';
import Posts from './Posts';
import { validURL } from '../../utils/functions';
import CommentsForArticle from './CommentsForArticle';
import TagUser from '../../components/TagUser';
import EmptyState from './assets/Emptystate.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import GRADIENTS, { BANNERS_LINKS_MAPPING } from './constants';
import LikeDetailModal from './LikeDetailModal';
import TextPostGradientBox from '../../components/TextPostGradientBox';
import TextPostDescription from '../../components/TextPostDescription';

const FeedsList = ({
    feedsList,
    getFeedsListMore,
    allFeedFetched,
    postLike,
    likedId,
    isLoading,
    showInterventionPage,
    setShowInterventionPage,
    isDeleted,
    deletePost,
    postSavedPosts,
    savedPostId,
    likeDetails,
    followedId,
    getLikeDetails,
    postFollowUser,
    showCommentsForThisArticle,
    articleComments,
    postComment,
    likeComment,
    deleteComment,
    clickedArticle,
    isDeleting,
    reportPost,
    handleClickOnHashTag,
    selectedHashTag,
    setPage,
    page,
    postUnfollowUser,
    getUsersFollowed,
    followedUsers,
    setFollowUnfollowInFeeds,
    fetchUsersList,
    userListForSharePost,
    sharePost,
    fetchMoreUsersList,
    allUsersFetched,
    selectedKey,
    removeBookMark,
    handleOpen,
    setSelectedKey,
    bannerOpened,
    removeIsNewTagFromLocalState,
    articleIndexStartTimeTracker,
    articleIndexEndTimeTracker,
    userScrollAnalytics,
    storeNumberOfNewPostsCount,
    storeNumberOfNewPostsCountSocialGroup,
    badgeOfNewerPostsCount,
    badgeOfNewerPostsCountSocialGroup,
    history,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { addAComment, noFilterRecord, socialNorecords, stayTunned } = localisationsAfterLogin;
    const newTag = localisationsAfterLogin.new;

    const [commentValue, setCommentValue] = useState('');
    const [articleId, setArticleId] = useState(null);
    const [selectedUsers, setSelecteduser] = useState([]);
    const [replyCommentProps, setReplyProps] = useState({
        parentcommentid: '',
        articleid: '',
    });
    const [getLikes, setGetLikes] = useState(false);
    const [likeDetailsWithFollowed, setLikeDetailsWithFollowed] = useState([]);

    const inputRefs = [];

    const deviceId = localStorage.getItem('fcmToken');

    useEffect(() => {
        const likeDetailsWithFollowed = likeDetails.map(user => ({
            ...user,
            is_following: followedUsers.some(followedUser => followedUser.following_to === user.liked_by),
        }));
        setLikeDetailsWithFollowed(likeDetailsWithFollowed);
    }, [followedUsers, likeDetails]);

    useEffect(() => {
        const imgs = document.images;
        const len = imgs.length;
        let counter = 0;
        function incrementCounter() {
            // eslint-disable-next-line no-plusplus
            counter++;
            if (counter === len) {
                const elements = document.querySelectorAll('.feed_image_post_container');
                elements.forEach(node => {
                    if (node.offsetWidth !== node.firstElementChild.offsetWidth) {
                        node.classList.add('black_bg');
                        node.firstElementChild.classList.add('no_border_radius');
                    }
                });
            }
        }

        [].forEach.call(imgs, img => {
            if (img.complete) incrementCounter();
            else img.addEventListener('load', incrementCounter, false);
        });
    }, [feedsList, articleIndexStartTimeTracker, articleIndexEndTimeTracker, removeIsNewTagFromLocalState]);

    useEffect(() => {
        const logPercentageSeen = () => {
            const elements = document.querySelectorAll('.feedsCardScaling');
            const eles = Array.prototype.slice.call(elements);
            const viewportHeight = window.innerHeight;
            // eslint-disable-next-line consistent-return
            eles.forEach(element => {
                const { dataset: { feeditemid } } = element;
                const feedItem = feedsList.filter(item => item.id === Number(element.dataset.feeditemid))[0];

                // if (!feedItem.endTime) {
                const scrollTop = window.scrollY;
                const elementOffsetTop = element.offsetTop;
                const elementHeight = element.offsetHeight;
                const hiddenBefore = scrollTop - elementOffsetTop;
                const hiddenAfter = elementOffsetTop + elementHeight - (scrollTop + viewportHeight);
                if (scrollTop > elementOffsetTop + elementHeight || elementOffsetTop > scrollTop + viewportHeight) {
                    return 0;
                }
                let result = 100;
                if (hiddenBefore > 0) {
                    result -= Math.round((hiddenBefore * 100) / elementHeight);
                    if (result === 30) {
                        const date = new Date();
                        const time = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
                        articleIndexStartTimeTracker({
                            startTime: time,
                            feeditemid,
                        });
                    }
                    if (result >= 70 && result <= 100) {
                        const date = new Date();
                        const time = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
                        articleIndexEndTimeTracker({
                            endTime: time,
                            feeditemid,
                        });
                        userScrollAnalytics({
                            article: Number(feedItem.id),
                            start_time: feedItem.startTime || null,
                            end_time: time || null,
                            device_id: deviceId,
                        });
                        if (feedItem.is_new) {
                            if (selectedKey === 1) {
                                storeNumberOfNewPostsCount(badgeOfNewerPostsCount - 1);
                            } else if (selectedKey === 4) {
                                storeNumberOfNewPostsCountSocialGroup(badgeOfNewerPostsCountSocialGroup - 1);
                            }
                        }
                        removeIsNewTagFromLocalState(feeditemid);
                    }
                }

                if (hiddenAfter > 0) {
                    result -= Math.round((hiddenAfter * 100) / elementHeight);
                    if (result === 30) {
                        const date = new Date();
                        const time = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
                        articleIndexStartTimeTracker({
                            startTime: time,
                            feeditemid,
                        });
                    }
                    if (result >= 70 && result <= 100) {
                        const date = new Date();
                        const time = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
                        articleIndexEndTimeTracker({
                            endTime: time,
                            feeditemid,
                        });
                        userScrollAnalytics({
                            article: Number(feedItem.id),
                            start_time: feedItem.startTime || null,
                            end_time: time || null,
                            device_id: deviceId,
                        });
                        if (feedItem.is_new) {
                            if (selectedKey === 1) {
                                storeNumberOfNewPostsCount(badgeOfNewerPostsCount - 1);
                            } else if (selectedKey === 4) {
                                storeNumberOfNewPostsCountSocialGroup(badgeOfNewerPostsCountSocialGroup - 1);
                            }
                        }
                        removeIsNewTagFromLocalState(feeditemid);
                    }
                }
            });
        };
        window.addEventListener('scroll', logPercentageSeen);
        return () => {
            window.removeEventListener('scroll', logPercentageSeen);
        };
    }, [
        feedsList,
        removeIsNewTagFromLocalState,
        articleIndexStartTimeTracker,
        articleIndexEndTimeTracker,
        userScrollAnalytics,
        deviceId,
        storeNumberOfNewPostsCount,
        storeNumberOfNewPostsCountSocialGroup,
        badgeOfNewerPostsCount,
        badgeOfNewerPostsCountSocialGroup,
        selectedKey,
    ]);

    const scrollCallback = () => {
        setPage(page + 1);
        if (!allFeedFetched) {
            getFeedsListMore(page + 1, selectedKey, selectedHashTag);
        }
    };

    const handleClose = () => {
        setGetLikes(false);
    };

    const handleChange = (value, itemId) => {
        setCommentValue(value);
        setArticleId(Number(itemId));
    };

    const handleClick = e => {
        const { currentTarget: { dataset: { itemid } } } = e;

        const parrentid = Number(replyCommentProps.parentcommentid);
        const articleid = Number(replyCommentProps.articleid);

        if (replyCommentProps.parentcommentid) {
            postComment(articleid, commentValue, selectedUsers, parrentid);
            setReplyProps({
                parentcommentid: '',
                articleid: '',
            });
        } else {
            postComment(itemid, commentValue, selectedUsers);
        }
        setCommentValue('');

        inputRefs.forEach(ed => {
            if (ed.editor) {
                ed.createEmptyState();
            }
        });
    };

    function showTransitionAnimation(fileSrc, showInterventionPage, clickedArticle, id) {
        if (showInterventionPage && clickedArticle === id) {
            return fileSrc.TRANSITION;
        }
        return fileSrc.CLOSED;
    }

    const urlify = text => {
        // eslint-disable-next-line no-useless-escape
        const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const stringList = text.split(' ');
        return stringList
            .map(element => element.replace(urlRegex, url => `<a data-test="link-created" href="http://${url}">${url}</a>`))
            .join(' ');
    };

    const handleUrlClick = e => {
        e.preventDefault();
        if (e.target.href) {
            window.open(`${e.target.href}`, '_blank');
        }
    };

    const handlePostClick = e => {
        const { currentTarget: { dataset: { feeditemtype, feeditemid } } } = e;
        if (feeditemtype === 'article') {
            history.push(`/feed-details-article/${feeditemid}`);
        }
    };

    const renderMedia = ({ fileType, fileSrc, videoUrl, id, description, config, type }) => {
        // eslint-disable-next-line dot-notation
        if (!fileType && fileSrc && fileSrc['CLOSED']) {
            return (
                <Lottie
                    className="outer_animation_container"
                    borderRadius={20}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: showTransitionAnimation(fileSrc, showInterventionPage, clickedArticle, id),
                    }}
                />
            );
        }
        if (!fileType) {
            return (
                <div
                    className="feed_image_post_container"
                    onClick={handlePostClick}
                    data-feeditemtype={type}
                    data-feeditemid={id}
                >
                    <img src={fileSrc} alt="alt" className="postImage" />
                </div>
            );
        }
        switch (fileType.toUpperCase()) {
            case 'TEXT':
                if (config) {
                    return (
                        <div className="feed_text_post_container">
                            <TextPostGradientBox gradient={GRADIENTS[config.index].value}>
                                <TextPostDescription
                                    fontFamily={config.fontFamily}
                                    dangerouslySetInnerHTML={{ __html: urlify(description) }}
                                    onClick={handleUrlClick}
                                />
                            </TextPostGradientBox>
                        </div>
                    );
                }
                return (
                    <div className="feed_image_post_container">
                        <img src={fileSrc} alt="alt" className="postImage" />
                    </div>
                );
            case 'IMAGE':
                return (
                    <div className="feed_image_post_container">
                        {!validURL(fileSrc) ? (
                            <Lottie
                                className="outer_animation_container"
                                borderRadius={20}
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: showTransitionAnimation(
                                        fileSrc,
                                        showInterventionPage,
                                        clickedArticle,
                                        id,
                                    ),
                                }}
                            />
                        ) : (
                            <img src={fileSrc} alt="alt" className="postImage" />
                        )}
                    </div>
                );
            case 'VIDEO': {
                return (
                    <div>
                        <video src={videoUrl} controls muted playsInline className="video">
                            <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                        </video>
                    </div>
                );
            }
            case 'AUDIO':
                return (
                    <div>
                        <audio src={fileSrc} className="illustration">
                            <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                        </audio>
                    </div>
                );
            default:
                return <img src={fileSrc} alt="alt" className="illustration" />;
        }
    };

    const likeThisComment = e => {
        const { currentTarget: { dataset: { commid, articleid } } } = e;
        likeComment(commid, articleid);
    };

    const getlikedBy = e => {
        const { currentTarget: { dataset: { commentid } } } = e;
        getLikeDetails({ commentId: commentid });
        setGetLikes(true);
        getUsersFollowed();
    };

    const setRef = ref => {
        inputRefs.push(ref);
    };

    const setFocus = articleindex => {
        inputRefs[Number(articleindex)].editor.focus();
    };

    const bannerClickHandler = e => {
        const { currentTarget: { dataset: { linkurl, id } } } = e;
        const link = BANNERS_LINKS_MAPPING[linkurl];
        bannerOpened(id);

        if (link === 'CREATE POST') {
            handleOpen();
        } else if (link === 'Set Goals') {
            setSelectedKey(3);
        } else if (typeof link !== 'undefined' && link !== 'External link') {
            window.open(linkurl, '_blank');
        } else if (linkurl !== '#' && linkurl.length > 0) {
            window.open(linkurl, '_blank');
        }
    };

    const renderFeedCard = (item, index) => {
        if (item.file_type === 'banner') {
            return (
                <div
                    ref={setRef}
                    className="banners_card"
                    data-id={item.id}
                    data-linkurl={item.link_url}
                    key={`${item.id}-feeditem-banners_card-card`}
                    onClick={bannerClickHandler}
                >
                    {item.title !== '' && (
                        <div className={item.banner_link === '' ? 'banners_card_title' : 'banners_card_title notitle'}>
                            {item.title}
                        </div>
                    )}
                    {item.banner_link !== '' && (
                        <img
                            src={item.banner_link}
                            alt="banner_image"
                            className={item.title === '' ? 'banners_card_image image_notitle' : 'banners_card_image'}
                        />
                    )}
                </div>
            );
        }
        if (
            ((!validURL(item.file) && item.file_type !== 'TEXT') || (item.file && item.file.OPEN === undefined))
            && item.type === 'article'
        ) {
            return (
                <div className="illustrationScaling" key={`${item.id}-feeditem-article-card`}>
                    {item.is_new && <div className="new_label">{newTag}</div>}
                    <IllustrationFeed
                        feedItem={item}
                        renderMedia={renderMedia}
                        isLiked={item.is_liked}
                        likedId={likedId}
                        postLike={postLike}
                        isLoading={isLoading}
                        postSavedPosts={postSavedPosts}
                        savedPostId={savedPostId}
                        likeDetails={likeDetails}
                        getLikeDetails={getLikeDetails}
                        setShowInterventionPage={setShowInterventionPage}
                        showInterventionPage={showInterventionPage}
                        clickedArticle={clickedArticle}
                        ref={setRef}
                        postUnfollowUser={postUnfollowUser}
                        getUsersFollowed={getUsersFollowed}
                        followedUsers={followedUsers}
                        setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                        postFollowUser={postFollowUser}
                        fetchUsersList={fetchUsersList}
                        userListForSharePost={userListForSharePost}
                        sharePost={sharePost}
                        fetchMoreUsersList={fetchMoreUsersList}
                        allUsersFetched={allUsersFetched}
                        removeBookMark={removeBookMark}
                        selectedHashTag={selectedHashTag}
                        selectedKey={selectedKey}
                    />
                </div>
            );
        }
        return (
            <div
                className="feedsCardScaling"
                key={`${item.id}-feeditem-social-card`}
                data-feeditemid={item.id}
                data-feeditemindex={index}
                data-isnew={item.is_new}
            >
                <Posts
                    feedItem={item}
                    renderMedia={renderMedia}
                    isLiked={item.is_liked}
                    likedId={likedId}
                    postLike={postLike}
                    isLoading={isLoading}
                    isDeleted={isDeleted}
                    deletePost={deletePost}
                    likeDetails={likeDetails}
                    followedId={followedId}
                    getLikeDetails={getLikeDetails}
                    postFollowUser={postFollowUser}
                    showCommentsForThisArticle={showCommentsForThisArticle}
                    articleComments={articleComments}
                    isDeleting={isDeleting}
                    reportPost={reportPost}
                    handleClickOnHashTag={handleClickOnHashTag}
                    postUnfollowUser={postUnfollowUser}
                    getUsersFollowed={getUsersFollowed}
                    followedUsers={followedUsers}
                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                    feedKey={`${item.id}-feeditem-social-card`}
                    selectedHashTag={selectedHashTag}
                    selectedKey={selectedKey}
                />
                <div className="commentWrapper">
                    <div className="commentWrapper_first_el">
                        <TagUser
                            onChange={handleChange}
                            placeholder={addAComment}
                            userAddMentions={setSelecteduser}
                            value={articleId === item.id ? commentValue : ''}
                            itemid={item.id}
                            className={`commentSection outline-none ${commentValue.length === 0 && 'full-width'}`}
                            ref={setRef}
                        />
                        {commentValue.length > 0 && (
                            <span data-itemid={item.id} role="none" onClick={handleClick} className="post_comment_btn">
                                POST
                            </span>
                        )}
                    </div>
                    {item.showComments && (
                        <CommentsForArticle
                            articleComments={articleComments}
                            likeThisComment={likeThisComment}
                            deleteComment={deleteComment}
                            setFocus={setFocus}
                            articleindex={index}
                            replyCommentProps={replyCommentProps}
                            setReplyProps={setReplyProps}
                            getlikedBy={getlikedBy}
                        />
                    )}
                </div>
            </div>
        );
    };

    const _renderEmptyMessage = () => {
        switch (selectedKey) {
            case 1:
                return stayTunned;
            case 2:
                return noFilterRecord;
            case 4:
                return socialNorecords;
            default:
                return stayTunned;
        }
    };

    return (
        <div className="feedsListWrapper">
            {getLikes && (
                <LikeDetailModal
                    open={getLikes}
                    handleClose={handleClose}
                    likeDetails={likeDetailsWithFollowed}
                    isLoading={isLoading}
                    followedId={followedId}
                    postFollowUser={postFollowUser}
                    postUnfollowUser={postUnfollowUser}
                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                />
            )}
            <BottomScrollListener onBottom={scrollCallback} offset={1000} debounce={1000} />
            <div className="feedsPnl">
                {feedsList
                    && feedsList.length > 0
                    && feedsList.map((item, index) => (
                        <div className="feedCard" key={`${item.id}-feeditem-card`}>
                            {renderFeedCard(item, index)}
                        </div>
                    ))}
                {feedsList.length === 0 && (
                    <div className="mt_state">
                        <img src={EmptyState} alt="mt_state" />
                        <span>{_renderEmptyMessage()}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

FeedsList.defaultProps = {
    articleComments: [],
    clickedArticle: null,
    badgeOfNewerPostsCountSocialGroup: null,
    badgeOfNewerPostsCount: null,
    followedId: 0,
    history: {},
};

FeedsList.propTypes = {
    feedsList: PropTypes.array.isRequired,
    getFeedsListMore: PropTypes.func.isRequired,
    allFeedFetched: PropTypes.bool.isRequired,
    postLike: PropTypes.func.isRequired,
    likedId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setShowInterventionPage: PropTypes.func.isRequired,
    showInterventionPage: PropTypes.bool.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    deletePost: PropTypes.func.isRequired,
    postSavedPosts: PropTypes.func.isRequired,
    savedPostId: PropTypes.number.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedId: PropTypes.number,
    getLikeDetails: PropTypes.func.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    articleComments: PropTypes.array,
    showCommentsForThisArticle: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    clickedArticle: PropTypes.number,
    isDeleting: PropTypes.bool.isRequired,
    reportPost: PropTypes.func.isRequired,
    handleClickOnHashTag: PropTypes.func.isRequired,
    selectedHashTag: PropTypes.string.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    fetchUsersList: PropTypes.func.isRequired,
    userListForSharePost: PropTypes.array.isRequired,
    sharePost: PropTypes.func.isRequired,
    fetchMoreUsersList: PropTypes.func.isRequired,
    allUsersFetched: PropTypes.bool.isRequired,
    selectedKey: PropTypes.number.isRequired,
    removeBookMark: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    setSelectedKey: PropTypes.func.isRequired,
    bannerOpened: PropTypes.func.isRequired,
    removeIsNewTagFromLocalState: PropTypes.func.isRequired,
    articleIndexStartTimeTracker: PropTypes.func.isRequired,
    articleIndexEndTimeTracker: PropTypes.func.isRequired,
    userScrollAnalytics: PropTypes.func.isRequired,
    storeNumberOfNewPostsCount: PropTypes.func.isRequired,
    storeNumberOfNewPostsCountSocialGroup: PropTypes.func.isRequired,
    badgeOfNewerPostsCount: PropTypes.number,
    badgeOfNewerPostsCountSocialGroup: PropTypes.number,
    history: PropTypes.object,
};
export default FeedsList;
