import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../components/Text';
import actionCompletedIcon from '../../assets/action-completed.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import { fontColors, fontFamilies } from '../../common/fontMap';

function ActionProgressCard({ cardDetails }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { action_completed } = localisationsAfterLogin;

    return (
        <div className="action-progress-card">
            <div className="content-container">
                <Text
                    fontsize="16px"
                    fontcolor={fontColors.electricBlue200}
                    fontfamily={fontFamilies.MckinseySansRegular}
                    fontweight={400}
                    lineHeight="16px"
                    margin="0 16px 24px 0"
                >
                    {action_completed}
                </Text>
                <Text
                    fontsize="45px"
                    fontcolor={fontColors.siteWhite}
                    fontfamily={fontFamilies.MckinseySansRegular}
                    fontweight={400}
                    lineHeight="34px"
                    letterSpacing="0.2px"
                >
                    {cardDetails.completed}
                    /
                    {cardDetails.total}
                </Text>
            </div>
            <div className="img-container">
                <img src={actionCompletedIcon} alt="action-completed" />
            </div>
        </div>
    );
}

export default ActionProgressCard;

ActionProgressCard.defaultProps = {
    cardDetails: {},
};

ActionProgressCard.propTypes = {
    cardDetails: PropTypes.shape({
        completed: PropTypes.number,
        total: PropTypes.number,
    }),
};
