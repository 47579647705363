import { connect } from 'react-redux';
import WeeklyReplayMailerComponent from './WeeklyReplayMailerComponent';
import weeklyReplayMailerSaga from './redux/sagas';
import weeklyReplayMailerContentReducer from './redux/reducer';
import { getHtmlContent } from './redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('weeklyReplayMailerContentReducer');
    return {
        htmlContent: REDUCER.htmlContent,
        isLoading: REDUCER.isLoading,
    };
};

export { weeklyReplayMailerSaga, weeklyReplayMailerContentReducer };

const dispatchToProps = {
    getHtmlContent,
};

export default connect(stateToProps, dispatchToProps)(WeeklyReplayMailerComponent);
