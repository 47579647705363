import React from 'react';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import Text from '../../../../components/Text';
import './index.scss';
import loaderGifBlack from '../../../../assets/mck_black.gif';

const Previous = ({ config, refreshCallback, promptLoading, loadingState, setLoadingState, setError }) => {
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    return (
        <div className={isClientPG ? 'prev_pg' : 'prev'}>
            {(promptLoading && loadingState.prevLoading)
                ? (
                    <img
                        style={{
                            margin: 'auto',
                            height: '54px',
                            width: 'auto',
                        }}
                        src={loaderGifBlack}
                        alt="loader"
                    />
                )
                : (
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanRegular}
                        decoration="underline"
                        cursor="pointer"
                        textAlign="center"
                        justifyContent="center"
                        onClick={event => {
                            setLoadingState({ prevLoading: true, nextLoading: false });
                            setError({ isError: false });
                            refreshCallback(config.prevId);
                        }}
                    >
                        {config.btnText || 'Previous'}
                    </Text>
                )
            }
        </div>
    );
};

export default Previous;
