export default {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_PENDING: 'LOGIN_PENDING',
    LOGIN_FAILED: 'LOGIN_FAILED',
    FORGOT_PASSWORD_REQUEST_PENDING: 'FORGOT_PASSWORD_REQUEST_PENDING',
    FORGOT_PASSWORD_REQUEST_SUCCESS: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
    SIGN_UP_REQUEST_PENDING: 'SIGN_UP_REQUEST_PENDING',
    SIGN_UP_REQUEST_SUCCESS: 'SIGN_UP_REQUEST_SUCCESS',
    GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
    GET_STREAK_STATUS: 'GET_STREAK_STATUS',
    SIGN_UP_REQUEST_FAILED: 'SIGN_UP_REQUEST_FAILED',
    FORGOT_PASSWORD_REQUEST_FAILED: 'FORGOT_PASSWORD_REQUEST_FAILED',
    GET_STATUS_FAILED: 'GET_STATUS_FAILED',
    RESET_ERROR: 'RESET_ERROR',
    RESET_SCREEN: 'RESET_SCREEN',
    GET_USER_DATA_PENDING: 'GET_USER_DATA_PENDING',
    GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
    GET_USER_DATA_FAILED: 'GET_USER_DATA_FAILED',
    LOGOUT_USER_PENDING: 'LOGOUT_USER_PENDING',
    LOGOUT_USER_FAILED: 'LOGOUT_USER_FAILED',
    RESET_USER_DETAILS_SUCCESS: 'RESET_USER_DETAILS_SUCCESS',
    EMAIL_SUBSCRIBE_SUCCESS: 'EMAIL_SUBSCRIBE_SUCCESS',
    SET_NEW_NOTIFICATION_SUCCESS: 'SET_NEW_NOTIFICATION_SUCCESS',
    SET_HIDE_SECTIONS_SUCCESS: 'SET_HIDE_SECTIONS_SUCCESS',
    SET_HIDE_ADD_GOAL_BTN_SUCCESS: 'SET_HIDE_ADD_GOAL_BTN_SUCCESS',
    SMRF_LINK_SUCCESS: 'SMRF_LINK_SUCCESS',
};
