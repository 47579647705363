import React, { useState, useEffect, Fragment } from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';

import { Badges } from '../constants';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';

export default function BadgeReceived({ earnedBannerBadge }) {
    const [open, setOpen] = useState(false);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { yayyHeading } = localisationsAfterLogin;

    useEffect(() => {
        if (earnedBannerBadge && earnedBannerBadge.hasUserEarnedBadge) {
            setOpen(true);
        }
    }, [earnedBannerBadge]);

    const _renderBadge = item => {
        const { badge_id } = item;
        const socialBadges = Badges.filter(badge => badge.type === 'social');
        const badge = socialBadges.find(sb => sb.badgeId === badge_id);
        return {
            image: badge.badgeImage,
            name: badge.badgeName,
            content: badge.content,
        };
    };

    const closeModall = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            {earnedBannerBadge && earnedBannerBadge.hasUserEarnedBadge && (
                <Modal open={open} className="earned_badge_outer">
                    <div className="earned_badge_inner">
                        <div
                            className="close_badge_popup"
                            onClick={closeModall}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                        >
                            <Cross color="black" />
                        </div>
                        <div className="badge_Contauner">
                            <img src={_renderBadge(earnedBannerBadge).image} alt="received_badge" />
                        </div>
                        <div className="badge_heading_and_text">
                            <div>{yayyHeading}</div>
                            <div>{_renderBadge(earnedBannerBadge).content}</div>
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    );
}

BadgeReceived.defaultProps = {
    earnedBannerBadge: {
        badge_id: 0,
        hasUserEarnedBadge: false,
    },
};

BadgeReceived.propTypes = {
    earnedBannerBadge: PropTypes.shape({
        badge_id: PropTypes.number.isRequired,
        hasUserEarnedBadge: PropTypes.bool.isRequired,
    }),
};
