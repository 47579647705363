/* eslint-disable react/prop-types */
import React from 'react';
import { fontFamilies } from '../../../common/fontMap';

export const HomeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path
            fill="#282828"
            fillRule="evenodd"
            d="M63 128.5L63 136 67.5 136 67.5 132.25 70.5 132.25 70.5 136 75 136 75 128.5 69 124z"
            transform="translate(-63 -124)"
        />
    </svg>
);

export const CoachingIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="12"
        height="14"
        viewBox="0 0 12 14"
    >
        <defs>
            <path id="prefix__a" d="M0 16L16 16 16 0 0 0z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(-2 -1)">
            <path
                fill="#282828"
                stroke="#282828"
                d="M9.805 2.195C9.679 2.071 9.51 2 9.333 2h-6c-.368 0-.666.299-.666.667v10.666c0 .368.298.667.666.667h9.334c.368 0 .666-.299.666-.667V6c0-.177-.07-.347-.195-.471L9.805 2.195z"
            />
            <mask id="prefix__b" fill="#fff">
                <use href="#prefix__a" />
            </mask>
            <path
                fill="#EBEBF2"
                d="M5.333 10.667L10.667 10.667 10.667 9.333 5.333 9.333zM5.333 8L8 8 8 6.667 5.333 6.667z"
                mask="url(#prefix__b)"
            />
        </g>
    </svg>
);

export const LoadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
        <path
            fill="none"
            fillRule="evenodd"
            stroke="#2A37EF"
            strokeDasharray="100.531"
            strokeLinecap="round"
            strokeWidth="2"
            d="M1 9.5C1 14.194 4.806 18 9.5 18S18 14.194 18 9.5 14.194 1 9.5 1"
        />
    </svg>
);

export const UpLoadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <g fill="none" fillRule="evenodd">
            <g>
                <g transform="translate(-1120 -19) translate(1120 19)">
                    <circle cx="11" cy="11" r="11" fill="#FFF" />
                    <path stroke="#282828" strokeWidth="2" d="M6 11.885h10M11 7v10" />
                </g>
            </g>
        </g>
    </svg>
);

export const TextFormatting = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="14"
        height="10"
        viewBox="0 0 14 10"
    >
        <defs>
            <path id="prefix__a" d="M0 0.055L8.825 0.055 8.825 9.993 0 9.993z" />
            <path id="prefix__c" d="M0.144 0.116L3.845 0.116 3.845 4 0.144 4z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="prefix__b" fill="#fff">
                <use href="#prefix__a" />
            </mask>
            <path
                fill="#FFF"
                d="M2.347 6.113c.585-1.439 1.348-3.125 2.097-4.7.681 1.342 1.485 3.124 2.044 4.454-1.308-.041-2.806.027-4.141.246M4.88.07c-.258 0-.613 0-.83-.014-1.513 3.057-2.711 5.84-3.992 9.06-.095.247-.068.412.041.549.136.164.422.26.708.329.355-.987.695-1.865 1.104-2.838 1.553-.37 3.35-.438 4.971-.356.423 1.055.872 2.261 1.172 3.166.19-.068.45-.178.613-.343.136-.137.204-.301.122-.507C7.768 6.291 6.256 2.865 4.88.07"
                mask="url(#prefix__b)"
            />
            <g transform="translate(10 6)">
                <mask id="prefix__d" fill="#fff">
                    <use href="#prefix__c" />
                </mask>
                <path
                    fill="#FFF"
                    d="M2.974 2.649c-.235.297-.522.544-.855.69-.257.108-.545.166-.855.137-.265-.03-.515-.189-.575-.457-.038-.196.053-.435.227-.551.28-.182.605-.24.938-.298.379-.058.742-.123 1.128-.224 0 .18-.008.529-.008.703m.568.116c-.045-.355-.038-.725 0-1.052.03-.319.045-.638-.023-.848-.068-.218-.22-.4-.522-.544-.303-.145-.65-.218-.991-.203-.326.007-.644.087-.939.225C.81.459.567.618.348.82c-.076.073-.114.182-.106.283.008.13.083.268.204.355.22-.246.492-.478.78-.63.242-.131.575-.225.9-.225.265 0 .523.065.712.217.19.153.25.377.197.61-.477.116-.9.203-1.37.275-.363.05-.742.13-1.044.341-.288.203-.477.508-.477.849 0 .384.196.74.537.928.37.203.848.218 1.28.109.393-.102.74-.29 1.104-.617.023.087.053.247.083.363.038.13.129.218.25.261.136.05.303.05.447-.007-.16-.348-.258-.754-.303-1.168"
                    mask="url(#prefix__d)"
                />
            </g>
        </g>
    </svg>
);

export const MoreIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4">
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        />
    </svg>
);

export const DragImageIcon = ({ style }) => (
    <svg style={style} width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 0C10.1587 0 8.31253 0.695861 6.90832 2.1C5.81867 3.18968 5.16944 4.55308 4.92501 5.96667C2.13675 6.41955 0 8.82173 0 11.7333C0 14.9671 2.63293 17.6 5.86667 17.6H18.9333C21.731 17.6 24 15.331 24 12.5333C24 9.80968 21.8452 7.60741 19.15 7.49167C19.228 5.5512 18.5618 3.57852 17.0833 2.1C15.68 0.696653 13.8413 0 12 0ZM12 1.06667C13.5711 1.06667 15.1332 1.65821 16.3333 2.85833C17.7204 4.24539 18.2995 6.12904 18.0833 7.93333C18.0738 8.00876 18.0806 8.08533 18.1031 8.15794C18.1256 8.23055 18.1634 8.29751 18.2139 8.35433C18.2644 8.41115 18.3265 8.45651 18.3959 8.48739C18.4654 8.51826 18.5407 8.53392 18.6167 8.53333H18.9333C21.1586 8.53333 22.9333 10.3081 22.9333 12.5333C22.9333 14.7586 21.1586 16.5333 18.9333 16.5333H5.86667C3.20941 16.5333 1.06667 14.3906 1.06667 11.7333C1.06667 9.22419 2.97829 7.17213 5.42501 6.95C5.54687 6.93942 5.66138 6.88729 5.74937 6.80233C5.83736 6.71737 5.89348 6.60474 5.90832 6.48333C6.06219 5.15793 6.64003 3.87662 7.65835 2.85833C8.85765 1.65901 10.4289 1.06667 12 1.06667Z"
            fill="white"
        />
        <path
            d="M12.0029 14.3999C12.2975 14.3999 12.5362 14.1611 12.5362 13.8666L12.5362 8.13324L14.5779 9.99155C14.7849 10.1806 15.148 10.166 15.3362 9.95822C15.5244 9.75038 15.5137 9.39068 15.2945 9.1999L12.3612 6.53323C12.285 6.46342 12.1455 6.40257 12.0029 6.3999C11.8613 6.3999 11.7619 6.42604 11.6445 6.53323L8.71118 9.1999C8.50252 9.38715 8.46417 9.75254 8.66953 9.95822C8.86761 10.1566 9.22084 10.1806 9.42788 9.99155L11.4695 8.13324L11.4695 13.8666C11.4695 14.1611 11.7083 14.3999 12.0029 14.3999Z"
            fill="white"
        />
    </svg>
);

export const MenuIcon = () => (
    <svg width="24" height="16" viewBox="0 0 24 16">
        <path
            fill="#FFF"
            d="M.869 0H.857C.383 0 0 .447 0 1c0 .553.383 1 .857 1H23.144c.473 0 .856-.447.856-1 0-.553-.383-1-.856-1H.869zm0 7H.857C.383 7 0 7.447 0 8c0 .553.383 1 .857 1H23.144c.473 0 .856-.447.856-1 0-.553-.383-1-.856-1H.869zm0 7H.857C.383 14 0 14.447 0 15c0 .553.383 1 .857 1H23.144c.473 0 .856-.447.856-1 0-.553-.383-1-.856-1H.869z"
        />
    </svg>
);

export const SocialLearning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#282828"
                strokeLinejoin="round"
                d="M13 8c0 3.313-2.687 6-6 6s-6-2.687-6-6 2.687-6 6-6 6 2.687 6 6z"
                transform="translate(1 1)"
            />
            <path
                fill="#282828"
                d="M9 3c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2"
                transform="translate(1 1)"
            />
            <path
                fill="#282828"
                stroke="#282828"
                strokeLinejoin="round"
                d="M9 2c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2z"
                transform="translate(1 1)"
            />
            <path
                fill="#282828"
                d="M4 11c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2"
                transform="translate(1 1)"
            />
            <path
                fill="#282828"
                stroke="#282828"
                strokeLinejoin="round"
                d="M4 11c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2z"
                transform="translate(1 1)"
            />
            <path
                fill="#282828"
                stroke="#282828"
                d="M14 11c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2"
                transform="translate(1 1)"
            />
            <path
                stroke="#282828"
                strokeLinejoin="round"
                d="M14 11c0 1.105-.895 2-2 2s-2-.895-2-2 .895-2 2-2 2 .895 2 2z"
                transform="translate(1 1)"
            />
        </g>
    </svg>
);

export const Badge = ({ badgeNumber, badgeName }) => (
    <svg width="299" height="218" viewBox="0 0 299 218" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M107.265 43.5299C77.2191 57.0669 66.5409 108.763 78.0011 149.251C87.2406 181.893 174.143 225.701 208.944 174.617C250.212 114.039 231.911 74.6602 208.944 57.1239C191.692 43.9515 137.311 29.9928 107.265 43.5299Z"
            fill="#ECC4C3"
        />
        <path
            d="M139.345 51.3404C138.29 51.4067 137.288 51.9586 136.694 52.8772C131.591 60.7731 125.282 63.2141 120.779 64.9562C117.525 66.2149 115.215 67.1087 114.985 69.4316C114.442 74.9011 112.61 85.2339 105.934 96.5362C105.163 97.8411 105.459 99.5168 106.554 100.564C113.45 107.162 116.141 116.821 115.833 123.29C115.767 124.67 116.481 126.022 117.74 126.589C128.386 131.389 133.862 136.075 136.344 139.374C137.528 140.948 139.908 142.089 141.804 141.557C151.006 138.977 161.898 140.576 168.132 142.23C169.458 142.581 170.867 142.043 171.643 140.912C174.023 137.449 176.863 134.615 179.71 132.359L179.71 132.354C183.169 129.614 186.637 127.725 189.299 126.594C190.734 125.985 191.602 124.446 191.496 122.891C190.922 114.545 195.77 104.222 199.364 98.3176C200.115 97.0836 199.92 95.4936 199.005 94.3758C193.633 87.8148 192.046 77.4613 191.878 70.8694C191.836 69.1947 190.485 67.7646 188.891 67.2484C182.424 65.1536 173.49 57.4894 168.209 52.307C167.526 51.6366 166.591 51.3529 165.654 51.4101C165.19 51.4392 164.725 51.5522 164.29 51.7435C161.983 52.7591 159.213 53.2986 156.3 53.4791C150.861 53.8173 144.926 52.9026 140.596 51.4982C140.186 51.3651 139.761 51.3149 139.345 51.3404Z"
            fill="white"
        />
        <path
            d="M182.288 141.217C180.948 142.525 179.67 144.001 178.538 145.649C175.805 149.627 170.809 151.593 165.988 150.315C162.451 149.377 157.528 148.557 152.597 148.564C157.484 163.158 164.056 180.875 167.809 184.871C168.341 185.438 169.136 185.15 169.471 184.449C171.142 180.951 173.595 174.677 174.209 168.967C174.335 167.797 175.579 167.017 176.654 167.499C182.577 170.152 191.413 173.498 193.908 172.191C194.334 171.968 194.34 171.428 194.152 170.986C190.687 162.861 185.416 150.045 182.288 141.217Z"
            fill="white"
        />
        <path
            d="M151.196 148.589C148.73 148.675 146.294 148.986 144.063 149.611C140.784 150.531 137.719 149.879 135.542 148.957C133.332 148.02 131.214 146.47 129.659 144.403C128.81 143.274 126.848 141.279 123.007 138.834C117.185 150.801 114.844 171.681 116.223 180.997C116.386 182.097 117.641 182.466 118.492 181.751C123.069 177.903 126.568 175.826 130.492 171.084C131.825 169.751 132.961 172.557 135.053 176.337C136.303 178.595 137.748 181.206 139.389 183.377C140.163 184.402 141.667 183.938 141.897 182.674C143.738 172.568 147.128 157.789 151.196 148.589Z"
            fill="white"
        />
        <text
            x="153"
            y="100"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#3E74CA"
            fontSize="36"
            fontWeight="bold"
        >
            {badgeNumber}
        </text>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.0857 83.4483C67.6048 105.049 67.8159 130.54 74.1189 152.808C76.04 159.595 82.6646 168.487 93.8067 177.297C104.682 185.895 118.777 193.543 133.755 198.299C164.19 207.963 194.497 204.923 211.503 179.96C234.969 145.514 240.7 118.298 238.34 98.0727C235.984 77.875 225.411 63.3083 213.62 54.3051C205.211 47.8847 185.82 40.2362 163.425 36.2823C141.038 32.3298 118.449 32.5814 103.36 39.3795C89.4461 45.6485 78.6385 61.564 73.0857 83.4483ZM64.8436 155.433C51.3105 107.623 63.9201 46.5761 99.4005 30.5906C134.881 14.6051 199.098 31.0885 219.47 46.6434C246.591 67.3515 268.202 113.853 219.47 185.387C178.375 245.711 75.7543 193.979 64.8436 155.433Z"
            fill="#ECC4C3"
        />
        <path
            d="M268.886 155.531H239C237.895 155.531 237 156.427 237 157.531V188.531C237 189.636 237.895 190.531 239 190.531H268.886C270.555 190.531 271.49 188.608 270.459 187.296L260.225 174.267C259.655 173.542 259.655 172.521 260.225 171.796L270.459 158.767C271.49 157.454 270.555 155.531 268.886 155.531Z"
            fill="#D8D8D8"
        />
        <path
            d="M30.1141 190.531L60 190.531C61.1046 190.531 62 189.636 62 188.531L62 157.531C62 156.427 61.1046 155.531 60 155.531L30.1141 155.531C28.4454 155.531 27.5105 157.454 28.5413 158.767L38.7751 171.796C39.3447 172.521 39.3447 173.542 38.7751 174.267L28.5413 187.296C27.5105 188.608 28.4454 190.531 30.1141 190.531Z"
            fill="#D8D8D8"
        />
        <g filter="url(#filter0_d_8975_15936)">
            <path
                d="M246 180.346V151.769C246 149.281 243.716 147.404 241.265 147.831C162.858 161.479 133.891 161.028 57.6555 149.24C55.2285 148.865 53 150.735 53 153.191V179.261C53 179.972 53.125 180.639 53.6326 181.136C68.3241 195.525 246 195.98 246 180.346Z"
                fill="white"
            />
        </g>
        <text
            x="153"
            y="177"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#3E3E3E"
            fontSize="14"
            fontWeight="500"
            letterSpacing="1px"
            fontFamily={fontFamilies.TheinhardtPanRegular}
        >
            {badgeName}
        </text>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230.293 16.5496C234.431 15.7715 237.149 15.2595 240.514 14.6263C239.643 12.9216 239.42 11.78 238.73 11.248C234.285 7.83375 232.169 10.096 230.293 16.5496Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M82.9042 34.4828C85.0645 38.0974 86.4842 40.4705 88.2412 43.4094C89.5399 42.003 90.5342 41.3995 90.7948 40.5678C92.4628 35.2172 89.6086 34.0133 82.9042 34.4828Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.2926 167.55C33.431 166.772 36.1485 166.259 39.5136 165.626C38.6426 163.922 38.4199 162.78 37.7295 162.248C33.285 158.834 31.1689 161.096 29.2926 167.55Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220.995 35.9561C225.688 30.7747 223.83 29.1042 217.697 27.9996C218.978 31.0894 219.839 33.1667 220.995 35.9561Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9955 191.769C21.688 186.587 19.8305 184.917 13.6973 183.812C14.9778 186.902 15.8394 188.979 16.9955 191.769Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.965 80.6458C251.08 82.1942 249.272 82.6907 248.316 83.8753C247.835 84.4741 248.522 86.9078 249.36 87.4398C250.283 88.0255 252.837 87.8249 253.175 87.1792C253.902 85.7883 253.664 83.8932 253.965 80.6458Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.9654 193.646C79.0805 195.194 77.2716 195.691 76.3164 196.875C75.8349 197.474 76.5218 199.908 77.3603 200.44C78.2829 201.025 80.8373 200.825 81.1754 200.179C81.902 198.788 81.6639 196.893 81.9654 193.646Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M252.784 130.782C247.436 131.462 245.326 134.001 246.939 138.539C247.33 139.64 250.439 141.01 251.261 140.519C255.464 138.008 254.514 134.513 252.784 130.782Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M267.164 111.845C266.455 107.694 265.988 104.969 265.411 101.593C263.692 102.436 262.547 102.639 262.004 103.321C258.516 107.708 260.743 109.861 267.164 111.845Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M229.69 213.244C227.321 213.278 224.95 213.313 222.012 213.356C225.542 217.568 225.542 217.568 229.69 213.244Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M249.806 161.374C248.414 162.559 247.11 163.184 246.708 164.174C246.419 164.887 247.331 166.089 247.706 167.072C248.877 166.408 250.231 165.917 251.114 164.983C251.411 164.669 250.51 163.223 249.806 161.374Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M240.429 196.201C239.681 197.768 238.933 199.335 238.186 200.9C239.111 201.23 240.037 201.558 240.961 201.888C241.391 200.203 241.82 198.518 242.25 196.833C241.642 196.622 241.036 196.412 240.429 196.201Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221.066 196.303C219.899 197.487 218.634 198.599 217.674 199.932C217.536 200.126 218.871 201.378 219.524 202.141C220.7 200.897 221.877 199.654 223.053 198.41C222.391 197.708 221.729 197.005 221.066 196.303Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.226 190.978C198.655 192.428 198.939 193.383 199.221 194.338C200.134 194.025 201.509 193.954 201.839 193.331C202.208 192.633 201.935 191.121 201.36 190.56C200.954 190.163 199.579 190.76 198.226 190.978Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228.699 171.472C226.965 172.532 225.845 173.217 224.726 173.9C225.259 174.734 225.644 176.057 226.364 176.275C227.206 176.53 228.767 176.116 229.223 175.45C229.646 174.83 229.023 173.499 228.699 171.472Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5339 127.829C24.7064 130.227 26.951 129.688 28.0664 125.864C28.3374 124.936 27.2646 122.388 26.6081 122.273C23.2506 121.681 22.2341 124.471 21.5339 127.829Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.52618 132.57C4.0935 135.661 3.15333 137.691 1.98863 140.204C3.28107 140.567 3.98581 141.042 4.57579 140.885C8.36834 139.87 8.11211 137.279 5.52618 132.57Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.9883 86.1836C71.2771 87.4206 72.5674 88.657 74.1656 90.1906C74.1086 85.5609 74.1086 85.5609 69.9883 86.1836Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.5039 109.41C37.7703 109.375 38.7444 109.659 39.3935 109.225C39.8606 108.913 39.8955 107.643 40.1232 106.8C39.2031 106.612 38.2608 106.214 37.3781 106.355C37.0811 106.403 36.9333 107.827 36.5039 109.41Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.2658 114.024C53.6612 114.253 54.5624 114.4 55.463 114.547C55.5408 113.719 55.912 112.65 55.6196 112.124C55.2787 111.51 54.2583 110.951 53.7221 111.145C53.2241 111.325 52.977 112.527 52.2658 114.024Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M270.734 74.0628C274.675 74.5923 276.365 73.0199 275.458 69.1412C275.238 68.1996 273.051 66.508 272.423 66.7299C269.208 67.8642 269.693 70.7935 270.734 74.0628Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.121 86.0591C259.392 89.4549 259.57 91.685 259.791 94.4463C261.095 94.1271 261.942 94.1948 262.379 93.7684C265.183 91.0209 263.687 88.89 259.121 86.0591Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M292.473 13.9844C294.203 14.4285 295.935 14.8713 298.08 15.4217C295.756 11.4175 295.756 11.4175 292.473 13.9844Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M274.721 50.6659C275.807 50.0132 276.795 49.7822 277.147 49.0849C277.401 48.5834 276.807 47.4601 276.591 46.6144C275.698 46.9029 274.681 47.0187 273.982 47.5758C273.746 47.7629 274.318 49.076 274.721 50.6659Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M290.715 46.9399C292.043 46.4535 292.9 46.1387 293.757 45.8248C293.418 45.0652 293.216 43.9514 292.703 43.6375C292.104 43.2697 290.941 43.2844 290.569 43.7166C290.224 44.1184 290.599 45.2866 290.715 46.9399Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.1783 75.3767C47.8033 71.4919 48.8695 68.9404 50.1904 65.7813C48.2876 65.5718 47.2116 65.1303 46.3877 65.4145C41.0923 67.2503 41.8188 70.2615 46.1783 75.3767Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.4376 41.2427C46.482 43.8013 48.7515 43.3789 50.0631 39.6176C50.3816 38.7045 49.4419 36.1051 48.7923 35.9557C45.4698 35.1912 44.3106 37.9248 43.4376 41.2427Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.0044 26.5236C67.3861 26.8241 68.2785 27.0176 69.1703 27.2116C69.2908 26.3885 69.7167 25.3396 69.4518 24.7998C69.1432 24.1685 68.153 23.5577 67.6076 23.7234C67.1009 23.8779 66.792 25.0653 66.0044 26.5236Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.9654 106.646C33.0805 108.194 31.2716 108.691 30.3164 109.875C29.8349 110.474 30.5218 112.908 31.3603 113.44C32.2829 114.025 34.8373 113.825 35.1754 113.179C35.902 111.788 35.6639 109.893 35.9654 106.646Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.547 7.4197C147.412 9.00494 148.81 10.256 150.323 10.4168C151.087 10.4969 152.76 8.60057 152.753 7.60758C152.745 6.51485 151.192 4.47687 150.466 4.54266C148.903 4.6858 147.44 5.91301 144.547 7.4197Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266.809 36.0741C262.933 37.3177 261.058 37.9179 258.906 38.6082C263.011 43.3671 263.011 43.3671 266.809 36.0741Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.8086 187.074C61.9334 188.318 60.0584 188.918 57.9064 189.608C62.0108 194.367 62.0108 194.367 65.8086 187.074Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M252.419 3.40577C251.211 2.03355 250.565 0.740265 249.567 0.354997C248.85 0.077602 247.663 1.00916 246.686 1.40117C247.37 2.56103 247.883 3.90648 248.832 4.77336C249.151 5.06534 250.582 4.14065 252.419 3.40577Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.4191 154.406C50.2111 153.034 49.5647 151.74 48.5672 151.355C47.8496 151.078 46.6634 152.009 45.6864 152.401C46.3703 153.561 46.8834 154.906 47.832 155.773C48.1508 156.065 49.582 155.141 51.4191 154.406Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M245.978 26.7289C246.2 28.1314 246.363 29.1568 246.524 30.182C247.715 29.7316 249 29.4197 250.018 28.7184C250.228 28.5738 249.684 26.5285 249.043 26.1788C248.386 25.8212 247.181 26.4694 245.978 26.7289Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1943 138.969C25.4162 140.372 25.5787 141.397 25.7401 142.422C26.9308 141.972 28.2163 141.66 29.2342 140.959C29.4433 140.814 28.9002 138.769 28.259 138.419C27.6017 138.061 26.3968 138.71 25.1943 138.969Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.784 28.4453C293.28 29.2683 291.948 29.5595 291.594 30.312C291.231 31.0795 291.827 32.3001 292.004 33.3222C292.915 32.9251 294.178 32.7617 294.639 32.0666C295.101 31.3686 294.763 30.1403 294.784 28.4453Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.39 44.3652C237.885 45.1883 236.554 45.4794 236.2 46.2319C235.837 46.9994 236.433 48.22 236.609 49.2421C237.521 48.8451 238.784 48.6816 239.245 47.9865C239.706 47.2885 239.368 46.0602 239.39 44.3652Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.7842 179.445C92.2798 180.268 90.9485 180.559 90.5943 181.312C90.2312 182.079 90.827 183.3 91.004 184.322C91.9151 183.925 93.1783 183.762 93.6394 183.067C94.1008 182.369 93.7627 181.14 93.7842 179.445Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M258.333 61.9075C256.856 60.7824 255.417 59.5954 253.836 58.6429C253.707 58.5657 252.612 60.0958 251.966 60.8799L257.061 63.8854C257.484 63.226 257.909 62.5668 258.333 61.9075Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.333 212.908C55.8559 211.782 54.4167 210.595 52.8359 209.643C52.7073 209.566 51.6119 211.096 50.9657 211.88L56.0608 214.885C56.4842 214.226 56.9086 213.567 57.333 212.908Z"
            fill="#ECC4C3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M248.703 105.062C251.673 102.417 251.581 100.11 248.047 98.2721C247.189 97.8257 244.481 98.3823 244.24 99.0038C243.007 102.182 245.545 103.722 248.703 105.062Z"
            fill="#722E92"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M229.5 160.557C232.253 162.563 234.062 163.88 236.3 165.512C236.907 164.315 237.511 163.716 237.472 163.107C237.214 159.189 234.623 158.936 229.5 160.557Z"
            fill="#722E92"
        />
        <defs>
            <filter
                id="filter0_d_8975_15936"
                x="35"
                y="129.77"
                width="229"
                height="80.2305"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="9" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8975_15936" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8975_15936" result="shape" />
            </filter>
        </defs>
    </svg>
);
