import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import followingIcon from './assets/followingIcon.svg';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function FollowUnfollow({
    isFollowing,
    postFollowUser,
    userId,
    postUnfollowUser,
    userType,
    setFollowUnfollowInFeeds,
}) {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const { localisationsAfterLogin } = LocalisationsStore;
    const { follow, following, unfollow_c } = localisationsAfterLogin;
    const profileId = userProfile && userProfile.id;
    const [showIsFollowing, setShowIsFollowing] = useState(false);
    const followUnfollow = isfollow => {
        if (isfollow) {
            postFollowUser({ user: profileId, following_to: userId });
        } else {
            postUnfollowUser(userId, userType);
        }
        setFollowUnfollowInFeeds(userId, isfollow);
        if (isfollow) {
            setShowIsFollowing(true);
            setTimeout(() => {
                setShowIsFollowing(false);
            }, 5000);
        }
    };

    return (
        <Fragment>
            {isFollowing && !showIsFollowing && (
                <div
                    className=" unfollow outline-none transition_left"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => followUnfollow(false)}
                >
                    <div className="fade-in">{unfollow_c}</div>
                </div>
            )}
            {showIsFollowing && (
                <div className="following outline-none transition_left">
                    <div className="fade-in">
                        <span>{following}</span>
                        <img className="followingIcon" src={followingIcon} alt="" />
                    </div>
                </div>
            )}
            {!isFollowing && userType !== 1 && !showIsFollowing && (
                <div
                    className="follow outline-none transition_left"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={() => followUnfollow(true)}
                >
                    <div className="fade-in">{follow}</div>
                </div>
            )}
        </Fragment>
    );
}

FollowUnfollow.defaultProps = {
    userType: null,
};

FollowUnfollow.propTypes = {
    isFollowing: PropTypes.bool.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    userType: PropTypes.number,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
};
