import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ActiveNeutral from '../assets/active_neutral.svg';
import ActiveStronglyAgree from '../assets/active_strongly_agree.svg';
import ActiveStronglyDisagree from '../assets/active_strongly_disagree.svg';
import Neutral from '../assets/neutral.svg';
import StronglyAgree from '../assets/strongly_agree.svg';
import StronglyDisagree from '../assets/strongly_disagree.svg';
import Agree from '../assets/agree.svg';
import ActiveAgree from '../assets/active_agree.svg';
import Disagree from '../assets/disagree.svg';
import ActiveDisagree from '../assets/active_disagree.svg';

import LocalisationsStore from '../../../common/LocalisationsStore';

const Text = styled.div`
text-align: ${props => (props.centerAlign ? 'center' : 'left')}
  width: 100%;
  font-size: ${props => props.size}px;
  font-weight: ${props => props.weight};
  margin: 5px 0;
  color: ${props => props.color};
  opacity: ${props => props.opacity};
  text-transform: uppercase;
`;

const Faces = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 276px;
    min-width: 276px;
    max-width: 276px;
    margin-top: 10px;
    margin-bottom: 50px;
`;

const Face = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: ${props => props.marginRight}px;
    height: auto;
`;

const FaceImage = styled.div`
    background-image: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
`;

const MCK_ELECTRIC_BLUE = '#1f40e6';

export default function Rating({ setAnswer }) {
    const [response, setResponse] = useState('1');
    const { localisationsAfterLogin } = LocalisationsStore;
    const { stronglyDisagree, disagree, neutral, agree, stronglyAgree } = localisationsAfterLogin;

    useEffect(() => {
        setAnswer('1');
    }, [setAnswer]);

    const selectResponse = event => {
        const { currentTarget: { dataset: { emojival } } } = event;
        setResponse(emojival);
        setAnswer(emojival);
    };

    return (
        <Faces>
            <Face marginRight="20" onClick={selectResponse} data-emojival={1}>
                {response === '1' ? (
                    <Text size="13" weight="500" color={MCK_ELECTRIC_BLUE}>
                        {stronglyDisagree}
                    </Text>
                ) : (
                    <></>
                )}
                <FaceImage
                    src={response === '1' ? ActiveStronglyDisagree : StronglyDisagree}
                    height={response === '1' ? '55' : '25'}
                    width={response === '1' ? '55' : '25'}
                />
            </Face>
            <Face marginRight="20" onClick={selectResponse} data-emojival={2}>
                {response === '2' ? (
                    <Text size="13" weight="500" centerAlign color={MCK_ELECTRIC_BLUE}>
                        {disagree}
                    </Text>
                ) : (
                    <></>
                )}
                <FaceImage
                    src={response === '2' ? ActiveDisagree : Disagree}
                    height={response === '2' ? '55' : '25'}
                    width={response === '2' ? '55' : '25'}
                />
            </Face>
            <Face marginRight="20" onClick={selectResponse} data-emojival={3}>
                {response === '3' ? (
                    <Text size="13" weight="500" centerAlign color={MCK_ELECTRIC_BLUE}>
                        {neutral}
                    </Text>
                ) : (
                    <></>
                )}
                <FaceImage
                    src={response === '3' ? ActiveNeutral : Neutral}
                    height={response === '3' ? '55' : '25'}
                    width={response === '3' ? '55' : '25'}
                />
            </Face>
            <Face marginRight="20" onClick={selectResponse} data-emojival={4}>
                {response === '4' ? (
                    <Text size="13" weight="500" color={MCK_ELECTRIC_BLUE}>
                        {agree}
                    </Text>
                ) : (
                    <></>
                )}
                <FaceImage
                    src={response === '4' ? ActiveAgree : Agree}
                    height={response === '4' ? '55' : '25'}
                    width={response === '4' ? '55' : '25'}
                />
            </Face>
            <Face marginRight="20" onClick={selectResponse} data-emojival={5}>
                {response === '5' ? (
                    <Text size="13" weight="500" color={MCK_ELECTRIC_BLUE}>
                        {stronglyAgree}
                    </Text>
                ) : (
                    <></>
                )}
                <FaceImage
                    src={response === '5' ? ActiveStronglyAgree : StronglyAgree}
                    height={response === '5' ? '55' : '25'}
                    width={response === '5' ? '55' : '25'}
                />
            </Face>
        </Faces>
    );
}

Rating.propTypes = {
    setAnswer: PropTypes.func.isRequired,
};
