import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { UpLoadIcon, MenuIcon } from './assets/svg';
import './index.scss';
import MaskMenu from './MaskMenu';
import HeaderMenu from './HeaderMenu';
import MobileProfile from './MobileProfile';
import BackIcon from './assets/back.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import learningTeam from './assets/learningTeam.svg';
import FeedFilter from '../FeedFilters';
import UserActivity from '../UserActivity';
import { isMobile } from '../../utils/constants';
import NotificationCenter from '../NotificationCenter';

const ActivityClock = ({ filled }) => (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g fill="none" fillRule="evenodd">
                <g fill={filled ? '#000' : '#fff'} fillRule="nonzero">
                    <g>
                        <path
                            d="M8.476 0c4.673 0 8.475 3.802 8.475 8.476 0 4.673-3.802 8.476-8.475 8.476C3.802 16.952 0 13.149 0 8.476 0 3.802 3.802 0 8.476 0zm0 1.215c-4.004 0-7.261 3.257-7.261 7.26 0 4.004 3.257 7.262 7.26 7.262 4.004 0 7.261-3.258 7.261-7.261 0-4.004-3.257-7.261-7.26-7.261zm-.233 2.157c.336 0 .608.272.608.608v4.354h3.983c.335 0 .607.272.607.607 0 .336-.272.608-.607.608h-4.59c-.336 0-.608-.272-.608-.608V3.98c0-.336.272-.608.607-.608z"
                            transform="translate(-1259 -21) translate(1259 21)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </>
);

ActivityClock.propTypes = {
    filled: PropTypes.bool.isRequired,
};

export default function FeedHeader({
    handleOpen,
    history,
    logoutUser,
    selectedMenu,
    handleClickOnMore,
    getUserProgressData,
    selectedKey,
    showTeamMembers,
    storeTeamMembersList,
    setShowPreferencesModal,
    selectedHashTag,
    setPage,
    filterString,
    storeFeedFilters,
    emptyNotificationList,
    resetPageNumber,
    setSelectedKey,
}) {
    const userProfile = localStorage.getItem('userDetails');

    const [profilePic, setProfilePic] = useState(userProfile);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [userType, setUserType] = useState(-1);
    const [optionSelected, setOptionSelected] = useState(false);
    const [showFeedFilters, setShowFeedFilters] = useState(!isMobile);
    const [showFeedFiltersMobile, setShowFeedFiltersMobile] = useState(!isMobile);
    const [showUserActivity, setShowUserActivity] = useState(false);
    const [showUserActivityMobile, setShowUserActivityMobile] = useState(false);
    const [showNotificationCenter, setShowNotificationCenter] = useState(false);
    const [showMobileNotificationCenter, setShowMobileNotificationCenter] = useState(false);

    function useOutsideClockAlerter(ref) {
        useEffect(() => {
            function handleClickOutsideOfClock(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTimeout(() => {
                        setShowUserActivity(false);
                    }, 100);
                }
            }
            document.addEventListener('mousedown', handleClickOutsideOfClock);
            return () => {
                document.removeEventListener('mousedown', handleClickOutsideOfClock);
            };
        }, [ref]);
    }

    function useOutsideBellClockAlerter(ref) {
        useEffect(() => {
            function handleClickOutsideOfClock(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTimeout(() => {
                        setShowNotificationCenter(false);
                    }, 100);
                }
            }
            document.addEventListener('mousedown', handleClickOutsideOfClock);
            return () => {
                document.removeEventListener('mousedown', handleClickOutsideOfClock);
            };
        }, [ref]);
    }

    const clockIconRef = useRef(null);
    const bellIconRef = useRef(null);
    useOutsideClockAlerter(clockIconRef);
    useOutsideBellClockAlerter(bellIconRef);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { yourFeeds, yourfeed_article, myCoachingJourney, backToFeeds } = localisationsAfterLogin;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        setProfilePic(userProfile);
    }, [setProfilePic, userProfile]);

    const inspireLogoClick = () => {
        history.push('/feeds');
        selectedMenu(1);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const handleFeedFiltersClick = () => {
        setShowFeedFilters(true);
    };

    const handleFeedFiltersClickMobile = () => {
        setShowFeedFiltersMobile(true);
    };

    const _renderTabName = selectedKey => {
        let socialGroup;
        if (localStorage.userProfile) {
            socialGroup = JSON.parse(localStorage.userProfile).social_group;
        }
        switch (selectedKey) {
            case 1:
                return yourFeeds;
            case 2:
                return yourfeed_article;
            case 3:
                return 'MY_GOALS';
            case 4:
                return socialGroup[0].header_text;
            case 5:
                return myCoachingJourney;
            default:
                return yourFeeds;
        }
    };

    const handleShowUserActivityClockClick = () => {
        setShowUserActivity(prevState => !prevState);
    };

    const _renderFilterNotificationDot = () => {
        const contentTypeFilters = filterString.split('&')[0];
        const chapterFilters = filterString.split('&')[1];
        if (contentTypeFilters.split('=')[1].length > 0 || chapterFilters.split('=')[1].length > 0) {
            return <div className="icon" />;
        }
        return <></>;
    };

    const handleBellIconClick = e => {
        e.stopPropagation();

        const userProfile = JSON.parse(localStorage.userProfile);
        let { notification_count } = userProfile;
        notification_count = 0;
        userProfile.notification_count = notification_count;
        localStorage.setItem('userProfile', JSON.stringify(userProfile));

        setShowNotificationCenter(prevState => !prevState);
    };

    let notificationCount;
    if (localStorage.userProfile) {
        notificationCount = JSON.parse(localStorage.userProfile).notification_count;
    }

    return (
        <div className="feedsHeader">
            <div className="logo" onClick={inspireLogoClick} role="none" />
            <div className={selectedKey === 2 ? 'menuActionsAdjusted menuActions' : 'menuActions'}>
                <div
                    className="upload outline-none"
                    onClick={handleOpen}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                >
                    <UpLoadIcon />
                </div>
                {!isMobile && <div className="separator" />}
                {selectedKey === 2 && !history.location.pathname.includes('/feed-details-article') && (
                    <>
                        {!isMobile && <div className="separator" />}
                        <div className="desktop_funnel_icon" onClick={handleFeedFiltersClick}>
                            {_renderFilterNotificationDot()}
                            {showFeedFilters && (
                                <FeedFilter
                                    selectedHashTag={selectedHashTag}
                                    selectedKey={selectedKey}
                                    setShowFeedFilters={setShowFeedFilters}
                                    setPage={setPage}
                                />
                            )}
                        </div>
                        {!isMobile && <div className="separator" />}
                    </>
                )}
                <div
                    className={showUserActivity ? 'user_activity_clock whitebg' : 'user_activity_clock'}
                    onClick={handleShowUserActivityClockClick}
                    ref={clockIconRef}
                >
                    <ActivityClock filled={showUserActivity} />
                    {showUserActivity && <UserActivity setShowUserActivity={setShowUserActivity} />}
                </div>
                {!isMobile && <div className="separator" />}
                <div className="notifications_bell" onClick={handleBellIconClick} ref={bellIconRef}>
                    <div className="notification_number">{notificationCount > 0 ? notificationCount : ''}</div>
                    {showNotificationCenter && (
                        <NotificationCenter
                            setSelectedKey={setSelectedKey}
                            setShowNotificationCenter={setShowNotificationCenter}
                        />
                    )}
                </div>
                <MaskMenu profilePic={profilePic} handleClick={handleClick} selectedKey={selectedKey} />
                <HeaderMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    logoutUser={logoutUser}
                    getUserProgressData={getUserProgressData}
                    storeTeamMembersList={storeTeamMembersList}
                    setShowPreferencesModal={setShowPreferencesModal}
                    storeFeedFilters={storeFeedFilters}
                    storeNotificationsList={emptyNotificationList}
                    resetPageNumber={resetPageNumber}
                />
            </div>
            {showUserActivityMobile && <UserActivity setShowUserActivity={setShowUserActivityMobile} />}
            {showMobileNotificationCenter && (
                <NotificationCenter
                    setSelectedKey={setSelectedKey}
                    setShowNotificationCenter={setShowMobileNotificationCenter}
                />
            )}

            <div className="mobileHeader">
                <div className="funnel_container">
                    {selectedKey === 2 && !history.location.pathname.includes('/feed-details-article') && (
                        <>
                            {!isMobile && <div className="separator" />}
                            <div className="desktop_funnel_icon" onClick={handleFeedFiltersClickMobile}>
                                {_renderFilterNotificationDot()}
                                {showFeedFiltersMobile && (
                                    <FeedFilter
                                        selectedHashTag={selectedHashTag}
                                        selectedKey={selectedKey}
                                        setShowFeedFilters={setShowFeedFiltersMobile}
                                        setPage={setPage}
                                    />
                                )}
                            </div>
                            {!isMobile && <div className="separator" />}
                        </>
                    )}
                </div>
                <div className="hambur" onClick={handleDrawerToggle}>
                    <MenuIcon />
                </div>
                {!optionSelected ? (
                    <span
                        className="mobile_heading_tab"
                        onClick={() => {
                            history.push('/feeds');
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                    >
                        {_renderTabName(selectedKey)}
                    </span>
                ) : (
                    <div className="back_to_feeds_container">
                        <img
                            src={BackIcon}
                            alt="back"
                            onClick={e => {
                                e.stopPropagation();
                                history.push('/feeds');
                                selectedMenu(1);
                                setOptionSelected(false);
                            }}
                        />
                        <span
                            className="back_to_feeds"
                            onClick={e => {
                                e.stopPropagation();
                                history.push('/feeds');
                                selectedMenu(1);
                                setOptionSelected(false);
                            }}
                        >
                            {backToFeeds}
                        </span>
                    </div>
                )}
                {selectedKey === 4 && (
                    // eslint-disable-next-line no-use-before-define
                    <div className="social_learning_group_chip" onClick={showTeamMembers}>
                        <img src={learningTeam} alt="team" />
                    </div>
                )}
                {!optionSelected && (
                    <div className="uploadMobile" onClick={handleOpen}>
                        +
                    </div>
                )}
                <nav aria-label="mailbox folders">
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{ keepMounted: true }}
                            classes={{ paper: 'hamburgerMenu' }}
                        >
                            <MobileProfile
                                handleDrawerToggle={handleDrawerToggle}
                                profilePic={profilePic}
                                selectedMenu={selectedMenu}
                                logoutUser={logoutUser}
                                handleClickOnMore={handleClickOnMore}
                                userType={userType}
                                setUserType={setUserType}
                                history={history}
                                optionSelected={optionSelected}
                                setOptionSelected={setOptionSelected}
                                getUserProgressData={getUserProgressData}
                                storeTeamMembersList={storeTeamMembersList}
                                setShowPreferencesModal={setShowPreferencesModal}
                                setShowUserActivity={setShowUserActivityMobile}
                                storeFeedFilters={storeFeedFilters}
                                notificationCount={notificationCount}
                                setShowNotificationCenter={setShowMobileNotificationCenter}
                                storeNotificationsList={emptyNotificationList}
                                resetPageNumber={resetPageNumber}
                            />
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        </div>
    );
}

FeedHeader.defaultProps = {
    selectedMenu: () => {},
    handleClickOnMore: () => {},
    selectedKey: Number(localStorage.getItem('selectedMenu')),
    showTeamMembers: () => {},
    selectedHashTag: '',
    setPage: () => {},
    storeFeedFilters: () => {},
    setSelectedKey: () => {},
};

FeedHeader.propTypes = {
    handleOpen: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.object,
    }).isRequired,
    logoutUser: PropTypes.func.isRequired,
    selectedMenu: PropTypes.func,
    handleClickOnMore: PropTypes.func,
    getUserProgressData: PropTypes.func.isRequired,
    selectedKey: PropTypes.number,
    showTeamMembers: PropTypes.func,
    storeTeamMembersList: PropTypes.func.isRequired,
    setShowPreferencesModal: PropTypes.func.isRequired,
    selectedHashTag: PropTypes.string,
    setPage: PropTypes.func,
    filterString: PropTypes.string.isRequired,
    storeFeedFilters: PropTypes.func,
    emptyNotificationList: PropTypes.func.isRequired,
    resetPageNumber: PropTypes.func.isRequired,
    setSelectedKey: PropTypes.func,
};
