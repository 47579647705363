import axios from 'axios'
import { webApiAuthPost, webApiAuthGet, webApiGet } from '../../webapis/core'
import { fetchWebApi, postWebApi, putDashboardWebApi } from '../../webapis/dashboard'
import endpoints from '../../Endpoints'
import AuthStore from '../../common/AuthStore'

export const getTokenApi = payload => {
    const { apiBasePath, getToken } = endpoints
    const url = apiBasePath + getToken
    return webApiAuthPost(url, payload).request
}

export const signUpApi = payload => {
    const { apiBasePath, signUp } = endpoints
    const url = apiBasePath + signUp
    return webApiAuthPost(url, payload).request
}

export const resetPasswordApi = payload => {
    const { apiBasePath, forgotPassword } = endpoints
    const url = apiBasePath + forgotPassword
    return webApiAuthPost(url, payload).request
}

export const getUserStatusApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl
    const { userStatus } = endpoints
    const url = apiBasePath + userStatus
    return postWebApi(AuthStore.accessToken, url, body).request
}

export const getWorkInProgressApi = () => {
    const apiBasePath = AuthStore.baseUrl
    const { workOnProgress } = endpoints
    const url = apiBasePath + workOnProgress
    return fetchWebApi(AuthStore.accessToken, url).request
}

export const getUserDataApi = () => {
    const apiBasePath = AuthStore.baseUrl
    const { userData } = endpoints
    const url = apiBasePath + userData
    return fetchWebApi(AuthStore.accessToken, url).request
}

export const logoutUserApi = () => {
    const { logout, apiBasePath } = endpoints
    const url = `${apiBasePath + logout}`
    return postWebApi(
        AuthStore.accessToken,
        url,
        {
            refresh_token: AuthStore.refreshToken,
        },
        true,
    ).request
}

export const getCheckSum = () => {
    const { apiBasePath, checksum } = endpoints
    const url = `${apiBasePath}/${checksum}en`
    return webApiAuthGet(url).request
}

export const getCheckSum2 = () => {
    const { checksum } = endpoints
    const apiBasePath = AuthStore.baseUrl
    const lang = localStorage.getItem('selectedLanguage') || 'en'
    const url = `${apiBasePath}${checksum}${lang}`
    return webApiGet(AuthStore.accessToken, url).request
}

export const getLocalisationKeys2 = () => {
    const { localizationKeys } = endpoints
    const apiBasePath = AuthStore.baseUrl
    const lang = localStorage.getItem('selectedLanguage') || 'en'
    const url = `${apiBasePath + localizationKeys}${lang}`
    return webApiGet(AuthStore.accessToken, url).request
}

export const getLocalisationKeys = () => {
    const { apiBasePath, localizationKeys } = endpoints
    const url = `${apiBasePath}/${localizationKeys}en`
    return webApiAuthGet(url).request
}

export const verifyDomainNameApi = domain => {
    const { apiBasePath, verifyDomain } = endpoints
    const url = `${apiBasePath}${verifyDomain}${domain}`
    return webApiAuthGet(url).request
}

export const postInfoToAuth0Api = payload => {
    const { auth0post } = endpoints
    const url = auth0post
    return webApiAuthPost(url, payload).request
}

export const getLanguageApi = () => {
    const apiBasePath = AuthStore.baseUrl
    const { languages } = endpoints
    const url = `${apiBasePath}${languages}`
    return fetchWebApi(AuthStore.accessToken, url).request
}

export const postLanguageApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl
    const { postLanguage } = endpoints
    const url = `${apiBasePath}${postLanguage}`
    return putDashboardWebApi(AuthStore.accessToken, url, body).request
}

export const fetchAllFeeds = () => {
    const { apiBasePath, UNIVERSAL_HOME_DATA_ONEFIVE } = endpoints
    const url = `${apiBasePath}/${UNIVERSAL_HOME_DATA_ONEFIVE}`
    return webApiAuthGet(url).request
}

export const fetchUserInstructions = () => {
    const apiBasePath = AuthStore.baseUrl
    const { USER_INSTRUCTION } = endpoints
    const url = `${apiBasePath}${USER_INSTRUCTION}`
    return fetchWebApi(AuthStore.accessToken, url).request
}

export const updateCurrentLanguages = body => {
    const apiBasePath = AuthStore.baseUrl
    const { postLanguage } = endpoints
    const url = apiBasePath + postLanguage
    return putDashboardWebApi(AuthStore.accessToken, url, body).request
}

export const fetchTandC = () => {
    const apiBasePath = AuthStore.baseUrl
    const { USER_TAND_C } = endpoints
    const url = `${apiBasePath}${USER_TAND_C}`
    return fetchWebApi(AuthStore.accessToken, url).request
}

export const postTandC = () => {
    const apiBasePath = AuthStore.baseUrl
    const { USER_TAND_C } = endpoints
    const url = `${apiBasePath}${USER_TAND_C}`
    return putDashboardWebApi(AuthStore.accessToken, url).request
}

export const getArticleInfo = ({ baseUrl, tagId, cta }) => {
    const { emailCtaTrack } = endpoints

    let url = `${baseUrl}${emailCtaTrack}?tag_id=${tagId}&cta=${cta}`

    url = `https://${process.env.REACT_APP_API_BASEPATH}${url}`
    return fetchWebApi(AuthStore.accessToken, url).request
}

export const setReminderApi = ({ baseUrl, tagId, date }) => {
    const { setReminder } = endpoints
    let url = `${baseUrl}${setReminder}`
    url = `https://${process.env.REACT_APP_API_BASEPATH}${url}`
    return postWebApi(AuthStore.accessToken, url, { tag_id: tagId, notification_time: date }).request
}

export const completeGoalDetails = () => {
    const apiBasePath = AuthStore.baseUrl
    const { completeGoalDetails } = endpoints
    let url = `${apiBasePath}${completeGoalDetails}`
    return postWebApi(AuthStore.accessToken, url).request
}

export const extractJsonFromUrl = url => axios.get(url)
