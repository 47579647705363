class LocalisationsStore {
    constructor() {
        if (!LocalisationsStore.instance) {
            this._data = localStorage;
            LocalisationsStore.instance = this;
        }

        return LocalisationsStore.instance;
    }

    set checksumBefore(value) {
        this._data.setItem('checksumBefore', value);
    }

    get checksumBefore() {
        return this._data.getItem('checksumBefore');
    }

    set checksumAfter(value) {
        this._data.setItem('checksumAfter', value);
    }

    get checksumAfter() {
        return this._data.getItem('checksumAfter');
    }

    set localisationsBeforeLogin(value) {
        this._data.setItem('localisationsBeforeLogin', value);
    }

    get localisationsBeforeLogin() {
        return JSON.parse(this._data.getItem('localisationsBeforeLogin'));
    }

    set localisationsAfterLogin(value) {
        this._data.setItem('localisationsAfterLogin', value);
    }

    get localisationsAfterLogin() {
        return JSON.parse(this._data.getItem('localisationsAfterLogin'));
    }
}

const instance = new LocalisationsStore();
Object.freeze(instance);

export default instance;
