import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OutlinedInput, IconButton, InputAdornment, Popover, createTheme, ThemeProvider } from '@material-ui/core';
import Text from '../Text';
import Actionbutton from '../ActionButton';
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg';
import rArrow from '../../assets/rArrow.svg';
import lArrow from '../../assets/lArrow.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import { fontFamilies, fontColors } from '../../common/fontMap';
import calendarIcon from '../../assets/goal-calendar.svg';
import './index.scss';

const materialTheme = createTheme({
    palette: {
        primary: {
            main: '#2251FF',
        },
    },
});

function CustomDatePicker({
    selectedDate,
    handleDateChange,
    errorCallBack,
    errorShow = false,
    onCloseCallback,
    border,
    isHeaderVisible,
    isSaveButtonVisible,
    isStatic,
    showTextState,
}) {
    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0);
    const [error, setError] = useState(errorShow);
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState(selectedDate);
    const anchorEl = useRef(null);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { set_a_date, set_a_date_desc, save } = localisationsAfterLogin;

    useEffect(() => {
        setDate(selectedDate);
    }, [selectedDate, showCalendar]);

    const handleCloseCalendar = () => {
        setShowCalendar(false);
        onCloseCallback();
    };

    const handleClickSave = () => {
        handleDateChange(moment(date).format('yyyy-MM-DDTHH:mm:ss.SSS'));
        setShowCalendar(false);
    };

    const SaveButton = (
        <div className="save-button-container">
            <Actionbutton
                fontSize="1.125rem"
                fontFamily={fontFamilies.TheinhardtPanRegular}
                fontColor={fontColors.siteWhite}
                bgColor={fontColors.electricBlue}
                disabled={false}
                width="100%"
                height="44px"
                border={`1px solid ${fontColors.electricBlue}`}
                margin="4px 0 0"
                onClick={handleClickSave}
            >
                {save}
            </Actionbutton>
        </div>
    );

    const ToolbarComponent = () => (
        <div className="toolbar-component">
            <div
                onClick={handleCloseCalendar}
                role="button"
                tabIndex={0}
                className="cross-icon"
            >
                <Cross color="black" />
            </div>
            <Text
                fontsize="28px"
                lineHeight="32px"
                fontweight={700}
                fontcolor={fontColors.textBlack}
                fontfamily={fontFamilies.playfairBold}
                textAlign="center"
            >
                {set_a_date}
            </Text>
            <Text
                fontsize="16px"
                lineHeight="22px"
                fontweight={400}
                fontcolor={fontColors.textBlack}
                fontfamily={fontFamilies.MckinseySansRegular}
                textAlign="center"
                margin="8px 0 0 0"
            >
                {set_a_date_desc}
            </Text>
        </div>
    );

    const DatePicker = (
        <div className="goal-datepicker">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                <>
                    <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        variant="static"
                        value={new Date(date)}
                        minDate={new Date(minDate)}
                        onChange={value => {
                            if (new Date(value).getTime() - new Date().getTime() < 0) {
                                setError(true);
                                errorCallBack(true);
                            } else {
                                setError(false);
                                errorCallBack(false);
                            }
                            value.setHours(0, 0, 0, 0);
                            setDate(moment(value).format('yyyy-MM-DDTHH:mm:ss.SSS'));
                            if (!isSaveButtonVisible) {
                                handleDateChange(moment(value).format('yyyy-MM-DDTHH:mm:ss.SSS'));
                            }
                        }}
                        rightArrowIcon={<img src={rArrow} alt="Right Arrow" />}
                        leftArrowIcon={<img src={lArrow} alt="Left Arrow" />}
                        ToolbarComponent={ToolbarComponent}
                        disableToolbar={!isHeaderVisible}
                    />
                    {isSaveButtonVisible && SaveButton}
                    {error && (
                    <div className="date-error" style={{ color: '#ff0000' }}>
                        Please select future date and time.
                    </div>
                    )}
                </>
            </MuiPickersUtilsProvider>
        </div>
    );

    return (
        <ThemeProvider theme={materialTheme}>
            <div className="goal-date-container">
                {!isStatic && (
                    <>
                        {!showTextState
                            ? (
                                <div className="date-input">
                                    <OutlinedInput
                                        endAdornment={(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowCalendar(true)}
                                                    edge="end"
                                                >
                                                    <img src={calendarIcon} alt="calendar-icon" />
                                                </IconButton>
                                            </InputAdornment>
                                        )}
                                        ref={anchorEl}
                                        value={selectedDate ? moment(selectedDate).format('DD MMM YYYY') : selectedDate}
                                        style={{
                                            border: border || '1px solid #858585',
                                        }}
                                        disabled
                                    />
                                </div>
                            )
                            : (
                                <div
                                    className="due-date-text"
                                    onClick={() => setShowCalendar(true)}
                                    ref={anchorEl}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    {moment(selectedDate).format('DD MMM YYYY')}
                                </div>
                            )
                        }
                    </>
                )}
                {!isStatic ? (
                    <Popover
                        open={showCalendar}
                        onClose={handleCloseCalendar}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorEl.current}
                        className="goal-datepicker-modal"
                    >
                        {DatePicker}
                    </Popover>
                ) : <div className="goal-datepicker-modal-static">{DatePicker}</div>}
            </div>
        </ThemeProvider>
    );
}

export default CustomDatePicker;

CustomDatePicker.defaultProps = {
    onCloseCallback: () => {},
    border: null,
    isHeaderVisible: true,
    isSaveButtonVisible: true,
    isStatic: false,
    showTextState: false,
};

CustomDatePicker.propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    handleDateChange: PropTypes.func.isRequired,
    errorCallBack: PropTypes.func.isRequired,
    errorShow: PropTypes.bool.isRequired,
    onCloseCallback: PropTypes.func,
    border: PropTypes.string,
    isHeaderVisible: PropTypes.bool,
    isSaveButtonVisible: PropTypes.bool,
    isStatic: PropTypes.bool,
    showTextState: PropTypes.bool,
};
