import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivateRoute from '../../PrivateRoute';
import OnboardingComponent from './index';
import TermsAndConditionComponent from '../TermsAndConditionComponent';
import { pageAnalytics } from '../../utils/functions';

export default function OnboardingWrapperComponent({ history }) {
    const location = useLocation();
    const [checked, setChecked] = useState(false);
    const [preferredName, setPreferredName] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [imageUri, setImageUri] = useState('');

    useEffect(() => {
        pageAnalytics.initialise('onBoarding');
        return () => {
            pageAnalytics.complete('onBoarding');
        };
    }, []);

    useEffect(() => {
        if (localStorage.getItem('status') === 'true') {
            history.push('/app');
        }
        if (location.pathname === '/onboarding') {
            history.push('/onboarding/home');
        }
        // eslint-disable-next-line
    }, [history, location.pathname, localStorage.getItem('status')]);

    return (
        <div className="app_wrapper">
            <Switch>
                <PrivateRoute
                    path="/onboarding/home"
                    component={OnboardingComponent}
                    checked={checked}
                    setChecked={setChecked}
                    preferredName={preferredName}
                    setPreferredName={setPreferredName}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    imageUri={imageUri}
                    setImageUri={setImageUri}
                />
                <PrivateRoute path="/onboarding/T&C" component={TermsAndConditionComponent} setChecked={setChecked} />
            </Switch>
        </div>
    );
}

OnboardingWrapperComponent.propTypes = {
    history: PropTypes.object.isRequired,
};
