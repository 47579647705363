import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';

export default function PracticeProgressCard({ index, progress, bg_color, badge_name }) {
    return (
        <div className="practice_progress_card_container" key={`in-practice_progress-card-${index}`}>
            <div className="bar_percentage_wrapper">
                <Text fontsize="0.8rem" fontcolor={fontColors.black} fontfamily={fontFamilies.MckinseySansMedium} fontweight="500">
                    {badge_name}
                </Text>
                <div className="bar_wrapper">
                    <div className="bar">
                        <div
                            className="filler"
                            style={{ backgroundColor: bg_color, width: `${progress}%`, height: '100%' }}
                        />
                        <div
                            className="filler opacity"
                            style={{ backgroundColor: bg_color, width: `${100 - progress}%`, height: '100%' }}
                        />
                    </div>
                    <Text
                        fontsize="1.5rem"
                        fontcolor={fontColors.black}
                        fontfamily={fontFamilies.TheinhardtPanRegular}
                        margin="0 0 0 0.8rem"
                    >
                        {progress}
                        %
                    </Text>
                </div>
            </div>
        </div>
    );
}

PracticeProgressCard.propTypes = {
    index: PropTypes.number.isRequired,
    progress: PropTypes.string.isRequired,
    bg_color: PropTypes.string.isRequired,
    badge_name: PropTypes.string.isRequired,
};
