import { takeLatest, put, call } from 'redux-saga/effects';
import types from './types';
import { unsubscribeSuccessfull, alreadyUnsubscribed } from './actions';
import { apiFailed } from '../../../common/actions';
// import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { unsubscribeUserApi } from './apis';

function* unsubscribeUserPut({ baseUrl, privateKey }) {
    try {
        yield call(unsubscribeUserApi, [AuthStore.accessToken], {
            baseUrl,
            privateKey,
        });
        yield put(unsubscribeSuccessfull());
    } catch (e) {
        yield put(alreadyUnsubscribed());
        yield put(apiFailed(e));
    }
}

const unsubscribeUserSaga = [takeLatest(`${types.UNSUBSCRIBE_USER}_PENDING`, unsubscribeUserPut)];

export default unsubscribeUserSaga;
