/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';

const INITIAL_STATE = {
    loadingQuestions: false,
    open: false,
    openPredAdditionalQuestion: false,
    weeklyQuestionsData: null,
    preAdditionalQuestionsData: null,
    questionResponseFromDb: null,
    preAdditionalquestionResponseFromDb: null,
    chapterIntro: null,
    dontShowWeeklyQuestionsFromPreAdditionalQuestion: false,
};

const _mapQuestionsForView = data => {
    const splitQuestions = [];
    data.forEach(question => {
        question.seenPercentage = 0;
        if (question.behavior_text.length > 0) {
            question.isBeahviourText = true;
        }
        splitQuestions.push({ ...question });
        if (
            question.behavior_text.length !== 0
            && question.type !== 'Multiselect_ordered'
            && !question.questionType.includes('_Post')
        ) {
            question.isBeahviourText = false;
            splitQuestions.push({ ...question });
        }
        if (question.behavior_text.length !== 0 && question.questionType.includes('_Post')) {
            question.isBeahviourText = true;
        }
        if (question.type === 'Multiselect_ordered') {
            question.isBeahviourText = false;
        }
    });
    return splitQuestions;
};

const weeklyQuestionsReducer = createReducer(INITIAL_STATE, {
    GET_WEEKLY_QUESTIONS_PENDING: state => ({
        ...state,
        loadingQuestions: true,
    }),
    GET_WEEKLY_QUESTIONS_DONE: (state, { data }) => ({
        ...state,
        loadingQuestions: true,
        open: data.length > 0,
        weeklyQuestionsData: _mapQuestionsForView(data),
    }),
    GET_PRE_ADDITIONAL_QUESTIONS_DONE: (state, { data }) => ({
        ...state,
        loadingQuestions: true,
        openPredAdditionalQuestion: data.length > 0,
        preAdditionalQuestionsData: _mapQuestionsForView(data),
    }),
    SET_SEEN_PERCENTAGE_OF_WEEKLY_QUESTION: (state, { index }) => ({
        ...state,
        weeklyQuestionsData: state.weeklyQuestionsData.map((ele, i) => {
            if (i === index) {
                ele.seenPercentage = 100;
            }
            return ele;
        }),
    }),
    SET_SEEN_PERCENTAGE_OF_PRE_ADDITIONAL_QUESTION: (state, { index }) => ({
        ...state,
        preAdditionalQuestionsData: state.preAdditionalQuestionsData.map((ele, i) => {
            if (i === index) {
                ele.seenPercentage = 100;
            }
            return ele;
        }),
    }),
    POST_WEEKLY_QUESTION_ANSWER_DONE: (state, { payload }) => ({
        ...state,
        questionResponseFromDb: payload,
    }),
    POST_PRE_ADDITIONAL_QUESTION_ANSWER_DONE: (state, { payload }) => ({
        ...state,
        preAdditionalquestionResponseFromDb: payload,
    }),
    CLOSE_WEEKLY_QUESTIONS_MODAL: state => ({
        ...state,
        open: false,
    }),
    CLOSE_PRE_ADDITIONAL_QUESTIONS_MODAL: state => ({
        ...state,
        openPredAdditionalQuestion: false,
    }),
    GET_CHAPTER_INTRO_DONE: (state, { data }) => ({
        ...state,
        chapterIntro: data,
    }),
    DONT_SHOW_WEEKLY_QUESTION_FROM_HERE: state => ({
        ...state,
        dontShowWeeklyQuestionsFromPreAdditionalQuestion: true,
    }),
});

export default weeklyQuestionsReducer;
