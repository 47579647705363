import React from 'react';
import PropTypes from 'prop-types';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import InspireIcon from '../assets/inspireonlylogo.svg';
import BackIcon from '../assets/back.svg';
import './index.scss';
import PreviewHeaderImage from '../PreviewHeaderImage';
import BookmarkIcon from '../assets/shared.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';
import AuthStore from '../../../common/AuthStore';

export default function MobileProfile({
    handleDrawerToggle,
    profilePic,
    selectedMenu,
    logoutUser,
    handleClickOnMore,
    userType,
    setUserType,
    history,
    setOptionSelected,
    getUserProgressData,
    storeTeamMembersList,
    setShowPreferencesModal,
    setShowUserActivity,
    storeFeedFilters,
    notificationCount,
    setShowNotificationCenter,
    storeNotificationsList,
    resetPageNumber,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        faqlink,
        privacyPolicylink,
        taclink,
        myProgress,
        myActivity,
        notifications,
        contactUs,
        FAQs,
        privacyPolicy,
        logout,
        termsCondition,
        editPreferencesHeading,
        myGoals,
        saved,
        content,
    } = localisationsAfterLogin;

    const menu = [
        {
            name: notifications,
            key: 13,
        },
        {
            name: editPreferencesHeading,
            key: 11,
        },
        {
            name: `${saved} ${content}`,
            key: 1,
        },
        {
            name: myProgress,
            key: 2,
        },
        {
            name: myActivity,
            key: 12,
        },
        {
            name: myGoals,
            key: 5,
        },
        {
            name: contactUs,
            key: 6,
        },

        {
            name: FAQs,
            key: 7,
        },
        {
            name: termsCondition,
            key: 8,
        },
        {
            name: privacyPolicy,
            key: 9,
        },
        {
            name: logout,
            key: 10,
        },
    ];

    const handleClose = event => {
        const { currentTarget: { dataset: { vkey } } } = event;
        const key = Number(vkey);
        setOptionSelected(true);
        if (key === 10) {
            logoutUser();
            localStorage.removeItem('userProfile');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('status');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('selectedMenu');
            localStorage.removeItem('selectedLanguage');
            localStorage.removeItem('languageIsRightToLeft');
            storeTeamMembersList([]);
            storeNotificationsList([]);
            resetPageNumber();
            storeFeedFilters(null);
            AuthStore.deleteccessToken();
            setTimeout(() => {
                history.push('/');
            }, 500);
        } else if (key === 2) {
            getUserProgressData();
        } else if (key === 5) {
            selectedMenu(3);
            handleDrawerToggle();
            // setUserType('aaa');
        } else if (key === 6) {
            window.open('mailto:mpc0001@mckinsey.com');
        } else if (key === 1) {
            handleClickOnMore();
            handleDrawerToggle();
            // setUserType('aaa');
        } else if (key === 7) {
            window.open(faqlink);
        } else if (key === 8) {
            window.open(taclink);
        } else if (key === 9) {
            window.open(privacyPolicylink);
        } else if (key === 11) {
            setShowPreferencesModal(true);
        } else if (key === 12) {
            setShowUserActivity(true);
            handleDrawerToggle();
        } else if (key === 13) {
            setShowNotificationCenter(true);
            handleDrawerToggle();
        }
    };

    return (
        <>
            {userType === -1 ? (
                <div className="closeIcon" onClick={handleDrawerToggle}>
                    <Cross color="black" />
                </div>
            ) : (
                <div className="closeIcon" onClick={() => setUserType(-1)}>
                    <img src={BackIcon} alt="back" />
                </div>
            )}
            {userType === -1 && (
                <div className="mobile_profile_container">
                    <div className="upper">
                        <div className="inspire_icon">
                            <img src={InspireIcon} alt="inspireonly" />
                        </div>
                        <div className="mobile_profile_picture_wrapper">
                            <div className="image_wrapper">
                                <div
                                    className=" outline-none"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <svg width="0" height="0" viewBox="0 0 42 43">
                                        <clipPath id="myMobileHeaderPath">
                                            <path
                                                id="prefix__a"
                                                d="M21.102 3.594c-18.78 8.8-25.453 42.402-18.29 68.719C8.586 93.53 62.9 122.005 84.65 88.8c25.792-39.375 14.354-64.972 0-76.37C73.869 3.868 39.88-5.205 21.1 3.594z"
                                            />
                                        </clipPath>
                                    </svg>
                                    <div className="header_image_svg_wrapper" role="none">
                                        <div className="header_image_adjusted">
                                            <PreviewHeaderImage
                                                src={profilePic}
                                                alt="image"
                                                width="100px"
                                                height="104px"
                                                id="#myMobileHeaderPath"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user_details">
                                <span>{localStorage.userProfile && JSON.parse(localStorage.userProfile).username}</span>
                                <span>{localStorage.userProfile && JSON.parse(localStorage.userProfile).email}</span>
                            </div>
                        </div>
                    </div>
                    <div className="options">
                        {menu.map(v => (
                            <div key={v.key} data-vkey={v.key} onClick={handleClose} className="option">
                                <span>{v.name}</span>
                                <span>{v.value}</span>
                                {v.key === 1 && <img src={BookmarkIcon} alt="saved_content" />}
                                {v.key === 13 && (
                                    <span style={{ color: '#d63f1c', fontSize: '16px', fontWeight: 500 }}>
                                        {notificationCount > 0 ? notificationCount : ''}
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

MobileProfile.defaultProps = {
    handleClickOnMore: () => {},
};

MobileProfile.propTypes = {
    handleDrawerToggle: PropTypes.func.isRequired,
    profilePic: PropTypes.string.isRequired,
    selectedMenu: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    handleClickOnMore: PropTypes.func,
    userType: PropTypes.number.isRequired,
    setUserType: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    setOptionSelected: PropTypes.func.isRequired,
    getUserProgressData: PropTypes.func.isRequired,
    storeTeamMembersList: PropTypes.func.isRequired,
    setShowPreferencesModal: PropTypes.func.isRequired,
    setShowUserActivity: PropTypes.func.isRequired,
    storeFeedFilters: PropTypes.func.isRequired,
    notificationCount: PropTypes.number.isRequired,
    setShowNotificationCenter: PropTypes.func.isRequired,
    storeNotificationsList: PropTypes.func.isRequired,
    resetPageNumber: PropTypes.func.isRequired,
};
