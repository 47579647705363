import endpoints from '../../../Endpoints';
import { webApiAuthGet, webApiAuthPut } from '../../../webapis/core';

export const getLanguagesApi = (tag, baseUrl) => {
    const { getEmailLanguages } = endpoints;
    const base = window.atob(baseUrl);
    const url = `${base}/${getEmailLanguages}${tag}/`;
    return webApiAuthGet(url).request;
};

export const postEmailLaguageApi = (payload, baseurl) => {
    const { postEmailLanguages } = endpoints;
    const base = window.atob(baseurl);
    const url = `${base}/${postEmailLanguages}`;
    return webApiAuthPut(url, payload).request;
};
