import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    loading: false,
    activities: [],
};

const userActivityReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_USER_ACTIVITY_DATA}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_USER_ACTIVITY_DATA}_DONE`]: (state, { data }) => ({
        ...state,
        loading: false,
        activities: data,
    }),
});

export default userActivityReducer;
