import { call, put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { storeLanguagesData, languageChanged } from './actions';
import { postEmailLaguageApi, getLanguagesApi } from './apis';
import { apiFailed } from '../../../common/actions';
import { getOpenLinkTranslationsSaga } from '../../../common/sagas';

function* getLanguagesSaga({ tag, baseUrl, email }) {
    try {
        const url = window.atob(baseUrl);
        yield getOpenLinkTranslationsSaga({ baseUrl: url, email });
        const { data: { data } } = yield call(getLanguagesApi, tag, baseUrl);
        yield put(storeLanguagesData(data));
    } catch (e) {
        yield put(storeLanguagesData([]));
    }
}

function* setLanguage({ payload, baseurl, email }) {
    try {
        yield put(languageChanged(true));
        yield call(postEmailLaguageApi, payload, baseurl);
        const url = window.atob(baseurl);
        yield getOpenLinkTranslationsSaga({ baseUrl: url, email });
        yield put(languageChanged(false));
    } catch (e) {
        yield put(apiFailed([]));
    }
}

const emailLanguageSelectionSaga = [
    takeLatest(`${types.GET_LANGUAGES}_PENDING`, getLanguagesSaga),
    takeLatest(`${types.POSt_LANG_CHANGE}_PENDING`, setLanguage),
];

export default emailLanguageSelectionSaga;
