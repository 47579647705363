import React, { Fragment, useEffect, useState, useRef } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import WarningModal from '../../../components/WarningModal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import { editGoalApi, goalQuestionApi, completeGoalApi, getGoalApi } from '../../HomeContainer/redux/apis';
import CustomDateTimePicker from '../../../components/DateTimePicker';
import CustomDatePicker from '../../../components/CustomDatePicker';
import pencilIcon from '../../../assets/pencil.svg';
import { fontColors, fontFamilies } from '../../../common/fontMap';
import Actionbutton from '../../../components/ActionButton';
import Text from '../../../components/Text';
import Loader from '../../../components/Loader';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { pageAnalytics, handleGoogleClick, handleOutlookClick, useAddToCalendar } from '../../../utils/functions';
import { CODE_BAD_REQUEST, GOAL_DETAIL_VIEWS } from '../../../utils/constants';

const EditGoal = ({ closeModal, editCallBack, goalObj, goalView }) => {
    // const questionMap = {
    //     text_area: 'goal_text',
    //     date: 'due_date',
    // };
    const [goalDetail, setGoalDetail] = useState(null);
    const [goal, setGoal] = useState({
        goal_text: goalDetail?.goal_text,
        due_date: goalDetail?.due_date?.replace('Z', ''),
    });
    const [editError, setEditError] = useState({
        goal_text: null,
    });

    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        editGoal: _editGoal,
        dueDate,
        save,
        markAsComplete,
        add_to_calender,
        google,
        outlook,
        saveChanges,
        completed,
        goal_mandatory,
        vision_details,
        goal_details,
    } = localisationsAfterLogin;

    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [error, errorCallBack] = useState(false);
    const [currentView, setCurrentView] = useState(goalView);
    const [initialView, setInitialView] = useState(goalView);

    const [goalQuestions, setGoalQuestions] = useState([]);
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    const addedToCalendar = JSON.parse(localStorage.getItem('addedToCalendar'));
    useAddToCalendar();

    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0);
    const textareaRef = useRef(null);

    const goalId = goalObj.id;

    const fetchGoalDetail = () => {
        getGoalApi(goalId)
            .then(response => {
                const data = response?.data?.data || {};
                setGoalDetail({ ...data });
                if (!goalView) {
                    const _view = data.is_completed ? GOAL_DETAIL_VIEWS.EDIT : GOAL_DETAIL_VIEWS.VIEW;
                    setInitialView(_view);
                    setCurrentView(_view);
                }
                setInitialLoading(false);
            })
            .catch(() => {
                setInitialLoading(false);
            });
    };

    useEffect(() => {
        if (goalDetail) {
            setGoal({
                goal_text: goalDetail.goal_text,
                due_date: goalDetail.due_date?.replace('Z', ''),
            });
        }
    }, [goalDetail]);

    useEffect(() => {
        fetchGoalDetail();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (goalView) {
            setCurrentView(goalView);
            setInitialView(goalView);
        }
    }, [goalView]);

    useEffect(() => {
        if (textareaRef && textareaRef.current) {
            const textAreaEl = textareaRef.current;
            textAreaEl.setSelectionRange(textAreaEl.value.length, textAreaEl.value.length);
            textAreaEl.focus();
        }
        // eslint-disable-next-line
    }, [currentView]);

    useEffect(() => {
        setIsLoading(true);
        goalQuestionApi().then(response => {
            let sortedQuestions = response.data.data;
            sortedQuestions = sortedQuestions.sort((a, b) => a.sequence - b.sequence);
            setGoalQuestions(sortedQuestions);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (initialView === GOAL_DETAIL_VIEWS.EDIT) {
            pageAnalytics.initialise(`goalEdit/${goalId}`);
            return () => {
                pageAnalytics.complete(`goalEdit/${goalId}`);
            };
        }
        if (initialView === GOAL_DETAIL_VIEWS.VIEW) {
            pageAnalytics.initialise(`goalDetail/${goalId}`);
            return () => {
                pageAnalytics.complete(`goalDetail/${goalId}`);
            };
        }
        return () => {};
    }, [goalId, initialView]);

    const handleCloseView = () => {
        if (initialView === GOAL_DETAIL_VIEWS.EDIT && currentView === GOAL_DETAIL_VIEWS.EDIT) {
            closeModal(false);
        } else if (initialView === GOAL_DETAIL_VIEWS.VIEW && currentView === GOAL_DETAIL_VIEWS.EDIT) {
            pageAnalytics.complete(`goalEdit/${goalId}`);
            setCurrentView(GOAL_DETAIL_VIEWS.VIEW);
        } else if (initialView === GOAL_DETAIL_VIEWS.VIEW && currentView === GOAL_DETAIL_VIEWS.VIEW) {
            closeModal(false);
            editCallBack();
        }
    };

    const editCallBackHandler = () => {
        if (initialView === GOAL_DETAIL_VIEWS.EDIT && currentView === GOAL_DETAIL_VIEWS.EDIT) {
            closeModal(false);
            editCallBack();
        } else if (initialView === GOAL_DETAIL_VIEWS.VIEW && currentView === GOAL_DETAIL_VIEWS.EDIT) {
            pageAnalytics.complete(`goalEdit/${goalId}`);
            setInitialLoading(true);
            fetchGoalDetail();
            setCurrentView(GOAL_DETAIL_VIEWS.VIEW);
        }
    };

    const onClickEdit = () => {
        pageAnalytics.initialise(`goalEdit/${goalId}`);
        setGoal({
            goal_text: goalDetail.goal_text,
            due_date: goalDetail.due_date?.replace('Z', ''),
        });
        setCurrentView(GOAL_DETAIL_VIEWS.EDIT);
    };

    const setGoalText = goal_text => {
        setGoal({
            ...goal, goal_text,
        });
    };

    const setDueDate = due_date => {
        setEditError({
            ...editError,
            due_date: null,
        });
        setGoal({
            ...goal, due_date,
        });
    };

    const completeGoal = () => {
        completeGoalApi(goalId)
            .then(() => {
                if (initialView === GOAL_DETAIL_VIEWS.VIEW) {
                    pageAnalytics.complete(`goalEdit/${goalId}`);
                }
                closeModal();
                editCallBack(goalId);
            });
    };

    const editGoal = () => {
        const goalText = (goal.goal_text || '').trim();
        if (!goalText) {
            setEditError({
                ...editError,
                goal_text: true,
            });
            return;
        }
        setIsLoading(true);
        editGoalApi(goalId, {
            ...goal,
            goal_text: goalText,
        }).then(() => {
            setIsLoading(false);
            editCallBackHandler();
        }).catch(error => {
            setIsLoading(false);
            if (error?.response?.status === CODE_BAD_REQUEST) {
                const errorMessage = error?.response?.data?.message?.[0] || '';
                if (errorMessage.toLowerCase().includes('date')) {
                    setEditError({
                        ...editError,
                        due_date: errorMessage,
                    });
                } else {
                    setEditError({
                        ...editError,
                        message: errorMessage,
                    });
                }
            }
        });
    };

    const handleGoalTextChange = event => {
        setGoalText(event.target.value);
        setEditError({
            ...editError,
            goal_text: false,
        });
    };

    const getErrorView = (err, message, isCenter) => (err ? (
        <div className="goal-error">
            <Text
                fontsize="0.8rem"
                fontcolor={fontColors.errorRed}
                fontfamily={fontFamilies.TheinhardtPanRegular}
                margin={isCenter ? '0.5rem auto 0' : '0.5rem 0 0 0'}
                width={isCenter ? 'fit-content' : 'auto'}
            >
                {message}
            </Text>
        </div>
    ) : null);

    const getPGDateView = () => (goalDetail.is_due_date_display ? (
        <div className="editable_item">
            <div className="textarea">
                <div className="goal-duedate-container">
                    <div className="date-container">
                        <div className="dueDate-title">{dueDate}</div>
                        <CustomDatePicker
                            errorCallBack={errorCallBack}
                            handleDateChange={setDueDate}
                            selectedDate={currentView === GOAL_DETAIL_VIEWS.VIEW ? goalDetail.due_date?.replace('Z', '') : (goal.due_date || minDate)}
                            showTextState
                        />
                    </div>
                    <div className="add-calendar-container">
                        <div className={`${(addedToCalendar?.qId === goalId && addedToCalendar?.set) ? 'dueDate-title blue-text' : 'dueDate-title grey-text'}`}>{add_to_calender}</div>
                        <div
                            className="calendar-options"
                            style={{
                                height: '24px',
                            }}
                        >
                            <div className="calendar-option-text" onClick={() => handleGoogleClick(currentView === GOAL_DETAIL_VIEWS.VIEW ? goalDetail.due_date?.replace('Z', '') : (goal.due_date || minDate), goalId)}>{google}</div>
                            <div className="calendar-option-text" onClick={() => handleOutlookClick(currentView === GOAL_DETAIL_VIEWS.VIEW ? goalDetail.due_date?.replace('Z', '') : (goal.due_date || minDate), goalId)}>{outlook}</div>
                        </div>
                    </div>
                </div>
            </div>
            {getErrorView(editError?.due_date, editError?.due_date)}
        </div>
    ) : null);

    const showView = () => {
        const views = goalQuestions.map(question => {
            switch (question.question_type) {
                case 'date':
                    return (isClientPG ? getPGDateView()
                        : (
                            <Fragment>
                                <div className="editable_item">
                                    <div className="title">{dueDate}</div>
                                    <div className="textarea">
                                        <CustomDateTimePicker
                                            errorCallBack={errorCallBack}
                                            handleDateChange={setDueDate}
                                            selectedDate={goal.due_date}
                                        />
                                    </div>
                                    {getErrorView(editError?.due_date, editError?.due_date)}
                                </div>
                            </Fragment>
                        )
                    );
                case 'text_area':
                    return (
                        <Fragment>
                            <div className="editable_item">
                                {!isClientPG && <div className="title" />}
                                <div className="question">{question.question}</div>
                                <textarea
                                    className="goal-textarea"
                                    onChange={handleGoalTextChange}
                                    value={goal.goal_text}
                                    ref={textareaRef}
                                    style={editError.goal_text ? { borderColor: fontColors.errorRed } : {}}
                                />
                                {getErrorView(editError?.goal_text, goal_mandatory)}
                            </div>
                        </Fragment>
                    );
                default:
                    return null;
            }
        });

        return views;
    };

    const getPGEditGoalView = () => (
        <>
            <div className="divider" />
            <Text
                fontsize="14px"
                fontweight={500}
                fontcolor={fontColors.siteGrey}
                fontfamily={fontFamilies.MckinseySansMedium}
                lineHeight="20px"
                margin="0 0 20px 0"
            >
                {goalDetail.title}
            </Text>
            {goalQuestions.length > 0 && (
            <div className="edit-goal-content">
                {!goalDetail.is_completed ? (
                    <>
                        {showView()}
                        <div className="divider" />
                        <div className="action-button-wrapper">
                            {goalDetail.is_complete_btn_display && (
                            <Actionbutton
                                fontSize="18px"
                                fontFamily={fontFamilies.MckinseySansRegular}
                                fontColor={fontColors.electricBlue}
                                bgColor={fontColors.siteWhite}
                                border={`2px solid ${fontColors.electricBlue}`}
                                disabled={error}
                                width="300px"
                                height="54px"
                                padding="10px"
                                margin="0 20px 0 0"
                                onClick={() => {
                                    completeGoal();
                                }}
                            >
                                {markAsComplete}
                            </Actionbutton>
                            )}
                            <Actionbutton
                                fontSize="18px"
                                fontFamily={fontFamilies.MckinseySansRegular}
                                fontColor={fontColors.siteWhite}
                                bgColor={fontColors.electricBlue}
                                disabled={error}
                                width="300px"
                                height="54px"
                                padding="10px"
                                onClick={() => {
                                    editGoal(goalId);
                                }}
                            >
                                {save}
                            </Actionbutton>
                        </div>
                    </>
                ) : <div className="completed-goal-text">{goalDetail.goal_text}</div>}
                {getErrorView(editError?.message, editError?.message, true)}
            </div>
            )}
        </>
    );

    const showDetailView = () => {
        const views = goalQuestions.map(question => {
            switch (question.question_type) {
                case 'date':
                    return goalDetail?.due_date ? (
                        <Fragment>
                            <div className="divider" />
                            {getPGDateView()}
                        </Fragment>
                    ) : null;
                case 'text_area':
                    return (
                        <Fragment>
                            <div className="editable_item">
                                <div
                                    className="goal-textarea"
                                >
                                    <div className="textarea-content">
                                        {goalDetail.goal_text}
                                    </div>
                                    <img src={pencilIcon} alt="edit" onClick={onClickEdit} className="edit-icon" />
                                </div>
                            </div>
                        </Fragment>
                    );
                default:
                    return null;
            }
        });

        return views;
    };

    const getDetailContent = () => (
        goalQuestions.length > 0 ? (
            <div className="goal-view-detail-content">
                {showDetailView()}
            </div>
        ) : null
    );

    const getTitle = () => {
        if (currentView === GOAL_DETAIL_VIEWS.VIEW) {
            if ((goalDetail?.goal_category || '').toLowerCase() === 'vision') {
                return vision_details;
            }
            return goal_details;
        }
        if (goalDetail?.is_completed) {
            return completed;
        }
        return _editGoal;
    };

    return (
        <WarningModal classNames="edit-goal-modal" isClientPG={isClientPG}>
            {goalDetail ? (
                <div className={`edit-goal-view${isClientPG ? ' edit-goal-view-pg' : ''}`}>
                    <div className="header">
                        <div>{getTitle()}</div>
                        <div
                            className="cross"
                            onClick={() => {
                                handleCloseView();
                            }}
                        >
                            <Cross color="black" />
                        </div>
                    </div>
                    {currentView === GOAL_DETAIL_VIEWS.VIEW ? getDetailContent() : null}
                    {(isClientPG && currentView !== GOAL_DETAIL_VIEWS.VIEW)
                        ? getPGEditGoalView()
                        : (goalQuestions.length > 0 && currentView !== GOAL_DETAIL_VIEWS.VIEW) && (
                        <div className="edit-goal-content">
                            {showView() }
                            <Actionbutton
                                fontSize="14px"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.siteWhite}
                                bgColor={fontColors.electricBlue}
                                disabled={error}
                                width="40%"
                                height="auto"
                                padding="10px"
                                onClick={() => {
                                    editGoal(goalId);
                                }}
                            >
                                {saveChanges}
                            </Actionbutton>
                            {getErrorView(editError?.message, editError?.message)}
                        </div>
                        )}
                </div>
            ) : null}
            {(isLoading || initialLoading) && <Loader showBackground position={!isClientPG ? 'absolute' : 'fixed'} />}
        </WarningModal>
    );
};

EditGoal.defaultProps = {
    goalView: null,
};

EditGoal.propTypes = {
    goalObj: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
    goalView: PropTypes.oneOf(Object.values(GOAL_DETAIL_VIEWS)),
};

export default EditGoal;
