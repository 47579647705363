import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InspireLoader from '../InspireLoader/InspireLoader';

import { OAuthGetToken } from '../../containers/UnAuthenticatedContent/actions';

function CallbackComponent(props) {
    const { OAuthGetToken, history } = props;

    const OAuthGetTokenCall = useCallback(OAuthGetToken, []);

    useEffect(() => {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const code = url.searchParams.get('code');
        const state = url.searchParams.get('state');
        OAuthGetTokenCall(code, state, history);
    }, [OAuthGetTokenCall, history]);

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <InspireLoader isLoading />
        </div>
    );
}

CallbackComponent.propTypes = {
    OAuthGetToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const dispatchToProps = {
    OAuthGetToken,
};

export default withRouter(connect(null, dispatchToProps)(CallbackComponent));
