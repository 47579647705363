import {
    LOGOUT,
    THROW_ERROR,
    THROW_SUCCESS,
    REMOVE_NOTIFICATION,
    API_FAILED,
    STORE_API_SEQUENCE,
    STORE_API_SEQUENCE_INDEX,
    CALL_NEXT_API_IN_SEQUNCE, CLIENT_EXPIRED, RESET_CLIENT_EXPIRED,
} from '../utils/constants';

export const logoutBackend = () => ({ type: LOGOUT });

export const logoutBackendFailure = payload => ({
    type: `${LOGOUT}_FAILED`,
    payload,
});

export const storeError = payload => ({
    type: THROW_ERROR,
    payload,
});

export const storeSuccess = payload => ({
    type: THROW_SUCCESS,
    payload,
});

export const removeNotification = () => ({
    type: REMOVE_NOTIFICATION,
});

export const apiFailed = message => ({
    type: API_FAILED,
    message,
});

export const storeApiSequence = data => ({
    type: STORE_API_SEQUENCE,
    data,
});

export const storeApiIndex = data => ({
    type: STORE_API_SEQUENCE_INDEX,
    data,
});

export const getNextApiInSequence = () => ({
    type: CALL_NEXT_API_IN_SEQUNCE,
});

export const clientExpiredAction = () => ({
    type: CLIENT_EXPIRED,
});

export const resetClientExpiredAction = () => ({
    type: RESET_CLIENT_EXPIRED,
});
