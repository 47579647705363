import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function SavedPosts({ savedPosts, handleClickOnMore, history }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { saved, content, showMore, minRead } = localisationsAfterLogin;

    const handleClickOnArticle = e => {
        const { currentTarget: { dataset: { articleid } } } = e;
        history.push(`/feed-details-article/${articleid}`);
    };

    return (
        <Fragment>
            <div className="savedPosts">
                <div className="bookmarksHeading">
                    <div className="textHeading">
                        <div className="topText">{saved}</div>
                        <div className="bottomText">{content}</div>
                    </div>
                    <div className="savedImage" onClick={handleClickOnMore} role="none" />
                </div>
                <div>
                    {savedPosts.slice(0, 3).map(x => (
                        <div
                            className="articleBrief"
                            key={x.article}
                            data-articleid={x.article}
                            onClick={handleClickOnArticle}
                            role="none"
                        >
                            <div className="title">{x.title}</div>
                            <div className="timings">{`${x.readTime} ${minRead}`}</div>
                        </div>
                    ))}
                </div>
                {savedPosts.length > 3 && (
                    <span className="more_posts" onClick={handleClickOnMore} role="none">
                        {showMore}
                    </span>
                )}
            </div>
        </Fragment>
    );
}

SavedPosts.defaultProps = {
    handleClickOnMore: () => {},
};

SavedPosts.propTypes = {
    savedPosts: PropTypes.array.isRequired,
    handleClickOnMore: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
