import { takeLatest, put, select } from 'redux-saga/effects';
import types from './types';
import { apiFailed } from '../../../common/actions';
import {
    storeNotificationsList,
    markThisNotificationAsRead,
    toggleReachedEndOfNotificationsList,
    storeMoreNotificationsList,
} from './actions';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getNotificationsListApi, readThisNotificationApi, markAllAsReadApi } from './apis';

function* getNotificationsListSaga() {
    try {
        const state = yield select();
        const { pageNumber } = state.get('notificationsListReducer');

        const { data } = yield tokenValidatorGenerator({
            saga: getNotificationsListApi,
            payload: [AuthStore.accessToken, pageNumber],
        });
        yield put(storeNotificationsList(data.data));
    } catch (e) {
        if (e.response.status === 404 && e.response.data.detail === 'Invalid page.') {
            yield put(toggleReachedEndOfNotificationsList(true));
        }
        yield put(apiFailed(e));
    }
}

function* getNotificationsListMoreSaga() {
    try {
        const state = yield select();
        const { pageNumber } = state.get('notificationsListReducer');

        const { data } = yield tokenValidatorGenerator({
            saga: getNotificationsListApi,
            payload: [AuthStore.accessToken, pageNumber],
        });
        yield put(storeMoreNotificationsList(data.data));
    } catch (e) {
        if (e.response.status === 404 && e.response.data.detail === 'Invalid page.') {
            yield put(toggleReachedEndOfNotificationsList(true));
        }
        yield put(apiFailed(e));
    }
}

function* readThisNotificationSaga({ id }) {
    try {
        yield tokenValidatorGenerator({
            saga: readThisNotificationApi,
            payload: [AuthStore.accessToken],
            body: {
                notification_id: id,
            },
        });
        yield put(markThisNotificationAsRead(id));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* markAllAsReadSaga() {
    try {
        yield tokenValidatorGenerator({
            saga: markAllAsReadApi,
            payload: [AuthStore.accessToken],
            body: {},
        });
        const userProfile = JSON.parse(localStorage.userProfile);
        let { notification_count } = userProfile;
        notification_count = 0;
        userProfile.notification_count = notification_count;
        localStorage.setItem('userProfile', JSON.stringify(userProfile));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const notificationsListSaga = [
    takeLatest(`${types.GET_NOTIFICATIONS_LIST}_PENDING`, getNotificationsListSaga),
    takeLatest(`${types.READ_THIS_NOTIFICATION}_PENDING`, readThisNotificationSaga),
    takeLatest(`${types.MARK_ALL_AS_READ}_PENDING`, markAllAsReadSaga),
    takeLatest(`${types.GET_NOTIFICATIONS_LIST_MORE}_PENDING`, getNotificationsListMoreSaga),
];

export default notificationsListSaga;
