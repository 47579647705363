import { connect } from 'react-redux';
import MyProgressComponent from './MyProgressComponent';
import myProgressReducer from './redux/reducer';
import myProgressSaga from './redux/sagas';
import { getUserProgressData, closeUserProgress } from './redux/actions';
import { pageAnalytics } from '../Feeds/redux/actions';

const stateToProps = state => {
    const MyProgressReducer = state.get('myProgressReducer');
    return {
        userProgressData: MyProgressReducer.userProgressData,
        open: MyProgressReducer.open,
    };
};

export { myProgressSaga, myProgressReducer };

const dispatchToProps = {
    getUserProgressData,
    closeUserProgress,
    pageAnalytics,
};

export default connect(stateToProps, dispatchToProps)(MyProgressComponent);
