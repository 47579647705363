/* eslint-disable no-use-before-define */
import axios, { CancelToken } from 'axios';
import endpoints from '../Endpoints';

export function getConfig(Authorization) {
    let cancel;
    const config = {
        cancelToken: new CancelToken(c => {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Authorization}`,
            'Cache-Control': 'no-store',
        },
    };
    return {
        cancel,
        config,
    };
}

export const webApiGet = (accessToken, url) => {
    const data = getConfig(accessToken);
    return {
        request: axios.get(url, data.config),
        cancel: data.cancel,
    };
};

export const webApiPut = (accessToken, url, options) => {
    const config = getConfig(accessToken);
    return {
        request: axios.put(url, options, config.config),
        cancel: config.cancel,
    };
};

export function webApiPost(accessToken, url, options, removeCacheControl) {
    const config = getConfig(accessToken);
    if (removeCacheControl) {
        delete config.config.headers['Cache-Control'];
    }
    return {
        request: axios.post(url, options, config.config),
        cancel: config.cancel,
    };
}

export function webApiDelete(accessToken, url, data) {
    const config = getConfig(accessToken);
    return {
        request: axios.delete(url, { ...config.config, data }),
        cancel: config.cancel,
    };
}

export function webApiAuthPost(url, options) {
    return {
        request: axios.post(url, options),
    };
}

export function webApiAuthPut(url, options) {
    return {
        request: axios.put(url, options),
    };
}

export function webApiAuthGet(url) {
    return {
        request: axios.get(url),
    };
}

export const getTokenFromRefreshToken = payload => {
    const { apiBasePath, refreshToken } = endpoints;
    const url = apiBasePath + refreshToken;
    return webApiAuthPost(url, payload).request;
};

export function webApiPostFormData(accessToken, url, options) {
    return {
        request: axios.post(url, options, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'content-type': 'multipart/form-data',
                'Cache-Control': 'no-cache',
            },
        }),
    };
}
