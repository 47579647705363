/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';

const INITIAL_STATE = {
    open: false,
    userProgressData: null,
    isLoading: false,
};

const myProgressReducer = createReducer(INITIAL_STATE, {
    GET_USER_PROGESS_PENDING: state => ({
        ...state,
        isLoading: true,
    }),
    GET_USER_PROGESS_DONE: (state, { data }) => ({
        ...state,
        open: true,
        userProgressData: data,
        isLoading: false,
    }),
    CLOSE_USER_PROGRESS: state => ({
        ...state,
        open: false,
    }),
});

export default myProgressReducer;
