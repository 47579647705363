export const getUserProgressData = () => ({
    type: 'GET_USER_PROGESS_PENDING',
});

export const storeUserProgressData = data => ({
    type: 'GET_USER_PROGESS_DONE',
    data,
});

export const closeUserProgress = () => ({
    type: 'CLOSE_USER_PROGRESS',
});
