const CTATypes = Object.freeze({ CTA_TYPE_INTERNAL: 1, CTA_TYPE_EXTERNAL: 2 });
const CTARouteTypes = Object.freeze({
    PROFILE_PROGRESS: 1,
    PROMPT_DETAIL: 2,
    PRACTICE_DETAIL: 3,
    CREATE_POST: 4,
    ROADBLOCK: 5,
});

export default class CTATypeMapper {
    constructor(history, type, externalUrl, id, internalUrl, practiceId) {
        this.history = history;
        this.type = type;
        this.internalUrl = internalUrl;
        this.externalUrl = externalUrl;
        this.id = id;
        this.practiceId = practiceId;
    }

    openUrl({ setShowCreatePost, setShowPrompt }) {
        if (this.type === CTATypes.CTA_TYPE_INTERNAL) {
            switch (this.internalUrl) {
                case CTARouteTypes.CREATE_POST:
                    setShowCreatePost(true);
                    break;
                case CTARouteTypes.PRACTICE_DETAIL:
                    this.history.push(`/app/practice/${this.practiceId}`);
                    break;
                case CTARouteTypes.PROFILE_PROGRESS:
                    this.history.push('/app/profile');
                    break;
                case CTARouteTypes.PROMPT_DETAIL:
                    setShowPrompt(true);
                    break;
                case CTARouteTypes.ROADBLOCK:
                    this.history.push('roadblock');
                    break;
                default:
                    break;
            }
        } else {
            window.open(this.externalUrl, '_blank');
        }
    }
}
