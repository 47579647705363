import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';
import PromptDetails, { PracticeStateType } from './PromptDetails';
import Text from '../../components/Text';
import { getTitleColor, getFontFamily, getIconColor } from './helper';
import SvgPicture from '../../components/SvgPicture';
import { getInProgressPractices } from '../PracticesContainer/redux/actions';

function ArticleThisPractice({
    article,
    // totalCount,
    practiceId,
    getPracticeDetails,
    setShowProTagAnimation,
    setProtagAnimData,
    getSubPromptDetails,
    setShowCreatePost,
}) {
    const [showPrmpt, setShowPrmpt] = useState(false);

    const handlePromptClick = e => {
        e.stopPropagation();
        if (article.state !== PracticeStateType.lockedOrNew) {
            setShowPrmpt(true);
        }
    };

    const handleCrossClick = (e, practiceId, subpromptId) => {
        e.stopPropagation();
        setShowPrmpt(false);
        if (subpromptId) getSubPromptDetails(subpromptId);
        if (practiceId) getPracticeDetails(practiceId);
    };

    const _getOpacity = () => {
        if (article.state === PracticeStateType.lockedOrNew) {
            return 0.5;
        }
        return 1.0;
    };

    const ArticleWrapper = styled.div`
        display: flex
        background-color:#ffffff;
        border-radius: 5px;
        margin: 0 0 1rem 0;
        justify-content: space-between;
        height:92px;
    `;

    const RightView = styled.div`
        display: flex;
        margin-right: 0.5rem;
        padding: 1rem;
        align-self: ${article.component_name ? 'auto' : 'center'};
    `;

    return (
        <ArticleWrapper onClick={handlePromptClick} key={`p-article-${article.id}`}>
            <div className="practice_article" style={{ opacity: _getOpacity() }}>
                {showPrmpt && (
                    <PromptDetails
                        article={article}
                        handleCrossClick={handleCrossClick}
                        practiceId={practiceId}
                        getPracticeDetails={getPracticeDetails}
                        setShowCreatePost={setShowCreatePost}
                        setShowPrompt={setShowPrmpt}
                        setShowProTagAnimation={setShowProTagAnimation}
                        setProtagAnimData={setProtagAnimData}
                        getInProgressPractices={getInProgressPractices}
                    />
                )}

                {article.component_name && (
                    <Text
                        fontsize="14px"
                        fontcolor={fontColors.borderGrey}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        fontWeight="400"
                        letterSpacing="1px"
                        textTransform="uppercase"
                        cursor="pointer"
                    >
                        {article.component_name}
                    </Text>
                )}
                <Text
                    fontsize="18px"
                    fontcolor={getTitleColor(article)}
                    fontfamily={getFontFamily(article)}
                    cursor="pointer"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    display="block"
                    width="600px"
                    textOverflow="ellipsis"
                    margin="10px 0 0 0"
                >
                    {article.title}
                </Text>
            </div>
            <RightView>
                {PracticeStateType.completed === article.state && (
                    <SvgPicture
                        height="1.5rem"
                        width="1.5rem"
                        style={{
                            backgroundColor: getIconColor(article),
                            borderRadius: '50%',
                        }}
                    >
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.14567 8.66667C3.98743 8.66667 3.8292 8.6047 3.70866 8.48141L0 4.68773L0.874008 3.79368L4.14567 7.14034L11.126 0L12 0.894044L4.58267 8.48141C4.46214 8.6047 4.3039 8.66667 4.14567 8.66667Z"
                                fill="white"
                            />
                        </svg>
                    </SvgPicture>
                )}
                {article.in_todo && (
                    <SvgPicture height="1.5rem" width="1.5rem">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.731 8.86551C17.731 3.98 13.751 0 8.86551 0C3.98 0 0 3.98 0 8.86551C0 10.8871 0.652805 12.7823 1.89524 14.3617C2.21111 14.7618 2.80074 14.825 3.20085 14.5091C3.60096 14.1932 3.66413 13.6036 3.34826 13.2035C2.35852 11.9611 1.85312 10.4659 1.85312 8.86551C1.85312 5.01186 4.9908 1.87418 8.84445 1.87418C12.6981 1.87418 15.8358 5.01186 15.8358 8.86551C15.8358 12.6349 12.8455 15.6884 9.13927 15.8568V14.3196C9.13927 13.751 8.50752 13.393 8.02318 13.7089L4.2327 16.1938C3.79048 16.4886 3.79048 17.1203 4.2327 17.3941L8.04424 19.879C8.52858 20.1948 9.16033 19.8579 9.16033 19.2683V17.71C13.9195 17.5626 17.731 13.6457 17.731 8.86551Z"
                                fill="#999999"
                            />
                            <path
                                d="M8.86553 3.91687C8.36013 3.91687 7.93896 4.33803 7.93896 4.84343V8.86555C7.93896 9.22354 8.14955 9.53941 8.46542 9.70788L11.224 11.0767C11.3504 11.1398 11.4978 11.182 11.6452 11.182C11.9821 11.182 12.3191 10.9924 12.4875 10.6555C12.7192 10.1922 12.5297 9.62365 12.0664 9.41307L9.8342 8.29698V4.84343C9.79209 4.33803 9.37092 3.91687 8.86553 3.91687Z"
                                fill="#999999"
                            />
                        </svg>
                    </SvgPicture>
                )}
            </RightView>
        </ArticleWrapper>
    );
}

const Article = {
    created_date: PropTypes.string,
    id: PropTypes.number,
    practice_sequence: PropTypes.number,
    show_type: PropTypes.string,
    state: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
};

ArticleThisPractice.defaultProps = {
    setShowProTagAnimation: () => {},
    setProtagAnimData: () => {},
};

ArticleThisPractice.propTypes = {
    article: PropTypes.shape(Article).isRequired,
    // totalCount: PropTypes.number.isRequired,
    practiceId: PropTypes.string.isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    setShowProTagAnimation: PropTypes.func,
    setProtagAnimData: PropTypes.func,
    getSubPromptDetails: PropTypes.func.isRequired,
    setShowCreatePost: PropTypes.func.isRequired,
};
export default ArticleThisPractice;
