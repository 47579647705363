/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';

const INITIAL_STATE = {
    users: [],
};

const tagUserReducer = createReducer(INITIAL_STATE, {
    GET_USERS_BY_QUERY_DONE: (state, { users }) => ({
        ...state,
        users: users.map(user => {
            user.name = `\u200b${user.full_name}\u200b`;
            user.title = user.full_name;
            user.avatar = user.profile_picture;
            return user;
        }),
    }),
});

export default tagUserReducer;
