import createReducer from '../../../utils/redux-utils/createReducer';
import * as types from './types';

const INITIAL_STATE = {
    dynamicCards: [],
    isLoading: false,
    recommendedPrompts: [],
    todos: [],
    goalLists: [],
    goalsCount: 0,
    firstTimeDataLoaded: false,
    updateGoals: false,
    isDeleted: false,
    isDeleting: false,
    hideGoalsSection: false,
    resetGoalTabValue: 0,
};

const HomeReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_HOME_PAGE}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_HOME_PAGE}_DONE`]: (state, { data }) => ({
        ...state,
        dynamicCards: data,
        isLoading: false,
    }),
    [`${types.GET_RECOMMENDED_PROMPTS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_RECOMMENDED_PROMPTS}_DONE`]: (state, { data }) => ({
        ...state,
        recommendedPrompts: data,
        firstTimeDataLoaded: true,
        isLoading: false,
    }),
    [`${types.GET_TODOS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_TODOS}_DONE`]: (state, { data }) => ({
        ...state,
        todos: data,
        isLoading: false,
    }),

    [`${types.GET_GOALS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_GOALS}_DONE`]: (state, { data, count, hide_goal_section }) => ({ ...state,
        goalLists: data,
        goalsCount: count,
        isLoading: false,
        hideGoalsSection: hide_goal_section }),
    [`${types.GET_GOALS_BY_CATEGORIES}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_GOALS_BY_CATEGORIES}_DONE`]: (state, { data, hide_goal_section }) => ({ ...state,
        goalLists: data,
        isLoading: false,
        hideGoalsSection: hide_goal_section }),
    [`${types.GET_GOALS_BY_CATEGORIES}_FAILED`]: state => ({
        ...state,
        isLoading: false,
        goalLists: [],
        hideGoalsSection: false,
    }),
    [`${types.DELETE_GOAL}_PENDING`]: state => ({
        ...state,
        isDeleting: true,
        isDeleted: false,
    }),

    [`${types.DELETE_GOAL}L_SUCCESS`]: (state, payload) => ({
        ...state,
        isDeleting: false,
        isDeleted: true,
        goalLists: state.goalLists.filter(item => item.id !== payload.payload.goalId),
    }),
    [`${types.CLOSE_CARD}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.CLOSE_CARD}_DONE`]: state => ({
        ...state,
        isLoading: false,
    }),
    [`${types.UPDATE_GOALS}`]: state => ({
        ...state,
        updateGoals: true,
    }),
    [`${types.RESET_GOAL_TAB}`]: state => ({
        ...state,
        resetGoalTabValue: state.resetGoalTabValue + 1,
    }),
});

export default HomeReducer;
