import React from 'react';
import PropTypes from 'prop-types';
import { DragImageIcon } from '../assets/svg';
import UndoSelectionIcon from '../assets/shape-2.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';
import createpostdropiconbg from '../../../assets/createpostdropiconbg.svg';

export default function DragAndDropZone({
    file,
    setFile,
    imgSrc,
    setImageSrc,
    setDraggedFile,
    setFileType,
    setShowCropper,
    view,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { dragAndDrop = 'Drag and drop or', clickTo = 'click here to', browse = 'browse' } = localisationsAfterLogin;

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        const reader = new FileReader();
        const file = e.dataTransfer.files[0];
        if (file) {
            reader.onloadend = () => {
                setImageSrc(reader.result);
                if (file.type.includes('video')) {
                    setDraggedFile(file);
                } else {
                    setDraggedFile(reader.result);
                    setShowCropper(true);
                }
                setFileType(file.type);
            };
            reader.readAsDataURL(file);
            setFile(file);
        }
        e.dataTransfer.clearData();
    };

    const setSelectedFile = e => {
        const reader = new FileReader();
        const file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                setImageSrc(reader.result);
                if (file.type.includes('video')) {
                    setDraggedFile(file);
                } else {
                    setDraggedFile(reader.result);
                    setShowCropper(true);
                }
                setFileType(file.type);
            };
            reader.readAsDataURL(file);
            setFile(file);
        }
    };

    const UndoSelection = e => {
        e.stopPropagation();
        setFile(null);
        setImageSrc('');
    };

    const previewer = !imgSrc || !file ? (
        <div className="previewer_container">
            <div className="drag_drop_image_container">
                <span
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={createpostdropiconbg} alt="" />
                    <DragImageIcon style={{ position: 'absolute' }} />
                </span>
                <span>
                    {dragAndDrop}
                    {' '}
                </span>
                <div className="clickhere">
                    <span>{clickTo}</span>
                    <span className="browse">{browse}</span>
                </div>
                <div className="file_container">
                    <input
                        type="file"
                        onChange={setSelectedFile}
                        onClick={e => e.stopPropagation()}
                        size="60"
                        accept={view === 2 ? '.jpeg,.jpg,.png,.gif' : '.mp4,.m4a,.m4v,.webm,.wmv'}
                        className="outline-none"
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="previewer_container">
            <div className="undo_selection" role="none" onClick={UndoSelection}>
                <img src={UndoSelectionIcon} alt="undo" />
            </div>

            {file.type !== 'video/mp4' ? (
                <img src={imgSrc} alt="draggedImage" />
            ) : (
                <video className="outline-none" src={imgSrc} width="100%" height="100%" controls>
                    <track kind="captions" />
                </video>
            )}
        </div>
    );

    return (
        <div
            className="drag_drop_zone"
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
        >
            {previewer}
        </div>
    );
}

DragAndDropZone.defaultProps = {
    file: {},
};

DragAndDropZone.propTypes = {
    setDraggedFile: PropTypes.func.isRequired,
    setFileType: PropTypes.func.isRequired,
    setShowCropper: PropTypes.func.isRequired,
    file: PropTypes.instanceOf(File),
    setFile: PropTypes.func.isRequired,
    imgSrc: PropTypes.string.isRequired,
    setImageSrc: PropTypes.func.isRequired,
    view: PropTypes.number.isRequired,
};
