import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function UpNextCard({ index, next_article_id, next_article_title, handleNextArticleClick }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { upNext } = localisationsAfterLogin;
    return (
        <div
            className="practice_upnext_card_container"
            key={`in-practice_upnext-card-${index}`}
            onClick={e => handleNextArticleClick(e, next_article_id)}
        >
            <Text
                fontsize="0.75rem"
                fontcolor={fontColors.siteGrey}
                fontfamily={fontFamilies.MckinseySansMedium}
                fontweight="500"
                letterSpacing="1px"
                textTransform="uppercase"
                cursor="pointer"
            >
                {upNext}
            </Text>
            <Text
                fontsize="1rem"
                fontcolor={fontColors.electricBlue}
                fontfamily={fontFamilies.MckinseySansMedium}
                fontweight="500"
                margin="10px 0 0.8rem 0"
                cursor="pointer"
                data-id={next_article_id}
            >
                {next_article_title}
            </Text>
        </div>
    );
}

UpNextCard.propTypes = {
    index: PropTypes.number.isRequired,
    next_article_id: PropTypes.number.isRequired,
    next_article_title: PropTypes.string.isRequired,
    handleNextArticleClick: PropTypes.func.isRequired,
};
