import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';

const ImageCropper = ({ image, setCroppedAreaPixels, fileType }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback(
        (croppedArea, croppedAreaPixelsVal) => {
            setCroppedAreaPixels(croppedAreaPixelsVal);
        },
        [setCroppedAreaPixels],
    );

    return (
        <div className="crop-container">
            <Cropper
                // cropSize={{
                //     width: 268,
                //     height: 211.023622047,
                // }}
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1.27}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                containerClassName="containerClassName"
                classes={{
                    cropAreaClassName: 'crop_area_in_ratio',
                }}
            />
            {fileType !== 'image/gif' && (
                <div className="controls">
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoomVal) => setZoom(zoomVal)}
                        classes={{ container: 'slider' }}
                    />
                </div>
            )}
        </div>
    );
};

ImageCropper.defaultProps = {
    fileType: 'image/jpeg',
};

ImageCropper.propTypes = {
    image: PropTypes.string.isRequired,
    setCroppedAreaPixels: PropTypes.func.isRequired,
    fileType: PropTypes.string,
};

export default ImageCropper;
