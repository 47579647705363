/* eslint-disable block-scoped-var */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Box from '@material-ui/core/Box';
// import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Loader from '../../components/Loader';
// import Login from './Login';
// import SignUp from './SignUp';
// import ForgotPassword from './ForgotPassword';
// import HelpContent from './HelpContent';
// import { InfoIcon, Cross } from './assets/svg';
// // import logo from './assets/get_the_app.png';
// import inspireIndividualsIcon from './assets/inspire_individuals_splash_icon.svg';
// import leftCovering from './assets/left_covering.png';
// import rightCovering from './assets/right_covering.png';
// import { SITE_TXT } from './constants';
// import { isMobile, isIpadProPortrait } from '../../utils/constants';
// import ChangeHeaderTab from './ChangeHeaderTab';
// import { getMobileOperatingSystem } from '../../utils/functions';
// import Footer from '../../components/Footer';
import LocalisationsStore from '../../common/LocalisationsStore';
// import LanguageSelect from './LanguageSelect';
import InputField from '../../components/InputField';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import './index.scss';
import InspireTopIcon from '../../assets/InspireTopIcon.svg';
import Endpoints from '../../Endpoints';
import { CODE_ACCOUNT_LOCK, CODE_UNAUTHORIZE_USER, EMAIL_PATTERN } from '../../utils/constants';
import { isValidPattern, pageAnalytics } from '../../utils/functions';
import particleBg from '../../assets/particleBg.svg';
import WarningModal from '../../components/WarningModal';
import crossIcon from '../../assets/cross.svg';

function UnAuthContent(props) {
    const {
        isLoading,
        error,
        resetScreen,
        // changeSignUp,
        // successScreen,
        // getUserData,
        // getChecksum,
        // initialLocalisations,
        oauth,
        // getAvailableLanguages,
        // availableLanguages,
        // postSelectedLanguage,
        history,
        domainValid,
        getToken,
        getTokenFailed,
        clientExpired,
    } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, togglePassword] = useState(false);
    const [feError, setFeError] = useState(
        { emailValidated: true, emailError: '', passValidated: true, passError: '' },
    );
    const { localisationsBeforeLogin } = LocalisationsStore;
    const {
        Inspire,
        InspireDescription,
        Email,
        LoginEmailText,
        password: passwordText,
        passwordInput,
        SignIn,
        ForgotYourPassword,
        continueAsGuest,
        Register,
        PleaseEnterEmail,
        EmailError,
        PleaseEnterPassword,
        loginFailed,
        AccountLock,
        errorOccurred,
        ok,
    } = localisationsBeforeLogin;
    // const [currentView, setCurrentView] = useState('');
    // const [showHelp, toggleHelpSection] = useState(false);
    // const [showPopup, toggleShowPopup] = useState(false);
    // const [showForgotPassword, setShowForgotPassword] = useState(false);
    // const [showLanguageSelection, setShowLanguageSelection] = useState(false);

    // const getChecksumCall = useCallback(getChecksum, []);

    // useEffect(() => {
    //     getChecksumCall(false);
    // }, [getChecksumCall]);

    // if (initialLocalisations) {
    //     // eslint-disable-next-line
    //     var { login, signup, forgotPassword, android_app_link, ios_app_link, languages } = localisationsBeforeLogin
    // }

    // useEffect(() => {
    //     if (initialLocalisations) {
    //         setCurrentView(login);
    //     }
    // }, [initialLocalisations, login]);

    // const changeScreenContent = (updateView, enteredUsername) => {
    //     resetScreen();
    //     toggleHelpSection(false);
    //     setCurrentView(updateView);
    //     setUsername(enteredUsername);
    // };

    // const closePopup = () => {
    //     toggleShowPopup(true);
    // };

    // const setForgotPassword = value => {
    //     setShowForgotPassword(value);
    //     resetScreen();
    // };

    // const getCurrentView = () => {
    //     switch (currentView) {
    //         case login:
    //             return (
    //                 <Login
    //                     changeScreen={changeScreenContent}
    //                     error={error}
    //                     setForgotPassword={setForgotPassword}
    //                     getUserData={getUserData}
    //                     signupUserName={username}
    //                     oauth={oauth}
    //                     setShowLanguageSelection={setShowLanguageSelection}
    //                 />
    //             );
    //         case signup:
    //             return <SignUp changeSignUp={changeSignUp} changeScreen={changeScreenContent} error={error} />;
    //         case forgotPassword:
    //             return (
    //                 <ForgotPassword
    //                     error={error}
    //                     username={username}
    //                     successScreen={successScreen}
    //                     changeScreen={changeScreenContent}
    //                     setForgotPassword={setForgotPassword}
    //                 />
    //             );
    //         case languages:
    //             return (
    //                 <LanguageSelect
    //                     getAvailableLanguages={getAvailableLanguages}
    //                     availableLanguages={availableLanguages}
    //                     postSelectedLanguage={postSelectedLanguage}
    //                     languagesLoading={isLoading}
    //                 />
    //             );
    //         default:
    //             return null;
    //     }
    // };

    // const getLeftImage = (h, w) => (
    //     <Box className="leftImage_wrapper">
    //         {' '}
    //         <img src={leftCovering} alt="left_covering" height={h} width={w} />
    //     </Box>
    // );

    // const getRightImage = (h, w) => (
    //     <Box className="rightImage_wrapper">
    //         <img src={rightCovering} alt="right_covering" height={h} width={w} />
    //     </Box>
    // );

    // const getOS = () => {
    //     const osType = getMobileOperatingSystem();
    //     if (osType === 'Android') {
    //         return android_app_link;
    //     }
    //     if (osType === 'iOS') {
    //         return ios_app_link;
    //     }
    //     return android_app_link;
    // };

    // const responsiveDevice = isMobile || isIpadProPortrait;

    const [vanishPasswordField, vanishField] = useState(domainValid);
    const [domain, setDomain] = useState('');
    const [beError, setBeError] = useState(false);

    useEffect(() => {
        pageAnalytics.initialise('login');
        return () => {
            pageAnalytics.complete('login');
        };
    }, []);

    useEffect(() => {
        if (domainValid) {
            vanishField(true);
        } else {
            vanishField(false);
        }
    }, [domainValid]);

    useEffect(
        () => () => {
            getTokenFailed({});
        },
        [getTokenFailed],
    );

    const handleRegisterClick = () => {
        history.push('/signin');
    };

    const handleUsernameChange = event => {
        const { value } = event.target;
        setUsername(value);
        const domain = value.replace(/.*@/, '');
        setDomain(domain);
        oauth(domain);
    };

    const validateLogin = () => {
        if (username === '' || password === '') {
            if (username === '' && password === '') {
                setFeError({ emailValidated: false,
                    emailError: PleaseEnterEmail,
                    passValidated: false,
                    passError: PleaseEnterPassword });
            } else if (!isValidPattern(username, EMAIL_PATTERN) && password === '') {
                setFeError({ passValidated: false, passError: PleaseEnterPassword, emailValidated: false, emailError: EmailError });
            } else if (username === '') {
                setFeError({ emailValidated: false, emailError: PleaseEnterEmail, passError: '', passValidated: true });
            } else if (password === '') {
                setFeError({ passValidated: false, passError: PleaseEnterPassword, emailValidated: true, emailError: '' });
            }
        } else if (!isValidPattern(username, EMAIL_PATTERN)) {
            setFeError({ passValidated: true, passError: '', emailValidated: false, emailError: EmailError });
        } else {
            setFeError({ passValidated: true, passError: '', emailValidated: true, emailError: '' });
            resetScreen();
            if (domainValid) {
                const { apiBasePath, authorize } = Endpoints;
                const url = `${apiBasePath}${authorize}${domain}&state=12122121&isWeb=true`;
                window.open(url, '_top');
            } else if (isValidPattern(username, EMAIL_PATTERN)) {
                getToken({ username, password }, history);
            }
        }
    };

    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    const handleEnterPress = event => {
        if (event.keyCode === 13 || event.which === 13) {
            validateLogin();
        }
    };

    const handleContinueAsAGuestClick = () => {
        history.push('/guest');
    };

    const handlePasswordresetClick = () => {
        history.push('/forgotPassword');
    };

    const handleCross = () => {
        setBeError(true);
        resetScreen();
    };

    const tryAgain = () => {
        setBeError(true);
        resetScreen();
    };

    const renderEmailError = () => {
        let errMsg = '';
        if (!feError.emailValidated) {
            errMsg = feError.emailError;
        }
        return (
            <Text
                fontsize="0.8rem"
                fontcolor={fontColors.errorRed}
                fontfamily={fontFamilies.TheinhardtPanRegular}
                margin="0 0 1.25rem 0"
            >
                {errMsg}
            </Text>
        );
    };
    const renderPassError = () => {
        let passErrorText = '';
        if (error.status === CODE_UNAUTHORIZE_USER) {
            passErrorText = error.display_message || error.message;
        }
        if (!feError.passValidated) {
            passErrorText = feError.passError;
        } else {
            switch (error.message) {
                case 'INVALID_CREDENTIALS': {
                    passErrorText = error.display_message || loginFailed;
                    break;
                }
                default:
                    break;
            }
        }
        return (
            <Text
                fontsize="0.8rem"
                fontcolor={fontColors.errorRed}
                fontfamily={fontFamilies.TheinhardtPanRegular}
                margin="0 0 1.25rem 0"
            >
                {passErrorText}
            </Text>
        );
    };

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };


    return (
        <div className="unauth_content_container_wrapper">
            {clientExpired && moveToClientExpirePage()}
            {error.status === CODE_ACCOUNT_LOCK && !beError && (
                <WarningModal>
                    <div className="login-popup-error_popup">
                        <div className="login-popup-cross" onClick={handleCross}><img src={crossIcon} alt="cross icon" /></div>
                        <div className="login-popup-title">{errorOccurred}</div>
                        <div className="login-popup-description">{AccountLock}</div>
                        <div className="login-popup-cta" onClick={tryAgain}>{ok}</div>
                    </div>
                </WarningModal>
            )}
            {isLoading && <Loader position="absolute" showBackground />}
            <img src={particleBg} alt="particles" className="particleBg" particleBg />
            <img src={InspireTopIcon} alt="inspire_logo" className="iccc" />
            <div className="unauth_content_container">
                <div className="dummy_box" />
                <div className="central_content">
                    <div className="header_info">
                        <Text
                            fontsize="2.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.625rem 0"
                        >
                            {Inspire}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                        >
                            {InspireDescription}
                        </Text>
                    </div>
                    <div className="main_section" onKeyDown={handleEnterPress} role="none">
                        <div className="input_element">
                            <InputField
                                label={Email}
                                type="text"
                                value={username}
                                onChange={handleUsernameChange}
                                fontSize="1rem"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.textBlack}
                                placeholder={LoginEmailText}
                                borderColor={error.message || !feError.emailValidated ? fontColors.errorRed : fontColors.borderGrey}
                            />
                        </div>
                        <div className="render_error">{renderEmailError()}</div>
                        {!vanishPasswordField && (
                            <div className="input_element password-box">
                                <InputField
                                    label={passwordText}
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    fontSize="1rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.siteGrey}
                                    placeholder={passwordInput}
                                    borderColor={error.message || !feError.passValidated ? fontColors.errorRed : fontColors.borderGrey}
                                />
                                {showPassword ? (
                                    <VisibilityOff onClick={() => togglePassword(false)} className="pointer" />
                                ) : (
                                    <Visibility onClick={() => togglePassword(true)} className="pointer" />
                                )}
                            </div>
                        )}
                        <div className="render_error" style={{ maxWidth: '276px' }}>{renderPassError()}</div>
                        <div className="action_container">
                            <Actionbutton
                                fontSize="1.125rem"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.siteWhite}
                                bgColor={fontColors.electricBlue}
                                disabled={false}
                                onClick={validateLogin}
                            >
                                {SignIn}
                            </Actionbutton>
                        </div>
                        {!error?.is_hide_forgot_link ? (
                            <div className="action_container">
                                <Text
                                    fontsize="0.8rem"
                                    fontcolor={fontColors.textBlack}
                                    fontfamily={fontFamilies.TheinhardtPanMedium}
                                    decoration="underline"
                                    cursor="pointer"
                                    onClick={handlePasswordresetClick}
                                >
                                    {ForgotYourPassword}
                                </Text>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="footer_container">
                    <Text
                        fontsize="0.8rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        cursor="pointer"
                        onClick={handleContinueAsAGuestClick}
                        decoration="underline"
                    >
                        {continueAsGuest}
                    </Text>
                    <Text
                        fontsize="0.8rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        cursor="pointer"
                        onClick={handleRegisterClick}
                        decoration="underline"
                    >
                        {Register}
                    </Text>
                </div>
            </div>
        </div>
    );
}

UnAuthContent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string,
        status: PropTypes.number,
        display_message: PropTypes.string,
        is_hide_forgot_link: PropTypes.bool,
    }).isRequired,
    resetScreen: PropTypes.func.isRequired,
    // changeSignUp: PropTypes.bool.isRequired,
    // successScreen: PropTypes.bool.isRequired,
    // getUserData: PropTypes.func.isRequired,
    // getChecksum: PropTypes.func.isRequired,
    // initialLocalisations: PropTypes.bool.isRequired,
    oauth: PropTypes.func.isRequired,
    // getAvailableLanguages: PropTypes.func.isRequired,
    // availableLanguages: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         is_default: PropTypes.bool,
    //         name: PropTypes.string,
    //         value: PropTypes.string,
    //     }),
    // ).isRequired,
    // postSelectedLanguage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    domainValid: PropTypes.bool.isRequired,
    getToken: PropTypes.func.isRequired,
    getTokenFailed: PropTypes.func.isRequired,
    clientExpired: PropTypes.bool.isRequired,
};

export default withRouter(UnAuthContent);
