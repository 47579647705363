import { fetchWebApi, putDashboardWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

export const getPracticeDetailsSagaApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPractices } = endpoints;
    const url = `${apiBasePath}${getPractices}${params[1]}/`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getPromptDetailsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPromptDetails } = endpoints;
    const url = `${apiBasePath}${getPromptDetails}${params[1]}/`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const changeArticleStatusApi = (articleId, status,bodyObject) => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPromptDetails } = endpoints;
    const url = `${apiBasePath}${getPromptDetails}${articleId}/${status}/`;
    return putDashboardWebApi(AuthStore.accessToken, url,bodyObject).request;
};

export const startPracticeApi = (practiceId, status) => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPractices } = endpoints;
    const url = `${apiBasePath}${getPractices}${practiceId}/${status}/`;
    return putDashboardWebApi(AuthStore.accessToken, url).request;
};

export const updateProtag = id => {
    const apiBasePath = AuthStore.baseUrl;
    const { FEED_PROTAG } = endpoints;
    const url = `${apiBasePath}${FEED_PROTAG}${id}/`;
    return putDashboardWebApi(AuthStore.accessToken, url).request;
};
