import { takeLatest, put, call } from 'redux-saga/effects';
import types from './types';
import { storeHtml } from './actions';
import { apiFailed } from '../../../common/actions';
import { getMailerApi } from './apis';

function* mailerGeneratorSaga({ baseurl }) {
    try {
        const { data } = yield call(getMailerApi, baseurl);
        yield put(storeHtml(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const weeklyReplayMailerSaga = [takeLatest(`${types.GET_MAIL_ANSWER}_PENDING`, mailerGeneratorSaga)];

export default weeklyReplayMailerSaga;
