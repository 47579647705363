import React, { useState, Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';

import { useLocation } from 'react-router-dom';
import PostTextZone from '../PostTextZone';
import AddNewPostInnerContainer from '../AddNewPostInnerContainer';
import ImageCropper from '../../Profile/ImageSelectorContainer/ImageCropper';
import { getCroppedImg, getCroppedImgBase64 } from '../../../utils/functions';
import backIcon from '../../../assets/backArrow.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';
import cretePostCross from '../../../assets/cretePostCross.svg';
import Text from '../../../components/Text';
import { fontColors, fontFamilies } from '../../../common/fontMap';
import Actionbutton from '../../../components/ActionButton';
import { TextPostBGColor } from '../../HubContainer/TextContent';
import WarningModal from '../../../components/WarningModal';

export default function AddNewPost({
    imageUploaderModal,
    handleClose,
    uploadPost,
    tags,
    uploadingPost,
    selectedKey,
    selectedHashTag,
    setSelectedHashTag,
    setShowCreatePost,
    imageSrc,
    history,
}) {
    const [draggedFile, setDraggedFile] = useState(imageSrc || null);
    const [subTitle, setSubT] = useState('');
    const [fileType, setFileType] = useState('');
    const [showCropper, setShowCropper] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [file, setFile] = useState(imageSrc ? { type: 'image/png' } : null);
    const [imgSrc, setImageSrc] = useState(imageSrc);
    // const [canvass, setCanvas] = useState(null);
    const [selectedFont, setSelectedFont] = useState('Typewritter');
    const [currentGradient, setCurrentGradient] = useState(TextPostBGColor.postBGColors[0]);
    const [gradientIndex, setGradientIndex] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [practiceTags, setPracticeTags] = useState(tags.practice);
    const [practiceTagsForPost, setPracticeTagsForPost] = useState([]);
    const [categoryTags, setCategoryTags] = useState(tags.category);
    const [selectedUsers, setSelecteduser] = useState([]);
    const [description, setDescription] = useState('');
    const [view, viewSetter] = useState(1);

    const callbackSetCroppedAreaPixels = useCallback(setCroppedAreaPixels, []);
    const isMobileSetup = window.innerWidth <= 740;
    const isMobile = false;
    const { localisationsAfterLogin } = LocalisationsStore;
    const { newPostOnly = '', post, crop, Upload, Create_Post } = localisationsAfterLogin;
    const location = useLocation();

    const clearState = () => {
        setFile(null);
        setImageSrc('');
        setDraggedFile(null);
        viewSetter(1);
        setSubT('');
        setSelectedFont('Typewritter');
        setCurrentGradient(TextPostBGColor.postBGColors[0]);
    };

    const closeModal = () => {
        handleClose();
        clearState();
        setShowCropper(false);
        localStorage.removeItem('canvasImage');
        localStorage.removeItem('createPost');
    };

    useEffect(() => {
        if (imageSrc) {
            viewSetter(2);
        }
    }, [imageSrc]);

    useEffect(
        () => () => {
            const newState = tags.practice.map(thisTag => {
                // eslint-disable-next-line no-param-reassign
                thisTag.selected = false;
                return thisTag;
            });
            const newState2 = tags.category.map(thisTag => {
                // eslint-disable-next-line no-param-reassign
                thisTag.selected = false;
                return thisTag;
            });
            setPracticeTags(newState);
            setCategoryTags(newState2);
        },
        [tags.category, tags.practice],
    );

    useEffect(() => {
        if (imageSrc) {
            viewSetter(2);
        }
    }, [imageSrc]);

    const showResult = async () => {
        if (file.type === 'image/gif') {
            setImageSrc(imgSrc);
            setDraggedFile(imgSrc);
        } else if (isMobileSetup) {
            setDraggedFile(imgSrc);
        } else {
            const croppedImageUri = await getCroppedImg(imgSrc, croppedAreaPixels);
            const croppedImage = await getCroppedImgBase64(imgSrc, croppedAreaPixels);
            setImageSrc(URL.createObjectURL(croppedImageUri));
            setDraggedFile(croppedImage);
        }
        setShowCropper(false);
    };

    const handleUploadPost = async e => {
        e.stopPropagation();
        if (showCropper) {
            await showResult();
        } else {
            const capturedCancas = document.getElementById('textarea_containerid');
            const selectedPractices = practiceTagsForPost.filter(tag => tag.selected);
            const selectedCategories = categoryTags.filter(tag => tag.selected);
            const selectedPracticeTags = selectedPractices.map(tag => ` #${tag.name}`);
            const selectedCategoryTags = selectedCategories.map(tag => ` #${tag.name}`);
            if (capturedCancas && !draggedFile) {
                // setLoading(true);
                setSelectedHashTag('');
                await uploadPost(
                    {
                        file: '',
                        fileType,
                        desc: subTitle,
                        content: description + selectedPracticeTags.join(' ') + selectedCategoryTags.join(' '),
                        categoryTags,
                        practiceTags: practiceTagsForPost,
                        users_list: selectedUsers,
                        social_config: {
                            fontFamily: selectedFont,
                            colors: currentGradient.colorOrder,
                            index: gradientIndex,
                            fontSize: parseFloat('24.01'),
                        },
                        type: 'text',
                    },
                    selectedKey,
                    selectedHashTag,
                    history,
                    location.pathname === '/app/hub',
                );
                // setLoading(false);
            } else {
                setSelectedHashTag('');
                await uploadPost(
                    {
                        file: draggedFile,
                        fileType,
                        desc: subTitle,
                        content: description + selectedPracticeTags.join(' ') + selectedCategoryTags.join(' '),
                        categoryTags,
                        practiceTags: practiceTagsForPost,
                        users_list: selectedUsers,
                        social_config: null,
                        type: '',
                    },
                    selectedKey,
                    selectedHashTag,
                    history,
                    location.pathname === '/app/hub',
                );
            }
            clearState();
            setShowCreatePost(false);
        }
    };

    const CropperOrSelector = !showCropper ? (
        <Fragment>
            <div className="post_left_panel">
                <AddNewPostInnerContainer
                    uploadPost={uploadPost}
                    setDraggedFile={setDraggedFile}
                    draggedFile={draggedFile}
                    setSubT={setSubT}
                    subTitle={subTitle}
                    setFileType={setFileType}
                    setShowCropper={setShowCropper}
                    file={file}
                    view={view}
                    viewSetter={viewSetter}
                    setFile={setFile}
                    imgSrc={imgSrc}
                    setImageSrc={setImageSrc}
                    isMobile={isMobile}
                    selectedFont={selectedFont}
                    setSelectedFont={setSelectedFont}
                    currentGradient={currentGradient}
                    setCurrentGradient={setCurrentGradient}
                    setGradientIndex={setGradientIndex}
                />
            </div>
            <div className="post_right_panel">
                <PostTextZone
                    fileType={fileType}
                    uploadingPost={uploadingPost}
                    subTitle={subTitle}
                    uploadPost={uploadPost}
                    tags={tags}
                    draggedFile={draggedFile}
                    setFile={setFile}
                    setImageSrc={setImageSrc}
                    setDraggedFile={setDraggedFile}
                    selectedKey={selectedKey}
                    selectedHashTag={selectedHashTag}
                    selectedFont={selectedFont}
                    currentGradient={currentGradient}
                    gradientIndex={gradientIndex}
                    setSelectedHashTag={setSelectedHashTag}
                    selectedUsers={selectedUsers}
                    setSelecteduser={setSelecteduser}
                    description={description}
                    setDescription={setDescription}
                    setPracticeTagsForPost={setPracticeTagsForPost}
                />
            </div>
        </Fragment>
    ) : (
        <div className="crop_before_upload">
            <ImageCropper image={draggedFile} setCroppedAreaPixels={callbackSetCroppedAreaPixels} fileType={fileType} />
            <div type="submit" className="submit_btn outline-none" />
        </div>
    );

    return isMobile ? (
        <Modal open={imageUploaderModal} className="add_new_post_container_mobile">
            <div className="add_new_post">
                <div className="new_post_header_mobile">
                    <img src={backIcon} alt="" onClick={closeModal} role="none" />
                    <span>{newPostOnly}</span>
                    <div className="postButton">
                        <Actionbutton
                            fontSize="1rem"
                            padding="0.5rem"
                            width="5rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={!draggedFile && !subTitle}
                            onClick={handleUploadPost}
                        >
                            Post
                        </Actionbutton>
                    </div>
                </div>
                {!isMobile && (
                    <div className="top_bar">
                        <div className="cretePostCross pointer">
                            <img src={cretePostCross} alt="cross" onClick={closeModal} />
                        </div>
                        <Text
                            fontsize="1.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                        >
                            Create Post
                        </Text>
                    </div>
                )}
                {CropperOrSelector}
            </div>
        </Modal>
    ) : (imageUploaderModal && (
        <WarningModal classNames="add_new_post_container">
            <div className="add_new_post">
                <div className="top_bar">
                    <div className="left">
                        <div className="cretePostCross pointer">
                            <img src={cretePostCross} alt="cross" onClick={closeModal} />
                        </div>
                        <Text
                            fontsize="1.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0 1rem"
                        >
                            {showCropper ? crop : Create_Post}
                        </Text>
                    </div>
                    <div className="postButton">
                        <Actionbutton
                            fontSize="1rem"
                            padding="0.5rem"
                            width="5rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            height="38px"
                            disabled={!draggedFile && !subTitle}
                            onClick={handleUploadPost}
                        >
                            {showCropper ? Upload : post}
                        </Actionbutton>
                    </div>
                </div>
                {CropperOrSelector}
            </div>
        </WarningModal>
    )
    );
}

AddNewPost.defaultProps = {
    tags: {
        practice: [],
        category: [],
    },
    selectedKey: Number(localStorage.getItem('selectedMenu')),
    selectedHashTag: '',
    setSelectedHashTag: () => {},
};

AddNewPost.propTypes = {
    imageUploaderModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    uploadPost: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array.isRequired,
        category: PropTypes.array.isRequired,
    }),
    uploadingPost: PropTypes.bool.isRequired,
    selectedKey: PropTypes.number,
    selectedHashTag: PropTypes.string,
    setSelectedHashTag: PropTypes.func,
    setShowCreatePost: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        location: PropTypes.object,
    }).isRequired,
};
