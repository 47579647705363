export const getWeeklyQuestions = () => ({
    type: 'GET_WEEKLY_QUESTIONS_PENDING',
});

export const storeWeeklyQuestions = data => ({
    type: 'GET_WEEKLY_QUESTIONS_DONE',
    data,
});

export const postAnswer = payload => ({
    type: 'POST_WEEKLY_QUESTION_ANSWER_PENDING',
    payload,
});

export const storeAnsweredQuestionResponse = payload => ({
    type: 'POST_WEEKLY_QUESTION_ANSWER_DONE',
    payload,
});

export const setSeenPercentage = index => ({
    type: 'SET_SEEN_PERCENTAGE_OF_WEEKLY_QUESTION',
    index,
});

export const closeWeeklyQuestionModal = () => ({
    type: 'CLOSE_WEEKLY_QUESTIONS_MODAL',
});

export const getChapterIntro = () => ({
    type: 'GET_CHAPTER_INTRO_PENDING',
});

export const storeChapterIntro = data => ({
    type: 'GET_CHAPTER_INTRO_DONE',
    data,
});

export const chapterIntroSeen = () => ({
    type: 'CHAPTER_INTRO_SEEN',
});

export const userAdditionalQuestionComment = payload => ({
    type: 'POST_ADDITIONAL_QUESTION_COMMENT',
    payload,
});
