import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tabs/Tab';
import ActiveCard from '../ActiveCard';
import LocalisationsStore from '../../../common/LocalisationsStore';
import './index.scss';

const GoalsTabList = ({
    activeTab,
    setActiveTab,
    goalLists,
    setView,
    setActiveGoalDetail,
    actionCallBack,
    editCallBack,
    fromScreen,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;

    const getGoals = (goals = []) => {
        if (fromScreen === 'Goals') {
            return goals[0] ? [goals[0]] : [];
        }
        return goals;
    };

    return (
        <Tabs selected={activeTab} handleChange={setActiveTab} classes="goals-tab">
            {goalLists.map(goalCategory => (
                <Tab title={goalCategory.display_name} key={goalCategory.id}>
                    {goalCategory?.goals?.length > 0 ? getGoals(goalCategory.goals).map(goal => (
                        <ActiveCard
                            key={goal.id}
                            goal={goal}
                            callBack={actionCallBack}
                            editCallBack={editCallBack}
                            fromScreen={fromScreen}
                            displayActions
                            setView={setView}
                            setActiveGoalDetail={setActiveGoalDetail}
                            showGoalTitle={goalCategory.goal_category.toLowerCase() === 'micro' || fromScreen === 'AllGoals'}
                            goalTextMaxLines={goalCategory.goal_category.toLowerCase() === 'vision' ? 4 : 2}
                        />
                    )) : (
                        <div className="no-goals">
                            <div>{localisationsAfterLogin[`no_${goalCategory.goal_category}`]}</div>
                        </div>
                    )}
                </Tab>
            ))}
        </Tabs>
    );
};

GoalsTabList.defaultProps = {
    setView: () => {},
    setActiveGoalDetail: () => {},
};

GoalsTabList.propTypes = {
    activeTab: PropTypes.number.isRequired,
    fromScreen: PropTypes.string.isRequired,
    goalLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setView: PropTypes.func,
    setActiveGoalDetail: PropTypes.func,
    setActiveTab: PropTypes.func.isRequired,
    actionCallBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
};

export default (GoalsTabList);
