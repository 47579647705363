import React, { useState, Fragment } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Badges } from './constants';
import ProfileImage from './ProfileImage';
import LikeSection from './LikeSection';
import Loader from '../../components/Loader';
import FollowUnfollow from './FollowUnfollow';
import ReportPost from './ReportPost';
import LocalisationsStore from '../../common/LocalisationsStore';
import { isMobile } from '../../utils/constants';
import streakBadgeImg from './assets/streakBadge.svg';
import LastUpdatedAt from './LastUpdatedAt';

export default function Posts({
    feedItem,
    renderMedia,
    isLiked,
    likedId,
    postLike,
    deletePost,
    likeDetails,
    followedId,
    getLikeDetails,
    postFollowUser,
    isLoading,
    isDeleting,
    showCommentsForThisArticle,
    reportPost,
    handleClickOnHashTag,
    postUnfollowUser,
    getUsersFollowed,
    followedUsers,
    setFollowUnfollowInFeeds,
    feedKey,
    selectedHashTag,
    selectedKey,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { comments, youAreAboutToDeleteAPost, deletePostAction, cantUndoAction, dontDelete } = localisationsAfterLogin;
    const newTag = localisationsAfterLogin.new;

    const [isOverlay, setIsOverlay] = useState(false);
    const singleTag = feedItem.title.split(' ');
    const singleTagFilter = singleTag.filter(tag => tag.includes('#'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [badge, setBadge] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [badges] = useState([]);

    const deleteAPost = articleId => {
        setIsOverlay(true);
        deletePost({ articleId });
    };

    const showOverlay = isShown => {
        setIsOverlay(isShown);
    };
    const getBadge = ({ actionable, social, streak, socialBadges, actionBadges }) => {
        if (social > 0 && actionable === 0 && streak === 0) {
            const badge = socialBadges.find(sb => sb.badgeId === social);
            return {
                image: badge.badgeImage,
                name: badge.badgeName,
            };
        }
        if (actionable > 0 && social === 0 && streak === 0) {
            const badge = actionBadges.find(ab => ab.badgeId === actionable);
            return {
                image: badge.badgeImage,
                name: badge.badgeName,
            };
        }
        if (actionable === 0 && social === 0 && streak > 0) {
            const badge = Badges.find(ab => ab.type === 'streak');
            return {
                image: badge.badgeImage,
                name: badge.badgeName,
            };
        }
        const badge = Badges.find(badge => badge.type === 'multiple');
        const badgeFor3 = Badges.find(badge => badge.type === 'multiple2');
        if (actionable > 0 && streak > 0 && social > 0) {
            return {
                image: badgeFor3.badgeImage,
                name: badgeFor3.badgeName,
            };
        }
        return {
            image: badge.badgeImage,
            name: badge.badgeName,
        };
    };

    const _renderBadge = item => {
        const { badge: { actionable, social, streak } } = item;
        const socialBadges = Badges.filter(badge => badge.type === 'social');
        const actionBadges = Badges.filter(badge => badge.type === 'action');
        const streakBadges = Badges.filter(badge => badge.type === 'streak');
        // store all badges
        badges.length = 0;
        if (actionable > 0) {
            const actionalBadge = actionBadges.find(sb => sb.badgeId === actionable);
            badges.push({ image: actionalBadge.badgeImage, name: actionalBadge.badgeName, type: 'action' });
        }

        if (social > 0) {
            const socialBadge = socialBadges.find(ab => ab.badgeId === social);
            badges.push({ image: socialBadge.badgeImage, name: socialBadge.badgeName, type: 'social' });
        }

        if (streak > 0) {
            const streakBadge = streakBadges[0];
            badges.push({ image: streakBadge.badgeImage, name: streakBadge.badgeName, type: 'streak' });
        }

        return getBadge({ actionable, social, streak, socialBadges, actionBadges });
    };

    const handleBadgeClick = event => {
        const { currentTarget: { dataset: { baddgea, baddges, streak } } } = event;
        event.stopPropagation();
        const socialBadges = Badges.filter(badge => badge.type === 'social');
        const actionBadges = Badges.filter(badge => badge.type === 'action');
        const badge = getBadge({
            actionable: Number(baddgea),
            social: Number(baddges),
            streak: Number(streak),
            socialBadges,
            actionBadges,
        });
        setBadge(badge);

        if (isMobile) {
            setMobileOpen(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const urlify = text => {
        // eslint-disable-next-line no-useless-escape
        const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const stringList = text.split(' ');
        return stringList
            .map(element => element.replace(urlRegex, url => `<a href="http://${url}">${url}</a>`))
            .join(' ');
    };

    const handleUrlClick = e => {
        e.preventDefault();
        window.open(`${e.target.href}`, '_blank');
    };

    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const profileId = userProfile && userProfile.id;

    const getbadgeList = () => badges.map(badge => (
        <MenuItem onClick={() => setAnchorEl(null)} key={`${badge.name}-${feedItem.id}`}>
            {badge.type === 'streak' ? (
                <div className="streakBadgeImg">
                    <div className="streakBadgeImg">
                        <img src={streakBadgeImg} alt="deed" width="100px" height="100px" />
                        <div className="streakCount">{feedItem.badge.streak}</div>
                    </div>
                </div>
            ) : (
                <img src={badge.image} alt="deed" />
            )}
            <span style={{ marginLeft: '7px' }}>{badge.name}</span>
        </MenuItem>
    ));

    const getMobilebadgeList = () => badges.map(badge => (
        <div className="streakBadgeSheet">
            {badge.type === 'streak' ? (
                <div div className="streakRow">
                    <div className="streakRowChild">
                        <img src={streakBadgeImg} alt="deed" className="badgeImageCount" />
                        <div className="streakMobileCount">{feedItem.badge.streak}</div>
                    </div>
                    <span style={{ marginLeft: '7px' }}>{badge.name}</span>
                </div>
            ) : (
                <div className="streakRow">
                    <img src={badge.image} alt="deed" />
                    <span style={{ marginLeft: '7px' }}>{badge.name}</span>
                </div>
            )}
        </div>
    ));

    return (
        feedItem && (
            <div className="feedsCardSection">
                {badge && isMobile && (
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="bottom"
                            open={mobileOpen}
                            onClose={() => setMobileOpen(!mobileOpen)}
                            ModalProps={{ keepMounted: true }}
                            classes={{ paper: 'badge_drawer' }}
                        >
                            <div className="streakBadgeSheetWrapper">
                                {' '}
                                {getMobilebadgeList()}
                            </div>
                        </Drawer>
                    </Hidden>
                )}
                <div className="feedCardWrapper">
                    <div className="feedHeading">
                        {feedItem.is_new && <div className="new_label">{newTag}</div>}
                        <div
                            className="d-flex"
                            onClick={handleBadgeClick}
                            data-baddges={feedItem.badge.social}
                            data-baddgea={feedItem.badge.actionable}
                            data-streak={feedItem.badge.streak}
                        >
                            <ProfileImage image={feedItem.profile_picture} />
                            <div className="userName">{feedItem.created_by}</div>
                        </div>
                        {(feedItem.badge.actionable !== 0
                            || feedItem.badge.social !== 0
                            || feedItem.badge.streak !== 0) && (
                            <div className="badge_system">
                                {badge && !isMobile && (
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted={false}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                        className="desk_badge_drawer"
                                    >
                                        {getbadgeList()}
                                    </Menu>
                                )}
                                <div className="profileimage_badge">
                                    {feedItem.badge.streak > 0
                                        && feedItem.badge.actionable === 0
                                        && feedItem.badge.social === 0 && <span>{feedItem.badge.streak}</span>}
                                    <img src={_renderBadge(feedItem).image} alt="badge" />
                                </div>
                            </div>
                        )}
                        {profileId === feedItem.user ? (
                            <div
                                className="deleteAction outline-none"
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={0}
                                onClick={() => showOverlay(true)}
                            />
                        ) : (
                            <div className="feedHeading_btn_wrapper">
                                <FollowUnfollow
                                    isFollowing={feedItem.is_following}
                                    followedId={followedId}
                                    postFollowUser={postFollowUser}
                                    userId={feedItem.user}
                                    postUnfollowUser={postUnfollowUser}
                                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                                />
                                <ReportPost feedId={feedItem.id} reporttPost={reportPost} />
                            </div>
                        )}
                    </div>
                    <div className="postImage">
                        {renderMedia({
                            fileType: feedItem.file_type,
                            fileSrc: feedItem.file,
                            videoUrl: feedItem.file,
                            id: feedItem.id,
                            description: feedItem.description,
                            config: feedItem.config,
                            type: feedItem.type,
                        })}
                    </div>
                    <LastUpdatedAt postDate={feedItem.post_date} />

                    <div className="feedActions">
                        <div className="d-flex">
                            <LikeSection
                                postLike={postLike}
                                feedItem={feedItem}
                                likedId={likedId}
                                isLiked={isLiked}
                                feedType="post"
                                likeDetails={likeDetails}
                                getLikeDetails={getLikeDetails}
                                isLoading={isLoading}
                                isFollowing={feedItem.is_following}
                                followedId={followedId}
                                postFollowUser={postFollowUser}
                                postUnfollowUser={postUnfollowUser}
                                getUsersFollowed={getUsersFollowed}
                                followedUsers={followedUsers}
                                setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                                selectedHashTag={selectedHashTag}
                                selectedKey={selectedKey}
                            />
                            <div role="none" className="d-flex" onClick={() => showCommentsForThisArticle(feedItem.id)}>
                                <div className="comments" />
                                <div className="likeText">{`${feedItem.comment_count} ${' '} ${comments}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="contentPnl">
                        {singleTag
                            .filter(tag => !tag.includes('#'))
                            .map((tag, index) => {
                                const isAUser = tag.match(/[\u200b]/g);
                                if (isAUser) {
                                    return (
                                        <span key={`${feedKey}-hash-${index}`} className="_heading is_a_user">
                                            {` ${tag}`}
                                        </span>
                                    );
                                }
                                return (
                                    <span
                                        key={`${feedKey}-hash-no-user-${index}`}
                                        className="_heading"
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{ __html: urlify(tag) }}
                                        onClick={handleUrlClick}
                                    />
                                );
                            })}
                    </div>
                    {singleTagFilter.length > 0 && (
                        <div className="contentPnl tagSection">
                            {singleTagFilter.map((tag, index) => (
                                <span
                                    key={`${feedKey}-tags-${index}`}
                                    className="tagHeading"
                                    data-tag={tag}
                                    onClick={handleClickOnHashTag}
                                    role="none"
                                >
                                    {` ${tag}`}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                {isOverlay && (
                    <Fragment>
                        <div className="deleteBox stackTop">
                            <div className="deleteContent">
                                <div className="deleteText">{youAreAboutToDeleteAPost}</div>
                                <div className="deleteText">{cantUndoAction}</div>
                                <div
                                    className="deleteAction dontBtn outline-none"
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => showOverlay(false)}
                                >
                                    {dontDelete}
                                </div>
                                {isDeleting && <Loader position="relative" showBackground />}
                                <div
                                    className="deleteAction outline-none"
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={-2}
                                    onClick={() => deleteAPost(feedItem.id)}
                                >
                                    {deletePostAction.toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    );
}

Posts.defaultProps = {
    handleClickOnHashTag: () => {},
    selectedHashTag: '',
};

Posts.propTypes = {
    feedItem: PropTypes.shape({
        badge: PropTypes.shape({
            actionable: PropTypes.number.isRequired,
            social: PropTypes.number.isRequired,
            streak: PropTypes.number.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        file_type: PropTypes.string,
        file: PropTypes.string.isRequired,
        embedded_url: PropTypes.string,
        profile_picture: PropTypes.string.isRequired,
        created_by: PropTypes.string.isRequired,
        comment_count: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        user: PropTypes.number.isRequired,
        is_following: PropTypes.bool.isRequired,
        is_new: PropTypes.bool.isRequired,
        description: PropTypes.string,
        config: PropTypes.shape({
            colors: PropTypes.arrayOf(PropTypes.string),
            fontFamily: PropTypes.string,
            index: PropTypes.number,
        }),
        type: PropTypes.string,
        post_date: PropTypes.string,
    }).isRequired,
    postLike: PropTypes.func.isRequired,
    renderMedia: PropTypes.func.isRequired,
    isLiked: PropTypes.bool.isRequired,
    likedId: PropTypes.number.isRequired,
    deletePost: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedId: PropTypes.number.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    showCommentsForThisArticle: PropTypes.func.isRequired,
    reportPost: PropTypes.func.isRequired,
    handleClickOnHashTag: PropTypes.func,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    feedKey: PropTypes.string.isRequired,
    selectedHashTag: PropTypes.string,
    selectedKey: PropTypes.number.isRequired,
};
