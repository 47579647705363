import types from './types';

export const unsubscribeUser = ({ baseUrl, privateKey }) => ({
    type: `${types.UNSUBSCRIBE_USER}_PENDING`,
    baseUrl,
    privateKey,
});

export const unsubscribeSuccessfull = () => ({
    type: `${types.UNSUBSCRIBE_USER}_DONE`,
});

export const alreadyUnsubscribed = () => ({
    type: types.ALREADY_UNSUBSCRIBED,
});
