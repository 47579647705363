import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    loading: false,
    data: {},
};

const userMatrixReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_USER_MATRIX_DATA}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_USER_MATRIX_DATA}_DONE`]: (state, { data }) => ({
        ...state,
        loading: false,
        data,
    }),
    [types.RESET_USER_MATRIX_DATA]: state => ({
        ...state,
        data: {},
    }),
});

export default userMatrixReducer;
