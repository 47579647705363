import { fromJS } from 'immutable';
import createReducer from '../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = fromJS({
    isLoading: false,
    isFetched: false,
    resetUserDetails: false,
    data: {},
    error: {},
    signupError: {},
    changeSignUp: false,
    successScreen: false,
    accessToken: '',
    status: null,
    loggedIn: false,
    userData: {},
    initialLocalisations: false,
    domainValid: false,
    streakPopup: null,
    isAccountExpired: false,
    languages: [],
    count: 0,
});

const reducer = createReducer(INITIAL_STATE, {
    [types.RESET_SCREEN]: state => state
        .set('isLoading', false)
        .set('isFetched', false)
        .set('data', {})
        .set('error', {})
        .set('signupError', {})
        .set('changeSignUp', false)
        .set('successScreen', false)
        .set('accessToken', '')
        .set('status', null)
        .set('loggedIn', false)
        .set('userData', {})
        .set('count', 0),
    [types.LOGIN_PENDING]: state => state.set('isLoading', true),
    [types.SIGN_UP_REQUEST_PENDING]: state => state.set('isLoading', true),
    [types.FORGOT_PASSWORD_REQUEST_PENDING]: state => state.set('isLoading', true).set('isFetched', false),
    [types.LOGIN_SUCCESS]: (state, { payload }) => state
        .set('isLoading', false)
        .set('isFetched', true)
        .set('data', fromJS(payload))
        .set('accessToken', payload.token)
        .set('loggedIn', true),
    [types.FORGOT_PASSWORD_REQUEST_SUCCESS]: (state, { payload }) => state
        .set('isLoading', false)
        .set('isFetched', true)
        .set('successScreen', true)
        .set('data', fromJS(payload)),
    [types.SIGN_UP_REQUEST_SUCCESS]: (state, { payload }) => state
        .set('isLoading', false)
        .set('isFetched', true)
        .set('changeSignUp', true)
        .set('data', fromJS(payload)),
    [types.GET_STATUS_SUCCESS]: (state, { payload }) => state
        .set('isLoading', false)
        .set('isFetched', true)
        .set('status', fromJS(payload)),
    [types.RESET_USER_DETAILS_SUCCESS]: (state, { payload }) => state.set('resetUserDetails', fromJS(payload)),
    [types.EMAIL_SUBSCRIBE_SUCCESS]: (state, { payload }) => state.set('isEmailSubscribe', fromJS(payload)),
    [types.SMRF_LINK_SUCCESS]: (state, { payload }) => state.set('smrfLink', fromJS(payload)),
    [types.SET_NEW_NOTIFICATION_SUCCESS]: (state, { payload }) => state.set('setNewNotification', fromJS(payload)),
    [types.SET_HIDE_SECTIONS_SUCCESS]: (state, { payload }) => state.set('hide_sections', fromJS(payload)),
    [types.SET_HIDE_ADD_GOAL_BTN_SUCCESS]: (state,{payload }) => state.set('hide_add_goal_button',fromJS(payload)),
    [types.LOGIN_FAILED]: (state, { payload }) => state.set('error', payload).set('isLoading', false),
    [types.SIGN_UP_REQUEST_FAILED]: (state, { payload }) => state.set('isLoading', false).set('signupError', payload),
    [types.FORGOT_PASSWORD_REQUEST_FAILED]: (state, { payload }) => state.set('isLoading', false).set('error', payload),
    [types.GET_STATUS_FAILED]: (state, { payload }) => state
        .set('isLoading', false)
        .set('isFetched', false)
        .set('error', payload),

    [types.GET_USER_DATA_PENDING]: state => state.set('isLoading', true),
    [types.GET_USER_DATA_SUCCESS]: (state, { payload }) => {
        localStorage.setItem('userProfile', JSON.stringify(payload.data));
        state.set('isLoading', false).set('userData', fromJS(payload));
    },

    [`${types.GET_STREAK_STATUS}_SUCCESS`]: (state, { payload }) => state.set('isLoading', false).set('streakPopup', fromJS(payload)),

    STREAK_CLOSED: state => state.setIn(['streakPopup', 'hasUserEarnedStreak'], false),

    [types.GET_USER_DATA_FAILED]: state => state.set('isLoading', false),
    [types.LOGOUT_USER_PENDING]: state => state.set('loggedIn', false),
    INITIAL_LOCALISATIONS_FETCHED: (state, { value }) => state.set('initialLocalisations', value),
    VERIFY_DOMAIN_NAME_SUCCESS: (state, { isValid }) => state.set('domainValid', isValid),
    SET_ISLOADING: (state, { value }) => state.set('isLoading', value),
    STORE_ACCOUNT_EXPIRED_STATUS: (state, { payload }) => state.set('isAccountExpired', payload),
    GET_LANGUAGES_PENDING: state => state.set('isLoading', true),
    GET_LANGUAGES_DONE: (state, { payload }) => state.set('languages', payload).set('isLoading', false),
    SET_COUNT: (state, { count }) => state.set('count', count),
});

export default reducer;
