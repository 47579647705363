/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    languagesData: null,
    loading: false,
    changeLanguageLoading: false,
};

const emailLanguagesReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_LANGUAGES}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_LANGUAGES}_SUCCESS`]: (state, { data }) => ({
        ...state,
        loading: false,
        languagesData: data,
    }),
    [types.LANGUAGE_CHANGED]: (state, { value }) => ({
        ...state,
        changeLanguageLoading: value,
    }),
});

export default emailLanguagesReducer;
