import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AllToDos from './index';
import { getTodos } from '../HomeContainer/redux/actions';
import { getTags, uploadPost } from '../Feeds/redux/actions';

const stateToProps = state => {
    const REDUCER = state.get('HomeReducer');
    const FREDUCER = state.get('feedsReducer');
    const APPREDUCER = state.get('appReducer');
    return {
        isLoading: REDUCER.isLoading,
        todos: REDUCER.todos,
        uploadingPost: FREDUCER.uploadingPost,
        tags: FREDUCER.tags,
        globalLoader: APPREDUCER.globalLoader,
    };
};

const dispatchToProps = { getTodos, uploadPost, getTags };

export default withRouter(connect(stateToProps, dispatchToProps)(AllToDos));
