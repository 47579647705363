import { connect } from 'react-redux';
import PreferencesModalComponent from './PreferencesModalComponent';
import { postImage, getQuestionsAction, toggleShowSelectorModal, postAnswers } from '../Profile/actions';
import { resetPassword } from '../UnAuthenticatedContent/actions';
import { getUserProgressData } from '../MyProgress/redux/actions';

const stateToProps = state => ({
    questions: state.get('profileReducer').questions,
    userDisplayPicture: state.get('profileReducer').userDetails.profile_picture,
    isLoading: state.get('profileReducer').isLoading,
    popUpOpen: state.get('profileReducer').popUpOpen,
});

const dispatchToProps = {
    postImage,
    getQuestionsAction,
    toggleShowSelectorModal,
    postAnswers,
    resetPassword,
    getUserProgressData,
};

export default connect(stateToProps, dispatchToProps)(PreferencesModalComponent);
