import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../Text';
import rightImage from '../../assets/rightImage.svg';
import leftImage from '../../assets/leftImage.svg';
import './index.scss';
import CtaArrow from '../CtaArrow';
import LocalisationsStore from '../../common/LocalisationsStore';

const CardContainer = styled.div`
    width: 100%;
    min-height: 12.5rem
    border-radius: 0.4rem;
    background-color: ${props => props.bgColor || 'transparent'};
    display: flex !important;
    justify-content: space-between;
    align-items: end;
`;

function CompletedRpCard({ history }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { LookingForMore, recommended_promts_completed_text, congratulations } = localisationsAfterLogin;

    const handleProfileCompleteOnClick = () => {
        history.push('/app/practices');
    };

    return (
        <>
            <CardContainer bgColor={fontColors.emptyCardGreen} className="CompletedRpCard">
                <div className="fimage">
                    <img src={leftImage} alt="dyamic_card" />
                </div>
                <div className="left_panel">
                    <div className="element">
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.siteWhite}
                            fontfamily={fontFamilies.TheinhardtPanMedium}
                            margin="0 0 0.5rem 1rem"
                        >
                            {congratulations}
                        </Text>
                    </div>
                    <div className="element">
                        <Text
                            fontsize="1.75rem"
                            fontcolor={fontColors.siteWhite}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.5rem 1rem"
                        >
                            {recommended_promts_completed_text}
                        </Text>
                    </div>
                </div>
                <div className="image">
                    <img src={rightImage} alt="dyamic_card" />
                </div>
            </CardContainer>
            <div className="action_bar" onClick={handleProfileCompleteOnClick}>
                <div>
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                    >
                        {LookingForMore}
                    </Text>
                    <CtaArrow color={fontColors.textBlack} />
                </div>
            </div>
        </>
    );
}

CompletedRpCard.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(CompletedRpCard);
