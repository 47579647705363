import { webApiAuthPut } from '../../../webapis/core';

// eslint-disable-next-line import/prefer-default-export
export const unsubscribeUserApi = (params, payload) => {
    const url = `${payload.baseUrl}/`;
    let load;
    if (payload.privateKey.type === 'tag_id') {
        load = { tag_id: payload.privateKey.id };
    } else if (payload.privateKey.type === 'email') {
        load = { email: payload.privateKey.email };
    } else {
        load = { id: payload.privateKey.id };
    }
    return webApiAuthPut(url, load).request;
};
