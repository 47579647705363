import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import Next from './components/Next';
import Previous from './components/Previous';
import Actionbutton from '../../components/ActionButton';
import { fontColors, fontFamilies } from '../../common/fontMap';
import tick from '../../assets/tick.svg';
import Text from '../../components/Text';
import LocalisationsStore from '../../common/LocalisationsStore';
import ReminderAction from '../PracticesDetailsContainer/ReminderAction';
import './index.scss';

const Element = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
`;

const TickAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    img {
        height: 1rem;
        width: 1rem;
        background-color: green;
        border-radius: 50%;
        margin-right: 0.5rem;
        padding: 3px;
    }
`;

const HybridGoals = ({
    dataConfig,
    hybridData,
    required,
    state,
    callBack,
    changePracticeStatus,
    onClose,
    refreshCallback,
    disableParent,
    promptLoading,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { completed, returnToPractice } = localisationsAfterLogin;
    const [error, setError] = useState({ isError: false });
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const isClientPG = localStorage.getItem('client_config') === 'pg';
    const [loadingState, setLoadingState] = useState({ prevLoading: false, nextLoading: false });

    const removeToDo = event => {
        if (dataConfig.hit_remove_todo_api) {
            changePracticeStatus(dataConfig.id, 'remove_todo', event);
        } else {
            onClose(event);
        }
    };

    const getLayout = () => {
        switch (dataConfig.cta_layout) {
            case 'prev_next':
                return (
                    <Fragment>
                        <div className={`${isClientPG ? 'horizontal_wrapper row-reverse' : 'vertical_wrapper'}`}>
                            {dataConfig.next_prompt && (
                            <div>
                                <Next
                                    callBack={callBack}
                                    state={state}
                                    required={required}
                                    hybridData={hybridData}
                                    setError={setError}
                                    config={{ completeId: dataConfig.id, nextId: dataConfig.next_prompt, quizId: dataConfig?.quiz?.id }}
                                    onClose={onClose}
                                    refreshCallback={refreshCallback}
                                    disableParent={disableParent}
                                    promptLoading={promptLoading}
                                    loadingState={loadingState}
                                    setLoadingState={setLoadingState}
                                />
                            </div>
                            )}

                            {dataConfig.prev_prompt && (
                            <Previous
                                config={{
                                    btnTxt: dataConfig.article_buttons.find(elem => elem.key === 'prev').text,
                                    prevId: dataConfig.prev_prompt,
                                }}
                                refreshCallback={refreshCallback}
                                promptLoading={promptLoading}
                                loadingState={loadingState}
                                setLoadingState={setLoadingState}
                                setError={setError}
                            />
                            )}
                        </div>
                    </Fragment>
                );
            case 'submit_prev_next':
                return state !== 4 || dataConfig.next_prompt ? (
                    <Fragment>
                        <div className={`${isClientPG ? 'horizontal_wrapper row-reverse' : 'vertical_wrapper'}`}>
                            <div>
                                <Next
                                    state={state}
                                    callBack={callBack}
                                    required={required}
                                    hybridData={hybridData}
                                    setError={setError}
                                    config={{ completeId: dataConfig.id, nextId: dataConfig.next_prompt, text: 'Submit', quizId: dataConfig?.quiz?.id }}
                                    onClose={onClose}
                                    refreshCallback={refreshCallback}
                                    disableParent={disableParent}
                                    promptLoading={promptLoading}
                                    loadingState={loadingState}
                                    setLoadingState={setLoadingState}
                                />
                            </div>

                            {dataConfig.prev_prompt && (
                            <Previous
                                config={{
                                    btnTxt: dataConfig.article_buttons.find(elem => elem.key === 'prev').text,
                                    prevId: dataConfig.prev_prompt,
                                }}
                                refreshCallback={refreshCallback}
                                promptLoading={promptLoading}
                                loadingState={loadingState}
                                setLoadingState={setLoadingState}
                                setError={setError}
                            />
                            )}
                        </div>
                    </Fragment>
                ) : (
                    <div className={`${isClientPG ? 'horizontal_wrapper' : 'vertical_wrapper'}`}>
                        <TickAndTextWrapper>
                            <img src={tick} alt="completed practice" />
                            <Text
                                fontsize="1rem"
                                fontcolor={fontColors.textBlack}
                                fontfamily={fontFamilies.TheinhardtPanRegular}
                            >
                                {completed}
                                {' '}
                                {moment(dataConfig.completed_on).format('MM/DD/yyyy')}
                            </Text>
                        </TickAndTextWrapper>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanLight}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            width={isClientPG ? '16rem' : '20rem'}
                            border={`1px solid ${fontColors.electricBlue}`}
                            onClick={event => {
                                onClose(event);
                                if (localStorage.getItem('return_to_home') != null && localStorage.getItem('return_to_home') === 'true') {
                                    history.push('/');
                                } else {
                                    history.push(`/app/practice/${dataConfig.practice_id}`);
                                }
                            }}
                        >
                            {returnToPractice}
                        </Actionbutton>
                    </div>
                );
            case 'hybrid_prev_next':
                return state !== 4 || dataConfig.next_prompt ? (
                    <Fragment>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        <div className={`${isClientPG ? (dataConfig.in_todo ? 'horizontal_wrapper' : 'horizontal_wrapper row-reverse') : 'vertical_wrapper'}`}>
                            {dataConfig.in_todo && (
                            <ReminderAction promptDetailsData={dataConfig} setNotificationActive={() => {}} onClose={onClose} />
                            )}
                            <Next
                                state={state}
                                callBack={callBack}
                                required={required}
                                hybridData={hybridData}
                                setError={setError}
                                config={{
                                    completeId: dataConfig.id,
                                    nextId: dataConfig.next_prompt,
                                    text: dataConfig.article_buttons.find(elem => elem.key === 'complete').text,
                                    quizId: dataConfig?.quiz?.id,
                                }}
                                onClose={onClose}
                                refreshCallback={refreshCallback}
                                disableParent={disableParent}
                                promptLoading={promptLoading}
                                loadingState={loadingState}
                                setLoadingState={setLoadingState}
                            />
                            {(isClientPG && !dataConfig.in_todo) && (
                            <Element>
                                <Actionbutton
                                    fontSize="1.125rem"
                                    fontFamily={fontFamilies.TheinhardtPanLight}
                                    fontColor={fontColors.electricBlue}
                                    bgColor={fontColors.siteWhite}
                                    disabled={false}
                                    width={isClientPG ? '16rem' : '20rem'}
                                    border={`1px solid ${fontColors.electricBlue}`}
                                    btnLoading={loading}
                                    btnWhite
                                    onClick={event => {
                                        setLoadingState({ prevLoading: false, nextLoading: false });
                                        if (dataConfig.in_todo) removeToDo(event);
                                        else {
                                            setLoading(true);
                                            changePracticeStatus(dataConfig.id, 'todo').then(() => {
                                                // setLoading(false);
                                            });
                                        }
                                    }}
                                >
                                    {
                                        dataConfig.article_buttons.find(elem => (dataConfig.in_todo ? elem.key === 'remove_todo' : elem.key === 'add_todo')).text
                                    }
                                </Actionbutton>
                            </Element>
                            )}
                            {dataConfig.prev_prompt && !dataConfig.in_todo && (
                                <Previous
                                    config={{
                                        btnTxt: dataConfig.article_buttons.find(elem => elem.key === 'prev').text,
                                        prevId: dataConfig.prev_prompt,
                                    }}
                                    refreshCallback={refreshCallback}
                                    promptLoading={promptLoading}
                                    loadingState={loadingState}
                                    setLoadingState={setLoadingState}
                                    setError={setError}
                                />
                            )}
                        </div>
                    </Fragment>
                ) : (
                    <div className={`${isClientPG ? 'horizontal_wrapper' : 'vertical_wrapper'}`}>
                        <TickAndTextWrapper>
                            <img src={tick} alt="completed practice" />
                            <Text
                                fontsize="1rem"
                                fontcolor={fontColors.textBlack}
                                fontfamily={fontFamilies.TheinhardtPanRegular}
                            >
                                {completed}
                                {' '}
                                {moment(dataConfig.completed_on).format('MM/DD/yyyy')}
                            </Text>
                        </TickAndTextWrapper>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanLight}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            width={isClientPG ? '16rem' : '20rem'}
                            border={`1px solid ${fontColors.electricBlue}`}
                            onClick={event => {
                                onClose(event);
                                if (localStorage.getItem('return_to_home') != null && localStorage.getItem('return_to_home') === 'true') {
                                    history.push('/');
                                } else {
                                    history.push(`/app/practice/${dataConfig.practice_id}`);
                                }
                            }}
                        >
                            {returnToPractice}
                        </Actionbutton>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div key={dataConfig.id}>
            <div>{getLayout()}</div>
            {error.isError && (
                <div className="error" style={{ color: 'red', marginTop: '4px' }}>
                    {error.errorMessage}
                </div>
            )}
        </div>
    );
};


export default HybridGoals;

HybridGoals.defaultProps = {
    promptLoading: false,
};

HybridGoals.propTypes = {
    dataConfig: PropTypes.shape({
        hit_remove_todo_api: PropTypes.bool,
        id: PropTypes.number,
        cta_layout: PropTypes.string,
        next_prompt: PropTypes.number,
        prev_prompt: PropTypes.number,
        article_buttons: PropTypes.array,
        completed_on: PropTypes.string,
        practice_id: PropTypes.number,
        in_todo: PropTypes.bool,
        quiz: PropTypes.shape({
            id: PropTypes.number,
        }),
    }).isRequired,
    hybridData: PropTypes.shape({}).isRequired,
    required: PropTypes.shape({}).isRequired,
    state: PropTypes.number.isRequired,
    callBack: PropTypes.func.isRequired,
    changePracticeStatus: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    refreshCallback: PropTypes.func.isRequired,
    disableParent: PropTypes.func.isRequired,
    promptLoading: PropTypes.bool,
};
