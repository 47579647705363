import React from 'react';
import PropTypes from 'prop-types';
import './contentType.scss';

export default function ImageContent({ feedData }) {
    return (
        <div className="ImageContent">
            <img src={feedData.file} alt="feed file" />
        </div>
    );
}

ImageContent.propTypes = {
    feedData: PropTypes.object.isRequired,
};
