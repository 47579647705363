import React from 'react'
import WarningModal from '../../../../components/WarningModal'
import './index.scss'
import { fontColors, fontFamilies } from '../../../../common/fontMap'
import Actionbutton from '../../../../components/ActionButton'
import { useMobileCheck } from '../../../../utils/functions'

const QuizResult = ({ data, handleAction }) => {
    const isMobile = useMobileCheck();

    return (
        <WarningModal classNames="QuizPopUpModal" isFullScreen={isMobile} hasTopSpacing={isMobile}>
            <div className="quiz-result">
                <div className="quiz-result-content">
                    <div className="title">{data.title}</div>
                    <div className="quiz-result-image">
                        <img height="190" width="250" src={data.image_url} alt="Quiz" />
                    </div>

                    <div className="content">
                        <div className="sub-title" dangerouslySetInnerHTML={{ __html: data.description }} />
                    </div>

                    <div className="action">
                        <Actionbutton
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            fontSize="1.125rem"
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            width="20vw"
                            onClick={handleAction}
                        >
                            {data.button_text}
                        </Actionbutton>
                    </div>
                </div>
            </div>
        </WarningModal>
    )
}

export default QuizResult
