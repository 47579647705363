import types from './types';

export const getFeedbackQuestion = token => ({
    type: `${types.GET_FEEDBACK_QUESTION}_PENDING`,
    token,
});

export const storeFeedbackQuestion = data => ({
    type: `${types.GET_FEEDBACK_QUESTION}_SUCCESS`,
    data,
});

export const postFeedbackQuestionAnswer = (payload, token) => ({
    type: `${types.POST_FEEDBACK_QUESTION_ANSWER}_PENDING`,
    payload,
    token,
});

export const getFeedbackQuestionFromEmail = (baseurl, email) => ({
    type: `${types.GET_FEEDBACK_QUESTION_FROM_EMAIL}_PENDING`,
    baseurl,
    email,
});

export const postFeedbackQuestionAnswerFromEmail = (baseurl, email, payload) => ({
    type: `${types.POST_FEEDBACK_QUESTION_ANSWER_FROM_EMAIL}_PENDING`,
    baseurl,
    email,
    payload,
});
