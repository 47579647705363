import React, { useEffect, useState } from 'react';
import './index.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from '../../assets/addIcon.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import TodoCard from '../../components/TodoCard';
import rArrow from '../../assets/rArrow.svg';
import AddGoal from './AddGoal';
import GoalDetails from './GoalDetails';
import PromptArticle from '../PromptArticle/index.container';
import AddNewPost from '../Feeds/AddNewPost';
import { pageAnalytics } from '../../utils/functions';

const Todos = ({
    history,
    uploadPost,
    tags,
    uploadingPost,
    getTags,
    todos,
    getTodos,
    getRecommendedPrompts,
    getUserMatrix,
    resetGoalTab,
    // hideSections,
}) => {
    const [view, setView] = useState(0);

    // eslint-disable-next-line no-unused-vars
    const [activeGoalDetail, setActiveGoalDetail] = useState({});
    const [showPrompt, setShowPrompt] = useState({
        show: false,
    });
    const [hashtag, setHashTag] = useState('');

    const [articleId, setTodoArticleId] = useState('');
    const [showCreatePost, setShowCreatePost] = useState(false);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { toDoListTitle, emptyToDo, seeAllToDos, addNew } = localisationsAfterLogin;

    useEffect(() => {
        getTags(history);
    }, [getTags, history]);

    const handleOnTodoClick = card => {
        if (card.type === 'custom') {
            setActiveGoalDetail({ id: card.id });
            setView(2);
        } else if (card.type === 'prompt') {
            setActiveGoalDetail({ id: card.id });
            setTodoArticleId(card.id);
            setShowPrompt({ show: true });
            pageAnalytics.initialise(`todo/${card.id}/promptDetail`);
        }
    };

    const handleClose = () => {
        setShowCreatePost(false);
    };

    const handleClosePromptArticle = ({ show }) => {
        setShowPrompt({ show });
        getTodos(6, 1);
        getRecommendedPrompts();
        getUserMatrix();
        resetGoalTab();
        pageAnalytics.complete(`todo/${activeGoalDetail.id}/promptDetail`);
        setActiveGoalDetail({});
        /*  if (hideSections && !hideSections.includes('recommended')) {
            getRecommendedPrompts();
        } */
    };

    useEffect(() => {
        getTodos(6, 1);
        // eslint-disable-next-line
    }, [view])

    const showModal = () => {
        switch (view) {
            case 1:
                return <AddGoal setView={setView} />;
            case 2:
                return <GoalDetails setView={setView} activeGoalDetail={activeGoalDetail} />;
            default:
                return null;
        }
    };

    const showAllToDos = () => {
        history.push('/all-todos');
    };

    return (
        <div className="todos">
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={articleId}
                    setShowCreatePost={setShowCreatePost}
                    tag={`todoPrompts/${articleId}`}
                />
            )}
            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || { practice: [], category: [] }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
            <div className="todo-header">
                <div className="title">{toDoListTitle}</div>
                <div className="add-new-todo" onClick={() => setView(1)}>
                    <div className="icon">
                        <img src={AddIcon} alt="ADD NEW TODO" />
                    </div>
                    <div className="label">{addNew}</div>
                </div>
            </div>

            <div className="todos-list">
                {todos.length > 0 ? (
                    todos
                        .slice(0, 3)
                        .map((card, index) => (
                            <TodoCard key={index} index={index} card={card} onClick={() => handleOnTodoClick(card)} />
                        ))
                ) : (
                    <div className="no-goals">
                        <div className="title">{emptyToDo}</div>
                    </div>
                )}

                {todos.length > 3 && (
                    <div className="see-all-goals" onClick={showAllToDos}>
                        <div className="title">{seeAllToDos}</div>
                        <div className="icon">
                            <img src={rArrow} alt={seeAllToDos} />
                        </div>
                    </div>
                )}
            </div>
            {showModal()}
        </div>
    );
};

Todos.defaultProps = {
    tags: {},
};

Todos.propTypes = {
    history: PropTypes.object.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    uploadPost: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    getTags: PropTypes.func.isRequired,
    getTodos: PropTypes.func.isRequired,
    getRecommendedPrompts: PropTypes.func.isRequired,
    todos: PropTypes.array.isRequired,
    getUserMatrix: PropTypes.func.isRequired,
    resetGoalTab: PropTypes.func.isRequired,
    // hideSections: PropTypes.array.isRequired,
};
export default withRouter(Todos);
