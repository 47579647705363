import React from 'react';
import PropTypes from 'prop-types';
import PreviewProfileImage from './PreviewProfileImage';

export default function ProfileImage({ image }) {
    return (
        <div>
            <svg height="0" width="0">
                <clipPath id="myProfilePath">
                    <path
                        fill="none"
                        fillRule="evenodd"
                        stroke="#979797"
                        d="M15.542 2.489C6.796 6.793-1.244 20.263 2.092 33.137c2.69 10.38 25.388 26.49 37.996 12.243 18.18-20.543 5.045-31.448-1.641-37.024-5.023-4.189-14.158-10.171-22.905-5.867z"
                    />
                </clipPath>
            </svg>
            <div className="profile_image_adjusted">
                <PreviewProfileImage src={image} alt="image" width={48} height={50} id="myProfilePath" />
            </div>
        </div>
    );
}

ProfileImage.propTypes = {
    image: PropTypes.string.isRequired,
};
