import { put, takeLatest } from 'redux-saga/effects';
import {
    storeImage,
    storeQuestions,
    getVcrbDataSuccess,
    getVcrbDataFailed,
    getNotesSuccess,
    getNotesFailed,
    addNotesFailed,
    addNotesSuccess,
} from './actions';
import { getUserData } from '../UnAuthenticatedContent/actions';
import types from './types';
import { uploadImageApi, getQuestionsApi, postNameAnswersApi, getVcrbApi, getNotesApi, postNotesApi } from './apis';
import { tokenValidatorGenerator } from '../../utils/functions';
import AuthStore from '../../common/AuthStore';
import { clientExpiredAction, resetClientExpiredAction, storeError } from '../../common/actions';
import { CODE_CLIENT_EXPIRED } from '../../utils/constants';

function* uploadImage({ payload }) {
    try {
        if (payload.preferred_name) {
            const { data } = yield tokenValidatorGenerator({
                saga: uploadImageApi,
                payload: [AuthStore.accessToken],
                body: payload,
            });
            yield put(storeImage(data.data));
            localStorage.setItem('preferred_name', data.data.preferred_name);
        } else {
            const formData = new FormData();
            formData.append('profile_picture', payload.data[0].response);
            formData.append('filename', payload.data[0].response.name);
            const { data } = yield tokenValidatorGenerator({
                saga: uploadImageApi,
                payload: [AuthStore.accessToken],
                body: formData,
            });
            yield put(resetClientExpiredAction());
            yield put(storeImage(data.data));
            localStorage.setItem('profile_picture', data.data.profile_picture);
        }
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
        }
    }
}

function* getQuestions({ history }) {
    const { data } = yield tokenValidatorGenerator({
        saga: getQuestionsApi,
        payload: [AuthStore.accessToken],
        history,
    });
    if (data && data.data) {
        yield put(storeQuestions(data.data));
    }
}

function* postAnswers({ question, history }) {
    try {
        const payload1 = {
            data: [{ ...question }],
        };
        yield tokenValidatorGenerator({
            saga: postNameAnswersApi,
            payload: [AuthStore.accessToken],
            body: payload1,
        });
        yield put(getUserData());
        if (localStorage.getItem('status') === 'false') {
            history.push('/firstloginexperience');
        } else {
            history.push('/app');
        }
    } catch (e) {
        yield put(storeError(e));
    }
}

function* getVcrb() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getVcrbApi,
            payload: [AuthStore.accessToken],
        });
        yield put(getVcrbDataSuccess(data));
    } catch ({ response }) {
        yield put(getVcrbDataFailed(response.data));
    }
}

function* getNotes({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getNotesApi,
            payload: [AuthStore.accessToken, payload],
        });
        yield put(getNotesSuccess(data));
    } catch ({ response }) {
        yield put(getNotesFailed(response.data));
    }
}

function* addNotes({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: postNotesApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });
        yield put(addNotesSuccess(data));
    } catch ({ response }) {
        yield put(addNotesFailed(response.data));
    }
}
const CreateProfileSaga = [
    takeLatest(`${types.UPLOAD_IMAGE_PENDING}`, uploadImage),
    takeLatest(types.GET_QUESTIONS, getQuestions),
    takeLatest(types.POST_ANSWERS_PENDING, postAnswers),
    takeLatest(types.GET_VCRB_PENDING, getVcrb),
    takeLatest(types.GET_NOTES_PENDING, getNotes),
    takeLatest(types.ADD_NOTES_PENDING, addNotes),
];

export default CreateProfileSaga;
