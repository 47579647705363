import { put, takeLatest } from 'redux-saga/effects';
import * as types from './types';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { apiFailed, clientExpiredAction, resetClientExpiredAction } from '../../../common/actions';
import {
    getHomePageFeedApi,
    getRecommendedPromptsApi,
    getTodosApi,
    closeCardApi,
    getGoalsApi,
    getGoalsByCategoryApi,
    deleteGoalApi,
} from './apis';
import {
    deleteGoalSuccess,
    storeGoals,
    storeHomePage,
    storeRecommendedPrompts,
    storeTodos,
    getGoalsByCategoriesFailed,
    storeGoalsByCategories,
} from './actions';
import { CODE_CLIENT_EXPIRED } from '../../../utils/constants';

function* getHomeSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getHomePageFeedApi,
            payload: [AuthStore.accessToken],
        });
        yield put(resetClientExpiredAction());
        yield put(storeHomePage(data.data.dynamic_card));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getRecommendedPromptsSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getRecommendedPromptsApi,
            payload: [AuthStore.accessToken],
        });
        yield put(resetClientExpiredAction());
        yield put(storeRecommendedPrompts(data.data));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getTodosSaga({ pageSize, pageNumber }) {
     try {
        const { data } = yield tokenValidatorGenerator({
            saga: getTodosApi,
            payload: [AuthStore.accessToken, pageSize, pageNumber],
        });
         yield put(resetClientExpiredAction());
        yield put(storeTodos(data.data));
    } catch (e) {
         if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
         } else {
             yield put(resetClientExpiredAction());
             yield put(apiFailed());
         }
    }
}

function* getGoalsSaga({ pageSize, pageNumber, status }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getGoalsApi,
            payload: [AuthStore.accessToken, pageSize, pageNumber, status],
        });
        yield put(resetClientExpiredAction());
        yield put(storeGoals(data.data, data.count, data.hide_goal_section));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getGoalsByCategorySaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getGoalsByCategoryApi,
            payload: [AuthStore.accessToken],
        });
        yield put(resetClientExpiredAction());
        yield put(storeGoalsByCategories(data.data, data.hide_goal_section));
    } catch (e) {
        if (e?.response?.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(getGoalsByCategoriesFailed());
        }
    }
}

function* deleteGoalsSaga({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: deleteGoalApi,
            payload: [AuthStore.accessToken, payload],
        });
        yield put(resetClientExpiredAction());
        const dataRequest = { data, payload };
        yield put(deleteGoalSuccess(dataRequest));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}


function* closeCardSaga({ id }) {
    try {
        yield tokenValidatorGenerator({
            saga: closeCardApi,
            payload: [AuthStore.accessToken, id],
        });
        yield getHomeSaga();
    } catch (e) {
        yield put(apiFailed());
    }
}

const HomeSagas = [
    takeLatest(`${types.GET_HOME_PAGE}_PENDING`, getHomeSaga),
    takeLatest(`${types.GET_RECOMMENDED_PROMPTS}_PENDING`, getRecommendedPromptsSaga),
    takeLatest(`${types.GET_TODOS}_PENDING`, getTodosSaga),
    takeLatest(`${types.CLOSE_CARD}_PENDING`, closeCardSaga),
    takeLatest(`${types.GET_GOALS}_PENDING`, getGoalsSaga),
    takeLatest(`${types.GET_GOALS_BY_CATEGORIES}_PENDING`, getGoalsByCategorySaga),
    takeLatest(`${types.DELETE_GOAL}_PENDING`, deleteGoalsSaga),
];

export default HomeSagas;
