import { fromJS } from 'immutable';
import createReducer from '../utils/redux-utils/createReducer';

const INITIAL_STATE = fromJS({
    error: {},
    success: {},
});

const notification = createReducer(INITIAL_STATE, {
    THROW_ERROR: (state, { payload }) => state.set('error', payload),
    REMOVE_NOTIFICATION: state => state.set('error', {}).set('success', {}),
    THROW_SUCCESS: (state, { payload }) => state.set('success', payload),
});

export default notification;
