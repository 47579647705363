import { fetchWebApi } from '../../../webapis/dashboard';
import { webApiPut } from '../../../webapis/core';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';
import { COACH_MARKS_TYPES } from '../../../utils/constants';

export const getPracticesInProgressApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPractices } = endpoints;
    const url = `${apiBasePath}${getPractices}?state=2&page=${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getPracticesCompletedApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPractices } = endpoints;
    const url = `${apiBasePath}${getPractices}?state=3&page=${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const getPracticesNewApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { getPractices } = endpoints;
    const url = `${apiBasePath}${getPractices}?state=1&page=${params[1]}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const fetchNotifications = params => {
    const apiBasePath = AuthStore.baseUrl;
    const { fetchAllNotifications } = endpoints;
    const url = `${apiBasePath}${fetchAllNotifications}?page_size=${params.page_size}&page=${params.page}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const readNotification = notificaitonId => {
    const apiBasePath = AuthStore.baseUrl;
    const { fetchAllNotifications } = endpoints;
    const url = `${apiBasePath}${fetchAllNotifications}`;
    return webApiPut(AuthStore.accessToken, url, { notification_id: notificaitonId }).request;
};

export const readAllNotifications = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { markAllRead } = endpoints;
    const url = `${apiBasePath}${markAllRead}`;
    return webApiPut(AuthStore.accessToken, url, {}).request;
};

export const getCoachMarks = type => {
    const apiBasePath = AuthStore.baseUrl;
    const { COACH_MARKS_PRACTICE, COACH_MARKS_ACCELETOR } = endpoints;
    const url = `${apiBasePath}${type === COACH_MARKS_TYPES.PROFILE_VIDEO_ACCELETOR ? COACH_MARKS_ACCELETOR : COACH_MARKS_PRACTICE}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const setNotificationReminder = ({ article_id, is_notification_active, notification_time }) => {
    const apiBasePath = AuthStore.baseUrl;
    const { feeds } = endpoints;
    const url = `${apiBasePath}${feeds}${article_id}/todo_custom_notification/`;
    return webApiPut(AuthStore.accessToken, url, { is_notification_active, notification_time }).request;
};
