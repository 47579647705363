/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal } from '@material-ui/core';
import dotsIcon from './assets/dots.svg';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import LocalisationsStore from '../../common/LocalisationsStore';

const ReportPost = ({ feedId, reporttPost, profileId, user, showOverlay }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { reportPost, youAreAboutToReportPost, reportPostAction, deletePost } = localisationsAfterLogin;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openReportPostModal, toggleReportPostModal] = useState(false);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReportPostModalClose = () => {
        toggleReportPostModal(false);
    };

    const handleReportClick = () => {
        reporttPost(feedId);
        toggleReportPostModal(false);
    };

    const openReportModal = () => {
        toggleReportPostModal(true);
        handleClose();
    };

    return (
        <div>
            <div className="dot-wrapper pointer" onClick={handleClick}>
                <img className="report_dots" src={dotsIcon} alt="" role="none" />
            </div>
            <div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className="reportPostMenu"
                >
                    {profileId === user ? (
                        <MenuItem
                            key={2}
                            onClick={() => {
                                handleClose();
                                showOverlay(true);
                            }}
                        >
                            <span>{deletePost}</span>
                        </MenuItem>
                    ) : (
                        <MenuItem key={1} onClick={openReportModal}>
                            <span>{reportPost}</span>
                        </MenuItem>
                    )}
                </Menu>
            </div>

            <Modal open={openReportPostModal} className="report_post_modal">
                <div className="report_post_container">
                    <div className="text_container">
                        <div className="title_container">
                            <span className="report_this_post_label">{reportPost}</span>
                            <div
                                className="close_button"
                                onClick={handleReportPostModalClose}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={0}
                            >
                                <Cross color="black" />
                            </div>
                        </div>
                        <span className="subtitle_report_post">{youAreAboutToReportPost.split('.')[0]}</span>
                        <span className="subtitle_report_post">{youAreAboutToReportPost.split('.')[1]}</span>
                    </div>
                    <div className="minor_break" />
                    <div className="report_btn" onClick={handleReportClick} role="none">
                        {reportPostAction}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

ReportPost.propTypes = {
    feedId: PropTypes.number.isRequired,
    reporttPost: PropTypes.func.isRequired,
};
export default ReportPost;
