import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import './index.scss';

const QuestionHeading = props => {
    const { description } = props;
    return (
        <Grid className="questionContainer" container>
            {/* <Grid container className="questionNumber">
                <span className="currentQuestion">{`${`0${index}`.slice(-2)} /`}</span>
                <span className="totalQuestions">{`0${totalQuestions}`.slice(-2)}</span>
            </Grid> */}
            <Grid container className="questionText">
                {description}
            </Grid>
        </Grid>
    );
};

QuestionHeading.propTypes = {
    description: PropTypes.string.isRequired,
};

export default QuestionHeading;
