import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './FeedCard.scss';
import { fontColors, fontFamilies } from '../../common/fontMap';
import commentsIcon from '../../assets/commnts.svg';
import Text from '../../components/Text';
import { getLastUpdatedAt } from '../../utils/functions';
import SvgPicture from '../../components/SvgPicture';
import FollowUnfollow from '../Feeds/FollowUnfollow';
import FeedCardTypeMapper from './FeedCardTypeMapper';
import CommentsForArticle from '../Feeds/CommentsForArticle';
import TagUser from '../../components/TagUser';
import LocalisationsStore from '../../common/LocalisationsStore';
import ReportPost from '../Feeds/ReportPost';
import WarningModal from '../../components/WarningModal';
import Actionbutton from '../../components/ActionButton';
import heartlike from '../../assets/heartlike.svg';
import dislike from '../../assets/dislike.svg';
import LikeDetailModal from '../Feeds/LikeDetailModal';

export default function FeedCard({
    feed,
    postLike,
    likeComment,
    getAllComments,
    postComment,
    deleteComment,
    index,
    articleComments,
    followedId,
    postFollowUser,
    postUnfollowUser,
    // eslint-disable-next-line no-unused-vars
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    reportPost,
    deletePost,
    profilePicture,
    likeDetails,
    followedUsers,
    getLikeDetails,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        addAComment,
        youAreAboutToDeleteAPost,
        deletePostAction,
        cantUndoAction,
        dontdelete,
        like,
        likes,
        comments,
        sharePost,
        COMMENT,
    } = localisationsAfterLogin;

    const [isLiked, setFeedLiked] = useState(feed.is_liked);
    const [feedLikedCount, setFeedLikedCount] = useState(feed.like_count);
    const [commentValue, setCommentValue] = useState('');
    const [articleId, setArticleId] = useState(null);
    const [selectedUsers, setSelecteduser] = useState([]);
    const [replyCommentProps, setReplyProps] = useState({
        parentcommentid: '',
        articleid: '',
    });
    const [isOverlay, setIsOverlay] = useState(false);
    const [getLikes, setGetLikes] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [likeDetailsWithFollowed, setLikeDetailsWithFollowed] = useState([]);

    useEffect(() => {
        const likeDetailsWithFollowed = likeDetails.map(user => ({
            ...user,
            is_following: followedUsers.some(followedUser => followedUser.following_to === user.liked_by),
        }));
        setLikeDetailsWithFollowed(likeDetailsWithFollowed);
    }, [followedUsers, likeDetails]);

    useEffect(() => {
        setFeedLiked(feed.is_liked);
        setFeedLikedCount(feed.like_count);
    }, [feed.is_liked, feed.like_count]);

    const inputRefs = [];

    const singleTag = feed.title.split(' ');
    const singleTagFilter = singleTag.filter(tag => tag.includes('#'));

    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const profileId = userProfile && userProfile.id;

    const showCommentsForThisArticle = articleId => {
        getAllComments(articleId);
    };

    const setRef = ref => {
        inputRefs.push(ref);
    };

    const setFocus = () => {
        inputRefs[0].editor.focus();
    };

    const urlify = text => {
        // eslint-disable-next-line no-useless-escape
        const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const stringList = text.split(' ');
        return stringList
            .map(element => element.replace(urlRegex, url => `<a target="_blank" href="http://${url}">${url}</a>`))
            .join(' ');
    };

    // const handleUrlClick = e => {
    //     e.preventDefault();
    //     window.open(`${e.target.href}`, '_blank');
    // };

    const handleChange = (e, id) => {
        e.stopPropagation();
        postLike({ articleId: id, selectedHashTag: '', selectedKey: 1 });
        if (!isLiked) {
            setFeedLiked(true);
            setFeedLikedCount(feedLikedCount => feedLikedCount + 1);
        } else {
            setFeedLiked(false);
            setFeedLikedCount(feedLikedCount => feedLikedCount - 1);
        }
    };

    const handleClick = e => {
        const { currentTarget: { dataset: { itemid } } } = e;

        // if (!replyCommentProps.parentcommentid) {
        postComment(itemid, commentValue, selectedUsers);
        // }
        setCommentValue('');

        inputRefs.forEach(ed => {
            if (ed.editor) {
                ed.createEmptyState();
            }
        });
    };

    const likeThisComment = e => {
        const { currentTarget: { dataset: { commid, articleid } } } = e;
        likeComment(commid, articleid);
    };

    const getlikedBy = e => {
        // eslint-disable-next-line no-unused-vars
        const { currentTarget: { dataset: { commentid } } } = e;
        // getLikeDetails({ commentId: commentid });
        // setGetLikes(true);
        // getUsersFollowed();
    };

    const handleCommentOnClick = () => {
        setShowComments(!showComments);
        if (!showComments) showCommentsForThisArticle(feed.id);
    };
    const handleTagUserChange = (value, itemId) => {
        setCommentValue(value);
        setArticleId(Number(itemId));
    };

    const showOverlay = isShown => {
        setIsOverlay(isShown);
    };

    const deleteAPost = articleId => {
        deletePost({ articleId });
        setIsOverlay(false);
    };

    const handleClose = () => {
        setGetLikes(false);
    };

    const getDetails = articleId => {
        if (feedLikedCount > 0) {
            setGetLikes(true);
            getLikeDetails({ articleId });
            getUsersFollowed();
        }
    };

    return (
        <div className="feed_card_wrapper" key={`feed-card-social-${feed.id}`}>
            <div className="user_details_section">
                {getLikes && (
                    <LikeDetailModal
                        open={getLikes}
                        handleClose={handleClose}
                        likeDetails={likeDetailsWithFollowed}
                        isLoading={false}
                        followedId={followedId}
                        postFollowUser={postFollowUser}
                        postUnfollowUser={postUnfollowUser}
                        setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                    />
                )}
                <div className="display_picture_name_wrapper">
                    <SvgPicture>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.44064 1.38247C0.929164 4.76672 -1.7404 17.6908 1.12466 27.8127C3.43454 35.9732 25.1601 46.9252 33.8603 34.1541C44.1773 19.0099 39.6021 9.16505 33.8603 4.78097C29.5474 1.48788 15.9521 -2.00179 8.44064 1.38247Z"
                                fill={`url(#pattern${feed.id})`}
                            />
                            <defs>
                                <pattern
                                    id={`pattern${feed.id}`}
                                    patternContentUnits="objectBoundingBox"
                                    width="1"
                                    height="1"
                                >
                                    <use xlinkHref={`#image${feed.id}`} transform="scale(0.00390625)" />
                                </pattern>
                                <image id={`image${feed.id}`} width="256" height="256" xlinkHref={profilePicture} />
                            </defs>
                        </svg>
                    </SvgPicture>
                    <div>
                        <Text
                            fontsize="0.8rem"
                            fontfamily={fontFamilies.TheinhardtPanMedium}
                            fontcolor={fontColors.textBlack}
                            whiteSpace="pre-wrap"
                        >
                            {feed.created_by}
                        </Text>
                        <Text
                            fontsize="0.8rem"
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                            fontcolor={fontColors.placeholderGrey}
                        >
                            {getLastUpdatedAt(feed.post_date)}
                        </Text>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {profileId !== feed.user && (
                        <FollowUnfollow
                            isFollowing={feed.is_following}
                            followedId={followedId}
                            postFollowUser={postFollowUser}
                            userId={feed.user}
                            postUnfollowUser={postUnfollowUser}
                            setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                        />
                    )}
                    <ReportPost
                        feedId={feed.id}
                        reporttPost={reportPost}
                        showOverlay={showOverlay}
                        profileId={profileId}
                        user={feed.user}
                    />
                </div>
            </div>
            <div className="content_wrapper">{new FeedCardTypeMapper(feed).getContent()}</div>
            <div className="title_wrapper">
                {singleTag
                    .filter(tag => !tag.includes('#'))
                    .map((tag, index) => {
                        const isAUser = tag.match(/[\u200b]/g);
                        if (isAUser) {
                            return (
                                <span key={`${feed.id}-hash-${index}`} className="_heading is_a_user">
                                    {` ${tag}`}
                                </span>
                            );
                        }
                        return (
                            <span
                                key={`${feed.id}-hash-no-user-${index}`}
                                className="_heading"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: urlify(tag) }}
                                // onClick={handleUrlClick}
                            />
                        );
                    })}
                {singleTagFilter.length > 0 && (
                    <div className="contentPnl tagSection">
                        {singleTagFilter.map((tag, index) => (
                            <span
                                key={`${feed.id}-tags-${index}`}
                                className="tagHeading"
                                data-tag={tag}
                                onClick={() => {}}
                                role="none"
                            >
                                {` ${tag}`}
                            </span>
                        ))}
                    </div>
                )}
            </div>
            <div className="like_comment_wrapper">
                <div id="main-content">
                    <div className="like-icon" onClick={e => handleChange(e, feed.id)}>
                        <img src={isLiked ? dislike : heartlike} alt="" />
                    </div>
                    <Text
                        fontsize="0.8rem"
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        fontcolor={fontColors.electricBlue}
                        margin="0 5px 0 0"
                        letterSpacing="1px"
                        cursor="pointer"
                        onClick={() => getDetails(feed.id)}
                    >
                        <span>{`${feedLikedCount} ${feedLikedCount === 1 ? like : likes}`}</span>
                    </Text>
                </div>
                <div className="comment" onClick={handleCommentOnClick}>
                    <Text
                        fontsize="0.8rem"
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        fontcolor={fontColors.electricBlue}
                        margin="0 5px 0 0"
                        letterSpacing="1px"
                        cursor="pointer"
                        textTransform="uppercase"
                    >
                        <img src={commentsIcon} alt="Comments" className="comment__icon" />
                        <span>
                            {`${feed?.comment_count > 0 ? feed?.comment_count : ''} ${
                            feed?.comment_count > 1 ? comments : COMMENT
                            }`}
                        </span>
                    </Text>
                </div>
            </div>
            <div className="commentWrapper_first_el">
                <TagUser
                    onChange={handleTagUserChange}
                    placeholder={addAComment}
                    userAddMentions={setSelecteduser}
                    value={articleId === feed.id ? commentValue : ''}
                    itemid={feed.id}
                    className={`commentSection outline-none ${commentValue.length === 0 && 'full-width'}`}
                    ref={setRef}
                />
                {commentValue.length > 0 && (
                    <span data-itemid={feed.id} role="none" onClick={handleClick} className="post_comment_btn pointer">
                        {sharePost}
                    </span>
                )}
            </div>
            {feed.showComments && showComments && (
                <CommentsForArticle
                    articleComments={articleComments[articleId] || []}
                    likeThisComment={likeThisComment}
                    deleteComment={deleteComment}
                    setFocus={setFocus}
                    articleindex={index}
                    replyCommentProps={replyCommentProps}
                    setReplyProps={setReplyProps}
                    getlikedBy={getlikedBy}
                    postComment={postComment}
                />
            )}
            {isOverlay && (
                <WarningModal>
                    <div style={{ display: 'flex', padding: '1.25rem' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text
                                fontsize="1rem"
                                fontfamily={fontFamilies.TheinhardtPanLight}
                                fontcolor={fontColors.textBlack}
                                cursor="pointer"
                                margin="0 0 2rem 0"
                            >
                                {youAreAboutToDeleteAPost}
                            </Text>
                            <div className="deleteText">{cantUndoAction}</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Actionbutton
                                    fontSize="1rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.textBlack}
                                    bgColor={fontColors.siteWhite}
                                    disabled={false}
                                    onClick={() => showOverlay(false)}
                                    margin="0 10px 0 0"
                                    border={`1px oslid ${fontColors.textBlack}`}
                                >
                                    {dontdelete}
                                </Actionbutton>

                                <Actionbutton
                                    fontSize="1rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.siteWhite}
                                    bgColor={fontColors.electricBlue}
                                    disabled={false}
                                    onClick={() => deleteAPost(feed.id)}
                                >
                                    {deletePostAction}
                                </Actionbutton>
                            </div>
                        </div>
                    </div>
                </WarningModal>
            )}
        </div>
    );
}

FeedCard.propTypes = {
    feed: PropTypes.object.isRequired,
    postLike: PropTypes.func.isRequired,
    getAllComments: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    articleComments: PropTypes.array.isRequired,
    followedId: PropTypes.number.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    reportPost: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    profilePicture: PropTypes.string.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedUsers: PropTypes.array.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
};
