import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import InspireTopIcon from '../../assets/InspireTopIcon.svg';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import particleBg from '../../assets/particleBg.svg';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';

function CheckEmailComponent({ history }) {
    const { localisationsBeforeLogin } = LocalisationsStore;
    const { CheckYourEmail, ContinueToSignIn } = localisationsBeforeLogin;

    const handleOnClick = () => {
        history.push('/');
    };

    return (
        <div className="unauth_content_container_wrapper_check_email">
            <img src={particleBg} alt="particles" className="particleBg" particleBg />
            <div className="unauth_content_container">
                <img src={InspireTopIcon} alt="inspire_logo" className="biggerInspireTopIcon" />
                <div className="central_content">
                    <div className="header_info">
                        <Text
                            fontsize="2.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.625rem 0"
                        >
                            {CheckYourEmail}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                        >
                            {history.location.state.msg}
                        </Text>
                    </div>
                    <div className="main_section">
                        <div className="action_container">
                            <Actionbutton
                                fontSize="1.125rem"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.siteWhite}
                                bgColor={fontColors.electricBlue}
                                disabled={false}
                                onClick={handleOnClick}
                            >
                                {ContinueToSignIn}
                            </Actionbutton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CheckEmailComponent.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(CheckEmailComponent);
