import React from 'react';
import PropTypes from 'prop-types';
import DragAndDropZone from '../DragAndDropZone';

import StoryWriter from '../StoryWriter';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function AddNewPostInnerContainer({
    file,
    setFile,
    imgSrc,
    setImageSrc,
    setDraggedFile,
    setSubT,
    setFileType,
    setShowCropper,
    isMobile,
    selectedFont,
    setSelectedFont,
    currentGradient,
    setCurrentGradient,
    setGradientIndex,
    subTitle,
    view,
    viewSetter,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { photo, video, text } = localisationsAfterLogin;

    const setView = (e, val) => {
        e.stopPropagation();
        viewSetter(val);
        setSubT('');
        setFile(null);
        setDraggedFile(null);
    };

    return (
        <div className="left_panel_inner">
            <div className="upper_tabs_container">
                <div
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={e => setView(e, 1)}
                    className={view === 1 ? 'tab_button selected' : 'tab_button'}
                >
                    {text.toUpperCase()}
                </div>
                <div
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={e => setView(e, 2)}
                    className={view === 2 ? 'tab_button selected' : 'tab_button'}
                >
                    {photo.toUpperCase()}
                </div>
                <div
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={e => setView(e, 3)}
                    className={view === 3 ? 'tab_button selected' : 'tab_button'}
                >
                    {video.toUpperCase()}
                </div>
            </div>
            {view === 2 || view === 3 ? (
                <DragAndDropZone
                    file={file}
                    setFile={setFile}
                    imgSrc={imgSrc}
                    setImageSrc={setImageSrc}
                    setDraggedFile={setDraggedFile}
                    setFileType={setFileType}
                    setShowCropper={setShowCropper}
                    view={view}
                />
            ) : (
                <StoryWriter
                    setSubT={setSubT}
                    isMobile={isMobile}
                    selectedFont={selectedFont}
                    setSelectedFont={setSelectedFont}
                    currentGradient={currentGradient}
                    setCurrentGradient={setCurrentGradient}
                    setGradientIndex={setGradientIndex}
                    subTitle={subTitle}
                />
            )}
        </div>
    );
}

AddNewPostInnerContainer.defaultProps = {
    file: {},
    isMobile: false,
};

AddNewPostInnerContainer.propTypes = {
    setDraggedFile: PropTypes.func.isRequired,
    setSubT: PropTypes.func.isRequired,
    setFileType: PropTypes.func.isRequired,
    setShowCropper: PropTypes.func.isRequired,
    file: PropTypes.instanceOf(File),
    setFile: PropTypes.func.isRequired,
    imgSrc: PropTypes.string.isRequired,
    setImageSrc: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    selectedFont: PropTypes.string.isRequired,
    setSelectedFont: PropTypes.func.isRequired,
    currentGradient: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        colorOrder: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    setCurrentGradient: PropTypes.func.isRequired,
    setGradientIndex: PropTypes.func.isRequired,
    subTitle: PropTypes.string.isRequired,
    view: PropTypes.number.isRequired,
    viewSetter: PropTypes.func.isRequired,
};
