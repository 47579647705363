import React, { Fragment, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import Actions from './Actions';
import { MONTH_MAP } from '../../../utils/constants';
import LocalisationsStore from '../../../common/LocalisationsStore';
import NewCompletedIcon from '../../../assets/new_completed.svg';
import rArrow from '../../../assets/white_arrow.svg';
import completedTick from '../../../assets/completedTick.svg';
import EditGoal from '../EditGoal';

const ActiveCard = ({
    goal,
    callBack,
    editCallBack,
    displayActions = true,
    setView,
    setActiveGoalDetail,
    fromScreen,
    deleteCallBack,
    deleteGoalAction,
    showGoalTitle,
    goalTextMaxLines,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { dueOn, goalCompletedOn } = localisationsAfterLogin;
    const today = new Date();
    const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0);

    const month = goal.is_completed
        ? new Date(goal.completed_at.replace('Z', '')).getMonth()
        : goal.due_date
            ? new Date(goal.due_date.replace('Z', '')).getMonth()
            : '';
    const date = goal.is_completed
        ? new Date(goal.completed_at.replace('Z', '')).getDate()
        : goal.due_date
            ? new Date(goal.due_date.replace('Z', '')).getDate()
            : '';
    const completionLabel = goal.is_completed ? goalCompletedOn : goal.due_date ? dueOn : '';
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    const [editModal, setEditModal] = useState({ active: false, goal: null });

    const editGoal = goalObj => {
        setEditModal({ active: true, goal: goalObj });
    };

    const onGoalDetailsCardClick = () => {
        if (displayActions) {
            return null;
        }
        if (isClientPG) {
            setView(3);
            setActiveGoalDetail(goal);
        } else {
            setView(2);
            setActiveGoalDetail({ id: goal.id });
        }
        return false;
    };

    const getMaxLinesStyle = maxLines => ({
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: maxLines || 2,
        lineClamp: maxLines || 2,
        WebkitBoxOrient: 'vertical',
    });

    const dueDate = new Date(goal.due_date?.replace('Z', ''));
    return (
        <Fragment>
            {!isClientPG && (
            <div className={`active-card ${displayActions ? '' : 'full-width'}`} onClick={onGoalDetailsCardClick}>
                <div className="card-details">
                    <div className="goal-title">{goal.title ? goal.title.toUpperCase() : 'GOAL'}</div>
                    <div style={{ whiteSpace: 'pre-line' }} className="name">
                        {goal.goal_text}
                    </div>

                    {fromScreen === 'goalDetails' && completionLabel && (
                        <div className="date">{` ${completionLabel} ${MONTH_MAP[month]} ${date}`}</div>
                    )}
                </div>
                {displayActions && !goal.is_completed && (
                    <div className="card-actions">
                        <Actions
                            goal={goal}
                            callBack={callBack}
                            editCallBack={editCallBack}
                            deleteCallBack={deleteCallBack}
                            deleteGoalAction={deleteGoalAction}
                        />
                    </div>
                )}
                {goal.is_completed && (
                    <img style={{ padding: '0 10px' }} onClick={() => { editGoal(goal); }} src={completedTick} alt="Completed Goal" />
                )}
                {editModal.active
                    && (
                        <EditGoal
                            editCallBack={editCallBack}
                            goalObj={editModal.goal}
                            closeModal={setEditModal}
                        />
                    )}
            </div>
            )}
            {isClientPG && goal.is_completed && (
                <div className="completed-goals-new">
                    <img src={NewCompletedIcon} alt="completed" />
                    <div className="c-label">{goal.title}</div>
                    <img style={{ cursor: 'pointer' }} onClick={() => { editGoal(goal); }} src={rArrow} alt="arrow" />
                </div>
            )}
            {isClientPG && !goal.is_completed && (
                <div className={`active-card pg-active-card ${displayActions ? '' : 'full-width'}`} onClick={onGoalDetailsCardClick}>
                    <div className="card-details">
                        {showGoalTitle
                            ? (
                                <div
                                    className="goal-title"
                                    style={{ ...getMaxLinesStyle() }}
                                >
                                    {goal.title ? goal.title.toUpperCase() : 'GOAL'}
                                </div>
                            ) : null}
                        <div
                            style={
                                {
                                    whiteSpace: 'pre-wrap',
                                    lineHeight: '22px',
                                    ...getMaxLinesStyle(goalTextMaxLines),
                                }
                            }
                            className="name"
                        >
                            {goal.goal_text}
                        </div>

                        {(goal.is_due_date_display && goal.due_date) && (
                        <div className="date">{moment(dueDate || minDate).format('DD MMM YYYY')}</div>
                        )}
                    </div>
                    {displayActions && (
                    <div className="card-actions">
                        <Actions
                            goal={goal}
                            callBack={callBack}
                            editCallBack={editCallBack}
                            deleteCallBack={deleteCallBack}
                            deleteGoalAction={deleteGoalAction}
                        />
                    </div>
                    )}
                </div>
            )}
            {editModal.active
                && (
                    <EditGoal
                        editCallBack={editCallBack}
                        goalObj={editModal.goal}
                        closeModal={setEditModal}
                    />
                )}
        </Fragment>
    );
};

ActiveCard.defaultProps = {
    deleteGoalAction: () => {},
    deleteCallBack: () => {},
    setView: () => {},
    setActiveGoalDetail: () => {},
    showGoalTitle: true,
    goalTextMaxLines: null,
    displayActions: true,
};

ActiveCard.propTypes = {
    goal: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired,
    setView: PropTypes.func,
    editCallBack: PropTypes.func.isRequired,
    setActiveGoalDetail: PropTypes.func,
    displayActions: PropTypes.bool,
    deleteCallBack: PropTypes.func,
    fromScreen: PropTypes.string.isRequired,
    deleteGoalAction: PropTypes.func,
    showGoalTitle: PropTypes.bool,
    goalTextMaxLines: PropTypes.number,
};

export default ActiveCard;
