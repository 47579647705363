import React, { useEffect, useState } from 'react';
import WarningModal from '../../components/WarningModal';
import { getCoachMarks } from './redux/apis';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import './coachmarks.scss';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import { pageAnalytics } from '../../utils/functions';

// eslint-disable-next-line react/prop-types
function CoachMarks({ setShowCoachMarks }) {
    const [coachMarks, setCoachMarks] = useState(null);

    useEffect(() => {
        getCoachMarks().then(response => {
            setCoachMarks(response.data.data);
        });
    }, []);

    useEffect(() => {
        pageAnalytics.initialise('coachMarks');
        return () => {
            pageAnalytics.complete('coachMarks');
        };
    }, []);

    const handleCrossClick = () => {
        setShowCoachMarks(false);
    };

    return (
        <>
            {coachMarks && (
                <WarningModal>
                    <div className="coachMarks_wrapper">
                        <div
                            onClick={handleCrossClick}
                            role="button"
                            tabIndex={0}
                            className="cross outline-none pointer"
                        >
                            <Cross color="black" />
                        </div>
                        <img src={coachMarks.image} alt="" />
                        <Text
                            fontsize="1rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                            margin="1rem 0 0 0"
                        >
                            {coachMarks.description}
                        </Text>
                        <div className="steps">
                            {coachMarks.steps.map((step, index) => (
                                <div className="step" key={`coach-${index}`}>
                                    <div className="circle">
                                        <Text
                                            fontsize="1rem"
                                            fontcolor={fontColors.siteWhite}
                                            fontfamily={fontFamilies.TheinhardtPanMedium}
                                        >
                                            {index + 1}
                                        </Text>
                                    </div>
                                    <Text
                                        fontsize="1rem"
                                        fontcolor={fontColors.textBlack}
                                        fontfamily={fontFamilies.TheinhardtPanRegular}
                                        margin="0 0 0 1rem"
                                        width="calc(100% - 28px)"
                                    >
                                        {step}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    </div>
                </WarningModal>
            )}
        </>
    );
}

export default CoachMarks;
