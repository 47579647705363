import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import ClippedImage from '../ClippedImage';
import tick from '../../assets/tick.svg';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';

export default function CompletedPracticeCard({ index, id, name, thumbnail, completedOn, onClick }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { completedOn: completedOnText } = localisationsAfterLogin;

    return (
        <div className="completed_practice_card_container" key={`completed-progress-card-${index}`} data-id={id}>
            <div className="left_panel" onClick={e => onClick(e, id)}>
                <div className="view_practice_wrapper">
                    <Text
                        fontsize=".875rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0 10px 0"
                        letterSpacing="1px"
                        textTransform="uppercase"
                    >
                        {name}
                    </Text>
                    <div className="checkmark_completed_on">
                        <img src={tick} alt="completed practice" />
                        <Text
                            fontsize="1rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontweight="400"
                            margin="0 0 0 0.5rem"
                        >
                            {completedOnText}
                            <>&nbsp;</>
                            <span className="capitalize">
                                {completedOn ? moment(completedOn).format('MMM DD, YYYY') : ''}
                            </span>
                        </Text>
                    </div>
                </div>
            </div>
            <div className="thumbnail">
                <ClippedImage
                    src={thumbnail}
                    alt=""
                    width="3.75rem"
                    height="3.75rem"
                    clipid="dpBlob"
                    bgColor={fontColors.siteWhite}
                />
            </div>
        </div>
    );
}

CompletedPracticeCard.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    completedOn: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
