import { fetchWebApi, postWebApi } from '../../../webapis/dashboard';
import endpoints from '../../../Endpoints';
import AuthStore from '../../../common/AuthStore';

export const weeklyQuestionsApi = params => {
    const apiBasePath = AuthStore.baseUrl;
    const isBefore = params[1];
    const { weeklyQuestion } = endpoints;
    let url = '';
    if (isBefore) {
        url = `${apiBasePath}${weeklyQuestion}?type=before`;
    } else {
        url = `${apiBasePath}${weeklyQuestion}`;
    }
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const weeklyQuestionsAnswerPostApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { weeklyQuestion } = endpoints;
    const url = `${apiBasePath + weeklyQuestion}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const chapterIntroApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { chapterIntroduction } = endpoints;
    const url = `${apiBasePath + chapterIntroduction}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};

export const chapterIntroSeenApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { chapterIntroduction } = endpoints;
    const url = `${apiBasePath}${chapterIntroduction}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};

export const postPreAdditionalQuestionCommentApi = (accessToken, body) => {
    const apiBasePath = AuthStore.baseUrl;
    const { additionalQuestionComment } = endpoints;
    const url = `${apiBasePath}${additionalQuestionComment}`;
    return postWebApi(AuthStore.accessToken, url, body).request;
};
