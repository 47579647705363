import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CarouselSlider from '../../../../components/Carousel';

const Carousel = ({ carousel }) => {
    const { config } = carousel;
    const Title = styled.div`
        font-size: ${`${config.font_size}px` || '16px'};
        color: ${config.color};
        text-transform: ${config.all_caps ? 'uppercase' : ''};
        font-family: ${config.font_family};
        font-weight: ${config.font_weight};
        margin-bottom: 10px;
    `;
    // render
    return (
        <div className="carousel-slider">
            <Title>{carousel.header}</Title>
            <CarouselSlider>
                {(carousel.image || []).map(elem => (
                    <div className="carousel-item" style={{ marginRight: '8px' }} key={elem}>
                        <img src={elem} alt="Carousel Item" height="200" width="200" />
                    </div>
                ))}
            </CarouselSlider>
        </div>
    );
};

export default Carousel;

Carousel.propTypes = {
    carousel: PropTypes.shape({
        config: PropTypes.shape({
            font_size: PropTypes.number,
            color: PropTypes.string,
            all_caps: PropTypes.bool,
            font_family: PropTypes.string,
            font_weight: PropTypes.string,
        }),
        header: PropTypes.string,
        image: PropTypes.array,
    }).isRequired,
};
