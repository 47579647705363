import { put, takeLatest } from 'redux-saga/effects';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getUsersListApi } from './apis';
import { storeQueriedUsers } from './actions';

function* getUsersListSaga({ query }) {
    const { data: { data } } = yield tokenValidatorGenerator({
        saga: getUsersListApi,
        payload: [AuthStore.accessToken, query],
    });
    yield put(storeQueriedUsers(data));
}

const tagUserSaga = [takeLatest('GET_USERS_BY_QUERY_PENDING', getUsersListSaga)];

export default tagUserSaga;
