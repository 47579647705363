import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextPostBGColor } from '../../containers/HubContainer/TextContent';

const Avatar = styled.div`
    width: 2rem;
    height: 2rem;
    border: solid 1.5px #fff;
    border-radius: 0.4rem;
    background-color: ${props => props.gradient};
    margin: 0 0.25rem;
    cursor: pointer;
`;

export default function GradientPicker({ setCurrentGradient, isMobile, setGradientIndex }) {
    const openVariousGradientsAndSet = (e, val, i) => {
        e.stopPropagation();
        setCurrentGradient(val);
        setGradientIndex(i);
    };

    return (
        <Fragment>
            <div className="gradient_container">
                {TextPostBGColor.postBGColors.map((gradient, index) => {
                    if (index === 0) {
                        return (
                            <Avatar
                                gradient={gradient.imageColor}
                                setCurrentGradient={setCurrentGradient}
                                onClick={e => openVariousGradientsAndSet(e, TextPostBGColor.postBGColors[0], index)}
                                isMobile={isMobile}
                            />
                        );
                    }
                    return (
                        <Avatar
                            gradient={gradient.imageColor}
                            onClick={e => {
                                e.stopPropagation();
                                setCurrentGradient(gradient, index);
                            }}
                        />
                    );
                })}
            </div>
        </Fragment>
    );
}

GradientPicker.defaultProps = {
    isMobile: false,
};

GradientPicker.propTypes = {
    setCurrentGradient: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    setGradientIndex: PropTypes.number.isRequired,
};
