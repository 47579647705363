import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SingleNotificationView from './index';
import {
    deleteComment, deletePost,
    getAllComments, getLikeDetails, getUsersFollowed,
    likeComment,
    postComment,
    postFollowUser,
    postLike, postUnfollowUser, reportPost, setFollowUnfollowInFeeds,
} from '../Feeds/redux/actions';


const stateToProps = state => {
    const REDUCER = state.get('feedsReducer');
    return {
        articleComments: REDUCER.articleComments,
        followedId: REDUCER.followedId,
        likeDetails: REDUCER.likeDetails,
        followedUsers: REDUCER.followedUsers,
    };
};

const dispatchToProps = {
    postLike,
    likeComment,
    getAllComments,
    postComment,
    deleteComment,
    postFollowUser,
    postUnfollowUser,
    getUsersFollowed,
    setFollowUnfollowInFeeds,
    reportPost,
    deletePost,
    getLikeDetails,

};


export default withRouter(connect(stateToProps, dispatchToProps)(SingleNotificationView));
