const GET_HOME_PAGE = 'GET_HOME_PAGE';
const GET_RECOMMENDED_PROMPTS = 'GET_RECOMMENDED_PROMPTS';
const GET_TODOS = 'GET_TODOS';
const CLOSE_CARD = 'CLOSE_CARD';
const UPDATE_GOALS = 'UPDATE_GOALS';
const REFRESH_TODO = 'REFRESH_TODO';
const REFRESH_GOALS = 'REFRESH_GOALS';
const GET_GOALS = 'GET_GOALS';
const GET_GOALS_BY_CATEGORIES = 'GET_GOALS_BY_CATEGORIES';
const DELETE_GOAL = 'DELETE_GOAL';
const RESET_GOAL_TAB = 'RESET_GOAL_TAB';

export {
    GET_HOME_PAGE, GET_RECOMMENDED_PROMPTS, GET_TODOS, CLOSE_CARD, UPDATE_GOALS, REFRESH_TODO, REFRESH_GOALS, GET_GOALS,
    DELETE_GOAL, GET_GOALS_BY_CATEGORIES, RESET_GOAL_TAB,
};
