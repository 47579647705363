import { takeLatest, put, call } from 'redux-saga/effects';
import types from './types';
import { storeFaqs } from './actions';
import { apiFailed } from '../../../common/actions';
import { getFaq, getFaqFromToken } from './apis';
import { getOpenLinkTranslationsSaga } from '../../../common/sagas';
import Endpoints from '../../../Endpoints';

function* faqGenerator({ baseurl, email }) {
    try {
        const { faqEmail } = Endpoints;
        const url = baseurl.split(faqEmail)[0];
        yield getOpenLinkTranslationsSaga({ baseUrl: url, email });
        const { data } = yield call(getFaq, baseurl, email);
        yield put(storeFaqs(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

function* faqGeneratorUsingToken({ token }) {
    try {
        const { data } = yield call(getFaqFromToken, token);
        yield put(storeFaqs(data.data));
    } catch (e) {
        yield put(apiFailed([]));
    }
}

const faqSaga = [
    takeLatest(`${types.GET_FAQ}_PENDING`, faqGenerator),
    takeLatest(`${types.GET_FAQ_FROM_TOKEN}_PENDING`, faqGeneratorUsingToken),
];

export default faqSaga;
