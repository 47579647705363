import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
    postImage,
    getQuestionsAction,
    toggleShowSelectorModal,
    postAnswers,
    getVcrbData,
    getNotes,
    addNotes,
} from './actions';
import CreateProfileSaga from './saga';
import profileReducer from './reducer';
import ProfileTabs from './ProfileTabs';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { TERMS_CONDITIONS_LINK, isMobile } from '../../utils/constants';
import './index.scss';
import Loader from '../../components/Loader';
import rightCovering from '../UnAuthenticatedContent/assets/right_covering.png';
import InspireLoader from '../../components/InspireLoader/InspireLoader';
import LocalisationsStore from '../../common/LocalisationsStore';

const Profile = ({
    postImage,
    getQuestionsAction,
    questions,
    userDisplayPicture,
    isLoading,
    popUpOpen,
    toggleShowSelectorModal,
    postAnswers,
    getVcrbData,
    vcrbDetails,
    getNotes,
    addNotes,
    notes,
    status,
    history,
    clientExpired,
}) => {
    const [valid, setValid] = useState(true);
    const [checked, setChecked] = useState(isMobile);
    const [tabIndex, setTabIndex] = useState(0);

    let nameQuestion;
    let timeQuestion;

    if (questions && questions.length > 0) {
        [nameQuestion] = questions.filter(question => question.question_detail.type === 'text');
        [timeQuestion] = questions.filter(question => question.question_detail.type === 'objective');
    }

    const [nameQuestionResponse, setNameQuestionAns] = useState('');
    const [timeQuestionResponse, setTimeQuestionAns] = useState('');

    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || {
        WELCOME_HEADING: '',
        WELCOME_TEXT: '',
        termsCondition: '',
        TERM_CONDITIONS_TICK_TEXT: '',
        TERM_CONDITIONS_VALIDATION: '',
    };
    const {
        WELCOME_HEADING,
        WELCOME_TEXT,
        termsCondition,
        TERM_CONDITIONS_TICK_TEXT,
        TERM_CONDITIONS_VALIDATION,
    } = localisationsAfterLogin;

    const getQuestionsActionCall = useCallback(getQuestionsAction, []);

    useEffect(() => {
        getQuestionsActionCall(history);
    }, [getQuestionsActionCall, history]);

    if (status || localStorage.status === 'true') {
        return <Redirect to="/feeds" />;
    }

    const clickOverlay = val => {
        if (!checked) {
            if (!isMobile) setValid(val);
        } else {
            if (nameQuestion.question_detail.is_skip) {
                if (nameQuestionResponse.length > 0) {
                    postAnswers({
                        ...nameQuestion,
                        response: nameQuestionResponse,
                    });
                }
                setTabIndex(1);
            } else if (nameQuestionResponse.length > 0) {
                postAnswers({
                    ...nameQuestion,
                    response: nameQuestionResponse,
                });
                setTabIndex(1);
            }
            if (timeQuestionResponse.length > 0) {
                postAnswers({
                    ...timeQuestion,
                    response: timeQuestionResponse,
                });
            }
        }
    };

    const isChecked = e => {
        setChecked(e.target.checked);
        if (e.target.checked) {
            setValid(true);
        }
    };

    const getRightImage = (h, w) => (
        <Box className="rightImage_wrapper">
            <img src={rightCovering} alt="right_covering" height={h} width={w} />
        </Box>
    );
    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    }
    return (
        <Fragment>
            {localStorage.status === 'false' ? (
                <div className={`${!valid ? 'overlayWrapper' : ''} profileWrapper`}>
                    {clientExpired && moveToClientExpirePage() }
                    {!isMobile && (
                        <Grid container spacing={0} className={`${!valid ? 'overlayOpacity' : ''}`}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className="headerLogo">
                                    <Header />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={0} alignItems="flex-end">
                        <Grid item xs={12} sm={12} md={3} lg={3} className="tndSection">
                            <Box className={`${!valid ? 'overlayOpacity' : ''}`}>
                                <Box className="profileHeading">{WELCOME_HEADING}</Box>
                                <Box className="profileText">{WELCOME_TEXT}</Box>
                            </Box>
                            {tabIndex === 0 && (
                                <form>
                                    <Box className={`${!valid ? 'noOverlay' : ''} TermConditionContainer d-flex`}>
                                        <Box className="round">
                                            <Box className="circular-container">
                                                <label className="checkbox-label" htmlFor="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="checkbox"
                                                        id="checkbox"
                                                        onChange={v => isChecked(v)}
                                                        checked={checked}
                                                    />
                                                    <span className="checkbox-custom circular" />
                                                </label>
                                            </Box>
                                        </Box>

                                        <Box className="checkboxText">
                                            <Box className="checkboxLabel">{TERM_CONDITIONS_TICK_TEXT}</Box>
                                            <Box className="tnd">
                                                <a
                                                    href={TERMS_CONDITIONS_LINK}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {termsCondition}
                                                </a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                            {!valid && <Box className="tndValidation">{TERM_CONDITIONS_VALIDATION}</Box>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={6} className="profile_main_section">
                            {/* {isLoading && <Loader position="relative" background="transparent" showBackground />} */}
                            <Box className={`${!valid ? 'overlayOpacity' : ''} profileTabs`}>
                                {nameQuestion && timeQuestion ? (
                                    <ProfileTabs
                                        questions={questions}
                                        postImage={postImage}
                                        userDisplayPicture={userDisplayPicture}
                                        isLoading={isLoading}
                                        popUpOpen={popUpOpen}
                                        toggleShowSelectorModal={toggleShowSelectorModal}
                                        clickOverlay={clickOverlay}
                                        setNameQuestionAns={setNameQuestionAns}
                                        setTimeQuestionAns={setTimeQuestionAns}
                                        tabIndex={tabIndex}
                                        getVcrbData={getVcrbData}
                                        vcrbDetails={vcrbDetails}
                                        getNotes={getNotes}
                                        addNotes={addNotes}
                                        notes={notes}
                                    />
                                ) : (
                                    <Loader position="absolute" background="transparent" showBackground />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} className="profile_footer_section">
                            <Box>{getRightImage(96, 66)}</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box className={`${!valid ? 'overlayOpacity' : ''} footer-links`}>
                                <Footer />
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <InspireLoader isLoading />
            )}
        </Fragment>
    );
};

const stateToProps = state => ({
    questions: state.get('profileReducer').questions,
    userDisplayPicture: state.get('profileReducer').userDetails.profile_picture,
    isLoading: state.get('profileReducer').isLoading,
    popUpOpen: state.get('profileReducer').popUpOpen,
    vcrbDetails: state.get('profileReducer').vcrbDetails,
    notes: state.get('profileReducer').notes,
    status: state.get('authentication').get('status'),
    clientExpired: state.get('RefreshReducer').clientExpired,
});

const dispatchToProps = {
    postImage,
    getQuestionsAction,
    toggleShowSelectorModal,
    postAnswers,
    getVcrbData,
    getNotes,
    addNotes,
};

Profile.defaultProps = {
    userDisplayPicture: '',
    history: {},
};

Profile.propTypes = {
    postImage: PropTypes.func.isRequired,
    getQuestionsAction: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    userDisplayPicture: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    toggleShowSelectorModal: PropTypes.func.isRequired,
    postAnswers: PropTypes.func.isRequired,
    getVcrbData: PropTypes.func.isRequired,
    vcrbDetails: PropTypes.arrayOf({
        detail: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        inner_subheading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    getNotes: PropTypes.func.isRequired,
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    status: PropTypes.bool.isRequired,
    history: PropTypes.object,
    clientExpired: PropTypes.bool.isRequired,
};

export { CreateProfileSaga };
export { profileReducer };

export default withRouter(connect(stateToProps, dispatchToProps)(Profile));
