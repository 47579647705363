import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function ProgressBar({
    percentage,
    backgroundColor,
    barColor,
}) {
    const [completedPercentage, setCompletedPercentage] = useState(0);

    useEffect(() => {
        if (percentage) {
            setCompletedPercentage(percentage);
        }
    }, [percentage]);

    return (
        <div
            className="progress-bar-container"
            style={{ backgroundColor }}
        >
            <div
                className="progress-bar"
                style={{
                    width: `${completedPercentage}%`,
                    backgroundColor: barColor,
                }}
            />
        </div>
    );
}

export default ProgressBar;

ProgressBar.defaultProps = {
    percentage: 0,
    backgroundColor: null,
    barColor: null,
};

ProgressBar.propTypes = {
    percentage: PropTypes.number,
    backgroundColor: PropTypes.string,
    barColor: PropTypes.string,
};
