/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';
import { showCommentsKeyFeedsList } from '../../../utils/functions';

const storeThisFeedData = (state, data) => {
    const item = JSON.parse(JSON.stringify(state.thisFeedData));
    if (!item) return data;
    if (!data) return null;
    if (item.id !== data.id) return data;
    if (item.quiz) {
        if (item.quiz.question === 'no_quiz') {
            return data;
        }
        return data;
    }
    item.notes_list = data.notes_list;
    return item;
};

const INITIAL_STATE = {
    file: null,
    loading: false,
    tags: null,
    isLoading: false,
    feedsList: [],
    allFeedFetched: false,
    isFeedLoading: false,
    isLiked: false,
    uploadingPost: false,
    imageUploaderModal: false,
    thisFeedData: null,
    likedId: -1,
    isDeleted: false,
    savedPosts: [],
    savedPostId: -1,
    likeDetails: [],
    followedId: -1,
    isLikeLoading: false,
    userListOfType: [],
    articleComments: {},
    isDeleting: false,
    postQuizSuccess: false,
    showShareSuccess: false,
    followedUsers: [],
    userListForSharePost: [],
    allUsersFetched: false,
    isInitialFeedsLoading: false,
    earnedBannerBadge: null,
    showBadge: false,
    teamMembersList: [],
    filters: null,
    filterString: 'content_type=&chapter=',
    newerPostsCount: 0,
    badgeOfNewerPostsCount: null,
    badgeOfNewerPostsCountCoachingJourney: null,
    badgeOfNewerPostsCountSocialGroup: null,
    filtersAreLoading: false,
    stickyQuizResponse: null,
};

const feedsReducer = createReducer(INITIAL_STATE, {
    [types.UPDATE_SHOW_BADGE]: (state, { payload }) => ({
        ...state,
        showBadge: payload.showBadge,
    }),

    [types.GET_FEEDS_PENDING]: state => ({
        ...state,
        isInitialFeedsLoading: true,
        isLoading: true,
    }),

    [types.GET_FEEDS_SUCCESS]: (state, { payload }) => ({
        ...state,
        feedsList: payload,
        isInitialFeedsLoading: false,
        isLoading: false,
    }),
    [types.GET_FEEDS_FAILED]: state => ({
        ...state,
        isInitialFeedsLoading: false,
    }),
    [types.GET_FEEDS_MORE_PENDING]: state => ({
        ...state,
        isFeedLoading: true,
        allFeedFetched: false,
    }),
    [types.GET_FEEDS_MORE_SUCCESS]: (state, { payload }) => ({
        ...state,
        feedsList: [...state.feedsList, ...payload],
        isFeedLoading: false,
        allFeedFetched: false,
    }),
    [types.GET_FEEDS_MORE_FAILED]: state => ({
        ...state,
        isFeedLoading: false,
        allFeedFetched: true,
    }),
    [types.ADD_FILE_TO_LIST]: (state, { file }) => ({
        ...state,
        file,
    }),
    [`${types.GET_TAGS}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_TAGS}_DONE`]: (state, { tags }) => ({
        ...state,
        loading: false,
        tags,
    }),

    [types.POST_LIKE_PENDING]: state => ({
        ...state,
        isLoading: true,
        isLiked: false,
    }),

    [types.POST_LIKE_SUCCESS]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLiked: true,
        likedId: payload.payload.articleId,
    }),
    [types.POST_LIKE_FAILURE]: state => ({
        ...state,
        isLoading: false,
        isLiked: false,
    }),
    [`${types.UPLOAD_POST}_PENDING`]: state => ({
        ...state,
        uploadingPost: true,
        isLoading: true,
    }),
    [`${types.UPLOAD_POST}_DONE`]: state => ({
        ...state,
        uploadingPost: false,
        isLoading: false,
    }),
    [types.TOGGLE_CREATE_POST_MODAL]: (state, { value }) => ({
        ...state,
        imageUploaderModal: value,
    }),
    [`${types.GET_THIS_FEED}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_THIS_FEED}_DONE`]: (state, { data }) => ({
        ...state,
        thisFeedData: storeThisFeedData(state, data),
        isLoading: false,
    }),
    [types.DELETE_POST_PENDING]: state => ({
        ...state,
        isDeleting: true,
        isDeleted: false,
    }),

    [types.DELETE_POST_SUCCESS]: (state, payload) => ({
        ...state,
        isDeleting: false,
        isDeleted: true,
        feedsList: state.feedsList.filter(item => item.id !== payload.payload.articleId),
    }),
    [types.DELETE_POST_FAILURE]: state => ({
        ...state,
        isDeleting: false,
        isDeleted: false,
    }),
    [types.GET_SAVED_POSTS_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),

    [types.GET_SAVED_POSTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        savedPosts: payload.data,
        isLoading: false,
    }),
    [types.GET_SAVED_POSTS_FAILED]: state => ({
        ...state,
        isLoading: false,
    }),

    [types.POST_SAVED_POSTS_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),

    [types.POST_SAVED_POSTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        savedPostId: payload.articleId,
        savedPosts: [payload.data.data[0], ...state.savedPosts],
    }),
    [types.POST_SAVED_POSTS_FAILURE]: state => ({
        ...state,
        isLoading: false,
    }),

    [types.GET_LIKE_DETAILS_PENDING]: state => ({
        ...state,
        isLoading: true,
        isLikeLoading: true,
    }),

    [types.GET_LIKE_DETAILS_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        likeDetails: payload.data,
        isLikeLoading: false,
    }),
    [types.GET_LIKE_DETAILS_FAILURE]: state => ({
        ...state,
        isLoading: false,
        isLikeLoading: false,
    }),

    [types.POST_FOLLOW_USER_PENDING]: state => ({
        ...state,
        isLoading: true,
    }),

    [types.POST_FOLLOW_USER_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        followedId: payload.id,
    }),
    [types.POST_FOLLOW_USER_FAILURE]: state => ({
        ...state,
        isLoading: false,
    }),
    [`${types.GET_USERS_OF_TYPE}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_USERS_OF_TYPE}_DONE`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        userListOfType: data.data,
    }),
    [`${types.GET_ALL_COMMENTS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_ALL_COMMENTS}_DONE`]: (state, { data, articleId }) => ({
        ...state,
        isLoading: false,
        articleComments: {
            ...state.articleComments,
            [articleId]: data,
        },
        feedsList: showCommentsKeyFeedsList({
            feedId: articleId,
            feedList: state.feedsList,
        }),
    }),
    [`${types.POST_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.POST_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.LIKE_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.DELETE_COMMENT}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.LIKE_COMMENT}_DONE`]: state => ({
        ...state,
        isLoading: false,
    }),
    [types.UPDATE_FEEDS]: (state, { articleId, operation, replycount }) => ({
        ...state,
        feedsList: state.feedsList.map(item => {
            if (item.id === articleId) {
                switch (operation) {
                    case '+':
                        item.comment_count += 1;
                        break;
                    case '-': {
                        if (replycount > 0) item.comment_count = item.comment_count - 1 - replycount;
                        else item.comment_count -= 1;
                        break;
                    }
                    default:
                        break;
                }
                item.showComments = true;
            }
            return item;
        }),
    }),
    [`${types.POST_QUIZ_ANSWER}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        postQuizSuccess: false,
        showShareSuccess: false,
    }),
    [`${types.POST_QUIZ_ANSWER}_SUCCESS`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        postQuizSuccess: true,
        showShareSuccess: data.status === 201,
    }),
    [`${types.POST_QUIZ_ANSWER}_DONE`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        stickyQuizResponse: data,
    }),
    [types.RESET_QUIZ_DATA]: state => ({
        ...state,
        isLoading: false,
        postQuizSuccess: false,
        showShareSuccess: false,
    }),
    [`${types.REPORT_POST}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        reportPostSuccess: false,
    }),
    [`${types.REPORT_POST}_SUCCESS`]: state => ({
        ...state,
        isLoading: false,
        reportPostSuccess: true,
    }),
    [`${types.REPORT_POST}_FAILED`]: state => ({
        ...state,
        isLoading: false,
        reportPostSuccess: false,
    }),
    [types.RESET_ALL_FEED_FETCHED]: state => ({
        ...state,
        allFeedFetched: false,
    }),
    [`${types.GET_USERS_FOLLOWED}_SUCCESS`]: (state, { data }) => ({
        ...state,
        followedUsers: data.data,
    }),
    [types.SET_FOLLOW_UNFOLLOW_FEEDS]: (state, { userId, isFollow }) => {
        const newFeeds = state.feedsList.map(feedItem => {
            if (feedItem.user === userId) {
                feedItem.is_following = isFollow;
            }
            return feedItem;
        });
        return {
            ...state,
            feedsList: newFeeds,
        };
    },
    [`${types.FETCH_USERS_LIST}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        userListForSharePost: [],
        allUsersFetched: false,
    }),
    [`${types.FETCH_USERS_LIST}_SUCCESS`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        userListForSharePost: data.data,
    }),
    [`${types.FETCH_USERS_LIST}_FAILED`]: state => ({
        ...state,
        isLoading: false,
    }),
    [`${types.FETCH_MORE_USERS_LIST}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.FETCH_MORE_USERS_LIST}_SUCCESS`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        userListForSharePost: [...state.userListForSharePost, ...data.data],
        allUsersFetched: data.data.length === 0,
    }),
    [`${types.FETCH_MORE_USERS_LIST}_FAILED`]: state => ({
        ...state,
        isLoading: false,
        allUsersFetched: true,
    }),
    [types.SET_THIS_FEED]: (state, { id }) => ({
        ...state,
        thisFeedData: state.feedsList.filter(f => f.id === id)[0],
    }),
    [types.STORE_BANNERS]: (state, { banners }) => {
        const newFeedList = [...state.feedsList];
        banners
            .map(banner => {
                banner.file_type = 'banner';
                return banner;
            })
            .sort((a, b) => a.index_to_display_on - b.index_to_display_on)
            .forEach(banner => {
                newFeedList.splice(banner.index_to_display_on, 0, banner);
            });
        return {
            ...state,
            feedsList: newFeedList,
        };
    },
    [types.EARNED_BANNER_BADGE_STORE]: (state, { data }) => ({
        ...state,
        earnedBannerBadge: data,
    }),
    [`${types.GET_TEAM_MEMBERS}_SUCCESS`]: (state, { data }) => ({
        ...state,
        teamMembersList: data,
    }),
    [`${types.GET_FEED_FILTERS}_PENDING`]: state => ({
        ...state,
        filtersAreLoading: true,
    }),
    [`${types.GET_FEED_FILTERS}_DONE`]: (state, { data }) => ({
        ...state,
        filtersAreLoading: false,
        filters: data,
    }),
    [types.SET_FILTER_STRING]: (state, { filterString }) => ({
        ...state,
        filterString,
    }),
    [`${types.GET_NEW_POSTS_BLIP}_DONE`]: (state, { data }) => ({
        ...state,
        newerPostsCount: data,
    }),
    [`${types.STORE_NO_OF_NEW_POStS_COUNT}_HOME`]: (state, { data }) => ({
        ...state,
        badgeOfNewerPostsCount: Number(data),
    }),
    [`${types.STORE_NO_OF_NEW_POStS_COUNT}_COACHING_JORNEY`]: (state, { data }) => ({
        ...state,
        badgeOfNewerPostsCountCoachingJourney: Number(data),
    }),
    [`${types.STORE_NO_OF_NEW_POStS_COUNT}_SOCIAL_GROUP`]: (state, { data }) => ({
        ...state,
        badgeOfNewerPostsCountSocialGroup: Number(data),
    }),
    [types.REMOVE_FEED_LIST_FROM_LOCAL_STATE]: (state, { id }) => ({
        ...state,
        feedsList: state.feedsList.map(post => {
            if (post.id === Number(id)) {
                post.is_new = false;
            }
            return post;
        }),
    }),
    [`${types.SET_ARTICLE_INDEX_TIME}_START`]: (state, { startTime, feeditemid }) => ({
        ...state,
        feedsList: state.feedsList.map(post => {
            if (post.id === Number(feeditemid)) {
                post.startTime = startTime;
            }
            return post;
        }),
    }),
    [`${types.SET_ARTICLE_INDEX_TIME}_END`]: (state, { endTime, feeditemid }) => ({
        ...state,
        feedsList: state.feedsList.map(post => {
            if (post.id === Number(feeditemid)) {
                post.endTime = endTime;
            }
            return post;
        }),
    }),
});

export default feedsReducer;
