import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import LocalisationsStore from '../../../../common/LocalisationsStore';

const Quiz = ({
    quiz,
    callBack,
    callBackData,
    freeze,
    updateParent,
}) => {
    const {
        question,
        max_selection,
        quizoption_set,
        id,
        last_answer,
    } = quiz;
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [quizOptions, setQuizOptions] = useState(JSON.parse(JSON.stringify(quizoption_set)));
    const { localisationsAfterLogin } = LocalisationsStore;
    const { quiz_mandatory } = localisationsAfterLogin;


    useEffect(() => {
        setQuizOptions(JSON.parse(JSON.stringify(quizoption_set)));
        const responseIdList = last_answer ? last_answer.split(',') : [];
        setSelectedOptions(responseIdList.map(str => Number(str)));

        const _obj = {
            ...callBackData,
            quiz_response: {
                ...callBackData.quiz_response,
                [id]: {
                    response: quiz.last_answer,
                    quiz_id: quiz.id,
                },
            },
        };
        callBack(_obj);
        updateParent(_obj);
        // eslint-disable-next-line
    }, [quiz]);

    const clickOption = (item, index) => {
        if (max_selection === 1) {
            setSelectedOptions([item.id]);
            const newOptions = quizOptions.map(elem => {
                if (elem.id === item.id) {
                    return {
                        ...elem,
                        isSelected: true,
                    };
                }
                return {
                    ...elem,
                    isSelected: false,
                };
            });
            callBack({
                ...callBackData,
                quiz_response: {
                    ...callBackData.quiz_response,
                    [id]: {
                        response: `${item.id}`,
                        quiz_id: quiz.id,
                    },
                },
            });
            setQuizOptions(newOptions);
        } else {
            const newOptions = [...quizOptions];
            if (selectedOptions.includes(item.id)) {
                newOptions[index].isSelected = false;
                // item remove id
                const newList = selectedOptions.filter(id => id !== item.id);
                setSelectedOptions(newList);
            } else {
                // if (selectedOptions.length === max_selection) return
                newOptions[index].isSelected = true;
                setSelectedOptions([...selectedOptions, item.id]);
            }
            const response = newOptions.filter(elem => elem.isSelected)
                .map(elem => elem.id);
            callBack({
                ...callBackData,
                quiz_response: {
                    ...callBackData.quiz_response,
                    [id]: {
                        response: response.join(','),
                        quiz_id: quiz.id,
                    },
                },
            });
            setQuizOptions(newOptions);
        }
    };

    return (
        <div className="quiz">
            <div className="title">{question}</div>
            <div className={`${freeze ? 'freeze' : ' '} `}>
                {quizOptions.map((item, index) => (
                    <button
                        className={`quiz-option ${selectedOptions.includes(item.id) ? 'active' : ''}`}
                        onClick={() => clickOption(item, index)}
                        key={item?.id}
                        type="button"
                    >
                        {item.text}
                    </button>
                ))}
            </div>
            <div className="mandatory">{quiz_mandatory}</div>
        </div>
    );
};

export default Quiz;

Quiz.propTypes = {
    quiz: PropTypes.shape({
        id: PropTypes.number,
        max_selection: PropTypes.number,
        question: PropTypes.string,
        last_answer: PropTypes.string,
        quizoption_set: PropTypes.array,
    }).isRequired,
    callBackData: PropTypes.shape({
        quiz_response: PropTypes.shape({}),
    }).isRequired,
    callBack: PropTypes.func.isRequired,
    updateParent: PropTypes.func.isRequired,
    freeze: PropTypes.bool.isRequired,
};
