import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.png';
import './index.scss';
import { extractJsonFromUrl, getArticleInfo } from '../UnAuthenticatedContent/apis';
import Loader from '../../components/Loader';
import EmailCard from '../../components/EmailCard';
import EmailFooter from '../../components/EmailFooter';
import Lottie from '../../components/Lottie';
import Text from '../../components/Text';
import { fontColors, fontFamilies } from '../../common/fontMap';

const MarkAsComplete = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [articleDetails, setArticleDetails] = useState({ localization: {} });
    const [protaganim, setProtagAnimData] = useState(null);

    useEffect(() => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const baseUrl = url.searchParams.get('base_url');
        const tagId = url.searchParams.get('tag_id');
        const cta = url.searchParams.get('cta');
        setIsLoading(true);
        getArticleInfo({ baseUrl, tagId, cta })
            .then(response => {
                setIsLoading(false);
                const { data, localization } = response.data.data;
                setArticleDetails({
                    practice_name: data.practice_name,
                    article_title: data.article_title,
                    protag_title: data.protag_title,
                    completed_percentage: data.completed_percentage,
                    localization,
                });
                extractJsonFromUrl(data.protag_anim).then(response => {
                    setProtagAnimData(response.data);
                });
            })
            .catch(() => {
                setIsLoading(false);
                setArticleDetails({ localization: {} });
                // history.push('/404-not-found');
            });
    }, []);

    const {
        practices,
        exploreMorePractices,
        goToInspireWeb,
        complete,
        wellDone,
        inspireWebLink,
        appStoreLink,
        playStoreLink,
    } = articleDetails.localization;
    const { practice_name, article_title, completed_percentage } = articleDetails;
    return (
        <div className="mark-as-complete">
            {isLoading ? (
                <Loader position="absolute" showBackground />
            ) : (
                <div className="header">
                    <div className="logo">
                        <img src={logo} alt="Inspire" />
                    </div>
                    <div className="content">
                        <div className="label">{wellDone}</div>
                        <div className="page-details">
                            <div className="image">
                                <div className="mark-as-complete-animation">
                                    <Lottie
                                        className="outer_animation_container"
                                        borderRadius={20}
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: protaganim,
                                        }}
                                    />
                                    <div className="percentage">
                                        <Text
                                            fontsize="3.25rem"
                                            fontcolor={fontColors.textBlack}
                                            fontfamily={fontFamilies.playfairBold}
                                        >
                                            {completed_percentage}
                                        </Text>
                                        <Text
                                            fontsize="1.25rem"
                                            fontcolor={fontColors.textBlack}
                                            fontfamily={fontFamilies.playfairBold}
                                            letterSpacing="-0.2px"
                                            margin="0 0 0.5rem 0.5rem"
                                        >
                                            %
                                        </Text>
                                        <Text
                                            font-size="0.75rem"
                                            letterSpacing="0.75px"
                                            fontcolor={fontColors.textBlack}
                                            fontfamily={fontFamilies.TheinhardtPanRegular}
                                            margin="2.2rem 0 0 0"
                                        >
                                            {complete}
                                        </Text>
                                    </div>
                                </div>
                            </div>

                            <div className="practice-detail">
                                <EmailCard
                                    title={practice_name}
                                    subtitle={article_title}
                                    isComplete
                                    complete={complete}
                                />
                            </div>
                            {articleDetails.localization.is_hide_footer && articleDetails.localization.is_hide_footer === "true" ? (
                                <div />
                            ) : (
                                <div className="footer">
                                    <EmailFooter
                                    heading={practices}
                                    subHeading={exploreMorePractices}
                                    link={goToInspireWeb}
                                    inspireWebLink={inspireWebLink}
                                    appStoreLink={appStoreLink}
                                    playStoreLink={playStoreLink}
                                />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarkAsComplete;

MarkAsComplete.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
