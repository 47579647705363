import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../../common/LocalisationsStore';
import './index.scss';
import CommentsAndReply from './CommentsAndReply';

export default function CommentsForArticle({
    articleComments,
    likeThisComment,
    deleteComment,
    articleindex,
    replyCommentProps,
    setReplyProps,
    getlikedBy,
    postComment,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [_shownArticles, setShownArticles] = useState(articleComments.filter((article, index) => index < 3));

    const _thisUser = Number(localStorage.id);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { viewAll } = localisationsAfterLogin;

    useEffect(() => {
        const { length } = articleComments;
        if (length > 3) {
            setShownArticles(articleComments.filter((article, index) => index < 3));
        } else {
            setShownArticles(articleComments);
        }
    }, [articleComments]);

    const handleClick = event => {
        setAnchorEl(Number(event.currentTarget.dataset.commid));
        setTimeout(() => {
            // setAnchorEl(null);
        }, 3000);
    };

    const handleClose = e => {
        const { currentTarget: { dataset: { commid, articleid, replycount } } } = e;
        deleteComment(commid, articleid, Number(replycount));
        setAnchorEl(null);
    };

    const viewMore = () => {
        setShownArticles(articleComments);
    };

    const showReplies = event => {
        const { currentTarget: { dataset: { commentindex } } } = event;
        const copy = [..._shownArticles];
        // eslint-disable-next-line no-param-reassign
        copy[commentindex].repliesOpen = true;
        setShownArticles(copy);
    };

    const replyToThisComment = event => {
        const { currentTarget: { dataset: { parentcommentid, articleid } } } = event;
        // setFocus(articleindex);
        setReplyProps({
            ...replyCommentProps,
            parentcommentid,
            articleid,
        });
    };

    if (!_shownArticles.length) {
        return null;
    }

    return (
        <div className="all_comments_list_wrapper">
            {_shownArticles.map((comment, index) => (
                <CommentsAndReply
                    comment={comment}
                    index={index}
                    showReplies={showReplies}
                    handleClose={handleClose}
                    handleClick={handleClick}
                    likeThisComment={likeThisComment}
                    _thisUser={_thisUser}
                    anchorEl={anchorEl}
                    replyToThisComment={replyToThisComment}
                    parentCommentId={comment.parent_comment}
                    articleindex={articleindex}
                    isParent
                    getlikedBy={getlikedBy}
                    postComment={postComment}
                    commenColor="commenColor"
                />
            ))}
            {_shownArticles.length !== articleComments.length && (
                <div role="none" className="view_more_comments" onClick={viewMore}>
                    <span>{viewAll}</span>
                    <span>
                        {_shownArticles.length}
                        of
                        {articleComments.length}
                    </span>
                </div>
            )}
        </div>
    );
}

CommentsForArticle.defaultProps = {
    articleComments: [],
};

CommentsForArticle.propTypes = {
    articleComments: PropTypes.array,
    likeThisComment: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    articleindex: PropTypes.number.isRequired,
    replyCommentProps: PropTypes.shape({
        parentcommentid: PropTypes.string.isRequired,
        articleid: PropTypes.string.isRequired,
    }).isRequired,
    setReplyProps: PropTypes.func.isRequired,
    getlikedBy: PropTypes.func.isRequired,
};
