import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import loaderGifWhite from '../../assets/mck_white.gif';
import loaderGifBlue from '../../assets/mck_3.gif';

const Button = styled.button`
    ${({
        fontSize,
        fontFamily,
        fontColor,
        bgColor,
        width,
        disabled,
        padding,
        border,
        height,
        margin,
    }) => `
        font-size: ${fontSize};
        font-family: ${fontFamily};
        color: ${fontColor};
        background-color: ${bgColor}
        cursor: pointer;
        width: ${width || '100%'};
        border: ${border || 'none'};
        box-sizing: border-box;
        border-radius: 1.875rem;
        opacity: ${disabled ? 0.5 : 1};
        height: ${height || '54px'};
        padding: ${padding || '0'};
        margin: ${margin};
        :hover {
            opacity: 0.8;
        }
    `}
`;

export default function Actionbutton({
    children,
    disabled,
    onClick,
    fontSize,
    fontFamily,
    fontColor,
    bgColor,
    width,
    padding,
    border,
    height,
    margin,
    btnLoading = false,
    btnWhite = false,
}) {
    return (
        btnLoading
            ? (
                <div style={{
                    width: '16rem',
                    backgroundColor: btnWhite ? '#ffffff' : '#1F40E6',
                    boxSizing: 'border-box',
                    borderRadius: '1.875rem',
                    height: '54px',
                    textAlign: 'center',
                    borderColor: '#1F40E6',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                }}
                >
                    <img
                        style={{
                            margin: 'auto',
                            height: '54px',
                            width: 'auto',
                        }}
                        src={btnWhite ? loaderGifBlue : loaderGifWhite}
                        alt="loader"
                    />
                </div>
            )
            : (
                <Button
                    onClick={onClick}
                    fontSize={fontSize}
                    fontFamily={fontFamily}
                    fontColor={fontColor}
                    bgColor={bgColor}
                    disabled={disabled}
                    width={width}
                    padding={padding}
                    border={border}
                    height={height}
                    margin={margin}
                >
                    {children}
                </Button>
            )
    );
}

Actionbutton.defaultProps = {
    margin: '',
    padding: null,
    border: null,
    height: null,
    btnLoading: false,
    btnWhite: false,
};

Actionbutton.propTypes = {
    onClick: PropTypes.func.isRequired,
    fontSize: PropTypes.string.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    width: PropTypes.string.isRequired,
    padding: PropTypes.string,
    border: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
    btnLoading: PropTypes.bool,
    btnWhite: PropTypes.bool,
};
