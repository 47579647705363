import { takeLatest, put } from 'redux-saga/effects';
import types from '../../Feeds/redux/types';
import { apiFailed } from '../../../common/actions';
import { storeFeedFilters } from './actions';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getFeedFiltersApi } from './apis';

function* getFeedFiltersSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getFeedFiltersApi,
            payload: [AuthStore.accessToken],
        });
        yield put(storeFeedFilters(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const FeedFiltersSaga = [takeLatest(`${types.GET_FEED_FILTERS}_PENDING`, getFeedFiltersSaga)];

export default FeedFiltersSaga;
