import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from '../../components/Text';
import ActionProgressCard from './ActionProgressCard';
import JourneyProgressCard from './JourneyProgressCard';
import LocalisationsStore from '../../common/LocalisationsStore';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';

function UserMatrix({
    getUserMatrix,
    resetUserMatrix,
    matrixData,
    handleSeeMoreClick,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { progress_snapshot } = localisationsAfterLogin;

    useEffect(() => {
        getUserMatrix();

        return (() => {
            resetUserMatrix();
        });
    }, [getUserMatrix, resetUserMatrix]);

    if (!matrixData || !Object.keys(matrixData).length) return null;

    return (
        <div className="user-matrix-container">
            <div className="title-container">
                <div className="title">{progress_snapshot}</div>
                <div className="see-more-cta" onClick={handleSeeMoreClick}>See more</div>
            </div>
            {matrixData.todaymessage ? (
                <Text
                    fontsize="13px"
                    fontcolor={fontColors.black200}
                    fontfamily={fontFamilies.MckinseySansRegular}
                    fontweight={400}
                    lineHeight="16px"
                    margin="5px 0 0 0"
                >
                    {matrixData.todaymessage}
                </Text>
            ) : null}
            <div className="user-matrix-card-container">
                <JourneyProgressCard cardDetails={matrixData.journeyprogress} />
                <ActionProgressCard cardDetails={matrixData.actioncompleted} />
            </div>
        </div>
    );
}

export default UserMatrix;

UserMatrix.defaultProps = {
};

UserMatrix.propTypes = {
    matrixData: PropTypes.shape({
        todaymessage: PropTypes.string,
        actioncompleted: PropTypes.shape({}),
        journeyprogress: PropTypes.shape({}),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    getUserMatrix: PropTypes.func.isRequired,
    resetUserMatrix: PropTypes.func.isRequired,
    handleSeeMoreClick: PropTypes.func.isRequired,
};
