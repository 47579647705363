import React, { useState, useEffect, Fragment } from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import streakBadge from '../assets/streakBadge.svg';
import { dissmissStreakPopupApi } from '../redux/apis';
import { streakDissmissPress } from '../../UnAuthenticatedContent/actions';

function StreakBadgeReceived({ streakPopup, streakDissmissPress }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (streakPopup && streakPopup.hasUserEarnedStreak) {
            setOpen(streakPopup.hasUserEarnedStreak);
        }
    }, [streakPopup]);

    const closeModall = () => {
        streakDissmissPress();
        dissmissStreakPopupApi({ id: streakPopup.id });
        setOpen(false);
    };

    return (
        <Fragment>
            {streakPopup && streakPopup.hasUserEarnedStreak && (
                <Modal open={open} className="earned_badge_outer">
                    <div className="earned_badge_inner">
                        <div
                            className="close_badge_popup"
                            onClick={closeModall}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                        >
                            <Cross color="black" />
                        </div>
                        <div className="badge_Contauner">
                            <div className="received_badge_wrapper">
                                <img src={streakBadge} alt="received_badge" />
                                <span>{streakPopup.streak}</span>
                            </div>
                        </div>
                        <div className="badge_heading_and_text">
                            <div>{streakPopup.heading}</div>
                            <div>{streakPopup.detail}</div>
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    );
}

const stateToProps = state => {
    const authenTicationReducer = state.get('authentication');
    return {
        streakPopup:
            authenTicationReducer.get('streakPopup') != null ? authenTicationReducer.get('streakPopup').toJS() : {},
        loading: authenTicationReducer.loading,
    };
};

const dispatchToProps = {
    streakDissmissPress,
};

StreakBadgeReceived.defaultProps = {
    streakPopup: {
        hasUserEarnedStreak: false,
        streak: '0',
        heading: '',
        detail: '',
        id: 0,
    },
};

StreakBadgeReceived.propTypes = {
    streakPopup: PropTypes.shape({
        hasUserEarnedStreak: PropTypes.bool,
        streak: PropTypes.string,
        heading: PropTypes.string,
        detail: PropTypes.string,
        id: PropTypes.number,
    }),
    streakDissmissPress: PropTypes.func.isRequired,
};

export default connect(stateToProps, dispatchToProps)(StreakBadgeReceived);
