import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import Lottie from '../../components/Lottie';
import WarningModal from '../../components/WarningModal';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import './protag.scss';
import Actionbutton from '../../components/ActionButton';
import { updateProtag } from './redux/apis';
import CtaArrow from '../../components/CtaArrow';
import LocalisationsStore from '../../common/LocalisationsStore';
import { nullable, useMobileCheck } from '../../utils/functions';

export default function ProtagAnimation({ handleCrossClick, practiceDetailsData, protaganim, storeProTagViewed }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { shareYourProgress, done, complete } = localisationsAfterLogin;
    const history = useHistory();
    const hideHubTab = localStorage.getItem('is_hide_social_post') === 'true';
    const isMobile = useMobileCheck();

    const changeProtagViewwedStatus = () => {
        updateProtag(practiceDetailsData.protag_id).then(() => {
            handleCrossClick();
            storeProTagViewed();
        });
    };

    const closeCallback = () => {
        updateProtag(practiceDetailsData.protag_id).then(() => {
            handleCrossClick();
            storeProTagViewed();
        });
    };

    const handleShareClick = e => {
        e.stopPropagation();
        const capturedCanvas = document.getElementById('protag_animation_wrapper');

        domtoimage.toPng(capturedCanvas).then(dataUrl => {
            localStorage.setItem('canvasImage', dataUrl);

            handleCrossClick();
            history.push('/app/hub');
        });

        // html2canvas(capturedCanvas, {
        //     scrollX: 0,
        //     scrollY: -window.scrollY
        // }).then(canvas => {
        //     localStorage.setItem(
        //         'canvasImage',
        //         canvas.toDataURL('image/png')
        //     );

        //     console.log(canvas.toDataURL('image/png'))

        //     //.replace('image/png', 'image/octet-stream'),

        //     // uploadPost(
        //     //     {
        //     //         file: localStorage.canvasImage,
        //     //         fileType: 'image',
        //     //         desc: '',
        //     //         content: '',
        //     //         categoryTags: [],
        //     //         practiceTags: [],
        //     //         users_list: [],
        //     //         social_config: {
        //     //             fontFamily: '',
        //     //             colors: [],
        //     //             index: '',
        //     //             fontSize: 18.0,
        //     //         },
        //     //         type: 'image',
        //     //     },
        //     //     '',
        //     //     '',
        //     // );
        //     // handleCrossClick();
        //     // history.push(`/app/hub`);

        // });
    };

    return (
        <WarningModal classNames="protag_animation_warning_modal" isFullScreen={isMobile} hasTopSpacing={isMobile}>
            <div className="protag_animation_wrapper">
                <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none pointer">
                    <Cross color="black" />
                </div>
                <div id="protag_animation_wrapper" className="p_wrapper">
                    <Text
                        fontsize="1.125rem"
                        fontcolor={fontColors.siteGrey}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        margin="0 0 1rem 0"
                    >
                        {practiceDetailsData.protag_title}
                    </Text>
                    <div className="animation_wrapper">
                        <Lottie
                            className="outer_animation_container"
                            borderRadius={20}
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: protaganim,
                            }}
                        />
                    </div>
                    <div className="percenntage">
                        <Text
                            fontsize="3.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                        >
                            {practiceDetailsData.completed_percentage}
                        </Text>
                        <Text
                            fontsize="1.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            letterSpacing="-0.2px"
                            margin="0 0 0.5rem 0.5rem"
                        >
                            %
                        </Text>
                        <Text
                            font-size="0.75rem"
                            letterSpacing="0.75px"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                            margin="2.2rem 0 0 1.4rem"
                        >
                            {complete}
                        </Text>
                    </div>
                </div>
                <Text
                    fontsize="18px"
                    fontcolor={fontColors.textBlack}
                    fontfamily={fontFamilies.TheinhardtPanLight}
                    margin="0 0 2rem 0"
                    textAlign="center"
                >
                    {practiceDetailsData.protag_subtitle}
                </Text>
                <div className="protag-button">
                    <Actionbutton
                        fontSize="18px"
                        fontFamily={fontFamilies.TheinhardtPanLight}
                        fontColor={fontColors.siteWhite}
                        bgColor={fontColors.electricBlue}
                        disabled={false}
                        onClick={changeProtagViewwedStatus}
                        width="20rem"
                        padding="10px 0"
                    >
                        {done}
                    </Actionbutton>
                </div>
                {!hideHubTab ? (
                    <div className="shareTHought" onClick={handleShareClick}>
                        <Text
                            fontsize="16px"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanLight}
                            margin="0 0.5rem 0 0"
                            cursor="pointer"
                        >
                            {shareYourProgress}
                        </Text>
                        <CtaArrow color={fontColors.textBlack} />
                    </div>
                ) : null}
            </div>
        </WarningModal>
    );
}

const Article = {
    created_date: PropTypes.string,
    id: PropTypes.number,
    practice_sequence: PropTypes.number,
    show_type: PropTypes.string,
    state: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
};

ProtagAnimation.propTypes = {
    practiceDetailsData: PropTypes.shape({
        articles: PropTypes.arrayOf(PropTypes.shape(Article)),
        bg_color: PropTypes.string,
        completed_percentage: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        practice_image: PropTypes.string,
        progress_img: PropTypes.string,
        protag: null,
        protag_anim: PropTypes.string,
        protag_id: null,
        protag_img: PropTypes.string,
        protag_subtitle: PropTypes.string,
        protag_title: PropTypes.string,
        protag_viewed: PropTypes.bool,
    }).isRequired,
    handleCrossClick: PropTypes.func.isRequired,
    protaganim: nullable(PropTypes.object).isRequired,
    storeProTagViewed: PropTypes.func.isRequired,
};
