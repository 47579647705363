import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/Text';
import Loader from '../../../components/Loader';
import UserJourneyProgress from './UserJourneyProgess';
import UserActionsProgress from './UserActionsProgress';
import ActionDetailsTab from './ActionDetailsTab';
import PromptArticle from '../../PromptArticle/index.container';
import AddNewPost from '../../Feeds/AddNewPost';
import backArrow from '../../../assets/backArrow.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { fontFamilies, fontColors } from '../../../common/fontMap';
import './index.scss';

const ProgressSnapshot = ({
    getTags,
    getUserMatrix,
    hasBackButton,
    history,
    loading,
    matrixData,
    onBackClick,
    resetUserMatrix,
    showLoader,
    tags,
    uploadingPost,
    uploadPost,
    shouldRetainData,
}) => {
    const [showPrompt, setShowPrompt] = useState({
        show: false,
    });
    const [activePromptId, setActivePromptId] = useState(null);
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [hashtag, setHashTag] = useState('');

    const { localisationsAfterLogin } = LocalisationsStore;
    const { welcome_back, progress_snapshot } = localisationsAfterLogin;

    const name = localStorage.getItem('full_name') || 'User';
    const greetingsText = `${welcome_back}, ${name}`;

    useEffect(() => {
        getUserMatrix();

        return (() => {
            if (!shouldRetainData) {
                resetUserMatrix();
            }
        });
    }, [getUserMatrix, resetUserMatrix, shouldRetainData]);

    useEffect(() => {
        getTags(history);
    }, [getTags, history]);

    const handleCloseCreatePost = () => {
        setShowCreatePost(false);
    };

    const handleClosePromptArticle = ({ show }) => {
        getUserMatrix();
        setActivePromptId(null);
        setShowPrompt({ show });
    };

    const handleOpenPromptArticle = id => {
        setActivePromptId(id);
        setShowPrompt({ show: true });
    };

    return (
        <>
            {(loading && showLoader) && <Loader position="fixed" showBackground />}
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={activePromptId}
                    setShowCreatePost={setShowCreatePost}
                />
            )}
            {showCreatePost && (
                <AddNewPost
                    handleClose={handleCloseCreatePost}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || { practice: [], category: [] }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
            <div className="progress-snapshot-container">
                <div className="progress-header-container">
                    <div className="progress-title-container">
                        {hasBackButton ? (
                            <div className="back-icon" onClick={onBackClick}>
                                <img src={backArrow} alt="back-icon" />
                            </div>
                        ) : null}
                        <Text
                            fontfamily={fontFamilies.playfairBold}
                            fontcolor={fontColors.black}
                            fontsize="28px"
                            lineHeight="32px"
                            fontweight={700}
                            letterSpacing="-0.2px"
                        >
                            {progress_snapshot}
                        </Text>
                    </div>
                    <Text
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontcolor={fontColors.black}
                        fontsize="16px"
                        lineHeight="24px"
                        fontweight={400}
                        letterSpacing="-0.2px"
                        whiteSpace="pre-wrap"
                    >
                        {greetingsText}
                    </Text>
                    {matrixData?.todaymessage ? (
                        <Text
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontcolor={fontColors.black200}
                            fontsize="14px"
                            lineHeight="16px"
                            fontweight={400}
                            margin="5px 0 0 0"
                        >
                            {matrixData?.todaymessage}
                        </Text>
                    ) : null}
                    <div className="divider" />
                </div>
                <UserJourneyProgress data={matrixData?.journeyprogress} />
                <UserActionsProgress data={matrixData?.actioncompleted} />
                <ActionDetailsTab onClickCard={handleOpenPromptArticle} actionList={matrixData?.action_list} />
            </div>
        </>
    );
};

ProgressSnapshot.defaultProps = {
    hasBackButton: false,
    loading: false,
    onBackClick: () => {},
    showLoader: true,
    tags: null,
    shouldRetainData: false,
};

ProgressSnapshot.propTypes = {
    getTags: PropTypes.func.isRequired,
    getUserMatrix: PropTypes.func.isRequired,
    hasBackButton: PropTypes.bool,
    history: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
    matrixData: PropTypes.shape({
        todaymessage: PropTypes.string,
        actioncompleted: PropTypes.shape({}),
        journeyprogress: PropTypes.shape({}),
        action_list: PropTypes.shape({}),
    }).isRequired,
    onBackClick: PropTypes.func,
    resetUserMatrix: PropTypes.func.isRequired,
    showLoader: PropTypes.bool,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    uploadingPost: PropTypes.bool.isRequired,
    uploadPost: PropTypes.func.isRequired,
    shouldRetainData: PropTypes.bool,
};

export default ProgressSnapshot;
