import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from '../../components/Lottie';
import Posts from '../Feeds/Posts';
import { validURL } from '../../utils/functions';
import CommentsForArticle from '../Feeds/CommentsForArticle';
import Loader from '../../components/Loader';
import TagUser from '../../components/TagUser';
import LocalisationsStore from '../../common/LocalisationsStore';
import LikeDetailModal from '../Feeds/LikeDetailModal';
import GRADIENTS from '../Feeds/constants';
import TextPostDescription from '../../components/TextPostDescription';
import TextPostGradientBox from '../../components/TextPostGradientBox';

const FeedsList = ({
    feedsList,
    postLike,
    likedId,
    isLoading,
    showInterventionPage,
    isDeleted,
    deletePost,
    likeDetails,
    followedId,
    getLikeDetails,
    postFollowUser,
    showCommentsForThisArticle,
    articleComments,
    postComment,
    likeComment,
    deleteComment,
    clickedArticle,
    isDeleting,
    reportPost,
    postUnfollowUser,
    getUsersFollowed,
    followedUsers,
    setFollowUnfollowInFeeds,
    selectedKey,
    history,
}) => {
    const [commentValue, setCommentValue] = useState('');
    const [articleId, setArticleId] = useState(null);
    const [selectedUsers, setSelecteduser] = useState([]);
    const [replyCommentProps, setReplyProps] = useState({
        parentcommentid: '',
        articleid: '',
    });
    const [getLikes, setGetLikes] = useState(false);
    const [likeDetailsWithFollowed, setLikeDetailsWithFollowed] = useState([]);

    const inputRefs = [];

    const { localisationsAfterLogin } = LocalisationsStore;
    const { addAComment } = localisationsAfterLogin;

    useEffect(() => {
        const likeDetailsWithFollowed = likeDetails.map(user => ({
            ...user,
            is_following: followedUsers.some(followedUser => followedUser.following_to === user.liked_by),
        }));
        setLikeDetailsWithFollowed(likeDetailsWithFollowed);
    }, [followedUsers, likeDetails]);

    const handleClose = () => {
        setGetLikes(false);
    };

    const handleChange = (value, itemId) => {
        setCommentValue(value);
        setArticleId(Number(itemId));
    };

    const handleClick = e => {
        const { currentTarget: { dataset: { itemid } } } = e;

        const parrentid = Number(replyCommentProps.parentcommentid);
        const articleid = Number(replyCommentProps.articleid);

        if (replyCommentProps.parentcommentid) {
            postComment(articleid, commentValue, selectedUsers, parrentid);
            setReplyProps({
                parentcommentid: '',
                articleid: '',
            });
        } else {
            postComment(itemid, commentValue, selectedUsers);
        }
        setCommentValue('');

        inputRefs.forEach(ed => {
            if (ed.editor) {
                ed.createEmptyState();
            }
        });
    };

    function showTransitionAnimation(fileSrc, showInterventionPage, clickedArticle, id) {
        if (showInterventionPage && clickedArticle === id) {
            return fileSrc.TRANSITION;
        }
        return fileSrc.CLOSED;
    }

    const urlify = text => {
        // eslint-disable-next-line no-useless-escape
        const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const stringList = text.split(' ');
        return stringList
            .map(element => element.replace(urlRegex, url => `<a href="http://${url}">${url}</a>`))
            .join(' ');
    };

    const handleUrlClick = e => {
        e.preventDefault();
        if (e.target.href) {
            window.open(`${e.target.href}`, '_blank');
        }
    };

    const handlePostClick = e => {
        const { currentTarget: { dataset: { feeditemtype, feeditemid } } } = e;
        if (feeditemtype === 'article') {
            history.push(`/feed-details-article/${feeditemid}`);
        }
    };

    const renderMedia = ({ fileType, fileSrc, videoUrl, id, description, config, type }) => {
        switch (fileType.toUpperCase()) {
            case 'IMAGE':
            case 'TEXT':
                if (config) {
                    return (
                        <div className="feed_text_post_container">
                            <TextPostGradientBox gradient={GRADIENTS[config.index].value}>
                                <TextPostDescription
                                    fontFamily={config.fontFamily}
                                    dangerouslySetInnerHTML={{ __html: urlify(description) }}
                                    onClick={handleUrlClick}
                                />
                            </TextPostGradientBox>
                        </div>
                    );
                }
                return (
                    <div
                        className="feed_image_post_container"
                        onClick={handlePostClick}
                        data-feeditemtype={type}
                        data-feeditemid={id}
                    >
                        <img src={fileSrc} alt="alt" className="postImage" />
                    </div>
                );
            case 'IMAGES':
                return (
                    <div className="feed_image_post_container">
                        {!validURL(fileSrc) ? (
                            <Lottie
                                className="outer_animation_container"
                                borderRadius={20}
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: showTransitionAnimation(
                                        fileSrc,
                                        showInterventionPage,
                                        clickedArticle,
                                        id,
                                    ),
                                }}
                            />
                        ) : (
                            <img src={fileSrc} alt="alt" className="postImage" />
                        )}
                    </div>
                );
            case 'VIDEO': {
                return (
                    <div>
                        <video src={videoUrl} controls muted playsInline className="video">
                            <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                        </video>
                    </div>
                );
            }
            case 'AUDIO':
                return (
                    <div>
                        <audio src={fileSrc} className="illustration">
                            <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                        </audio>
                    </div>
                );
            default:
                return <img src={fileSrc} alt="alt" className="illustration" />;
        }
    };

    const likeThisComment = e => {
        const { currentTarget: { dataset: { commid, articleid } } } = e;
        likeComment(commid, articleid);
    };

    const getlikedBy = e => {
        const { currentTarget: { dataset: { commentid } } } = e;
        getLikeDetails({ commentId: commentid });
        setGetLikes(true);
        getUsersFollowed();
    };

    const setRef = ref => {
        inputRefs.push(ref);
    };

    const setFocus = articleindex => {
        inputRefs[Number(articleindex)].editor.focus();
    };

    return (
        <div className="feedsListWrapper">
            {getLikes && (
                <LikeDetailModal
                    open={getLikes}
                    handleClose={handleClose}
                    likeDetails={likeDetailsWithFollowed}
                    isLoading={isLoading}
                    followedId={followedId}
                    postFollowUser={postFollowUser}
                    postUnfollowUser={postUnfollowUser}
                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                />
            )}
            <div className="feedsPnl">
                {feedsList
                    && feedsList.map((item, index) => (
                        <div className="feedCard" key={item.id}>
                            <div className="feedsCardScaling">
                                <Posts
                                    feedItem={item}
                                    renderMedia={renderMedia}
                                    isLiked={item.is_liked}
                                    likedId={likedId}
                                    postLike={postLike}
                                    isLoading={isLoading}
                                    isDeleted={isDeleted}
                                    deletePost={deletePost}
                                    likeDetails={likeDetails}
                                    followedId={followedId}
                                    getLikeDetails={getLikeDetails}
                                    postFollowUser={postFollowUser}
                                    showCommentsForThisArticle={showCommentsForThisArticle}
                                    articleComments={articleComments}
                                    isDeleting={isDeleting}
                                    reportPost={reportPost}
                                    postUnfollowUser={postUnfollowUser}
                                    getUsersFollowed={getUsersFollowed}
                                    followedUsers={followedUsers}
                                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                                    feedKey={`${item.id}-feeditem-social-card`}
                                    selectedKey={selectedKey}
                                />
                                <div className="commentWrapper">
                                    {isLoading && (
                                        <Loader position="relative" background="transparent" showBackground />
                                    )}
                                    <div className="commentWrapper_first_el">
                                        <TagUser
                                            onChange={handleChange}
                                            placeholder={addAComment}
                                            userAddMentions={setSelecteduser}
                                            value={articleId === item.id ? commentValue : ''}
                                            itemid={item.id}
                                            className={`commentSection outline-none ${commentValue.length === 0
                                                && 'full-width'}`}
                                            ref={setRef}
                                        />
                                        {commentValue.length > 0 && (
                                            <span
                                                data-itemid={item.id}
                                                role="none"
                                                onClick={handleClick}
                                                className="post_comment_btn"
                                            >
                                                POST
                                            </span>
                                        )}
                                    </div>
                                    {item.showComments && (
                                        <CommentsForArticle
                                            articleComments={articleComments}
                                            likeThisComment={likeThisComment}
                                            deleteComment={deleteComment}
                                            setFocus={setFocus}
                                            articleindex={index}
                                            replyCommentProps={replyCommentProps}
                                            setReplyProps={setReplyProps}
                                            getlikedBy={getlikedBy}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

FeedsList.defaultProps = {
    articleComments: [],
    clickedArticle: null,
    history: {},
};

FeedsList.propTypes = {
    feedsList: PropTypes.array.isRequired,
    postLike: PropTypes.func.isRequired,
    likedId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showInterventionPage: PropTypes.bool.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    deletePost: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedId: PropTypes.number.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    articleComments: PropTypes.array,
    showCommentsForThisArticle: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    clickedArticle: PropTypes.number,
    isDeleting: PropTypes.bool.isRequired,
    reportPost: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    selectedKey: PropTypes.number.isRequired,
    history: PropTypes.object,
};
export default FeedsList;
