import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

export default function MobileImageCropper({ src, setCanvas }) {
    const imageElement = React.createRef();

    useEffect(() => {
        const cropper = new Cropper(imageElement.current, {
            zoomable: false,
            scalable: false,
            aspectRatio: 1,
            crop: () => {
                const canvas = cropper.getCroppedCanvas();
                setCanvas(canvas);
            },
        });
    }, [imageElement, setCanvas]);

    return (
        <div className="mobile-crop-container">
            <div className="mobile-img-container">
                <img ref={imageElement} src={src} alt="Source" crossOrigin />
            </div>
        </div>
    );
}

MobileImageCropper.propTypes = {
    src: PropTypes.string.isRequired,
    setCanvas: PropTypes.func.isRequired,
};
