import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ERROR_400_MSG, ERROR_404_BUTTON } from '../../utils/constants';
import Logo404 from './assets/404.svg';
import './style.scss';

const LinkAdapter = forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

function Page404() {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" className="container404">
            <Grid container spacing={3} direction="column" justify="center" alignItems="center">
                <Grid item>
                    <img src={Logo404} alt="404_image" />
                </Grid>
                <Grid item className="text404">
                    {ERROR_400_MSG}
                </Grid>
                <Grid item>
                    <Button variant="contained" component={LinkAdapter} to="/">
                        {ERROR_404_BUTTON}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Page404;
