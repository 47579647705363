/* eslint-disable no-param-reassign, no-plusplus  */
import createReducer from '../../../utils/redux-utils/createReducer';
import * as types from './types';

const INITIAL_STATE = {
    isLoading: false,
    practicesInProgress: null,
    practicesCompleted: null,
    practicesNew: null,
    browseMoreCompleted: false,
    inPrgLoading: false,
};

const PracticesReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_PRACTICES_IN_PROGRESS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        inPrgLoading: true,
    }),
    [`${types.GET_PRACTICES_INPROGRESS_NEW}_PENDING`]: state => ({
        ...state,
        inPrgLoading: true,
    }),
    [`${types.BROWSE_ALL}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_PRACTICES_IN_PROGRESS}_DONE`]: (state, { data }) => {
        const moreData = data.detail.data || [];
        const hasMoreData = moreData.length >= 5;
        return {
            ...state,
            practicesInProgress: {
                ...data.detail,
                hasMoreData,
            },
            isLoading: false,
            inPrgLoading: false,
        };
    },
    [`${types.GET_PRACTICES_IN_PROGRESS}_EMPTY`]: state => ({
        ...state,
        practicesInProgress: null,
        isLoading: true,
    }),

    [`${types.GET_PRACTICES_COMPLETED}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),

    [`${types.GET_PRACTICES_COMPLETED}_BROWSE_MORE`]: (state, { value }) => ({
        ...state,
        browseMoreCompleted: value,
    }),

    [`${types.GET_PRACTICES_COMPLETED}_DONE`]: (state, { data }) => {
        const moreData = data.detail.data || [];
        const hasMoreData = moreData.length >= 5;
        if (state.browseMoreCompleted && state.practicesCompleted && state.practicesCompleted.data) {
            const idMap = state.practicesCompleted.data.map(elem => elem.id);

            for (let i = 0; i < moreData.length; i++) {
                if (!idMap.includes(moreData[i].id)) {
                    state.practicesCompleted.data.push(moreData[i]);
                } else {
                    const index = state.practicesCompleted.data.findIndex(elem => elem.id === moreData[i].id);
                    state.practicesCompleted.data[index] = moreData[i];
                }
            }
            return {
                ...state,
                practicesCompleted: {
                    data: state.practicesCompleted.data,
                    hasMoreData,
                },
                isLoading: false,
            };
        }
        return {
            ...state,
            practicesCompleted: {
                ...data.detail,
                hasMoreData,
            },
            isLoading: false,
        };
    },
    [`${types.GET_PRACTICES_COMPLETED}_EMPTY`]: state => ({
        ...state,
        practicesCompleted: null,
        isLoading: true,
    }),
    [`${types.BROWSE_ALL}_DONE`]: (state, { data }) => {
        const moreData = data.detail.data || [];
        const hasMoreData = moreData.length >= 5;
        if (state.practicesInProgress && state.practicesInProgress.data) {
            const idMap = state.practicesInProgress.data.map(elem => elem.id);

            for (let i = 0; i < moreData.length; i++) {
                if (!idMap.includes(moreData[i].id)) {
                    state.practicesInProgress.data.push(moreData[i]);
                } else {
                    const index = state.practicesInProgress.data.findIndex(elem => elem.id === moreData[i].id);
                    state.practicesInProgress.data[index] = moreData[i];
                }
            }

            return {
                ...state,
                practicesInProgress: {
                    data: state.practicesInProgress.data,
                    hasMoreData,
                },
                isLoading: false,
            };
        }
        return {
            ...state,
            practicesInProgress: {
                ...data.detail,
                hasMoreData,
            },
            isLoading: false,
        };
    },

    [`${types.GET_PRACTICES_NEW}_PENDING`]: state => ({
        ...state,
    }),
    [`${types.GET_PRACTICES_NEW}_DONE`]: (state, { data, page }) => {
        const moreData = data.detail.data || [];
        const hasMoreData = moreData.length >= 5;
        if (page !== 1 && state.practicesNew && state.practicesNew.data) {
            const idMap = state.practicesNew.data.map(elem => elem.id);

            for (let i = 0; i < moreData.length; i++) {
                if (!idMap.includes(moreData[i].id)) {
                    state.practicesNew.data.push(moreData[i]);
                } else {
                    const index = state.practicesNew.data.findIndex(elem => elem.id === moreData[i].id);
                    state.practicesNew.data[index] = moreData[i];
                }
            }
            return {
                ...state,
                practicesNew: {
                    completed: false,
                    data: state.practicesNew.data,
                    inprogress: true,
                    hasMoreData,
                },
                isLoading: false,
            };
        }
        return {
            ...state,
            practicesNew: { ...data.detail, hasMoreData },
            isLoading: false,
        };
    },
    [`${types.GET_PRACTICES_NEW}_EMPTY`]: state => ({
        ...state,
        practicesNew: null,
        isLoading: true,
    }),
    [types.CLOSE_LOADER]: state => ({
        ...state,
        isLoading: false,
    }),
});

export default PracticesReducer;
