import React, { useState, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import AddNewPost from '../Feeds/AddNewPost';
import FeedsList from './FeedsList';
import FeedHeader from '../Feeds/FeedHeader';
import SkeletonLoader from '../Feeds/SkeletonLoader';
import MyProgress from '../MyProgress';
import PreferencesModal from '../PreferencesModal';

export default function Feeds({
    feedsList,
    isLoading,
    getFeedsList,
    uploadPost,
    getTags,
    tags,
    postLike,
    isLiked,
    uploadingPost,
    imageUploaderModal,
    toggleCreatePostModal,
    likedId,
    history,
    isDeleted,
    deletePost,
    likeDetails,
    followedId,
    getLikeDetails,
    postFollowUser,
    isLikeLoading,
    getAllComments,
    articleComments,
    postComment,
    likeComment,
    deleteComment,
    isDeleting,
    reportPost,
    postUnfollowUser,
    getUsersFollowed,
    followedUsers,
    setFollowUnfollowInFeeds,
    fetchUsersList,
    userListForSharePost,
    sharePost,
    fetchMoreUsersList,
    allUsersFetched,
    isInitialFeedsLoading,
    match,
    logoutUser,
    getUserProgressData,
    getUserData,
    emptyNotificationList,
    resetPageNumber,
    storeTeamMembersList,
}) {
    // eslint-disable-next-line no-unused-vars
    const [showInterventionPage, setShowInterventionPage] = useState(false);
    const [showInterventionTransitionAnimation, runAmination] = useState(false);
    const [clickedArticle, setArticleId] = useState(null);
    const [selectedHashTag, setSelectedHashTag] = useState('');
    const [page, setPage] = useState(2);
    // eslint-disable-next-line no-unused-vars
    const [goalScreen, setGoalScreen] = useState('');
    const [showPreferences, setShowPreferencesModal] = useState(false);
    const [selectedKey, setSelectedKey] = useState(Number(1));
    const getTagsCall = useCallback(getTags, []);

    const { params: { id } } = match;

    useEffect(() => {
        getUserData();
        getTagsCall(history);
    }, [getTagsCall, history, getUserData]);

    const showInterventionDetailPage = (val, feedId) => {
        runAmination(true);
        setArticleId(feedId);
        setTimeout(() => {
            setShowInterventionPage(val);
            history.push(`/feed-details-article/${feedId}`);
        }, 1000);
    };

    const showCommentsForThisArticle = articleId => {
        getAllComments(articleId);
    };

    const handleClickOnHashTag = e => {
        const { currentTarget: { dataset: { tag } } } = e;
        setSelectedHashTag(tag);
    };

    function handleOpen() {
        toggleCreatePostModal(true);
    }

    const _renderTabsContent = () => (
        <Fragment>
            <div className="feedCol">
                <FeedsList
                    history={history}
                    isDeleting={isDeleting}
                    isLoading={isLoading}
                    feedsList={feedsList}
                    postLike={postLike}
                    isLiked={isLiked}
                    likedId={likedId}
                    showInterventionPage={showInterventionTransitionAnimation}
                    setShowInterventionPage={showInterventionDetailPage}
                    isDeleted={isDeleted}
                    deletePost={deletePost}
                    likeDetails={likeDetails}
                    followedId={followedId}
                    getLikeDetails={getLikeDetails}
                    postFollowUser={postFollowUser}
                    isLikeLoading={isLikeLoading}
                    showCommentsForThisArticle={showCommentsForThisArticle}
                    articleComments={articleComments}
                    postComment={postComment}
                    likeComment={likeComment}
                    deleteComment={deleteComment}
                    clickedArticle={clickedArticle}
                    reportPost={reportPost}
                    handleClickOnHashTag={handleClickOnHashTag}
                    selectedHashTag={selectedHashTag}
                    page={page}
                    setPage={setPage}
                    postUnfollowUser={postUnfollowUser}
                    getUsersFollowed={getUsersFollowed}
                    followedUsers={followedUsers}
                    setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                    fetchUsersList={fetchUsersList}
                    userListForSharePost={userListForSharePost}
                    sharePost={sharePost}
                    fetchMoreUsersList={fetchMoreUsersList}
                    allUsersFetched={allUsersFetched}
                    selectedKey={selectedKey}
                    handleOpen={handleOpen}
                    setSelectedKey={setSelectedKey}
                />
            </div>
        </Fragment>
    );

    function handleClose() {
        toggleCreatePostModal(false);
    }

    const getFeedsListCall = useCallback(getFeedsList, []);

    useEffect(() => {
        getFeedsListCall(id);
    }, [getFeedsListCall, id]);

    return (
        <div className="feedsWrapper yourFeeds">
            <FeedHeader
                handleOpen={handleOpen}
                history={history}
                logoutUser={logoutUser}
                getUserProgressData={getUserProgressData}
                setShowPreferencesModal={setShowPreferencesModal}
                emptyNotificationList={emptyNotificationList}
                resetPageNumber={resetPageNumber}
                storeTeamMembersList={storeTeamMembersList}
            />
            <AddNewPost
                handleClose={handleClose}
                imageUploaderModal={imageUploaderModal}
                uploadPost={uploadPost}
                tags={tags}
                uploadingPost={uploadingPost}
                history={history}
            />
            <MyProgress />
            <PreferencesModal showPreferences={showPreferences} setShowPreferencesModal={setShowPreferencesModal} />
            {isInitialFeedsLoading ? (
                <SkeletonLoader />
            ) : (
                <div className="flex-grid-wrapper">
                    <div className="col2">{_renderTabsContent()}</div>
                </div>
            )}
        </div>
    );
}

Feeds.defaultProps = {
    tags: {
        practice: [],
        category: [],
    },
    history: {},
    articleComments: [],
};

Feeds.propTypes = {
    feedsList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getFeedsList: PropTypes.func.isRequired,
    uploadPost: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    postLike: PropTypes.func.isRequired,
    isLiked: PropTypes.bool.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    imageUploaderModal: PropTypes.bool.isRequired,
    toggleCreatePostModal: PropTypes.func.isRequired,
    likedId: PropTypes.number.isRequired,
    history: PropTypes.object,
    isDeleted: PropTypes.bool.isRequired,
    deletePost: PropTypes.func.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedId: PropTypes.number.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    isLikeLoading: PropTypes.bool.isRequired,
    articleComments: PropTypes.array,
    getAllComments: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    reportPost: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    fetchUsersList: PropTypes.func.isRequired,
    userListForSharePost: PropTypes.array.isRequired,
    sharePost: PropTypes.func.isRequired,
    fetchMoreUsersList: PropTypes.func.isRequired,
    allUsersFetched: PropTypes.bool.isRequired,
    isInitialFeedsLoading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        isExact: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    logoutUser: PropTypes.func.isRequired,
    getUserProgressData: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
    emptyNotificationList: PropTypes.func.isRequired,
    resetPageNumber: PropTypes.func.isRequired,
    storeTeamMembersList: PropTypes.func.isRequired,
};
