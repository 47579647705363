import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import GoalHeading from './GoalHeading';
import LocalisationsStore from '../../../common/LocalisationsStore';

const Goals = ({ getVcrbData, getGoalType, vcrbDetails }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { performance, health, organization, individual } = localisationsAfterLogin;

    let valueGoal;
    let cultureGoal;
    let roleeGoal;
    let behaviourGoal;

    if (vcrbDetails.length > 0) {
        valueGoal = vcrbDetails.find(goal => goal.type === 'value');
        cultureGoal = vcrbDetails.find(goal => goal.type === 'culture');
        roleeGoal = vcrbDetails.find(goal => goal.type === 'role');
        behaviourGoal = vcrbDetails.find(goal => goal.type === 'behaviour');
    }

    const getVcrbDataCall = useCallback(getVcrbData, []);

    useEffect(() => {
        getVcrbDataCall();
    }, [getVcrbDataCall]);

    const getGoals = type => {
        getGoalType(type);
    };
    return (
        <div className="vcrb">
            {vcrbDetails && vcrbDetails.length > 0 && (
                <div className="vcrb_inner">
                    <div className="two_roles_row">
                        <div
                            className="section valRoleSection valCulSection"
                            onClick={() => getGoals('value')}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                        >
                            <div className="rotated rotatedText organization">{organization}</div>
                            <div className="right_wrapper">
                                <div className="rotatedText performance">{performance}</div>
                                <div className="value roleValue">
                                    <GoalHeading
                                        type="value"
                                        heading={valueGoal.heading}
                                        subHeading={valueGoal.subheading}
                                        innerSubheading={valueGoal.inner_subheading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="section valCulSection"
                            onClick={() => getGoals('culture')}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={-1}
                        >
                            <div className="rotatedText health">{health}</div>
                            <div className="culture">
                                <GoalHeading
                                    type="culture"
                                    heading={cultureGoal.heading}
                                    subHeading={cultureGoal.subheading}
                                    innerSubheading={cultureGoal.inner_subheading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="two_roles_row">
                        <div
                            className="section valRoleSection third"
                            onClick={() => getGoals('role')}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={-2}
                        >
                            <div className="rotated rotatedText individual">{individual}</div>
                            <div className="role roleValue">
                                <GoalHeading
                                    type="role"
                                    heading={roleeGoal.heading}
                                    subHeading={roleeGoal.subheading}
                                    innerSubheading={roleeGoal.inner_subheading}
                                />
                            </div>
                        </div>
                        <div
                            className="section "
                            onClick={() => getGoals('behaviour')}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={-3}
                        >
                            <div className="behaviour">
                                <GoalHeading
                                    type="behaviour"
                                    heading={behaviourGoal.heading}
                                    subHeading={behaviourGoal.subheading}
                                    innerSubheading={behaviourGoal.inner_subheading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

Goals.propTypes = {
    getVcrbData: PropTypes.func.isRequired,
    getGoalType: PropTypes.func.isRequired,
    vcrbDetails: PropTypes.arrayOf({
        detail: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        inner_subheading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default Goals;
