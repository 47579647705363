import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function TextWithTags(props) {
    const { text, config } = props;

    const HeaderText = styled.div`
        font-size: ${`${config.font_size}px` || '16px'};
        color: ${config.color};
        text-transform: ${config.all_caps ? 'uppercase' : ''};
        font-family: ${config.font_family};
        font-weight: ${config.font_weight};
        white-space:pre-wrap;
    `;
    return (
        <HeaderText>{text}</HeaderText>
    );
}

TextWithTags.propTypes = {
    config: PropTypes.shape({
        font_size: PropTypes.number,
        color: PropTypes.string,
        all_caps: PropTypes.bool,
        font_family: PropTypes.string,
        font_weight: PropTypes.string,
    }).isRequired,
    text: PropTypes.string.isRequired,

};
export default TextWithTags;
