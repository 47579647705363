/* eslint-disable no-console */
import React, { Fragment, Component } from 'react';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './history';

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
    render() {
        return (
            <Router component={Fragment} history={history} basename={process.env.REACT_APP_BASENAME}>
                <Routes />
            </Router>
        );
    }
}

export default App;
