/* eslint-disable prefer-destructuring */
import LocalisationsStore from '../../common/LocalisationsStore';
import Action_taker from './assets/Action_taker.png';
import Super_action_taker from './assets/Super_action_taker.svg';
import Mega_action_taker from './assets/Mega_action_taker.svg';
import Star from '../../assets/Star.svg';
import Heart from '../../assets/Heart.svg';
import Diamond from '../../assets/Diamond.svg';
import Multiple_badges from './assets/Multiple_badges.svg';
import Multiple_badges2 from './assets/multiple2.svg';
import streakBadge from './assets/streakBadge.svg';

const GRADIENTS = [
    {
        name: 'gradient1',
        value: 'linear-gradient(125deg, #ff6cab -32%, #1f3fe3 100%, #7366ff 116%)',
        colorOrder: ['ff6cab', '1f3fe3', '7366ff'],
    },
    {
        name: 'gradient3',
        value: 'linear-gradient(122deg, #00d4e2 -15%, #1889e8 58%, #214fed 114%)',
        colorOrder: ['00d4e2', '1889e8', '214fed'],
    },
    {
        name: 'gradient4',
        value: 'linear-gradient(122deg, #fd8991 -15%, #bc80cf 58%, #8d73fc 114%)',
        colorOrder: ['fd8991', 'bc80cf', '8d73fc'],
    },
    {
        name: 'gradient6',
        value: 'linear-gradient(125deg, #ff3b4c -9%, #d3217b 32%, #7366ff 107%)',
        colorOrder: ['ff3b4c', 'd3217b', '7366ff'],
    },
    {
        name: 'gradient5',
        value: 'linear-gradient(122deg, #f00b51 -15%, #7366ff 114%);',
        colorOrder: ['f00b51', '7366ff'],
    },
    {
        name: 'gradient7',
        value: 'linear-gradient(122deg, #ff9897 -15%, #f650a0 114%)',
        colorOrder: ['ff9897', 'f650a0'],
    },
    {
        name: 'gradient2',
        value: 'linear-gradient(124deg, #ff5900 -1%, #f8f11e 24%, #26ae00 50%, #0029b0 80%, #6c00a5 105%)',
        colorOrder: ['ff5900', 'f8f11e', '26ae00', '0029b0', '6c00a5'],
    },
];

export default GRADIENTS;

export const ERRORS = {};

export const BANNERS_LINKS_MAPPING = {
    '/createPost': 'CREATE POST',
    '#': 'External link',
    '/VCRB': 'Set Goals',
};

const { localisationsAfterLogin } = LocalisationsStore;
let actionTacker;
let yayyHeadingForAction1;
let yayyContentForAction1;
let supperActionTacker;
let yayyHeadingForAction2;
let yayyContentForAction2;
let meghaActionTacker;
let yayyHeadingForAction3;
let yayyContentForAction3;
// let star;
let yayyHeadingForSocial1;
let yayyContentForSocial1;
// let heart;
let yayyHeadingForSocial2;
let yayyContentForSocial2;
let diamond;
let yayyHeadingForSocial3;
let yayyContentForSocial3;
let multiple;
let multiple2;
let streak;
let streakContent;
let influencer;
let champion;

if (LocalisationsStore.localisationsAfterLogin) {
    actionTacker = localisationsAfterLogin.actionTacker;
    yayyHeadingForAction1 = localisationsAfterLogin.yayyHeadingForAction1;
    yayyContentForAction1 = localisationsAfterLogin.yayyContentForAction1;
    supperActionTacker = localisationsAfterLogin.supperActionTacker;
    yayyHeadingForAction2 = localisationsAfterLogin.yayyHeadingForAction2;
    yayyContentForAction2 = localisationsAfterLogin.yayyContentForAction2;
    meghaActionTacker = localisationsAfterLogin.meghaActionTacker;
    yayyHeadingForAction3 = localisationsAfterLogin.yayyHeadingForAction3;
    yayyContentForAction3 = localisationsAfterLogin.yayyContentForAction3;
    // star = localisationsAfterLogin.star;
    yayyHeadingForSocial1 = localisationsAfterLogin.yayyHeadingForSocial1;
    yayyContentForSocial1 = localisationsAfterLogin.yayyContentForSocial1;
    // heart = localisationsAfterLogin.heart;
    yayyHeadingForSocial2 = localisationsAfterLogin.yayyHeadingForSocial2;
    yayyContentForSocial2 = localisationsAfterLogin.yayyContentForSocial2;
    diamond = localisationsAfterLogin.diamond;
    yayyHeadingForSocial3 = localisationsAfterLogin.yayyHeadingForSocial3;
    yayyContentForSocial3 = localisationsAfterLogin.yayyContentForSocial3;
    multiple = localisationsAfterLogin.multiple;
    multiple2 = localisationsAfterLogin.multiple2;
    streak = localisationsAfterLogin.streak;
    streakContent = localisationsAfterLogin.streakContent;
    influencer = localisationsAfterLogin.influencer;
    champion = localisationsAfterLogin.champion;
}

export const Badges = [
    {
        badgeId: 1,
        badgeName: actionTacker,
        badgeImage: Action_taker,
        type: 'action',
        title: yayyHeadingForAction1,
        content: yayyContentForAction1,
    },
    {
        badgeId: 2,
        badgeName: supperActionTacker,
        badgeImage: Super_action_taker,
        type: 'action',
        title: yayyHeadingForAction2,
        content: yayyContentForAction2,
    },
    {
        badgeId: 3,
        badgeName: meghaActionTacker,
        badgeImage: Mega_action_taker,
        type: 'action',
        title: yayyHeadingForAction3,
        content: yayyContentForAction3,
    },
    {
        badgeId: 1,
        badgeName: influencer,
        badgeImage: Star,
        type: 'social',
        title: yayyHeadingForSocial1,
        content: yayyContentForSocial1,
    },
    {
        badgeId: 2,
        badgeName: champion,
        badgeImage: Heart,
        type: 'social',
        title: yayyHeadingForSocial2,
        content: yayyContentForSocial2,
    },
    {
        badgeId: 3,
        badgeName: diamond,
        badgeImage: Diamond,
        type: 'social',
        title: yayyHeadingForSocial3,
        content: yayyContentForSocial3,
    },
    {
        badgeId: 99,
        badgeName: streak,
        badgeImage: streakBadge,
        type: 'streak',
        title: actionTacker,
        content: streakContent,
    },
    {
        badgeId: 100,
        badgeName: multiple,
        badgeImage: Multiple_badges,
        type: 'multiple',
        title: actionTacker,
        content: actionTacker,
    },
    {
        badgeId: 98,
        badgeName: multiple2,
        badgeImage: Multiple_badges2,
        type: 'multiple2',
        title: actionTacker,
        content: actionTacker,
    },
];
