import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';
import GreyClock from '../../assets/greyClock.svg';
import BlueClock from '../../assets/blueClock.svg';

export default function TodoCard({ index, card, backgroundColor, isHidden, onClick }) {
    return (
        <div
            className={`todo_card_container ${isHidden ? 'hidden' : ''}`}
            key={`todo-card-${index}`}
            data-id={card.id}
            onClick={onClick}
            style={{ backgroundColor }}
        >
            <div className="left_panel">
                <div className="view_practice_wrapper">
                    {card.practice_name && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Text
                                wordBreak="break-word"
                                fontsize=".75rem"
                                fontcolor={fontColors.siteGrey}
                                fontfamily={fontFamilies.MckinseySansMedium}
                                fontweight="500"
                                margin="0 0 0.5rem 0"
                                width="90%"
                                letterSpacing="0.75px"
                                textTransform="uppercase"
                                cursor="pointer"
                            >
                                {card.practice_name}
                            </Text>
                            {card.is_notification_active ? <img src={BlueClock} alt="TODO" /> : <img src={GreyClock} alt="TODO" />}
                        </div>
                    )}
                    <div className="checkmark_completed_on">
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontweight="400"
                            cursor="pointer"
                            margin="5px 0 0 0"
                        >
                            {card.article_title}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
}

TodoCard.defaultProps = {
    backgroundColor: '',
    isHidden: false,
};

TodoCard.propTypes = {
    index: PropTypes.number.isRequired,
    card: PropTypes.object.isRequired,
    backgroundColor: PropTypes.string,
    isHidden: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
