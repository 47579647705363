import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, makeStyles } from '@material-ui/core';
import './index.scss';

const WarningModal = props => {
    const { children, classNames, isClientPG, isFullScreen, hasTopSpacing } = props;
    const useStyles = makeStyles(_ => {
        const appbarHeight = 64;
        return {
            root: { top: `${appbarHeight}px !important` },
        };
    });
    const classes = useStyles();

    return (
        <Dialog
            open
            disableEscapeKeyDown
            maxWidth="md"
            className={isClientPG || hasTopSpacing ? classes.root : classNames}
            classes={{
                root: 'warningDialog',
            }}
            fullScreen={!!isClientPG || isFullScreen}
        >
            {children}
        </Dialog>
    );
};

WarningModal.defaultProps = {
    classNames: '',
    isClientPG: false,
    isFullScreen: false,
    hasTopSpacing: false,
};

WarningModal.propTypes = {
    classNames: PropTypes.string,
    isClientPG: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    hasTopSpacing: PropTypes.bool,
};

export default WarningModal;
