import React from 'react';

const SkeletonLoader = () => (
    <div className="skeleton_wrapper">
        <div className="column1">
            <div className="col1_container">
                <div className="col1_skeleton1 loading" />
                <div className="col1_skeleton2 loading" />
            </div>
        </div>
        <div className="column2">
            <div className="col2_feed_container">
                <div className="feed_header">
                    <div className="profile_container">
                        <div className="profile_skeleton loading" />
                        <div className="profile_name_skeleton loading" />
                    </div>
                    <div className="profile_skeleton2 loading" />
                </div>
                <div className="post_skeleton loading" />
                <div className="feed_bottom_container">
                    <div className="feed_bottom_1 loading" />
                    <div className="separator" />
                    <div className="feed_bottom_1 loading" />
                </div>
                <div className="feed_bottom_skeleton1 loading" />
                <div className="feed_bottom_skeleton2 loading" />
            </div>
        </div>
        <div className="column3">
            <div className="col3_skeleton1 loading" />
            <div className="col3_skeleton2 loading" />
        </div>
    </div>
);

export default SkeletonLoader;
