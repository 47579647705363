import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Comment from '../Comment';


const CommentsAndReply = ({
    comment,
    index,
    showReplies,
    handleClose,
    handleClick,
    likeThisComment,
    _thisUser,
    anchorEl,
    replyToThisComment,
    articleindex,
    isParent,
    getlikedBy,
    postComment,
    commenColor,
}) => {
    const [isReplyOpen, setReplyOpen] = useState(false);
    return (
        <Fragment>
            <Comment
                comment={comment}
                index={index}
                showReplies={showReplies}
                handleClose={handleClose}
                handleClick={handleClick}
                likeThisComment={likeThisComment}
                _thisUser={_thisUser}
                anchorEl={anchorEl}
                replyToThisComment={replyToThisComment}
                parentCommentId={comment.parent_comment}
                articleindex={articleindex}
                isParent={isParent}
                getlikedBy={getlikedBy}
                postComment={postComment}
                setReplyOpen={setReplyOpen}
            />
            {isReplyOpen
            && comment.replies.map((reply, indexValue) => (
                <div className="indent_comment">
                    <Comment
                        comment={reply}
                        index={indexValue}
                        handleClose={handleClose}
                        handleClick={handleClick}
                        likeThisComment={likeThisComment}
                        _thisUser={_thisUser}
                        anchorEl={anchorEl}
                        commenColor={commenColor}
                        replyToThisComment={replyToThisComment}
                        parentCommentId={comment.parent_comment}
                        articleindex={articleindex}
                        getlikedBy={getlikedBy}
                    />
                </div>
            ))}
        </Fragment>
    );
};


export default CommentsAndReply;


CommentsAndReply.propTypes = {
    comment: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showReplies: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    likeThisComment: PropTypes.func.isRequired,
    _thisUser: PropTypes.number.isRequired,
    anchorEl: PropTypes.number.isRequired,
    commenColor: PropTypes.string.isRequired,
    replyToThisComment: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    articleindex: PropTypes.number.isRequired,
    isParent: PropTypes.bool.isRequired,
    getlikedBy: PropTypes.func.isRequired,
};
