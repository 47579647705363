import {
    LOGIN,
    SIGN_UP_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_SCREEN,
    GET_STATUS,
    GET_STREAK_STATUS,
    GET_USER_DATA,
    LOGOUT_USER,
    GET_CHECKSUM,
    GET_LANGUAGES,
    POST_LANGUAGE,
    RESET_USER_DETAILS,
    SET_NEW_NOTIFICATION,
    EMAIL_SUBSCRIBE,
    SET_HIDE_SECTIONS,
    SET_HIDE_ADD_GOAL_BTN,
    SMRF_LINK,
} from './constants';

export const resetScreen = () => ({ type: RESET_SCREEN });

export const getToken = (payload, history) => ({
    type: `${LOGIN}_PENDING`,
    payload,
    history,
});

export const getTokenSuccess = payload => ({
    type: `${LOGIN}_SUCCESS`,
    payload,
});

export const getTokenFailed = (payload = {}) => ({
    type: `${LOGIN}_FAILED`,
    payload,
});

export const requestSignUp = (payload, callback) => ({
    type: `${SIGN_UP_REQUEST}_PENDING`,
    payload,
    callback,
});

export const requestSignUpSuccess = payload => ({
    type: `${SIGN_UP_REQUEST}_SUCCESS`,
    payload,
});

export const requestSignUpFailed = (payload = {}) => ({
    type: `${SIGN_UP_REQUEST}_FAILED`,
    payload,
});

export const resetPassword = (payload, callback) => ({
    type: `${FORGOT_PASSWORD_REQUEST}_PENDING`,
    payload,
    callback,
});

export const resetPasswordSuccess = payload => ({
    type: `${FORGOT_PASSWORD_REQUEST}_SUCCESS`,
    payload,
});

export const resetPasswordFailed = (payload = {}) => ({
    type: `${FORGOT_PASSWORD_REQUEST}_FAILED`,
    payload,
});

export const getStatus = fcmToken => ({ type: `${GET_STATUS}_PENDING`, fcmToken });

export const getStatusSuccess = payload => ({
    type: `${GET_STATUS}_SUCCESS`,
    payload,
});

export const resetUserDetailsSuccess = payload => ({
    type: `${RESET_USER_DETAILS}_SUCCESS`,
    payload,
});

export const emailSubscribe = payload => ({
    type: `${EMAIL_SUBSCRIBE}_SUCCESS`,
    payload,
});

export const smrfLink = payload => ({
    type: `${SMRF_LINK}_SUCCESS`,
    payload,
});

export const setNewNotification = payload => ({
    type: `${SET_NEW_NOTIFICATION}_SUCCESS`,
    payload,
});

export const setHideSections = payload => ({
    type: `${SET_HIDE_SECTIONS}_SUCCESS`,
    payload,
});

export const setHideAddGoalsBtn = payload => ({
    type: `${SET_HIDE_ADD_GOAL_BTN}_SUCCESS`,
    payload,
});

export const resetUserDetailsFailed = payload => ({
    type: `${RESET_USER_DETAILS}_FAILED`,
    payload,
});

export const getStreakSuccess = payload => ({
    type: `${GET_STREAK_STATUS}_SUCCESS`,
    payload,
});

export const getStatusFailed = (payload = {}) => ({
    type: `${GET_STATUS}_FAILED`,
    payload,
});

export const getUserData = payload => ({
    type: `${GET_USER_DATA}_PENDING`,
    payload,
});

export const getUserDataSuccess = payload => ({
    type: `${GET_USER_DATA}_SUCCESS`,
    payload,
});

export const getUserDataFailed = (payload = {}) => ({
    type: `${GET_USER_DATA}_FAILED`,
    payload,
});

export const logoutUser = history => ({
    type: `${LOGOUT_USER}_PENDING`,
    history,
});

export const failedLogoutUser = () => ({
    type: `${LOGOUT_USER}_FAILED`,
});

export const getChecksum = isLoggedIn => ({
    type: `${GET_CHECKSUM}_PENDING`,
    isLoggedIn,
});

export const initialLocalisationsFetched = value => ({
    type: 'INITIAL_LOCALISATIONS_FETCHED',
    value,
});

export const oauth = domain => ({
    type: 'VERIFY_DOMAIN_NAME_PENDING',
    domain,
});

export const OAuthGetToken = (code, state, history) => ({
    type: 'SEND_INFO_TO_AUTH0',
    code,
    state,
    history,
});

export const validDomain = isValid => ({
    type: 'VERIFY_DOMAIN_NAME_SUCCESS',
    isValid,
});

export const setIsLoading = value => ({
    type: 'SET_ISLOADING',
    value,
});

export const setCount = count => ({
    type: 'SET_COUNT',
    count,
});

export const streakDissmissPress = id => ({
    type: 'STREAK_CLOSED',
    id,
});

export const storeIsAccountExpired = payload => ({
    type: 'STORE_ACCOUNT_EXPIRED_STATUS',
    payload,
});
export const getAvailableLanguages = () => ({
    type: `${GET_LANGUAGES}_PENDING`,
});

export const storeLanguages = payload => ({
    type: `${GET_LANGUAGES}_DONE`,
    payload,
});

export const postSelectedLanguage = language => ({
    type: POST_LANGUAGE,
    language,
});
