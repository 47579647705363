import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Grid } from '@material-ui/core';
import GoalHeading from './GoalHeading';
import NotesDetails from './NotesDetails';
import Loader from '../../../components/Loader';

const GoalDetails = ({ goalType, vcrbDetails, addNotes, notes, isLoading }) => {
    const thisGoal = vcrbDetails.find(goal => goal.type === goalType);
    const detailedData = [
        {
            type: 'value',
            data: thisGoal.detail,
            key: 1,
        },
        {
            type: 'culture',
            data: thisGoal.detail,
            key: 2,
        },
        {
            type: 'role',
            data: thisGoal.detail,
            key: 3,
        },
        {
            type: 'behaviour',
            data: thisGoal.detail,
            key: 4,
        },
    ];

    const divideSection = goalType === 'role' || goalType === 'behaviour';
    return (
        <Grid container spacing={0}>
            {isLoading && <Loader position="absolute" background="transparent" showBackground />}
            <Grid item xs={12} sm={12} md={divideSection ? 7 : 12} lg={divideSection ? 7 : 12}>
                <div className="goalDetails">
                    <div className="heading">
                        <GoalHeading
                            heading={thisGoal.heading}
                            subHeading={thisGoal.subheading}
                            innerSubheading={thisGoal.inner_subheading}
                            type={goalType}
                            innerDetail
                        />
                    </div>

                    {detailedData.map(
                        v => v.type === goalType && (
                        <div
                            className={`${divideSection ? 'goalNonDividedList' : ''} goalList`}
                            key={v.key}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: v.data }}
                        />
                        ),
                    )}
                </div>
            </Grid>
            {divideSection && (
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <NotesDetails addNotes={addNotes} notes={notes} goalType={goalType} />
                </Grid>
            )}
        </Grid>
    );
};

GoalDetails.propTypes = {
    goalType: PropTypes.string.isRequired,
    vcrbDetails: PropTypes.arrayOf({
        detail: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        inner_subheading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default GoalDetails;
