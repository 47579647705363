import React from 'react';
import PropTypes from 'prop-types';
import * as sc from './styles';

const SingleSelectDropDownWithLabel = ({
    label = '',
    menuItems,
    onChange,
    name = '',
    disabled = false,
    value,
    mandatory,
    placeholder,
}) => (
    <sc.DropdownWrapper disabled={disabled}>
        <sc.Label label={label}>
            {label}
            {mandatory && <sup>*</sup>}
        </sc.Label>
        <sc.Select
            name={name}
            onChange={onChange}
            displayEmpty
            renderValue={() => <sc.PlaceholderText>{value.name || placeholder}</sc.PlaceholderText>}
            value={value.value}
            variant="outlined"
            disabled={disabled}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
        >
            {menuItems
                && menuItems.map(language => (
                    <sc.MenuItem value={language.value} className="options" key={`language-${language.name}`}>
                        {language.name}
                    </sc.MenuItem>
                ))}
        </sc.Select>
    </sc.DropdownWrapper>
);

SingleSelectDropDownWithLabel.defaultProps = {
    menuItems: [],
    disabled: false,
    mandatory: false,
    value: '',
    placeholder: '',
};

SingleSelectDropDownWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    menuItems: PropTypes.array,
    value: PropTypes.string,
    placeholder: PropTypes.string,
};

export default SingleSelectDropDownWithLabel;
