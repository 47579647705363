import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Text from '../../../../components/Text';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import './index.scss';
import { fontFamilies } from '../../../../common/fontMap';

export default function Stats({
    contentState,
    setShowUsers,
    setUserType,
    pageAnalytics,
    myProgressHomeStartTime,
    setFollowersStartTime,
    setFollowingStartTime,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        networkStats,
        likesReceived,
        socialPostsMade,
        following,
        followers,
        influncerStats,
    } = localisationsAfterLogin;

    // const handleDrawerOpen = () => {
    //     setChapterDetailsOpen(true);
    //     const date = new Date();
    //     const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
    //     setChapterParticipatedStartTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
    //     pageAnalytics({
    //         page_tag: 'myProgressHome',
    //         device_id: localStorage.fcmToken,
    //         start_time: myProgressHomeStartTime,
    //         end_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    //         user: userProfile,
    //     });
    // };

    const connections = (connections, count = 0) => {
        // const { currentTarget: { dataset: { connections } } } = e;
        if (!count) {
            return false;
        }

        const date = new Date();
        const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
        pageAnalytics({
            page_tag: 'myProgressHome',
            device_id: localStorage.fcmToken,
            start_time: myProgressHomeStartTime,
            end_time: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            user: userProfile,
        });
        if (connections === 'followers') {
            setShowUsers(true);
            setUserType(0);
            setFollowersStartTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
        } else if (connections === 'following') {
            setShowUsers(true);
            setUserType(1);
            setFollowingStartTime(moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS'));
        }

        return true;
    };

    return (
        <div className="contentstates_wrapper">
            <div className="content_stats">
                <div className="heading">
                    <Text fontcolor="#272727" fontsize="1.25rem" fontfamily={fontFamilies.playfairBold}>
                        {influncerStats}
                    </Text>
                </div>
                <div className="row">
                    <span>
                        <Text
                            fontcolor="#525252"
                            fontsize="1rem"
                            fontfamily="TheinhardtPan"
                            fontweight="500"
                            cursor="default"
                        >
                            {socialPostsMade}
                        </Text>
                    </span>
                    <span className="fraction">
                        <Text
                            fontcolor="#000000"
                            fontsize="1rem"
                            fontfamily="TheinhardtPan"
                            fontweight="600"
                            cursor="default"
                        >
                            {contentState.influencerStats.totalPost}
                        </Text>
                    </span>
                </div>
                <div className="row">
                    <span>
                        <Text
                            fontcolor="#525252"
                            fontsize="1rem"
                            fontfamily="TheinhardtPan"
                            fontweight="500"
                            cursor="default"
                        >
                            {likesReceived}
                        </Text>
                    </span>
                    <span className="fraction">
                        <Text
                            fontcolor="#000000"
                            fontsize="1rem"
                            fontfamily="TheinhardtPan"
                            fontweight="600"
                            cursor="default"
                        >
                            {contentState.influencerStats.totalLike}
                        </Text>
                    </span>
                </div>
            </div>
            <div className="content_stats">
                <div className="heading">
                    <Text fontcolor="#272727" fontsize="1.25rem" fontfamily={fontFamilies.playfairBold}>
                        {networkStats}
                    </Text>
                </div>
                <div
                    className="row"
                    onClick={() => connections('followers', contentState.networkStats.followers)}
                    data-connections="followers"
                >
                    <span>
                        <Text
                            fontcolor="#525252"
                            fontsize="1rem"
                            fontfamily="TheinhardtPan"
                            fontweight="500"
                            cursor={contentState.networkStats.followers > 0 ? 'pointer' : 'default'}
                        >
                            {followers}
                        </Text>
                    </span>
                    <div>
                        <span>
                            <Text
                                fontcolor="#000000"
                                fontsize="1rem"
                                fontfamily="TheinhardtPan"
                                fontweight="600"
                                cursor={contentState.networkStats.followers > 0 ? 'pointer' : 'default'}
                            >
                                {contentState.networkStats.followers}
                            </Text>
                        </span>
                        {contentState.networkStats.followers > 0 && <div className="arrow_action pointer" />}
                    </div>
                </div>
                <div
                    className="row"
                    onClick={() => connections('following', contentState.networkStats.following)}
                    data-connections="following"
                >
                    <span>
                        <Text
                            fontcolor="#525252"
                            fontsize="1rem"
                            fontfamily="TheinhardtPan"
                            fontweight="500"
                            cursor={contentState.networkStats.following > 0 ? 'pointer' : 'default'}
                        >
                            {following}
                        </Text>
                    </span>
                    <div>
                        <span>
                            <Text
                                fontcolor="#000000"
                                fontsize="1rem"
                                fontfamily="TheinhardtPan"
                                fontweight="600"
                                cursor={contentState.networkStats.following > 0 ? 'pointer' : 'default'}
                            >
                                {contentState.networkStats.following}
                            </Text>
                        </span>
                        {contentState.networkStats.following > 0 && <div className="arrow_action pointer" />}
                    </div>
                </div>
            </div>
        </div>
    );
}

Stats.propTypes = {
    contentState: PropTypes.object.isRequired,
    setShowUsers: PropTypes.func.isRequired,
    setUserType: PropTypes.func.isRequired,
    pageAnalytics: PropTypes.func.isRequired,
    myProgressHomeStartTime: PropTypes.string.isRequired,
    setFollowersStartTime: PropTypes.func.isRequired,
    setFollowingStartTime: PropTypes.func.isRequired,
};
