import AuthStore from '../../../common/AuthStore';
import endpoints from '../../../Endpoints';
import { fetchWebApi } from '../../../webapis/dashboard';

// eslint-disable-next-line import/prefer-default-export
export const getUserMatrixApi = () => {
    const apiBasePath = AuthStore.baseUrl;
    const { homeMatrix } = endpoints;
    const url = `${apiBasePath}${homeMatrix}`;
    return fetchWebApi(AuthStore.accessToken, url).request;
};
