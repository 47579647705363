import React from 'react';
import PropTypes from 'prop-types';
import ImageSelector from './ImageSelector';
import { Camera } from '../assets/svg';
import '../index.scss';

// eslint-disable-next-line no-shadow
const ImageSelectorContainer = ({
    postImage,
    questions,
    setImageUri,
    imageUri,
    isLoading,
    popUpOpen,
    toggleShowSelectorModal,
}) => {
    const imageQuestionId = questions
        && questions.length > 0
        && questions.filter(question => question.question_detail === 'profile_picture');

    const handleOpen = () => {
        toggleShowSelectorModal(true);
        setImageUri('');
    };

    const handleClose = () => {
        toggleShowSelectorModal(false);
    };

    return (
        <div
            onClick={handleOpen}
            className={imageUri ? 'image_div_container' : 'image_div_container_adjusted'}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            {popUpOpen && (
                <ImageSelector
                    imageQuestionId={imageQuestionId}
                    uploadImage={postImage}
                    open={popUpOpen}
                    handleClose={handleClose}
                    setImageUri={setImageUri}
                    imageUri={imageUri}
                    isLoading={isLoading}
                />
            )}
            <div className="image_div">
                <div>
                    <Camera />
                </div>
            </div>
        </div>
    );
};

export default ImageSelectorContainer;

ImageSelectorContainer.propTypes = {
    postImage: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    setImageUri: PropTypes.func.isRequired,
    imageUri: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    toggleShowSelectorModal: PropTypes.func.isRequired,
};
