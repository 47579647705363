import { put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { tokenValidatorGenerator, dataURItoBlob, validURL, decompressedFile } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getUserData } from '../../UnAuthenticatedContent/actions';
import {
    getTagsApi,
    makeAPostApi,
    postLikeApi,
    deletePostApi,
    getThisFeedsApi,
    postSavedPostsApi,
    getSavedPostsApi,
    getLikeDetailsApi,
    postFollowApi,
    getUsersListOfType,
    getAllCommentsApi,
    postCommentApi,
    modifyCommentApi,
    postQuizAnswerApi,
    reportPostApi,
    postUnfollowUserApi,
    getUsersFollowedApi,
    fetchUsersApi,
    sharePostApi,
    removeBookMarkApi,
} from '../../Feeds/redux/apis';

import getFeedsApi from './apis';

import {
    storeTags,
    getFeedsListSuccess,
    getFeedsListFailed,
    getFeedsListMoreSuccess,
    getFeedsListMoreFailed,
    postLikeFailed,
    postLikeSuccess,
    uploadPostDone,
    toggleCreatePostModal,
    getThisFeedsListSuccess,
    deletePostSuccess,
    deletePostFailed,
    getSavedPostsSuccess,
    getSavedPostsFailed,
    postSavedPostsSuccess,
    postSavedPostsFailed,
    getLikeDetailsSuccess,
    getLikeDetailsFailed,
    postFollowUserSuccess,
    postFollowUserFailed,
    storeUsersListOfType,
    storeAllComments,
    postCommentDone,
    upDateComments,
    updateFeeds,
    successPostQuizAnswer,
    successReportPost,
    failedReportPost,
    successGetUsersFollowed,
    failedGetUsersFollowed,
    getUsersOfType,
    getUsersFollowed,
    successFetchUsersList,
    failedFetchUsersList,
    successSharePost,
    failedSharePost,
    successFetchMoreUsersList,
    failedFetchMoreUsersList,
    successRemoveBookMark,
    failedRemoveBookMark,
    getSavedPosts,
} from './actions';

function* getAllTags() {
    const { data } = yield tokenValidatorGenerator({
        saga: getTagsApi,
        payload: [AuthStore.accessToken],
    });
    yield put(storeTags(data.data));
}

function* getFeeds({ id }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getFeedsApi,
            payload: [AuthStore.accessToken, id],
        });
        const newArr = data && data.data;
        yield put(getFeedsListSuccess([newArr]));
    } catch ({ response }) {
        yield put(getFeedsListFailed(response));
    }
}

function* getMoreFeeds({ payload, hashTag }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getFeedsApi,
            payload: [AuthStore.accessToken, payload, hashTag],
        });
        const files = [];
        const promises = [];
        let newArr = data && data.data;
        newArr.map(k => {
            if (validURL(k.file)) {
                if (k.file.split('.').pop() === 'flr') {
                    return promises.push(
                        decompressedFile(
                            'https://ohi-npn-upload-image.s3.amazonaws.com/mpc/dev/animations/data.lottie',
                        ).then(response => files.push({ file: JSON.parse(response), id: k.id })),
                    );
                }
            }
            return false;
        });

        yield Promise.all(promises).then(() => {
            if (files) {
                newArr = [
                    ...newArr.map(x => {
                        if (validURL(x.file)) {
                            if (x.file.split('.').pop() === 'flr') {
                                const item = files.find(({ id }) => id === x.id);
                                return Object.assign({}, x, { file: item.file });
                            }
                            return Object.assign({}, x, { file: x.file });
                        }
                        return false;
                    }),
                ];
            }
        });
        yield put(getFeedsListMoreSuccess(newArr));
    } catch ({ response }) {
        yield put(getFeedsListMoreFailed(response.data));
    }
}

function* postLikeFeed({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: postLikeApi,
            payload: [AuthStore.accessToken],
            body: { article_id: articleId },
        });
        const dataRequest = { data, articleId };
        yield put(postLikeSuccess(dataRequest));
    } catch ({ response }) {
        yield put(postLikeFailed(response));
    }
}

function* deletePost({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: deletePostApi,
            payload: [AuthStore.accessToken],
            body: { article_id: articleId },
        });
        const dataRequest = { data, articleId };
        yield put(deletePostSuccess(dataRequest));
    } catch ({ response }) {
        yield put(deletePostFailed(response));
    }
}

// eslint-disable-next-line camelcase
function* makeAPost({
    file,
    // eslint-disable-next-line camelcase
    desc,
    categoryTags,
    practiceTags,
    content,
    fileType,
    // eslint-disable-next-line camelcase
    users_list,
    social_config,
    postType,
}) {
    const formData = new FormData();
    const payload = {
        desc,
        practice_tags: practiceTags
            .filter(tag => tag.selected)
            .map(tag => tag.id)
            .join(','),
        category_tags: categoryTags
            .filter(tag => tag.selected)
            .map(tag => tag.id)
            .join(','),
        file,
        content,
        fileType,
        users_list,
        social_config,
        type: postType,
    };
    if (payload.file) {
        if (payload.fileType.includes('video')) {
            formData.append('file', payload.file, 'rec_1.mp4');
        } else {
            formData.append('file', dataURItoBlob(payload.file), 'Post.png');
        }
    }
    formData.append('practice_tags', payload.practice_tags);
    formData.append('category_tags', payload.category_tags);
    formData.append('content', payload.content);
    formData.append('desc', payload.desc);
    formData.append('users_list', payload.users_list);
    if (payload.social_config) {
        formData.append('social_config', JSON.stringify(payload.social_config));
    }
    formData.append('type', payload.type);

    yield tokenValidatorGenerator({
        saga: makeAPostApi,
        payload: [AuthStore.accessToken],
        body: formData,
    });
    localStorage.removeItem('canvasImage');
    yield put(uploadPostDone());
    yield put(toggleCreatePostModal(false));
}

function* getThisFeedData({ id }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getThisFeedsApi,
            payload: [AuthStore.accessToken, id],
        });
        yield put(getThisFeedsListSuccess(data.data));
    } catch ({ response }) {
        yield put(getFeedsListFailed(response.data));
    }
}

function* fetchSavedPosts() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getSavedPostsApi,
            payload: [AuthStore.accessToken],
        });
        yield put(getSavedPostsSuccess(data));
    } catch ({ response }) {
        yield put(getSavedPostsFailed(response));
    }
}

function* saveAPost({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: postSavedPostsApi,
            payload: [AuthStore.accessToken],
            body: { article_id: articleId },
        });
        const dataRequest = {
            data,
            articleId,
        };
        yield put(postSavedPostsSuccess(dataRequest));
    } catch ({ response }) {
        yield put(postSavedPostsFailed(response));
    }
}
function* fetchLikeDetails({ payload }) {
    try {
        const { articleId } = payload;
        const { data } = yield tokenValidatorGenerator({
            saga: getLikeDetailsApi,
            payload: [AuthStore.accessToken, articleId],
        });
        yield put(getLikeDetailsSuccess(data));
    } catch ({ response }) {
        yield put(getLikeDetailsFailed(response));
    }
}
function* postFollow({ payload }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: postFollowApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });

        yield put(getUsersFollowed());
        yield put(getUserData());
        yield put(postFollowUserSuccess(data));
    } catch ({ response }) {
        yield put(postFollowUserFailed(response));
    }
}

function* getUsersOfTypeSaga({ userType }) {
    const { data } = yield tokenValidatorGenerator({
        saga: getUsersListOfType,
        payload: [AuthStore.accessToken, userType],
    });
    yield put(storeUsersListOfType(data));
}

function* getAllCommentsForArticleSaga({ articleId }) {
    const { data } = yield tokenValidatorGenerator({
        saga: getAllCommentsApi,
        payload: [AuthStore.accessToken, articleId],
    });
    yield put(storeAllComments(data.data, articleId));
}

function* postCommentSaga({ articleId, text, selectedUsers, parrentid }) {
    yield tokenValidatorGenerator({
        saga: postCommentApi,
        payload: [AuthStore.accessToken],
        body: {
            article: articleId,
            text,
            user: JSON.parse(localStorage.userProfile).id,
            users_list: selectedUsers.join(),
            parent_comment: parrentid || null,
        },
    });
    yield getAllCommentsForArticleSaga({ articleId });
    yield put(postCommentDone());
    yield put(updateFeeds({ articleId: Number(articleId), operation: '+' }));
}

function* likeCommentSaga({ id, articleId }) {
    yield tokenValidatorGenerator({
        saga: modifyCommentApi,
        payload: [AuthStore.accessToken, id, 'like'],
    });
    yield getAllCommentsForArticleSaga({ articleId });
    yield put(upDateComments());
}

function* deleteCommentSaga({ id, articleId, replycount }) {
    yield tokenValidatorGenerator({
        saga: modifyCommentApi,
        payload: [AuthStore.accessToken, id, 'delete'],
    });
    yield getAllCommentsForArticleSaga({ articleId });
    yield put(upDateComments());
    yield put(updateFeeds({ articleId: Number(articleId), operation: '-', replycount }));
}

function* postQuizAnswerFn({ payload }) {
    const response = yield tokenValidatorGenerator({
        saga: postQuizAnswerApi,
        payload: [AuthStore.accessToken],
        body: payload,
    });
    yield put(successPostQuizAnswer(response));
}

function* reportPostFn({ feedId }) {
    try {
        yield tokenValidatorGenerator({
            saga: reportPostApi,
            payload: [AuthStore.accessToken, feedId],
            body: {
                action: 'report',
            },
        });
        yield put(successReportPost());
    } catch (e) {
        yield put(failedReportPost());
    }
}

function* postUnfollowUser({ payload, userType }) {
    try {
        yield tokenValidatorGenerator({
            saga: postUnfollowUserApi,
            payload: [AuthStore.accessToken, payload],
        });

        if (userType !== null) {
            yield put(getUsersOfType(userType));
        }
        yield put(getUsersFollowed());
        yield put(getUserData());
    } catch (e) {
        yield put(failedReportPost(e));
    }
}

function* getUsersFollowedFn() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUsersFollowedApi,
            payload: [AuthStore.accessToken],
        });
        yield put(successGetUsersFollowed(data));
    } catch ({ response }) {
        yield put(failedRemoveBookMark(response));
    }
}

function* removeBookMarkFn({ articleId }) {
    try {
        yield tokenValidatorGenerator({
            saga: removeBookMarkApi,
            payload: [AuthStore.accessToken, articleId],
        });
        yield put(getSavedPosts());
        yield put(successRemoveBookMark());
    } catch (e) {
        yield put(failedGetUsersFollowed(e));
    }
}

function* fetchUsersListFn({ page, query }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: fetchUsersApi,
            payload: [AuthStore.accessToken, query, page],
        });

        yield put(successFetchUsersList(data));
    } catch (e) {
        yield put(failedFetchUsersList());
    }
}

function* sharePostFn({ payload }) {
    try {
        yield tokenValidatorGenerator({
            saga: sharePostApi,
            payload: [AuthStore.accessToken],
            body: payload,
        });

        yield put(successSharePost());
    } catch (e) {
        yield put(failedSharePost());
    }
}

function* fetchMoreUsersListFn({ page, query }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: fetchUsersApi,
            payload: [AuthStore.accessToken, query, page],
        });

        yield put(successFetchMoreUsersList(data));
    } catch (e) {
        yield put(failedFetchMoreUsersList());
    }
}

const NotificationFeedsSaga = [
    takeLatest(`${types.UPLOAD_POST}_PENDING`, makeAPost),
    takeLatest(`${types.GET_TAGS}_PENDING`, getAllTags),
    takeLatest(types.GET_FEEDS_PENDING, getFeeds),
    takeLatest(types.GET_FEEDS_MORE_PENDING, getMoreFeeds),
    takeLatest(types.POST_LIKE_PENDING, postLikeFeed),
    takeLatest(`${types.GET_THIS_FEED}_PENDING`, getThisFeedData),
    takeLatest(types.DELETE_POST_PENDING, deletePost),
    takeLatest(types.GET_SAVED_POSTS_PENDING, fetchSavedPosts),
    takeLatest(types.POST_SAVED_POSTS_PENDING, saveAPost),
    takeLatest(types.GET_LIKE_DETAILS_PENDING, fetchLikeDetails),
    takeLatest(types.POST_FOLLOW_USER_PENDING, postFollow),
    takeLatest(`${types.GET_USERS_OF_TYPE}_PENDING`, getUsersOfTypeSaga),
    takeLatest(`${types.GET_ALL_COMMENTS}_PENDING`, getAllCommentsForArticleSaga),
    takeLatest(`${types.POST_COMMENT}_PENDING`, postCommentSaga),
    takeLatest(`${types.LIKE_COMMENT}_PENDING`, likeCommentSaga),
    takeLatest(`${types.DELETE_COMMENT}_PENDING`, deleteCommentSaga),
    takeLatest(`${types.POST_QUIZ_ANSWER}_PENDING`, postQuizAnswerFn),
    takeLatest(`${types.REPORT_POST}_PENDING`, reportPostFn),
    takeLatest(`${types.POST_UNFOLLOW_USER}_PENDING`, postUnfollowUser),
    takeLatest(`${types.GET_USERS_FOLLOWED}_PENDING`, getUsersFollowedFn),
    takeLatest(`${types.REMOVE_BOOKMARK}_PENDING`, removeBookMarkFn),
    takeLatest(`${types.FETCH_USERS_LIST}_PENDING`, fetchUsersListFn),
    takeLatest(`${types.SHARE_POST}_PENDING`, sharePostFn),
    takeLatest(`${types.FETCH_MORE_USERS_LIST}_PENDING`, fetchMoreUsersListFn),
];

export default NotificationFeedsSaga;
