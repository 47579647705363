import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const TextAnswer = ({ setNameQuestionAns }) => {
    const setResponse = value => {
        setNameQuestionAns(value);
    };

    return <TextField fullWidth required id="standard-name" onChange={e => setResponse(e.target.value)} />;
};

TextAnswer.propTypes = {
    setNameQuestionAns: PropTypes.func.isRequired,
};

export default TextAnswer;
