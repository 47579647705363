import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import LocalisationsStore from '../../../../common/LocalisationsStore';

const DropDownQues = ({
    elem,
    callBack,
    callBackData,
    freeze,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { quiz_mandatory } = localisationsAfterLogin;
    const {
        id,
        question_text,
        options,
        config,
        required,
        is_editable,
    } = elem;

    const [showCard, setShowCard] = useState({ active: false, cardInfo: null });
    const [selectedPrac, setSelectedPrac] = useState(null);

    useEffect(() => {
        setShowCard({ active: false, cardInfo: null });
        if (elem.response) {
            setSelectedPrac(options.find(opt => opt.id.toString() === elem.response));
        } else {
            setSelectedPrac(null);
        }
        // eslint-disable-next-line
    }, [elem]);

    const QuesTitle = styled.div`
        font-size: ${`${config.font_size}px` || '16px'};
        color: ${config.color};
        text-transform: ${config.all_caps ? 'uppercase' : ''};
        font-family: ${config.font_family};
        font-weight: ${config.font_weight};
        margin-bottom:8px;
        white-space: pre-wrap;
    `;

    const clickOption = event => {
        const { target: { value } } = event;
        const prac = options.find(opt => opt.title === value);
        setSelectedPrac(prac);
        setShowCard({ active: true, cardInfo: prac.card_info });
        callBack({
            ...callBackData,
            question_response: {
                ...callBackData.question_response,
                [id]: {
                    response: prac.id.toString(),
                    question_id: id,
                },
            },
        });
    };

    const getPracticeCard = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '6px', backgroundColor: `${selectedPrac ? selectedPrac.card_info.bg_color : showCard.cardInfo.bg_color}`, height: 'auto', marginTop: '15px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 15px' }}>
                <div
                    style={{
                        fontFamily: 'playfair_bold',
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '20px',
                        color: '#000',
                    }}
                >
                    {selectedPrac ? selectedPrac.card_info.title : showCard.cardInfo.title}
                </div>
                <div
                    style={{
                        fontFamily: 'Mckinsey-Sans-Regular',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#000',
                        marginTop: '10px',
                    }}
                >
                    {selectedPrac ? selectedPrac.card_info.detail : showCard.cardInfo.detail}
                </div>
            </div>
            <img style={{ height: '150px', borderRadius: '6px', padding: '20px 15px 20px 0' }} src={selectedPrac ? (selectedPrac.card_info.image_url_web || selectedPrac.card_info.image_url) : (showCard.cardInfo.image_url_web || showCard.cardInfo.image_url)} alt="img" />
        </div>
    );


    return (
        <div className="dropdown">
            <QuesTitle>{question_text}</QuesTitle>
            <FormControl variant="outlined" fullWidth>
                <InputLabel style={{ color: `${(freeze || !is_editable) ? '#cccccc' : '#333333'}` }} shrink={false} id="demo-simple-select-label">{selectedPrac ? '' : 'Select an option'}</InputLabel>
                <Select
                    value={((selectedPrac || '') && selectedPrac.title)}
                    onChange={clickOption}
                    disabled={freeze || !is_editable}
                    IconComponent={KeyboardArrowDown}
                    MenuProps={{
                        classes: {
                            paper: 'dropdownMenuPaper',
                        },
                    }}
                >
                    {options.map(opt => (
                        <MenuItem value={opt.title} key={opt?.id}>{opt.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {(showCard.active || selectedPrac) && (
                getPracticeCard()
            )}
            {required && (
                <div
                    className="mandatory"
                    style={{
                        marginTop: '6px',
                    }}
                >
                    {quiz_mandatory}
                </div>
            )}

        </div>
    );
};
export default DropDownQues;

DropDownQues.propTypes = {
    elem: PropTypes.shape({
        id: PropTypes.number,
        question_text: PropTypes.string,
        options: PropTypes.array,
        response: PropTypes.string,
        config: PropTypes.shape({
            font_size: PropTypes.number,
            color: PropTypes.string,
            all_caps: PropTypes.bool,
            font_family: PropTypes.string,
            font_weight: PropTypes.string,
        }),
        required: PropTypes.bool,
        is_editable: PropTypes.bool,
    }).isRequired,
    callBackData: PropTypes.shape({
        question_response: PropTypes.shape({}),
    }).isRequired,
    callBack: PropTypes.func.isRequired,
    freeze: PropTypes.bool.isRequired,
};
