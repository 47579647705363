import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import PreviewProfileImage from '../PreviewProfileImage';
import FollowUnfollow from '../FollowUnfollow';
import {
    getUsersOfType,
    postFollowUser,
    getUsersFollowed,
    postUnfollowUser,
    setFollowUnfollowInFeeds,
} from '../redux/actions';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../utils/functions';

function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${57}%)`,
    };
}

function FollowUnfollowList({
    open,
    handleClose,
    userList,
    followedId,
    postFollowUser,
    getUsersOfType,
    userType,
    getUsersFollowed,
    followedUsers,
    postUnfollowUser,
    setFollowUnfollowInFeeds,
    isMobile,
}) {
    const getUsersOfTypeCallback = useCallback(getUsersOfType, []);
    const getUsersFollowedCallback = useCallback(getUsersFollowed, []);
    const [userListWithFollowed, setUserListWithFollowed] = useState([]);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { followers, following } = localisationsAfterLogin;

    useEffect(() => {
        if (userType === 0) {
            pageAnalytics.initialise('followers');
            return () => {
                pageAnalytics.complete('followers');
            };
        }
        pageAnalytics.initialise('following');
        return () => {
            pageAnalytics.complete('following');
        };
    }, [userType]);

    useEffect(() => {
        getUsersOfTypeCallback(userType);
        getUsersFollowedCallback();
    }, [getUsersOfTypeCallback, userType, getUsersFollowedCallback]);

    useEffect(() => {
        const userListWithFollowed = userList.map(user => ({
            ...user,
            is_following: followedUsers.some(followedUser => followedUser.following_to === user.id),
        }));
        setUserListWithFollowed(userListWithFollowed);
    }, [userList, followedUsers]);

    const [modalStyle] = useState(getModalStyle);
    const body = (
        // <div style={modalStyle} className="likeModalBody outline-none paper">
        <div
            style={modalStyle}
            className={`${isMobile && 'mobile_follower_container'} likeModalBody paper outline-none `}
        >
            <div className="close_icon_selector">
                <div className="likeHeading">{userType === 0 ? followers : following}</div>
                <div
                    className="cursor-pointer outline-none"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={e => {
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    <Cross color="black" />
                </div>
            </div>
            {userListWithFollowed.map(x => (
                <div className="likesUsers" key={x.liked_by}>
                    <div className="d-flex">
                        <div>
                            <svg height="0" width="0">
                                <clipPath id="myLikePath">
                                    <path
                                        fill="none"
                                        fillRule="evenodd"
                                        stroke="#979797"
                                        d="M15.542 2.489C6.796 6.793-1.244 20.263 2.092 33.137c2.69 10.38 25.388 26.49 37.996 12.243 18.18-20.543 5.045-31.448-1.641-37.024-5.023-4.189-14.158-10.171-22.905-5.867z"
                                    />
                                </clipPath>
                            </svg>
                            <div className="profile_image_adjusted">
                                <PreviewProfileImage
                                    src={x.profile_picture}
                                    alt="image"
                                    width={48}
                                    height={48}
                                    id="myLikePath"
                                />
                            </div>
                        </div>
                        <div className="nameText">{x.full_name}</div>
                    </div>
                    <div className="followUnfollowBtn">
                        <FollowUnfollow
                            isFollowing={x.is_following}
                            followedId={followedId}
                            postFollowUser={postFollowUser}
                            userId={x.id}
                            postUnfollowUser={postUnfollowUser}
                            userType={userType}
                            setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
            {isMobile ? (
                <div className="mobile_profile_container">{body}</div>
            ) : (
                <Modal
                    open={open}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="likeModal"
                >
                    {body}
                </Modal>
            )}
        </>
    );
}

FollowUnfollowList.defaultProps = {
    isMobile: false,
};

FollowUnfollowList.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    userList: PropTypes.array.isRequired,
    followedId: PropTypes.number.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    userType: PropTypes.number.isRequired,
    getUsersOfType: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
};

const stateProps = state => {
    const FeedsReducer = state.get('feedsReducer');
    return {
        followedId: FeedsReducer.followedId,
        userList: FeedsReducer.userListOfType,
        followedUsers: FeedsReducer.followedUsers,
    };
};

const dispatchers = {
    getUsersOfType,
    postFollowUser,
    getUsersFollowed,
    postUnfollowUser,
    setFollowUnfollowInFeeds,
};

export default connect(stateProps, dispatchers)(FollowUnfollowList);
