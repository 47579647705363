import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';
import PromptArticle from '../../containers/PromptArticle/index.container';
import AddNewPost from '../../containers/Feeds/AddNewPost';
import star from '../../assets/start.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import { pageAnalytics } from '../../utils/functions';

export default function RecommendedPromptCard({
    index,
    id,
    name,
    thumbnail,
    description,
    getRecommendedPrompts,
    getTodos,
    uploadPost,
    tags,
    uploadingPost,
    showCreatePost,
    setShowCreatePost,
    history,
    is_featured,
    getUserMatrix,
    resetGoalTab,
}) {
    const [showPrompt, setShowPrompt] = useState({ show: false });
    const [hashtag, setHashTag] = useState('');
    const { localisationsAfterLogin } = LocalisationsStore;
    const { feature } = localisationsAfterLogin;

    const handleClosePromptArticle = ({ show }) => {
        setShowPrompt({ show });
        getRecommendedPrompts();
        getTodos(5, 1);
        getUserMatrix();
        resetGoalTab();
        pageAnalytics.complete(`recommendedPrompts/${id}`);
    };

    const handleClose = () => {
        setShowCreatePost(false);
    };

    const handleClickCard = () => {
        setShowPrompt({ show: true });
        pageAnalytics.initialise(`recommendedPrompts/${id}`);
    };

    return (
        <Fragment>
            <div
                className="recommended_prompt_card_container"
                key={`recommended_prompt-card-${index}`}
                data-id={id}
                onClick={handleClickCard}
            >
                <div className="left_panel">
                    <img src={thumbnail} alt="thm" className="pointer" />
                    <div className="view_practice_wrapper">
                        <Text
                            fontsize=".75rem"
                            fontcolor={fontColors.siteGrey}
                            fontfamily={fontFamilies.TheinhardtPanLight}
                            margin="15px 0 0 0"
                            textTransform="uppercase"
                            letterSpacing="1px"
                            cursor="pointer"
                            fontweight="bold"
                        >
                            {name}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanLight}
                            margin="10px 0 0 0"
                            cursor="pointer"
                        >
                            {description}
                        </Text>
                        {is_featured && (
                            <div className="featured pointer">
                                <img src={star} alt="featured" />
                                <Text
                                    fontsize="11px"
                                    fontcolor={fontColors.textBlack}
                                    fontfamily={fontFamilies.TheinhardtPanRegular}
                                    margin="0 0 0 0.5rem"
                                    cursor="pointer"
                                    letterSpacing="0.75px"
                                    lineHeight="unset"
                                >
                                    {feature}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showPrompt.show && (
                <PromptArticle
                    handleCrossClick={handleClosePromptArticle}
                    id={id}
                    setShowCreatePost={setShowCreatePost}
                    shouldSendAnalytics={false}
                    history={history}
                />
            )}

            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags || { practice: [], category: [] }}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
        </Fragment>
    );
}

RecommendedPromptCard.defaultProps = {
    tags: {},
}
RecommendedPromptCard.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    getTodos: PropTypes.func.isRequired,
    getRecommendedPrompts: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    uploadPost: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    showCreatePost: PropTypes.bool.isRequired,
    setShowCreatePost: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    is_featured: PropTypes.bool.isRequired,
    getUserMatrix: PropTypes.func.isRequired,
    resetGoalTab: PropTypes.func.isRequired,
};
