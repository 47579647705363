import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import UnsubscribeDoneImg from '../assets/done.svg';

export default function UnsubscribeComponent({ history, unsubscribeUser, isInsubscribed, alreadyUnsubscribed }) {
    const [baseUrl, setBaseUrl] = useState('');
    const [privateKey, setPrivateKey] = useState({ id: '', type: '' });

    useEffect(() => {
        let id;
        let email;
        const urlString = window.location.href;
        const url = new URL(urlString);
        const baseurl = url.searchParams.get('baseurl');
        if (urlString.includes('tag_id')) {
            id = url.searchParams.get('tag_id');
            setPrivateKey({ id, type: 'tag_id' });
        } else if (urlString.includes('email')) {
            email = url.searchParams.get('email');
            setPrivateKey({ email, type: 'email' });
        } else {
            id = url.searchParams.get('id');
            setPrivateKey({ id: window.atob(id), type: 'id' });
        }
        setBaseUrl(window.atob(baseurl));
    }, [history]);

    const handleClick = () => {
        unsubscribeUser({ baseUrl, privateKey });
    };

    return (
        <div className="unsubscribe">
            {!isInsubscribed ? (
                <>
                    <div className="question">Are you sure you want to unsubscribe?</div>
                    <button type="button" onClick={handleClick}>
                        Unsubscribe
                    </button>
                </>
            ) : (
                <>
                    <img src={UnsubscribeDoneImg} alt="" />
                    <div className="success">Unsubscribe request successful</div>
                    <div className="msg">You have been unsubscribed from this mailing list</div>
                </>
            )}
            {alreadyUnsubscribed && <div className="success">You are already unsubscribed</div>}
        </div>
    );
}

UnsubscribeComponent.propTypes = {
    unsubscribeUser: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isInsubscribed: PropTypes.bool.isRequired,
    alreadyUnsubscribed: PropTypes.bool.isRequired,
};
