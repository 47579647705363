import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotificationCenterComponent from './NotificationCenterComponent';
import notificationsListSaga from './redux/sagas';
import notificationsListReducer from './redux/reducer';
import {
    getNotificationsList,
    readThisNotification,
    markAllRead,
    incrementPageNumber,
    toggleReachedEndOfNotificationsList,
    getNotificationsListMore,
    resetPageNumber,
} from './redux/actions';
import { getThisFeedsListSuccess, getThisFeedData, pageAnalytics } from '../Feeds/redux/actions';

const stateToProps = state => {
    const NotificationsListReducer = state.get('notificationsListReducer');
    return {
        notificationsList: NotificationsListReducer.notifications,
        loading: NotificationsListReducer.loading,
        allNotificationsFetched: NotificationsListReducer.allNotificationsFetched,
        notificationsLoading: NotificationsListReducer.notificationsLoading,
    };
};

export { notificationsListSaga, notificationsListReducer };

const dispatchToProps = {
    getNotificationsList,
    getThisFeedsListSuccess,
    getThisFeedData,
    readThisNotification,
    markAllRead,
    incrementPageNumber,
    toggleReachedEndOfNotificationsList,
    getNotificationsListMore,
    resetPageNumber,
    pageAnalytics,
};

export default withRouter(connect(stateToProps, dispatchToProps)(NotificationCenterComponent));
