import * as TYPES from './types';

export const getInProgressPractices = page => ({
    type: `${TYPES.GET_PRACTICES_IN_PROGRESS}_PENDING`,
    page,
});
export const getCompletedPractices = page => ({
    type: `${TYPES.GET_PRACTICES_COMPLETED}_PENDING`,
    page,
});
export const getNewPractices = page => ({
    type: `${TYPES.GET_PRACTICES_NEW}_PENDING`,
    page,
});
export const storeInProgressPractices = data => ({
    type: `${TYPES.GET_PRACTICES_IN_PROGRESS}_DONE`,
    data,
});
export const storeCompletedPractices = data => ({
    type: `${TYPES.GET_PRACTICES_COMPLETED}_DONE`,
    data,
});
export const setBrowseMoreCompleted = value => ({
    type: `${TYPES.GET_PRACTICES_COMPLETED}_BROWSE_MORE`,
    value,
});

export const storeNewPractices = (data, page) => ({
    type: `${TYPES.GET_PRACTICES_NEW}_DONE`,
    data,
    page,
});
export const storeEmptyNewPractices = () => ({
    type: `${TYPES.GET_PRACTICES_NEW}_EMPTY`,
});
export const storeInProgressEmptyPractices = data => ({
    type: `${TYPES.GET_PRACTICES_IN_PROGRESS}_EMPTY`,
    data,
});
export const storeCompletedEmptyPractices = data => ({
    type: `${TYPES.GET_PRACTICES_COMPLETED}_EMPTY`,
    data,
});
export const closeLoader = () => ({
    type: TYPES.CLOSE_LOADER,
});
export const fetchbrowseMore = page => ({
    type: `${TYPES.BROWSE_ALL}_PENDING`,
    page,
});

export const browseMoreFetched = data => ({
    type: `${TYPES.BROWSE_ALL}_DONE`,
    data,
});
export const getInProgressNewPractices = page => ({
    type: `${TYPES.GET_PRACTICES_INPROGRESS_NEW}_PENDING`,
    page,
});
