import React from 'react';
import PropTypes from 'prop-types';

export default function ProfilePic({ profilePic }) {
    return (
        <div className="commenter_image">
            <svg width="0" height="0" viewBox="0 0 34 35">
                <clipPath id="comment_bubble">
                    <path
                        transform="translate(1 1)"
                        id="prefix__a"
                        d="M9.481.976C3.65 3.796-1.709 12.622.514 21.058c1.794 6.8 16.926 17.357 25.331 8.021 12.12-13.46 3.363-20.606-1.094-24.26C21.402 2.076 15.312-1.844 9.48.977z"
                    />
                </clipPath>
            </svg>
            <img src={profilePic} alt="commentor" />
        </div>
    );
}

ProfilePic.propTypes = {
    profilePic: PropTypes.string.isRequired,
};
