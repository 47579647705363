import { call, put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { storeFeedbackQuestion } from './actions';
import {
    getFeedbackQuestionApi,
    postFeedbackQuestionAnswerApi,
    getFeedbackQuestionFromEmailApi,
    postFeedbackQuestionAnswerFromEmailApi,
} from './apis';
import { apiFailed } from '../../../common/actions';
import { getOpenLinkTranslationsSaga } from '../../../common/sagas';
import Endpoints from '../../../Endpoints';

function* getFeedbackQuestionSaga({ token }) {
    try {
        const { data: { data } } = yield call(getFeedbackQuestionApi, token);
        yield put(storeFeedbackQuestion(data));
    } catch (e) {
        yield put(storeFeedbackQuestion([]));
    }
}

function* postFeedbackQuestionSaga({ payload, token }) {
    try {
        yield call(postFeedbackQuestionAnswerApi, payload, token);
    } catch (e) {
        yield put(apiFailed([]));
    }
}

function* getFeedbackQuestionFromEmailSaga({ baseurl, email }) {
    try {
        const { emailFeedback } = Endpoints;
        const url = baseurl.split(emailFeedback)[0];
        yield getOpenLinkTranslationsSaga({ baseUrl: url, email });
        const { data: { data } } = yield call(getFeedbackQuestionFromEmailApi, baseurl, email);
        yield put(storeFeedbackQuestion(data));
    } catch (e) {
        yield put(storeFeedbackQuestion([]));
    }
}

function* postFeedbackQuestionFromEmailSaga({ baseurl, email, payload }) {
    try {
        yield call(postFeedbackQuestionAnswerFromEmailApi, baseurl, email, payload);
    } catch (e) {
        yield put(apiFailed([]));
    }
}

const FeedbackQuestionSaga = [
    takeLatest(`${types.GET_FEEDBACK_QUESTION}_PENDING`, getFeedbackQuestionSaga),
    takeLatest(`${types.POST_FEEDBACK_QUESTION_ANSWER}_PENDING`, postFeedbackQuestionSaga),
    takeLatest(`${types.GET_FEEDBACK_QUESTION_FROM_EMAIL}_PENDING`, getFeedbackQuestionFromEmailSaga),
    takeLatest(`${types.POST_FEEDBACK_QUESTION_ANSWER_FROM_EMAIL}_PENDING`, postFeedbackQuestionFromEmailSaga),
];

export default FeedbackQuestionSaga;
