import createReducer from '../../../utils/redux-utils/createReducer'
import * as types from './types'

const INITIAL_STATE = {
    isLoading: false,
    practiceDetailsData: null,
    promptDetails: null,
    isSubPromptsLoading: false,
}

const PracticeDetailReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_PRACTICE_DETAILS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
    }),
    [`${types.GET_PRACTICE_DETAILS}_DONE`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        practiceDetailsData: data.data,
    }),
    [`${types.GET_PROMPT_DETAILS}_PENDING`]: state => ({
        ...state,
        isLoading: true,
        //promptDetails: null,
    }),
    [`${types.GET_PROMPT_DETAILS}_DONE`]: (state, { data }) => ({
        ...state,
        isLoading: false,
        promptDetails: data,
    }),
    [types.RESET_PROMPT_DATA]: state => ({
        ...state,
        promptDetails: null,
    }),
    [`${types.GET_SUBPROMPT_DETAILS}_PENDING`]: state => ({
        ...state,
        isSubPromptsLoading: true,
    }),
    [`${types.GET_SUBPROMPT_DETAILS}_DONE`]: (state, { data }) => ({
        ...state,
        isSubPromptsLoading: false,
        subPromptDetails: data,
    }),
    [types.PROTAG_VIEWED]: state => ({
        ...state,
        practiceDetailsData: {
            ...state.practiceDetailsData,
            protag_viewed: true,
        },
    }),
})

export default PracticeDetailReducer
