/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import Lottie from '../../components/Lottie';
import PromptDetails, { PracticeStateType } from './PromptDetails';
import './index.scss';
import SvgPicture from '../../components/SvgPicture';
import ProtagAnimation from './ProtagAnimation';
import { extractJsonFromUrl } from '../UnAuthenticatedContent/apis';
import DynamicCardMapper from '../../components/DynamicCard/DynamicCardMapper';
import AddNewPost from '../Feeds/AddNewPost';
import { updateCardStatus } from '../HomeContainer/redux/apis';
import LocalisationsStore from '../../common/LocalisationsStore';
import SubPromptsContainer from './SubPromptsContainer';
import { pageAnalytics, nullable } from '../../utils/functions';

export default function PracticesDetailsContainer({
    getPracticeDetails,
    isLoading,
    practiceDetailsData,
    uploadPost,
    closeCard,
    getTags,
    uploadingPost,
    tags,
    storeProTagViewed,
    history,
    clientExpired,
    storeInPracticeDetails,
}) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { complete, practices } = localisationsAfterLogin;
    const params = useParams();

    const [showProTagAnimation, setShowProTagAnimation] = useState(false);
    const [protaganim, setProtagAnimData] = useState(null);
    const [practiceImageData, setPracticeImageData] = useState(null);
    const [isDataFetched, setDataFetched] = useState(false);
    const [progressAnim, setProgressAnim] = useState(false);
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 740));

    useEffect(() => {
        const handleCheckMobile = () => {
            if (window.innerWidth <= 740) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleCheckMobile);

        return (() => window.addEventListener('resize', handleCheckMobile));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        pageAnalytics.initialise(`practiceDetail/${params.id}`);
        return () => {
            pageAnalytics.complete(`practiceDetail/${params.id}`);
        };
    }, [params.id]);

    useEffect(() => {
        getPracticeDetails(params.id);

        return (() => {
            storeInPracticeDetails({ data: null });
        });
    }, [getPracticeDetails, storeInPracticeDetails, params.id]);

    useEffect(() => {
        getTags();
    }, [getTags]);

    useEffect(() => {
        if (practiceDetailsData) {
            setPracticeImageData(!isMobile ? practiceDetailsData.practice_image_web : practiceDetailsData.practice_image_mobile);
        }
        // eslint-disable-next-line
    }, [isMobile]);

    useEffect(() => {
        if (practiceDetailsData && practiceDetailsData.id === +params.id) {
            if (practiceDetailsData.id === +params.id) setDataFetched(true);
            setPracticeImageData(!isMobile ? practiceDetailsData.practice_image_web : practiceDetailsData.practice_image_mobile);
            if (practiceDetailsData.protag_anim) {
                extractJsonFromUrl(practiceDetailsData.protag_anim).then(res => {
                    setProgressAnim(res.data);
                });
            }

            if (!practiceDetailsData.protag_viewed && practiceDetailsData.protag_id) {
                extractJsonFromUrl(practiceDetailsData.protag_anim)
                    .then(response => {
                        setShowProTagAnimation(true);
                        setProtagAnimData(response.data);
                        return extractJsonFromUrl(!isMobile ? practiceDetailsData.practice_image_web : practiceDetailsData.practice_image_mobile);
                    })
                    .then(response => {
                        setPracticeImageData(response.data);
                    });
            }
        }
        // eslint-disable-next-line
    }, [practiceDetailsData, params.id]);

    const handleCrossClick = () => {
        setShowProTagAnimation(false);
    };

    const getImageOrAnimation = () => {
        if (practiceImageData) {
            return (
                <img
                    src={practiceImageData}
                    alt=""
                />
            );
        }
        return (
            <Lottie
                className="outer_animation_container"
                borderRadius={20}
                options={{
                    loop: false,
                    autoplay: true,
                    animationData: practiceImageData,
                }}
            />
        );
    };

    const closeCallback = cardId => {
        closeCard(cardId);
    };
    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };
    const refreshPage = id => {
        getPracticeDetails(id);
    };
    return (
        <div className="practice_details_container">
            {clientExpired && moveToClientExpirePage()}
            {showProTagAnimation && practiceDetailsData && (
                <ProtagAnimation
                    practiceDetailsData={practiceDetailsData}
                    handleCrossClick={handleCrossClick}
                    protaganim={protaganim}
                    storeProTagViewed={storeProTagViewed}
                />
            )}
            {isLoading && <Loader position="fixed" showBackground />}
            {isDataFetched && (
                <>
                    {!isMobile ? <div className="animation_wrapper">{getImageOrAnimation()}</div> : null}
                    <div className="practice-content">
                        <div className="right_panel_details">
                            {isMobile ? (
                                <div className="practice-heading">{practices}</div>
                            ) : null }
                            {progressAnim && practiceDetailsData.progress_img && [
                                <div className="middle_pro_tag_wrapper" key="middle_pro_tag_wrapper">
                                    {localStorage.getItem('client_config') === 'pg' ? (
                                        <div className="practice_detail">
                                            <div className="anim_wrapper">
                                                <Lottie
                                                    className="outer_animation_container"
                                                    borderRadius={20}
                                                    options={{
                                                        loop: false,
                                                        autoplay: true,
                                                        animationData: progressAnim,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="practice_detail">
                                            {practiceDetailsData.completed_percentage !== '100' && (
                                            <div className="percenntage">
                                                <Text
                                                    fontsize="4rem"
                                                    fontcolor={fontColors.textBlack}
                                                    fontfamily={fontFamilies.playfairBold}
                                                    margin="0 0.5rem 0 0"
                                                >
                                                    {practiceDetailsData.completed_percentage}
                                                </Text>
                                                <div className="right">
                                                    <Text
                                                        fontsize="1rem"
                                                        fontcolor={fontColors.textBlack}
                                                        fontfamily={fontFamilies.playfairBold}
                                                    >
                                                        %
                                                    </Text>
                                                    <Text
                                                        fontsize="1rem"
                                                        fontcolor={fontColors.siteGrey}
                                                        fontfamily={fontFamilies.TheinhardtPanRegular}
                                                    >
                                                        {complete}
                                                    </Text>
                                                </div>
                                            </div>
                                            )}
                                            <img src={practiceDetailsData.progress_img} alt="protag_img" />
                                        </div>
                                    )}
                                </div>,
                            ]}
                            <div className="upper_texts_wrapper">
                                <Text
                                    fontsize="29px"
                                    fontcolor={fontColors.black}
                                    fontfamily={fontFamilies.playfairBold}
                                    margin="0 0 0.5rem 0"
                                >
                                    {practiceDetailsData.name}
                                </Text>
                                <Text
                                    fontsize="1.125rem"
                                    fontcolor={fontColors.black}
                                    fontfamily={fontFamilies.TheinhardtPanLight}
                                    textAlign="center"
                                >
                                    {practiceDetailsData.description}
                                </Text>
                            </div>
                            <div className="divider" />
                            <div className="article_list_wrapper">
                                {practiceDetailsData?.articles
                                    && practiceDetailsData.articles.map(article => {
                                        if (article.type === 'folder') {
                                            return (
                                                <SubPromptsContainer
                                                    key={article.id}
                                                    article={article}
                                                    history={history}
                                                    practiceId={params.id}
                                                    refreshPage={refreshPage}
                                                    getPracticeDetails={getPracticeDetails}
                                                />
                                            );
                                        }
                                        if (article.show_type === 'article') {
                                            return (
                                                <ArticleThisPractice
                                                    article={article}
                                                    totalCount={practiceDetailsData.articles.length}
                                                    practiceId={params.id}
                                                    getPracticeDetails={getPracticeDetails}
                                                    uploadingPost={uploadingPost}
                                                    tags={tags}
                                                    uploadPost={uploadPost}
                                                    setShowProTagAnimation={setShowProTagAnimation}
                                                    setProtagAnimData={setProtagAnimData}
                                                    history={history}
                                                    key={article.id}
                                                />
                                            );
                                        }

                                        return new DynamicCardMapper(
                                            article.card_type,
                                            () => closeCallback(article.id),
                                            {
                                                key: article.title,
                                                bgColor: article.bg_color,
                                                title: article.title,
                                                body: article.body,
                                                thumbnail: article.thumbnail,
                                                star_streak: article.star_streak,
                                                competedPrompt: article.completed_promt,
                                                ctaExternal: article.cta_external,
                                                ctaInternal: article.cta_internal,
                                                ctaType: article.cta_type,
                                                id: article.id,
                                                cta_text: article.cta_text,
                                                updateCardStatus,
                                                showRemoveIcon: false,
                                            },
                                        ).getCard();
                                    })}
                            </div>
                        </div>
                        <div className="animation_wrapper right" />
                    </div>
                </>
            )}
        </div>
    );
}

function ArticleThisPractice({
    article,
    totalCount,
    practiceId,
    getPracticeDetails,
    uploadingPost,
    tags,
    uploadPost,
    setShowProTagAnimation,
    setProtagAnimData,
    history,
}) {
    const [showPrompt, setShowPrompt] = useState(false);
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [hashtag, setHashTag] = useState('');

    const handlePromptClick = e => {
        e.stopPropagation();
        if (article.state !== PracticeStateType.lockedOrNew) {
            setShowPrompt(true);
        }
    };

    const handleCrossClick = (e, id) => {
        e.stopPropagation();
        if (id) getPracticeDetails(id);
        setShowPrompt(false);
    };
    const _getOpacity = () => {
        if (article.state === PracticeStateType.lockedOrNew) {
            return 0.5;
        }
        return 1.0;
    };

    const handleClose = () => {
        setShowCreatePost(false);
        // setShowPrompt(false);
    };

    const _getIconColor = () => {
        let value = '';
        switch (article.state) {
            case PracticeStateType.lockedOrNew:
                value = fontColors.nuetralGrey;
                break;

            case PracticeStateType.unlockedOrNext:
                value = fontColors.nuetralGrey;
                break;

            case PracticeStateType.read:
                value = fontColors.nuetralGrey;
                break;

            case PracticeStateType.completed:
                value = fontColors.forestGreen;
                break;
            default:
                break;
        }
        return value;
    };

    const _getTitleColor = () => {
        let value = '';
        switch (article.state) {
            case PracticeStateType.lockedOrNew:
                value = fontColors.textBlack;
                break;

            case PracticeStateType.unlockedOrNext:
                value = fontColors.electricBlue;
                break;

            case PracticeStateType.read:
                value = fontColors.textBlack;
                break;

            case PracticeStateType.completed:
                value = fontColors.textBlack;
                break;
            default:
                break;
        }
        return value;
    };

    const _getFontFamily = () => {
        if ([PracticeStateType.unlockedOrNext, PracticeStateType.lockedOrNew].includes(article.state)) {
            return fontFamilies.TheinhardtPanRegular;
        }
        return fontFamilies.TheinhardtPanLight;
    };

    const TickAndTextWrapper = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 0.5rem;

        img {
            height: 1rem;
            width: 1rem;
            background-color: green;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
    `;

    return (
        <Fragment>
            <div
                className="practice_article"
                key={`p-article-${article.id}`}
                onClick={handlePromptClick}
                style={{ opacity: _getOpacity() }}
            >
                {showPrompt && (
                    <PromptDetails
                        article={article}
                        handleCrossClick={handleCrossClick}
                        practiceId={practiceId}
                        getPracticeDetails={getPracticeDetails}
                        setShowCreatePost={setShowCreatePost}
                        setShowPrompt={setShowPrompt}
                        setShowProTagAnimation={setShowProTagAnimation}
                        setProtagAnimData={setProtagAnimData}
                        hideProtagAnimation
                    />
                )}

                {article.component_name && (
                    <>
                        <TickAndTextWrapper>
                            <Text
                                fontsize="14px"
                                fontcolor={fontColors.borderGrey}
                                fontfamily={fontFamilies.TheinhardtPanMedium}
                                letterSpacing="1px"
                                textTransform="uppercase"
                                cursor="pointer"
                            >
                                {article.component_name}
                            </Text>
                            {PracticeStateType.completed === article.state && (
                                <SvgPicture
                                    height="1.5rem"
                                    width="1.5rem"
                                    style={{
                                        backgroundColor: _getIconColor(),
                                        borderRadius: '50%',
                                    }}
                                >
                                    <svg
                                        width="12"
                                        height="9"
                                        viewBox="0 0 12 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.14567 8.66667C3.98743 8.66667 3.8292 8.6047 3.70866 8.48141L0 4.68773L0.874008 3.79368L4.14567 7.14034L11.126 0L12 0.894044L4.58267 8.48141C4.46214 8.6047 4.3039 8.66667 4.14567 8.66667Z"
                                            fill="white"
                                        />
                                    </svg>
                                </SvgPicture>
                            )}
                            {article.in_todo && (
                                <SvgPicture height="1.5rem" width="1.5rem">
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.731 8.86551C17.731 3.98 13.751 0 8.86551 0C3.98 0 0 3.98 0 8.86551C0 10.8871 0.652805 12.7823 1.89524 14.3617C2.21111 14.7618 2.80074 14.825 3.20085 14.5091C3.60096 14.1932 3.66413 13.6036 3.34826 13.2035C2.35852 11.9611 1.85312 10.4659 1.85312 8.86551C1.85312 5.01186 4.9908 1.87418 8.84445 1.87418C12.6981 1.87418 15.8358 5.01186 15.8358 8.86551C15.8358 12.6349 12.8455 15.6884 9.13927 15.8568V14.3196C9.13927 13.751 8.50752 13.393 8.02318 13.7089L4.2327 16.1938C3.79048 16.4886 3.79048 17.1203 4.2327 17.3941L8.04424 19.879C8.52858 20.1948 9.16033 19.8579 9.16033 19.2683V17.71C13.9195 17.5626 17.731 13.6457 17.731 8.86551Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M8.86553 3.91687C8.36013 3.91687 7.93896 4.33803 7.93896 4.84343V8.86555C7.93896 9.22354 8.14955 9.53941 8.46542 9.70788L11.224 11.0767C11.3504 11.1398 11.4978 11.182 11.6452 11.182C11.9821 11.182 12.3191 10.9924 12.4875 10.6555C12.7192 10.1922 12.5297 9.62365 12.0664 9.41307L9.8342 8.29698V4.84343C9.79209 4.33803 9.37092 3.91687 8.86553 3.91687Z"
                                            fill="#999999"
                                        />
                                    </svg>
                                </SvgPicture>
                            )}
                        </TickAndTextWrapper>
                        <Text
                            fontsize="18px"
                            fontcolor={_getTitleColor()}
                            fontfamily={_getFontFamily()}
                            cursor="pointer"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            display="block"
                            width="350px"
                            textOverflow="ellipsis"
                            margin="10px 0 0 0"
                        >
                            {article.title}
                        </Text>
                    </>
                )}

                {!article.component_name && (
                    <TickAndTextWrapper>
                        <Text
                            fontsize="18px"
                            fontcolor={_getTitleColor()}
                            fontfamily={_getFontFamily()}
                            cursor="pointer"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            display="block"
                            width="350px"
                            textOverflow="ellipsis"
                        >
                            {article.title}
                        </Text>
                        {PracticeStateType.completed === article.state && (
                            <SvgPicture
                                height="1.5rem"
                                width="1.5rem"
                                style={{
                                    backgroundColor: _getIconColor(),
                                    borderRadius: '50%',
                                }}
                            >
                                <svg
                                    width="12"
                                    height="9"
                                    viewBox="0 0 12 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4.14567 8.66667C3.98743 8.66667 3.8292 8.6047 3.70866 8.48141L0 4.68773L0.874008 3.79368L4.14567 7.14034L11.126 0L12 0.894044L4.58267 8.48141C4.46214 8.6047 4.3039 8.66667 4.14567 8.66667Z"
                                        fill="white"
                                    />
                                </svg>
                            </SvgPicture>
                        )}
                        {article.in_todo && (
                            <SvgPicture height="1.5rem" width="1.5rem">
                                <svg
                                    width="18"
                                    height="20"
                                    viewBox="0 0 18 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.731 8.86551C17.731 3.98 13.751 0 8.86551 0C3.98 0 0 3.98 0 8.86551C0 10.8871 0.652805 12.7823 1.89524 14.3617C2.21111 14.7618 2.80074 14.825 3.20085 14.5091C3.60096 14.1932 3.66413 13.6036 3.34826 13.2035C2.35852 11.9611 1.85312 10.4659 1.85312 8.86551C1.85312 5.01186 4.9908 1.87418 8.84445 1.87418C12.6981 1.87418 15.8358 5.01186 15.8358 8.86551C15.8358 12.6349 12.8455 15.6884 9.13927 15.8568V14.3196C9.13927 13.751 8.50752 13.393 8.02318 13.7089L4.2327 16.1938C3.79048 16.4886 3.79048 17.1203 4.2327 17.3941L8.04424 19.879C8.52858 20.1948 9.16033 19.8579 9.16033 19.2683V17.71C13.9195 17.5626 17.731 13.6457 17.731 8.86551Z"
                                        fill="#999999"
                                    />
                                    <path
                                        d="M8.86553 3.91687C8.36013 3.91687 7.93896 4.33803 7.93896 4.84343V8.86555C7.93896 9.22354 8.14955 9.53941 8.46542 9.70788L11.224 11.0767C11.3504 11.1398 11.4978 11.182 11.6452 11.182C11.9821 11.182 12.3191 10.9924 12.4875 10.6555C12.7192 10.1922 12.5297 9.62365 12.0664 9.41307L9.8342 8.29698V4.84343C9.79209 4.33803 9.37092 3.91687 8.86553 3.91687Z"
                                        fill="#999999"
                                    />
                                </svg>
                            </SvgPicture>
                        )}
                    </TickAndTextWrapper>
                )}
            </div>
            {showCreatePost && (
                <AddNewPost
                    handleClose={handleClose}
                    imageUploaderModal={showCreatePost}
                    uploadPost={uploadPost}
                    tags={tags}
                    uploadingPost={uploadingPost}
                    selectedKey={1}
                    selectedHashTag={hashtag}
                    setSelectedHashTag={setHashTag}
                    setShowCreatePost={setShowCreatePost}
                    history={history}
                />
            )}
        </Fragment>
    );
}

const Article = {
    created_date: PropTypes.string,
    id: PropTypes.number,
    practice_sequence: PropTypes.number,
    show_type: PropTypes.string,
    state: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
};

ArticleThisPractice.defaultProps = {
    uploadingPost: false,
};

ArticleThisPractice.propTypes = {
    article: PropTypes.shape(Article).isRequired,
    totalCount: PropTypes.number.isRequired,
    practiceId: PropTypes.string.isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool,
    tags: PropTypes.shape({}).isRequired,
    uploadPost: PropTypes.func.isRequired,
    setShowProTagAnimation: PropTypes.func.isRequired,
    setProtagAnimData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

PracticesDetailsContainer.defaultProps = {
    practiceDetailsData: {},
    uploadingPost: false,
};
PracticesDetailsContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    practiceDetailsData: PropTypes.shape({
        articles: PropTypes.arrayOf(PropTypes.shape(Article)),
        bg_color: PropTypes.string,
        completed_percentage: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        practice_image: PropTypes.string,
        practice_image_web: PropTypes.string,
        practice_image_mobile: PropTypes.string,
        progress_img: PropTypes.string,
        protag: null,
        protag_anim: PropTypes.string,
        protag_id: null,
        protag_img: PropTypes.string,
        protag_subtitle: PropTypes.string,
        protag_title: PropTypes.string,
        protag_viewed: PropTypes.bool,
    }),
    uploadPost: PropTypes.func.isRequired,
    closeCard: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool,
    tags: nullable(PropTypes.shape({})).isRequired,
    storeProTagViewed: PropTypes.func.isRequired,
    storeInPracticeDetails: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    clientExpired: PropTypes.bool.isRequired,
};
