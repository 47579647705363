import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import Goals from './Goals';
import GoalDetails from './GoalDetails';
import GoalsModal from './GoalsModal';
import LocalisationsStore from '../../../common/LocalisationsStore';

const SetGoals = ({ getVcrbData, vcrbDetails, isLoading, getNotes, addNotes, notes, getGoalScreen, className }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { actiondone } = localisationsAfterLogin;

    const [goalType, setGoalType] = useState('');
    const getGoalType = type => {
        if (type === 'role' || type === 'behaviour') {
            getNotes(type);
        }
        setGoalType(type);
        getGoalScreen(type);
    };
    const doneClick = () => {
        setGoalType('');
        getGoalScreen('');
    };

    const goalScreen = () => {
        if (goalType !== '' && !className) {
            return (
                <GoalDetails
                    goalType={goalType}
                    vcrbDetails={vcrbDetails}
                    addNotes={addNotes}
                    notes={notes}
                    isLoading={isLoading}
                />
            );
        }
        if (!className) {
            return (
                <Goals
                    vcrbDetails={vcrbDetails}
                    getVcrbData={getVcrbData}
                    isLoading={isLoading}
                    getGoalType={getGoalType}
                    addNotes={addNotes}
                    getNotes={getNotes}
                    notes={notes}
                />
            );
        }
        if (goalType === '' && className) {
            return (
                <Goals
                    vcrbDetails={vcrbDetails}
                    getVcrbData={getVcrbData}
                    isLoading={isLoading}
                    getGoalType={getGoalType}
                    addNotes={addNotes}
                    getNotes={getNotes}
                    notes={notes}
                />
            );
        }
        return (
            <GoalsModal
                goalType={goalType}
                vcrbDetails={vcrbDetails}
                addNotes={addNotes}
                notes={notes}
                isLoading={isLoading}
                open={goalType !== '' && className}
                doneClick={doneClick}
            />
        );
    };

    return [
        <div className={`setGoalsSection ${className}`}>
            {goalScreen()}
            {goalType && !className && (
                <div className="doneBtn">
                    <div className="button" onClick={() => doneClick()} onKeyDown={() => {}} role="button" tabIndex={0}>
                        {actiondone}
                    </div>
                </div>
            )}
        </div>,
    ];
};

SetGoals.defaultProps = {
    getGoalType: () => {},
    className: '',
};

SetGoals.propTypes = {
    getVcrbData: PropTypes.func.isRequired,
    vcrbDetails: PropTypes.arrayOf({
        detail: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        inner_subheading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    getNotes: PropTypes.func.isRequired,
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    getGoalType: PropTypes.func,
    getGoalScreen: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default SetGoals;
