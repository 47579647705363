import { put, takeLatest } from 'redux-saga/effects';
import * as types from './types';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { apiFailed, clientExpiredAction, resetClientExpiredAction } from '../../../common/actions';
import { getPracticeDetailsSagaApi, getPromptDetailsApi } from './apis';
import { storeInPracticeDetails, storeInPromptDetails, storeInSubPromptDetails } from './actions';
import { CODE_CLIENT_EXPIRED } from '../../../utils/constants';


function* getPracticeDetailsSaga({ id }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPracticeDetailsSagaApi,
            payload: [AuthStore.accessToken, id],
        });
        yield put(resetClientExpiredAction());
        yield put(storeInPracticeDetails(data));
    } catch (e) {
        console.log(e.response.status);
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getPromptDetailsSaga({ id }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPromptDetailsApi,
            payload: [AuthStore.accessToken, id],
        });
        yield put(resetClientExpiredAction());
        yield put(storeInPromptDetails(data.data));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}

function* getSubPromptDetailsSaga({ id }) {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getPromptDetailsApi,
            payload: [AuthStore.accessToken, id],
        });
        yield put(storeInSubPromptDetails(data.data));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed());
        }
    }
}
const PracticeDetailSaga = [
    takeLatest(`${types.GET_PRACTICE_DETAILS}_PENDING`, getPracticeDetailsSaga),
    takeLatest(`${types.GET_PROMPT_DETAILS}_PENDING`, getPromptDetailsSaga),
    takeLatest(`${types.GET_SUBPROMPT_DETAILS}_PENDING`, getSubPromptDetailsSaga),
];

export default PracticeDetailSaga;
