import React, { useState } from 'react'
import QuizResult from '../QuizResult'
import PromptPopUp from '../PromptPopUp'
const PopUpStack = ({ popUpData, showNextPopUp, handleCrossClick, goBack }) => {
    const popUpViews = Object.keys(popUpData)
    const [view, setView] = useState(0)
    const handleAction = event => {
        if (view < popUpViews.length - 1) {
            setView(view + 1)
        } else {
            goBack(event)
        }
    }
    const getLayout = _view => {
        switch (_view) {
            case 'quiz':
                return (
                    <QuizResult
                        data={popUpData[_view]}
                        handleAction={event => {
                            handleAction(event)
                        }}
                        handleCrossClick={handleCrossClick}
                    />
                )

            case 'prompt':
                return (
                    <PromptPopUp
                        data={popUpData[_view]}
                        handleAction={event => {
                            handleAction(event)
                        }}
                        handleCrossClick={handleCrossClick}
                    />
                )
            default:
                return null
        }
    }
    return getLayout(popUpViews[view])
}

export default PopUpStack
