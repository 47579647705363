import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/Text';
import SvgPicture from '../../../components/SvgPicture';
import { ReactComponent as ActionCompletedIcon } from '../../../assets/action-completed.svg';
import actionCompleteRatioIcon from '../../../assets/action-completion-ratio.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { fontFamilies, fontColors } from '../../../common/fontMap';
import './index.scss';

const UserActionsProgress = ({ data }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { action_completed, completed_, remaining_ } = localisationsAfterLogin;

    if (!data || Object.keys(data).length === 0) return null;

    return (
        <div className="action-completed-container">
            <div className="progress-subheading-container">
                <Text
                    fontfamily={fontFamilies.MckinseySansMedium}
                    fontcolor={fontColors.black200}
                    fontsize="16px"
                    lineHeight="16px"
                    fontweight={500}
                >
                    {action_completed}
                </Text>
                <SvgPicture className="action-completed-icon" height="auto" width="auto">
                    <ActionCompletedIcon />
                </SvgPicture>
            </div>
            <div className="action-completed-card-container">
                <div className="main-content-container">
                    <Text
                        fontsize="45px"
                        fontcolor={fontColors.black}
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontweight={400}
                        lineHeight="34px"
                        letterSpacing="0.2px"
                    >
                        {data.completed}
                        /
                        {data.total}
                    </Text>
                    <img src={actionCompleteRatioIcon} alt="action-completed-ratio" />
                </div>
                <div className="completion-ratio-text">
                    <Text
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontcolor={fontColors.black}
                        fontsize="20px"
                        lineHeight="20px"
                        fontweight={400}
                    >
                        {data.completed}
                        &nbsp;
                        <Text
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontcolor="#656565"
                            fontsize="14px"
                            lineHeight="15px"
                            fontweight={400}
                            margin="3.4px 0 0 0"
                        >
                            {completed_}
                        </Text>
                    </Text>
                    <div className="vertical-divider" />
                    <Text
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontcolor={fontColors.black}
                        fontsize="20px"
                        lineHeight="20px"
                        fontweight={400}
                    >
                        {data.total - data.completed}
                        &nbsp;
                        <Text
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontcolor="#656565"
                            fontsize="14px"
                            lineHeight="15px"
                            fontweight={400}
                            margin="3.4px 0 0 0"
                        >
                            {remaining_}
                        </Text>
                    </Text>
                </div>
            </div>
        </div>
    );
};

UserActionsProgress.defaultProps = {
    data: null,
};

UserActionsProgress.propTypes = {
    data: PropTypes.shape({
        percentage: PropTypes.number,
        completed: PropTypes.number,
        total: PropTypes.number,
    }),
};

export default UserActionsProgress;
