import types from './types';

export const getFaqContent = (baseurl, email) => ({
    type: `${types.GET_FAQ}_PENDING`,
    baseurl,
    email,
});

export const storeFaqs = data => ({
    type: `${types.GET_FAQ}_DONE`,
    data,
});

export const getFaqContentFromToken = token => ({
    type: `${types.GET_FAQ_FROM_TOKEN}_PENDING`,
    token,
});
