import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import Lottie from '../../components/Lottie';
import FeedHeader from '../Feeds/FeedHeader';
import AddNewPost from '../Feeds/AddNewPost';
import LikeSection from '../Feeds/LikeSection';
import groupIcon from './assets/groupIcon.svg';
import QuizModal from './QuizModal';
import InspireLoader from '../../components/InspireLoader/InspireLoader';
import backBlackIcon from './assets/backBlackIcon.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import BadgeReceived from '../Feeds/BadgeReceived';
import MyProgress from '../MyProgress';
import PreferencesModal from '../PreferencesModal';
import quizIconReversed from '../../assets/quizIconReversed.svg';
import StickyQuizResponseModal from './StickyQuizResponseModal';

export default function FeedDetails(props) {
    const {
        isLoading,
        toggleCreatePostModal,
        imageUploaderModal,
        uploadPost,
        uploadingPost,
        tags,
        getTags,
        getThisFeedData,
        description,
        readTime,
        postDate,
        title,
        subTitle,
        postLike,
        likedId,
        thisFeedData,
        likeDetails,
        getLikeDetails,
        followedId,
        postFollowUser,
        quizData,
        postQuizAnswer,
        postQuizSuccess,
        showShareSuccess,
        history,
        resetPostQuizData,
        postUnfollowUser,
        getUsersFollowed,
        followedUsers,
        setFollowUnfollowInFeeds,
        logoutUser,
        postIsActionableNotes,
        earnedBannerBadge,
        getUserProgressData,
        storeTeamMembersList,
        getTeamMembersList,
        getUserData,
        getThisFeedsListSuccess,
        emptyNotificationList,
        resetPageNumber,
        storeFeedFilters,
        stickyQuizResponse,
    } = props;

    const { match: { params: { id } } } = props;
    const getTagsCall = useCallback(getTags, []);
    const getFeedsListCall = useCallback(getThisFeedData, []);
    const { localisationsAfterLogin } = LocalisationsStore;
    const { testYourKnowledge, letsPutYour, addaction, actiondone, minRead, quiz, begin } = localisationsAfterLogin;
    const [openQuizModal, toggleQuizModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [notesList, setNotesList] = useState([]);
    const [showPreferences, setShowPreferencesModal] = useState(false);
    const [openResultModal, setOpenResultModal] = useState(false);
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [lastStoredAnswer, setLastStoredAnswer] = useState('');
    const [selectedStickyQuizResponse, setSelectedStickyQuizResponse] = useState('');
    const [showStickyQuizResponseModal, setShowStickyQuizResponseModal] = useState(false);

    const isMobile = window.innerWidth <= 740;

    const reverseDirectionLanguage = localStorage.getItem('languageIsRightToLeft') === 'true';

    useEffect(() => {
        getFeedsListCall(id);
        getUserData();
        sessionStorage.setItem('interventionId', id);
    }, [getFeedsListCall, id, getUserData]);

    useEffect(() => {
        getTagsCall(history);
    }, [getTagsCall, history]);

    useEffect(() => {
        if (thisFeedData && thisFeedData.notes_list && thisFeedData.notes_list.length > 0) {
            setNotesList(thisFeedData.notes_list);
        }
    }, [thisFeedData]);

    useEffect(
        () => () => {
            getThisFeedsListSuccess(null);
        },
        [getThisFeedsListSuccess],
    );

    useEffect(() => {
        if (quizData) {
            setLastStoredAnswer(quizData.last_answer);
        }
    }, [quizData]);

    function handleOpen() {
        toggleCreatePostModal(true);
    }

    function handleClose() {
        toggleCreatePostModal(false);
    }

    function handleQuizModalClose() {
        toggleQuizModal(false);
    }

    const uploadScreenshot = () => {
        const capturedCanvas = document.getElementById('result');
        if (capturedCanvas) {
            setLoading(true);
            html2canvas(capturedCanvas, {
                scrollX: 0,
                scrollY: 0,
            }).then(canvas => {
                setLoading(false);
                localStorage.setItem(
                    'canvasImage',
                    canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
                );
                uploadPost(
                    {
                        file: localStorage.canvasImage,
                        fileType: 'image',
                        desc: '',
                        content: '',
                        categoryTags: [],
                        practiceTags: [],
                        users_list: [],
                        social_config: null,
                        type: '',
                    },
                    1,
                );
                handleQuizModalClose();
                setOpenResultModal(false);
                resetPostQuizData();
                setCheckedOptions([]);
                localStorage.setItem('selectedMenu', 1);
                localStorage.setItem('quizResponseShared', true);
            });
        }
    };

    const handleClickOnBegin = () => {
        toggleQuizModal(true);
    };

    const handleClickOnBack = () => {
        history.push('/app');
    };

    const changeNotes = e => {
        setNotes(e.target.value);
    };

    const postIsActionable = () => {
        postIsActionableNotes(thisFeedData.id, notes);
        const notesListCopy = [...notesList];
        notesListCopy.splice(0, 1, {
            id: '',
            desc: notes,
        });
        setNotesList(notesListCopy);
        setNotes('');
    };

    const descriptionClicked = e => {
        e.preventDefault();
        if (e.target.tagName.toLowerCase() === 'a') {
            switch (e.target.pathname) {
                case '/createPost': {
                    handleOpen();
                    break;
                }
                default: {
                    window.open(`${e.target.href}`, '_blank');
                    break;
                }
            }
        }
        return false;
    };

    const showTeamMembers = () => {
        getTeamMembersList();
    };

    const handleStickyQuizSelection = e => {
        setLastStoredAnswer('');
        setSelectedStickyQuizResponse(e.currentTarget.dataset.value);
        const isStickyQuiz = true;
        const payload = {
            quiz_id: quizData.id,
            response: e.currentTarget.dataset.value,
        };
        postQuizAnswer(payload, isStickyQuiz);
        setShowStickyQuizResponseModal(true);
    };

    const closeStickyResponsePopUp = () => {
        setShowStickyQuizResponseModal(false);
    };

    return (
        <div className="feedsWrapper" style={{ backgroundColor: 'white' }}>
            {(loading || uploadingPost) && <InspireLoader isLoading={loading || uploadingPost} />}
            {showStickyQuizResponseModal && (
                <StickyQuizResponseModal
                    stickyQuizResponse={stickyQuizResponse}
                    showStickyQuizResponseModal={showStickyQuizResponseModal}
                    closeStickyResponsePopUp={closeStickyResponsePopUp}
                />
            )}
            {!isMobile && (
                <FeedHeader
                    handleOpen={handleOpen}
                    history={history}
                    logoutUser={logoutUser}
                    getUserProgressData={getUserProgressData}
                    showTeamMembers={showTeamMembers}
                    storeTeamMembersList={storeTeamMembersList}
                    setShowPreferencesModal={setShowPreferencesModal}
                    emptyNotificationList={emptyNotificationList}
                    resetPageNumber={resetPageNumber}
                    storeFeedFilters={storeFeedFilters}
                />
            )}
            <BadgeReceived earnedBannerBadge={earnedBannerBadge} />
            <PreferencesModal showPreferences={showPreferences} setShowPreferencesModal={setShowPreferencesModal} />
            <MyProgress />
            <AddNewPost
                handleClose={handleClose}
                imageUploaderModal={imageUploaderModal}
                uploadPost={uploadPost}
                tags={tags}
                uploadingPost={uploadingPost}
                history={history}
            />
            {quizData && (
                <QuizModal
                    openQuizModal={openQuizModal}
                    handleQuizModalClose={handleQuizModalClose}
                    quizData={quizData}
                    postQuizAnswer={postQuizAnswer}
                    postQuizSuccess={postQuizSuccess}
                    showShareSuccess={showShareSuccess}
                    uploadScreenshot={uploadScreenshot}
                    resetPostQuizData={resetPostQuizData}
                    openResultModal={openResultModal}
                    setOpenResultModal={setOpenResultModal}
                    checkedOptions={checkedOptions}
                    setCheckedOptions={setCheckedOptions}
                />
            )}
            <div className="intervention_details_container">
                <img className="back_icon_mobile" src={backBlackIcon} alt="" onClick={handleClickOnBack} />
                <div className="intervention_details">
                    {thisFeedData && thisFeedData.file && thisFeedData.file.OPEN && (
                        <div>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: thisFeedData.file.OPEN,
                                }}
                                height="auto"
                                width="100vw"
                            />
                        </div>
                    )}
                    {thisFeedData && thisFeedData.file && thisFeedData.file.OPEN === undefined && (
                        <div>
                            <img src={thisFeedData.file} alt="" />
                        </div>
                    )}
                    <div
                        className={reverseDirectionLanguage ? 'description_area reverse_direction' : 'description_area'}
                    >
                        <div className="description_area_container_mobile">
                            <div className="read_time">
                                <span>{`${readTime} ${minRead} `}</span>
                                <span> | </span>
                                <span>{postDate}</span>
                            </div>
                            <div className="title">{title}</div>
                            <div className="subtitle">{subTitle}</div>
                            <div
                                onClick={descriptionClicked}
                                className="description"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                            {quizData && quizData.quizoption_set.length && !quizData.is_sticky_footer && (
                                <div className="quiz_div">
                                    <div className="quiz_icon_and_desc">
                                        <img
                                            className="quiz_div_icon"
                                            src={reverseDirectionLanguage ? quizIconReversed : groupIcon}
                                            alt="groupIcon"
                                        />
                                        <div className="text_description">
                                            <div className="heading_label">
                                                <span className="quiz_label">{quiz}</span>
                                                <span className="test_your_knowledge_label">{testYourKnowledge}</span>
                                            </div>
                                            <span className="memory_label">{letsPutYour}</span>
                                        </div>
                                    </div>
                                    <div className="beginBtn" onClick={handleClickOnBegin} role="none">
                                        <div className="begin_label">
                                            <span>{begin}</span>
                                        </div>
                                        <div
                                            className={
                                                reverseDirectionLanguage
                                                    ? 'oval_container reversedLanguage'
                                                    : 'oval_container'
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {notesList.length > 0 && (
                            <div className="older_posted_notes">
                                <ul>
                                    {notesList.map(ntes => (
                                        <li key={`${ntes.id}-ntes`}>{ntes.desc}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {thisFeedData && thisFeedData.isActionable && (
                            <div className="is_actionable">
                                <textarea onChange={changeNotes} value={notes} placeholder={addaction} />
                                {notes.length > 0 && (
                                    <button type="submit" onClick={postIsActionable}>
                                        {actiondone}
                                    </button>
                                )}
                            </div>
                        )}
                        {quizData && quizData.is_sticky_footer && (
                            <div className="sticky_footer">
                                <div>{quizData.question}</div>
                                <div>
                                    {quizData.quizoption_set.map(option => (
                                        <button
                                            className={
                                                option.value === selectedStickyQuizResponse
                                                || option.value === lastStoredAnswer
                                                    ? 'selected_btn outline-none'
                                                    : 'outline-none'
                                            }
                                            type="button"
                                            data-value={option.value}
                                            onClick={handleStickyQuizSelection}
                                        >
                                            {option.text}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                        {quizData && !quizData.is_sticky_footer && (
                            <>
                                <div className="separator_line" />
                                <div className="likes">
                                    {thisFeedData && thisFeedData.id && (
                                        <LikeSection
                                            postLike={postLike}
                                            feedItem={thisFeedData}
                                            likedId={likedId}
                                            isLiked={thisFeedData.is_liked}
                                            feedType="article"
                                            likeDetails={likeDetails}
                                            getLikeDetails={getLikeDetails}
                                            isLoading={isLoading}
                                            isFollowing={thisFeedData.is_following}
                                            followedId={followedId}
                                            postFollowUser={postFollowUser}
                                            postUnfollowUser={postUnfollowUser}
                                            getUsersFollowed={getUsersFollowed}
                                            followedUsers={followedUsers}
                                            setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

FeedDetails.defaultProps = {
    tags: {
        practice: [],
        category: [],
    },
    description: '',
    readTime: 0,
    postDate: '',
    title: '',
    subTitle: '',
    thisFeedData: {},
    history: {},
    quizData: {},
    earnedBannerBadge: {
        badge_id: 0,
        hasUserEarnedBadge: false,
    },
};

FeedDetails.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    uploadPost: PropTypes.func.isRequired,
    imageUploaderModal: PropTypes.bool.isRequired,
    toggleCreatePostModal: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    getThisFeedData: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    match: PropTypes.shape({
        isExact: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string,
    readTime: PropTypes.number,
    postDate: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    thisFeedData: PropTypes.object,
    postLike: PropTypes.func.isRequired,
    likedId: PropTypes.number.isRequired,
    likeDetails: PropTypes.array.isRequired,
    getLikeDetails: PropTypes.func.isRequired,
    followedId: PropTypes.number.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    quizData: PropTypes.object,
    postQuizAnswer: PropTypes.func.isRequired,
    postQuizSuccess: PropTypes.bool.isRequired,
    showShareSuccess: PropTypes.bool.isRequired,
    history: PropTypes.object,
    resetPostQuizData: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    postIsActionableNotes: PropTypes.func.isRequired,
    earnedBannerBadge: PropTypes.shape({
        badge_id: PropTypes.number.isRequired,
        hasUserEarnedBadge: PropTypes.bool.isRequired,
    }),
    getUserProgressData: PropTypes.func.isRequired,
    getTeamMembersList: PropTypes.func.isRequired,
    storeTeamMembersList: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
    getThisFeedsListSuccess: PropTypes.func.isRequired,
    emptyNotificationList: PropTypes.func.isRequired,
    resetPageNumber: PropTypes.func.isRequired,
    storeFeedFilters: PropTypes.func.isRequired,
    stickyQuizResponse: PropTypes.shape({
        response_img: PropTypes.string,
        response_title: PropTypes.string,
        response_content: PropTypes.string,
    }).isRequired,
};
