import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import LocalisationsStore from '../../../common/LocalisationsStore';
import InspireLoader from '../../../components/InspireLoader/InspireLoader';
import submitideaResponse from '../../../assets/submitIdeaResponse.svg';

import '../index.scss';

export default function SubmitIdeaComponent({
    getFeedbackQuestion,
    feedbackQuestion,
    postFeedbackQuestionAnswer,
    loading,
    getFeedbackQuestionFromEmail,
    postFeedbackQuestionAnswerFromEmail,
    mobileCheck,
}) {
    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || {
        feedbackTextAreaPlaceholder: '',
        submit: '',
        feedbackSubmittedMessage: '',
    };
    const { feedbackTextAreaPlaceholder, submit, feedbackSubmittedMessage } = localisationsAfterLogin;

    const [questionText, setQuestionText] = useState('');
    const [questionId, setQuestionId] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [baseUr, setBaseurl] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const getFeedbackQuestionCall = useCallback(getFeedbackQuestion, []);

    useEffect(() => {
        let baseUrl;
        let email;
        const urlString = window.location.href;
        const url = new URL(urlString);
        if (urlString.includes('baseurl')) {
            baseUrl = window.atob(url.searchParams.get('baseurl'));
            email = url.searchParams.get('email');
            getFeedbackQuestionFromEmail(baseUrl, email);
            setEmail(email);
            setBaseurl(baseUrl);
        } else {
            const code = url.searchParams.get('userToken');
            getFeedbackQuestionCall(code);
            setToken(code);
        }
    }, [getFeedbackQuestionCall, getFeedbackQuestionFromEmail]);

    const handleOnChange = (e, id) => {
        setQuestionText(e.target.value);
        setQuestionId(id);
    };

    const handleOnClick = async () => {
        if (questionText.length > 0) {
            const payload = [
                {
                    question_id: questionId,
                    response: questionText,
                },
            ];
            if (email === '') {
                await postFeedbackQuestionAnswer(payload, token);
            } else {
                await postFeedbackQuestionAnswerFromEmail(baseUr, email, payload);
            }
            setTimeout(() => {
                setShowMessage(true);
            }, 1000);
        }
    };

    return (
        <>
            {loading && (
                <div style={{ height: '100vh', width: '100vw' }}>
                    <InspireLoader isLoading />
                </div>
            )}

            {!loading
                && !mobileCheck
                && feedbackQuestion
                && feedbackQuestion.length > 0
                && feedbackQuestion.map((element, index) => (
                    <div className="container" key={`feedback-q-${index}`} data-test="feedback-test-attr">
                        <div className="feedsHeader">
                            <div className="logo" role="none" />
                        </div>
                        <div className="bg_blob" />
                        <div className="inner_container">
                            <div className="question_text_container">
                                {showMessage ? (
                                    <>
                                        <img src={submitideaResponse} alt="" />
                                        <div className="feedback_submitted_message">{feedbackSubmittedMessage}</div>
                                    </>
                                ) : (
                                    <>
                                        <div>{element.question}</div>
                                        <textarea
                                            onChange={event => handleOnChange(event, element.id)}
                                            placeholder={feedbackTextAreaPlaceholder}
                                        />
                                    </>
                                )}
                                {!showMessage && (
                                    <div className="btn_container">
                                        <button
                                            type="submit"
                                            onClick={handleOnClick}
                                            disabled={questionText.length < 1}
                                        >
                                            {submit}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

            {!loading
                && mobileCheck
                && feedbackQuestion
                && feedbackQuestion.length > 0
                && feedbackQuestion.map((element, index) => (
                    <div className="container" key={`feedback-q-${index}`} data-test="feedback-test-attr-mobile">
                        <div className="feedsHeader">
                            <div className="logo" role="none" />
                        </div>
                        <div className="inner_container">
                            <div className="question_text_container">
                                {showMessage ? (
                                    <>
                                        <img src={submitideaResponse} alt="" />
                                        <div className="feedback_submitted_message">{feedbackSubmittedMessage}</div>
                                    </>
                                ) : (
                                    <>
                                        <div>{element.question}</div>
                                        <textarea
                                            onChange={event => handleOnChange(event, element.id)}
                                            placeholder={feedbackTextAreaPlaceholder}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        {!showMessage && (
                            <button
                                type="submit"
                                style={{ zIndex: 2 }}
                                onClick={handleOnClick}
                                disabled={questionText.length < 1}
                            >
                                {submit}
                            </button>
                        )}
                    </div>
                ))}
        </>
    );
}

SubmitIdeaComponent.defaultProps = {
    feedbackQuestion: [],
};

SubmitIdeaComponent.propTypes = {
    getFeedbackQuestion: PropTypes.func.isRequired,
    feedbackQuestion: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            question: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    postFeedbackQuestionAnswer: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    getFeedbackQuestionFromEmail: PropTypes.func.isRequired,
    postFeedbackQuestionAnswerFromEmail: PropTypes.func.isRequired,
    mobileCheck: PropTypes.bool.isRequired,
};
