/* eslint-disable no-param-reassign */

import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    loading: false,
    notificationsLoading: false,
    notifications: [],
    pageNumber: 1,
    allNotificationsFetched: false,
};

const notificationsListReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_NOTIFICATIONS_LIST}_PENDING`]: state => ({
        ...state,
        notificationsLoading: true,
    }),
    [`${types.GET_NOTIFICATIONS_LIST}_DONE`]: (state, { data }) => ({
        ...state,
        notificationsLoading: false,
        loading: false,
        notifications: data,
    }),
    [`${types.GET_NOTIFICATIONS_LIST_MORE}_PENDING`]: state => ({
        ...state,
        loading: true,
    }),
    [`${types.GET_NOTIFICATIONS_LIST_MORE}_DONE`]: (state, { data }) => ({
        ...state,
        notificationsLoading: false,
        loading: false,
        notifications: state.notifications.concat(data),
    }),
    [types.EMPTY_NOTIFICATION_LIST]: (state, { data }) => ({
        ...state,
        notificationsLoading: false,
        notifications: data,
    }),
    [types.MARK_THIS_NOTIFICATION_AS_DELETED]: (state, { id }) => ({
        ...state,
        notifications: state.notifications.map(row => {
            if (row.notification_id === id) {
                row.isDeleted = true;
                row.is_read = true;
            }
            return row;
        }),
    }),
    [types.MARK_THIS_NOTIFICATION_AS_READ]: (state, { id }) => ({
        ...state,
        notifications: state.notifications.map(row => {
            if (row.notification_id === id) {
                row.is_read = true;
            }
            return row;
        }),
    }),
    [`${types.MARK_ALL_AS_READ}_PENDING`]: state => ({
        ...state,
        notifications: state.notifications.map(row => {
            row.is_read = true;
            return row;
        }),
    }),
    [types.INCREMENT_PAGE_NUMBER]: state => ({
        ...state,
        pageNumber: state.pageNumber + 1,
    }),
    [types.RESET_NOTIFICATIONS_PAGE_NUMBER]: state => ({
        ...state,
        pageNumber: 1,
    }),
    [types.REACHED_END_OF_NOTIFICATIONS_LIST]: (state, { value }) => ({
        ...state,
        loading: false,
        allNotificationsFetched: value,
        notificationsLoading: false,
    }),
});

export default notificationsListReducer;
