/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Frequency from '../Frequency';
import Rating from '../Rating';
import LocalisationsStore from '../../../common/LocalisationsStore';

function _renderQuestion(
    question,
    setAnswer,
    setResponsesInRange,
    openendResponses,
    setopenendResponses,
    singleSelect,
    setSingleSelect,
    selectedOps,
    setSelectedOps,
) {
    const [indices, setIndices] = useState([]);
    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || { enterText: '' };
    const { enterText } = localisationsAfterLogin;

    useEffect(() => {
        const opts = [];
        if (question.options) {
            question.options.forEach(o => {
                selectedOps.forEach(so => {
                    // eslint-disable-next-line eqeqeq
                    if (so == o.id && so == o.id) {
                        opts.push(o);
                    }
                });
            });
        }
        if (question.type === 'Multiselect_ordered') {
            if (
                question.max !== question.min
                && selectedOps.length <= question.max
                && selectedOps.length >= question.min
            ) {
                if (
                    selectedOps[selectedOps.length - 1] === question.options[question.options.length - 1].id.toString()
                ) {
                    if (
                        openendResponses[openendResponses.length - 1] === ''
                        && question.options[question.options.length - 1].isOpenTextBox
                    ) {
                        setResponsesInRange(false);
                    } else {
                        setResponsesInRange(true);
                    }
                }
            } else if (question.max === question.min) {
                if (
                    selectedOps[selectedOps.length - 1] === question.options[question.options.length - 1].id.toString()
                ) {
                    if (
                        openendResponses[openendResponses.length - 1] === ''
                        && question.options[question.options.length - 1].isOpenTextBox
                    ) {
                        setResponsesInRange(false);
                    } else {
                        setResponsesInRange(true);
                    }
                }
            } else {
                setResponsesInRange(false);
            }
        }
    }, [
        selectedOps,
        setResponsesInRange,
        question.max,
        question.min,
        openendResponses,
        question.options,
        question.type,
        question.isOpenTextBox,
    ]);

    const selectedOptions = event => {
        event.stopPropagation();
        const {
            currentTarget: { dataset: { indice } },
            target: { value, checked },
        } = event;

        let v = [];
        let ind = [];
        let openEndResponses = [];

        if (checked) {
            if (selectedOps.length === 0) {
                ind = [...indices];
                ind.push(indice);
                setIndices(ind);
                v = [...selectedOps];
                v.push(value);
                openEndResponses.push('');
                setSelectedOps(v);
                setopenendResponses(openEndResponses);
                setResponsesInRange(true);
                setAnswer(v.sort((a, b) => Number(a) - Number(b)).join());
            } else {
                // eslint-disable-next-line no-lonely-if
                if (
                    question.max !== question.min
                    && selectedOps.length < question.max
                    && selectedOps.length >= question.min
                ) {
                    ind = [...indices];
                    ind.push(indice);
                    setIndices(ind);
                    v = [...selectedOps];
                    openEndResponses = [...openendResponses];
                    v.push(value);
                    setSelectedOps(v);
                    openEndResponses.push('');
                    setopenendResponses(openEndResponses);
                    setResponsesInRange(true);
                    setAnswer(v.sort((a, b) => Number(a) - Number(b)).join());
                } else if (question.max === question.min && selectedOps.length <= question.max) {
                    ind = [...indices];
                    ind.push(indice);
                    setIndices(ind);
                    v = [...selectedOps];
                    openEndResponses = [...openendResponses];
                    v.push(value);
                    setSelectedOps(v);
                    openEndResponses.push('');
                    setopenendResponses(openEndResponses);
                    setResponsesInRange(true);
                    setAnswer(v.sort((a, b) => Number(a) - Number(b)).join());
                } else {
                    setResponsesInRange(false);
                }
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (
                question.max !== question.min
                && selectedOps.length <= question.max
                && selectedOps.length >= question.min
            ) {
                v = selectedOps.filter(a => a !== value);
                ind = indices.filter(a => a !== indice);
                openEndResponses = [...openendResponses];
                openEndResponses[selectedOps.lastIndexOf(selectedOps.find(a => a === value))] = '';
                setopenendResponses(openEndResponses);
                openEndResponses.splice(selectedOps.lastIndexOf(selectedOps.find(a => a === value)), 1);
                setopenendResponses(openEndResponses);
                setIndices(ind);
                setSelectedOps(v);
                setResponsesInRange(true);
                setAnswer(v.sort((a, b) => Number(a) - Number(b)).join());
            } else if (question.max === question.min) {
                v = selectedOps.filter(a => a !== value);
                ind = indices.filter(a => a !== indice);
                openEndResponses = [...openendResponses];
                openEndResponses[selectedOps.lastIndexOf(selectedOps.find(a => a === value))] = '';
                setopenendResponses(openEndResponses);
                openEndResponses.splice(selectedOps.lastIndexOf(selectedOps.find(a => a === value)), 1);
                setopenendResponses(openEndResponses);
                setIndices(ind);
                setSelectedOps(v);
                setResponsesInRange(true);
                setAnswer(v.sort((a, b) => Number(a) - Number(b)).join());
            } else {
                setResponsesInRange(false);
            }
        }
    };

    const addIntoOpenEndResponse = e => {
        e.stopPropagation();
        const { target: { value } } = e;
        const arr = [...openendResponses];
        const newarr = arr.map((element, i) => {
            if (i === arr.length - 1) {
                element = value;
            } else {
                element = '';
            }
            return element;
        });
        setopenendResponses(newarr);
    };

    const setSingleSelectAnswer = e => {
        const { target: { dataset: { optionvalue } } } = e;
        setSingleSelect(optionvalue);
        setAnswer(optionvalue);
    };

    const optionisDiabled = indx => {
        if (!(indices.filter(ele => Number(ele) === indx).length > 0) && selectedOps.length >= question.max) {
            return true;
        }
        return false;
    };

    const textFieldIsDisabled = option => {
        const ops = [...selectedOps];
        const opt = ops.find(o => Number(o) === option.id);
        if (!opt) {
            return true;
        }
        return false;
    };

    const { type, questionType } = question;
    if (question.isBeahviourText && type !== 'Multiselect_ordered' && !questionType.includes('_Post')) {
        return <div dangerouslySetInnerHTML={{ __html: question.behavior_text }} className="set_html_container" />;
    }
    if (question.isBeahviourText && questionType.includes('_Post')) {
        return (
            <div className="real_question_text_container">
                <div dangerouslySetInnerHTML={{ __html: question.behavior_text }} className="set_html_container" />
                <div dangerouslySetInnerHTML={{ __html: question.question_text }} />
                <Frequency setAnswer={setAnswer} />
            </div>
        );
    }
    switch (type) {
        case 'frequency': {
            return (
                <div className="real_question_text_container">
                    <div dangerouslySetInnerHTML={{ __html: question.question_text }} className="set_html_container" />
                    <Frequency setAnswer={setAnswer} />
                </div>
            );
        }
        case 'rating': {
            return (
                <div className="real_question_text_container">
                    <div dangerouslySetInnerHTML={{ __html: question.question_text }} className="set_html_container" />
                    <Rating setAnswer={setAnswer} />
                </div>
            );
        }
        case 'Multiselect_ordered': {
            return (
                <div className="real_question_text_container_multiselect">
                    <div dangerouslySetInnerHTML={{ __html: question.question_text }} />
                    <div dangerouslySetInnerHTML={{ __html: question.behavior_text }} className="set_html_container" />
                    <div className="multi_select_options_container">
                        {question.options.map((option, index) => (
                            <div className="multiselect_option" key={`multiselect-question-option-${index}`}>
                                <input
                                    data-test={`multi-select-option-${index}`}
                                    type="checkbox"
                                    id={`${option.id}_options`}
                                    data-indice={index}
                                    onChange={selectedOptions}
                                    value={option.id}
                                    checked={option.checked}
                                    disabled={optionisDiabled(index)}
                                />
                                <label htmlFor={`${option.id}_options`}>{option.text}</label>
                                <div className="open_end_response">
                                    {option.isOpenTextBox && !textFieldIsDisabled(option) && (
                                        <TextField
                                            value={openendResponses[openendResponses.length - 1]}
                                            disabled={textFieldIsDisabled(option)}
                                            label={enterText}
                                            onChange={e => addIntoOpenEndResponse(e)}
                                            autoComplete="on"
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        case 'objective': {
            return (
                <div className="single_select_options_container">
                    <div
                        className="objective_type_question_statement"
                        dangerouslySetInnerHTML={{ __html: question.question_text }}
                    />
                    <div className="objective_type_question_options">
                        {question.options.map((option, index) => (
                            <button
                                key={`objective-question-btn-${index}`}
                                type="submit"
                                className={`outline-none ${singleSelect === option.value && 'selected_single_select'}`}
                                onClick={setSingleSelectAnswer}
                                data-optionvalue={option.value}
                            >
                                {option.text}
                            </button>
                        ))}
                    </div>
                </div>
            );
        }
        default:
            return '';
    }
}

export default function Question({
    question,
    setAnswer,
    setResponsesInRange,
    openendResponses,
    setopenendResponses,
    singleSelect,
    setSingleSelect,
    selectedOps,
    setSelectedOps,
}) {
    return (
        <div className="question_container" data-test="question-id">
            {_renderQuestion(
                question,
                setAnswer,
                setResponsesInRange,
                openendResponses,
                setopenendResponses,
                singleSelect,
                setSingleSelect,
                selectedOps,
                setSelectedOps,
            )}
        </div>
    );
}

Question.defaultProps = {
    singleSelect: false,
};

Question.propTypes = {
    question: PropTypes.shape({
        behavior_text: PropTypes.string.isRequired,
        question_text: PropTypes.string.isRequired,
    }).isRequired,
    setAnswer: PropTypes.func.isRequired,
    setResponsesInRange: PropTypes.func.isRequired,
    openendResponses: PropTypes.arrayOf(PropTypes.string).isRequired,
    setopenendResponses: PropTypes.func.isRequired,
    singleSelect: PropTypes.string,
    setSingleSelect: PropTypes.func.isRequired,
    selectedOps: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedOps: PropTypes.func.isRequired,
};
