import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import AddGoal from '../Goals/AddGoal';
import TabViewByCategory from './TabViewByCategory';
import TabViewByCompletion from './TabViewByCompletion';
import { getGoals, getGoalsByCategoryApi } from '../HomeContainer/redux/apis';
import { getUserStatusApi } from '../UnAuthenticatedContent/apis';
import { deleteGoalAction } from '../HomeContainer/redux/actions';
import AuthStore from '../../common/AuthStore';
import { pageAnalytics } from '../../utils/functions';
import './index.scss';

const AllGoals = ({ history, deleteGoalAction }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [goals, setGoals] = useState({
        list: [],
        count: 0,
        completedGoals: [],
        hasGoals: true,
    });
    const [view, setView] = useState(0);
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState(0);
    const status = localStorage.getItem('status');
    const [hideAddGoalBtn, setHideAddGoalBtn] = useState(true);
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    const fetchGoals = (status, page, add) => {
        setIsLoading(true);
        const goalsApiFunc = isClientPG ? getGoalsByCategoryApi : getGoals;
        goalsApiFunc({
            page,
            page_size: 10,
            status,
        })
            .then(response => {
                setIsLoading(false);
                if (add) {
                    setGoals(prevGoals => ({
                        count: response.data.count,
                        list: [...prevGoals.list, ...response.data.data],
                        completedGoals: [...prevGoals.completedGoals],
                    }));
                } else {
                    setGoals(prevGoals => ({
                        count: response.data.count,
                        list: [...response.data.data],
                        completedGoals: [...prevGoals.completedGoals],
                        hasGoals: !response.data.hide_goal_section,
                    }));
                }
            })
            .catch(() => {
                setIsLoading(false);
                setGoals({
                    list: [],
                    count: 0,
                    completedGoals: [],
                    hasGoals: false,
                });
            });
    };

    const actionCallBack = id => {
        const _goalsList = goals.list.filter(elem => elem.id !== id);

        setGoals({
            ...goals,
            list: _goalsList,
        });
    };

    useEffect(() => {
        if (status === 'false') {
            history.push('/onboarding');
        }
    }, [status, history]);

    useEffect(() => {
        if (!isClientPG && page > 1) {
            let status = 'pending';
            if (activeTab === 1) {
                status = 'completed';
            }
            fetchGoals(status, page, true);
        }
        // eslint-disable-next-line
    }, [page])

    const fetchInitialGoals = () => {
        let status = 'pending';
        if (activeTab === 1) {
            status = 'completed';
        }
        const body = {
            device_id: 'fcmToken',
            app_version: '1',
            device_type: 'web',
            user_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        };

        getUserStatusApi(AuthStore.accessToken, body).then(response => {
            setHideAddGoalBtn(response.data.data.hide_add_goal_button);
        });
        fetchGoals(status, 1);
        setPage(1);
    };

    const editCallBack = () => {
        fetchInitialGoals();
    };

    const deleteCallBack = status => {
        fetchGoals(status, 1);
        setPage(1);
    };

    useEffect(() => {
        if (!isClientPG) {
            fetchInitialGoals();
        }
        // eslint-disable-next-line
    }, [activeTab, view]);

    useEffect(() => {
        if (isClientPG) {
            fetchInitialGoals();
        }
        // eslint-disable-next-line
    }, [view]);

    useEffect(() => {
        pageAnalytics.initialise('goalHome');
        return () => {
            pageAnalytics.complete('goalHome');
        };
    }, []);

    const showModal = () => {
        switch (view) {
            case 1:
                return <AddGoal setView={setView} refreshHomeGoals={() => {}} />;
            default:
                return null;
        }
    };

    return (
        <>
            {isClientPG ? (
                <TabViewByCategory
                    isLoading={isLoading}
                    hideAddGoalBtn={hideAddGoalBtn}
                    goals={goals}
                    setView={setView}
                    activeTab={activeTab}
                    actionCallBack={actionCallBack}
                    editCallBack={editCallBack}
                    setActiveTab={setActiveTab}
                />
            ) : (
                <TabViewByCompletion
                    isLoading={isLoading}
                    hideAddGoalBtn={hideAddGoalBtn}
                    page={page}
                    goals={goals}
                    setPage={setPage}
                    setView={setView}
                    activeTab={activeTab}
                    actionCallBack={actionCallBack}
                    deleteGoalAction={deleteGoalAction}
                    editCallBack={editCallBack}
                    deleteCallBack={deleteCallBack}
                    setActiveTab={setActiveTab}
                />
            )}
            {showModal()}
        </>
    );
};

AllGoals.propTypes = {
    history: PropTypes.object.isRequired,
    deleteGoalAction: PropTypes.func.isRequired,
};

const dispatchToProps = { deleteGoalAction };

export default withRouter(connect(null, dispatchToProps)(AllGoals));
