import { takeLatest, put } from 'redux-saga/effects';
import types from './types';
import { apiFailed, clientExpiredAction, resetClientExpiredAction } from '../../../common/actions';
import { storeUserMatrix } from './actions';
import { tokenValidatorGenerator } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import { getUserMatrixApi } from './apis';
import { CODE_CLIENT_EXPIRED } from '../../../utils/constants';

function* getUserMatrixSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUserMatrixApi,
            payload: [AuthStore.accessToken],
        });
        yield put(resetClientExpiredAction());
        yield put(storeUserMatrix(data.data));
    } catch (e) {
        if (e.response.status === CODE_CLIENT_EXPIRED) {
            yield put(clientExpiredAction());
        } else {
            yield put(resetClientExpiredAction());
            yield put(apiFailed(e));
        }
    }
}

const userMatrixSaga = [takeLatest(`${types.GET_USER_MATRIX_DATA}_PENDING`, getUserMatrixSaga)];

export default userMatrixSaga;
