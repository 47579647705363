import { takeLatest, put } from 'redux-saga/effects';
import AuthStore from '../../../common/AuthStore';

import { getUserProgressApi } from './apis';
import { storeUserProgressData } from './actions';
import { tokenValidatorGenerator } from '../../../utils/functions';
import { apiFailed } from '../../../common/actions';

function* getUserProgressSaga() {
    try {
        const { data } = yield tokenValidatorGenerator({
            saga: getUserProgressApi,
            payload: [AuthStore.accessToken],
        });
        yield put(storeUserProgressData(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const myProgressSaga = [takeLatest('GET_USER_PROGESS_PENDING', getUserProgressSaga)];

export default myProgressSaga;
