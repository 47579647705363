import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import userMatrixSaga from './redux/sagas';
import userMatrixReducer from './redux/reducer';
import { getUserMatrix, resetUserMatrix } from './redux/actions';
import UserMatrixComponent from './UserMatrixComponent';

const stateToProps = state => {
    const UserMatrixReducer = state.get('userMatrixReducer');
    return {
        matrixData: UserMatrixReducer.data,
    };
};

export { userMatrixSaga, userMatrixReducer };

const dispatchToProps = {
    getUserMatrix,
    resetUserMatrix,
};

export default withRouter(connect(stateToProps, dispatchToProps)(UserMatrixComponent));
