/* eslint-disable no-nested-ternary */
import React, { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import ImageCropper from './ImageCropper';
import { getCroppedImg, getCroppedImgBase64 } from '../../../utils/functions';
import { DpHolder, UploadCloud } from '../assets/svg';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import PreviewImage from '../../../components/PreviewImage';
import { isMobile } from '../../../utils/constants';
import MobileImageCropper from './MobileImageCropper';
import LocalisationsStore from '../../../common/LocalisationsStore';

export default function ImageSelector({
    imageQuestionId,
    open,
    handleClose,
    uploadImage,
    setImageUri,
    imageUri,
    isLoading,
}) {
    // eslint-disable-next-line no-unused-vars
    const [file, setFile] = useState();
    const [imgSrc, setImageSrc] = useState('');
    const [showCropper, setShowCropper] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [canvass, setCanvas] = useState(null);
    const callbackSetCroppedAreaPixels = useCallback(setCroppedAreaPixels, []);
    // const [completed, setCompleted] = useState();
    const { localisationsAfterLogin } = LocalisationsStore;
    const { Upload, browse, draganddropanimageor, clickTo } = localisationsAfterLogin;

    const selectImage = e => {
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        const file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
            setFile(file);
        }
        setShowCropper(true);
    };

    const UploadImageAndClosePop = async fil => {
        await uploadImage({
            data: [
                {
                    id: imageQuestionId,
                    question: 4,
                    response: fil,
                    type: 'profile_picture',
                },
            ],
        });
    };

    const showResult = async () => {
        let croppedImageUri;
        let croppedImage;

        if (!isMobile) {
            croppedImageUri = await getCroppedImg(imgSrc, croppedAreaPixels);
            croppedImage = await getCroppedImgBase64(imgSrc, croppedAreaPixels);
        } else {
            croppedImageUri = await new Promise(resolve => {
                canvass.toBlob(
                    file => {
                        resolve(file);
                    },
                    'image/jpeg',
                    0.8,
                );
            });
            croppedImage = await canvass.toDataURL('image/jpeg', 0.8);
        }
        setImageUri(URL.createObjectURL(croppedImageUri));
        setFile(croppedImage);
        UploadImageAndClosePop(new File([croppedImageUri], 'pict.jpeg'));
    };

    const path = 'M32.7,191c12.86,47.37,133.78,111,182.21,36.81,57.42-87.91,32-145,0-170.5-24-19.12-99.68-39.38-141.49-19.74S16.76,132.25,32.7,191Z';
    const transform = 'translate(-19.35 -22.17)';

    const body = (
        <Grid md={12} xs={12} className="image_selector">
            {!imageUri ? (
                <div className="crop-selector-container">
                    <div
                        className="close_icon_selector"
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                            e.stopPropagation();
                            handleClose();
                        }}
                    >
                        <Cross color="black" />
                    </div>
                    {!showCropper ? (
                        <div className="drag_drop_image_container">
                            <div className="selection_area">
                                <DpHolder />
                                <span>
                                    {draganddropanimageor}
                                    <span>
                                        {' '}
                                        {clickTo}
                                        {' '}
                                    </span>
                                    <span>
                                        {' '}
                                        {browse}
                                        {' '}
                                    </span>
                                </span>
                                <UploadCloud />
                                <span>
                                    <input
                                        type="file"
                                        onChange={selectImage}
                                        name="profile_image"
                                        value={imageUri}
                                        accept=".jpeg,.jpg,.png,"
                                    />
                                </span>
                            </div>
                        </div>
                    ) : isMobile && imgSrc !== '' ? (
                        <MobileImageCropper setCanvas={setCanvas} src={imgSrc} />
                    ) : (
                        <ImageCropper image={imgSrc} setCroppedAreaPixels={callbackSetCroppedAreaPixels} />
                    )}
                    <div className="upload_button_container">
                        <div type="submit" onClick={showResult} role="none">
                            {Upload}
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {isLoading && (
                        <div className="svg_wrapper">
                            <svg height="0" width="0">
                                <clipPath id="myPath2">
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d={path}
                                        transform={transform}
                                        fill="none"
                                        stroke="#231f20"
                                        strokeMiterlimit="10"
                                        strokeWidth="0.5"
                                    />
                                </clipPath>
                            </svg>
                            <svg height="260" width="260">
                                <path
                                    xmlns="http://www.w3.org/2000/svg"
                                    d="M30.13,192.12C43.3,240.65,167.18,305.79,216.8,229.83c58.83-90.06,32.74-148.6,0-174.67-24.6-19.59-102.12-40.34-145-20.22S13.8,131.92,30.13,192.12Z"
                                    transform="translate(-19.35 -22.17)"
                                    fill="none"
                                    stroke="#ff642e"
                                    strokeMiterlimit="10"
                                    strokeWidth="5"
                                    className="line2"
                                />
                            </svg>
                            <PreviewImage
                                src={imageUri}
                                alt=""
                                width={260}
                                height={260}
                                opacity={0.6}
                                clipid="myPath2"
                            />
                        </div>
                    )}
                </Fragment>
            )}
        </Grid>
    );

    return (
        <Modal
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="image_selector_container"
        >
            {body}
        </Modal>
    );
}

ImageSelector.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    imageQuestionId: PropTypes.array.isRequired,
    setImageUri: PropTypes.func.isRequired,
    imageUri: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
