/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import InspireTopIcon from '../../assets/InspireTopIcon.svg';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Actionbutton from '../../components/ActionButton';
import { requestSignUp } from '../UnAuthenticatedContent/actions';
import { selectLoading } from '../UnAuthenticatedContent/selector';
import Loader from '../../components/Loader';
import './index.scss';
import { fetchTandC, postTandC } from '../UnAuthenticatedContent/apis';
import LocalisationsStore from '../../common/LocalisationsStore';

function TermsAndConditionComponent({ history, setChecked }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { Accept } = localisationsAfterLogin;

    const [loading, setIsLoading] = useState(false);
    const [tc, setTc] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchTandC()
            .then(response => {
                setTc(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    const handleOnClick = () => {
        postTandC();
        setChecked(true);
        history.push('/onboarding');
    };

    return (
        <div className="unauth_content_container_wrapper_tnc">
            {loading && <Loader position="absolute" showBackground />}
            <div className="unauth_content_container">
                <img src={InspireTopIcon} alt="inspire_logo" className="biggerInspireTopIcon" />
                {tc && (
                    <div className="central_content">
                        <div className="tnc" dangerouslySetInnerHTML={{ __html: tc.data.json_data }} />
                        <div className="accept_wrapper">
                            <Actionbutton
                                fontSize="1.125rem"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.siteWhite}
                                bgColor={fontColors.electricBlue}
                                disabled={false}
                                onClick={handleOnClick}
                                height="7vh"
                                width="23vw"
                            >
                                {Accept}
                            </Actionbutton>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

TermsAndConditionComponent.propTypes = {
    history: PropTypes.object.isRequired,
    setChecked: PropTypes.func.isRequired,
};

const dispatchToProps = { requestSignUp };

const stateToProps = createStructuredSelector({
    isLoading: selectLoading(),
});

export default withRouter(connect(stateToProps, dispatchToProps)(TermsAndConditionComponent));
