import types from './types';

export const getHtmlContent = ({ baseurl }) => ({
    type: `${types.GET_MAIL_ANSWER}_PENDING`,
    baseurl,
});

export const storeHtml = data => ({
    type: `${types.GET_MAIL_ANSWER}_DONE`,
    data,
});
