/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WarningModal from '../../components/WarningModal';
import { getCoachMarks } from './redux/apis';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Lottie from '../../components/Lottie';
import './coachmarks.scss';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import { pageAnalytics } from '../../utils/functions';
import { extractJsonFromUrl } from '../UnAuthenticatedContent/apis';
import { COACH_MARKS_TYPES } from '../../utils/constants';
import LocalisationsStore from '../../common/LocalisationsStore';

const MEDIA_TYPE = {
    IMAGE: 'Image',
    VIDEO: 'Video',
    GIF: 'Gif',
    ANIMATION: 'Animation',
};

function CoachMarks({ setShowCoachMarks, type }) {
    const [coachMarks, setCoachMarks] = useState(null);
    const [animation, setAnimation] = useState(null);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { how_does_the_accelerator_work, how_to_use_this_program } = localisationsAfterLogin;

    const isAccelerator = type === COACH_MARKS_TYPES.PROFILE_VIDEO_ACCELETOR;
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    useEffect(() => {
        getCoachMarks(type).then(response => {
            setCoachMarks(response.data.data);
            if (response.data.data.media_type === MEDIA_TYPE.ANIMATION) {
                return extractJsonFromUrl(response.data.data.image);
            }
            return {};
        }).then(response => {
            setAnimation(response.data);
        });
    }, [type]);

    useEffect(() => {
        pageAnalytics.initialise('coachMarks');
        return () => {
            pageAnalytics.complete('coachMarks');
        };
    }, []);

    const handleCrossClick = () => {
        setShowCoachMarks(false);
    };

    const renderAnimation = mediaType => {
        switch (mediaType) {
            case MEDIA_TYPE.ANIMATION:
                return (
                    <Lottie
                        className="outer_animation_container"
                        borderRadius={20}
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: animation,
                        }}
                    />
                );
            case MEDIA_TYPE.VIDEO:
                return <video src={coachMarks.image} controls muted playsInline />;
            case MEDIA_TYPE.GIF:
            case MEDIA_TYPE.IMAGE:
                return <img src={coachMarks.image} alt="animation img" />;
            default:
                return (
                    <Lottie
                        className="outer_animation_container"
                        borderRadius={20}
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: animation,
                        }}
                    />
                );
        }
    };

    const updateHTML = htmlStr => htmlStr.replaceAll('<img', '<img style = "max-height: 400px;width:100%"');

    return (
        <>
            {coachMarks && (
                <WarningModal isClientPG={isAccelerator}>
                    <div className={`coachMarks_wrapper${isAccelerator ? ' accelerator_coach_marks' : ''}`}>
                        <div
                            onClick={handleCrossClick}
                            role="button"
                            tabIndex={0}
                            className="cross outline-none pointer"
                        >
                            <Cross color="black" />
                        </div>
                        {isAccelerator && (
                            <div className="accelerator-header-container">
                                <Text
                                    fontcolor={fontColors.placeholderGrey}
                                    fontfamily={fontFamilies.TheinhardtPanLight}
                                    lineHeight="20px"
                                    fontweight={500}
                                    margin="0 0 10px 0"
                                    textTransform="uppercase"
                                >
                                    {how_to_use_this_program}
                                </Text>
                                <Text
                                    fontsize="28px"
                                    lineHeight="32px"
                                    fontcolor={fontColors.black}
                                    fontfamily={fontFamilies.playfairBold}
                                    margin="0 0 40px 0"
                                >
                                    {how_does_the_accelerator_work}
                                </Text>
                            </div>
                        )}
                        {!isClientPG ? <img src={coachMarks.image} alt="" /> : (
                            <div className="coach-marks-media">
                                {renderAnimation(coachMarks.media_type)}
                            </div>
                        )}
                        <Text
                            fontsize={isAccelerator ? '18px' : '1rem'}
                            lineHeight={isAccelerator && '24px'}
                            fontcolor={fontColors.textBlack}
                            fontfamily={isAccelerator ? fontFamilies.TheinhardtPanLight : fontFamilies.TheinhardtPanRegular}
                            margin="1rem 0 0 0"
                            wordBreak="break-word"
                            width={isAccelerator ? '100%' : null}
                        >
                            {isAccelerator && coachMarks.content_type === 'html' ? <div dangerouslySetInnerHTML={{ __html: updateHTML(coachMarks.description) }} /> : coachMarks.description}
                        </Text>
                        {coachMarks.content_type === 'steps' && (
                            <div className="steps">
                                {coachMarks.steps.map((step, index) => (
                                    <div className="step" key={`coach-${index}`}>
                                        <div className="circle">
                                            <Text
                                                fontsize="1rem"
                                                fontcolor={fontColors.siteWhite}
                                                fontfamily={fontFamilies.TheinhardtPanMedium}
                                            >
                                                {index + 1}
                                            </Text>
                                        </div>
                                        <Text
                                            fontsize={isAccelerator ? '18px' : '1rem'}
                                            lineHeight={isAccelerator && '24px'}
                                            fontcolor={fontColors.textBlack}
                                            fontfamily={isAccelerator ? fontFamilies.TheinhardtPanLight : fontFamilies.TheinhardtPanRegular}
                                            margin="0 0 0 1rem"
                                            width="calc(100% - 28px)"
                                            wordBreak="break-word"
                                        >
                                            {step}
                                        </Text>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </WarningModal>
            )}
        </>
    );
}

export default CoachMarks;

CoachMarks.defaultProps = {
    type: COACH_MARKS_TYPES.PRACTICE,
};

CoachMarks.propTypes = {
    setShowCoachMarks: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(COACH_MARKS_TYPES)),
};
