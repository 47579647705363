import types from './types';

export const postImage = payload => ({
    type: types.UPLOAD_IMAGE_PENDING,
    payload,
});

export const storeImage = payload => ({
    type: types.UPLOAD_IMAGE_DONE,
    payload,
});

export const getQuestionsAction = history => ({ type: types.GET_QUESTIONS, history });

export const storeQuestions = payload => ({
    type: types.STORE_QUESTIONS,
    payload,
});

export const toggleShowSelectorModal = val => ({
    type: types.TOGGLE_POP_UP,
    val,
});

export const postAnswers = (question, history) => ({
    type: types.POST_ANSWERS_PENDING,
    question,
    history,
});

export const postAnswersDone = () => ({
    type: types.POST_ANSWERS_DONE,
});

export const getVcrbData = payload => ({
    type: types.GET_VCRB_PENDING,
    payload,
});

export const getVcrbDataSuccess = payload => ({
    type: types.GET_VCRB_SUCCESS,
    payload,
});

export const getVcrbDataFailed = (payload = {}) => ({
    type: types.GET_VCRB_FAILED,
    payload,
});

export const getNotes = payload => ({
    type: types.GET_NOTES_PENDING,
    payload,
});

export const getNotesSuccess = payload => ({
    type: types.GET_NOTES_SUCCESS,
    payload,
});

export const getNotesFailed = (payload = {}) => ({
    type: types.GET_NOTES_FAILED,
    payload,
});

export const addNotes = payload => ({
    type: types.ADD_NOTES_PENDING,
    payload,
});

export const addNotesSuccess = payload => ({
    type: types.ADD_NOTES_SUCCESS,
    payload,
});

export const addNotesFailed = (payload = {}) => ({
    type: types.ADD_NOTES_FAILED,
    payload,
});
