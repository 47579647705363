import React, { useState } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PropTypes from 'prop-types';
import { ADD_TEXT } from '../../../utils/constants';
import LocalisationsStore from '../../../common/LocalisationsStore';

const NotesInput = ({ addNotes, goalType, setNumberOfNotes }) => {
    const [text, setText] = useState('');
    const { localisationsAfterLogin } = LocalisationsStore;
    const { ADD_ADDITIONAL_GOALS_TO_YOUR_ROLE } = localisationsAfterLogin;
    const addNewNote = () => {
        if (text) {
            const payload = {
                type: goalType,
                text,
            };
            addNotes(payload);
            setText('');
        }
    };

    const textAreaChange = e => {
        setText(e.target.value);
        const height = document.getElementById('notesInput').clientHeight;
        if (height >= 260) {
            setNumberOfNotes(1);
        }
    };
    return (
        <div className="notesInput" id="notesInput">
            <div className="heading">{ADD_ADDITIONAL_GOALS_TO_YOUR_ROLE}</div>
            <div className="onAddBtn">
                <div className="textareaSection">
                    <TextareaAutosize
                        rowsMax={7}
                        aria-label="maximum height"
                        placeholder="Write New Note"
                        rowsMin={4}
                        value={text}
                        onChange={e => textAreaChange(e)}
                    />
                </div>
                {text && (
                    <div
                        className="addBtn"
                        onClick={() => addNewNote()}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                    >
                        {ADD_TEXT}
                    </div>
                )}
            </div>
        </div>
    );
};
NotesInput.propTypes = {
    addNotes: PropTypes.func.isRequired,
    goalType: PropTypes.string.isRequired,
    setNumberOfNotes: PropTypes.func.isRequired,
};

export default NotesInput;
