import html2canvas from 'html2canvas';
import React,{useCallback} from 'react';
import downloadjs from 'downloadjs'
import './index.scss'
import { pageAnalytics } from '../../../../utils/functions';
import TextDownloadContent from './TextDownloadContent';


const ShareView = ({data, downloadCallback,promptId}) => {
    const feedData = {
        description: data,
        config: {
            index: 0,
            fontSize: '26px',
        },
    };

    const downloadImage = useCallback(async(promptId) =>{
        pageAnalytics.initialise('goal_share/article_id/'+promptId)
        const divToCapture = document.getElementById('share_bg');
        const canvas = await html2canvas(divToCapture)
        const dataUrl = canvas.toDataURL('shruti/png')
        downloadjs(dataUrl,'ss.png','shruti/png')
        downloadCallback()
        pageAnalytics.complete('goal_share/article_id/'+promptId);
    },[downloadCallback])



    return <div className="share-view">
      <div id="share_bg"><TextDownloadContent feedData={feedData} /></div>
      <div onClick ={() => downloadImage(promptId)} className="share-btn">DOWNLOAD IMAGE</div>
    </div>

};

export default ShareView;
