/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../Text';
import CTATypeMapper from './CTATypeMapper';
import './index.scss';
import WarningModal from '../WarningModal';
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg';
import Frame from '../../assets/Frame.svg';
import ArrowCircle from '../../assets/arrow-circle.svg';
import PromptDetails, { PracticeStateType } from '../../containers/PracticesDetailsContainer/PromptDetails';
import { pageAnalytics } from '../../utils/functions';

const CardContainer = styled.div`
    width: 100%;
    min-height: 12.5rem
    border-radius: ${props => (props.cardType === 'AP' ? '0.55rem' : '0.313rem')};
    background-color: ${props => props.bgColor || 'transparent'};
    display: flex !important;
    justify-content: space-between;
    align-items: end;
    position: relative;
`;

function DynamicCard({
    key,
    bgColor,
    title,
    body,
    thumbnail,
    history,
    ctaExternal,
    ctaInternal,
    ctaType,
    id,
    cta_text,
    thumbnailTop,
    updateNewCardStatus,
    // getHomePage,
    setShowCreatePost,
    articleId,
    practiceId,
    cardType,
}) {
    const card = new CTATypeMapper(history, ctaType, ctaExternal, id, ctaInternal, practiceId);
    const [openModal, setOpenModal] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);

    const handleProfileCompleteOnClick = () => {
        updateNewCardStatus(card.id);
        // getHomePage();
        if (cardType === 'AP') {
            card.openUrl({ setShowCreatePost, setShowPrompt });
        } else if (ctaExternal.includes('html')) {
            pageAnalytics.initialise(`dynamicCard/promptDetail/${card.id}`);
            setOpenModal(true);
        } else {
            card.openUrl({ setShowCreatePost, setShowPrompt });
        }
    };

    const closeCallback = () => {
        pageAnalytics.complete(`dynamicCard/promptDetail/${card.id}`);
        setOpenModal(false);
    };

    const handleCrossClick = () => {
        setShowPrompt(false);
    };

    return (
        <CardContainer key={key} bgColor={bgColor} cardType="AP" className="FirstTimeWelcomeCard">
            {openModal && (
                <WarningModal>
                    <div className="cardtype1modal">
                        <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                            <Cross color="black" />
                        </div>
                        <div className="img">
                            <img src={Frame} alt="" />
                        </div>
                        <div className="html" dangerouslySetInnerHTML={{ __html: ctaExternal }} />
                    </div>
                </WarningModal>
            )}
            {showPrompt && (
                <PromptDetails
                    article={{
                        id: articleId,
                        state: PracticeStateType.lockedOrNew,
                        from: 'homePage',
                    }}
                    handleCrossClick={handleCrossClick}
                    practiceId={practiceId}
                />
            )}
            <div className="fimage">
                <img src={thumbnailTop} alt="dynamic_card" />
            </div>
            <div className="left_panel">
                <div className="element">
                    <Text
                        fontsize="0.875rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0 0.625rem 0"
                        textTransform="uppercase"
                        letterSpacing="1px"
                        lineHeight="1.25rem"
                    >
                        {title}
                    </Text>
                </div>
                <div className="element">
                    <Text
                        fontsize="1.75rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.playfairBold}
                        margin="0 0 0.625rem 0"
                        lineHeight="2rem"
                    >
                        {body}
                    </Text>
                </div>
                <div className="element" onClick={handleProfileCompleteOnClick}>
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.siteWhite}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        margin="0 0.625rem 0 0"
                        fontweight="500"
                    >
                        {cta_text}
                    </Text>
                    {/* <CtaArrow color={fontColors.siteWhite} /> */}
                    <img src={ArrowCircle} alt="arrow" className="pointer" />
                </div>
            </div>
            <div className="image">
                <img src={thumbnail} alt="dyamic_card" />
            </div>
        </CardContainer>
    );
}

DynamicCard.defaultProps = {
    practiceId: -1,
    ctaType: -1,
    articleId: -1,
    key: -1,
    ctaInternal: '',
    cardType: '',
};

DynamicCard.propTypes = {
    key: PropTypes.number,
    bgColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    ctaExternal: PropTypes.string.isRequired,
    ctaInternal: PropTypes.string,
    ctaType: PropTypes.number,
    id: PropTypes.number.isRequired,
    cta_text: PropTypes.string.isRequired,
    thumbnailTop: PropTypes.string.isRequired,
    updateNewCardStatus: PropTypes.func.isRequired,
    // getHomePage: PropTypes.func.isRequired,
    setShowCreatePost: PropTypes.func.isRequired,
    articleId: PropTypes.number,
    practiceId: PropTypes.number,
    cardType: PropTypes.string,
};

export default withRouter(DynamicCard);
