import React, { Fragment, useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import WarningModal from '../../../components/WarningModal';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';
import leftIcon from '../../../assets/leftIcon.svg';
import Actionbutton from '../../../components/ActionButton';
import { fontColors, fontFamilies } from '../../../common/fontMap';
import { setGoalApi } from '../../HomeContainer/redux/apis';
import CustomDateTimePicker from '../../../components/DateTimePicker';
import CustomDatePicker from '../../../components/CustomDatePicker';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { pageAnalytics } from '../../../utils/functions';

const AddGoal = ({ setView }) => {
    const [goalView, setGoalView] = useState(1);
    const [isGoalCreating, setIsGoalCreating] = useState(false);
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    useEffect(() => {
        pageAnalytics.initialise('todoCreate');
        return () => {
            pageAnalytics.complete('todoCreate');
        };
    }, []);
    // eslint-disable-next-line no-unused-vars
    const [goalQuestions, setGoalQuestions] = useState([
        {
            question_type: 'text_area',
        },
        {
            question_type: 'date',
        },
    ]);
    const today = new Date();
    const [goal, setGoal] = useState({
        goal_text: '',
        goal_description: '',
        due_date: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0)).format('yyyy-MM-DDTHH:mm:ss.SSS'),
    });

    const { localisationsAfterLogin } = LocalisationsStore;
    const [error, errorCallBack] = useState(false);
    const { createNewTask, taskTitle, taskDetails, setReminder, nextFirst, add } = localisationsAfterLogin;

    const setGoalText = goal_text => {
        setGoal({
            ...goal,
            goal_text,
        });
    };

    const setGoalDescription = goal_description => {
        setGoal({
            ...goal,
            goal_description,
        });
    };

    const setDueDate = due_date => {
        setGoal({
            ...goal,
            due_date,
        });
    };

    const setGoals = () => {
        const goalText = (goal.goal_text || '').trim();
        const goalDescription = (goal.goal_description || '').trim();
        setGoalApi({ ...goal, goal_text: goalText, goal_description: goalDescription, prompt: 'custom' }).then(() => {
            setView(0);
            setIsGoalCreating(false);
        }).catch(() => setIsGoalCreating(false));
    };
    const showView = () => {
        const match = goalQuestions.length > 0 ? goalQuestions[goalView - 1] : { question_type: '' };
        switch (match.question_type) {
            case 'text_area':
                return (
                    <Fragment>
                        <div className="task-item">
                            <div className="title">{taskTitle}</div>
                            <textarea
                                className="text-area"
                                onChange={event => {
                                    setGoalText(event.target.value);
                                }}
                                value={goal.goal_text}
                            />
                        </div>

                        <div className="task-item">
                            <div className="title">{taskDetails}</div>
                            <textarea
                                className="text-area"
                                onChange={event => {
                                    setGoalDescription(event.target.value);
                                }}
                                value={goal.goal_description}
                            />
                        </div>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={goal.goal_text.trim().length < 1 || goal.goal_description.trim().length < 1}
                            width={isClientPG ? '25%' : '20%'}
                            onClick={() => {
                                setGoalView(2);
                            }}
                        >
                            {nextFirst}
                        </Actionbutton>
                    </Fragment>
                );
            case 'date':
                return (
                    <Fragment>
                        <div className="info">
                            <div className="title">{setReminder}</div>
                            <div className="goal-date">
                                {isClientPG ? (
                                    <CustomDatePicker
                                        selectedDate={goal.due_date}
                                        handleDateChange={setDueDate}
                                        errorCallBack={errorCallBack}
                                        errorShow={error}
                                        isHeaderVisible={false}
                                        isSaveButtonVisible={false}
                                        isStatic
                                    />
                                ) : (
                                    <CustomDateTimePicker
                                        selectedDate={goal.due_date}
                                        handleDateChange={setDueDate}
                                        errorCallBack={errorCallBack}
                                        errorShow={error}
                                    />
                                )}
                            </div>
                        </div>
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={error || isGoalCreating}
                            width={isClientPG ? '25%' : '20%'}
                            onClick={() => {
                                setIsGoalCreating(true);
                                setGoals();
                            }}
                        >
                            {add}
                        </Actionbutton>
                    </Fragment>
                );
            default:
                return null;
        }
    };
    return (
        <WarningModal classNames="add-goal">
            <div className={`add-task-view${isClientPG ? ' add-task-view-pg' : ''}`}>
                <div className="header">
                    <div className="title">
                        {goalView > 1 && (
                            <div
                                className="back-icon"
                                onClick={() => {
                                    setGoalView(goalView - 1);
                                }}
                            >
                                <img src={leftIcon} alt="Back" />
                            </div>
                        )}
                        <div>{createNewTask}</div>
                    </div>
                    <div
                        className="cross"
                        onClick={() => {
                            setView(0);
                        }}
                    >
                        <Cross color="black" />
                    </div>
                </div>
                <div className="add-goal-content">{showView()}</div>
            </div>
        </WarningModal>
    );
};
AddGoal.propTypes = {
    setView: PropTypes.func.isRequired,
};

export default AddGoal;
