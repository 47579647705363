import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import InspireTopIcon from '../../assets/InspireTopIcon.svg';
import InputField from '../../components/InputField';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import { resetPassword } from '../UnAuthenticatedContent/actions';
import { selectLoading } from '../UnAuthenticatedContent/selector';
import Loader from '../../components/Loader';
import particleBg from '../../assets/particleBg.svg';
import './index.scss';
import LocalisationsStore from '../../common/LocalisationsStore';
import { isValidPattern } from '../../utils/functions';
import { EMAIL_PATTERN } from '../../utils/constants';

function ForgotPassWordComponent({ history, resetPassword, isLoading }) {
    const { localisationsBeforeLogin } = LocalisationsStore;
    const {
        WhatsYourEmail,
        LoginEmailText,
        Continue,
        checkemailplease,
        BackToSignIn,
        resetPasswordMsg,
        PasswordReset,
        PleaseEnterEmail,
        EmailError,
    } = localisationsBeforeLogin;
    const [email, setEmail] = useState('');
    const [feError, setFeError] = useState({ emailValidated: true, emailError: '' },);

    const handleChange = event => {
        setEmail(event.target.value);
    };

    const handleRegisterClick = () => {
        history.push('/');
    };

    const handleOnClick = () => {
        if (email === '') {
            setFeError({ emailValidated: false, emailError: PleaseEnterEmail });
        } else if (!isValidPattern(email, EMAIL_PATTERN)) {
            setFeError({ emailValidated: false, emailError: EmailError });
        } else {
            setFeError({
                emailValidated: true,
                emailError: ''
            });
            const callback = () => {
                history.push({
                    pathname: '/checkemail',
                    state: { msg: checkemailplease },
                });
            };
            resetPassword({ username: email }, () => callback());
        }
    };
    
    const renderEmailError = () => {
        let errMsg = '';
        if (!feError.emailValidated) {
            errMsg = feError.emailError;
        }
        return (
            <Text
                fontsize="0.8rem"
                fontcolor={fontColors.errorRed}
                fontfamily={fontFamilies.TheinhardtPanRegular}
                margin="0 0 1.25rem 0"
            >
                {errMsg}
            </Text>
        );
    };

    return (
        <div className="unauth_content_container_wrapper_forgot">
            {isLoading && <Loader position="absolute" showBackground />}
            <img src={particleBg} alt="particles" className="particleBg" particleBg />
            <div className="unauth_content_container">
                <img src={InspireTopIcon} alt="inspire_logo" className="biggerInspireTopIcon" />
                <div className="central_content">
                    <div className="header_info">
                        <Text
                            fontsize="2.25rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.playfairBold}
                            margin="0 0 0.625rem 0"
                        >
                            {PasswordReset}
                        </Text>
                        <Text
                            fontsize="1.125rem"
                            fontcolor={fontColors.textBlack}
                            fontfamily={fontFamilies.TheinhardtPanRegular}
                        >
                            {resetPasswordMsg}
                        </Text>
                    </div>
                    <div className="main_section">
                        <div className="input_element">
                            <InputField
                                label={WhatsYourEmail}
                                type="text"
                                value={email}
                                onChange={handleChange}
                                fontSize="1rem"
                                fontFamily={fontFamilies.TheinhardtPanRegular}
                                fontColor={fontColors.textBlack}
                                placeholder={LoginEmailText}
                                borderColor={email.length > 0 && !feError.emailValidated ? fontColors.errorRed : fontColors.borderGrey}
                            />
                        </div>
                        {email.length > 0 && (<div className="render_error">{renderEmailError()}</div>)}
                        {email.length > 0 && (
                            <div className="action_container">
                                <Actionbutton
                                    fontSize="1.125rem"
                                    fontFamily={fontFamilies.TheinhardtPanRegular}
                                    fontColor={fontColors.siteWhite}
                                    bgColor={fontColors.electricBlue}
                                    disabled={false}
                                    onClick={handleOnClick}
                                >
                                    {Continue}
                                </Actionbutton>
                            </div>
                            )}
                    </div>
                </div>
                <div className="footer_container">
                    <Text
                        fontsize="0.8rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.TheinhardtPanMedium}
                        cursor="pointer"
                        onClick={handleRegisterClick}
                        decoration="underline"
                    >
                        {BackToSignIn}
                    </Text>
                </div>
            </div>
        </div>
    );
}

ForgotPassWordComponent.propTypes = {
    history: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const dispatchToProps = { resetPassword };

const stateToProps = createStructuredSelector({
    isLoading: selectLoading(),
});

export default withRouter(connect(stateToProps, dispatchToProps)(ForgotPassWordComponent));
