import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Info from '@material-ui/icons/Info';
import Text from '../../../../components/Text';
import { Badges } from '../../../Feeds/constants';
import LocalisationsStore from '../../../../common/LocalisationsStore';
import './index.scss';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import { LightTooltip } from '../ChartProgress';

export default function BadgesEarned({ badges }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { badgesEarned, tooltipSocialBadge } = localisationsAfterLogin;

    const isBadgeEarned = badge => {
        const { social } = badges;
        const { badgeId } = badge;
        if (badgeId <= social && social !== 0) {
            return true;
        }
        return false;
    };

    const _renderBadge = () => {
        const socialBadges = Badges.filter(badge => badge.type === 'social');

        return socialBadges.map((badge, i) => {
            const badgeEarned = isBadgeEarned(badge);
            return (
                <Fragment>
                    <div className={badgeEarned ? 'badge_element' : 'badge_element faded'}>
                        <div>
                            <img src={badge.badgeImage} alt="badger" />
                        </div>
                        <div>
                            <Text fontcolor="#666666" fontfamily="TheinhardtPan" fontsize="12" fontweight="500">
                                {badge.badgeName}
                            </Text>
                        </div>
                    </div>
                    {i < socialBadges.length - 1 && <div className="badge_link" />}
                </Fragment>
            );
        });
    };

    return (
        <div className="badges_container">
            <div className="heading">
                <Text
                    fontcolor="#000000"
                    fontfamily={fontFamilies.playfairBold}
                    fontsize="1.25rem"
                    fontweight="500"
                    margin="0 0.5rem 0 0"
                >
                    {badgesEarned}
                </Text>
                <LightTooltip title={tooltipSocialBadge} enterTouchDelay={5}>
                    <Info style={{ fill: fontColors.electricBlue, marginTop: 1, fontSize: 25 }} />
                </LightTooltip>
            </div>
            <div className="badge_sequence">{_renderBadge()}</div>
        </div>
    );
}

BadgesEarned.propTypes = {
    badges: PropTypes.shape({ social: PropTypes.number, actionable: PropTypes.number }).isRequired,
};
