import React from 'react';
import TextContent from './TextContent';
import ImageContent from './ImageContent';
import VideoContent from './VideoContent';

export const feedTypes = Object.freeze({
    VIDEO: 'VIDEO',
    PDF: 'PDF',
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    TEXT: 'TEXT',
});

export default class FeedCardTypeMapper {
    constructor(feedData) {
        this.feedData = feedData;
    }

    getContent() {
        switch (this.feedData.file_type) {
            case feedTypes.IMAGE:
                return <ImageContent feedData={this.feedData} />;
            case feedTypes.TEXT:
                return <TextContent feedData={this.feedData} />;
            case feedTypes.VIDEO:
                return <VideoContent feedData={this.feedData} />;
            default:
                return <></>;
        }
    }
}
