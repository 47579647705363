import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../Text';
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg';
import './index.scss';

const CardContainer = styled.div`
    width: 100%;
    min-height: 12.5rem
    border-radius: 0.4rem;
    background-color: ${fontColors.siteWhite};
    display: flex !important;
    justify-content: space-between;
    align-items: center;
`;

export default function CoachIntroCard({ key, title, body, closeCallback, thumbnail }) {
    return (
        <CardContainer key={key} className="CoachIntroCard">
            <div className="left_panel">
                <div className="element">
                    <Text
                        fontsize="0.75rem"
                        fontcolor={fontColors.placeholderGrey}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0 1rem 0"
                        letterSpacing="0.75px"
                        textTransform="uppercase"
                    >
                        {title}
                    </Text>
                    <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                        <Cross color="black" />
                    </div>
                </div>
                <div className="element">
                    <Text
                        fontsize="1.125rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontweight="400"
                    >
                        {body}
                    </Text>
                </div>
                <div className="coach-image">
                    <img src={thumbnail} alt="Coach" />
                </div>
            </div>
        </CardContainer>
    );
}

CoachIntroCard.defaultProps = {
    key: -1,
};
CoachIntroCard.propTypes = {
    key: PropTypes.number,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    closeCallback: PropTypes.func.isRequired,
    thumbnail: PropTypes.string.isRequired,
};
