import { connect } from 'react-redux';

import { getLanguages, setLanguage } from './redux/actions';
import emailLanguagesReducer from './redux/reducer';
import emailLanguageSelectionSaga from './redux/sagas';
import LanguageSelectionComponent from './LanguageSelectionComponent';

import './index.scss';

const mapStateToProps = state => {
    const Reducer = state.get('emailLanguagesReducer');
    return {
        languagesData: Reducer.languagesData,
        loading: Reducer.loading,
        changeLanguageLoading: Reducer.changeLanguageLoading,
    };
};

const dispatchToProps = {
    getLanguages,
    setLanguage,
};

export { emailLanguageSelectionSaga, emailLanguagesReducer };

export default connect(mapStateToProps, dispatchToProps)(LanguageSelectionComponent);
