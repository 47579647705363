/* eslint-disable no-param-reassign */
import createReducer from '../../../utils/redux-utils/createReducer';
import types from './types';

const INITIAL_STATE = {
    feedbackQuestion: [],
    loading: false,
};

const feedbackQuestionReducer = createReducer(INITIAL_STATE, {
    [`${types.GET_FEEDBACK_QUESTION}_PENDING`]: (state, { data }) => ({
        ...state,
        loading: true,
        feedbackQuestion: data,
    }),
    [`${types.GET_FEEDBACK_QUESTION}_SUCCESS`]: (state, { data }) => ({
        ...state,
        loading: false,
        feedbackQuestion: data,
    }),
});

export default feedbackQuestionReducer;
