import createReducer from '../utils/redux-utils/createReducer';

const INITIAL_STATE = {
    apis: [],
    apiIndex: 0,
};

const apiSequence = createReducer(INITIAL_STATE, {
    STORE_API_SEQUENCE: (state, { data }) => ({
        ...state,
        apis: data,
    }),
    STORE_API_SEQUENCE_INDEX: (state, { data }) => ({
        ...state,
        apiIndex: data,
    }),
});

export default apiSequence;
