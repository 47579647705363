import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddGoal from './AddGoal';
import EditGoal from './EditGoal';
import GoalsTabList from './GoalsTabList';
import rArrow from '../../assets/rArrow.svg';
import AddIcon from '../../assets/addIcon.svg';
import { getGoalsByCategoriesAction } from '../HomeContainer/redux/actions';
import LocalisationsStore from '../../common/LocalisationsStore';
import './index.scss';

const GoalsTabView = ({
    history,
    hideAddGoalBtn,
    goalLists,
    getGoalsByCategoriesAction,
    hideGoalsSection,
    resetGoalTabValue,
}) => {
    const [view, setView] = useState(0);
    const [activeGoalDetail, setActiveGoalDetail] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        goals: _goals,
        seeAllYourGoals,
        newGoals,
    } = localisationsAfterLogin;

    useEffect(() => {
        if (resetGoalTabValue) {
            setActiveTab(0);
        }
    }, [resetGoalTabValue]);

    const refreshHomeGoals = () => {
        getGoalsByCategoriesAction();
    };

    const showModal = () => {
        switch (view) {
            case 1:
                return <AddGoal setView={setView} refreshHomeGoals={refreshHomeGoals} />;
            case 3:
                return (
                    <EditGoal
                        goalObj={activeGoalDetail}
                        editCallBack={refreshHomeGoals}
                        closeModal={() => {
                            setView(0);
                            setActiveGoalDetail({});
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const showAllGoals = () => {
        history.push('/all-goals');
    };

    return (
        !hideGoalsSection && goalLists?.length
            ? (
                <div className="goals goals-tab-view">
                    <div className="goals-tab-container">
                        <div className="goal-header">
                            <div className="title">{_goals}</div>
                            {hideAddGoalBtn != null && hideAddGoalBtn === false && (
                                <div className="add-goal" onClick={() => setView(1)}>
                                    <div className="icon">
                                        <img src={AddIcon} alt="ADD NEW GOAL" />
                                    </div>
                                    <div className="label">{newGoals}</div>
                                </div>
                            )}
                        </div>
                        <GoalsTabList
                            activeTab={activeTab}
                            fromScreen="Goals"
                            goalLists={goalLists}
                            setActiveTab={setActiveTab}
                            setView={setView}
                            setActiveGoalDetail={setActiveGoalDetail}
                            actionCallBack={refreshHomeGoals}
                            editCallBack={refreshHomeGoals}
                        />
                    </div>
                    <div className="see-all-goals" onClick={showAllGoals}>
                        <div className="title">{seeAllYourGoals}</div>
                        <div className="icon">
                            <img src={rArrow} alt="See All YOUR GOALS" />
                        </div>
                    </div>
                    {showModal()}
                </div>
            )
            : null
    );
};

GoalsTabView.defaultProps = {
    hideAddGoalBtn: true,
    hideGoalsSection: null,
};

GoalsTabView.propTypes = {
    history: PropTypes.object.isRequired,
    hideAddGoalBtn: PropTypes.bool,
    goalLists: PropTypes.array.isRequired,
    getGoalsByCategoriesAction: PropTypes.func.isRequired,
    hideGoalsSection: PropTypes.bool,
    resetGoalTabValue: PropTypes.number.isRequired,
};

const dispatchToProps = {
    getGoalsByCategoriesAction,
};

const stateToProps = state => {
    const REDUCER = state.get('HomeReducer');
    return {
        goalLists: REDUCER.goalLists,
        hideGoalsSection: REDUCER.hideGoalsSection,
        resetGoalTabValue: REDUCER.resetGoalTabValue,
    };
};


export default withRouter(connect(stateToProps, dispatchToProps)(GoalsTabView));
