/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import Slider from 'react-slick';
import MagicSliderDots from 'react-magic-slider-dots';
import Loader from '../../components/Loader';
import { fontColors, fontFamilies } from '../../common/fontMap';
import Text from '../../components/Text';
import Actionbutton from '../../components/ActionButton';
import { fetchUserInstructions, extractJsonFromUrl } from '../UnAuthenticatedContent/apis';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import './index.scss';
import Lottie from '../../components/Lottie';
import { mobileCheck } from '../../utils/functions';
import LocalisationsStore from '../../common/LocalisationsStore';

function FirstLoginExperience(props) {
    const { history } = props;

    const [data, setData] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [showInitialGrow, setShowInitialGrow] = useState(false);
    const [json, setWelcomeJson] = useState(null);
    const [showText, setShowText] = useState(true);
    const [activePage, setActivePage] = useState(0);

    const { localisationsAfterLogin } = LocalisationsStore;
    const { nextFirst, skip, NextSecond } = localisationsAfterLogin;
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    let sliderRef = null;

    const isMobile = mobileCheck();

    useEffect(() => {
        setIsLoading(true);
        fetchUserInstructions()
            .then(response => {
                setShowInitialGrow(true);
                setData(response.data.data);
                setIsLoading(false);
                setTimeout(() => {
                    setShowInitialGrow(false);
                    setShowText(false);
                }, 4500);
                if (
                    response.data.data.json_data.media_type === 1
                    || response.data.data.json_data.media_type === 2
                    || response.data.data.json_data.media_type === 3
                ) {
                    setWelcomeJson(response.data.data.json_data.welcome_json);
                } else {
                    if (isMobile) {
                        return extractJsonFromUrl(response.data.data.json_data.welcome_mobile_json);
                    }
                    return extractJsonFromUrl(response.data.data.json_data.welcome_json);
                }
            })
            .then(response => {
                setWelcomeJson(response.data);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [isMobile]);

    const handleNextClick = (index, pages) => {
        if (index === pages.length - 1) {
            history.push('/app');
        } else {
            sliderRef.slickGoTo(index + 1);
        }
    };

    const handleSkipClick = () => {
        history.push('/app');
    };

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        appendDots: dots => (
            <MagicSliderDots dots={dots} numDotsToShow={data && data.json_data.pages.length} dotWidth={30} />
        ),
        slidesToShow: 1,
        dotsClass: 'dotsClass',
        afterChange(index) {
            setActivePage(index);
        },
    };

    const renderAnimation = mediaType => {
        switch (mediaType) {
            case 0:
                return (
                    <Lottie
                        className="outer_animation_container"
                        borderRadius={20}
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: json,
                        }}
                    />
                );
            case 1:
                return <video src={json} controls muted playsInline />;
            case 2:
            case 3:
                return <img src={json} alt="animation img" />;
            default:
                return (
                    <Lottie
                        className="outer_animation_container"
                        borderRadius={20}
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: json,
                        }}
                    />
                );
        }
    };

    const updateHTML = htmlStr => (isClientPG
        ? htmlStr
            .replaceAll('<img', '<img style = "max-height:155px;width:100%;max-width:180px;"')
            .replaceAll('<br /></div><img', '</div>\n<img')
        : htmlStr.replaceAll('<img', '<img style = "max-height: 400px;width:100%"'));
    const WelcomeText = () => showText && (
    <Text
        fontsize="2.25rem"
        fontcolor={!isClientPG ? fontColors.textBlack : fontColors.black}
        fontfamily={fontFamilies.playfairBold}
        textAlign="center"
        whiteSpace="pre-wrap"
        lineHeight="42px"
    >
        {data.json_data.welcome_text.replace(', ', '\n')}
    </Text>
    );

    const SliderContent = () => (
        <Slider
            {...settings}
            ref={Slider => {
                sliderRef = Slider;
            }}
        >
            {data && data.json_data.pages.map((page, index) => (
                <div className="instruction" key={page.title}>
                    <Text
                        fontsize={!isClientPG ? '2.25rem' : '28px'}
                        lineHeight={isClientPG && '32px'}
                        fontcolor={!isClientPG ? fontColors.textBlack : fontColors.black}
                        fontfamily={fontFamilies.playfairBold}
                        margin={!isClientPG ? '0 0 0.625rem 0' : '0 0 20px 0'}
                    >
                        {page.title}
                    </Text>
                    {activePage === index
                    && <div dangerouslySetInnerHTML={{ __html: updateHTML(page.description) }} />
                    }
                    <div className="btn">
                        <Actionbutton
                            fontSize="1.125rem"
                            fontFamily={fontFamilies.TheinhardtPanRegular}
                            fontColor={fontColors.siteWhite}
                            bgColor={fontColors.electricBlue}
                            disabled={false}
                            onClick={() => handleNextClick(index, data.json_data.pages)}
                            width={
                                index === data.json_data.pages.length - 1 ? '10rem' : '6rem'
                            }
                        >
                            {index === data.json_data.pages.length - 1
                                ? NextSecond
                                : nextFirst}
                        </Actionbutton>
                    </div>
                </div>
            ))}
        </Slider>
    );

    return (
        <div
            className={`unauth_content_container_wrapper_first${isClientPG ? ' pg-unauth' : ''}`}
            style={{ marginLeft: (!isMobile && !isClientPG) ? (data && !showInitialGrow ? 0 : '10%') : 0 }}
        >
            {loading && <Loader position="absolute" showBackground />}
            {data && (
                <>
                    {!isClientPG ? (
                        <div className="full_width_intersection">
                            <div className="Welcome_image_text_wrapper">
                                {data && (
                                    <>
                                        {renderAnimation(data.json_data.media_type)}
                                        {WelcomeText()}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : !showInitialGrow && (
                        <div className="left-container">
                            <div className="media-container">
                                {renderAnimation(data.json_data.media_type)}
                            </div>
                        </div>
                    )}
                </>
            )}
            {!isClientPG ? (
                <>
                    {!showInitialGrow && (
                        <div className="unauth_content_container">
                            <Grow in={!showInitialGrow}>
                                <>
                                    <div className="right_half">
                                        <div className="skip">
                                            <Text
                                                fontsize="0.8rem"
                                                fontcolor={fontColors.textBlack}
                                                fontfamily={fontFamilies.TheinhardtPanMedium}
                                                decoration="underline"
                                                cursor="pointer"
                                                onClick={handleSkipClick}
                                            >
                                                {skip}
                                            </Text>
                                        </div>
                                        {SliderContent()}
                                    </div>
                                </>
                            </Grow>
                        </div>
                    )}
                </>
            ) : (
                <div className={`${!showText ? 'right-wrapper' : ''}`}>
                    {data && WelcomeText()}
                    <div className="right-container">
                        {!showInitialGrow && (
                            <Grow in={!showInitialGrow}>
                                {SliderContent()}
                            </Grow>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

FirstLoginExperience.propTypes = {
    error: PropTypes.shape({ message: PropTypes.string }).isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(FirstLoginExperience);
