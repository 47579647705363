import React, { useState } from 'react';
import { fontColors, fontFamilies } from '../../../../common/fontMap';
import Actionbutton from '../../../../components/ActionButton';
import { changeArticleStatusApi } from '../../../PracticesDetailsContainer/redux/apis';
import LocalosationsStore from '../../../../common/LocalisationsStore';
import './index.scss';
import PopUpStack from '../PopUpStack';

const Next = ({
    config,
    hybridData,
    required,
    state,
    callBack,
    setError,
    onClose,
    refreshCallback,
    disableParent,
    promptLoading,
    loadingState,
    setLoadingState,
}) => {
    const { localisationsAfterLogin } = LocalosationsStore;
    const [loading, setLoading] = useState(false);
    const { mandatory_question_not_answered } = localisationsAfterLogin;
    const [showPopUpStack, setShowPopUpStack] = useState({ show: false });
    const isClientPG = localStorage.getItem('client_config') === 'pg';
    const formatData = _data => {
        const data = {};
        const {
            question_response,
            quiz_response,
        } = _data;

        const questionResponse = Object.values(question_response);

        const quizResponse = Object.values(quiz_response);
        if (questionResponse.length > 0) {
            data.question_response = questionResponse.map(respObj => ({ ...respObj, response: respObj.response && respObj.response.trim() }));
        }
        if (quizResponse.length > 0) {
            // const lastIndex = quizResponse.length - 1;
            data.quiz_response = config.quizId ? (quiz_response[config.quizId] || quizResponse[0]) : quizResponse[0];
        }
        return data;
    };

    const validateQuiz = () => {
        let isValid = true;
        for (let i = 0; i < required.quiz.length; i++) {
            if (required.quiz[i]) {
                if (
                    !(
                        hybridData.quiz_response[required.quiz[i]]
                        && hybridData.quiz_response[required.quiz[i]].response.trim()
                    )
                ) {
                    isValid = false;
                    break;
                }
            }
        }

        return isValid;
    };

    const validatePromptQuestions = () => {
        let isValid = true;
        for (let i = 0; i < required.questions.length; i++) {
            if (
                !(
                    hybridData.question_response[required.questions[i]]
                    && hybridData.question_response[required.questions[i]].response.trim()
                )
            ) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const validateData = () => {
        const isValid = true;
        if (state === 4) {
            return isValid;
        }
        return validateQuiz() && validatePromptQuestions();
    };

    const informationalPopUps = _data => {
        const {
            prompt_popup_priority = [],
            show_quiz_popup,
            quiz_popup_data,
            show_goal_popup,
            prompt_popup_data,
        } = _data;
        const popUpObject = {};

        for (let i = 0; i < prompt_popup_priority.length; i++) {
            switch (prompt_popup_priority[i]) {
                case 'quiz': {
                    if (show_quiz_popup) {
                        popUpObject.quiz = quiz_popup_data;
                    }
                    break;
                }
                case 'prompt': {
                    if (show_goal_popup) {
                        popUpObject.prompt = prompt_popup_data;
                    }
                    break;
                }
                default:
                    break;
            }
        }

        return popUpObject;
    };
    const complete = () => {
        const {
            completeId,
            nextId,
        } = config;
        if (!validateData()) {
            setError({
                isError: true,
                errorMessage: mandatory_question_not_answered,
            });
        } else {
            setLoading(true);
            setError({
                isError: false,
                errorMessage: '',
            });

            if (state === 4) {
                if (nextId) {
                    disableParent(true);
                    refreshCallback(nextId);
                    // setShowNextPromptArticle(true)
                }
            } else {
                const articleData = formatData(hybridData);
                setLoading(true);
                disableParent(true);
                changeArticleStatusApi(completeId, 'complete', articleData)
                    .then(res => {
                        // callBack();
                        setLoading(false);
                        const popUpData = informationalPopUps(res.data.data);
                        if (Object.keys(popUpData).length > 0) {
                            setShowPopUpStack({
                                show: true,
                                data: popUpData,
                            });
                        } else {
                            if (nextId !== null) {
                                refreshCallback(nextId);
                            } else {
                                refreshCallback(completeId);
                            }
                            // refreshCallback(nextId && completeId);
                            // showNextPopUp()
                        }
                    });
            }
        }
    };

    const showNextPopUp = () => {
        // setShowNextPromptArticle(true);
    };

    const handleCrossClick = () => {
        setShowPopUpStack({ show: false });
        showNextPopUp();
    };

    const goBack = event => {
        const { nextId } = config;
        setShowPopUpStack({ show: false });
        if (!config.nextId) {
            onClose(event);
        } else {
            refreshCallback(nextId);
            // showNextPopUp()
        }
    };

    // const promptId = config.nextId || config.completeId;
    return (
        <div className="next">
            <Actionbutton
                fontSize="1.125rem"
                fontFamily={fontFamilies.TheinhardtPanRegular}
                fontColor={fontColors.siteWhite}
                bgColor={fontColors.electricBlue}
                disabled={false}
                width={isClientPG ? '16rem' : '20rem'}
                btnLoading={loading || (promptLoading && loadingState.nextLoading)}
                onClick={() => {
                    setLoadingState({ prevLoading: false, nextLoading: true });
                    complete();
                }}
            >
                {config.text || 'Next'}
            </Actionbutton>
            {showPopUpStack.show && (
                <PopUpStack
                    popUpData={showPopUpStack.data}
                    showNextPopUp={showNextPopUp}
                    goBack={goBack}
                    handleCrossClick={handleCrossClick}
                />
            )}
        </div>
    );
};
export default Next;
