import types from './types';

// eslint-disable-next-line camelcase
export const uploadPost = (
    // eslint-disable-next-line camelcase
    { file, desc, categoryTags, practiceTags, content, fileType, users_list, social_config, type },
    selectedKey,
    selectedHashTag,
) => ({
    type: `${types.UPLOAD_POST}_PENDING`,
    file,
    desc,
    categoryTags,
    practiceTags,
    content,
    fileType,
    users_list,
    selectedKey,
    selectedHashTag,
    social_config,
    postType: type,
});

export const uploadPostDone = () => ({
    type: `${types.UPLOAD_POST}_DONE`,
});

export const getTags = () => ({
    type: `${types.GET_TAGS}_PENDING`,
});

export const storeTags = tags => ({
    type: `${types.GET_TAGS}_DONE`,
    tags,
});

export const getFeedsList = id => ({
    type: types.GET_FEEDS_PENDING,
    id,
});

export const getFeedsListSuccess = payload => ({
    type: types.GET_FEEDS_SUCCESS,
    payload,
});

export const getFeedsListFailed = (payload = {}) => ({
    type: types.GET_FEEDS_FAILED,
    payload,
});

export const getFeedsListMore = (payload, hashTag) => ({
    type: types.GET_FEEDS_MORE_PENDING,
    payload,
    hashTag,
});

export const getFeedsListMoreSuccess = payload => ({
    type: types.GET_FEEDS_MORE_SUCCESS,
    payload,
});

export const getFeedsListMoreFailed = (payload = {}) => ({
    type: types.GET_FEEDS_MORE_FAILED,
    payload,
});

export const postLike = payload => ({
    type: types.POST_LIKE_PENDING,
    payload,
});

export const postLikeSuccess = payload => ({
    type: types.POST_LIKE_SUCCESS,
    payload,
});

export const postLikeFailed = payload => ({
    type: types.POST_LIKE_FAILURE,
    payload,
});

export const toggleCreatePostModal = value => ({
    type: types.TOGGLE_CREATE_POST_MODAL,
    value,
});

export const getThisFeedData = id => ({
    type: `${types.GET_THIS_FEED}_PENDING`,
    id,
});

export const getThisFeedsListSuccess = data => ({
    type: `${types.GET_THIS_FEED}_DONE`,
    data,
});

export const deletePost = payload => ({
    type: types.DELETE_POST_PENDING,
    payload,
});

export const deletePostSuccess = payload => ({
    type: types.DELETE_POST_SUCCESS,
    payload,
});

export const deletePostFailed = payload => ({
    type: types.DELETE_POST_FAILURE,
    payload,
});

export const getSavedPosts = payload => ({
    type: types.GET_SAVED_POSTS_PENDING,
    payload,
});

export const getSavedPostsSuccess = payload => ({
    type: types.GET_SAVED_POSTS_SUCCESS,
    payload,
});

export const getSavedPostsFailed = (payload = {}) => ({
    type: types.GET_SAVED_POSTS_FAILURE,
    payload,
});

export const postSavedPosts = payload => ({
    type: types.POST_SAVED_POSTS_PENDING,
    payload,
});

export const postSavedPostsSuccess = payload => ({
    type: types.POST_SAVED_POSTS_SUCCESS,
    payload,
});

export const postSavedPostsFailed = (payload = {}) => ({
    type: types.POST_SAVED_POSTS_FAILURE,
    payload,
});

export const getLikeDetails = payload => ({
    type: types.GET_LIKE_DETAILS_PENDING,
    payload,
});

export const getLikeDetailsSuccess = payload => ({
    type: types.GET_LIKE_DETAILS_SUCCESS,
    payload,
});

export const getLikeDetailsFailed = (payload = {}) => ({
    type: types.GET_LIKE_DETAILS_FAILURE,
    payload,
});

export const postFollowUser = payload => ({
    type: types.POST_FOLLOW_USER_PENDING,
    payload,
});

export const postFollowUserSuccess = payload => ({
    type: types.POST_FOLLOW_USER_SUCCESS,
    payload,
});

export const postFollowUserFailed = (payload = {}) => ({
    type: types.POST_FOLLOW_USER_FAILURE,
    payload,
});

export const getUsersOfType = userType => ({
    type: `${types.GET_USERS_OF_TYPE}_PENDING`,
    userType,
});

export const storeUsersListOfType = data => ({
    type: `${types.GET_USERS_OF_TYPE}_DONE`,
    data,
});

export const getAllComments = articleId => ({
    type: `${types.GET_ALL_COMMENTS}_PENDING`,
    articleId,
});

export const storeAllComments = (data, articleId) => ({
    type: `${types.GET_ALL_COMMENTS}_DONE`,
    data,
    articleId,
});

export const postComment = (id, commentValue, selectedUsers, parrentid) => ({
    type: `${types.POST_COMMENT}_PENDING`,
    articleId: id,
    text: commentValue,
    selectedUsers,
    parrentid,
});

export const postCommentDone = () => ({
    type: `${types.POST_COMMENT}_DONE`,
});

export const likeComment = (id, articleId) => ({
    type: `${types.LIKE_COMMENT}_PENDING`,
    id,
    articleId,
});

export const upDateComments = () => ({
    type: `${types.LIKE_COMMENT}_DONE`,
});

export const deleteComment = (id, articleId, replycount) => ({
    type: `${types.DELETE_COMMENT}_PENDING`,
    id,
    articleId,
    replycount,
});

export const updateFeeds = ({ articleId, operation, replycount }) => ({
    type: types.UPDATE_FEEDS,
    articleId,
    operation,
    replycount,
});

export const postQuizAnswer = payload => ({
    type: `${types.POST_QUIZ_ANSWER}_PENDING`,
    payload,
});

export const successPostQuizAnswer = data => ({
    type: `${types.POST_QUIZ_ANSWER}_SUCCESS`,
    data,
});

export const resetPostQuizData = () => ({
    type: types.RESET_QUIZ_DATA,
});

export const reportPost = feedId => ({
    type: `${types.REPORT_POST}_PENDING`,
    feedId,
});

export const successReportPost = () => ({
    type: `${types.REPORT_POST}_SUCCESS`,
});

export const failedReportPost = () => ({
    type: `${types.REPORT_POST}_FAILED`,
});

export const resetAllFeedFetched = () => ({
    type: types.RESET_ALL_FEED_FETCHED,
});

export const fetchUsersList = (page, query) => ({
    type: `${types.FETCH_USERS_LIST}_PENDING`,
    page,
    query,
});

export const successFetchUsersList = data => ({
    type: `${types.FETCH_USERS_LIST}_SUCCESS`,
    data,
});

export const failedFetchUsersList = () => ({
    type: `${types.FETCH_USERS_LIST}_FAILED`,
});

export const sharePost = payload => ({
    type: `${types.SHARE_POST}_PENDING`,
    payload,
});

export const successSharePost = () => ({
    type: `${types.SHARE_POST}_SUCCESS`,
});

export const failedSharePost = () => ({
    type: `${types.SHARE_POST}_FAILED`,
});

export const postUnfollowUser = (payload, userType) => ({
    type: `${types.POST_UNFOLLOW_USER}_PENDING`,
    payload,
    userType,
});

export const getUsersFollowed = () => ({
    type: `${types.GET_USERS_FOLLOWED}_PENDING`,
});

export const successGetUsersFollowed = data => ({
    type: `${types.GET_USERS_FOLLOWED}_SUCCESS`,
    data,
});

export const failedGetUsersFollowed = () => ({
    type: `${types.GET_USERS_FOLLOWED}_FAILED`,
});

export const setFollowUnfollowInFeeds = (userId, isFollow) => ({
    type: types.SET_FOLLOW_UNFOLLOW_FEEDS,
    userId,
    isFollow,
});

export const fetchMoreUsersList = (page, query) => ({
    type: `${types.FETCH_MORE_USERS_LIST}_PENDING`,
    page,
    query,
});

export const successFetchMoreUsersList = data => ({
    type: `${types.FETCH_MORE_USERS_LIST}_SUCCESS`,
    data,
});

export const failedFetchMoreUsersList = () => ({
    type: `${types.FETCH_MORE_USERS_LIST}_FAILED`,
});

export const removeBookMark = articleId => ({
    type: `${types.REMOVE_BOOKMARK}_PENDING`,
    articleId,
});

export const successRemoveBookMark = () => ({
    type: `${types.REMOVE_BOOKMARK}_SUCCESS`,
});

export const failedRemoveBookMark = () => ({
    type: `${types.REMOVE_BOOKMARK}_FAILED`,
});

export const getTeamMembersList = () => ({
    type: `${types.GET_TEAM_MEMBERS}_PENDING`,
});

export const storeTeamMembersList = data => ({
    type: `${types.GET_TEAM_MEMBERS}_SUCCESS`,
    data,
});
