import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.div`
    ${({ height, width }) => `
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${height || 'auto'};
        width: ${width || 'auto'};
    `}
`;

export default function SvgPicture({ height, width, style, children, className }) {
    return (
        <Image width={width} height={height} style={style} className={className}>
            {children}
        </Image>
    );
}

SvgPicture.defaultProps = {
    style: {},
    className: '',
};

SvgPicture.propTypes = {
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};
