/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import faqSaga from './redux/sagas';
import faqReducer from './redux/reducer';
import { getFaqContent, getFaqContentFromToken } from './redux/actions';
import downCollapse from '../../assets/downCollapse.svg';
import upExpand from '../../assets/upExpand.svg';
import AuthStore from '../../common/AuthStore';
import './index.scss';

function FaqComponent({ getFaqContent, faqContent, getFaqContentFromToken }) {
    const [data, setData] = useState([]);
    useEffect(() => {
        let baseurl;
        const urlString = window.location.href;
        const url = new URL(urlString);
        if (urlString.includes('baseurl')) {
            baseurl = window.atob(url.searchParams.get('baseurl'));
            const email = url.searchParams.get('email');
            getFaqContent(baseurl, email);
        } else {
            const code = url.searchParams.get('userToken') || AuthStore.accessToken;
            getFaqContentFromToken(code);
        }
    }, [getFaqContent, getFaqContentFromToken]);

    useEffect(() => {
        if (faqContent.length > 0) {
            const data = [
                ...faqContent.map(row => {
                    // eslint-disable-next-line no-param-reassign
                    row.collapse = true;
                    return row;
                }),
            ];
            setData(data);
        }
    }, [faqContent]);

    const openQuestion = event => {
        const index = Number(event.currentTarget.dataset.faqindexd);
        const value = event.currentTarget.dataset.faqcollapsevalue;
        const dataCopy = [...data];
        dataCopy[index].collapse = value === 'true';
        setData(dataCopy);
    };

    return (
        <div className="faq_wrapper">
            {data.length > 0 && (
                <>
                    <span className="heading">FAQs</span>
                    <div className="faq_main">
                        {data.map((row, index) => (
                            <div className="faq_row" key={`faq-question-${index}`}>
                                <div className="question_answer_container">
                                    <div dangerouslySetInnerHTML={{ __html: row.question }} />
                                    <div
                                        className={row.collapse ? 'answer answer_hidden' : 'answer answer_shown'}
                                        dangerouslySetInnerHTML={{ __html: row.answer_text }}
                                    />
                                </div>
                                <div
                                    className="btn"
                                    onClick={openQuestion}
                                    data-faqindexd={index}
                                    data-faqcollapsevalue={!row.collapse}
                                >
                                    <img src={row.collapse ? downCollapse : upExpand} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

const stateToProps = state => {
    const REDUCER = state.get('faqReducer');
    return {
        faqContent: REDUCER.faqContent,
    };
};

export { faqSaga, faqReducer };

const dispatchToProps = {
    getFaqContent,
    getFaqContentFromToken,
};

FaqComponent.propTypes = {
    getFaqContent: PropTypes.func.isRequired,
    getFaqContentFromToken: PropTypes.func.isRequired,
    faqContent: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string,
            answer_text: PropTypes.string,
        }),
    ).isRequired,
};

export default connect(stateToProps, dispatchToProps)(FaqComponent);
