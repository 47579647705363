import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddNewPost from './AddNewPost';
import { HomeIcon, CoachingIcon, LoadIcon, SocialLearning } from './assets/svg';
import FeedsList from './FeedsList';
import upArrow from './assets/upArrow.png';
import FeedHeader from './FeedHeader';
import './index.scss';
import SavedPosts from './SavedPosts';
import Footer from '../../components/Footer';
import crossIcon from './assets/cross.svg';
import WeeklyQuestions from '../WeeklyQuestions';
import SetGoals from '../Profile/SetGoals';
import SkeletonLoader from './SkeletonLoader';
import SavedPostsContainer from './SavedPostsContainer';
import LocalisationsStore from '../../common/LocalisationsStore';
import BadgeReceived from './BadgeReceived';
import MyProgress from '../MyProgress';
import learningTeam from './assets/learningTeam.svg';
import TeamMembers from './TeamMembers';
import PreAdditionalQuestion from '../PreAdditionalQuestion';
import PreferencesModal from '../PreferencesModal';
import HomeIcona from './assets/HomeIcon.svg';
import CoachingIcona from './assets/CoachingIcon.svg';
import SocialLearninga from './assets/SocialLearning.svg';
import CoachingJourney from './CoachingJourney';
import Mycoachingjourney from './assets/mycoachingjourney.svg';
import StreakBadgeReceived from './StreakPopup';
import AccountExpired from './AccountExpired';

export default function Feeds({
    feedsList,
    isLoading,
    getFeedsList,
    getFeedsListMore,
    allFeedFetched,
    isFeedLoading,
    uploadPost,
    getTags,
    tags,
    postLike,
    isLiked,
    uploadingPost,
    imageUploaderModal,
    toggleCreatePostModal,
    likedId,
    history,
    isDeleted,
    deletePost,
    getSavedPosts,
    postSavedPosts,
    savedPosts,
    savedPostId,
    likeDetails,
    followedId,
    getLikeDetails,
    postFollowUser,
    isLikeLoading,
    getAllComments,
    articleComments,
    postComment,
    likeComment,
    deleteComment,
    isDeleting,
    reportPost,
    resetAllFeedFetched,
    postUnfollowUser,
    getUsersFollowed,
    followedUsers,
    setFollowUnfollowInFeeds,
    vcrbDetails,
    getVcrbData,
    getNotes,
    addNotes,
    notes,
    fetchUsersList,
    userListForSharePost,
    sharePost,
    fetchMoreUsersList,
    allUsersFetched,
    isInitialFeedsLoading,
    removeBookMark,
    setThisFeed,
    logoutUser,
    bannerOpened,
    earnedBannerBadge,
    getUserProgressData,
    getTeamMembersList,
    teamMembersList,
    storeTeamMembersList,
    getUserData,
    setFilterString,
    storeFeedFilters,
    filterString,
    getSequenceOfApis,
    getNewPosts,
    newerPostsCount,
    storeNewPostsCount,
    badgeOfNewerPostsCount,
    badgeOfNewerPostsCountCoachingJourney,
    badgeOfNewerPostsCountSocialGroup,
    storeApiIndex,
    getCoachinJourneyData,
    removeIsNewTagFromLocalState,
    articleIndexStartTimeTracker,
    articleIndexEndTimeTracker,
    userScrollAnalytics,
    emptyNotificationList,
    resetPageNumber,
    storeNumberOfNewPostsCount,
    storeNumberOfNewPostsCountSocialGroup,
    pageAnalytics,
    clientExpired,
}) {
    const [selectedKey, setSelectedKey] = useState(Number(localStorage.getItem('selectedMenu') || 1));
    // eslint-disable-next-line no-unused-vars
    const [showInterventionPage, setShowInterventionPage] = useState(false);
    const [showInterventionTransitionAnimation, runAmination] = useState(false);
    const [clickedArticle, setArticleId] = useState(null);
    const [selectedHashTag, setSelectedHashTag] = useState('');
    const [page, setPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [goalScreen, setGoalScreen] = useState('');
    const [showBookMarks, setShowBookMarks] = useState(false);
    const [teamMembers, setShowTeamMembers] = useState(false);
    const [showPreferences, setShowPreferencesModal] = useState(false);

    const getTagsCall = useCallback(getTags, []);
    const resetAllFeedFetchedCall = useCallback(resetAllFeedFetched, []);
    const getFeedsListCall = useCallback(getFeedsList, []);
    const getSavedPostsCall = useCallback(getSavedPosts, []);

    const localisationsAfterLogin = LocalisationsStore.localisationsAfterLogin || {
        yourFeeds: '',
        yourfeed_article: '',
        newPost: '',
        myCoachingJourney: '',
        myGoals: '',
        reachedEnd: '',
        updating: '',
        filter: '',
    };
    const {
        yourFeeds,
        yourfeed_article,
        newPost,
        myCoachingJourney,
        myGoals,
        reachedEnd,
        updating,
        filter,
    } = localisationsAfterLogin;

    let socialGroup;
    if (localStorage.userProfile) {
        socialGroup = JSON.parse(localStorage.userProfile).social_group;
    }

    const timer = useRef(null);

    const reverseDirectionLanguage = localStorage.getItem('languageIsRightToLeft') === 'true';

    const isVersion2 = localStorage.getItem('isVersion2') === 'true';

    const [coachingJourneyStartTime, setCoachingJourneyStartTime] = useState(
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    );

    useEffect(() => {
        if (feedsList.length > 0) {
            timer.current = setInterval(() => {
                const feedsWithoutBanners = feedsList.filter(feed => feed.file_type !== 'banner');
                const time = Math.round(new Date(feedsWithoutBanners[0].mapping_date).getTime() / 1000);
                getNewPosts(time, selectedKey);
            }, 30000);
        }
        return () => {
            clearInterval(timer.current);
            storeApiIndex(0);
        };
    }, [feedsList, getNewPosts, selectedKey, storeApiIndex]);

    useEffect(() => {
        if (teamMembersList.length > 0) {
            setShowTeamMembers(true);
        }
    }, [teamMembersList]);

    useEffect(() => {
        getTagsCall(history);
        getUserData();
        getSequenceOfApis();
        if (localStorage.quizResponseShared) {
            setSelectedKey(Number(localStorage.selectedMenu));
        }
        localStorage.removeItem('quizResponseShared');
    }, [getTagsCall, getSequenceOfApis, history, getUserData]);

    useEffect(() => {
        getSavedPostsCall();
    }, [getSavedPostsCall]);

    useEffect(() => {
        if (selectedKey !== 3 && selectedKey !== 0 && selectedKey !== 5) {
            getFeedsListCall(1, selectedKey, selectedHashTag);
            resetAllFeedFetchedCall();
            setPage(1);
            // eslint-disable-next-line no-use-before-define
            scrollToTop();
        }
        if (selectedKey === 5) {
            getCoachinJourneyData({ queryString: '' });
        }
    }, [selectedHashTag, getFeedsListCall, resetAllFeedFetchedCall, setPage, selectedKey, getCoachinJourneyData]);

    const getGoalScreen = type => {
        setGoalScreen(type);
    };

    const showInterventionDetailPage = (val, feedId) => {
        runAmination(true);
        setArticleId(feedId);
        setThisFeed(feedId);
        setTimeout(() => {
            setShowInterventionPage(val);
            history.push(`/feed-details-article/${feedId}`);
        }, 1000);
    };

    const showCommentsForThisArticle = articleId => {
        getAllComments(articleId);
    };

    const handleClickOnHashTag = e => {
        const { currentTarget: { dataset: { tag } } } = e;
        setSelectedHashTag(tag);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    function handleOpen() {
        toggleCreatePostModal(true);
    }

    const fetchNewerPosts = e => {
        e.stopPropagation();
        clearInterval(timer.current);
        storeNewPostsCount(0);
        getFeedsListCall(1, selectedKey, selectedHashTag);
    };

    const _renderTabsContent = key => {
        if ((key === 1 || key === 2 || key === 4) && selectedKey !== 5) {
            return (
                <Fragment>
                    <div
                        className={newerPostsCount > 0 ? 'new_posts_blip' : 'new_posts_blip dont_show_blip'}
                        onClick={fetchNewerPosts}
                    >
                        <div>
                            <span>{newPost}</span>
                        </div>
                    </div>
                    <div className="feedCol">
                        <FeedsList
                            history={history}
                            isDeleting={isDeleting}
                            isLoading={isLoading}
                            feedsList={feedsList}
                            getFeedsListMore={getFeedsListMore}
                            allFeedFetched={allFeedFetched}
                            postLike={postLike}
                            isLiked={isLiked}
                            likedId={likedId}
                            showInterventionPage={showInterventionTransitionAnimation}
                            setShowInterventionPage={showInterventionDetailPage}
                            isDeleted={isDeleted}
                            deletePost={deletePost}
                            postSavedPosts={postSavedPosts}
                            savedPosts={savedPosts}
                            savedPostId={savedPostId}
                            likeDetails={likeDetails}
                            followedId={followedId}
                            getLikeDetails={getLikeDetails}
                            postFollowUser={postFollowUser}
                            isLikeLoading={isLikeLoading}
                            showCommentsForThisArticle={showCommentsForThisArticle}
                            articleComments={articleComments}
                            postComment={postComment}
                            likeComment={likeComment}
                            deleteComment={deleteComment}
                            clickedArticle={clickedArticle}
                            reportPost={reportPost}
                            handleClickOnHashTag={handleClickOnHashTag}
                            selectedHashTag={selectedHashTag}
                            page={page}
                            setPage={setPage}
                            postUnfollowUser={postUnfollowUser}
                            getUsersFollowed={getUsersFollowed}
                            followedUsers={followedUsers}
                            setFollowUnfollowInFeeds={setFollowUnfollowInFeeds}
                            fetchUsersList={fetchUsersList}
                            userListForSharePost={userListForSharePost}
                            sharePost={sharePost}
                            fetchMoreUsersList={fetchMoreUsersList}
                            allUsersFetched={allUsersFetched}
                            selectedKey={selectedKey}
                            removeBookMark={removeBookMark}
                            handleOpen={handleOpen}
                            setSelectedKey={setSelectedKey}
                            bannerOpened={bannerOpened}
                            removeIsNewTagFromLocalState={removeIsNewTagFromLocalState}
                            articleIndexStartTimeTracker={articleIndexStartTimeTracker}
                            articleIndexEndTimeTracker={articleIndexEndTimeTracker}
                            userScrollAnalytics={userScrollAnalytics}
                            storeNumberOfNewPostsCount={storeNumberOfNewPostsCount}
                            storeNumberOfNewPostsCountSocialGroup={storeNumberOfNewPostsCountSocialGroup}
                            badgeOfNewerPostsCount={badgeOfNewerPostsCount}
                            badgeOfNewerPostsCountSocialGroup={badgeOfNewerPostsCountSocialGroup}
                        />
                        {allFeedFetched && (
                            <div className="loaderWrapper">
                                <div
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={-2}
                                    onClick={scrollToTop}
                                    className="outline-none"
                                >
                                    <img
                                        src={upArrow}
                                        width="20"
                                        height="12"
                                        alt="upArrow"
                                        className="cursor-pointer"
                                    />
                                </div>
                                <div className="updating">{reachedEnd}</div>
                            </div>
                        )}
                        {isFeedLoading && (
                            <div className="loaderWrapper">
                                <LoadIcon />
                                <div className="updating">{updating}</div>
                            </div>
                        )}
                    </div>
                </Fragment>
            );
        }
        if (selectedKey === 5) {
            return <CoachingJourney coachingJourneyStartTime={coachingJourneyStartTime} />;
        }
        return (
            <div className="feedCol">
                <SetGoals
                    vcrbDetails={vcrbDetails}
                    getVcrbData={getVcrbData}
                    isLoading={isLoading}
                    addNotes={addNotes}
                    getNotes={getNotes}
                    notes={notes}
                    getGoalScreen={getGoalScreen}
                    className="feeds_vcrb"
                />
            </div>
        );
    };

    function handleClose() {
        toggleCreatePostModal(false);
    }

    const selectedMenu = key => {
        setSelectedKey(key);
        if (key !== 2) {
            setFilterString('content_type=&chapter=');
            storeFeedFilters(null);
        }
        localStorage.setItem('selectedMenu', key);
        if (coachingJourneyStartTime.length > 0 && key !== 5) {
            const userProfile = localStorage.userProfile ? JSON.parse(localStorage.userProfile).id.toString() : '';
            pageAnalytics({
                page_tag: 'timeLineHome',
                device_id: localStorage.fcmToken,
                start_time: coachingJourneyStartTime,
                end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                user: userProfile,
            });
            setCoachingJourneyStartTime('');
        }
        storeNewPostsCount(0);
        setShowBookMarks(false);
    };

    const handleClickOnCross = () => {
        setSelectedHashTag('');
    };

    const handleClickOnMore = () => {
        setShowBookMarks(true);
        setSelectedKey(0);
    };

    const showTeamMembers = () => {
        getTeamMembersList();
    };

    const closeTeamList = () => {
        setShowTeamMembers(false);
    };

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    }

    return (
        <div
            className={`feedsWrapper ${selectedKey === 1 ? 'yourFeeds ' : ' coachingJourney'} ${
                showInterventionTransitionAnimation ? 'blur_bg' : ''
            }`}
            data-test="feeds_test_id"
        >
            {clientExpired && moveToClientExpirePage() }
            <FeedHeader
                handleOpen={handleOpen}
                logoutUser={logoutUser}
                selectedMenu={selectedMenu}
                handleClickOnMore={handleClickOnMore}
                history={history}
                getUserProgressData={getUserProgressData}
                selectedKey={selectedKey}
                showTeamMembers={showTeamMembers}
                storeTeamMembersList={storeTeamMembersList}
                setShowPreferencesModal={setShowPreferencesModal}
                selectedHashTag={selectedHashTag}
                filterString={filterString}
                setPage={setPage}
                storeFeedFilters={storeFeedFilters}
                emptyNotificationList={emptyNotificationList}
                resetPageNumber={resetPageNumber}
                setSelectedKey={setSelectedKey}
                setSelectedHashTag={setSelectedHashTag}
            />
            <AddNewPost
                handleClose={handleClose}
                imageUploaderModal={imageUploaderModal}
                uploadPost={uploadPost}
                tags={tags}
                uploadingPost={uploadingPost}
                selectedKey={selectedKey}
                selectedHashTag={selectedHashTag}
                setSelectedHashTag={setSelectedHashTag}
                history={history}
            />
            <TeamMembers teamMembers={teamMembers} teamMembersList={teamMembersList} handleClose={closeTeamList} />
            <MyProgress />
            <WeeklyQuestions setSelectedKey={setSelectedKey} />
            <PreAdditionalQuestion />
            <BadgeReceived earnedBannerBadge={earnedBannerBadge} />
            <StreakBadgeReceived />
            <AccountExpired />
            <PreferencesModal showPreferences={showPreferences} setShowPreferencesModal={setShowPreferencesModal} />
            {isInitialFeedsLoading ? (
                <SkeletonLoader />
            ) : (
                <div className="flex-grid-wrapper">
                    <div className={reverseDirectionLanguage ? 'reverse_direction col1' : 'col1'}>
                        <div
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                            className={`nav outline-none ${selectedKey === 1 ? 'selectedNav' : ''}`}
                            onClick={() => selectedMenu(1)}
                        >
                            <div className="aligner homeFeed" onClick={scrollToTop}>
                                <div>
                                    <img src={HomeIcona} alt="homeIcone" />
                                    <div className="tab_name">{yourFeeds}</div>
                                </div>
                            </div>
                            {selectedKey === 1 && badgeOfNewerPostsCount !== null && badgeOfNewerPostsCount > 0 && (
                                <div className="newposts_number_circle">{badgeOfNewerPostsCount}</div>
                            )}
                        </div>
                        {!isVersion2 && (
                            <div
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={-1}
                                className={`nav outline-none ${selectedKey === 2 ? 'selectedNav coach' : ''}`}
                                onClick={() => selectedMenu(2)}
                            >
                                <div className="aligner coaching">
                                    <div>
                                        <img src={CoachingIcona} alt="CoachinIcon" />
                                        <div className="tab_name">{yourfeed_article}</div>
                                    </div>
                                </div>
                                {selectedKey === 2
                                    && badgeOfNewerPostsCountCoachingJourney !== null
                                    && badgeOfNewerPostsCountCoachingJourney > 0 && (
                                        <div className="newposts_number_circle">
                                            {badgeOfNewerPostsCountCoachingJourney}
                                        </div>
                                )}
                            </div>
                        )}
                        <div
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={-1}
                            className={`nav outline-none ${selectedKey === 5 ? 'selectedNav coach' : ''}`}
                            onClick={() => selectedMenu(5)}
                        >
                            <div className="aligner coaching">
                                <div>
                                    <img src={Mycoachingjourney} alt="CoachinIcon" />
                                    <div className="tab_name">{myCoachingJourney}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={-1}
                            className={`nav outline-none ${selectedKey === 3 ? 'selectedNav coach' : ''}`}
                            onClick={() => selectedMenu(3)}
                        >
                            <div className="aligner coaching">
                                <img src={CoachingIcona} alt="CoachinIcon" />
                                <div className="tab_name">{myGoals}</div>
                            </div>
                            <div className="dot" />
                        </div>
                        {socialGroup && socialGroup.length > 0 && (
                            <div
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={-1}
                                className={`nav outline-none ${selectedKey === 4 ? 'selectedNav' : ''}`}
                                onClick={() => selectedMenu(4)}
                            >
                                <div className="aligner homeFeed" onClick={scrollToTop}>
                                    <div>
                                        <img src={SocialLearninga} alt="SocialLearninga" />
                                        <div className="tab_name">{socialGroup[0].header_text}</div>
                                    </div>
                                </div>
                                {selectedKey === 4
                                    && badgeOfNewerPostsCountSocialGroup !== null
                                    && badgeOfNewerPostsCountSocialGroup > 0 && (
                                        <div className="newposts_number_circle">
                                            {badgeOfNewerPostsCountSocialGroup}
                                        </div>
                                )}
                            </div>
                        )}
                        {selectedKey === 4 && (
                            <div className="social_learning_group_chip" onClick={showTeamMembers}>
                                <img src={learningTeam} alt="team" />
                                <div>View your team</div>
                            </div>
                        )}
                        {selectedHashTag && (selectedKey === 1 || selectedKey === 4) && (
                            <div className="filtered_hashtags">
                                <div className="break" />
                                <div className="filters_label">{filter}</div>
                                <div className="hashtag_bubble">
                                    <span className="hashtag">{selectedHashTag}</span>
                                    <img
                                        className="cross_btn"
                                        src={crossIcon}
                                        alt=""
                                        onClick={handleClickOnCross}
                                        role="none"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {!showBookMarks && (
                        <>
                            <div className="col2">{_renderTabsContent(selectedKey)}</div>
                            <div className={reverseDirectionLanguage ? 'reverse_direction col3' : 'col3'}>
                                <SavedPosts
                                    savedPosts={savedPosts}
                                    handleClickOnMore={handleClickOnMore}
                                    history={history}
                                />
                                <div className="rightSidebarFooter">
                                    <Footer />
                                </div>
                            </div>
                        </>
                    )}
                    {showBookMarks && (
                        <SavedPostsContainer
                            savedPosts={savedPosts}
                            history={history}
                            removeBookMark={removeBookMark}
                        />
                    )}
                </div>
            )}
            <div className="mobile_bottom_bar">
                <div
                    onClick={() => selectedMenu(1)}
                    className={selectedKey === 1 ? 'selectedMobileNav' : 'unSelectedMobileNav'}
                >
                    <span>
                        {selectedKey === 1 && badgeOfNewerPostsCount !== null && badgeOfNewerPostsCount > 0 && (
                            <div className="newposts_number_circle">{badgeOfNewerPostsCount}</div>
                        )}
                        <HomeIcon />
                    </span>
                </div>
                <div
                    onClick={() => selectedMenu(2)}
                    className={selectedKey === 2 ? 'selectedMobileNav' : 'unSelectedMobileNav'}
                >
                    <span>
                        {selectedKey === 2
                            && badgeOfNewerPostsCountCoachingJourney !== null
                            && badgeOfNewerPostsCountCoachingJourney > 0 && (
                                <div className="newposts_number_circle">{badgeOfNewerPostsCountCoachingJourney}</div>
                        )}
                        <CoachingIcon />
                    </span>
                </div>
                <div
                    onClick={() => selectedMenu(5)}
                    className={selectedKey === 5 ? 'selectedMobileNav' : 'unSelectedMobileNav'}
                >
                    <span>
                        <img src={Mycoachingjourney} alt="CoachinIcon" />
                    </span>
                </div>
                {socialGroup && socialGroup.length > 0 && (
                    <div
                        onClick={() => selectedMenu(4)}
                        className={selectedKey === 4 ? 'selectedMobileNav' : 'unSelectedMobileNav'}
                    >
                        <span>
                            {selectedKey === 4
                                && badgeOfNewerPostsCountSocialGroup !== null
                                && badgeOfNewerPostsCountSocialGroup > 0 && (
                                    <div className="newposts_number_circle">{badgeOfNewerPostsCountSocialGroup}</div>
                            )}
                            <SocialLearning />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

Feeds.defaultProps = {
    tags: {
        practice: [],
        category: [],
    },
    history: {},
    articleComments: [],
    earnedBannerBadge: {
        badge_id: 0,
        hasUserEarnedBadge: false,
    },
    badgeOfNewerPostsCount: null,
    badgeOfNewerPostsCountCoachingJourney: null,
    badgeOfNewerPostsCountSocialGroup: null,
    followedId: 0,
};

Feeds.propTypes = {
    feedsList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getFeedsList: PropTypes.func.isRequired,
    getFeedsListMore: PropTypes.func.isRequired,
    allFeedFetched: PropTypes.bool.isRequired,
    isFeedLoading: PropTypes.bool.isRequired,
    uploadPost: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    tags: PropTypes.shape({
        practice: PropTypes.array,
        category: PropTypes.array,
    }),
    postLike: PropTypes.func.isRequired,
    isLiked: PropTypes.bool.isRequired,
    uploadingPost: PropTypes.bool.isRequired,
    imageUploaderModal: PropTypes.bool.isRequired,
    toggleCreatePostModal: PropTypes.func.isRequired,
    likedId: PropTypes.number.isRequired,
    history: PropTypes.object,
    isDeleted: PropTypes.bool.isRequired,
    deletePost: PropTypes.func.isRequired,
    getSavedPosts: PropTypes.func.isRequired,
    postSavedPosts: PropTypes.func.isRequired,
    savedPosts: PropTypes.array.isRequired,
    savedPostId: PropTypes.number.isRequired,
    likeDetails: PropTypes.array.isRequired,
    followedId: PropTypes.number,
    getLikeDetails: PropTypes.func.isRequired,
    postFollowUser: PropTypes.func.isRequired,
    isLikeLoading: PropTypes.bool.isRequired,
    articleComments: PropTypes.array,
    getAllComments: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    reportPost: PropTypes.func.isRequired,
    resetAllFeedFetched: PropTypes.func.isRequired,
    postUnfollowUser: PropTypes.func.isRequired,
    getUsersFollowed: PropTypes.func.isRequired,
    followedUsers: PropTypes.array.isRequired,
    setFollowUnfollowInFeeds: PropTypes.func.isRequired,
    vcrbDetails: PropTypes.arrayOf(
        PropTypes.shape({
            detail: PropTypes.string.isRequired,
            heading: PropTypes.string.isRequired,
            inner_subheading: PropTypes.string.isRequired,
            subheading: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
    ).isRequired,
    getVcrbData: PropTypes.func.isRequired,
    getNotes: PropTypes.func.isRequired,
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    fetchUsersList: PropTypes.func.isRequired,
    userListForSharePost: PropTypes.array.isRequired,
    sharePost: PropTypes.func.isRequired,
    fetchMoreUsersList: PropTypes.func.isRequired,
    allUsersFetched: PropTypes.bool.isRequired,
    isInitialFeedsLoading: PropTypes.bool.isRequired,
    removeBookMark: PropTypes.func.isRequired,
    setThisFeed: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    bannerOpened: PropTypes.func.isRequired,
    earnedBannerBadge: PropTypes.shape({
        badge_id: PropTypes.number.isRequired,
        hasUserEarnedBadge: PropTypes.bool.isRequired,
    }),
    getUserProgressData: PropTypes.func.isRequired,
    getTeamMembersList: PropTypes.func.isRequired,
    teamMembersList: PropTypes.arrayOf(
        PropTypes.shape({
            email: PropTypes.string.isRequired,
            follower_following: PropTypes.bool.isRequired,
            full_name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            profile_picture: PropTypes.string.isRequired,
        }),
    ).isRequired,
    storeTeamMembersList: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
    setFilterString: PropTypes.func.isRequired,
    storeFeedFilters: PropTypes.func.isRequired,
    filterString: PropTypes.string.isRequired,
    getSequenceOfApis: PropTypes.func.isRequired,
    getNewPosts: PropTypes.func.isRequired,
    newerPostsCount: PropTypes.number.isRequired,
    storeNewPostsCount: PropTypes.func.isRequired,
    badgeOfNewerPostsCount: PropTypes.number,
    badgeOfNewerPostsCountCoachingJourney: PropTypes.number,
    badgeOfNewerPostsCountSocialGroup: PropTypes.number,
    storeApiIndex: PropTypes.func.isRequired,
    getCoachinJourneyData: PropTypes.func.isRequired,
    removeIsNewTagFromLocalState: PropTypes.func.isRequired,
    articleIndexStartTimeTracker: PropTypes.func.isRequired,
    articleIndexEndTimeTracker: PropTypes.func.isRequired,
    userScrollAnalytics: PropTypes.func.isRequired,
    emptyNotificationList: PropTypes.func.isRequired,
    resetPageNumber: PropTypes.func.isRequired,
    storeNumberOfNewPostsCount: PropTypes.func.isRequired,
    storeNumberOfNewPostsCountSocialGroup: PropTypes.func.isRequired,
    pageAnalytics: PropTypes.func.isRequired,
    clientExpired: PropTypes.bool.isRequired,
};
