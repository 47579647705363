import React from 'react';
import PropTypes from 'prop-types';
import './contentType.scss';

export default function VideoContent({ feedData }) {
    return (
        <div className="VideoContent">
            <video src={feedData.file} controls muted playsInline className="video">
                <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
            </video>
        </div>
    );
}

VideoContent.propTypes = {
    feedData: PropTypes.object.isRequired,
};
