import React from 'react';
import PropTypes from 'prop-types';
import Actionbutton from '../../components/ActionButton';
import { fontColors, fontFamilies } from '../../common/fontMap';

export default function SurveyAction({ practiceId, changePracticeStatus, surveyUrl, article }) {
    return (
        <div className="action_wrapper">
            <Actionbutton
                fontSize="1.125rem"
                fontFamily={fontFamilies.TheinhardtPanLight}
                fontColor={fontColors.siteWhite}
                bgColor={fontColors.electricBlue}
                disabled={false}
                onClick={() => {
                    window.open(surveyUrl);
                    // changePracticeStatus(practiceId, 'complete');
                }}
                width="20rem"
            >
                {article.article_buttons.find(elem => elem.key === 'take_a_survey').text}
            </Actionbutton>
        </div>
    );
}

SurveyAction.propTypes = {
    practiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    changePracticeStatus: PropTypes.func.isRequired,
    surveyUrl: PropTypes.string.isRequired,
    article: PropTypes.object.isRequired,
};
