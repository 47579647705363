/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WarningModal from '../../components/WarningModal';
import {
    getArticleDetails,
    getPracticeDetails,
    getSubPromptDetails,
    resetPromptData,
    storeProTagViewed,
} from './redux/actions';
import Text from '../../components/Text';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './promptDetails.scss';
import ArticleAction from './ArticleAction';
import { Cross } from '../UnAuthenticatedContent/assets/svg';
import TodoAction from './TodoAction';
import CompleteAction from './CompleteAction';
import OrientationAction from './OrientationAction';
import SurveyAction from './SurveyAction';
import { changeArticleStatusApi, startPracticeApi } from './redux/apis';
import { uploadPost } from '../Feeds/redux/actions';
import { extractJsonFromUrl } from '../UnAuthenticatedContent/apis';
import ProtagAnimation from './ProtagAnimation';
import HybridGoals from '../HybridGoals';
import Quiz from '../HybridGoals/components/Quiz';
import PromptQuestion from '../HybridGoals/components/PromptQuestion';
import ShareView from '../HybridGoals/components/ShareView';
import Carousel from '../HybridGoals/components/Carousel';
import { CODE_CLIENT_EXPIRED } from '../../utils/constants';
import { clientExpiredAction, resetClientExpiredAction } from '../../common/actions';
import Stepper from '../../components/Stepper';
import Loader from '../../components/Loader';
import { pageAnalytics, nullable } from '../../utils/functions';

export const PracticeStateType = Object.freeze({
    lockedOrNew: 1,
    unlockedOrNext: 2,
    read: 3,
    completed: 4,
    todo: 5,
});

function PromptDetails({
    article,
    getArticleDetails,
    promptDetailsData,
    handleCrossClick,
    practiceId,
    getPracticeDetails,
    getTodos,
    uploadPost,
    uploadingPost,
    tags,
    setShowCreatePost,
    setShowPrompt,
    practiceDetailsData,
    // setShowProTagAnimation,
    // setProtagAnimData,
    storeProTagViewed,
    resetPromptData,
    resetPracticesPage = {},
    hideProtagAnimation,
}) {
    // const [isDataFetched, setDataFetched] = useState(false)
    const [showProTagAnimation, setShowProTagAnimation] = useState(false);
    const [protaganim, setProtagAnimData] = useState(null);
    const [hybridData, setHybridData] = useState({
        question_response: {},
        quiz_response: {},
    });
    const [showToolTip, setShowToolTip] = useState(false);
    const [disableDiv, setDisableDiv] = useState(false);
    const [currentPromptId, setCurrentPromptId] = useState(article.id);
    const [promptParentId, setPromptParentId] = useState(article.parent);

    const params = useParams();
    const history = useHistory();
    const myRef = React.createRef();
    const refToTop = React.createRef();
    const promptId = promptDetailsData?.id;

    useEffect(() => {
        if (refToTop && refToTop.current) {
            refToTop.current.scrollIntoView();
        }
        // eslint-disable-next-line
    },[currentPromptId]);

    useEffect(() => {
        if (currentPromptId && currentPromptId !== -1) {
            pageAnalytics.initialise(`promptDetail/${currentPromptId}`);
            return () => {
                pageAnalytics.complete(`promptDetail/${currentPromptId}`);
            };
        }
        return (() => {});
    }, [currentPromptId]);

    useEffect(() => () => {
        if (promptParentId) resetPromptData();
    }, [promptParentId, resetPromptData]);

    useEffect(() => {
        if (promptParentId) {
            setPromptParentId(promptParentId);
        } else {
            // if parent id is null, then set promptDetail to null for new state
            resetPromptData();
        }
    }, [promptParentId, resetPromptData]);

    useEffect(() => {
        if (promptId) {
            setCurrentPromptId(promptId);
        }
    }, [promptId]);

    const moveToClientExpirePage = () => {
        history.push('/client-expire');
    };

    const disableParent = status => {
        setDisableDiv(status);
    };

    useEffect(() => {
        getArticleDetails(article.id);
    }, [getArticleDetails, article.id]);

    useEffect(() => {
        if (practiceDetailsData) {
            if (!practiceDetailsData.protag_viewed && practiceDetailsData.protag_id) {
                extractJsonFromUrl(practiceDetailsData.protag_anim).then(response => {
                    setShowProTagAnimation(true);
                    setProtagAnimData(response.data);
                    return extractJsonFromUrl(practiceDetailsData.practice_image);
                });
            }
        }
    }, [practiceDetailsData, setProtagAnimData, setShowProTagAnimation]);


    useEffect(() => {
        setDisableDiv(false);
    }, [promptDetailsData]);

    const refreshPromptsPage = (newPromptId, fromStepper) => {
        if (myRef && myRef.current) {
            myRef.current.scrollTo(0, 0);
        }
        if (fromStepper) {
            resetPromptData();
        }
        setHybridData({
            question_response: {},
            quiz_response: {},
        });
        getArticleDetails(newPromptId);
    };

    const startAPractice = (practiceId, status) => {
        startPracticeApi(practiceId, status).then(() => {
            if (article.from === 'practicesListPage') {
                resetPracticesPage();
                handleCrossClick({
                    stopPropagation: () => {},
                });
            } else if (article.from === 'homePage') {
                getTodos(5, 1);
                handleCrossClick({
                    stopPropagation: () => {},
                });
            } else {
                // getPracticeDetails(practiceId);
                getArticleDetails(article.id);
            }
        });
    };

    const changePracticeStatus = (practiceId, status, event) => {
        const _id = promptDetailsData.id || article.id;
        changeArticleStatusApi(_id, status)
            .then(() => {
                if (article.from === 'homePage') {
                    getTodos(5, 1);
                    handleCrossClick({
                        stopPropagation: () => {},
                    });
                } else {
                    getArticleDetails(_id);
                    if (status === 'complete') {
                        getPracticeDetails(practiceId);
                    }
                }
                if (status === 'remove_todo') {
                    handleCrossClick(event, practiceId, params.id);
                }
            })
            .catch(e => {
                if (e.response.status === CODE_CLIENT_EXPIRED) {
                    clientExpiredAction();
                    moveToClientExpirePage();
                } else {
                    resetClientExpiredAction();
                }
            });
    };

    const addToDo = (id, status) => changeArticleStatusApi(id, status)
        .then(() => {
            resetPromptData();
            getArticleDetails(id);
        })
        .catch(e => {
            if (e.response.status === CODE_CLIENT_EXPIRED) {
                clientExpiredAction();
                moveToClientExpirePage();
            } else {
                resetClientExpiredAction();
            }
        });

    const resetHybridData = () => {
        setHybridData({
            question_response: {},
            quiz_response: {},
        });
    };

    const getRequiredData = () => {
        const prompt_questions = promptDetailsData.prompt_questions || article.prompt_questions;
        const quiz = promptDetailsData.quiz || article.quiz || {};
        const questions = (prompt_questions || [])
            .filter(elem => elem.required && elem.is_editable)
            .map(item => item.id);
        const obj = {
            questions,
            quiz: [quiz.id],
        };
        return obj;
    };

    const getPrevNextWidgetLayout = () => {
        const state = promptDetailsData.state || article.state;
        const practiceId = promptDetailsData.practiceId || article.practiceId;
        return (
            <HybridGoals
                dataConfig={promptDetailsData}
                hybridData={hybridData}
                required={getRequiredData()}
                state={state}
                callBack={resetHybridData}
                practiceId={practiceId}
                changePracticeStatus={addToDo}
                onClose={event => {
                    handleCrossClick(event, practiceId, params.id);
                }}
                disableParent={disableParent}
                refreshCallback={refreshPromptsPage}
            />
        );
    };

    const getWidget = () => {
        if (article.type === 'orientation' || promptDetailsData.type === 'orientation') {
            if (
                article.state === PracticeStateType.completed
                || promptDetailsData.state === PracticeStateType.completed
            ) {
                return (
                    <CompleteAction
                        practiceId={practiceId}
                        article={promptDetailsData}
                        changePracticeStatus={changePracticeStatus}
                        handleCrossClick={handleCrossClick}
                        type={promptDetailsData.type}
                        uploadPost={uploadPost}
                        uploadingPost={uploadingPost}
                        tags={tags}
                        setShowCreatePost={setShowCreatePost}
                        setShowPrompt={setShowPrompt}
                    />
                );
            }
            return (
                <OrientationAction
                    article={promptDetailsData}
                    practiceId={practiceId}
                    changePracticeStatus={startAPractice}
                />
            );
            // eslint-disable-next-line no-else-return
        } else {
            if (promptDetailsData.completed_on) {
                return (
                    <CompleteAction
                        article={promptDetailsData}
                        practiceId={practiceId}
                        changePracticeStatus={changePracticeStatus}
                        setShowCreatePost={setShowCreatePost}
                        setShowPrompt={setShowPrompt}
                    />
                );
            }
            if (article.in_todo || promptDetailsData.in_todo) {
                return (
                    <TodoAction
                        promptDetailsData={promptDetailsData}
                        practiceId={practiceId}
                        changePracticeStatus={changePracticeStatus}
                        setNotificationActive={() => {}}
                        onClose={event => {
                            handleCrossClick(event, practiceId, params.id);
                        }}
                    />
                );
            }
            if (article.is_survey_article || promptDetailsData.is_survey_article) {
                return (
                    <SurveyAction
                        promptDetailsData={promptDetailsData}
                        practiceId={practiceId}
                        changePracticeStatus={changePracticeStatus}
                        surveyUrl={promptDetailsData.survey_url}
                        article={promptDetailsData}
                    />
                );
            }

            if (
                (article.state === PracticeStateType.read
                    || article.state === PracticeStateType.unlockedOrNext
                    || article.state === PracticeStateType.lockedOrNew
                    || promptDetailsData.state === PracticeStateType.read
                    || promptDetailsData.state === PracticeStateType.unlockedOrNext
                    || promptDetailsData.state === PracticeStateType.lockedOrNew)
                && (!article.in_todo || !promptDetailsData.in_todo)
            ) {
                return (
                    <ArticleAction
                        practiceId={practiceId}
                        changePracticeStatus={changePracticeStatus}
                        handleCrossClick={handleCrossClick}
                        type={promptDetailsData.type}
                        article={promptDetailsData}
                        subpromptId={params.id}
                    />
                );
            }

            return (
                <OrientationAction
                    article={promptDetailsData}
                    practiceId={practiceId}
                    changePracticeStatus={startAPractice}
                />
            );
        }
    };
    // Replace anchor tags to open in new tabs

    const getWidgetType = () => {
        if (promptDetailsData.cta_layout === 'standard') {
            return getWidget();
        }
        return getPrevNextWidgetLayout();
    };

    const handleCrossClickProtag = () => {
        setShowProTagAnimation(false);
    };

    const downloadCallback = () => {
        setShowToolTip(true);
    };

    const updateParent = hybridData => {
        setHybridData(hybridData);
    };

    const getParsedHTML = yourHTML => yourHTML.replaceAll('<a ', '<a target = "_blank" ').replaceAll('<img ', '<img style="width:auto;max-width:350px" ')
        .replaceAll('<video', '<video style="width:auto;max-height:350px;max-width:350px;display: block;margin: 0 auto;" ');


    const getSequencedView = () => {
        const _sequence = promptDetailsData.prompt_component_sequence || article.prompt_component_sequence;
        const prompt_quiz = promptDetailsData.quiz || article.quiz;
        const prompt_questions = promptDetailsData.prompt_questions || article.prompt_questions;
        const state = promptDetailsData.state || article.state;
        const showSharePopUp = promptDetailsData.show_share_popup || article.show_share_popup;
        const sharePopUpData = promptDetailsData.share_popup_data || article.share_popup_data;
        const carousel = promptDetailsData.corousel || article.corousel;
        const promptId = promptDetailsData.id || article.id;
        return _sequence.map(elem => {
            switch (elem) {
                case 'quiz_question':
                    return (
                        prompt_quiz
                        && prompt_quiz.quizoption_set
                        && prompt_quiz.quizoption_set.length > 0 && (
                            <Fragment key={elem}>
                                <Quiz
                                    quiz={prompt_quiz}
                                    callBackData={hybridData}
                                    callBack={setHybridData}
                                    freeze={state === 4}
                                    updateParent={updateParent}
                                />
                                <div className="divider" />
                            </Fragment>
                        )
                    );
                case 'carousel':
                    return (
                        carousel && (
                            <Fragment key={elem}>
                                <Carousel carousel={carousel} />
                                {' '}
                                <div className="divider" style={{ margin: '2.5rem 0' }} />
                            </Fragment>
                        )
                    );
                case 'html':
                    return (
                        <Fragment key={elem}>
                            <div
                                className="feed_inner_html ck-content"
                                dangerouslySetInnerHTML={{ __html: getParsedHTML(promptDetailsData.description_web) }}
                            />
                            {promptDetailsData.description_web && <div className="divider" />}
                        </Fragment>
                    );
                case 'prompt_question':
                    return (
                        prompt_questions
                        && prompt_questions.length > 0 && (
                            <Fragment key={elem}>
                                <PromptQuestion
                                    callBackData={hybridData}
                                    callBack={setHybridData}
                                    promptQuestions={prompt_questions}
                                    freeze={state === 4}
                                    updateParent={updateParent}
                                />
                                <div className="divider" />
                            </Fragment>
                        )
                    );
                case 'share_popup':
                    return (
                        showSharePopUp && (
                            <Fragment key={elem}>
                                <ShareView
                                    data={sharePopUpData}
                                    downloadCallback={downloadCallback}
                                    promptId={promptId}
                                />
                                {sharePopUpData && <div className="divider" />}
                            </Fragment>
                        )
                    );
                default:
                    return null;
            }
        });
    };

    const stepper = promptDetailsData?.show_article_completed_stepper || article?.show_article_completed_stepper;
    const isClientPG = localStorage.getItem('client_config') === 'pg';

    return (
        <>
            {showProTagAnimation && practiceDetailsData && !hideProtagAnimation && (
                <ProtagAnimation
                    practiceDetailsData={practiceDetailsData}
                    handleCrossClick={handleCrossClickProtag}
                    protaganim={protaganim}
                    storeProTagViewed={storeProTagViewed}
                />
            )}
            <WarningModal classNames="prompt" isClientPG={isClientPG}>
                {promptDetailsData ? (
                    <div className="prompt_outer_wrapper" ref={refToTop}>
                        <div
                            className="prompt_detail_wrapper"
                            style={{
                                position: 'relative',
                                overflowY: stepper?.show_stepper ? 'hidden' : 'initial',
                            }}
                        >
                            {showToolTip && (
                            <div className="info_popup">
                                <div
                                    style={{
                                        width: '320px',
                                        paddingLeft: '10px',
                                        paddingTop: '5px',
                                    }}
                                >
                                    Image downloaded successfully
                                </div>
                                <div
                                    onClick={() => {
                                        setShowToolTip(false);
                                    }}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Cross color="black" />
                                </div>
                            </div>
                            )}
                            {stepper?.show_stepper ? (
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            marginRight: '10px',
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        {stepper.article_detail.map(el => <Stepper key={el.article_id} state={el.state} current={el.is_current} articleId={el.article_id} onClick={refreshPromptsPage} />)}
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}
                            <div
                                ref={myRef}
                                style={{
                                    pointerEvents: `${disableDiv ? 'none' : 'auto'}`,
                                    overflowY: stepper?.show_stepper ? 'auto' : 'hidden',
                                    overflowX: 'hidden',
                                }}
                            >
                                <div className="title_and_image_wrapper">
                                    {promptDetailsData.hide_practice_image !== undefined
                                    && !promptDetailsData.hide_practice_image && (
                                        <div className="image">
                                            <img src={promptDetailsData.file} alt="" />
                                        </div>
                                    )}
                                    <div className="title_and_subtitle">
                                        <Text
                                            fontsize=".875rem"
                                            fontcolor={fontColors.siteGrey}
                                            fontfamily={fontFamilies.TheinhardtPanRegular}
                                            margin="0.5rem 0 0 0"
                                            letterSpacing="1px"
                                            textTransform="uppercase"
                                        >
                                            {promptDetailsData.sub_title}
                                        </Text>
                                        <Text
                                            fontsize="1.75rem"
                                            fontcolor={fontColors.black}
                                            fontfamily={fontFamilies.playfairBold}
                                        >
                                            {promptDetailsData.title}
                                        </Text>
                                    </div>
                                </div>
                                <div className="divider" />
                                <div className="spacer" />
                                <div>{getSequencedView()}</div>
                                <div className={`actions_wrapper_conntainer ${isClientPG ? 'content_center' : ''}`}>{getWidgetType()}</div>
                            </div>
                        </div>
                        <div
                            onClick={event => {
                                handleCrossClick(event, practiceId, params.id);
                            }}
                            role="button"
                            tabIndex={0}
                            style={{
                                cursor: 'pointer',
                                marginTop: '10px',
                                marginRight: '10px',
                                position: 'sticky',
                                right: '10px',
                                top: '10px',
                                height: 'fit-content',
                            }}
                            className="outline-none"
                        >
                            <Cross color="black" />
                        </div>
                    </div>
                ) : (
                    <Loader position="absolute" showBackground />
                )}
            </WarningModal>
        </>
    );
}

const dispatchToProps = {
    getArticleDetails,
    uploadPost,
    getPracticeDetails,
    getSubPromptDetails,
    storeProTagViewed,
    resetPromptData,
};

const stateToProps = state => {
    const REDUCER = state.get('PracticeDetailReducer');
    const FeedsREDUCER = state.get('feedsReducer');
    const RefREDUCER = state.get('RefreshReducer');
    return {
        promptDetailsData: REDUCER.promptDetails,
        uploadingPost: REDUCER.uploadingPost,
        tags: FeedsREDUCER.tags,
        practiceDetailsData: REDUCER.practiceDetailsData,
        clientExpired: RefREDUCER.clientExpired,
    };
};

export default connect(stateToProps, dispatchToProps)(PromptDetails);

const Article = {
    created_date: PropTypes.string,
    id: PropTypes.number,
    practice_sequence: PropTypes.number,
    show_type: PropTypes.string,
    state: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    from: PropTypes.string,
};

PromptDetails.defaultProps = {
    getTodos: () => {},
    setShowCreatePost: () => {},
    setShowPrompt: () => {},
    resetPracticesPage: () => {},
    hideProtagAnimation: false,
    uploadingPost: false,
};
PromptDetails.propTypes = {
    article: PropTypes.shape(Article).isRequired,
    getArticleDetails: PropTypes.func.isRequired,
    promptDetailsData: nullable(PropTypes.object).isRequired,
    handleCrossClick: PropTypes.func.isRequired,
    practiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    getPracticeDetails: PropTypes.func.isRequired,
    getTodos: PropTypes.func,
    uploadPost: PropTypes.func.isRequired,
    uploadingPost: PropTypes.bool,
    tags: nullable(PropTypes.shape({})).isRequired,
    setShowCreatePost: PropTypes.func,
    setShowPrompt: PropTypes.func,
    practiceDetailsData: nullable(PropTypes.shape({
        articles: PropTypes.arrayOf(PropTypes.shape(Article)),
        bg_color: PropTypes.string,
        completed_percentage: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        practice_image: PropTypes.string,
        progress_img: PropTypes.string,
        protag: null,
        protag_anim: PropTypes.string,
        protag_id: null,
        protag_img: PropTypes.string,
        protag_subtitle: PropTypes.string,
        protag_title: PropTypes.string,
        protag_viewed: PropTypes.bool,
    })).isRequired,
    // setShowProTagAnimation: PropTypes.func.isRequired,
    // setProtagAnimData: PropTypes.func.isRequired,
    storeProTagViewed: PropTypes.func.isRequired,
    resetPromptData: PropTypes.func.isRequired,
    resetPracticesPage: PropTypes.func,
    hideProtagAnimation: PropTypes.bool,
};
