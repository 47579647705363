import React, { useState } from 'react';
import './index.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActiveCard from './ActiveCard';
import rArrow from '../../assets/rArrow.svg';
import AddIcon from '../../assets/addIcon.svg';
import AddGoal from './AddGoal';
import LocalisationsStore from '../../common/LocalisationsStore';
import GoalDetails from './GoalDetails';
import { deleteGoalAction, getGoalsAction } from '../HomeContainer/redux/actions';
import EditGoal from './EditGoal';


const Goals = ({
    history,
    hideAddGoalBtn,
    goalLists,
    goalsCount,
    deleteGoalAction,
    getGoalsAction,
    hideGoalsSection,
}) => {
    const [view, setView] = useState(0);
    const [activeGoalDetail, setActiveGoalDetail] = useState({});
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        goals: _goals,
        seeAllYourGoals,
        newGoals,
        dontHaveGoalsAtThisMoment,
    } = localisationsAfterLogin;

    const refreshHomeGoals = () => {
        getGoalsAction(3, 1);
    };

    const showModal = () => {
        switch (view) {
            case 1:
                return <AddGoal setView={setView} refreshHomeGoals={refreshHomeGoals} />;
            case 2:
                return (
                    <GoalDetails
                        setView={setView}
                        goalId={activeGoalDetail.id}
                        deleteGoalAction={deleteGoalAction}
                        refreshGoals={refreshHomeGoals}
                    />
                );
            case 3:
                return (
                    <EditGoal
                        goalObj={activeGoalDetail}
                        editCallBack={refreshHomeGoals}
                        closeModal={() => {
                            setView(0);
                            setActiveGoalDetail({});
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const showAllGoals = () => {
        history.push('/all-goals');
    };
    return (
        !hideGoalsSection
            ? (
                <div className="goals">
                    <div className="goal-header">
                        <div className="title">{_goals}</div>
                        {hideAddGoalBtn != null && hideAddGoalBtn === false && (
                            <div className="add-goal" onClick={() => setView(1)}>
                                <div className="icon">
                                    <img src={AddIcon} alt="ADD NEW GOAL" />
                                </div>
                                <div className="label">{newGoals}</div>
                            </div>
                        )}
                    </div>
                    <div className="goalList">
                        {goalsCount > 0 ? (
                            goalLists.map(goal => (
                                <ActiveCard
                                    key={goal.id}
                                    goal={goal}
                                    displayActions
                                    setView={setView}
                                    setActiveGoalDetail={setActiveGoalDetail}
                                    deleteGoalAction={deleteGoalAction}
                                    fromScreen="Goals"
                                    callBack={refreshHomeGoals}
                                    editCallBack={refreshHomeGoals}
                                    deleteCallBack={refreshHomeGoals}
                                />
                            ))
                        ) : (
                            <div className="no-goals" onClick={showAllGoals}>
                                <div className="title">{dontHaveGoalsAtThisMoment}</div>
                            </div>
                        )}

                        {goalsCount > 3 && (
                            <div className="see-all-goals" onClick={showAllGoals}>
                                <div className="title">{seeAllYourGoals}</div>
                                <div className="icon">
                                    <img src={rArrow} alt="See All YOUR GOALS" />
                                </div>
                            </div>
                        )}
                    </div>
                    {showModal()}
                </div>
            )
            : null
    );
};

Goals.defaultProps = {
    hideAddGoalBtn: true,
};

Goals.propTypes = {
    history: PropTypes.object.isRequired,
    hideAddGoalBtn: PropTypes.bool,
    goalLists: PropTypes.array.isRequired,
    goalsCount: PropTypes.number.isRequired,
    deleteGoalAction: PropTypes.func.isRequired,
    hideGoalsSection: PropTypes.bool.isRequired,
};

const dispatchToProps = {
    deleteGoalAction,
    getGoalsAction,
};

const stateToProps = state => {
    const REDUCER = state.get('HomeReducer');
    return {
        goalLists: REDUCER.goalLists,
        goalsCount: REDUCER.goalsCount,
        hideGoalsSection: REDUCER.hideGoalsSection,
    };
};


export default withRouter(connect(stateToProps, dispatchToProps)(Goals));
