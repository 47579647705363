import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/Text';
import ProgressBar from '../../../components/ProgressBar';
import SvgPicture from '../../../components/SvgPicture';
import { ReactComponent as JourneyProgressIcon } from '../../../assets/journey-progress.svg';
import journeyProgressPercentageIcon from '../../../assets/journey-progress-percentage.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';
import { fontFamilies, fontColors } from '../../../common/fontMap';
import './index.scss';

const UserJourneyProgress = ({ data }) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        journey_progress,
        journey_completed,
        daily_practice,
        completed_,
        remaining_,
    } = localisationsAfterLogin;

    if (!data || Object.keys(data).length === 0) return null;

    return (
        <div className="journey-progress-container">
            <div className="progress-subheading-container">
                <Text
                    fontfamily={fontFamilies.MckinseySansMedium}
                    fontcolor={fontColors.black200}
                    fontsize="16px"
                    lineHeight="16px"
                    fontweight={500}
                >
                    {journey_progress}
                </Text>
                <SvgPicture className="journey-progess-icon" height="auto" width="auto">
                    <JourneyProgressIcon />
                </SvgPicture>
            </div>
            <div className="journey-progress-card-container">
                <div className="percentage-wrapper">
                    <div className="percentage-content-container">
                        <div className="percentage-text-container">
                            <Text
                                fontsize="45px"
                                fontcolor={fontColors.black200}
                                fontfamily={fontFamilies.MckinseySansRegular}
                                fontweight={400}
                                lineHeight="45px"
                            >
                                {data.percentage}
                            </Text>
                            <div className="percentage-text">
                                <Text
                                    fontsize="20px"
                                    fontcolor={fontColors.black200}
                                    fontfamily={fontFamilies.MckinseySansLight}
                                    fontweight={300}
                                    lineHeight="16px"
                                    margin="0 0 2px 0"
                                >
                                    %
                                </Text>
                                <Text
                                    fontsize="14px"
                                    fontcolor={fontColors.black200}
                                    fontfamily={fontFamilies.MckinseySansLight}
                                    fontweight={300}
                                    lineHeight="10px"
                                >
                                    {journey_completed}
                                </Text>
                            </div>
                        </div>
                        <ProgressBar percentage={data.percentage} barColor={fontColors.blueLight} />
                    </div>
                    <img src={journeyProgressPercentageIcon} alt="journey-progress-percentage" />
                </div>
                <div className="completion-ratio-wrapper">
                    <Text
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontcolor={fontColors.siteGrey}
                        fontsize="12px"
                        lineHeight="16px"
                        fontweight={500}
                        letterSpacing="0.75px"
                    >
                        {daily_practice}
                    </Text>
                    <div className="completion-ratio-text">
                        <Text
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontcolor={fontColors.black}
                            fontsize="20px"
                            lineHeight="22px"
                            fontweight={400}
                        >
                            {data.completed}
                        &nbsp;
                            <Text
                                fontfamily={fontFamilies.MckinseySansRegular}
                                fontcolor="#656565"
                                fontsize="14px"
                                lineHeight="15px"
                                fontweight={400}
                                margin="3.4px 0 0 0"
                            >
                                {completed_}
                            </Text>
                        </Text>
                        <div className="vertical-divider" />
                        <Text
                            fontfamily={fontFamilies.MckinseySansRegular}
                            fontcolor={fontColors.black}
                            fontsize="20px"
                            lineHeight="22px"
                            fontweight={400}
                        >
                            {data.total - data.completed}
                        &nbsp;
                            <Text
                                fontfamily={fontFamilies.MckinseySansRegular}
                                fontcolor="#656565"
                                fontsize="14px"
                                lineHeight="15px"
                                fontweight={400}
                                margin="3.4px 0 0 0"
                            >
                                {remaining_}
                            </Text>
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserJourneyProgress.defaultProps = {
    data: null,
};

UserJourneyProgress.propTypes = {
    data: PropTypes.shape({
        percentage: PropTypes.number,
        completed: PropTypes.number,
        total: PropTypes.number,
    }),
};

export default UserJourneyProgress;
