import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressSnapshot from './ProgressSnapshot';
import { getUserMatrix, resetUserMatrix } from '../../UserMatrix/redux/actions';
import { uploadPost, getTags } from '../../Feeds/redux/actions';

const stateToProps = state => {
    const UserMatrixReducer = state.get('userMatrixReducer');
    const FREDUCER = state.get('feedsReducer');
    return {
        matrixData: UserMatrixReducer.data,
        loading: UserMatrixReducer.loading,
        uploadingPost: FREDUCER.uploadingPost,
        tags: FREDUCER.tags,
    };
};

const dispatchToProps = { getUserMatrix, resetUserMatrix, uploadPost, getTags };

export default withRouter(connect(stateToProps, dispatchToProps)(ProgressSnapshot));
