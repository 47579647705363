/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PieSegment from '../../../components/PieSegment';
import Neeedle from '../assets/needle.svg';
import LocalisationsStore from '../../../common/LocalisationsStore';

const Needle = styled.div`
    width: 51px;
    height: 22px;
    background-image: url(${Neeedle});
    background-repeat: no-repeat;
    background-size: contain;
    ${({ angle }) => `
    transform: rotate(${angle}deg);
    transform-origin: 83% 50%;
`}
`;

export default function Frequency({ setAnswer }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { tap_needle } = localisationsAfterLogin;
    const [currentAngle, setAngle] = useState(0);
    const [colors, setColors] = useState({
        position1: '#cccccc',
        position2: '#cccccc',
        position3: '#cccccc',
        position4: '#cccccc',
        position5: '#cccccc',
    });
    const [ratingTextValue, setRatingTextValue] = useState('');

    return (
        <div className="meter_gauge">
            <svg>
                <PieSegment
                    centre={{ x: 150, y: 150 }}
                    strokeColor={colors.position1}
                    fillColor={colors.position1}
                    delta={Math.PI / 5}
                    start={Math.PI}
                    label="label 1"
                    rOut={90}
                    rIn={70}
                    degreeVal={0}
                    setAngle={setAngle}
                    value={1}
                    setAnswer={setAnswer}
                    setColors={setColors}
                    setRatingTextValue={setRatingTextValue}
                />
                <PieSegment
                    centre={{ x: 150, y: 150 }}
                    strokeColor={colors.position2}
                    fillColor={colors.position2}
                    delta={Math.PI / 5}
                    start={(6 * Math.PI) / 5}
                    label="label 2"
                    rOut={90}
                    rIn={70}
                    degreeVal={45}
                    setAngle={setAngle}
                    value={2}
                    setAnswer={setAnswer}
                    setColors={setColors}
                    setRatingTextValue={setRatingTextValue}
                />
                <PieSegment
                    centre={{ x: 150, y: 150 }}
                    strokeColor={colors.position3}
                    fillColor={colors.position3}
                    delta={Math.PI / 5}
                    start={(7 * Math.PI) / 5}
                    label="label 3"
                    rOut={90}
                    rIn={70}
                    degreeVal={90}
                    setAngle={setAngle}
                    value={3}
                    setAnswer={setAnswer}
                    setColors={setColors}
                    setRatingTextValue={setRatingTextValue}
                />
                <PieSegment
                    centre={{ x: 150, y: 150 }}
                    strokeColor={colors.position4}
                    fillColor={colors.position4}
                    delta={Math.PI / 5}
                    start={(8 * Math.PI) / 5}
                    label="label 4"
                    rOut={90}
                    rIn={70}
                    degreeVal={135}
                    setAngle={setAngle}
                    value={4}
                    setAnswer={setAnswer}
                    setColors={setColors}
                    setRatingTextValue={setRatingTextValue}
                />
                <PieSegment
                    centre={{ x: 150, y: 150 }}
                    strokeColor={colors.position5}
                    fillColor={colors.position5}
                    delta={Math.PI / 5}
                    start={(9 * Math.PI) / 5}
                    label="label 5"
                    rOut={90}
                    rIn={70}
                    degreeVal={180}
                    setAngle={setAngle}
                    value={5}
                    setAnswer={setAnswer}
                    setColors={setColors}
                    setRatingTextValue={setRatingTextValue}
                />
            </svg>
            <div className="needle_wrapper">
                <Needle angle={currentAngle} />
            </div>
            <div className="ratings_text_below">
                {ratingTextValue.length > 0 ? ratingTextValue : <span>{tap_needle}</span>}
            </div>
        </div>
    );
}

Frequency.propTypes = {
    setAnswer: PropTypes.func.isRequired,
};
