import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import GoalDetails from './GoalDetails';
import { Cross } from '../../UnAuthenticatedContent/assets/svg';

export default function GoalsModal({ goalType, vcrbDetails, addNotes, notes, isLoading, open, doneClick }) {
    return (
        <Modal open={open} className="feeds_goal_details_container">
            <div className="feeds_goal_details">
                <div
                    onClick={doneClick}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    className="close_details outline-none"
                >
                    <Cross color="black" />
                </div>
                <GoalDetails
                    goalType={goalType}
                    vcrbDetails={vcrbDetails}
                    addNotes={addNotes}
                    notes={notes}
                    isLoading={isLoading}
                />
            </div>
        </Modal>
    );
}

GoalsModal.propTypes = {
    goalType: PropTypes.string.isRequired,
    vcrbDetails: PropTypes.arrayOf({
        detail: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        inner_subheading: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    addNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    doneClick: PropTypes.func.isRequired,
};
