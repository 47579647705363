/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Text from '../../components/Text';
import Loader from '../../components/Loader';
import GoalsTabList from '../Goals/GoalsTabList';
import EditGoal from '../Goals/EditGoal';
import backArrow from '../../assets/backArrow.svg';
import PG_goals_stay_tuned from '../../assets/PG_goals_stay_tuned.png';
import AddIcon from '../../assets/addIcon.svg';
import editIcon from '../../assets/edit.svg';
import goalsDetailImg from '../../assets/goals_detail_foreground.svg';
import LocalisationsStore from '../../common/LocalisationsStore';
import { GOAL_DETAIL_VIEWS } from '../../utils/constants';
import { fontColors, fontFamilies } from '../../common/fontMap';
import './index.scss';

const TabViewByCategory = ({
    isLoading,
    hideAddGoalBtn,
    activeTab,
    goals,
    history,
    setView,
    setActiveTab,
    actionCallBack,
    editCallBack,
}) => {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { goals: _goals, dontHaveGoalsAtThisMoment, newGoals, read_more } = localisationsAfterLogin;
    const [editModal, setEditModal] = useState({ active: false, goal: null, view: null });
    const visionTextRef = useRef(null);
    const [showReadMore, setShowReadMore] = useState(false);

    const handleBackCLick = () => {
        history.push('/app/home');
    };

    const editGoal = (goalObj, view) => {
        setEditModal({ active: true, goal: goalObj, view });
    };

    const Image = <img src={PG_goals_stay_tuned} alt="No Active Goals" width="223px" />;

    const visionGoal = goals?.list?.find(category => category.goal_category === 'vision')?.goals?.[0];
    const { goal_text } = visionGoal || {};

    const checkVisionTextOverflow = () => {
        if (visionTextRef?.current && visionTextRef.current.offsetHeight < visionTextRef.current.scrollHeight) {
            setShowReadMore(true);
        } else {
            setShowReadMore(false);
        }
    };

    useEffect(() => {
        checkVisionTextOverflow();
    }, [goal_text]);

    useEffect(() => {
        window.addEventListener('resize', checkVisionTextOverflow);

        return (() => window.addEventListener('resize', checkVisionTextOverflow));
    }, []);

    return (
        <div className="all-goals all-goals-category-view">
            <div className="all-goals-container">
                <div
                    className="all-goals-header-container"
                    style={visionGoal ? { minHeight: '346px' } : { background: 'none' }}
                >
                    <div className="all-goals-header">
                        <div className="back" onClick={handleBackCLick}>
                            <img src={backArrow} alt="" />
                        </div>
                        <div className="title">{_goals}</div>
                    </div>
                    {visionGoal ? (
                        <>
                            <div className="vision-container">
                                <div className="vision-header">
                                    <Text
                                        fontsize="18px"
                                        fontcolor={fontColors.black}
                                        fontfamily={fontFamilies.MckinseySansMedium}
                                        fontweight={500}
                                        lineHeight="20px"
                                        letterSpacing="1px"
                                    >
                                        {visionGoal.title}
                                    </Text>
                                    <img src={editIcon} alt="edit-icon" className="edit-icon" onClick={() => { editGoal(visionGoal, GOAL_DETAIL_VIEWS.EDIT); }} />
                                </div>
                                <div className="vision-text-container">
                                    <Text
                                        fontsize="18px"
                                        fontcolor={fontColors.textBlack}
                                        fontfamily={fontFamilies.MckinseySansRegular}
                                        fontweight={400}
                                        lineHeight="26px"
                                        whiteSpace="pre-wrap"
                                        wordBreak="break-word"
                                        ref={visionTextRef}
                                    >
                                        {visionGoal.goal_text}
                                    </Text>
                                    {showReadMore ? <span className="readmore-text" onClick={() => { editGoal(visionGoal, GOAL_DETAIL_VIEWS.VIEW); }}>{read_more}</span> : null}
                                </div>
                            </div>
                            <img src={goalsDetailImg} alt="goals-boat" className="goals-header-image" />
                        </>
                    ) : null}
                </div>
                <div className="all-goals-content">
                    {hideAddGoalBtn != null && hideAddGoalBtn === false && !isLoading && (
                        <div
                            className="add-goal"
                            onClick={() => {
                                setView(1);
                            }}
                        >
                            <div className="icon">
                                <img src={AddIcon} alt="ADD NEW GOAL" />
                            </div>
                            <div className="label">{newGoals}</div>
                        </div>
                    )}
                    {(isLoading ? (
                        <Loader position="relative" background="transparent" />
                    ) : goals.hasGoals ? (
                        <GoalsTabList
                            activeTab={activeTab}
                            fromScreen="AllGoals"
                            goalLists={goals.list.filter(category => category.goal_category.toLowerCase() !== 'vision')}
                            setActiveTab={setActiveTab}
                            actionCallBack={actionCallBack}
                            editCallBack={editCallBack}
                        />
                    ) : (
                        <div className="empty-goals">
                            {Image}
                            <div className="empty-goals__message">{dontHaveGoalsAtThisMoment}</div>
                        </div>
                    )
                    )}
                    {editModal.active && (
                        <EditGoal
                            editCallBack={editCallBack}
                            goalObj={editModal.goal}
                            closeModal={() => setEditModal({ active: false, goal: null, view: null })}
                            goalView={editModal.view}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

TabViewByCategory.propTypes = {
    hideAddGoalBtn: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    goals: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.shape({})),
        count: PropTypes.number,
        hasGoals: PropTypes.bool,
    }).isRequired,
    history: PropTypes.object.isRequired,
    setView: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    actionCallBack: PropTypes.func.isRequired,
    editCallBack: PropTypes.func.isRequired,
};

export default withRouter(TabViewByCategory);
