import React from 'react';
import PropTypes from 'prop-types';
import { ValueIcon, BehaviourIcon, CultureIcon, RoleIcon } from '../assets/svg';
import './index.scss';

const GoalHeading = ({ type, innerDetail, heading, subHeading, innerSubheading }) => {
    const headingArray = [
        {
            type: 'value',
            icon: <ValueIcon />,
            bottomText: innerDetail ? innerSubheading : subHeading,
            name: heading,
            key: 1,
        },
        {
            type: 'culture',
            icon: <CultureIcon />,
            bottomText: innerDetail ? innerSubheading : subHeading,
            name: heading,
            key: 2,
        },
        {
            type: 'role',
            icon: <RoleIcon />,
            bottomText: innerDetail ? innerSubheading : subHeading,
            name: heading,
            key: 3,
        },
        {
            type: 'behaviour',
            icon: <BehaviourIcon />,
            bottomText: innerDetail ? innerSubheading : subHeading,
            name: heading,
            key: 4,
        },
    ];
    return (
        headingArray
        && headingArray.map(
            v => v.type === type && (
            <div key={v.key}>
                <div className={innerDetail ? 'd-flex' : ''}>
                    <div>{v.icon}</div>
                    <div className="vcrbText">{v.name}</div>
                </div>
                <div className="lowerText">{v.bottomText}</div>
            </div>
            ),
        )
    );
};

GoalHeading.defaultProps = {
    innerDetail: false,
};

GoalHeading.propTypes = {
    type: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    innerSubHeading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
};

export default GoalHeading;
