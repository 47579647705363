import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontColors, fontFamilies } from '../../common/fontMap';

const Input = styled.input`
    ${({ fontSize, fontFamily, fontColor, outline, borderColor, capitalise }) => `
        height: 65px;
        text-transform: ${capitalise ? 'uppercase' : 'auto'};
        font-size: ${fontSize};
        font-family: ${fontFamily};
        color: ${fontColor};
        width: 100%;
        border: 1px solid ${borderColor || '#999999'};
        box-sizing: border-box;
        border-radius: 0.3125rem;
        padding: 0.75rem 1.25rem;
        margin-top: 0.5rem;
        outline: ${outline || 'none'}
        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${fontColors.placeholderGrey};
            font-size: 0.8rem;
            font-family: ${fontFamilies.TheinhardtPanLight};
        }
        :-ms-input-placeholder {
            color: ${fontColors.placeholderGrey};
            font-size: 0.8rem;
            font-family: ${fontFamily.TheinhardtPanLight};
        }
    `}
`;

const Label = styled.label`
    font-size: 0.75rem;
    font-family: TheinhardtPan_medium;
    color: ${fontColors.textBlack};
    letter-spacing: 1px;
`;

export default function InputField({
    type,
    value,
    onChange,
    id,
    label,
    fontSize,
    fontFamily,
    fontColor,
    placeholder,
    outline,
    borderColor,
    capitalise,
    maxLength,
}) {
    return (
        <div>
            <Label htmlFor={id}>{label}</Label>
            <Input
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                fontSize={fontSize}
                fontFamily={fontFamily}
                fontColor={fontColor}
                placeholder={placeholder}
                outline={outline}
                borderColor={borderColor}
                capitalise={capitalise}
                maxLength={maxLength}
            />
        </div>
    );
}

InputField.defaultProps = {
    outline: null,
    capitalise: false,
    maxLength: null,
};

InputField.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fontSize: PropTypes.string.isRequired,
    fontFamily: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    outline: PropTypes.string,
    borderColor: PropTypes.string.isRequired,
    capitalise: PropTypes.bool,
    maxLength: PropTypes.number,
};
