import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import './index.scss';
import { Cross } from '../UnAuthenticatedContent/assets/svg';

export default function StickyQuizResponseModal({
    stickyQuizResponse,
    showStickyQuizResponseModal,
    closeStickyResponsePopUp,
}) {
    return (
        <>
            {stickyQuizResponse && (
                <Modal open={showStickyQuizResponseModal} className="sticky_quiz_response_modal">
                    <div className="sticky_quiz_response_inner">
                        <div
                            onClick={closeStickyResponsePopUp}
                            role="button"
                            tabIndex={0}
                            className="cross outline-none"
                        >
                            <Cross color="black" />
                        </div>
                        <img src={stickyQuizResponse.response_img} alt="" />
                        <span>{stickyQuizResponse.response_title}</span>
                        <span>{stickyQuizResponse.response_content}</span>
                    </div>
                </Modal>
            )}
        </>
    );
}

StickyQuizResponseModal.propTypes = {
    showStickyQuizResponseModal: PropTypes.bool.isRequired,
    stickyQuizResponse: PropTypes.shape({
        response_img: PropTypes.string,
        response_title: PropTypes.string,
        response_content: PropTypes.string,
    }).isRequired,
    closeStickyResponsePopUp: PropTypes.func.isRequired,
};
