/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { fontColors, fontFamilies } from '../../common/fontMap'
import Text from '../Text'
import ArrowCircle from '../../assets/arrow-circle-black.svg'
import { Cross } from '../../containers/UnAuthenticatedContent/assets/svg'
import './index.scss'
import CTATypeMapper from './CTATypeMapper'
import WarningModal from '../WarningModal'
import Frame from '../../assets/Frame.svg'
import PromptDetails, { PracticeStateType } from '../../containers/PracticesDetailsContainer/PromptDetails'

const CardContainer = styled.div`
    width: 100%;
    min-height: 12.5rem
    border-radius: 0.4rem;
    background-color: ${fontColors.siteWhite};
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    position:relative;
`

function CompleteProfileCard({
    key,
    title,
    body,
    closeCallback,
    history,
    ctaExternal,
    ctaInternal,
    ctaType,
    id,
    cta_text,
    thumbnail,
    updateNewCardStatus,
    // getHomePage,
    setShowCreatePost,
    articleId,
    practiceId,
    showRemoveIcon,
}) {
    const card = new CTATypeMapper(history, ctaType, ctaExternal, id, ctaInternal, practiceId)
    const [openModal, setOpenModal] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)

    const handleProfileCompleteOnClick = () => {
        //updateNewCardStatus(card.id)
        // getHomePage();
        if (ctaExternal.includes('html')) {
            setOpenModal(true)
        } else {
            card.openUrl({
                setShowCreatePost,
                setShowPrompt,
            })
        }
    }

    const handleCrossClick = () => {
        setShowPrompt(false)
    }

    return (
        <CardContainer key={key} className="CompleteProfileCard">
            {openModal && (
                <WarningModal>
                    <div className="cardtype1modal">
                        <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                            <Cross color="black" />
                        </div>
                        <div className="img">
                            <img src={Frame} alt="" />
                        </div>
                        <div className="html" dangerouslySetInnerHTML={{ __html: ctaExternal }} />
                    </div>
                </WarningModal>
            )}
            {showPrompt && (
                <PromptDetails
                    article={{
                        id: articleId,
                        state: PracticeStateType.lockedOrNew,
                        from: 'homePage',
                    }}
                    handleCrossClick={handleCrossClick}
                    practiceId={practiceId}
                />
            )}
            <div className="left_panel">
                <div className="element">
                    <Text
                        fontsize="0.75rem"
                        fontcolor={fontColors.placeholderGrey}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0 1rem 0"
                        letterSpacing="0.75px"
                        textTransform="uppercase"
                    >
                        {title}
                    </Text>
                    {showRemoveIcon && (
                        <div onClick={closeCallback} role="button" tabIndex={0} className="cross outline-none">
                            <Cross color="black" />
                        </div>
                    )}
                </div>
                <div className="element">
                    <Text
                        fontsize="1.125rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.MckinseySansRegular}
                        fontweight="400"
                        margin="0 0 0.5rem 0"
                        width="85%"
                    >
                        {body}
                    </Text>
                </div>
                <div className="element" onClick={handleProfileCompleteOnClick}>
                    <Text
                        fontsize="1rem"
                        fontcolor={fontColors.textBlack}
                        fontfamily={fontFamilies.MckinseySansMedium}
                        fontweight="500"
                        margin="0 0.5rem 0 0"
                    >
                        {cta_text}
                    </Text>

                    <img src={ArrowCircle} alt="arrow" className="pointer" />
                </div>
            </div>
            <div className="fimage">
                <img src={thumbnail} alt="" />
            </div>
        </CardContainer>
    )
}

CompleteProfileCard.defaultProps = {
    articleId: -1,
}

CompleteProfileCard.propTypes = {
    key: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    closeCallback: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    ctaExternal: PropTypes.string.isRequired,
    ctaInternal: PropTypes.string.isRequired,
    ctaType: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    cta_text: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    updateNewCardStatus: PropTypes.func.isRequired,
    // getHomePage: PropTypes.func.isRequired,
    setShowCreatePost: PropTypes.func.isRequired,
    practiceId: PropTypes.number.isRequired,
    articleId: PropTypes.number,
    showRemoveIcon: PropTypes.bool.isRequired,
}

export default withRouter(CompleteProfileCard)
