import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Help from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core';
import Text from '../../../../components/Text';
import LocalisationsStore from '../../../../common/LocalisationsStore';

export const LightTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: '#3E3E3E',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.26)',
        fontSize: 12,
        padding: '15px 15px',
        minWidth: '310px',
    },
}))(Tooltip);

export default function ChartProgress({ chartProgress }) {
    const { localisationsAfterLogin } = LocalisationsStore;
    const { influencerScore, influencerDes, contentScore, contentDesc } = localisationsAfterLogin;

    return (
        <div className="chart_progress">
            {!chartProgress.influencer.is_hide && (
                <div className="influencer_progress">
                    <div className="chart_heading">
                        <Text fontcolor="#272727" fontsize="14" fontfamily="TheinhardtPan" fontweight="600">
                            {influencerScore}
                        </Text>
                        <div>
                            <LightTooltip title={influencerDes} enterTouchDelay={5}>
                                <Help fill="#e8e8e8" />
                            </LightTooltip>
                        </div>
                    </div>
                    <svg viewBox="0 0 36 36" className="circular-chart orange">
                        <path
                            className="circle-bg"
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                            className="circle"
                            strokeDasharray={`${chartProgress.influencer.earned} 100`}
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" className="percentage_bold">
                            {chartProgress.influencer.earned}
                            /
                            {chartProgress.influencer.total}
                        </text>
                    </svg>
                </div>
            )}
            {!chartProgress.performance.is_hide && (
                <div className="influencer_progress">
                    <div className="chart_heading">
                        <Text fontcolor="#272727" fontsize="14" fontfamily="TheinhardtPan" fontweight="600">
                            {contentScore}
                        </Text>
                        <div>
                            <LightTooltip title={contentDesc} enterTouchDelay={5}>
                                <Help fill="#e8e8e8" />
                            </LightTooltip>
                        </div>
                    </div>
                    <svg viewBox="0 0 36 36" className="circular-chart orange">
                        <path
                            className="circle-bg"
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                            className="circle"
                            strokeDasharray={`${chartProgress.performance.earned} 100`}
                            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" className="percentage_bold">
                            {chartProgress.performance.earned}
                            /
                            {chartProgress.performance.total}
                        </text>
                    </svg>
                </div>
            )}
        </div>
    );
}

ChartProgress.propTypes = {
    chartProgress: PropTypes.shape({
        influencer: PropTypes.shape({
            total: PropTypes.number,
            earned: PropTypes.number,
            is_hide: PropTypes.bool,
        }),
        performance: PropTypes.shape({
            total: 100,
            earned: PropTypes.number,
            is_hide: PropTypes.bool,
        }),
    }).isRequired,
};
