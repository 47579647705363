import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from 'react-router-dom';
import LocalisationsStore from '../../common/LocalisationsStore';
import AuthStore from '../../common/AuthStore';

const HeaderMenu = withRouter(props => {
    const {
        anchorEl,
        setAnchorEl,
        logoutUser,
        storeTeamMembersList,
        setShowPreferencesModal,
        storeFeedFilters,
        storeNotificationsList,
        resetPageNumber,
    } = props;
    const { localisationsAfterLogin } = LocalisationsStore;
    const {
        faqlink,
        privacyPolicylink,
        taclink,
        myProgress,
        contactUs,
        FAQs,
        privacyPolicy,
        logout,
        termsCondition,
        editPreferencesHeading,
    } = localisationsAfterLogin;

    const handleClose = key => {
        if (key === 8) {
            logoutUser();
            localStorage.removeItem('userProfile');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('status');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('selectedMenu');
            localStorage.removeItem('selectedLanguage');
            localStorage.removeItem('languageIsRightToLeft');
            storeTeamMembersList([]);
            if (storeFeedFilters) {
                storeFeedFilters(null);
            }
            storeNotificationsList([]);
            resetPageNumber();
            AuthStore.deleteccessToken();
            props.history.push('/');
        } else if (key === 1) {
            window.open(faqlink);
        } else if (key === 2) {
            props.getUserProgressData();
        } else if (key === 5) {
            window.open('mailto:inspire@mckinsey.com');
        } else if (key === 6) {
            window.open(taclink);
        } else if (key === 7) {
            window.open(privacyPolicylink);
        } else if (key === 9) {
            setShowPreferencesModal(true);
        }
        setAnchorEl(null);
    };

    const menu = [
        {
            name: editPreferencesHeading,
            key: 9,
        },
        {
            name: FAQs,
            key: 1,
        },
        {
            name: myProgress,
            key: 2,
        },
        {
            name: contactUs,
            key: 5,
        },
        {
            name: termsCondition,
            key: 6,
        },
        {
            name: privacyPolicy,
            key: 7,
        },
        {
            name: logout,
            key: 8,
        },
    ];

    return (
        <div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="dropdownMenu"
            >
                {menu.map(v => (
                    <MenuItem key={v.key} onClick={() => handleClose(v.key)}>
                        <span>{v.name}</span>
                        <span>{v.value}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});

export default HeaderMenu;
