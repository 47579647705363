import { takeLatest, put, call } from 'redux-saga/effects';
import types from './types';
import { storeAnswerResponse } from './actions';
import { apiFailed } from '../../../common/actions';
import { postMailerAnswerApi } from './apis';

function* mailerGeneratorSaga({ baseurl, id, response, email }) {
    try {
        const { data } = yield call(postMailerAnswerApi, baseurl, {
            quiz_id: id,
            email,
            response,
        });
        yield put(storeAnswerResponse(data.data));
    } catch (e) {
        yield put(apiFailed(e));
    }
}

const mailerReducer = [takeLatest(`${types.POST_MAIL_ANSWER}_PENDING`, mailerGeneratorSaga)];

export default mailerReducer;
